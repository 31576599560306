import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../common/apiCallService';
import ScrollIntoView from '../../../../common/scrollIntoView';
import * as actions from '../../../../Redux/actions';
import HeaderAndFooterLayout from '../../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import EmailVerfication from '../EmailVerfication';
import VerifyModel from '../KYC/VerifyModel';
import BalanceTable from './b2cBalanceTable';
import SendCryptoModel from './b2cSendCryptoModel';
import SendingStatus from './b2cSendCryptoStatus';
import B2CTransactionFilters from './b2cTransactionFilter';
import SingleTransactionTableRow from './b2cTransactionTableRow';
import DropdownForTime from './Dropdown';
import PortFoilioDonoutChart from './PortFoilioDonoutChart';
import ReceiveCryptoModel from './ReceiveCryptoModel';
import WalletHistory from './WalletHistory';
import snsWebSdk from '@sumsub/websdk';
import { styled } from '@mui/material/styles';
import browser from 'browser-detect';
import { saveActivity } from "../../../../common/activityTrackingService";
import { getCryptoIcon } from "../../../../common/getCryptoIcon";
import { getDeviceIP } from "../../../../common/getIP";
import localDb from "../../../../common/localDb";
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../../common/theme';
import useDebounce from '../../../../common/useDebounce';
// const searchicon = '/common/crytoicons/searchicon1.png';

let sText = ''
const nohistoryicon = "/common/nohistoryicon.svg"
const sendIcon = '/homeb2c/b2cwalletsendicon.svg';
const receiveIcon = '/homeb2c/b2cwalletwhitegiftbox.svg';
const buyIcon = '/homeb2c/b2cwalletdownarrow.svg';
const browserInfo = browser();

const useStyles = styled(Tooltip)({
  arrow: {
    "&:before": {
      backgroundColor: '#497cf9',
    },
  },
  tooltip: {
    fontSize: "1em",
    backgroundColor: '#497cf9',
    borderRadius: '32px',
    padding: '4px 18px',
    marginLeft: '-35px',
    marginBottom: '8px !important'
  },
});
let firstCall = false


const WalletDetail = (props) => {
  const propsData = useRef({ value: null });
  const [TransactionDetailModal, setTransactionDetailModal] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [showCoppied, toggleCoppied] = useState({
    copy1: false,
    copy2: false,
    copy3: false,
    copy4: false,
  });
  const [detailsData, setDetaulsData] = useState({});
  const [totalAmtInBTC, setTotalAmtInBTC] = useState(null);
  const [totalUSDAmount, setTotalUSDAmount] = useState(null);
  const [balanceTableData, setBalanceTableData] = useState([]);
  const [actualBalanceTableData, setActualBalanceTableData] = useState([]);
  const [pieChartBalanceData, setPieChartBalanceData] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [walletHistoryData, setWalletHistoryData] = useState([]);
  const [balancePieData, setBalancePieData] = useState(null)
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [activeListName, setActiveListName] = useState("Gainers"); // Gainers Losses Trending
  const setLoading = props.setLoading;
  const navigate = useNavigate();

  const api_url = useSelector((state) => state.config.api_url);
  const [orderBy, setOrderBy] = useState('dese');
  const [sortBy, setSortBy] = useState('createdDate');
  const [allTypes, setAlltypes] = useState(['buy', 'send', 'receive']);
  const [allStatus, setAllStatus] = useState(['completed', 'rejected', 'Pending']);
  const [allCryptos, setAllCryptos] = useState([]);
  const [totalTransactonData, setTotalTransactonData] = useState(0);
  const [page, setPage] = useState(1);
  const [dateSelected, setDateSelected] = useState(false);
  const [receiveModel, setReceiveModel] = useState(false);
  const [sendModel, setSendModel] = useState(false);
  const [showStatusModel, setShowStatusModel] = useState(false);
  const [sendAmount, setSendAmount] = useState(0);
  const [sendAmountSymbol, setSendAmountSymbol] = useState('');
  const [msgStatus, setMsgStatus] = useState(false);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const [openGainsDropdown, setGainsDropDown] = useState({
    open: false,
    data: '24H'
  })
  const [openLossDropdown, setLossDropDown] = useState({
    open: false,
    data: '24H'
  })
  const [openTrendingDropdown, setTrendingDropDown] = useState({
    open: false,
    data: '1M'
  })
  const [filteredGains, setFilteredGains] = useState([])
  const [filteredLosses, setFilteredLosses] = useState([])
  const [biggestLosers, setBiglosersList] = useState([])
  const [biggestWinners, setBigWinnersList] = useState([])
  const [filteredTrending, setFilteredTrending] = useState([])
  const [biggestTrending, setBiggestTrending] = useState([])

  const [openEmailVerifycation, setOpenEmailVerification] = useState(false);
  const [emailForVerifycation, setEmailForVerification] = useState(null);

  const [dollarValue, setDollerValue] = useState();
  const [cryptoValue, setCryptoValue] = useState();
  const [fromCurrency, setFromCurrency] = useState('');
  const [toCurrency, setToCurrency] = useState('');
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const [toAddress, setToAddress] = useState();
  const [modelType, setModelType] = useState('');
  const [verfiyModel, setVerifyModel] = useState(false);
  const [coin, setCoin] = useState('BTC');
  const [coinFridge, setCoinFridge] = useState(false)
  const [blockchain, setBlockchain] = useState('Bitcoin');
  const [showBuyButton, setShowBuyButton] = useState(true);
  const [datae2eTransactionId, setDatae2eTransactionId] = useState('');
  const [sendError, setSendError] = useState("")
  const KYC_ACTIVATE = useSelector((state) => state.config.KYC_ACTIVATE);

  useEffect(() => {
    propsData.current.value = props
  }, [props])

  const saveCryptoBySelectedAddress = (
    user_email,
    toAddress,
    fromCurrency,
    toCurrency,
    currencyAmount,
    dollarValue,
    cryptoValue,
    gasFee) => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-send-crypto-save',
      {
        user_email,
        toAddress,
        fromCurrency,
        toCurrency,
        currencyAmount,
        dollarValue,
        cryptoValue,
        gasFee,
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (data) => {
        props.setLoading(false);
        if (data) {
          console.log('transaction id checking', data);
          setDatae2eTransactionId(data.transactionId)
          handleSendStatus(cryptoValue, toCurrency, { send: true });
        }
      },
      (err) => {
        props.setLoading(false);
        handleSendStatus(cryptoValue, toCurrency, { send: false });
        // setError(err.responseJSON.msg);
        console.log(err);
        setSendError(err && err.responseJSON && err.responseJSON.msg)
        console.log("err.message", err && err.responseJSON && err.responseJSON.msg)
      },
    );
  };

  const getKycStatusCheckAndContinue = (type) => {
    if (type === "buy") {
      if (localStorage.getItem("token") && localStorage.getItem("token") !== "" && KYC_ACTIVATE === "true") {
        apiService(
          (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getKycStatus',
          { domainNameUrl: localStorage.getItem("domainNameUrl") },
          (data) => {
            // setLoading(false)
            if (data) {
              if (data.reviewAnswer === "GREEN") {
                setModelType(type)
                setSendModel(true);
              } else {
                handleVerify()
              }
            } else {
              handleVerify()
            }
          },
          (err) => {
            props.setOpenKYCVerificationModal(true)
            getNewAccessToken("#sumsub-websdk-container2");
            console.log(err);
          },
        );
      }else{
        setModelType(type)
        setSendModel(true);
      }
    } else {
      setModelType(type)
      setSendModel(true);
    }
  };

  const getNewAccessToken = (id) => {
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getAccessToken',
      { domainNameUrl: localStorage.getItem("domainNameUrl") },
      (data) => {
        // setLoading(false)
        if (data) {
          //SDKADDED
          let snsWebSdkInstance = snsWebSdk
            .init(data.token, () => getNewAccessToken())
            .withConf({
              lang: 'en',
              uiConf: {
                customCss: 'https://url.com/styles.css',
              },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.stepCompleted', (payload) => {
              console.log('stepCompleted', payload);
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error);
            })
            .build();

          snsWebSdkInstance.launch(id ? id :'#sumsub-websdk-container');
        }
      },
      (err) => {
        // setLoading(false)
        // setError('Error');
        console.log(err);
      },
    );
  };

  const handleCloseVerifyModel = () => {
    setVerifyModel(false);
  };

  const handleVerify = () => {
    setVerifyModel(true);
    // setLoading(true)
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getAccessToken',
      { domainNameUrl: localStorage.getItem("domainNameUrl") },
      (data) => {
        // setLoading(false)
        if (data) {
          let snsWebSdkInstance = snsWebSdk
            .init(data.token, () => getNewAccessToken())
            .withConf({
              lang: 'en',
              uiConf: {
                customCss: 'https://url.com/styles.css',
              },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.stepCompleted', (payload) => {
              // console.log('stepCompleted', payload);
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error);
            })
            .build();

          snsWebSdkInstance.launch('#sumsub-websdk-container');
        }
      },
      (err) => {
        // setLoading(false)
        // setError('Error');
        console.log(err);
      },
    );
  };

  const fetchLatestTrendData = () => {
    apiService((window.location.hostname === "localhost" ? props.api_url : "") + '/restapi/get-stats-data',
      {},
      (data) => {
        // Logic for handling the page getting blank if data is Array of empty objects - CRYP-5315
        for (let d of data) {
          if (d === null || d === undefined || Object.keys(d)?.length === 0) {
            data = [];
            break;
          }
        }
        if (data) {
          setBiglosersList(data)
          setBigWinnersList(data)
          setBiggestTrending(data)
        }

      }, (err) => {
        console.log(err);
      })
  }

  const initWalletDetails = async () => {
    let deviceIp = await getDeviceIP()
    saveActivity({
      EventPage: "Wallet-B2C",
      EventType: "View",
      attribute1: localDb.getVal("b2cUserId") === undefined ? "" : localDb.getVal("b2cUserId"),
      attribute2: localDb.getVal("user_name") === undefined ? "" : localDb.getVal("user_name"),
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    })
}
  useEffect(() => {
    fetchLatestTrendData()
    initWalletDetails();
  }, [])

  let sortWinners = () => {
    let res = []
    if (biggestWinners.length == 0) return []
    // if (openGainsDropdown.data == '1H') {
    //   res = biggestWinners.filter((item) => {
    //     let sign = Math.sign(item.ptrChange1h)
    //     return sign == 1 || sign == 0
    //   })
    //   res.sort((a, b) => b.ptrChange1h - a.ptrChange1h)
    // } 
    if(openGainsDropdown?.data == '24H') {
      res = biggestWinners?.filter((item) => {
        let sign = Math.sign(item?.ptrChange24h)
        return sign == 1 || sign == 0
      })
      res.sort((a, b) => b.ptrChange24h - a.ptrChange24h)
    } else if (openGainsDropdown?.data == '1M') {
      res = biggestWinners?.filter((item) => {
        let sign = Math.sign(item?.change1month)
        return sign == 1 || sign == 0
      })
      res.sort((a, b) => b.change1month - a.change1month)
    } else if (openGainsDropdown?.data == '1Y') {
      res = biggestWinners?.filter((item) => {
        let sign = Math.sign(item?.change1Year)
        return sign == 1 || sign == 0
      })
      res.sort((a, b) => b.change1Year - a.change1Year)
    }

    return res
  }
  useEffect(() => {
    let res = sortWinners()
    setFilteredGains(res)
  }, [biggestWinners, openGainsDropdown.data])

  let sortLosers = () => {
    let res = []
    if (biggestLosers?.length == 0) return []
    // if (openLossDropdown.data == '1H') {
    //   res = biggestLosers.filter((item) => {
    //     let sign = Math.sign(item.ptrChange1h)
    //     return sign == -1
    //   })
    //   res.sort((a, b) => a.ptrChange1h - b.ptrChange1h)
    // } 
    if(openLossDropdown?.data == '24H') {
      res = biggestLosers.filter((item) => {
        let sign = Math.sign(item?.ptrChange24h)
        return sign == -1
      })
      res.sort((a, b) => a.ptrChange24h - b.ptrChange24h)
    } else if (openLossDropdown?.data == '1M') {
      res = biggestLosers?.filter((item) => {
        let sign = Math.sign(item?.change1month)
        return sign == -1
      })
      res.sort((a, b) => a.change1month - b.change1month)
    } else if (openLossDropdown?.data == '1Y') {
      res = biggestLosers?.filter((item) => {
        let sign = Math.sign(item?.change1Year)
        return sign == -1
      })
      res.sort((a, b) => a.change1Year - b.change1Year)
    }
    return res
  }
  useEffect(() => {
    let res = sortLosers()
    setFilteredLosses(res)
  }, [biggestLosers, openLossDropdown.data])

  let sortTrendingData = () => {
    let res = []
    if (biggestTrending?.length == 0) return []
    if (openTrendingDropdown?.data == '1Y') {
      res = [...biggestTrending]
      res.sort((a, b) => Math.abs(b.trending1Year) - Math.abs(a.trending1Year))
    } else if (openTrendingDropdown?.data == '1M') {
      res = [...biggestTrending]
      res.sort((a, b) => Math.abs(b.trending1Month) - Math.abs(a.trending1Month))
    }
    return res
  }
  useEffect(() => {
    let res = sortTrendingData()
    setFilteredTrending(res)
  }, [biggestTrending, openTrendingDropdown.data])


  useEffect(() => {
    if (openGainsDropdown?.data == openLossDropdown?.data) return
    setGainsDropDown({
      open: false,
      data: openLossDropdown?.data
    })
  }, [openLossDropdown.data])

  useEffect(() => {
    if (openGainsDropdown.data == openLossDropdown.data) return
    setLossDropDown({
      open: false,
      data: openGainsDropdown?.data
    })
  }, [openGainsDropdown.data])


  const handleEmailValidation = (email,
    toAddress,
    fromCurrency,
    toCurrency,
    currencyAmount,
    dollarValue,
    cryptoValue) => {
    setDollerValue(dollarValue);
    setCryptoValue(cryptoValue);
    setCurrencyAmount(currencyAmount);
    setToCurrency(toCurrency);
    setFromCurrency(fromCurrency);
    setToAddress(toAddress);
    setSendModel(false)
    setOpenEmailVerification(true)
    setEmailForVerification(email);
  }

  const handleCloseEmailVerification = () => {
    setOpenEmailVerification(false);
  };

  const handleSendStatus = (amount, symbol, status) => {
    setMsgStatus(status.send);
    setSendAmount(amount);
    setSendAmountSymbol(symbol);
    setSendModel(false);
    setShowStatusModel(true);
    setOpenEmailVerification(false);
  }

  const handleCloseSendStatusModel = () => {
    setShowStatusModel(false);
  };

  const handleCloseReceiveModel = () => {
    setReceiveModel(false);
  };

  const handleSend = (type) => {
    getKycStatusCheckAndContinue(type);
  };
  const handleCloseSendModel = () => {
    setCoinFridge(false)
    setSendModel(false);
  };

  const handleReceive = () => {
    setReceiveModel(true);
  };

  const getCryptoCoins = () => {
    let requestBody = {};
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-cryptos',
      requestBody,
      (data) => {
        props.setLoading(false);
        setAllCryptos(data.cryptos);
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };
  const debouncedSearchText = useDebounce(props.searchValue);
  useEffect(() => {
      if(firstCall){
        getB2CUserTransactions()
      }
  }, [debouncedSearchText])
  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      if(firstCall){
        getB2CUserTransactions()
      }
      else{
        firstCall =true
      }
    }
  }, [props.selectedCryptos, allCryptos, props.selectedStatus, props.selectedTypes, page, props.fromDate, props.toDate])

  const [totalUserTransaction, setTotalUserTransaction] = useState(0);

  const getB2CUserTransactions = async (page = 1) => {

    let payload = {
      b2cUserId: localStorage.getItem('b2cUserId'),
      transactionId: propsData.current.value.searchValue.toString(),
      cryptoId: propsData.current.value.searchValue.toString(),
      orderBy: orderBy,
      sortBy: sortBy,
      page,
      CryptoCurrency:
        propsData.current.value.selectedCryptos.length === 0 ||
          propsData.current.value.selectedCryptos.length === allCryptos.length
          ? 'All'
          : propsData.current.value.selectedCryptos,
      status:
        propsData.current.value.selectedStatus.length === 0 ||
          propsData.current.value.selectedStatus.length === allStatus.length
          ? 'All'
          : propsData.current.value.selectedStatus,
      type:
        propsData.current.value.selectedTypes.length === 0 ||
          propsData.current.value.selectedTypes.length === allTypes.length
          ? 'All'
          : propsData.current.value.selectedTypes,
    }

    if (dateSelected) {
      payload = {
        ...payload,
        fromDate: propsData.current.value.fromDate,
        toDate: moment(propsData.current.value.toDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format('YYYY-MM-DDT23:59:59'),
      };
    }
    setLoading(true)
    await apiService(
      (window.location.hostname === 'localhost' ? propsData.current.value.api_url : '') + '/restapi/get-b2c-user-transactions',
      payload,
      (data) => {
        setLoading(false)
        if (data) {
          setTransactionTableData(data && data.b2cUserTransactionsList);
          setTotalTransactonData(data && data.total_transactions);
          setTotalUserTransaction(data && data.total_transactionsForUser);
        }
      },
      (err) => {
        setLoading(false)
        // setError('Error');
        console.log(err);
      },
    );
  };


  const getB2CUserTransactionHistory = async () => {
    let payload = {
      b2cUserId: localStorage.getItem('b2cUserId'),
      orderBy: orderBy,
      sortBy: sortBy,
      page: 1,
    }

    setLoading(true)
    await apiService(
      (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-b2c-user-transactions',
      payload,
      (data) => {
        setLoading(false)
        if (data) {
          setTransactionHistoryData(data && data.b2cUserTransactionsList)
        }
      },
      (err) => {
        setLoading(false)
        // setError('Error');
        console.log(err);
      },
    );
  };
  const getSingleFeatureConfig = (featureName) => {
    return new Promise(async (resolve, reject) => {
      await apiService((window.location.hostname === "localhost" ? props.api_url : "") + '/restapi/get-single-feature-config', {
        featureName,
      }, async (data) => {
        if (data) {
          resolve(data)
        }
      }, (err) => {
        console.log("errrr", err)
        resolve(false)
      })
    })
  }

  const getFeatureConfig = async () => {
    let res = await getSingleFeatureConfig("Buy Crypto")
    if (res) {
      if (res.enabled === false) {
        setShowBuyButton(false);
      }
    }
  }

  useEffect(() => {
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
    if (localStorage.getItem("user_name")) {
      getCryptoCoins();
      getUserBalances();
      getB2CUserTransactionHistory();
      getFeatureConfig();
    }
    let interval1 = null
    if (!initialized && !interval1) {
      interval1 = setInterval(() => {
        setIntervalCalls();
      }, 30000)
    }
    return () => {
      clearInterval(interval1)
    }
  }, [])

  const setIntervalCalls = async () => {
    setInitialized(true);
    // await getUserBalances();
    // await getB2CUserTransactions()
    // await getWalletHistory()
    // await getB2CUserTransactionHistory()
  }

  sText = props.searchValue

  const getUserBalances = async (searchText) => {
    if (localStorage.getItem("user_name") && localStorage.getItem("user_name") !== "") {
      if (!searchText) {
        searchText = sText
      }
      setLoading(true)
      let UserEmail;
      let domainNameUrl;
      if (localStorage.getItem('user_name')) {
        const emailDomain = localStorage.getItem('user_name');
        if (emailDomain.includes('|')) {
          UserEmail = emailDomain.split('|')[0];
          domainNameUrl = emailDomain.split('|')[1];
        } else {
          UserEmail = localStorage.getItem('user_name');
        }
      }
      apiService(
        (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-user-wallet-balances',
        {

          user_email: UserEmail,
          fiatCurrency: 'USD',
          domainNameUrl: localStorage.getItem('domainNameUrl')|| domainNameUrl,
        },
        (data) => {
          setLoading(false);
          if (data) {
            if (data.balanceList) {
              let arrayToShow = data.balanceList.filter((data) => {
                if (
                  data.coin.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
                  data.cryptoName.toString().toLowerCase().includes(searchText.toString().toLowerCase())
                ) {
                  return true;
                } else {
                  return false;
                }
              });
              setBalanceTableData(arrayToShow);
              setActualBalanceTableData(data.balanceList);
              if (!searchText || searchText === '') {
                let balanceArr = [];
                data.balanceList.map((bal) => {
                  if (bal.USDAmount > 0) {
                    balanceArr.push(bal);
                  }
                });
                setPieChartBalanceData(balanceArr);
                const balanceData = data.balanceList;
                if (balanceData) {
                  let showData = false;
                  let dataValue = balanceData.map((bal) => {
                    if (parseFloat(bal.USDAmount) > 0) {
                      showData = true;
                    }
                    return bal.USDAmount ? parseFloat(bal.USDAmount) : 0;
                  });
                  if (dataValue.length > 0 && showData) {
                    setBalancePieData({
                      labels: ['USDT', 'DAI', 'USDC', 'ETH', 'LTC', 'XRP', 'BTC'],
                      datasets: [
                        {
                          data: dataValue,
                          backgroundColor: ['#00AB7C', '#FCAC11', '#006ADA', '#716B94', '#D3D3D3', '#F8C743', '#FF8400'],
                          // hoverBackgroundColor: ['#faa83c', '#716b94','#00ab7c', '#fcac11', '#006ada','#d3d3d3'],
                          borderWidth: 0,
                        },
                      ],
                    });
                  }
                }
              }
            }
            if (data.totalAmtInBTC) {
              setTotalAmtInBTC(data.totalAmtInBTC);
            }
            if (data.totalUSDAmount) {
              setTotalUSDAmount(data.totalUSDAmount);
            }
          } else {
          }
        },
        (err) => {
          setLoading(false);
          console.log(err);
        },
        true,
      );
    }
  }
  const [walletsHistoryToData, setWalletsHistoryToData] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));
  const [walletsHistoryFromData, setWalletsHistoryFromData] = useState(moment().startOf('year').format('MMMM Do YYYY, h:mm:ss a'));
  const [changeWalletDate, setChangeWalletDate] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      getWalletHistory();
    }
  }, [changeWalletDate]);

  const [totalWalletHistoryData, setTotalWalletHistoryData] = useState(0);

  const getWalletHistory = () => {
    setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-user-wallet-history',
      {
        b2cUserId: localStorage.getItem("b2cUserId"),
        fromDate: walletsHistoryFromData === '' ? 'All' : walletsHistoryFromData,
        toDate: walletsHistoryToData === '' ? 'All' : walletsHistoryToData,
        domainNameUrl: localStorage.getItem("domainNameUrl")
      }
      ,
      (data) => {
        setLoading(false)
        if (data) {
          setWalletHistoryData(data.walletHistory);
          setTotalWalletHistoryData(data.totaCount);
        }
      },
      (err) => {
        setLoading(false)
        console.log(err);
      },
    );
  };

  const getStatusIcon = (status, type) => {
    if (type === 'send' || type === 'buy') {
      switch (status) {
        case 'B2CSendFlow:SendCompleted':
        case 'CardBuyFlow:DepositCompleted':
          return '/homeb2c/b2cwalletsendaprroved.svg';
        case 'B2CSendFlow:ErrorEncountered':
        case 'CardBuyFlow:DepositFailed':
          return '/homeb2c/b2cwalletsendrejected.svg';
        default:
          return '/homeb2c/b2cwalletsendinprogress.svg';
      }
    } else {
      switch (status) {
        case 'B2CWalletMonitor:ReceiveCompleted':
        case 'B2CWalletMonitor:ReceiveManuallyCompleted':
          return '/homeb2c/b2cwalletreceiveaprroved.svg';
        case 'B2CWalletMonitor:ErrorEncountered':
          return '/homeb2c/b2cwalletreceiverejected.svg';
        default:
          return '/homeb2c/b2cwalletreceiveinprogress.svg';
      }
    }
  }

  const getStatusMsg = (status, type) => {
    if (type === 'send') {
      switch (status) {
        case 'B2CSendFlow:SendCompleted':
          return 'Send Successfully';
        case 'B2CSendFlow:ErrorEncountered':
          return 'Send Rejected';
        default:
          return 'Send in progress';
      }
    } else if (type === 'buy') {
      switch (status) {
        case 'CardBuyFlow:DepositCompleted':
          return 'Buy Successfully';
        case 'CardBuyFlow:DepositFailed':
          return 'Buy Rejected';
        default:
          return 'Buy in progress';
      }
    } else {
      switch (status) {
        case 'B2CWalletMonitor:ReceiveCompleted':
        case 'B2CWalletMonitor:ReceiveManuallyCompleted':
          return 'Received';
        case 'B2CWalletMonitor:ErrorEncountered':
          return 'Recive Rejected';
        default:
          return 'In Progress';
      }
    }
  }

  const getShortAddress = (str) => {
    if (str !== undefined) {
      if (str.length > 20) {
        return str.substr(0, 12) + "..." + str.substr(-4, 4);
      }
      return "N/A";
    }
  }


  const sendCryptoIcon = b2cTheme === 'light' ? '/homeb2c/sendcryptolight.png' : '/homeb2c/sendcryptodark.png';
  const buyCryptoIcon = b2cTheme === 'light' ? '/homeb2c/buycryptolight.png' : '/homeb2c/buycryptodark.png';
  const receiveCryptoIcon = b2cTheme === 'light' ? '/homeb2c/receivecryptolight.png' : '/homeb2c/receivecryptodark.png';

  const handleCopy = (e) => {
    toggleCoppied({ ...showCoppied, [e]: true });
    setTimeout(() => {
      toggleCoppied({ ...showCoppied, [e]: false });
    }, 2000);
  };

  const classes = useStyles;

  const handleSaveActivity = async (type, crypto) => {
    let deviceIp = await getDeviceIP()
    saveActivity({
      EventPage: "Wallet-B2C",
      EventType: type,
      attribute1: localDb.getVal("b2cUserId") === undefined ? "" : localDb.getVal("b2cUserId"),
      attribute2: localDb.getVal("user_name") === undefined ? "" : localDb.getVal("user_name"),
      attribute3: navigator.language,
      attribute4: crypto !== undefined ? crypto : '',
      device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    })
  }

  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)
  let headerButtonBorderStyle = getHeaderButtonBorderStyle(b2cColorMode, headerButtonDarkBgColour, headerButtonLightBgColour)

  return (
    <div>
      <ScrollIntoView>
        <div className="walletDetailPage">
          <HeaderAndFooterLayout handleSend={handleSend} setCoin={setCoin}>
            <div className="outter__container">
              <div className="wallets">
                <div className="wallet-container">
                  {/* <OnBoardKYC handleReceive={handleReceive} handleSend={handleSend} /> */}
                  <div className="wallet">
                    <div className="wallet__portfolio">
                      {balancePieData ? <div>
                        <MediaQuery minWidth={1025}>
                          <div className="donought-chart donought-chart-wrap">
                            <PortFoilioDonoutChart balanceData={balancePieData ? balancePieData : {}} chartHeight={200} cutout={85} />
                            <div className="donout-chart-text">
                              <div className="donout-chart-heading">$ {totalUSDAmount ? parseFloat(totalUSDAmount).toFixed(2) : 0.00}</div>
                              <div className="donout-chart-sub-heading">~{totalAmtInBTC ? parseFloat(totalAmtInBTC).toFixed(8) : 0.00000000} BTC</div>
                            </div>
                          </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={1024}>
                          <div className="walletPorfolioContent">
                            <div className="balanceTextWrap">
                              <div className="totalBalanceTitle">
                                Total Balance
                              </div>
                              <div className="totalBalanceUsd">${totalUSDAmount ? parseFloat(totalUSDAmount).toFixed(2) : 0.00}</div>
                              <div className="totalBalanceBtc">~{totalAmtInBTC ? parseFloat(totalAmtInBTC).toFixed(8) : 0.00000000} BTC</div>
                            </div>
                            <div className="donought-chart donought-chart-wrap">
                              <PortFoilioDonoutChart balanceData={balancePieData ? balancePieData : {}} chartHeight={100} cutout={35} />
                            </div>
                          </div>
                        </MediaQuery>
                      </div>
                        : <div className="donought-chart donought-chart-default">
                          <div className="dcd_outter_crl">
                            <div className="dcd_inner_crl">
                              <div className="donout-chart-text topZero">
                                <div className="donout-chart-heading">$ {totalUSDAmount ? parseFloat(totalUSDAmount).toFixed(2) : 0.00}</div>
                                <div className="donout-chart-sub-heading">~{totalAmtInBTC ? parseFloat(totalAmtInBTC).toFixed(8) : 0.00000000} BTC</div>
                              </div>
                            </div>
                          </div>
                        </div>}
                      <div className="wallet-assests pt-5">
                        <div>
                          <div className="assest">{pieChartBalanceData && balancePieData && pieChartBalanceData.length > 0 ? pieChartBalanceData.length : 0} Assests</div>
                          <div className="in-portfolio">In Portfolio</div>
                        </div>
                        {balancePieData &&
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {pieChartBalanceData.map((obj, i) => {
                              return (
                                <img key={i} src={`${image_base_url}${getCryptoIcon(obj.coin)}`} style={{ width: '30px', height: '30px', position: "absolute", borderRadius: "100%", right: i ? `${i * 20}px` : 0 }} alt="bitcoin" />
                              )
                            })}
                          </div>
                        }
                      </div>
                      <div className="wallet-buttons">
                        {totalAmtInBTC && totalAmtInBTC > 0 && (
                          <div className="wallet-btns"  style={{ ...headerButtonStyle }} data-e2e="send" onClick={() => { handleSend('receive'); setCoin('BTC'); handleSaveActivity('Send', 'BTC'); }}>
                            <img src={`${image_base_url}${sendIcon}`} alt="sendIcon" />
                            <span >Send</span>
                          </div>
                        )}
                        <div className="wallet-btns" style={{ ...headerButtonStyle }} data-e2e="receive" onClick={() => { handleReceive(); setCoin('BTC'); setBlockchain('Bitcoin'); handleSaveActivity('Receive', 'BTC'); }}>
                          <img src={`${image_base_url}${receiveIcon}`} alt="receiveIcon" />
                          <span>Receive</span>
                        </div>
                        {showBuyButton && <div className="wallet-btns" style={{ ...headerButtonStyle }} data-e2e="buy" onClick={() => { handleSend('buy'); setCoin('BTC'); handleSaveActivity('Buy', 'BTC'); }}>
                          <img src={`${image_base_url}${buyIcon}`} alt="buyIcon" />
                          <span>Buy</span>
                        </div>}
                      </div>
                    </div>
                    <div className="wallet__history">
                      <WalletHistory
                        walletHistoryData={walletHistoryData}
                        fromDate={walletsHistoryFromData}
                        toDate={walletsHistoryToData}
                        setFromDate={setWalletsHistoryFromData}
                        setToDate={setWalletsHistoryToData}
                        changeWalletDate={changeWalletDate}
                        setChangeWalletDate={setChangeWalletDate}
                        totalWalletHistoryData={totalWalletHistoryData}
                      />
                    </div>
                    <div className="wallet__transaction__history">
                      <div className='wallet-history-header'>
                        <div className='wallet-history-chart-heading font-bold'>Transaction History</div>
                        {totalTransactonData &&
                          <div
                            data-e2e="see-all"
                            onClick={() => {
                              navigate('/user-transaction-history-b2c')
                              handleSaveActivity('TransactionsAll')
                            }}
                            style={{ ...headerButtonBorderStyle, cursor: 'pointer' }}>
                            See all
                          </div>
                        }
                      </div>
                      {transactionHistoryData && transactionHistoryData.length > 0 ? <div>
                        {transactionHistoryData.slice(0, 5).map((data, index) => {
                          return (
                            <div
                              className="transaction__history"
                              key={index}
                              onClick={() => {
                                setTransactionDetailModal(true);
                                setDetaulsData(data);
                              }}
                            >
                              <div className="history__coin">
                                <div className='status__img'>
                                  <img src={`${image_base_url}${getStatusIcon(data.status.code, data.type)}`} alt={data.type} data-e2e={index} />
                                </div>
                                <div className='status__Address'>
                                  <div className='address'>{data.fromWallet && data.fromWallet.substr(0, 4) + "..." + data.fromWallet.substr(-4)}</div>
                                  <div className="status" data-e2e={data.fromWallet}>
                                    {getStatusMsg(data.status.code, data.type)}
                                  </div>
                                </div>
                              </div>
                              <div className="history__coin__value">
                                <div
                                  className={data.type === 'receive' ? 'coin__amount recive__coin' : data.type === 'reject' ? 'coin__amount reject__coin' : 'coin__amount send__coins'}
                                >
                                  <span style={{ paddingRight: '5px' }}>{data.type === 'receive' ? "+" : data.type === 'send' ? '-' : null}</span>
                                  {parseFloat(data.amount).toFixed(8)} {data.cryptoId}
                                </div>
                                <span>
                                  <img src={`${image_base_url}${getCryptoIcon(data && data.cryptoId)}`} alt="bitcoin" />
                                </span>
                                <div className="forward__arrow">
                                  <i className="fa fa-angle-right" aria-hidden="true" data-e2e="arrow"></i>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div> : <div className="nohistorycomponent">
                        <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                        <div className="nohistorytext">No Transaction History</div>
                      </div>}
                    </div>
                  </div>
                </div>

                {
                  <div>
                    <Dialog open={TransactionDetailModal} className="transaction__Detail__model b2c-signup-model b2c-login-model">
                      <div className="details__model details__model_mobile_view">
                        <div className="details__close__icon">
                          <ClearIcon
                            onClick={() => {
                              setTransactionDetailModal(false);
                              setDetaulsData({});
                            }}
                            className="icon__close"
                          />
                        </div>
                        <div className="details__date">{detailsData.createdDate ? moment(detailsData.createdDate).format("MMMM Do YYYY , HH:mm") : "-"}</div>
                        <div className="details__status">
                          <span style={{ textTransform: 'capitalize' }}>{detailsData.type}</span> from {detailsData.type === "receive" && detailsData.transactionRefLink && detailsData.transactionRefLink !== "" ? "merchant" : "wallet"}
                        </div>
                        <div className='details__address'>{detailsData.fromWallet && detailsData.fromWallet.substr(0, 4) + "..." + detailsData.fromWallet.substr(-4)}</div>
                        <div className={detailsData.type == 'send' ? "details__amount details__amount__send__BG" : "details__amount details__amount__BG"}>
                          {detailsData.type === 'receive' ? "+" : detailsData.type === 'send' ? '-' : null}
                          {detailsData.amount ? parseFloat(detailsData.amount).toFixed(6) : '0'} {detailsData.cryptoId}
                        </div>
                        <div className="detail__Orignal__amount">{detailsData && detailsData.USDAmount ? parseFloat(detailsData.USDAmount).toFixed(2) : '0'} {"  USD"}</div>
                        <div className="transaction__Details__status">
                          Completed transaction
                        </div>
                      </div>
                      <div className="transaction__Details">
                        <div className="from__address">
                          <h1>From</h1>
                          <div className="details__row">
                            <h1>{getShortAddress(detailsData.fromWallet)}</h1>
                            <Tooltip open={showCoppied.copy1} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy(detailsData.fromWallet);
                                  handleCopy('copy1');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="from__address">
                          <h1>To</h1>
                          <div className="details__row">
                            <h1>{getShortAddress(detailsData.toWallet)}</h1>
                            <Tooltip open={showCoppied.copy2} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy(detailsData.toWallet);
                                  handleCopy('copy2');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="from__address">
                          <h1>Txid</h1>
                          <div className="details__row">
                            <h1>{getShortAddress(detailsData.transactionId)}</h1>
                            <Tooltip open={showCoppied.copy3} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy(detailsData.transactionId);
                                  handleCopy('copy3');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="from__address">
                          <h1>Balance after transaction</h1>
                          <div className="details__row">
                            <h1>
                              {detailsData.overallBalance && detailsData.overallBalance.btcAmount ? parseFloat(detailsData.overallBalance.btcAmount).toFixed(6) : '0'} {detailsData.cryptoId}/ <span>{"$ "}{detailsData.overallBalance && detailsData.overallBalance.usdAmount ? parseFloat(detailsData.overallBalance.usdAmount).toFixed(2) : '0'}</span>
                            </h1>
                            <Tooltip open={showCoppied.copy4} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy((detailsData.overallBalance && detailsData.overallBalance.btcAmount ? detailsData.overallBalance.btcAmount : '0') + ' ' + detailsData.cryptoId + '/' + "$" + (detailsData.overallBalance && detailsData.overallBalance.usdAmount ? detailsData.overallBalance.usdAmount : '0'));
                                  handleCopy('copy4');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        {detailsData.Merchant && detailsData.Merchant.name != null &&
                          <div className="from__address">
                            <h1>{`Merchant Name`}</h1>
                            <div className="details__row">
                              <h1>
                                {detailsData.Merchant.name}
                              </h1>
                            </div>
                          </div>
                        }
                      </div>
                    </Dialog>
                  </div>
                }
                <div className="sectionTable">
                  <div className="switchAndTable">
                    <div className="switchAndSearch">
                      {(totalUserTransaction && totalUserTransaction > 0) ? <div className="switchButton">
                        <div className={props.tableName === 'balance' ? 'balance activeButton' : 'balance'} onClick={() => {
                          sText = '';
                          props.setSearchValue('');
                          getUserBalances();
                          props.setTableName('balance');
                        }} style={props.tableName === 'balance' ? { ...headerButtonStyle } : {}} data-e2e="balances">
                          Balances
                        </div>
                        <div
                          className={props.tableName === 'transaction' ? 'transaction activeButton' : 'transaction'}
                          onClick={() => {
                            getB2CUserTransactions()
                            props.setSearchValue('');
                            props.setTableName('transaction');
                            handleSaveActivity('Transactions')
                          }}
                          data-e2e="transactions"
                          style={props.tableName === 'transaction' ? { ...headerButtonStyle } : {}}
                        >
                          Transactions
                        </div>
                      </div> : null}

                      <MediaQuery minWidth={1025}>

                        <div className={b2cTheme === 'dark' ? 'searchInput searchInputDark' : 'searchInput'}>
                          <input type="text" value={props.searchValue} placeholder={props.tableName === 'balance' ? "Search Assets " : "Search Transactions "} onChange={(e) => {
                            props.setSearchValue(e.target.value)
                            if (props.tableName === 'balance') {
                              let arrayToShow = actualBalanceTableData.filter((data) => {
                                if (data.coin.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                                  || data.cryptoName.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())) {
                                  return true
                                } else {
                                  return false
                                }
                              })
                              setBalanceTableData(arrayToShow)
                              // getUserBalances(e.target.value === "" ? "All" : e.target.value)
                            }

                          }} data-e2e="search-assets-text-box" />
                          {/* <img src={`${image_base_url}${searchicon}`} alt="searchicon" data-e2e="search icon" /> */}
                          <i className="fa fa-search serchIcon" aria-hidden="true"></i>
                        </div>
                      </MediaQuery>
                    </div>
                    {props.tableName === 'balance' ? (
                      <div style={{ overflowX: 'hidden' }}>
                        {balanceTableData && balanceTableData.length > 0 ?
                          <BalanceTable
                            coinFridge={coinFridge}
                            setCoinFridge={setCoinFridge}
                            handleSend={handleSend}
                            handleReceive={handleReceive}
                            setCoin={setCoin}
                            setBlockchain={setBlockchain}
                            data={balanceTableData}
                            showBuyButton={showBuyButton}
                            sortBy={sortBy}
                            orderBy={orderBy}
                            changeOrder={(sortBy) => {
                              if (sortBy !== sortBy) {
                                setOrderBy('desc');
                              } else {
                                setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                              }
                            }}
                          /> : <div style={{ color: "grey", fontSize: "14px", textAlign: "center", display: "flex", width: "100%", padding: "5px", minHeight: "200px" }}>
                            No Balance
                          </div>}
                      </div>
                    ) : (
                      <div>
                        <MediaQuery minWidth={1025}>
                          <B2CTransactionFilters
                            fromDate={props.fromDate}
                            toDate={props.toDate}
                            setFromDate={props.setFromDate}
                            setToDate={props.setToDate}
                            allTypes={allTypes}
                            selectedTypes={props.selectedTypes}
                            setSelectedTypes={props.setSelectedTypes}
                            allStatus={allStatus}
                            selectedStatus={props.selectedStatus}
                            setSelectedStatus={props.setSelectedStatus}
                            allCryptos={allCryptos}
                            selectedCryptos={props.selectedCryptos}
                            setSelectedCryptos={props.setSelectedCryptos}
                            dateSelected={dateSelected}
                            setDateSelected={setDateSelected}
                          />
                          <div>
                            {totalTransactonData && totalTransactonData > 0 ? (
                              <div className="TransactionTable">
                                {transactionTableData.map((TData, i) => {
                                  return (
                                    <SingleTransactionTableRow
                                      data={TData}
                                      key={i}
                                    />
                                  );
                                })}
                              </div>
                            ) :
                              (<div className='No_Transactions_Found text__color'>
                                NO Transaction Found.
                              </div>)
                            }
                          </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={1024}>
                          <div>
                            {totalTransactonData && totalTransactonData > 0 ? (
                              <div className="TransactionTable">
                                {transactionTableData.map((data, index) => {
                                  return (
                                    <div
                                      className="transaction__history"
                                      key={index}
                                      onClick={() => {
                                        setTransactionDetailModal(true);
                                        setDetaulsData(data);
                                      }}
                                    >
                                      <div className="history__coin">
                                        <div className='status__img'>
                                          <img src={`${image_base_url}${getStatusIcon(data.status.code, data.type)}`} alt={data.type} data-e2e={index} />
                                        </div>
                                        <div className='status__Address'>
                                          <div className='address'>{data.fromWallet && data.fromWallet.substr(0, 4) + "..." + data.fromWallet.substr(-4)}</div>
                                          <div className="status" data-e2e={data.fromWallet}>
                                            {getStatusMsg(data.status.code, data.type)}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="history__coin__value">
                                        <div
                                          className={data.type === 'receive' ? 'coin__amount recive__coin' : data.type === 'reject' ? 'coin__amount reject__coin' : 'coin__amount send__coins'}
                                        >
                                          <span style={{ paddingRight: '5px' }}>{data.type === 'receive' ? "+" : data.type === 'send' ? '-' : null}</span>
                                          {parseFloat(data.amount).toFixed(8)} {data.cryptoId}
                                        </div>
                                        <span>
                                          <img src={`${image_base_url}${getCryptoIcon(data && data.cryptoId)}`} alt="bitcoin" />
                                        </span>
                                        <div className="forward__arrow">
                                          <i className="fa fa-angle-right" aria-hidden="true" data-e2e="arrow"></i>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) :
                              (<div className='No_Transactions_Found text__color'>
                                NO Transaction Found.
                              </div>)
                            }
                          </div>
                        </MediaQuery>
                        {totalTransactonData && totalTransactonData > 10 ?
                            <div className='seeAll__TR' style={{ ...headerButtonBorderStyle, borderColor: "rgba(0, 0, 0, 0.1)" }}
                            onClick={() => navigate('/user-transaction-history-b2c')}
                          >
                            see all transactions
                          </div> : (null)
                        }
                      </div>
                    )}
                  </div>
                  <div>
                    <MediaQuery minWidth={1025}>
                      <div className="gaineLoseTrand">
                        <div className="BiggestGainers ScrollBarStyle">
                          <div className="title">
                            <div className="title__left">Biggest Gainers</div>
                            <DropdownForTime
                              setDropDown={setGainsDropDown}
                              dropdownData={openGainsDropdown}
                              fetchLatestTrendData={fetchLatestTrendData}
                              mode='gain'
                            />
                          </div>

                          <div>
                            {filteredGains.length > 0 ?
                              filteredGains.map((data, index) => {
                                return <SingleGaineLoseTrandData image_base_url={image_base_url} key={index + 1} data={data} mode={openGainsDropdown.data} type='gain' orderNo={index + 1} />;
                              })
                              :
                              <div className="nohistorycomponent">
                                <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                                <div className="nohistorytext">No Coins</div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="BiggestGainers ScrollBarStyle">
                          <div className="title">
                            <div className="title__left">Biggest Losses</div>
                            <DropdownForTime
                              setDropDown={setLossDropDown}
                              dropdownData={openLossDropdown}
                              fetchLatestTrendData={fetchLatestTrendData}
                              mode='loss'
                            />

                          </div>

                          <div>
                            {filteredLosses.length > 0 ?
                              filteredLosses.map((data, index) => {
                                return <SingleGaineLoseTrandData image_base_url={image_base_url} key={index + 1} data={data} mode={openLossDropdown.data} type='loss' orderNo={index + 1} />;
                              })
                              :
                              <div className="nohistorycomponent">
                                <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                                <div className="nohistorytext">No Coins</div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="BiggestGainers ScrollBarStyle">
                          <div className="title">
                            <div className="title__left">Trending</div>
                            <DropdownForTime
                              setDropDown={setTrendingDropDown}
                              dropdownData={openTrendingDropdown}
                              fetchLatestTrendData={fetchLatestTrendData}
                              mode='trending'
                            />
                          </div>

                          <div>

                            {
                              filteredTrending.length > 0 ?
                                filteredTrending.map((data, index) => {
                                  return <SingleGaineLoseTrandData image_base_url={image_base_url} key={index + 1} data={data} orderNo={index + 1} type="trending" mode={openTrendingDropdown.data} />;
                                })
                                :
                                <div className="nohistorycomponent">
                                  <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                                  <div className="nohistorytext">No Coins</div>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </MediaQuery>

                    <MediaQuery maxWidth={1024}>
                      <div className="gaineLoseTrandWrap">
                        <div className="switchButtonWrap">
                          <div className={activeListName === 'Gainers' ? 'switchBtn activeBtn' : 'switchBtn'} onClick={() => {
                            setActiveListName('Gainers');
                          }} style={activeListName === 'Gainers' ? { ...headerButtonStyle } : {}}>
                            Gainers
                          </div>
                          <div
                            className={activeListName === 'Losses' ? 'switchBtn activeBtn' : 'switchBtn'}
                            onClick={() => {
                              setActiveListName('Losses');
                            }}
                            style={activeListName === 'Losses' ? { ...headerButtonStyle } : {}}
                          >
                            Losses
                          </div>
                          <div
                            className={activeListName === 'Trending' ? 'switchBtn activeBtn' : 'switchBtn'}
                            onClick={() => {
                              setActiveListName('Trending');
                            }}
                            style={activeListName === 'Trending' ? { ...headerButtonStyle } : {}}
                          >
                            Trending
                          </div>
                        </div>
                        {activeListName === 'Gainers' && <div>
                          <div>
                            {filteredGains.length > 0 ?
                              filteredGains.map((data, index) => {
                                return <SingleGaineLoseTrandData image_base_url={image_base_url} key={index + 1} data={data} mode={openGainsDropdown.data} type='gain' orderNo={index + 1} />;
                              })
                              :
                              <div className="nohistorycomponent">
                                <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                                <div className="nohistorytext">No Coins</div>
                              </div>
                            }
                          </div>
                        </div>}
                        {activeListName === 'Losses' && <div>
                          <div>
                            {filteredLosses.length > 0 ?
                              filteredLosses.map((data, index) => {
                                return <SingleGaineLoseTrandData image_base_url={image_base_url} key={index + 1} data={data} mode={openLossDropdown.data} type='loss' orderNo={index + 1} />;
                              })
                              :
                              <div className="nohistorycomponent">
                                <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                                <div className="nohistorytext">No Coins</div>
                              </div>
                            }
                          </div>
                        </div>}
                        {activeListName === 'Trending' && <div>
                          <div>
                            {
                              filteredTrending.length > 0 ?
                                filteredTrending.map((data, index) => {
                                  return <SingleGaineLoseTrandData image_base_url={image_base_url} key={index + 1} data={data} orderNo={index + 1} type="trending" mode={openTrendingDropdown.data} />;
                                })
                                :
                                <div className="nohistorycomponent">
                                  <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
                                  <div className="nohistorytext">No Coins</div>
                                </div>
                            }
                          </div>
                        </div>}
                      </div>
                    </MediaQuery>
                  </div>

                  <div className="buyReceiveSend">
                    {showBuyButton && <div className="buy cursor-pointer" onClick={() => { handleSend('buy'); setCoin('BTC'); handleSaveActivity('Buy', 'BTC'); }}>
                      <div className="buy__content">
                        <div className="content__image">
                          <img src={`${image_base_url}${buyCryptoIcon}`} alt="buyCryptoIcon" />
                        </div>
                        <div className="buyConentTextWrap">
                          <MediaQuery minWidth={1025}>
                            <h1 data-e2e="buy-crypto">Buy Crypto</h1>
                            <p>Safely purchase crypto with regular bank card</p>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                            <div className="buyText">Buy Crypto</div>
                            <div className="buyDesc">Safely purchase crypto with regular bank card</div>
                          </MediaQuery>
                        </div>
                        {/* <MediaQuery minWidth={1025}>
                          <div className="content__promotion">
                            <div className="promotion">
                              {' '}
                              <img src={`${image_base_url}/homeb2c/bestratesicon.svg`} alt="bitcoin" /> BEST RATES
                            </div>
                            <div className="promotion">
                              {' '}
                              <img src={`${image_base_url}/homeb2c/flashicon.svg`} alt="bitcoin" /> SUPER FAST
                            </div>
                          </div>
                        </MediaQuery> */}
                        <MediaQuery maxWidth={1024}>
                          <div className="OpenArrow"><i className="fa fa-angle-right" /></div>
                        </MediaQuery>
                      </div>
                    </div>}

                    <div className="buy cursor-pointer" onClick={() => { handleReceive(); setCoin('BTC'); setBlockchain('Bitcoin'); handleSaveActivity('Receive', 'BTC'); }}>
                      <div className="buy__content">
                        <div className="content__image">
                          <img src={`${image_base_url}${receiveCryptoIcon}`} alt="receiveCryptoIcon" />
                        </div>
                        <div className="buyConentTextWrap">
                          <MediaQuery minWidth={1025}>
                            <h1 data-e2e="receive-currency">Receive</h1>
                            <p>Send cryptocurrency to your wallet</p>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                            <div className="buyText" data-e2e="receive-currency">Receive</div>
                            <div className="buyDesc">Send cryptocurrency to your wallet</div>
                          </MediaQuery>
                        </div>
                        {/* <MediaQuery minWidth={1025}>
                          <div className="content__promotion">
                            <div className="promotion" data-e2e="receive-exchange">
                              {' '}
                              <img src={`${image_base_url}/homeb2c/superfast.svg`} alt="bitcoin" /> EXCHANGE
                            </div>
                          </div>
                        </MediaQuery> */}
                        <MediaQuery maxWidth={1024}>
                          <div className="OpenArrow"><i className="fa fa-angle-right" /></div>
                        </MediaQuery>
                      </div>
                    </div>

                    <div className="buy cursor-pointer" onClick={() => { handleSend('receive'); setCoin('BTC'); handleSaveActivity('Send', 'BTC'); }}>
                      <div className="buy__content">
                        <div className="content__image">
                          <img src={`${image_base_url}${sendCryptoIcon}`} alt="sendCryptoIcon" />
                        </div>
                        <div className="buyConentTextWrap">
                          <MediaQuery minWidth={1025}>
                            <h1 data-e2e="send-currency">Send</h1>
                            <p>Send Crypto anywhere you want </p>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                            <div className="buyText" data-e2e="send-currency">Send</div>
                            <div className="buyDesc">Send Crypto anywhere you want </div>
                          </MediaQuery>
                        </div>
                        {/* <MediaQuery minWidth={1025}>
                          <div className="content__promotion">
                            <div className="promotion" data-e2e="send-bank">
                              {' '}
                              <img src={`${image_base_url}/homeb2c/bankicon.svg`} alt="bitcoin" /> BANK
                            </div>
                            <div className="promotion" data-e2e="send-wallet">
                              {' '}
                              <img src={`${image_base_url}/homeb2c/bt.svg`} alt="bitcoin" /> WALLET
                            </div>
                            <div className="promotion" data-e2e="send-credit-card">
                              {' '}
                              <img src={`${image_base_url}/homeb2c/creditcardicon.svg`} alt="bitcoin" /> CREDIT CARD
                            </div>
                          </div>
                        </MediaQuery> */}
                        <MediaQuery maxWidth={1024}>
                          <div className="OpenArrow"><i className="fa fa-angle-right" /></div>
                        </MediaQuery>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {receiveModel && <ReceiveCryptoModel coinFridge={coinFridge} allCryptos={allCryptos} setCoinFridge={setCoinFridge} coin={coin} setCoin={setCoin} blockchain={blockchain} open={receiveModel} onClose={handleCloseReceiveModel} />}
            {sendModel && <SendCryptoModel open={sendModel}
              coinFridge={coinFridge}
              setCoinFridge={setCoinFridge}
              onClose={handleCloseSendModel}
              handleSendStatus={handleSendStatus}
              handleEmailValidation={handleEmailValidation}
              saveCryptoBySelectedAddress={saveCryptoBySelectedAddress}
              type={modelType}
              coin={coin}
            />}
            {showStatusModel && (
              <SendingStatus
                msg={msgStatus ?
                  `You’re sending ${sendAmount} ${sendAmountSymbol}, for more information check you transactions.` :
                  `${sendAmount} ${sendAmountSymbol} has been rejected, unable to send.
                  ${sendError}`}
                open={showStatusModel}
                sendStatus={msgStatus}
                onClose={handleCloseSendStatusModel}
                saveCryptoBySelectedAddress={saveCryptoBySelectedAddress}
                datae2eTransactionId={datae2eTransactionId}

              />
            )}
            {openEmailVerifycation && (
              <EmailVerfication
                user_email={emailForVerifycation}
                openLogin={() => {
                  saveCryptoBySelectedAddress(
                    localStorage.getItem("user_name"),
                    toAddress,
                    fromCurrency,
                    toCurrency,
                    currencyAmount,
                    dollarValue,
                    cryptoValue
                  )
                }}
                open={openEmailVerifycation}
                onClose={handleCloseEmailVerification}
                modal="addressBook"
              />
            )}
          </HeaderAndFooterLayout>
        </div>
      </ScrollIntoView>
      {verfiyModel && <VerifyModel open={verfiyModel} onClose={handleCloseVerifyModel} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    api_url: state.config.api_url,
    searchValue: state.wallet.searchValue,
    toDate: state.common.toDate,
    fromDate: state.common.fromDate,
    selectedTypes: state.common.selectedTypes,
    selectedStatus: state.common.selectedStatus,
    selectedCryptos: state.common.selectedCryptos,
    tableName: state.common.walletPageTableName,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setSearchValue: (value) => dispatch(actions.setSearchValue(value)),
    setFromDate: (value) => dispatch(actions.setFromDate(value)),
    setToDate: (value) => dispatch(actions.setToDate(value)),
    setSelectedTypes: (value) => dispatch(actions.setSelectedTypes(value)),
    setSelectedStatus: (value) => dispatch(actions.setSelectedStatus(value)),
    setSelectedCryptos: (value) => dispatch(actions.setSelectedCryptos(value)),
    setTableName: (value) => dispatch(actions.setWalletPageTableName(value)),
    setOpenKYCVerificationModal: (value) => dispatch(actions.setOpenKYCVerificationModal(value)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetail);

const SingleGaineLoseTrandData = (props) => {
  const { coinData, ptrChange24h, ptrChange1h, change1month, change1Year, trending1Year, trending1Month } = props.data;
  let { type, mode } = props
  let numberToUse
  switch (mode) {
    case '24H':
      numberToUse = ptrChange24h;
      break;
    // case '1H':
    //   numberToUse = ptrChange1h;
      break;
    case '1M':
      numberToUse = type == 'trending' ? trending1Month : change1month;
      break;
    case '1Y':
      numberToUse = type == 'trending' ? trending1Year : change1Year;
      break;
  }
  return (
    <div className="dataRow">
      <div className="data__row__left">
        <MediaQuery minWidth={1025}>
          <div className="dataRow__counter">{props.orderNo}</div>
        </MediaQuery>
        <img src={`${props.image_base_url}${coinData && coinData.length > 0 ? coinData[0].icon : ''}`} alt="bitcoin" />
        <div className="dataRow__name">
          {coinData && coinData.length > 0 ? coinData[0].symbol : ''} / <span>{coinData && coinData.length > 0 ? coinData[0].name : ''}</span>
        </div>
      </div>
      <div className={`data__row__right  ${mode == 'loss' ? 'loss-mode' : null}`}>
        {numberToUse && <i className={`fa fa-sort-asc ${numberToUse && numberToUse.toString().includes('-') ? 'negative-value-icon-style' : ''}`} aria-hidden="true"></i> || ""}
        <span className={`${numberToUse && numberToUse.toString().includes('-') ? 'negative-value-color ' : ''}`}>{numberToUse && numberToUse.toString().slice(0, 6) || "0.0"} %</span>
      </div>
    </div>
  );
};