import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import { getCryptoIcon } from "../../../../common/getCryptoIcon";
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const EditAddressModal = (props) => {
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [formData, setFormData] = useState({
    nickName: '',
    asset: '',
    address: ''
  })
  const [selectedCrypto, setSelectedCrypto] = useState('');
  const [cryptoWallet, setCryptoWallet] = useState([]);
  const [openCryptoOption, setOpenCryptoOption] = useState(false);
  const api_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [error, setError] = useState(null)


  const handleFormData = (data, fieldName) => {
    let temp = { ...formData }
    temp[fieldName] = data
    setFormData(temp)
    setError(null)
  }


  const updateAddress = () => {
    apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/update-address', {
      id: formData.id,
      cryptoId: formData.asset._id,
      address: formData.address,
      nickName: formData.nickName,
    }, (data) => {
      props.loadAddress()
      props.onClose({
        ...props.openAddModalData,
        open: false
      })
      props.setAlertData('Successfully Edited Address')
      props.setShowAlert(true)
      setTimeout(() => {
        props.setShowAlert(true)
      }, 500)
      setTimeout(() => {
        props.setShowAlert(false)
      }, 4000)
    },
      (err) => {
        console.log('the error from API get address is ', err);
      })


  }

  const handleCrypto = () => {
    setOpenCryptoOption(!openCryptoOption);
  };

  useEffect(() => {
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, [])

  useEffect(() => {
    setCryptoWallet(props.coinsList)
  }, [props.coinsList]);

  useEffect(() => {
    let { data } = props.openAddModalData
    let { address, nickName, cryptoId, _id } = data
    setFormData({
      ...formData,
      address,
      nickName,
      asset: cryptoId,
      id: _id
    })
    setSelectedCrypto(cryptoId)
  }, [props.openAddModalData])



  const handleSelectCryptoOptions = (ele) => {
    setSelectedCrypto(ele);
    handleFormData(ele, 'asset')
    setOpenCryptoOption(false);
  };

  const onSubmitHandler = () => {
    if (!formData?.nickName || !formData?.address || !formData?.asset) {
      setError("Please enter missing fields");
      return;
    } else {
      setError("");
    }
    if (!formData.address || !formData.nickName) return
    updateAddress()
  }

  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog onClose={
        () => props.onClose({
          ...props.openAddModalData,
          open: false
        })} open={props.openAddModalData.open} className="b2c-signup-model">
        <div className="change-password-modal-header set-modal-width">
          <div className="d-flex justify-content-between ">
            <div className="change-password-heading">Edit Address</div>
            <div className="signup-close-icon">
              <ClearIcon onClick={() => props.onClose({
                ...props.openAddModalData,
                open: false
              })} className="icon-close" />
            </div>
          </div>
        </div>
        <div className="p-4 center-add-modal-container">
          <div>
            <div className="change-Password-input-label">Select Asset</div>
            <div className="mt-3">
              <div
                className={openCryptoOption ? 'kyc-seleted-options kyc-select-options cursor-pointer set-button-width' : 'kyc-select-options kyc-select cursor-pointer set-button-width'}
                onClick={() => handleCrypto()}
              >
                <div className="verify-onboard-sub-heading">
                  {selectedCrypto && selectedCrypto !== '' ? (
                    <div>
                      {' '}
                      <img src={`${image_base_url}${getCryptoIcon(selectedCrypto.symbol)}`} className="currencyImg" alt="bitcoin" height={30} width={30} />{' '}
                      <span className="ml-10">{selectedCrypto.symbol}/{selectedCrypto.name}</span>
                    </div>
                  ) : (
                    cryptoWallet &&
                    cryptoWallet.length > 0 && (
                      <div>
                        {' '}
                        <img src={`${image_base_url}${getCryptoIcon(cryptoWallet[0].symbol)}`} className="currencyImg" alt="bitcoin" height={30} width={30} />{' '}
                        <span className="ml-10">{cryptoWallet[0].name}</span>
                      </div>
                    )
                  )}
                </div>
                <div>{openCryptoOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
              </div>
              {openCryptoOption && (
                <div className="kyc-options-container verify-onboard-sub-heading set-button-width">
                  {cryptoWallet &&
                    cryptoWallet.length > 0 &&
                    cryptoWallet.map((ele, index) => {
                      return (
                        <div className="kyc-options cursor-pointer set-button-width" key={index}>
                          <div onClick={() => handleSelectCryptoOptions(ele)}>
                            <div>
                              {' '}
                              <img src={`${image_base_url}${getCryptoIcon(ele.symbol)}`} className="currencyImg" alt="bitcoin" data-e2e={index} height={30} width={30} />{' '}
                              <span className="ml-10">{ele.name}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="change-Password-input-label mt-4">NickName</div>
            <div className="mt-3">
              <input
                type='text'
                className="change-password-input set-button-width address-input-background"
                name="nickname"
                onChange={(e) => handleFormData(e.target.value, 'nickName')}
                value={formData.nickName}
                placeholder="Enter Name"
                data-e2e="edit-enter-name"
              />
            </div>
          </div>

          <div>
            <div className="change-Password-input-label mt-4">Address</div>
            <div className="mt-3">
              <input
                type="text"
                className="change-password-input set-button-width address-input-background  "
                name="address"
                onChange={(e) => handleFormData(e.target.value, 'address')}
                value={formData.address}
                placeholder={`Enter a ${selectedCrypto ? selectedCrypto.name : ''} Address`}
                data-e2e="edit-addres"
              />
            </div>
          </div>
          {error && <div className='mt-3 text-danger'>{error}</div>}
          <div className="mt-4 center-edit-submit-button">
            <button
              className='add-address-modal-button set-button-width'
              onClick={() => onSubmitHandler()}
              data-e2e="submit-btn"
              style={{ ...headerButtonStyle }}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default EditAddressModal