import React, { useEffect, useState } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';
import CodeCard from './CodeCard';
import SingleParameter from './SingleParameter';

const returns = [
    {
        name: "transactionID",
        description: "A unique transaction identifier that can be used as a reference in other API calls."
    },
    {
        name: "transactionURL",
        description: "The URL a user should be redirected to so they can authorise the payment."
    },
    {
        name: "status",
        description: "A status object with the results of the call."
    },
]

const tabSpace = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
const request = {
    type: "Request",
    method: "Post",
    url: ".../pga/CreatePayment",
    code: <div>{`{`}<br />
        {tabSpace}{`"merchantTransactionId": "string",`}<br />
        {tabSpace}{`"merchantUserId": "string",`}<br />
        {tabSpace}{`"merchantId": "string",`}<br />
        {tabSpace}{`"amount": "number",`}<br />
        {tabSpace}{`"currency": "string",`}<br />
        {tabSpace}{`"brand": "string",`}<br />
        {tabSpace}{`"useBrand": "string",`}<br />
        {tabSpace}{`"network": "string",`}<br />
        {tabSpace}{`"cryptoCurrency": "string",`}<br />
        {tabSpace}{`"mode": "string",`}<br />
        {tabSpace}{`"method": "string",`}<br />
        {tabSpace}{`"quickDeposit": "boolean",`}<br />
        {tabSpace}{`"returnToMerchantUrl": "string",`}<br />
        {tabSpace}{`"firstName": "string",`}<br />
        {tabSpace}{`"lastName": "string",`}<br />
        {tabSpace}{`"billingAddress1": "string",`}<br />
        {tabSpace}{`"billingCity": "string",`}<br />
        {tabSpace}{`"billingPostcode": "string",`}<br />
        {tabSpace}{`"billingState": "string",`}<br />
        {tabSpace}{`"billingCountry": "string",`}<br />
        {`}`}</div>,
}

const response = {
    type: "Return",
    method: "Post",
    url: ".../pga/CreatePayment",
    code: <div>{`{`}<br />
        {tabSpace}{`"transactionID": "string",`}<br />
        {tabSpace}{`"transactionURL": "string",`}<br />
        {tabSpace}{`"status": {`}<br />
        {tabSpace}{tabSpace}{`"code": "string",`}<br />
        {tabSpace}{tabSpace}{`"message": "string",`}<br />
        {tabSpace}{`}`}<br />
        {`}`}</div>,
}

const CreatePayment = (props) => {
    const [paymentProviderName, setPaymentProviderName] = useState(null)
    useEffect(() => {
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
        }
    }, []);

    const parameters = [
        {
            name: "merchantTransactionId",
            description: "A Unique ID to identify the transaction."
        },
        {
            name: "merchantUserId",
            description: "A unique ID to identify the user."
        },
        {
            name: "merchantId",
            description: "A unique ID of the merchant."
        },
        {
            name: "merchantUserEmail",
            description: "A merchant customer email"
        },
        {
            name: "amount",
            description: "A positive integer in cents representing how much the user wishes to deposit. e.g. 100 EUR would be 10000 / 50 USD would be 5000"
        },
        {
            name: "currency",
            description: "The ISO 3 letter currency code in lowercase, see the supported currency list for details."
        },
        {
            name: "brand",
            description: "An optional string that can be used to send identifying information for the merchant to allow grouping "
        },
        {
            name: "useBrand",
            description: `An optional string that when set to true will show the user the contents of the brand string in place of the merchant’s name registered with ${paymentProviderName ? paymentProviderName : "Crypton Pay"}.`
        },
        {
            name: "network",
            description: "The blockchain network upon which the transaction will take place e.g. mainnet, testnet, goerli. Defaults to mainnet on production and testnet on test servers. "
        },
        {
            name: "cryptoCurrency",
            description: "An optional parameter which indicate selected crypto currency in which we are doing the transaction e.g. BTC, LTC, ETH, XRP."
        },
        {
            name: "mode",
            description: "An optional parameter which indicate that background will be light or dark "
        },
        {
            name: "method",
            description: "An optional parameter which indicate method for transaction  for e.g. wallet, other, card.  "
        },
        {
            name: "quickDeposit",
            description: "An optional and boolean value which defined that transaction happend with quick link or not."
        },
        {
            name: "returnToMerchantUrl",
            description: "An optional parameter used for redirecting to merchant user."
        },
        {
            name: "firstName",
            description: "An optional parameter which indicate user first name. "
        },
        {
            name: "lastName",
            description: "An optional parameter which indicate user last name. "
        },
        {
            name: "billingAddress1",
            description: "An optional parameter which indicate user address. "
        },
        {
            name: "billingCity",
            description: "An optional parameter which indicate user city."
        },
        {
            name: "billingPostcode",
            description: "An optional parameter which indicate user post code."
        },
        {
            name: "billingState",
            description: "An optional parameter which indicate user state."
        },
        {
            name: "billingCountry",
            description: "An optional parameter which indicate user country."
        },
    ]
    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <div className="tool_main">
                        <div className="tool_heading" data-e2e="Create Payment">Create Payment</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    Create payment allows a merchant to initiate a deposit and will return a redirect URL to be provided to the user to complete the payment
                                </div>
                            </div>
                            <div className="tools_right_part">
                            </div>
                        </div>
                        <div className="tools_content_wrap mt-0">
                            <div className="tools_left_part">
                                <div className="tools_sub_heading">Parameters</div>
                                {parameters.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <CodeCard data={request} />
                            </div>
                        </div>
                        <div className="tools_content_wrap tools_content_wrap_margin">
                            <div className="tools_left_part">
                                <div className="tools_sub_heading">Returns</div>
                                {returns.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <CodeCard data={response} />
                            </div>
                        </div>
                    </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout>
    )
}

export default CreatePayment