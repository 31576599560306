import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";

export default function PaysuWho() {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section grey-background">
			<div className="content-container">
				<div className="ps-section-grid align-center order-reversed-mobile">
					<img loading="lazy" className="ps-section-img" src={`${image_base_url}/paysu/ps-we-are-2.webp`} />
					<div>
						<div className="ps-section-title-line"></div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="ps-section-title mb">Who We Are?</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Based in Europe with our roots and support teams in Asia, our team of multi-skilled specialists in payments finance and technology is on hand to support your business. As a bold and exciting company, we’re always at the forefront of technological innovation and the latest trends, ensuring the evolving needs of our clients are met.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							<p className="ps-section-text">PaySu is dedicated to helping businesses maximise opportunities, providing them with the tools and support they need to grow and succeed. We understand the unique challenges that businesses face today, and our services are designed to help our clients overcome those challenges.</p>
						</AnimationOnScroll>
					</div>
				</div>
			</div>
		</section>
	)
}