import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: require('./en/translate.json') },
        ja: { translation: require('./ja/translate.json') },
        ru: { translation: require('./ru/translate.json') },
        po: { translation: require('./po/translate.json') },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;