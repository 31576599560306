import React from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';
import CodeCard from './CodeCard';
import SingleParameter from './SingleParameter';

const crypto = [
    {
        name: "transactionId",
        description: "A unique transaction identifier that can be used as a reference in other API calls."
    },
    {
        name: "merchantTransactionId",
        description: "A unique merchant transaction identifier"
    },
    {
        name: "merchantUserId",
        description: "A unique ID to identify the user"
    },
    {
        name: "date",
        description: "The date and time the transaction was created in ISO8601 format"
    },
    {
        name: "amount",
        description: "A positive integer in cents representing the value of the transaction"
    },
    {
        name: "currency",
        description: "The currency of amount."
    },
    {
        name: "type",
        description: "Returns DEPOSIT, placeholder for future functions"
    },
    {
        name: "fee",
        description: "The fee chargeable to the merchant for this transaction in cents."
    },
    {
        name: "status",
        description: "A status object with the status of the transaction."
    },
    {
        name: "message",
        description: "The transaction status message."
    }
]    

const cards = [
    {
        name: "transactionId",
        description: "A unique transaction identifier that can be used as a reference in other API calls."
    },
    {
        name: "merchantTransactionId",
        description: "A unique merchant transaction identifier"
    },
    {
        name: "merchantUserId",
        description: "A unique ID to identify the user"
    },
    {
        name: "date",
        description: "The date and time the transaction was created in ISO8601 format"
    },
    {
        name: "amount",
        description: "A positive integer in cents representing the value of the transaction"
    },
    {
        name: "currency",
        description: "The currency of amount."
    },
    {
        name: "type",
        description: "Returns DEPOSIT, placeholder for future functions"
    },
    {
        name: "fee",
        description: "The fee chargeable to the merchant for this transaction in cents."
    },
    {
        name: "status",
        description: "A status object with the status of the transaction."
    },
    {
        name: "message",
        description: "The transaction status message."
    },
    {
        name: "issuer",
        description: "The customer’s card issuer."
    },
    {
        name: "paymenttypedescription",
        description: "A type of card for e.g. VISA, MASTERCARD"
    },
    {
        name: "eci",
        description: "The ECI (E-Commerce Indicator) security level associated with the transaction."
    },
    {
        name: "accounttypedescription",
        description: "ECOM (e-commerce) is returned in the response."
    },
    {
        name: "acquirerresponsecode",
        description: "Contains information regarding errors returned during the 3-D Secure process."
    },
    {
        name: "requesttypedescription",
        description: "Contains information regarding errors returned during the 3-D Secure process."
    },
    {
        name: "securityresponsesecuritycode",
        description: "Status of security code response entered by customer "
    },
    {
        name: "authcode",
        description: "The authorisation code provided by the issuing bank. This will differ depending on which bank you use."
    },
    {
        name: "errormessage",
        description: `This provides a brief explanation as to the cause of the error.
        For successful transactions, this is returned as “Ok”.`
    },
    {
        name: "securityresponsepostcode",
        description: ""
    },
    {
        name: "maskedpan",
        description: "The maskedpan field represents the customer’s card"
    },
    {
        name: "securityresponseaddress",
        description: "The result of AVS and Security Code Checks."
    },
    {
        name: "issuercountryiso2a",
        description: "The country for the customer’s card issuer.This will be in ISO2A format."
    }

]
const tabSpace = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

const requestCrypto = {
    type: "Webhooks",
    method: "",
    url: "",
    code: <div>{`{`}<br />
        {tabSpace}{`"transactionId": "string",`}<br />
        {tabSpace}{`"merchantTransactionId": "string",`}<br />
        {tabSpace}{`"merchantUserId": "string",`}<br />
        {tabSpace}{`"date": "string",`}<br />
        {tabSpace}{`"amount": "number",`}<br />
        {tabSpace}{`"currency": "string",`}<br />
        {tabSpace}{`"fee": "number",`}<br />
        {tabSpace}{`"type": "string",`}<br />
        {tabSpace}{`"status": {`}<br />
        {tabSpace}{tabSpace}{`"code": "string",`}<br />
        {tabSpace}{tabSpace}{`"message": "string",`}<br />
        {tabSpace}{`}`}<br />
        {`}`}</div>,
}
const requestCards = {
    type: "Webhooks",
    method: "",
    url: "",
    code: <div>{`{`}<br />
        {tabSpace}{`"transactionId": "string",`}<br />
        {tabSpace}{`"merchantTransactionId": "string",`}<br />
        {tabSpace}{`"merchantUserId": "string",`}<br />
        {tabSpace}{`"date": "string",`}<br />
        {tabSpace}{`"amount": "number",`}<br />
        {tabSpace}{`"currency": "string",`}<br />
        {tabSpace}{`"fee": "number",`}<br />
        {tabSpace}{`"type": "string",`}<br />
        {tabSpace}{`"status": {`}<br />
        {tabSpace}{tabSpace}{`"code": "string",`}<br />
        {tabSpace}{tabSpace}{`"message": "string",`}<br />
        {tabSpace}{`}`}<br />
        {tabSpace}{`"issuer": "string",`}<br />
        {tabSpace}{`"paymenttypedescription": "string",`}<br />
        {tabSpace}{`"eci": "string",`}<br />
        {tabSpace}{`"accounttypedescription": "string",`}<br />
        {tabSpace}{`"acquirerresponsecode": "string",`}<br />
        {tabSpace}{`"requesttypedescription": "string",`}<br />
        {tabSpace}{`"securityresponsesecuritycode": "string",`}<br />
        {tabSpace}{`"authcode": "string",`}<br />
        {tabSpace}{`"errormessage": "string",`}<br />
        {tabSpace}{`"securityresponsepostcode": "string",`}<br />
        {tabSpace}{`"maskedpan": "string",`}<br />
        {tabSpace}{`"securityresponseaddress": "string",`}<br />
        {tabSpace}{`"issuercountryiso2a": "string",`}<br />
        {`}`}</div>,
}

const Webhooks = (props) => {
    // const parameters = [...cards,...crypto]
    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <div className="tool_main">
                        <div className="tool_heading" data-e2e="webHooks">WEBHOOKS</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    After successful payment merchant gets a callback to a configured URL when the transaction is finalised.
                                </div>
                            </div>
                            <div className="tools_right_part">
                            </div>
                        </div>

                        <div className="tools_content_wrap mt-0">
                            <div className="tools_left_part">
                            <div className="tools_sub_heading">Crypto</div>
                                {crypto.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>

                            <div className="tools_right_part">
                                <CodeCard data={requestCrypto} marginTopZero={true} />
                            </div>
                        </div>
                        <div className="tools_content_wrap tools_content_wrap_margin" >
                        <div className="tools_left_part">
                            <div className="tools_sub_heading">Card</div>
                                {cards.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                            <CodeCard data={requestCards} marginTopZero={true} />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout>
    )
}

export default Webhooks