import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { apiService } from '../../../common/apiCallService';
import { useSelector } from "react-redux";
import queryString from 'query-string';
import { useState } from 'react';


const CardResponseLoading = (props) => {

    const [payFlag, setPayFlag] = useState(true);
   const navigate = useNavigate();
   const payment_api_url = useSelector((state) => state.config.payment_api_url);
   const api_url = useSelector((state) => state.config.api_url);

    useEffect(() => {
        if (queryString.parse(props.location.search).chargeId && queryString.parse(props.location.search).transactionid) {
            const query = { 
                transactionId:queryString.parse(props.location.search).transactionid, 
                chargeId :queryString.parse(props.location.search).chargeId
            };
            let url = (window.location.hostname === 'localhost' ? payment_api_url : '') + '/pga/getPayDotComConfirmation';
            if(queryString.parse(props.location.search).api &&  queryString.parse(props.location.search).api == "true"){
                url = (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-payDotCom-confirmation';
            }
            apiService(
                url,
                query,
                (data) => {
                    if (data) {
                        setPayFlag(false);
                        navigate(data)
                    }
                })
        }
        
    }, [])

    return (
         <div>
            {payFlag &&<div className='loading_pay_main row' >
                <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
            </div>}

        </div>
    )
}

export default (props) => <CardResponseLoading {...props} location={useLocation()} />;