import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import { getCryptoIcon } from "../../../common/getCryptoIcon";
import * as actions from '../../../Redux/actions/index';
import { withTranslation } from 'react-i18next';
import NavbarLang from '../../../../locale/NavbarLang';
let timeInterval = null;
let timeInterval2 = null;
const darkToggle = "/common/crytoicons/dark_toggle_icon2x.png";
const lightToggle = "/common/crytoicons/light_toggle_icon2x.png";
const back_icon = "/common/crytoicons/back_icon.png";
const back_icon_light = "/common/crytoicons/back_icon_light.png";
const suggestedCryptos = ["ETH", "BTC", "BTH"]

class SelectCryptopayWalletToDeposit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listOfCryptoToShow: [],
            allCryptosList: [],
            transactionId: null,
            lightFlag: false,
            selectedCrypto: null,
            searchCryptoText: "",
            isCryptoDropdownOpen: false,
            cryptoEquUSDAmount: 0,
            cryptoAmount: 0,
            limitError: "",
            createError: "",
            showAddFunds: false,
            localLoading: false,
            exchangeRate: null,
        }
    }

    validateAmount = (value) => {
        if (value <= 0) {
            this.setLimitError("Amount must be greater than zero");
        } else {
            this.setLimitError("");
        }
    }

    setLimitError = (value) => {
        this.setState({ limitError: value })
    }
    setCryptoAmount = (value) => {
        this.setState({ cryptoAmount: value })
    }
    setCryptoEquUSDAmount = (value) => {
        this.setState({ cryptoEquUSDAmount: value })
    }

    getUserBalances = async () => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("user_name") && localStorage.getItem("user_name") !== "") {
                this.setState({ localLoading: true });
                let UserEmail;
                let domainNameUrl;
                if (localStorage.getItem('user_name')) {
                    const emailDomain = localStorage.getItem('user_name');
                    if (emailDomain.includes('|')) {
                      UserEmail = emailDomain.split('|')[0];
                      domainNameUrl = emailDomain.split('|')[1];
                    } else {
                      UserEmail = localStorage.getItem('user_name');
                    }
                  }
                apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-user-wallet-balances', {
                    user_email: UserEmail,
                    fiatCurrency: this.props.transaction.fiatCurrency,
                    domainNameUrl: localStorage.getItem("domainNameUrl")|| domainNameUrl,
                },
                    (data) => {
                        this.setState({ localLoading: false });
                        if (data) {
                            if (data.balanceList) {
                                resolve(data.balanceList)
                            }
                        } else {
                            reject([])
                        }
                    }, (err) => {
                        this.setState({ localLoading: false });
                        console.log(err);
                        reject([])
                    },
                true
                )
            }
        })
    }

    getSingleCryptoExchangedRate = async (amount, cryptoCurrency, fiatCurrency) => {
        return new Promise(async (resolve, reject) => {
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetSingleCryptoExchangeRate',
                {
                    amount, cryptoCurrency, fiatCurrency,
                    transactionId: localStorage.getItem("transactionId"),
                    merchantId: localStorage.getItem("depositMerchantId"),
                    deductDepositSpread: true,
                },
                (data) => {
                    resolve(data);
                }, (err) => {
                    console.log(err.message, 'err');
                })
        })
    };

    onCryptoAmountChange = async (value) => {
        const selectedToken = this.state.selectedCrypto ? this.state.selectedCrypto.symbol : null;
        if (value) {
            this.setCryptoAmount(value);
        } else if (value === 0) {
            this.setCryptoAmount(0);
        } else {
            this.setCryptoAmount(null);
        }
        const result = await this.getSingleCryptoExchangedRate(1, selectedToken, this.state.selectedCrypto.fiatCurrency);
        this.setState({ exchangeRate: result.cryptoAmounts[selectedToken] })
        if (value && result && result.cryptoAmounts) {
            const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(2)
            this.validateAmount(value)
            this.setCryptoEquUSDAmount(usdEquAmt);
        } else {
            this.validateAmount(0)
            this.setCryptoEquUSDAmount(0);
        }
    };
    onCryptoEquUSDAmountChange = async (value) => {
        const selectedToken = this.state.selectedCrypto ? this.state.selectedCrypto.symbol : null;
        if (value) {
            this.setCryptoEquUSDAmount(value);
        } else if (value === 0) {
            this.setCryptoEquUSDAmount(0);
        } else {
            this.setCryptoEquUSDAmount(undefined);
        }
        const result = await this.getSingleCryptoExchangedRate(value, selectedToken, this.state.selectedCrypto.fiatCurrency);
        this.setState({ exchangeRate: result.cryptoAmounts[selectedToken] / value })
        if (value && result && result.cryptoAmounts) {
            this.setCryptoAmount(result.cryptoAmounts[selectedToken])
            this.validateAmount(result.cryptoAmounts[selectedToken])
        } else {
            this.setCryptoAmount(0);
            this.validateAmount(0)
        }
    };

    componentDidMount = async () => {
        if (localStorage.getItem("token") && (localStorage.getItem("loginType") === "B2C")) { }
        else {
            if (
                window.location.pathname.indexOf('/cryptoToDeposit') === -1 &&
                window.location.pathname !== '/'
            ) {
                this.props.navigate('/cryptoToDeposit');
            }
        }
        if (sessionStorage.getItem("lightFlag")) {
            this.setState({ lightFlag: (sessionStorage.getItem("lightFlag") === "true") })
        } else {
            sessionStorage.setItem("lightFlag", false);
        }
        if (queryString.parse(this.props.location.search).uniqueId) {
            localStorage.setItem("uniqueId", queryString.parse(this.props.location.search).uniqueId);
        }

        if (localStorage.getItem("transactionId") && localStorage.getItem("depositMerchantId") && localStorage.getItem("activity_tracking_token")) {
            try {
                let transaction = await this.getTransaction()
                this.setState({ transactionId: localStorage.getItem("transactionId") })
                if (localStorage.getItem("receivedCryptoCurrency") !== undefined && localStorage.getItem('receivedCryptoCurrency') !== '') {
                    this.state.allCryptosList.filter((CryptoObj, i) => {
                        if (CryptoObj.symbol === localStorage.getItem("receivedCryptoCurrency")) {
                            this.setState({ selectedCrypto: CryptoObj })
                            let data = {
                                ...CryptoObj,
                                fiatAmount: `${this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}${CryptoObj.fiatAmount} `,
                                fiatCurrency: this.props.transaction.fiatCurrency,
                                fiatAmountValue: CryptoObj.fiatAmount
                            }
                            this.onCardClick(data);
                        } else {
                            return null;
                        }
                    })
                }
                timeInterval = setInterval(async () => {
                    if (transaction && this.state.selectedCrypto && this.state.cryptoEquUSDAmount) {
                        this.componentDidCatchonCryptoEquUSDAmountChange(this.state.cryptoEquUSDAmount)
                    }
                }, 60000)
                if (!transaction) {
                    alert("Transaction not found! Try again with correct URL....")
                    apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                        transactionId: null,
                        merchantId: null,
                        component: "DepositDialog",
                        severity: null,
                        errorCode: "406",
                        errorMessage: "Landing Error: Transaction not found! Try again with correct URL.",
                        postMvpComment: null,
                    }, (data) => {
                        // console.log(data)
                    }, (err) => {
                        console.log("err:", err)
                    })
                }
            } catch (err) {
                console.log(err)
            }
        } else {
            alert("TransactionId/MerchantId/ActivityToken is missing! Try again with correct URL.")
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                transactionId: null,
                merchantId: null,
                component: "DepositDialog",
                severity: null,
                errorCode: "406",
                errorMessage: "Landing Error: TransactionId/MerchantId/ActivityToken is missing! Try again with correct URL.",
                postMvpComment: null,
            }, (data) => {
                // console.log(data)
            }, (err) => {
                console.log("err:", err)
            })
        }
        var content = document.querySelector('.deposit_card_body_cryptos');
        if (content) {
            content.addEventListener('scroll', function () {
                if (content.scrollTop !== 0) {
                    content.style.boxShadow = "inset 0px 6px 12px -6px rgba(0,0,0,0.5)";
                } else {
                    content.style.boxShadow = "none";
                }
            });
        }
        timeInterval2 = setInterval(() => {
            let value = this.state.cryptoAmount;
            this.onCryptoAmountChange(value);
        }, 30000)
    }

    componentWillUnmount() {
        clearInterval(timeInterval)
        clearInterval(timeInterval2)
    }

    getTransaction = () => {

        return new Promise(async (resolve, reject) => {
            const transactionId = localStorage.getItem("transactionId");
            const merchantId = localStorage.getItem("depositMerchantId");
            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetTransactionDetails', {
                transactionId,
                merchantId,
                hostname: window.location.hostname
            }, async (data) => {
                if (data) {
                    if (data.merchantName) {
                        localStorage.setItem("depositMerchantName", data.merchantName);
                    }
                    this.props.setTransaction(data);
                    setTimeout(async () => {
                        let listOfAcceptableCurrencies = await this.getUserBalances();
                        listOfAcceptableCurrencies = listOfAcceptableCurrencies.filter(cr => cr.walletType === "user")
                        if (listOfAcceptableCurrencies && listOfAcceptableCurrencies.length > 0) {
                            const listToShow = listOfAcceptableCurrencies.map(currencyObj => {
                                return {
                                    name: currencyObj.cryptoName,
                                    img: getCryptoIcon(currencyObj.coin),
                                    symbol: currencyObj.coin,
                                    address: currencyObj.address ? currencyObj.address : null,
                                    walletId: currencyObj.walletId ? currencyObj.walletId : null,
                                    network: currencyObj.network ? currencyObj.network : null,
                                    cryptocurrencyAmount: currencyObj.amount ? currencyObj.amount : "0",
                                    fiatAmount: currencyObj.USDAmount,
                                }
                            });
                            this.setState({
                                allCryptosList: listToShow,
                                listOfCryptoToShow: listToShow
                            });
                        }
                    }, 0)
                    resolve(data)
                }
            }, (err) => {
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId,
                    merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "GetTransactionDetails API Error: " + err.message : "Error in GetTransactionDetails API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                resolve(false)
            },
            true
        )
        })
    }

    updateTransactionMethod = (method='wallet') => {
        const transactionId = localStorage.getItem("transactionId");
        const merchantId = localStorage.getItem("depositMerchantId");
        return new Promise(async (resolve, reject) => {
            await apiService(
              (window.location.hostname === 'localhost' ? this.props.payment_api_url : '') + '/pga/updateTransactionMethod',
              {
                transactionId,
                merchantId,
                depositMethod: method,
              },
              async (data) => {
                if (data) {
                  resolve(data);
                }
              },
              (err) => {
                apiService(
                  (window.location.hostname === 'localhost' ? this.props.payment_api_url : '') + '/pga/saveErrorLog',
                  {
                    transactionId,
                    merchantId,
                    component: 'DepositDialog',
                    severity: null,
                    errorCode: err.code ? err.code : '406',
                    errorMessage: err.message ? 'updateTransactionMethod API Error: ' + err.message : 'Error in updateTransactionMethod API',
                    postMvpComment: null,
                  },
                  (data) => {
                    // console.log(data)
                  },
                  (err) => {
                    console.log('err:', err);
                  },
                );
                resolve(false);
              },
              true,
            );
        })
    }

    onCardClick = async (cryptoData) => {
        if (timeInterval) {
            clearInterval(timeInterval)
        }
        this.setState({
            selectedCrypto: cryptoData, searchCryptoText: "", isCryptoDropdownOpen: false, listOfCryptoToShow: this.state.allCryptosList,
            showAddFunds: this.props.transaction.depositAmount > cryptoData.fiatAmountValue ? true : false,
        }, () => {
            this.onCryptoEquUSDAmountChange(this.props.transaction.depositAmount)
        })
        await this.updateTransactionMethod()
    }

    getFiatCurrencySymbol = (fiatCurrency) => {
        switch (fiatCurrency) {
            case "USD":
                return "$";
            case "GBP":
                return "£";
            case "EUR":
                return "€";
            case "RUB":
                return "р.";
            case "JPY":
                return "¥";
            default:
                return fiatCurrency
        }
    }
    handleToggle = () => {
        sessionStorage.setItem("lightFlag", !this.state.lightFlag);
        this.setState({ lightFlag: !this.state.lightFlag })
    }
    redirectToHome = () => {
        window.open("/")
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value && e.target.value !== "") {
            let newList = this.state.allCryptosList.filter((cryptoObj) => {
                return (cryptoObj.name.toLowerCase().includes(e.target.value.toLowerCase()) || cryptoObj.symbol.toLowerCase().includes(e.target.value.toLowerCase()))
            });
            this.setState({ listOfCryptoToShow: newList })
        } else {
            this.setState({ listOfCryptoToShow: this.state.allCryptosList })
        }
    }

    createSendTransaction = async () => {
        const merchantId = localStorage.getItem("depositMerchantId");
        let UserEmail;
        let domainNameUrl;
        if (localStorage.getItem('user_name')) {
          const emailDomain = localStorage.getItem('user_name');
          if (emailDomain.includes('|')) {
            UserEmail = emailDomain.split('|')[0];
            domainNameUrl = emailDomain.split('|')[1];
          } else {
            UserEmail = localStorage.getItem('user_name');
          }
        }
        let payload = {
            email: UserEmail,
            user_email: UserEmail,
            network: this.state.selectedCrypto.network,
            createdBy: UserEmail,
            exchangeRate: this.state.exchangeRate,
            fromWalletId: this.state.selectedCrypto.walletId,
            fromWalletAddress: this.state.selectedCrypto.address,
            cryptoId: this.state.selectedCrypto.symbol ? this.state.selectedCrypto.symbol : this.state.selectedCrypto.coin,
            cryptoAmount: this.state.cryptoAmount,
            merchantId,
            fiatCurrency: this.state.selectedCrypto.fiatCurrency,
            transactionId: localStorage.getItem("transactionId"),
            depositAmount: this.state.cryptoEquUSDAmount,
            message: "Send-Deposit initiated by: " + UserEmail,
            domainNameUrl: localStorage.getItem("domainNameUrl") || domainNameUrl,
        };
        this.setState({ localLoading: true });
        await apiService(
          (window.location.hostname === 'localhost' ? this.props.payment_api_url : '') + '/pga/CreateSendTransaction',
          payload,
          async (res) => {
            this.setState({ localLoading: false });
            if (res.status.code === 'DepositFlowViaWallet:DepositCompleted') {
              this.props.navigate('/depositSuccessful');
            } else {
              this.setState({ createError: 'Somthing went wrong! Try again later.' });
            }
          },
          (err) => {
            this.setState({ localLoading: false });
            console.error(err?.message, 'err');
          },
          true,
        );
    }

    render() {
        const listOfSuggestions = this.state.allCryptosList.filter((crypto) => {
            return suggestedCryptos.includes(crypto.symbol)
        })
        const { t } = this.props
        const features = [{ name: "Processing Time", value: "Instant" }]

        if (this.state.selectedCrypto) {
            return (<div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                <div className="deposit_screen_wrap">
                    <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"}>
                        {/* <TopHeading /> */}
                        <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                            <img className='cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                if (localStorage.getItem("methodSet") == "true") {
                                    return
                                }
                                this.setState({ selectedCrypto: null })
                            }} />
                            <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                        </div>
                        <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding paddingAndMinHeight"} >
                            <span className={this.state.lightFlag ? "text_color_black" : ""} data-e2e="send-deposit-btn">Send Deposit</span>
                        </div>
                        <div className="send_deposit_content" >
                            <div className="headingTitle leftAlignText">From</div>
                            <div style={{ position: 'relative' }}>
                                <div className={`cryptoDropdownSelector ${this.state.lightFlag ? "cryptoDropdownSelectorLight" : ""}`} onClick={() => {
                                    this.setState({ isCryptoDropdownOpen: true });
                                }}>
                                    <img className="cryptoImg" alt="cryptoicon" src={`${this.props.image_base_url}${this.state.selectedCrypto.img}`} />
                                    <div className="cryptoDetailsWrap">
                                        <div className="row1wrap">
                                            <div>{this.state.selectedCrypto.name}</div>
                                            <div>{this.state.selectedCrypto.fiatAmount}</div>
                                        </div>
                                        <div className="row2wrap">
                                            <div>{this.state.selectedCrypto.symbol}</div>
                                            <div>{this.state.selectedCrypto.cryptocurrencyAmount}</div>
                                        </div>
                                    </div>
                                    <i className={`fa fa-angle-down dropdoenIcon`} />
                                </div>
                                {this.state.isCryptoDropdownOpen && <div className={`cryptoDropdown ${this.state.lightFlag ? "cryptoDropdownLight" : ""}`}>
                                    <div className={`cryptoDropdownSelector ${this.state.lightFlag ? "cryptoDropdownSelectorLight" : ""}`} onClick={() => {
                                        this.setState({ isCryptoDropdownOpen: false });
                                    }}>
                                        <img className="cryptoImg" alt="cryptoicon" src={`${this.props.image_base_url}${this.state.selectedCrypto.img}`} />
                                        <div className="cryptoDetailsWrap">
                                            <div className="row1wrap">
                                                <div>{this.state.selectedCrypto.name}</div>
                                                <div>{""}</div>
                                            </div>
                                            <div className="row2wrap">
                                                <div>{this.state.selectedCrypto.symbol}</div>
                                                <div>{""}</div>
                                            </div>
                                        </div>
                                        <i className={`fa fa-angle-up dropdoenIcon`} />
                                    </div>
                                    <div className={`searchBar ${this.state.lightFlag ? "searchBarLight" : ""}`}>
                                        <i className={`fa fa-search searchIcon ${this.state.lightFlag ? "searchIconLight" : ""}`} />
                                        <input type="text" value={this.state.searchCryptoText} data-e2e="search-box" name="searchCryptoText" className={`searchInput ${this.state.lightFlag ? "searchInputLight" : ""}`} onChange={(e) => {
                                            this.handleChange(e)
                                        }} placeholder="Search by name" />
                                    </div>
                                    <div className="deposit_card_body_cryptos deposit_card_body_cryptosExpanded">
                                        <div className="deposit_content_wrap">
                                            <div className="deposit_content_column_heading">
                                                <div className={this.state.lightFlag ? "text_color_black token-name " : " token-name "}>NAME</div>
                                                <div className={this.state.lightFlag ? "text_color_black token-name " : " token-name "}>AVAILABLE</div>
                                            </div>
                                            {this.state.listOfCryptoToShow.map((CryptoObj, i) => {
                                                if (CryptoObj) {
                                                    return (<SingleCryptoCard
                                                        image_base_url={this.props.image_base_url}
                                                        lightFlag={this.state.lightFlag}
                                                        key={i} index={i} data={{
                                                            ...CryptoObj,
                                                            description: ` ${CryptoObj.cryptocurrencyAmount ? CryptoObj.cryptocurrencyAmount.toString().substring(0, 11) : "0"}${CryptoObj.cryptocurrencyAmount > 0 ? (CryptoObj.cryptocurrencyAmount.toString().length > 11 ? ".." : 0) : ""}`,
                                                            fiatAmount: `${this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}${CryptoObj.fiatAmount} `,
                                                            fiatCurrency: this.props.transaction.fiatCurrency,
                                                            fiatAmountValue: CryptoObj.fiatAmount
                                                        }} onCardClick={this.onCardClick} />)
                                                } else {
                                                    return null;
                                                }
                                            })}
                                            {!this.props.loading && this.state.listOfCryptoToShow.length === 0 && (this.state.searchCryptoText && this.state.searchCryptoText !== "") && <div className={this.state.lightFlag ? "text_color_black" : "text_color_white"} style={{ opacity: 0.5 }}>No token match with the current search.</div>}
                                            {!this.props.loading && this.state.listOfCryptoToShow.length === 0 && this.state.searchCryptoText === "" && <div className={this.state.lightFlag ? "text_color_black" : "text_color_white"} style={{ opacity: 0.5 }}>Merchant acceptable crypto list is empty!</div>}
                                        </div>
                                    </div>
                                </div>}
                            </div>

                            <div className="headingTitle leftAlignText">Available({this.getFiatCurrencySymbol(this.state.selectedCrypto.fiatCurrency) + this.state.selectedCrypto.fiatAmountValue})</div>
                            <div className="amountExchageWrap">
                                <input
                                    type="text"
                                    value={this.state.cryptoEquUSDAmount >= 0 ? this.state.cryptoAmount : null}
                                    className={`disableCursor form-control inputdropdown ${this.state.lightFlag ? "text_color_black inputdropdownLight" : ""}`}
                                    id="cryptoAmount"
                                    placeholder={`amount in ${this.state.selectedCrypto.name}`}
                                    name="cryptoAmount"
                                    disabled
                                    onChange={(e) => {
                                        // this.onCryptoAmountChange(e.target.value);
                                    }}
                                />
                                <div className={`exchangeIcon ${this.state.lightFlag ? "text_color_black" : ""}`}><i className="fa fa-exchange"></i></div>
                                <input
                                    type="text"
                                    value={this.state.cryptoAmount >= 0 ? this.getFiatCurrencySymbol(this.state.selectedCrypto.fiatCurrency) + (this.state.cryptoEquUSDAmount || this.state.cryptoEquUSDAmount == "0" ? this.state.cryptoEquUSDAmount : "") : null}
                                    className={`disableCursor form-control inputdropdown ${this.state.lightFlag ? "text_color_black inputdropdownLight" : ""}`}
                                    id="cryptoEquUSDAmount"
                                    placeholder={`amount in ${this.state.selectedCrypto.fiatCurrency}`}
                                    name="cryptoEquUSDAmount"
                                    disabled
                                    onChange={(e) => {
                                        // this.onCryptoEquUSDAmountChange(e.target.value.trim().replace(this.getFiatCurrencySymbol(this.state.selectedCrypto.fiatCurrency), ""));
                                    }}
                                />
                            </div>
                            {!this.state.showAddFunds && <div className={`divider ${this.state.lightFlag ? "divider_light" : ""}`} />}
                            {!this.state.showAddFunds && features.map((feature, i) => {
                                return (<div key={i} className="featureWrap">
                                    <div className={`featureTitle ${this.state.lightFlag ? "text_color_black" : ""}`}>
                                        {feature.name}
                                    </div>
                                    <div className={`featureValue`}>
                                        {feature.value}
                                    </div>
                                </div>)
                            })}
                            {this.state.createError != "" && <div style={{ color: "red" }}>{this.state.createError}</div>}
                            {this.state.showAddFunds && <div className="addFundsWarning">
                                <i className="fa fa-exclamation-circle warIcon" /><span>Please, add funds to your {this.state.selectedCrypto.name} wallet first.</span>
                            </div>}
                            {this.state.showAddFunds ? <div className="sendDepositOrAddFundsButton" onClick={() => {
                                alert("add funds flow");
                            }}>ADD FUNDS</div> : this.state.localLoading === false ? <div className="sendDepositOrAddFundsButton" onClick={() => {
                                this.setState({ createError: "" })
                                this.createSendTransaction();
                            }} data-e2e="send-deposit-btn">SEND DEPOSIT</div> : <div className="sendDepositOrAddFundsButton" style={{ backgroundColor: "grey" }} data-e2e="send-deposit-btn">SEND DEPOSIT</div>}
                        </div>
                    </div>
                </div>
            </div>)
        } else {
            return (
                <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                    <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                    <div className="deposit_screen_wrap">
                        <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"}>
                            {/* <TopHeading /> */}
                            <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                                <img className='cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                    if (localStorage.getItem("methodSet") == "true") {
                                        return
                                    }
                                    this.updateTransactionMethod(null)
                                    localStorage.setItem("depositMethod", null)
                                    this.props.navigate("/cryptoToDeposit")
                                }} />
                                <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding paddingAndMinHeight"} >
                                    <span className={this.state.lightFlag ? "text_color_black" : ""}>{t('Select Crypto')}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <NavbarLang />
                                    <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                                </div>
                            </div>
                            <div className="deposit_card_body pb-0">
                                <div className="deposit_content_wrap">
                                    <div className={this.state.lightFlag ? "text_color_black select-how-you-would select-margin-fix" : " select-how-you-would select-margin-fix"}>{t('Payment_method')}.</div>
                                </div>
                            </div>

                            <div className={`searchBoxWrap ${this.state.lightFlag ? "searchBoxWrapLight" : ""}`}>
                                <div className={`searchBar ${this.state.lightFlag ? "searchBarLight" : ""}`}>
                                    <i className={`fa fa-search searchIcon ${this.state.lightFlag ? "searchIconLight" : ""}`} />
                                    <input type="text" value={this.state.searchCryptoText} name="searchCryptoText" data-e2e="search-box" className={`searchInput ${this.state.lightFlag ? "searchInputLight" : ""}`} onChange={(e) => {
                                        this.handleChange(e)
                                    }} placeholder={t("Search by name")} />
                                </div>
                                <div className="suggestionsWrap">
                                    {listOfSuggestions.map((CryptoObj, i) => {
                                        if (CryptoObj) {
                                            return (<SingleSamllCryptoBtn
                                                image_base_url={this.props.image_base_url}
                                                lightFlag={this.state.lightFlag}
                                                key={i} index={i} data={{
                                                    ...CryptoObj,
                                                    fiatAmount: `${this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}${CryptoObj.fiatAmount} `,
                                                    fiatCurrency: this.props.transaction.fiatCurrency,
                                                    fiatAmountValue: CryptoObj.fiatAmount
                                                }} onCardClick={this.onCardClick} />)
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="deposit_card_body deposit_card_body_cryptos"
                                style={{ transition: "box-shadow 0.3s" }}>
                                <div className="deposit_content_wrap">
                                    <div className="deposit_content_column_heading">
                                        <div className={this.state.lightFlag ? "text_color_black token-name " : " token-name "}>{t('TOKEN NAME')}</div>
                                        <div className={this.state.lightFlag ? "text_color_black token-name " : " token-name "}>{t('FUNDS AVAILABLE')}</div>
                                    </div>
                                    {this.state.listOfCryptoToShow.map((CryptoObj, i) => {
                                        if (CryptoObj) {
                                            return (<SingleCryptoCard
                                                image_base_url={this.props.image_base_url}
                                                lightFlag={this.state.lightFlag}
                                                key={i} index={i} data={{
                                                    ...CryptoObj,
                                                    description: ` ${CryptoObj.cryptocurrencyAmount ? CryptoObj.cryptocurrencyAmount.toString().substring(0, 11) : "0"}${CryptoObj.cryptocurrencyAmount > 0 ? (CryptoObj.cryptocurrencyAmount.toString().length > 11 ? ".." : "") : ""}`,
                                                    fiatAmount: `${this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}${CryptoObj.fiatAmount} `,
                                                    fiatCurrency: this.props.transaction.fiatCurrency,
                                                    fiatAmountValue: CryptoObj.fiatAmount
                                                }} onCardClick={this.onCardClick} />)
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {!this.props.loading && this.state.listOfCryptoToShow.length === 0 && (this.state.searchCryptoText && this.state.searchCryptoText !== "") && <div className={this.state.lightFlag ? "text_color_black" : "text_color_white"} style={{ opacity: 0.5 }}>{t('No User Wallet match with the current search.')}</div>}
                                    {!this.props.loading && this.state.listOfCryptoToShow.length === 0 && this.state.searchCryptoText === "" && <div className={this.state.lightFlag ? "text_color_black" : "text_color_white"} style={{ opacity: 0.5 }}>{t('User wallet list is empty!')}</div>}
                                </div>
                            </div>
                            {/* <div className='deposit_card_body_footer deposit_card_body_footer_padding' style={{ backgroundImage: `url(${this.props.image_base_url}${this.state.lightFlag ? depsoitBgLight : depsoitBgDark})` }}>
                            <img className='mb-2 cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? logoLight : logoDark}`} alt="logo"
                                onClick={() => {
                                    this.redirectToHome();
                                }} />
                            <div className={this.state.lightFlag ? "text_color_black mb-2" : "mb-2"}>Deposit instantly from a Cryptonpay wallet for free. Learn more </div>
                            <a href="#" className="learmMore">Learm more</a>
                        </div> */}
                        </div>
                    </div>
                    {/* <img src={`${this.props.image_base_url}${this.state.lightFlag ? logoLight : logoDark}`} alt="logo" className="cptreadmarkLogo" /> */}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        payment_api_url: state.config.payment_api_url,
        image_base_url: state.config.image_base_url,
        loading: state.common.loading,
        token: state.common.token,
        transaction: state.deposit.transaction,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTransaction: (value) => dispatch(actions.setTransaction(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectCryptopayWalletToDeposit));

const SingleCryptoCard = (props) => {
    const { name, img, description, symbol, fiatAmount } = props.data;
    const { onCardClick } = props;
    return (
        <button className="deposit_select_cryptoCard" data-e2e={name} onClick={(e) => {
            e.preventDefault()
            onCardClick(props.data)
        }} >
            <img src={`${props.image_base_url}${img}`} className="deposit_select_crypto_img" />
            <div className="deposit_select_crypto_textWrap">
                <div className={props.lightFlag ? "text_color_black deposit_select_crypto_block" : "deposit_select_crypto_block"}>
                    <span className="deposit_select_crypto_name">{name}</span>
                    <span className='deposit_select_crypto_name deposit_align_right'> {fiatAmount}</span>
                </div>
                <div className={props.lightFlag ? "text_color_black deposit_select_crypto_block" : "deposit_select_crypto_block"}>
                    <span className="description">{symbol}</span>
                    <span className='description deposit_align_right'>{description}</span>
                </div>
            </div>
        </button>
    )
}
const SingleSamllCryptoBtn = (props) => {
    const { name, img, symbol } = props.data;
    const { onCardClick } = props;
    return (
        <button className={`select_suggestion ${props.lightFlag ? "select_suggestion_light" : ""}`} data-e2e={name} onClick={(e) => {
            e.preventDefault()
            onCardClick(props.data)
        }} >
            <img src={`${props.image_base_url}${img}`} className="select_suggestion_img" />
            <span className={`symbolName ${props.lightFlag ? "symbolNameLight" : ""}`}>{symbol}</span>
        </button>
    )
}