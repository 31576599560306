// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { apiService } from '../../../../common/apiCallService';
import { setLoading } from '../../../../Redux/actions';
import { checkValueInSS } from '../../../../common/theme';

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState('');
  const [stepOne, setStepOne] = useState(false);
  const api_url = useSelector((state) => state.config.api_url);
  const loading = useSelector((state) => state.common.loading);
  const [error, setError] = useState('');
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])


  const handleClose = () => {
    props.onClose();
  };

  const handleResetPassowrd = () => {
    if (emailId && emailId !== '' && validator.isEmail(emailId)) {
      dispatch(setLoading(true));
      apiService(
        (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-forgot-password',
        {
          email: emailId,
          userType: 'b2cUser',
		  domainNameUrl: localStorage.getItem("domainNameUrl") ? localStorage.getItem("domainNameUrl") : window.location.hostname,
          hostname: window.location.hostname,
		  paymentProviderName: sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"
        },
        (data) => {
          dispatch(setLoading(false));
          if (data && data.success) {
            setStepOne(true);
          } else if (data && data.msg) {
            alert('Something Went Wrong!');
          } else {
            alert('Something Went Wrong!');
          }
        },
        (err) => {
          dispatch(setLoading(false));
          console.log(err);
          setError('Please enter valid email address!');
        },
      );
    } else {
      dispatch(setLoading(false));
      setError('Please enter valid email address!');
    }
  };

  let headerButtonStyle = {}
	if (b2cColorMode === "dark") {
		if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
			headerButtonStyle.background = headerButtonDarkBgColour;
			headerButtonStyle.borderColor = headerButtonDarkBgColour;
		}
		if (headerDarkTextColour && headerDarkTextColour !== "") {
			headerButtonStyle.color = headerDarkTextColour;
		}
	} else {
		if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonLightBgColour;
			headerButtonStyle.background = headerButtonLightBgColour;
			headerButtonStyle.borderColor = headerButtonLightBgColour;
		}
		if (headerLightTextColour && headerLightTextColour !== "") {
			headerButtonStyle.color = headerLightTextColour;
		}
	}

  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} className="theme-modal">
				<div className="theme-modal-body theme-modal-body-grid theme-modal-body-forgot">
					<div className="theme-modal-close">
						<ClearIcon onClick={handleClose} className="icon-close" />
					</div>
					<p className="theme-modal-title">Forgot Password</p>
					{!stepOne && 
						<div className="flex-col-between">
							<div className="theme-modal-field">
								<p className="theme-modal-field-label">Enter your email and we’ll send a reset link</p>
								<input
									className="theme-modal-field-input"
									type="text"
									placeholder="Enter email address…"
									name="email"
									value={emailId}
									onChange={(e) => {
										setError("")
										setEmailId(e.target.value)
									}}
								/>
								<div className="errorMessage">{error}</div>
							</div>

							<div className="theme-btn-block theme-modal-submit full-width">
								<button
									disabled={emailId === '' || emailId.length === 0 ? true : false}
									className={`theme-btn ${emailId === '' || emailId.length === 0 ? 'disabled' : ''}`}
									onClick={handleResetPassowrd}
								  style={{ ...(error === "" ? headerButtonStyle : {}) }}
								><p>Reset Password</p>
								</button>
							</div>
						</div>
					}
					{stepOne && 
						<div className="theme-modal-thanks">
							<img width="60" height="60" className="theme-modal-thanks-icon" src={`${image_base_url}/upd/icons/thank-you.svg`} />
							<p className="theme-modal-thanks-title">Thanks!</p>
							<p className="section-text grey-text t-center">{`If a ${sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"} account exists for that email address, we will email you instructions for resetting your password.`}</p>
						</div>
					}
					{stepOne && 
						<div className="theme-btn-block full-width theme-modal-submit">
						  <div onClick={handleClose} className="theme-btn" style={{ ...headerButtonStyle }}>
								<p>Continue</p>
							</div>
						</div>
					}
				</div>
      </Dialog>
    </div>
  );
}

export default ForgotPassword;
