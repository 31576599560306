import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import { apiService } from '../../../common/apiCallService';
import localDb from "../../../common/localDb";
import * as actions from '../../../Redux/actions';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';
// import validator from 'validator'
const logo = "/common/logo_samll.svg"

class Signup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            redirectToLogin: 0,
            alertMsg: null,
            showSuccessAlertModal: false,
            isModalHide: false,
            emailError: "",   
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
            logoWidth:150
        }
    }

    componentDidMount() {
        if (localDb.getVal("token")) {
            this.setState({ redirectToLogin: 1 })
        }
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
        if (checkValueInSS("logoWidth")) {
            this.setState({ logoWidth: sessionStorage.getItem("logoWidth") });
        }
    }

    signUp = () => {
        if (this.state.emailId && this.state.emailId !== "" 
        // && validator.isEmail(this.state.emailId)
        ) {
            this.setState({ alertMsg: null })
            this.props.setLoading(true)
            const domainName=window.location.origin
            apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/merchant-signup', {
                email: this.state.emailId,
                domainNameUrl:domainName
            }, (data) => {
                this.props.setLoading(false)
                if (data && data.success) {
                    this.setState({
                        showSuccessAlertModal: true,
                        isModalHide: true,
                    })
                } else if (data && data.msg) {
                    this.setState({ alertMsg: "Something Went Wrong!", emailError: data.msg })
                } else {
                    this.setState({ alertMsg: "Something Went Wrong!" })
                }
            }, (err) => {
                this.props.setLoading(false)
                this.setState({ alertMsg: "Something Went Wrong!", emailError: "Please enter valid email address!" })
                console.log(err);
            })
        } else {
            this.setState({ emailError: "Please enter valid email address!" })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
            emailError: "",
        });
    }

    render() {
    let paymentProviderId = localDb.getSSVal("paymentProviderId")
    let domainId = localDb.getSSVal("domainId")
    let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)
    let headerButtonBorderStyle = getHeaderButtonBorderStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerButtonLightBgColour)

			return (
				<div>
					{!this.state.isModalHide && <Dialog className="theme-modal" open={this.props.show} onClose={() => this.props.closeModal()}>
						<div className="theme-modal-body">
							<div className="theme-modal-close">
								<ClearIcon onClick={() => this.props.closeModal()} className="icon-close" />
							</div>
							<div className="w-100">
								<img src={`${this.props.image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/logo${this.props.theme === 'light' ? "" : "dark"}.png` : logo}`} alt="crypton-logo-dark-mode" 
                                width={this.state.logoWidth} 
                                className='m-auto pb-2'
                                />
							</div>
							<p className="theme-modal-title">{this.props.title}</p>
							
							{this.state.redirectToLogin == 1 && <Navigate to='/login' />}
							{this.state.alertMsg &&
								<div className="alert alert-danger" role="alert">
									{this.state.alertMsg}
							</div>}
							
							<form>
								<p className="theme-modal-text">To sign up enter your email and a member of our team will be in touch shortly.</p>
								<br/>
								<div className="theme-modal-field">
									<p className="theme-modal-field-label">Email</p>
									<input type="text" className="theme-modal-field-input"
													value={this.state.emailId} placeholder="Email Address" onChange={this.handleInputChange} name="emailId" />
									<div className="errorMessage">{this.state.emailError}</div>
								</div>
								
								<div className="theme-btn-block theme-modal-submit full-width">
									<button type="button"
										disabled={this.state.emailError !== ""}
										className={`theme-btn ${this.state.emailError === "" ? '' : 'disabled'}`}
										onClick={this.signUp}
                                        style={{ ...headerButtonStyle }}
									><p>Submit</p>
									</button>
								</div>
							</form>
							<div className="theme-modal-account">
								Already have an account?{' '}
								<button type="button" className="theme-modal-account-btn" onClick={() => {this.props.changeModalType("login")}} style={{ ...headerButtonBorderStyle }}>Log In</button>
							</div>
						</div>
					</Dialog>}
					
					<Dialog open={this.state.showSuccessAlertModal} className="theme-modal" onClose={() => {
						this.props.closeModal()
						this.setState({ showSuccessAlertModal: false })
					}}>
						<div className="theme-modal-body">
							<div className="theme-modal-close">
								<ClearIcon onClick={() => this.props.closeModal()} className="icon-close" />
							</div>
							<div className="theme-modal-thanks">
								<svg className="theme-modal-thanks-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="#ECFDF3"/><circle cx="30" cy="30" r="15" fill="#62CA7A"/><path d="M22 30.25L23.5 28.75L27.25 32.5L35.5 24.25L37 25.75L27.25 35.5L22 30.25Z" fill="white"/><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke="#D1FADF"/></svg>
								<p className="theme-modal-thanks-title">Thanks!</p>
								<p className="section-text theme-modal-text t-center">A member of our team will be in touch shortly.</p>
							</div>
							
							<div className="theme-btn-block theme-modal-submit full-width">
								<button type="button"
									className="theme-btn"
                                    style={{ ...headerButtonStyle }}
									onClick={() => {
										this.setState({ showSuccessAlertModal: false });
										this.props.changeModalType("login")
									}}
								><p>Close</p>
								</button>
							</div>
						</div>
					</Dialog>
				</div>
			)

    }

}
const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
        theme: state.common.theme,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup)