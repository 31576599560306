import React from 'react'
import B2CHeaderAndFooter from '../../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter'

const CoinSpiritPrivacyPolicy = () => {
    return (
        <B2CHeaderAndFooter>
            <div className="">
                <div className="header__layout"></div>
                <section className="section legal">
                    <div className="content-container">
                        <p className="section-title mb">External Privacy Policy Notice</p>
                        <div className='legal_title'>
                            <p className="legal__desc">This Privacy Policy constitutes part of and should be read in conjunction with the Coinsspirit Terms and Conditions and Cookie Policy Notice. This policy explains how we may collect, create, process, store, protect, disclose, share, and transfer your Personal Data as part providing our Services and/or otherwise as part of our business operations.

                                We have defined some terms that we use throughout the Privacy Policy. You can find the meaning of a capitalised term in Section (19) Definitions below or as otherwise may be defined in this agreement.

                                “Personal Data” means any information that identifies or is reasonably capable of identifying an individual, directly, or indirectly, such as a name or identification number, an online identifier or other identifiable characteristics and other information that is being associated with an identified or reasonably identifiable individual.

                                Coinsspirit may also collect, create, process, store, protect, disclose, share, and transfer your Non- Personal Data as part providing our Services and/or otherwise as part of our business operations. For example, Coinsspirit may use anonymised and aggregated transactional information for commercial purposes such as reviewing or analysing transaction data and patterns.

                                Please note that Coinsspirit provides services to both individual consumers and businesses and this Privacy Policy applies to both and should be read and interpreted accordingly.</p>
                        </div>
                        {/* table */}
                        <div className='my-4'>
                            <table className='border' >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – The Summaries</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>Any section summary boxes, such as this one, are provided for your convenience only, we encourage you to read the full sections so that you can fully understand and accept the terms of this Privacy Policy.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        {/* table */}
                        <div className='my-4'>
                            <p className='my-2 '>This Notice</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – The Notice</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>This Notice explains how we Process Personal Data. This Notice may be amended or updated from time to time, so please check it regularly for updates.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">This Notice is issued by CPAD Services Sp. Z.O.O trading as coinsspirit ( “Coinsspirit” “we”, “us” and “our”) and is addressed to individuals outside our organisation with whom we interact, including customers, visitors to our Sites, users of our Apps, other users of our Services, and visitors to our premises (together, “you”). For the purposes of this Notice, Coinsspirit is the Controller.</p>
                                <p className="legal__desc">This Notice may be amended or updated from time to time to reflect changes in our practices with respect to the Processing of Personal Data, or changes in Applicable Law. We encourage you to read this Notice carefully, and to regularly check this page to review any changes we might make in accordance with the terms of this Notice. You can always find the latest version of this Privacy Policy here on this page.</p>
                            </div>
                        </div>
                        {/* table */}
                        <div className='my-4'>
                            <p className='my-2'>Collection of Personal Data</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Collection of Personal Data</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>We collect or obtain Personal Data:</strong> when those data are provided to us (e.g., where you contact us); in the course of our relationship with you (e.g., if you make a purchase); when you make Personal Data public (e.g., if you make a public post about us on social media); when you download, install, or use any of our Apps; when you visit our Sites; when you register to use any of our Sites, Apps, or Services; or when you interact with any third party content or advertising on our Site or in our App. We may also receive Personal Data about you from third parties (e.g., law enforcement authorities).</p>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">We will only collect Personal Data relevant to the purpose for which it will be used, and which will be limited to what is necessary for that purpose.</p>
                            </div>
                        </div>
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc"><strong>Collection of Personal Data:</strong> We collect or obtain Personal Data about you from the following sources:</p>
                                <ul style={{ paddingLeft: "30px" }} className='my-4'>
                                    <li><p className="legal__desc"><strong>Data provided to us: </strong>We obtain Personal Data when those data are provided to us (e.g., where you contact us via email or telephone, or by any other means, or when you provide us with your identity documents, or when you submit wage slips or other proof of funds documents, or when you provide other information required by Applicable Laws, or when you choose to provide us with information about your contacts or address book or provide information otherwise required by us to provide our Services).</p></li>
                                    <li><p className="legal__desc"><strong>Relationship data: </strong>We collect or obtain Personal Data in the ordinary course of our relationship with you (e.g., we provide a service to you).</p></li>
                                    <li><p className="legal__desc"><strong>Data you make public: </strong>We collect or obtain Personal Data that you manifestly choose to make public, including via social media (e.g., we may collect information from your social media profile(s), if you make a public post about us).</p></li>
                                    <li><p className="legal__desc"><strong>App data: </strong>We collect or obtain Personal Data when you download or use any of our Apps.</p></li>
                                    <li><p className="legal__desc"><strong>Site data: </strong> We collect or obtain Personal Data when you visit any of our Sites or use any features or resources available on or through a Site.</p></li>
                                    <li><p className="legal__desc"><strong>Registration details: </strong>We collect or obtain Personal Data when you use, and/or register to use, any of our Sites, Apps, or Services.</p></li>
                                    <li><p className="legal__desc"><strong>Content and advertising information: </strong> If you interact with any third party content or advertising on our Site or in our App (including third party plugins and cookies) we receive Personal Data from the relevant third party provider of that content or advertising.</p></li>
                                    <li><p className="legal__desc"><strong>Third party information: </strong>We collect or obtain Personal Data from third parties who provide it to us (e.g., credit reference agencies; law enforcement authorities; identity service providers; global PEP and Sanctions watchlists; etc.).</p></li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">Please note that third parties you interact with may have their own privacy policies, and we are not responsible for their operations or their use of the data they may collect. Information collected by third parties is governed by their privacy practices and we are not responsible for unauthorized or unlawful third-party conduct. We encourage you to learn and understand the privacy practices of any relevant third parties you engage with.</p>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Creation of Personal Data</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Creation of Personal Data</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We create Personal Data about you (e.g., records of your interactions with us).</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">We also create Personal Data about you in certain circumstances, such as records of your interactions with us, and details of your past interactions with us. We may also combine Personal Data from any of our Sites, Apps, or Services, including where those data are collected from different devices.</p>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Categories of Personal Data we Process</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Categories of Personal Data we Process</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>We Process: </strong>your personal details (e.g., your name); formal personal details (e.g., government ID); your contact details (e.g., your address); details of your contacts (e.g., the names and contact information in your address book); records of your consents; purchase details; payment details (e.g., your billing address); information about our Sites and Apps (e.g., the type of device you are using); details of your employer (where relevant); information about your interactions with our content or advertising; and any views or opinions you provide to us.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc"><strong>We Process the following categories of Personal Data about you:</strong> </p>
                                <ul style={{ paddingLeft: "30px" }} className='my-4'>
                                    <li><p className="legal__desc"><strong>Personal Identity Information: </strong>(such as given name(s), preferred name(s), gender, date of birth, age, nationality, title, language)</p></li>
                                    <li><p className="legal__desc"><strong>Formal Identity Information: (</strong>such as government issued identity documents such as identity cards, passports, driving license and/or any other government issued or public information deemed necessary to comply with our legal obligations under financial or anti-money laundering laws.)</p></li>
                                    <li><p className="legal__desc"><strong>Financial Information: </strong>(such as bank account and/or card details, routing number, location data and addresses, invoices, payment records, transaction history and/or other financial data)</p></li>
                                    <li><p className="legal__desc"><strong>Transaction Information: </strong>(details of transactions on our Services, such as details of sender and receiver, amounts, timing and date, eWallet addresses)</p></li>
                                    <li><p className="legal__desc"><strong> Employment Information: </strong>(such as job title, employer, location, proof of salary and/or earnings)</p></li>
                                    <li><p className="legal__desc"><strong>Correspondence Information: </strong>(such as account registration details, email, contact number and home address)</p></li>
                                    <li><p className="legal__desc"><strong>Contacts Information: </strong>(such as if you choose to provide us with information about your contacts and your address book by syncing, uploading or importing it to our App)</p></li>
                                    <li><p className="legal__desc"><strong>Sensory Information: </strong>(any audio or visual information such as recordings of calls, CCTV videos and selfies for identity verification)</p></li>
                                    <li><p className="legal__desc"><strong>Additional Information: </strong>(such as where we exercise discretion to collect information required to comply with legal/compliance obligations)</p></li>
                                    <li><p className="legal__desc"><strong>Data relating to our Sites and Apps: </strong>(such as device type, operating system, browser type, browser settings, IP address, language settings, dates and times of connecting to a Site, App usage statistics, App settings, QR codes, dates and times of connecting to an App, location data, and other technical communications information (some of which may constitute Personal Data), username; password, security login details, aggregate statistical information.)</p></li>
                                    <li><p className="legal__desc"><strong>Consent records: </strong>(such as records of any consents you have given, together with the date and time, means of consent and any related information (e.g., the subject matter of the consent)).</p></li>
                                    <li><p className="legal__desc"><strong>Service Information: </strong>(such as information about how frequently you use our Services, average transaction volumes, eWallet balances, people and merchants you send/receive money to/from and any other information provided or produced in the context of using the Services) (5) Sensitive</p></li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Sensitive Personal Data</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Sensitive Personal Data</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We do not seek to collect or otherwise Process Sensitive Personal Data. Where we need to Process Sensitive Personal Data for a legitimate purpose, we do so in accordance with Applicable Law.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">We do not seek to collect or otherwise Process Sensitive Personal Data in the ordinary course of our business. Where it becomes necessary to Process your Sensitive Personal Data for any reason, we rely on one of the following legal bases:</p>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <ul style={{ paddingLeft: "30px" }} className='my-4'>
                                    <li>
                                        <p>
                                            <strong>Compliance with Applicable Law:&nbsp;</strong>We may Process
                                            your Sensitive Personal Data where the Processing is required or
                                            permitted by Applicable Law (e.g., to comply with our diversity
                                            reporting obligations);
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Detection and prevention of crime:&nbsp;</strong>We may Process
                                            your Sensitive Personal Data where the Processing is necessary for the
                                            detection or prevention of crime (e.g., the prevention of fraud);
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>
                                                Establishment, exercise or defence of legal rights:&nbsp;
                                            </strong>
                                            We may Process your Sensitive Personal Data where the Processing is
                                            necessary for the establishment, exercise or defence of legal rights; or
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Consent:&nbsp;</strong>We may Process your Sensitive Personal
                                            Data where we have, in accordance with Applicable Law, obtained your
                                            prior, express consent before Processing your Sensitive Personal Data
                                            (this legal basis is only used in relation to Processing that is
                                            entirely voluntary – it is not used for Processing that is necessary or
                                            obligatory in any way).
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Purposes of Processing and legal bases for Processing</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Purposes of Processing and legal bases for Processing</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>We Process Personal Data for the following purposes: </strong>providing our Sites, Apps, and Services to you; compliance checks; operating our business; communicating with you; managing our IT systems; health and safety; financial management; conducting surveys; ensuring the security of our premises and systems; conducting investigations where necessary; compliance with Applicable Law; improving our Sites, Apps, and Services; fraud prevention; and recruitment and job applications.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">The legal basis for the Processing of your Personal Data is dependent on the context in which we collect it and the purposes for which it is used. Subject to Applicable Law, the purposes for which we Process Personal Data, and the most common legal bases on which we perform such Processing, are as follows:</p>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <ul style={{ paddingLeft: "30px" }} className='my-4'>
                                    <li>
                                        <p>
                                            <strong>For compliance with a legal obligation:&nbsp;</strong>Where the
                                            Processing is necessary for us (or a third party) to comply with a
                                            legal, compliance or regulatory obligation which are subject to;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>For the performance of a contract:&nbsp;</strong>Where the
                                            Processing is necessary in connection with any contract you have entered
                                            into with us, or to take steps prior to entering into a contract with
                                            us;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>For a legitimate interest:&nbsp;</strong>Where we (or a third
                                            party) have a legitimate interest in carrying out the Processing for the
                                            purpose of providing our Sites, Apps, or Services or other business
                                            operations (to the extent that such legitimate interest is not
                                            overridden by or in conflict with your interests, fundamental rights, or
                                            freedoms);
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Under your prior consent:&nbsp;</strong>Where you have consented
                                            to the Processing (this legal basis is only used in relation to
                                            Processing that is entirely voluntary and is not used for Processing
                                            that is necessary or obligatory in anyway);
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* 2 Col Table */}
                        <table className='border' width="100%"  >
                            <tbody>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td style={{ padding: "10px" }} width="50%">
                                        <p>
                                            <br />
                                            <strong>Processing Activity</strong>
                                        </p>
                                    </td>
                                    <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="50%">
                                        <p>
                                            <br />
                                            <strong>Legal basis for Processing</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td style={{ padding: "10px" }} width="50%" >
                                        <p>
                                            Providing our Sites, Apps, or Services;
                                            Providing promotional items upon request;
                                            and
                                            Communicating with you in relation to those
                                            Sites, Apps, or services.
                                        </p>
                                    </td>
                                    <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="50%" >
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for the performance of a contract
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of providing our Sites, Apps, or Services
                                            <br />
                                            &nbsp;We have obtained your prior consent to the Processing.
                                        </p>
                                    </td>
                                </tr>
                                {/* 2nd Right */}
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td style={{ padding: "10px" }} width="50%">
                                        <p>
                                            <strong>Helping with social interactions:</strong>
                                            <br />
                                            &nbsp;
                                            <br />
                                            &nbsp;Helping with social interactions through our services;
                                            <br />
                                            &nbsp;Adding extra functions in order to provide a better customer
                                            experience;
                                            <br />
                                            &nbsp;
                                            <br />
                                            &nbsp;For example, if you give us permission, we’ll use the contacts
                                            list on your phone so you can easily identify other Coinsspirit
                                            users and make payments to your contacts using the Coinsspirit App.
                                            We will not pass this information to any third parties.
                                        </p>
                                    </td>
                                    <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="50%">
                                        <p>
                                            We have a legitimate interest in carrying out the Processing for the
                                            purpose of providing our Sites, Apps, or Services
                                            &nbsp;We have obtained your prior consent to the Processing.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td style={{ padding: "10px", }} width="50%" >
                                        <p>
                                            <br />
                                            <strong>Compliance checks:</strong>
                                            <br />
                                            &nbsp;Fulfilling our legal, regulatory and/or compliance
                                            obligations;
                                            <br />
                                            &nbsp;‘Know Your Client’ checks and confirming and verifying your
                                            identity;
                                            <br />
                                            &nbsp;Screening against government and/or law enforcement agency
                                            sanctions lists and other legal restrictions.
                                        </p>
                                    </td>
                                    <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="50%" >
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;The Processing is necessary for the performance of a contract;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of fulfilling our regulatory and compliance
                                            obligations;
                                            <br />
                                            &nbsp;We have obtained your prior consent to the Processing.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td style={{ padding: "10px" }} width="50%">
                                        <p>
                                            <br />
                                            <strong>Operating our business:</strong>
                                            <br />
                                            &nbsp;Operating and managing our Sites, our Apps, and our Services;
                                            <br />
                                            &nbsp;Providing content to you;
                                            <br />
                                            &nbsp;Displaying advertising and other information
                                            <br />
                                            &nbsp;to you;
                                            <br />
                                            &nbsp;Communicating and interacting with you via
                                            <br />
                                            &nbsp;our Sites, our Apps, or our Services; and
                                            <br />
                                            &nbsp;Notifying you of changes to any of our Sites,
                                            <br />
                                            &nbsp;our Apps, or our Services.
                                        </p>
                                    </td>
                                    <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="50%">
                                        <p>
                                            The Processing is necessary for the performance of a contract;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of providing our Sites, our Apps, or our Services or
                                            other business operations to you;
                                            <br />
                                            &nbsp;We have obtained your prior consent to the Processing.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px", }}  >
                                        <p>
                                            <br />
                                            <strong>Communications and marketing:</strong>
                                            <br />
                                            &nbsp;Communicating with you via any means (including via email,
                                            telephone, text message, social media, post or in person) to provide
                                            <br />
                                            &nbsp;news items and other information in which
                                            <br />
                                            &nbsp;you may be interested, subject always to obtaining your prior
                                            opt-in consent to the extent required under Applicable Law;
                                            <br />
                                            &nbsp;Maintaining and updating your contact information where
                                            appropriate; and obtaining your prior, opt-in consent where
                                            required.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }} >
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for the performance of a contract;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of contacting you, subject always to compliance with
                                            Applicable Law ;<br />
                                            &nbsp;We have obtained your prior consent to the Processing
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            &nbsp;
                                            <br />
                                            <strong>Management of IT systems:</strong>
                                            <br />
                                            &nbsp;Management and operation of our communications, IT and
                                            security systems; and audits (including security audits) and
                                            monitoring of such systems.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out
                                            <br />
                                            &nbsp;the Processing for the purpose of managing and maintaining our
                                            communications and IT systems.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Health and safety</strong>
                                            <br />
                                            &nbsp;Health and safety assessments and record keeping;
                                            <br />
                                            &nbsp;Providing a safe and secure environment at our premises;
                                            <br />
                                            &nbsp;Compliance with related legal obligations.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out
                                            <br />
                                            &nbsp;the Processing for the purpose of ensuring a<br />
                                            &nbsp;safe environment at our premises;
                                            <br />
                                            &nbsp;The Processing is necessary to protect the
                                            <br />
                                            &nbsp;vital legitimate interests of any individual
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }} >
                                        <p>
                                            <br />
                                            <strong>Financial management:</strong>
                                            <br />
                                            &nbsp;Sales;
                                            <br />
                                            &nbsp;Finance;
                                            <br />
                                            &nbsp;Corporate audit;
                                            <br />
                                            &nbsp;Vendor management
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of managing and operating the financial affairs of
                                            our business;
                                            <br />
                                            &nbsp;We have obtained your prior consent to the Processing.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Security</strong>
                                            <br />
                                            &nbsp;Physical security of our premises (including records of visits
                                            to our premises);
                                            <br />
                                            &nbsp;CCTV recordings;
                                            <br />
                                            &nbsp;Electronic security (including login records
                                            <br />
                                            &nbsp;and access details).
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of ensuring the physical and electronic security of
                                            our business and our premises.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Investigations:</strong>
                                            <br />
                                            &nbsp;Detecting, investigating and preventing breaches of policy,
                                            and criminal offences, in accordance with Applicable Law.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of detecting, and protecting against, breaches of
                                            our policies and Applicable Laws.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Legal proceedings:</strong>
                                            <br />
                                            &nbsp;
                                            <br />
                                            &nbsp;Establishing, exercising, and/or defending legal rights.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;• We have a legitimate interest in carrying
                                            <br />
                                            &nbsp;out the Processing for the purpose of establishing, exercising
                                            or defending our legal rights.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Legal compliance:</strong>
                                            <br />
                                            &nbsp;Compliance with our legal and regulatory
                                            <br />
                                            &nbsp;obligations under Applicable Law.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px", }}>
                                        <p>
                                            <br />
                                            <strong>Improving our Sites, Apps, and services:</strong>
                                            <br />
                                            &nbsp;Identifying issues with our Sites, our Apps, or our Services;
                                            <br />
                                            &nbsp;Planning improvements to our Sites, our Apps, or our Services;
                                            <br />
                                            &nbsp;Creating new Sites, Apps, or Services.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of improving our Sites, our Apps, or our services;
                                            <br />
                                            &nbsp;We have obtained your prior consent to the Processing
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Fraud prevention:</strong>
                                            <br />
                                            &nbsp;Detecting, preventing and investigating fraud
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation;
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of detecting, and protecting against, fraud.
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Recruitment and job applications:</strong>
                                            <br />
                                            &nbsp;Recruitment activities;
                                            <br />
                                            &nbsp;Advertising of positions;
                                            <br />
                                            &nbsp;Interview activities;
                                            <br />
                                            &nbsp;Analysis of suitability for the relevant position;
                                            <br />
                                            &nbsp;Records of hiring decisions;
                                            <br />
                                            &nbsp;Offer details;
                                            <br />
                                            &nbsp;Acceptance details.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;The Processing is necessary for compliance with a legal
                                            obligation (especially in respect of applicable employment law);
                                            <br />
                                            &nbsp;We have a legitimate interest in carrying out the Processing
                                            for the purpose of recruitment activities and handling job
                                            applications; We have obtained your prior consent to the Processing
                                        </p>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                    <td width="50%" style={{ padding: "10px" }}>
                                        <p>
                                            <br />
                                            <strong>Where you consent:</strong>
                                            <br />
                                            &nbsp;For any other purpose for which you provide
                                            <br />
                                            &nbsp;your consent.
                                        </p>
                                    </td>
                                    <td width="50%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                        <p>
                                            <br />
                                            &nbsp;We have obtained your prior consent to the Processing
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Disclosure of Personal Data to third parties</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Disclosure of Personal Data to third parties</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>We disclose Personal Data to: </strong>legal and regulatory authorities; our external advisors; our Processors; any party as necessary in connection with legal proceedings; any party as necessary for investigating, detecting or preventing criminal offences; any purchaser of our business; and any third party providers of advertising, plugins or content used on our Sites or our Apps.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>
                                We disclose Personal Data within Coinsspirit, for legitimate business
                                purposes and the operation of our Sites, Apps, or Services to you, in
                                accordance with Applicable Law. In addition, we disclose Personal Data to:
                            </p>
                            <div style={{ paddingLeft: "30px" }} className='my-4'>
                                <ul>
                                    <li>
                                        <p>You and, where appropriate, your appointed representatives;</p>
                                    </li>
                                    <li>
                                        <p>
                                            Any legal and regulatory authorities, upon request, or for the purposes
                                            of reporting any actual or suspected breach of Applicable Law or
                                            regulation;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Accountants, auditors, lawyers and other outside professional advisors
                                            to Coinsspirit, subject to binding contractual obligations of
                                            confidentiality;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Third party Processors and associated Processors (such as payment
                                            services providers) located anywhere in the world, subject to the
                                            requirements noted below in this Section (7);
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Any relevant party, law enforcement agency or court, to the extent
                                            necessary for the establishment, exercise or defence of legal rights;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Any relevant party for the purposes of prevention, investigation,
                                            detection or prosecution of criminal offences or the execution of
                                            criminal penalties;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Any relevant third party acquirer(s), in the event that we sell or
                                            transfer all or any relevant portion of our business or assets
                                            (including in the event of a reorganization, dissolution or
                                            liquidation); and
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Any relevant third party provider, where our Sites and our Apps use
                                            third party advertising, plugins or content. If you choose to interact
                                            with any such advertising, plugins or content, your Personal Data may be
                                            shared with the relevant third party provider. We recommend that you
                                            review that third party’s privacy policy before interacting with its
                                            advertising, plugins or content.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>
                                If we engage a third-party Processor to Process your Personal Data, the Processor will be subject to binding contractual obligations to: (i) only Process the Personal Data in accordance with our prior written instructions; and (ii) use measures to protect the confidentiality and security of the Personal Data; together with any additional requirements under Applicable Law. Third party processors we use regularly, but which may change from time to time, include but are not limited to:
                            </p>
                            <div style={{ paddingLeft: "30px" }} className='my-4'>
                                <ul>
                                    <li>
                                        <p>SumSub; (for identification verification)</p>
                                    </li>
                                    <li>
                                        <p>Comply Advantage; (for compliance checks)</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Profiling</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Profiling</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>Personal Data are subject to automated decision-making and Profiling.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4 space-y-2'>
                            <p>
                                We Process Personal Data for the purposes of automated decision-making and
                                Profiling, which is defined in Section (19).
                            </p>
                            <p>
                                Automated decision-making and Profiling, are carried out for the following
                                purposes:
                            </p>
                        </div>
                        {/* Table 3 col */}
                        <div className='my-4'>
                            <table width="100%" cellPadding={10} className='border'>
                                <tbody>
                                    <tr style={{ borderBottom: "1px solid white" }}>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="16%">
                                            <p>Profiling Activity</p>
                                        </td>
                                        <td width="46%" style={{ padding: "10px", borderLeft: "1px solid white" }}>
                                            <p>Logic of the Profiling Activity</p>
                                        </td>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="38%">
                                            <p>Consequences for You</p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid white" }}>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="16%">
                                            <p>
                                                <br />
                                                <strong>Transaction Monitoring</strong>
                                            </p>
                                        </td>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="46%">
                                            <p>
                                                <br />
                                                &nbsp;We engage a third party to analyse transactions to highlight
                                                suspicious behaviour and potentially block suspicious transactions.
                                            </p>
                                        </td>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="38%">
                                            <p>
                                                <br />
                                                &nbsp;This profiling activity may mean that transactions are
                                                rejected or delayed if the activity is suspicious we may also report
                                                suspicious activity to the relevant regulatory bodies or law
                                                enforcement agencies.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid white" }}>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="16%" >
                                            <p>
                                                <br />
                                                <strong>KYC Identification</strong>
                                            </p>
                                        </td>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="46%" >
                                            <p>
                                                <br />
                                                &nbsp;We engage a third party to collect information and analyse it
                                                for KYC purposes, this information is then removed from their system
                                                and stored on ours.
                                            </p>
                                        </td>
                                        <td style={{ padding: "10px", borderLeft: "1px solid white" }} width="38%" >
                                            <p>
                                                <br />
                                                &nbsp;This profiling activity may mean that sign up is rejected or
                                                delayed if we may also need to provide this information relevant
                                                regulatory bodies or law enforcement agencies.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>International transfer of Personal Data</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – International transfer of Personal Data</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We transfer Personal Data to recipients in other countries. Where we transfer Personal Data from the EEA to a recipient outside the EEA that is not in an Adequate Jurisdiction, we do so on the basis of Standard Contractual Clauses.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div>
                            <p className='my-4'>
                                Because of the international nature of our business, we transfer Personal Data within Coinsspirit and to third parties as noted in Section (7) above, in connection with the purposes set out in this Notice. For this reason, we may transfer Personal Data to other countries that may have different laws and data protection compliance requirements to those that apply in the country in which you are located.
                            </p>
                            <p className='my-4'>
                                Where we transfer your Personal Data from the EEA to recipients located outside the EEA who are not in Adequate Jurisdictions, we rely primarily on the European Commission’s Standard Contractual Clauses to facilitate the international and onward transfer of Personal Data. You are entitled to request a copy of our Standard Contractual Clauses using the contact details provided in Section (18) below.
                            </p>
                            <p className='my-4'>
                                Please note that when you transfer any Personal Data directly to a recipient outside the EEA, we are not responsible for that transfer of your Personal Data. We will nevertheless Process your Personal Data, from the point at which we receive those data, in accordance with the provisions of this Notice.
                            </p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Data security</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Data security</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We implement appropriate technical and organisational security measures to protect your Personal Data. Please ensure that any Personal Data that you send to us are sent securely.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div>
                            <p className='my-4'>
                                We have implemented appropriate technical and organisational security measures designed to protect your Personal Data against accidental or unlawful destruction, loss, alteration, unauthorised disclosure, unauthorised access, and other unlawful or unauthorised forms of Processing, in accordance with Applicable Law.
                            </p>
                            <p className='my-4'>
                                Because the internet is an open system, the transmission of information via the internet is not completely secure. Although we will implement all reasonable measures to protect your Personal Data, we cannot guarantee the security or confidentiality of your data transmitted to us using the internet – any such transmission is at your own risk and you are responsible for ensuring that any Personal Data that you send to us are sent securely.
                            </p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Data accuracy</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Data accuracy</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We take every reasonable step to ensure that your Personal Data are kept accurate and up-to-date and are erased or rectified if we become aware of inaccuracies.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>
                                We take every reasonable step to ensure that:
                            </p>
                            <div style={{ paddingLeft: "30px" }} className='my-4'>
                                <ul>
                                    <li>
                                        <p>Your Personal Data that we Process are accurate and, where necessary, kept up to date; and</p>
                                    </li>
                                    <li>
                                        <p>
                                            any of your Personal Data that we Process that are inaccurate (having regard to the purposes for which they are Processed) are erased or rectified without delay.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <p className='my-4'>From time to time we may ask you to confirm the accuracy of your Personal Data.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Data minimisation</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Data minimisation</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We take every reasonable step to limit the volume of your Personal Data that we Process to what is necessary.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>We take every reasonable step to ensure that your Personal Data that we Process are limited to the Personal Data reasonably necessary in connection with the purposes set out in this Notice.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Data retention</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Data retention</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>We take every reasonable step to ensure that your Personal Data are only retained for as long as they are needed in connection with a lawful purpose.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*   */}
                        <div className='my-4'>
                            <p>We take every reasonable step to ensure that your Personal Data are only Processed for the minimum period necessary for the purposes set out in this Notice. The criteria for determining the duration for which we will retain your Personal Data are as follows:</p>
                            <div className='my-4'>
                                <p className='my-4'>
                                    (1) We will retain Personal Data in a form that permits identification only
                                    for as long as:
                                </p>
                                <div style={{ paddingLeft: "30px" }}  >
                                    <ol>
                                        <li>
                                            <p>
                                                We maintain an ongoing relationship with you (e.g., where you are a user
                                                of our services, or you are lawfully included in our mailing list and
                                                have not unsubscribed); or
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Your Personal Data are necessary in connection with the lawful purposes
                                                set out in this Notice, for which we have a valid legal basis (e.g.,
                                                where your personal data are included in a contract between us and you,
                                                and we have a legitimate interest in processing those data for the
                                                purposes of operating our business and fulfilling our obligations under
                                                a contract; or where we have a legal obligation to retain your Personal
                                                Data
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='my-4'>
                                <p className='my-4'>
                                    Plus (2) the duration of:
                                </p>
                                <div style={{ paddingLeft: "30px" }}  >
                                    <ol>
                                        <li>
                                            <p>
                                                Any applicable limitation period under Applicable Law (i.e., any period during which any person could bring a legal claim against us in connection with your Personal Data, or to which your Personal Data are relevant);
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                An additional two (2) month period following the end of such applicable limitation period (so that, if a person brings a claim at the end of the limitation period, we are still afforded a reasonable amount of time in which to identify any Personal Data that are relevant to that claim),
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-4'>
                                And (3) in addition, if any relevant legal claims are brought, we continue to Process Personal Data for such additional periods as are necessary in connection with that claim.
                            </p>
                            <p className='my-4'>
                                During the periods noted in paragraphs (2)(a) and (2)(b) above, we will restrict our Processing of your Personal Data to storage of, and maintaining the security of, those data, except to the extent that those data need to be reviewed in connection with any legal claim, or any obligation under Applicable Law.
                            </p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>
                                Once the periods in paragraphs (1), (2) and (3) above, each to the extent applicable, have concluded, we will either:
                            </p>
                            <div style={{ paddingLeft: "30px" }} className='my-4'>
                                <ul>
                                    <li>
                                        <p>Permanently delete or destroy the relevant Personal Data; or</p>
                                    </li>
                                    <li>
                                        <p>
                                            Anonymize the relevant Personal Data.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <p className='my-4'>From time to time we may ask you to confirm the accuracy of your Personal Data.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Your legal rights</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Your legal rights</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>Subject to Applicable Law, you may have a number of rights, including: </strong>the right not to provide your Personal Data to us; the right of access to your Personal Data; the right to request rectification of inaccuracies; the right to request the erasure, or restriction of Processing, of your Personal Data; the right to object to the Processing of your Personal Data; the right to have your Personal Data transferred to another Controller; the right to withdraw consent; and the right to lodge complaints with Data Protection Authorities. In some cases it will be necessary to provide evidence of your identity before we can give effect to these rights.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>You have many rights that you may be able to exercise in relation to your Personal Data. These rights may apply under a number of different regulations, for example, the General Data Protection Regulation (GDPR) which is generally applicable to EEA residents.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">Subject to Applicable Law, you may have the following rights regarding the Processing of your Relevant Personal Data:</p>
                                <ul style={{ paddingLeft: "30px" }} className='my-4'>
                                    <li><p className="legal__desc"><strong>The right not to provide </strong>your Relevant Personal Data to us (however, please note that we will be unable to provide you with the full benefit of our Sites, Apps, or Services, if you do not provide us with your Personal Data – e.g., we might not be able to process your requests without the necessary details);</p></li>
                                    <li><p className="legal__desc"><strong>The right to request access to, or copies of,</strong> your Relevant Personal Data, together with information regarding the nature, Processing and disclosure of those Relevant Personal Data;</p></li>
                                    <li><div><p className="legal__desc"><strong>The right to request rectification </strong>of any inaccuracies in your Relevant Personal Data; • the right to request, on legitimate grounds:</p>
                                        <ul style={{ paddingLeft: "10px" }}>
                                            <li>Erasure of your Relevant Personal Data; or</li>
                                            <li>Restriction of Processing of your Relevant Personal Data;</li>
                                        </ul>
                                    </div></li>
                                    <li><p className="legal__desc"><strong>The right to have certain Relevant Personal Data transferred </strong> to another Controller, in a structured, commonly used and machine-readable format, to the extent applicable;</p></li>
                                    <li><p className="legal__desc"><strong>The right to withdraw that consent </strong>where we Process your Relevant Personal Data on the basis of your consent, (noting that such withdrawal does not affect the lawfulness of any Processing performed prior to the date on which we receive notice of such withdrawal, and does not prevent the Processing of your Personal Data in reliance upon any other available legal bases); and</p></li>
                                    <li><p className="legal__desc"><strong>The right to lodge complaints </strong>regarding the Processing of your Relevant Personal Data with a Data Protection Authority (in particular, the Data Protection Authority of the EU Member State in which you live, or in which you work, or in which the alleged infringement occurred, each if applicable).</p></li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Subject to Applicable Law, you may also have the following additional rights regarding the Processing of your Relevant Personal Data:</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'>The right to object, on grounds relating to your particular situation, to the Processing of your Relevant Personal Data by us or on our behalf; and The right to object to the Processing of your Relevant Personal Data by us or on our behalf for direct marketing purposes.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <div className='legal_title'>
                                <p className="legal__desc">
                                    This does not affect your statutory rights.</p>
                                <p className="legal__desc">
                                    To exercise one or more of these rights, or to ask a question about these rights or any other provision of this Notice, or about our Processing of your Personal Data, please use the contact details provided in Section (18) below. Please note that:</p>
                                <ul style={{ paddingLeft: "30px" }} className='my-4'>
                                    <li><p className="legal__desc">In some cases it will be necessary to provide evidence of your identity before we can give effect to these rights; </p></li>
                                    <li><p className="legal__desc">Where your request requires the establishment of additional facts (e.g., a determination of whether any Processing is non-compliant with Applicable Law) we will investigate your request reasonably and promptly, before deciding what action to take.</p></li>
                                </ul>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Cookies and similar technologies</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Cookies and similar technologies</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>We Process Personal Data by using Cookies and similar technologies:</strong>For more information, please see our Cookie Policy.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>When you visit a Site or use an App, we will typically place Cookies onto your device, or read Cookies already on your device, subject always to obtaining your consent, where required, in accordance with Applicable Law. We use Cookies to record information about your device, your browser and, in some cases, your preferences and browsing habits. We Process Personal Data through Cookies and similar technologies, in accordance with our Cookie Policy.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Terms and Conditions</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Terms and Conditions</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>Our Terms and Conditions </strong>govern all use of our Sites, Apps and our Services.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>All use of our Sites, Apps, or Services is subject to our Terms and Conditions. We recommend that you review our Terms and Conditions regularly, in order to review any changes we might make from time to time.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-2'>Direct marketing</p>
                            <table className='border' style={{ width: "100%" }} >
                                <tr className=' border-b' >
                                    <td style={{ padding: "10px", borderBottom: "1px solid white", fontWeight: "600", fontSize: "18px" }}>Summary – Direct marketing</td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px", }} className='legal_title'>
                                        <p className='legal_desc'><strong>We Process Personal Data to contact you </strong>with information regarding Sites, Apps, or Services that may be of interest to you. You may unsubscribe for free at any time.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p>We Process Personal Data to contact you via email, telephone, direct mail or other communication formats to provide you with information regarding Sites, Apps, or Services that may be of interest to you. If we provide Sites, Apps, or Services to you, we may send information to you regarding our Sites, Apps, or Services, upcoming promotions and other information that may be of interest to you, using the contact details that you have provided to us, subject always to obtaining your prior opt-in consent to the extent required under Applicable Law.</p>
                        </div>
                        <div className='my-4'>
                            <p>You may unsubscribe from our promotional email list at any time by simply clicking on the unsubscribe link included in every promotional email we send. After you unsubscribe, we will not send you further promotional emails, but in some circumstances, we will continue to contact you to the extent necessary for the purposes of any Sites, Apps, or Services you have requested.</p>
                        </div>
                        {/*  */}
                        <div className='my-4'>
                            <p className='my-4'><strong>Definition - </strong></p>
                            <div style={{paddingLeft:"30px"}} className='my-4'>
                                <ul>
                                    <li>
                                        <p>
                                            “<strong>Adequate Jurisdiction”&nbsp;</strong>means a jurisdiction that
                                            has been formally designated by the European Commission as providing an
                                            adequate level of protection for Personal Data.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>App”&nbsp;</strong>means any application made available by us
                                            (including where we make such applications available via third party
                                            stores or marketplaces, or by any other means).
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Applicable Laws”&nbsp;</strong>means any applicable statutes,
                                            laws, ordinances, orders, judgments, decrees, rules or regulations
                                            issued by any government authority, and any judicial or administrative
                                            interpretation of any of these;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Cookie”&nbsp;</strong>means a small file that is placed on your
                                            device when you visit a website (including our Sites). In this Notice, a
                                            reference to a “Cookie” includes analogous technologies such as web
                                            beacons and clear GIFs.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Controller”&nbsp;</strong>means the entity that decides how and
                                            why Personal Data are Processed. In many jurisdictions, the Controller
                                            has primary responsibility for complying with applicable data protection
                                            laws.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Data Protection Authority”&nbsp;</strong>means an independent
                                            public authority that is legally tasked with overseeing compliance with
                                            applicable data protection laws.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>EEA”&nbsp;</strong>means the European Economic Area.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Non-Personal Data”&nbsp;</strong>means any information which
                                            does not enable a specific individual to be identified either directly
                                            or indirectly and that is not associated with an identified or
                                            reasonably identifiable individual.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Personal Data”&nbsp;</strong>has the meaning as set out at the
                                            beginning of this Privacy Policy.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Process”</strong>, “Processing” or “Processed” means anything
                                            that is done with any Personal Data, whether or not by automated means,
                                            such as collection, recording, organisation, structuring, storage,
                                            adaptation or alteration, retrieval, consultation, use, disclosure by
                                            transmission, dissemination or otherwise making available, alignment or
                                            combination, restriction, erasure or destruction.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Processor”&nbsp;</strong>means any person or entity that
                                            Processes Personal Data on behalf of the Controller (other than
                                            employees of the Controller).
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Profiling”&nbsp;</strong>means any form of automated Processing
                                            of Personal Data consisting of the use of Personal Data to evaluate
                                            certain personal aspects relating to a natural person, in particular to
                                            analyse or predict aspects concerning that natural person’s performance
                                            at work, economic situation, health, personal preferences, interests,
                                            reliability, behaviour, location or movements.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Relevant Personal Data”&nbsp;</strong>means Personal Data in
                                            respect of which we are the controller.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Sensitive Personal Data”&nbsp;</strong>means Personal Data
                                            about race or ethnicity, political opinions, religious or philosophical
                                            beliefs, trade union membership, physical or mental health, sexual life,
                                            any actual or alleged criminal offences or penalties, national
                                            identification number, or any other information that are deemed to be
                                            sensitive under Applicable Law.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Services&nbsp;</strong>has the same meaning as defined in clause
                                            4.1 of the Coinsspirit Terms and Conditions
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Standard Contractual Clauses”&nbsp;</strong>means template
                                            transfer clauses adopted by the European Commission or adopted by a Data
                                            Protection Authority and approved by the European Commission.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            “<strong>Site”&nbsp;</strong>means any website operated, or maintained,
                                            by us or on our behalf.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </B2CHeaderAndFooter>
    )
}

export default CoinSpiritPrivacyPolicy
