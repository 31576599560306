/**
 * getStatusComponent - Maps status values to their corresponding component statuses.
 * @param {string} status - The status value to map.
 * @returns {string} - The corresponding component status.
 */
export const getStatusComponent = (status) => {
    // Map status values to component statuses
const statusMap = new Map([
    // Pending statuses
	["CreatePayment", "Pending"],
	["DepositFlow:Launched", "Pending"],
	["CreateSecurePayment", "Pending"],
	["DepositFlow:AwaitingDeposit", "Pending"],
	["DepositMonitor:MonitoringMemPool", "Pending"],
	["DepositFlow:DepositByCardFlowLaunched", "Pending"],
	["DepositFlowViaWallet:Launched", "Pending"],
	["CreateSecurePaymentConfirmation", "Pending"],
	["DepositFlow:CardWhiteListingCheck", "Pending"],
	["DepositFlow:RoutingService", "Pending"],
	["DepositFlow:AMLRulesCheck", "Pending"],
	["DepositFlow:AcquirerNames", "Pending"],
	["DepositFlow:LoadBalanceCheck", "Pending"],
	["DepositFlow:AcquirerNamesBeforeRandomSelection", "Pending"],
	["DepositFlow:JwtTokenGenerated", "Pending"],
	["TPEvent:UIPageLoaded", "Pending"],
	["TPEvent:paymentInitStarted", "Pending"],
	["TPEvent:paymentStarted", "Pending"],
	["TPEvent:paymentInitCompleted", "Pending"],
	["TPEvent:paymentCompleted", "Pending"],
	["DepositFlow:Acquirer3dsEvent", "Pending"],
	["DepositFlow:TrustNotificationSecurityCheckPassed", "Pending"],
	["DepositFlow:CallbackResponseReceivedFromAcquirer", "Pending"],
	["DepositMonitor:ValidCardRequest", "Pending"],
	["DepositMonitor:DepositScheduledToSettle", "Pending"],
	["DepositMonitor:DepositSecurityCodeOK", "Pending"],
	["DepositMonitor:CryptoOrder", "Pending"],
	["DepositMonitor:SelectedLiquidityProvider", "Pending"],
	["DepositMonitor:WalletStatus", "Pending"],
	["DepositeMoniter:MerchantBalanceUpdated", "Pending"],	

	//Require Approval Status
	["DepositMonitor:DepositPendingManualApproval", "Require Approval"],
    ["WithdrawalFlow:PendingApproval", "Require Approval"],

    // Processing statuses
    ["DepositMonitor:WalletStatusAvailable", "Processing"],
    ["DepositMonitor:WalletStatusLocked", "Processing"],
    ["DepositMonitor:UpdateWalletStatus", "Processing"],
    ["DepositMonitor:NotfiyMerchant", "Processing"],
    ["DepositMonitor:DepositManualApprove", "Processing"],
    ["DepositMonitor:KYTCheckFail", "Processing"],
    ["DepositMonitor:KYTCheckPass", "Processing"],
    ["DepositMonitor:KYTCheck", "Processing"],
    ["DepositMonitor:DepositConfirmed", "Processing"],
    ["DepositMonitor:GasTooLow", "Processing"],
    ["DepositMonitor:ExcessTransactionExecuted", "Processing"],
    ["DepositMonitor:TransactionExecuted", "Processing"],
    ["DepositMonitor:TransactionExecuting", "Processing"],
    ["DepositMonitor:DepositReceivedToMemPool", "Processing"],
    ["DepositMonitor:TransactionComplete", "Processing"],
    ["DepositFlow:AcquirerSelected", "Processing"],
    ["DepositFlow:RequestingBINNDetails", "Processing"],
    ["DepositFlow:ReturnedBINNDetails", "Processing"],
    ["DepositFlow:CardSubmittedToAcquirer", "Processing"],
    ["DepositFlow:ResponseReceivedFromAcquirer", "Processing"],
    ["DepositMonitor:ReviseDepositExchangeRate", "Processing"],
    ["DepositMonitor:GasCheck", "Processing"],
    ["DepositMonitor:ReducedDepositReceivedToMemPool", "Processing"],
    ["DepositMonitor:SurplusDepositReceivedToMemPool", "Processing"],
    ["DepositMonitor:MerchantAutomationSetting", "Processing"],
    ["DepositMonitor:NoAutoTradeRequired", "Processing"],
    ["DepositMonitor:NoAutoTradeRequiredForTestnet", "Processing"],
    ["DepositMonitor:AutoTradeExecuting", "Processing"],
    ["DepositMonitor:AutoTradeFailed", "Processing"],
    ["DepositMonitor:AutoTradeExecuted", "Processing"],
    ["DepositMonitor:ExcessAutoTradeExecuted", "Processing"],
    ["DepositMonitor:AutoTradeComplete", "Processing"],
    ["DepositMonitor:MerchantAutoTradeTransactionCreated", "Processing"],
    ["DepositMonitor:AwaitingDepositConfirmation", "Processing"],
    ["DepositMonitor:DepositManualApproved", "Processing"],
    ["DepositMonitor:CalculateRevisedDepositAmount", "Processing"],
    ["DepositMonitor:NotfiyMerchantFromAdminUI", "Processing"],
    ["DepositMonitor:UpdateWalletStatus", "Processing"],
    ["DepositMonitor:UpdateWalletBalance", "Processing"],
    ["DepositMonitor:UpdateMerchantWalletBalance", "Processing"],
    ["DepositMonitor:DepositRefundLinkSent", "Processing"],
    ["DepositMonitor:GetRefundLinkClicked", "Processing"],
    ["DepositMonitor:GetRefundRequestSubmitted", "Processing"],
    ["DepositMonitor:CalculateRefundAmount", "Processing"],
    ["DepositMonitor:RefundSent", "Processing"],
    ["DepositMonitor:RefundConfirmed", "Processing"],
    ["DepositMonitor:DepositRefunded", "Processing"],
    ["DepositMonitor:NoKYTCheckRequired", "Processing"],
    ["WithdrawalFlow:NotifyMerchant", "Processing"],
    ["WithdrawalFlow:ApprovedForProcessing", "Processing"],
    ["WalletManagementService:ProcessingWithdrawal", "Processing"],
    ["WalletManagementService:CalculateWithdrawalAmount", "Processing"],
    ["WalletManagementService:UnableToSourceWithdrawalWallet", "Processing"],
    ["WalletManagementService:SourcedWithdrawalWallet", "Processing"],
    ["WalletManagementService:MerchantAutomationSetting", "Processing"],
    ["WalletManagementService:SendToRecipientWallet", "Processing"],
    ["WalletManagementService:NotifyMerchant", "Processing"],
    ["WalletManagementService:NotifyAdmin", "Processing"],
    ["WalletManagementService:UpdateWalletBalance", "Processing"],
    ["WalletManagementService:WithdrawalConfirmed", "Processing"],
    ["DepositFlowViaWallet:NoFiatSettlementTradeRequired", "Processing"],
    ["DepositFlowViaWallet:SettlementTradeExecuting", "Processing"],
    ["DepositFlowViaWallet:SettlementTradeFailed", "Processing"],
    ["DepositFlowViaWallet:SettlementTradeFailedNotifyAdmin", "Processing"],
    ["DepositFlowViaWallet:SettlementTradeExecuted", "Processing"],
    ["DepositFlowViaWallet:NotfiyMerchant", "Processing"],
    ["MerchantSettlementService:NoSettlementRequired", "Processing"],
    ["MerchantSettlementService:SettlementCompleted", "Processing"],
	["DepositMonitor:DepositProcessing", "Processing"],
	["DepositFlow:RedirectingTo3DS", "Processing"],
	["DepositFlow:RedirectedTo3DS", "Processing"],
	["DepositFlow:3dsProcessed", "Processing"],
	["DepositMonitor:GasCheckInvalid", "Processing"],
	["DepositMonitor:MerchantBalanceUpdated", "Processing"],
	["DepositMonitor:GasCheckValid", "Processing"],
	["WalletTransferFlow:TransferRequested", "Processing"],
	["WalletManagementService:ProcessingTransferRequest", "Processing"],
	["WalletManagementService:ProcessingTransfer", "Processing"],
	["WalletManagementService:TransferWallet", "Processing"],
	["WithdrawalManagementService:UpdateWalletBalance", "Processing"],
	["DepositMonitor:GasCheckValid", "Processing"],
	["MerchantAutoTradeExececuted", "Processing"],
	["DepositMonitor:NotfiyMerchantFailed", "Processing"],
	["DepositMonitor:UnableToSourceRefundWallet", "Processing"],
	["DepositMonitor:DepositHashAlreadyExist", "Processing"],
	["DepositMonitor:DepositCommunicationsFailure", "Processing"],
	["DepositMonitor:DepositSoftDeclined", "Processing"],
	["DepositMonitor:InvalidCardRequest", "Processing"],
	["DepositMonitor:DepositSuspended", "Processing"],
	["DepositMonitor:DepositCancelled", "Processing"],
	["DepositMonitor:DepositSecurityCodeIncorrect", "Processing"],
	["DepositMonitor:DepositSecurityCodeNotSent", "Processing"],
	["DepositMonitor:DepositSecurityCodeQuery", "Processing"],
	["DepositMonitor:DepositSecurityCodeOK", "Processing"],
	["DepositMonitor:DepositSettled", "Processing"],
	["DepositMonitor:DepositScheduledToSettle", "Processing"],
	["DepositMonitor:ValidCardRequest", "Processing"],	

    // Declined statuses
    ["DepositMonitor:DepositReceivedUnexpectedCurrency", "Declined"],
	["DepositMonitor:DepositDeclined", "Declined"],

     // Abandoned statuses
     ["DepositMonitor:TimedOutMonitoringMemPool", "Abandoned"],

     // Rejected statuses
     ["DepositMonitor:DepositRejected", "Rejected"],
     ["WalletManagementService:WithdrawalRejectionCompleted", "Rejected"],
     ["DepositMonitor:NotfiyRejectionToMerchant", "Rejected"],
     ["DepositMonitor:DepositManualRejected", "Rejected"],
     ["WalletManagementService:WithdrawalRejectedDueToMerchantBalance", "Rejected"],
     ["WalletManagementService:WithdrawalRejectedDueToInsufficientFunds", "Rejected"],
     ["DepositMonitor:DepositRejected", "Rejected"],
	 ["CardBuyFlow:DepositRejected", "Rejected"],

    // Success statuses
    ["success", "Success"],
    ["DepositMonitor:DepositCompleted", "Success"],
    ["DepositMonitor:DepositManuallyCompleted", "Success"],
    ["WalletManagementService:WithdrawalCompleted", "Success"],
    ["DepositFlowViaWallet:DepositCompleted", "Success"],
    ["WalletManagementService:TransferCompleted", "Success"],
    ["CardBuyFlow:DepositCompleted", "Success"],
    // 3DS failed status
    ["DepositFlow:3dsFailed", "3DS failed"],

    // Other statuses
    ["N/A", "N/A"]
]);

    // Return the corresponding component status, or "Failed" if not found
    return statusMap.get(status) || "Failed";
};
export const getStatusMessage = (highLevelStatus, detailedStatus) => {
    // Map high-level statuses to their corresponding status messages
    const statusMessageMap = new Map([

		["Pending", new Map([
			["DepositMonitor:DepositPendingManualApproval", "Pending manual approval"],
			["WithdrawalFlow:PendingApproval", "Pending approval"],
			["CreatePayment", "Payment creation pending"],
			["CreateSecurePayment", "Creating Secure Payment"],
			["DepositFlow:Launched", "Deposit flow launched"],
			["DepositFlow:AwaitingDeposit", "Awaiting deposit"],
			["DepositMonitor:MonitoringMemPool", "Monitoring mempool"],
			["DepositFlow:DepositByCardFlowLaunched", "Deposit by card flow launched"],
			["DepositFlowViaWallet:Launched", "Deposit flow via wallet launched"],
			["CreateSecurePaymentConfirmation", "Secure payment confirmation pending"],
			["DepositFlow:CardWhiteListingCheck", "Card white listing check pending"],
			["DepositFlow:RoutingService", "Routing service pending"],
			["DepositFlow:AMLRulesCheck", "AML rules check pending"],
			["DepositFlow:AcquirerNames", "Acquirer names pending"],
			["DepositFlow:LoadBalanceCheck", "Load balance check pending"],
			["DepositFlow:AcquirerNamesBeforeRandomSelection", "Acquirer names before random selection pending"],
			["DepositFlow:JwtTokenGenerated", "JWT token generation pending"],
			["TPEvent:UIPageLoaded", "UI page loaded pending"],
			["TPEvent:paymentInitStarted", "Payment initialization started pending"],
			["TPEvent:paymentStarted", "Payment started pending"],
			["TPEvent:paymentInitCompleted", "Payment initialization completed pending"],
			["TPEvent:paymentCompleted", "Payment completed pending"],
			["DepositFlow:Acquirer3dsEvent", "Acquirer 3DS event pending"],
			["DepositFlow:TrustNotificationSecurityCheckPassed", "Trust notification security check passed pending"],
			["DepositFlow:CallbackResponseReceivedFromAcquirer", "Callback response received from acquirer pending"],
			["DepositMonitor:ValidCardRequest", "Valid card request pending"],
			["DepositMonitor:DepositScheduledToSettle", "Deposit scheduled to settle pending"],
			["DepositMonitor:DepositSecurityCodeOK", "Deposit security code OK pending"],
			["DepositMonitor:CryptoOrder", "Crypto order pending"],
			["DepositMonitor:SelectedLiquidityProvider", "Selected liquidity provider pending"],
			["DepositMonitor:WalletStatus", "Wallet status pending"],
			["DepositeMoniter:MerchantBalanceUpdated", "Merchant balance updated pending"],
		])],

		["Require Approval", new Map([
            ["DepositMonitor:DepositPendingManualApproval", "Pending manual approval"],
            ["WithdrawalFlow:PendingApproval", "Pending approval"],
        ])],
        ["Processing", new Map([
			["DepositMonitor:WalletStatusAvailable", "Wallet status available"],
			["DepositMonitor:WalletStatusLocked", "Wallet status locked"],
			["DepositMonitor:UpdateWalletStatus", "Updating wallet status"],
			["DepositMonitor:NotfiyMerchant", "Notify merchant"],
			["DepositMonitor:DepositManualApprove", "Deposit manual approval"],
			["DepositMonitor:KYTCheckFail", "KYC check failed"],
			["DepositMonitor:KYTCheckPass", "KYC check passed"],
			["DepositMonitor:KYTCheck", "KYC check"],
			["DepositMonitor:DepositConfirmed", "Deposit confirmed"],
			["DepositMonitor:GasTooLow", "Gas too low"],
			["DepositMonitor:ExcessTransactionExecuted", "Excess transaction executed"],
			["DepositMonitor:TransactionExecuted", "Transaction executed"],
			["DepositMonitor:TransactionExecuting", "Transaction executing"],
			["DepositMonitor:DepositReceivedToMemPool", "Deposit received to mempool"],
			["DepositMonitor:TransactionComplete", "Transaction complete"],
			["DepositFlow:AcquirerSelected", "Acquirer selected"],
			["DepositFlow:RequestingBINNDetails", "Requesting BINN details"],
			["DepositFlow:ReturnedBINNDetails", "Returned BINN details"],
			["DepositFlow:CardSubmittedToAcquirer", "Card submitted to acquirer"],
			["DepositFlow:ResponseReceivedFromAcquirer", "Response received from acquirer"],
			["DepositMonitor:ReviseDepositExchangeRate", "Revise deposit exchange rate"],
			["DepositMonitor:GasCheck", "Gas check"],
			["DepositMonitor:ReducedDepositReceivedToMemPool", "Reduced deposit received to mempool"],
			["DepositMonitor:SurplusDepositReceivedToMemPool", "Surplus deposit received to mempool"],
			["DepositMonitor:MerchantAutomationSetting", "Merchant automation setting"],
			["DepositMonitor:NoAutoTradeRequired", "No auto trade required"],
			["DepositMonitor:NoAutoTradeRequiredForTestnet", "No auto trade required for testnet"],
			["DepositMonitor:AutoTradeExecuting", "Auto trade executing"],
			["DepositMonitor:AutoTradeFailed", "Auto trade failed"],
			["DepositMonitor:AutoTradeExecuted", "Auto trade executed"],
			["DepositMonitor:ExcessAutoTradeExecuted", "Excess auto trade executed"],
			["DepositMonitor:AutoTradeComplete", "Auto trade complete"],
			["DepositMonitor:MerchantAutoTradeTransactionCreated", "Merchant auto trade transaction created"],
			["DepositMonitor:AwaitingDepositConfirmation", "Awaiting deposit confirmation"],
			["DepositMonitor:DepositManualApproved", "Deposit manual approved"],
			["DepositMonitor:CalculateRevisedDepositAmount", "Calculate revised deposit amount"],
			["DepositMonitor:NotfiyMerchantFromAdminUI", "Notify merchant from admin UI"],
			["DepositMonitor:UpdateWalletStatus", "Update wallet status"],
			["DepositMonitor:UpdateWalletBalance", "Update wallet balance"],
			["DepositMonitor:UpdateMerchantWalletBalance", "Update merchant wallet balance"],
			["DepositMonitor:DepositRefundLinkSent", "Deposit refund link sent"],
			["DepositMonitor:GetRefundLinkClicked", "Get refund link clicked"],
			["DepositMonitor:GetRefundRequestSubmitted", "Get refund request submitted"],
			["DepositMonitor:CalculateRefundAmount", "Calculate refund amount"],
			["DepositMonitor:RefundSent", "Refund sent"],
			["DepositMonitor:RefundConfirmed", "Refund confirmed"],
			["DepositMonitor:DepositRefunded", "Deposit refunded"],
			["DepositMonitor:NoKYTCheckRequired", "No KYT check required"],
			["WithdrawalFlow:NotifyMerchant", "Notify merchant"],
			["WithdrawalFlow:ApprovedForProcessing", "Approved for processing"],
			["WalletManagementService:ProcessingWithdrawal", "Processing withdrawal"],
			["WalletManagementService:CalculateWithdrawalAmount", "Calculate withdrawal amount"],
			["WalletManagementService:UnableToSourceWithdrawalWallet", "Unable to source withdrawal wallet"],
			["WalletManagementService:SourcedWithdrawalWallet", "Sourced withdrawal wallet"],
			["WalletManagementService:MerchantAutomationSetting", "Merchant automation setting"],
			["WalletManagementService:SendToRecipientWallet", "Send to recipient wallet"],
			["WalletManagementService:NotifyMerchant", "Notify merchant"],
			["WalletManagementService:NotifyAdmin", "Notify admin"],
			["WalletManagementService:UpdateWalletBalance", "Update wallet balance"],
			["WalletManagementService:WithdrawalConfirmed", "Withdrawal confirmed"],
			["DepositFlowViaWallet:NoFiatSettlementTradeRequired", "No fiat settlement trade required"],
			["DepositFlowViaWallet:SettlementTradeExecuting", "Settlement trade executing"],
			["DepositFlowViaWallet:SettlementTradeFailed", "Settlement trade failed"],
			["DepositFlowViaWallet:SettlementTradeFailedNotifyAdmin", "Settlement trade failed, notify admin"],
			["DepositFlowViaWallet:SettlementTradeExecuted", "Settlement trade executed"],
			["DepositFlowViaWallet:NotfiyMerchant", "Notify merchant"],
			["MerchantSettlementService:NoSettlementRequired", "No settlement required"],
			["MerchantSettlementService:SettlementCompleted", "Settlement completed"],
			["DepositFlow:RedirectingTo3DS", "Redirecting to 3ds"],
			["DepositFlow:RedirectedTo3DS", "Redirected to 3ds"],
			["DepositFlow:3dsProcessed", "3ds processed"],
			["DepositMonitor:RejectDeposit", "Rejecting Deposit"],
			["DepositMonitor:GasCheckInvalid", "Gas check invalid"],
			["DepositMonitor:MerchantBalanceUpdated", "Merchant balance updated"],
			["DepositMonitor:GasCheckValid", "Gas check valid"],
			["WalletTransferFlow:TransferRequested", "Transfer requested"],
			["WalletManagementService:ProcessingTransferRequest", "Processing transfer request"],
			["WalletManagementService:ProcessingTransfer", "Processing transfer"],
			["WalletManagementService:TransferWallet", "Transfer wallet"],
			["WithdrawalManagementService:UpdateWalletBalance", "Update wallet balance"],
			["DepositMonitor:GasCheckValid", "Gas check valid"],
			["MerchantAutoTradeExececuted", "Merchant auto trade executed"],
			["DepositMonitor:NotfiyMerchantFailed", "Notify merchant failed"],
			["DepositMonitor:UnableToSourceRefundWallet", "Unable to source refund wallet"],
			["DepositMonitor:DepositHashAlreadyExist", "Deposit hash already exists"],
			["DepositMonitor:DepositCommunicationsFailure", "Deposit communications failure"],
			["DepositMonitor:DepositSoftDeclined", "Deposit soft declined"],
			["DepositMonitor:InvalidCardRequest", "Invalid card request"],
			["DepositMonitor:DepositSuspended", "Deposit suspended"],
			["DepositMonitor:DepositCancelled", "Deposit cancelled"],
			["DepositMonitor:DepositSecurityCodeIncorrect", "Deposit security code incorrect"],
			["DepositMonitor:DepositSecurityCodeNotSent", "Deposit security code not sent"],
			["DepositMonitor:DepositSecurityCodeQuery", "Deposit security code query"],
			["DepositMonitor:DepositSecurityCodeOK", "Deposit security code OK"],
			["DepositMonitor:DepositSettled", "Deposit settled"],
			["DepositMonitor:DepositScheduledToSettle", "Deposit scheduled to settle"],
    		["DepositMonitor:ValidCardRequest", "Valid card request"],
        ])],
        ["Abandoned", new Map([
            ["DepositMonitor:TimedOutMonitoringMemPool", "Abandoned by customer"],
        ])],
        ["Declined", new Map([
            ["DepositMonitor:DepositReceivedUnexpectedCurrency", "Unexpected currency"],
            ["DepositMonitor:DepositDeclined", "Deposit Declined"],
        ])],
        ["3DS failed", new Map([
			["DepositFlow:3dsFailed", "3DS authentication failed"],
        ])],
        ["Rejected", new Map([
            ["WalletManagementService:WithdrawalRejectionCompleted", "Withdrawal rejection completed"],
            ["DepositMonitor:NotfiyRejectionToMerchant", "Notify rejection to merchant"],
            ["DepositMonitor:DepositManualRejected", "Deposit manual rejected"],
            ["WalletManagementService:WithdrawalRejectedDueToMerchantBalance", "Withdrawal rejected due to merchant balance"],
            ["WalletManagementService:WithdrawalRejectedDueToInsufficientFunds", "Withdrawal rejected due to insufficient funds"],
            ["DepositMonitor:DepositRejected", "Deposit Rejected"],
            ["WithdrawalFlow:Rejected", "Withdrawal Rejected"],
            ["CardBuyFlow:DepositRejected", "Deposit Rejected"],
        ])],
        ["Success", new Map([
            ["success", "Success"],
            ["DepositMonitor:DepositCompleted", "Deposit completed"],
            ["CardBuyFlow:DepositCompleted", "Deposit completed"],
            ["DepositMonitor:DepositManuallyCompleted", "Deposit manually completed"],
            ["WalletManagementService:WithdrawalCompleted", "Withdrawal completed"],
            ["DepositFlowViaWallet:DepositCompleted", "Deposit completed via wallet"],
            ["WalletManagementService:TransferCompleted", "Transfer completed"],
            ["CardBuyFlow:DepositCompleted", "Deposit completed"],
        ])],
        ["Failed", new Map([
            ["N/A", "Full error logging"],
            ["DepositMonitor:RefundFailed", "Refund Failed"],
            ["DepositMonitor:DepositFailed", "Deposit Rejected"],
            ["CardBuyFlow:DepositFailed", "Deposit Rejected"],
        ])]
    ]);

    // Retrieve the corresponding status message based on the high-level status and detailed status
    return (statusMessageMap.get(highLevelStatus) || new Map()).get(detailedStatus) || "Unknown status";
};

export const getEmailVal = (email) => {
	const emailSplit = email ? email.split("|") : []
	const emailVal = emailSplit.length > 0 ? emailSplit[0] : null
	return emailVal
}