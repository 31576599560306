import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { apiService } from '../../../../common/apiCallService';
import { useSelector } from 'react-redux';

const ForgetPassword = ({ setForgetPassword, loading, setLoading }) => {
    const [emailId, setEmailId] = useState("")
    const [succuessMsg, setSuccuessMsg] = useState(false)
    const [error, setError] = useState("")
    const api_url = useSelector((state) => state.config.api_url)
    const handleForgetPass = (e) => {
        setLoading(true)
        e.preventDefault();
        forgotPasswordEmail()
    }
    const forgotPasswordEmail = () => {
        if (emailId && emailId !== "") {
            apiService((window.location.hostname === 'localhost' ? api_url : '') + '/restapi/forgot-password', {
                email: emailId,
                hostname: window.location.hostname,
                paymentProviderName: sessionStorage.getItem("paymentProviderName"),
                domainNameUrl: localStorage.getItem("domainNameUrl"),
            }, (data) => {
                if (data && data.success) {
                    setLoading(false)
                    setSuccuessMsg(true);
                }
            }, (err) => {
                setLoading(false)
                setError("invalid email please")
            })
        } else {

        }
    }
    return (
        <div className="kalonpay-modal">
            <h2 className='login-heading'>Forgot password?</h2>
            {succuessMsg ? <div>
                We have sent you email with instructions to reset your password
            </div> :
                <React.Fragment>
                    <p style={{ marginBottom: "10px" }}>No worries, we’ll send you reset instructions.</p>
                    {error && error !== "" && <p style={{ marginTop: "10px", marginBottom: "10px" }} className='kal-error'>{error}</p>}
                    <form onSubmit={handleForgetPass} className='login-form'>
                        <input type="email" value={emailId} placeholder='Enter your email address' required onChange={(e) => setEmailId(e.target.value)} />
                        <div><button type='submit' className='kalonpay-btn'>Reset Password</button></div>
                    </form>
                </React.Fragment>}
            <div onClick={() => setForgetPassword(false)} className='back-to-login mt-2'> <ArrowBackIcon /> <p>Back to <span >Log In</span></p></div>
        </div>
    )
}

export default ForgetPassword