import React from 'react';
import DepositSuccessfulForCreditCard from "./DepositSuccessForCreditCard.js"

class RefundSuccessForCreditCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <DepositSuccessfulForCreditCard refund="true" />
            </div>
        )
    }
}

export default RefundSuccessForCreditCard;