import React, { useState } from 'react';
import ScrollIntoView from '../../../common/scrollIntoView';
import Login from '../B2C/Login';
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../common/constants';

const B2CLogin = () => {
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(true);

  const redirectToSignUp = () => {
    navigate(constants.B2C_SIGNUP_PATH);
  };
  const redirectToLogin = () => {
    navigate(constants.B2C_LOGIN_PATH);
  };
  const handleClose = () => {
    setLoginModal(false);
    navigate('/');
  };
  return (
    <ScrollIntoView>
        <Login open={loginModal} onClose={handleClose} signUpModelOpenInLogin={redirectToSignUp} handleOpenLoginModel={redirectToLogin} />
    </ScrollIntoView>
  );
};

export default B2CLogin;
