import React, { useEffect, useState } from 'react';
import HeaderAndFooterLayout from '../../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import SingleTransactionTableRow from './b2cTransactionTableRow';
// import B2CTransactionFilters from './b2cTransactionFilter';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../common/apiCallService';
import ScrollIntoView from '../../../../common/scrollIntoView';
import * as actions from '../../../../Redux/actions';
import MediaQuery from 'react-responsive';
import { checkValueInSS, getHeaderButtonBorderStyle } from '../../../../common/theme';
import { constants } from '../../../../common/constants';
import useDebounce from '../../../../common/useDebounce';
// const searchicon = '/common/crytoicons/searchicon1.png';

const filterImg='/homeb2c/transaction-filter.svg';
let initialized = false


const B2cTransactionTable = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const navigate = useNavigate();
  const [transactionTableData, setTransactionTableData]= useState([]);
  const [totalTransations, setTotalTransactions] = useState('')

  const [orderBy, setOrderBy] = useState('dese');
  const [sortBy, setSortBy] = useState('createdDate');


  const [allTypes, setAlltypes] = useState(['buy', 'send', 'receive']);
  const [allStatus, setAllStatus] = useState(['completed', 'rejected', 'Pending']);
  const [allCryptos, setAllCryptos] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearhText] = useState('');
  const [dateSelected, setDateSelected] = useState(false);
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
    getCryptoCoins();  
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, []);

  const[showFilterModel, setShowFilterModel] = useState(false);
  const debouncedSearchText = useDebounce(searchText);
  useEffect(()=>{
    if (initialized) {
      getB2CUserTransactions()
    }
  },[debouncedSearchText])
  useEffect(()=>{
    if(localStorage.getItem("user_name")){
      if (initialized) {
        getB2CUserTransactions()
      } else {
        initialized = true
      }
     }   
  },[props.selectedCryptos,allCryptos,props.selectedStatus,allStatus,props.selectedTypes,allTypes,props.fromDate,props.toDate])


  const getB2CUserTransactions = async(page) => {
    let pageNo = 1;

    if (page) {
      pageNo = page;
    }
    let payload ={
      b2cUserId: localStorage.getItem('b2cUserId'),
      transactionId: searchText.toString(),
      cryptoId: searchText.toString(),
      orderBy: orderBy,
      sortBy: sortBy,
      page: pageNo,
      CryptoCurrency:
      props.selectedCryptos.length === 0 ||
      props.selectedCryptos.length === allCryptos.length
        ? 'All'
        : props.selectedCryptos,
      status:
      props.selectedStatus.length === 0 ||
      props.selectedStatus.length === allStatus.length
          ? 'All'
          : props.selectedStatus,
      type:
      props.selectedTypes.length === 0 ||
      props.selectedTypes.length === allTypes.length
              ? 'All'
              : props.selectedTypes,    
    }

    if (dateSelected) {
      payload = {
        ...payload,
        fromDate: props.fromDate,
        toDate: props.toDate,
      };
    }
    props.setLoading(true);
    await apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-b2c-user-transactions',
      payload,
      (data) => {
        props.setLoading(false);
        if (data) {
          if (pageNo === 1) {
          setTransactionTableData(data && data.b2cUserTransactionsList)
          setTotalTransactions(data && data.total_transactions)
          } else {
            let list = transactionTableData.concat(data.b2cUserTransactionsList);
            setTransactionTableData(list)
          }
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };


  const getCryptoCoins = () => {
    let requestBody = {};
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-cryptos',
      requestBody,
      (data) => {
        props.setLoading(false);
        setAllCryptos(data.cryptos);
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  const clearFilter =() =>{
    props.setSelectedCryptos([]);
    props.setSelectedStatus([]);
    props.setSelectedTypes([]);
    props.setFromDate('');
    props.setToDate('');
    setShowFilterModel(!showFilterModel)
  }
  let headerButtonBorderStyle = getHeaderButtonBorderStyle(b2cColorMode, headerButtonDarkBgColour, headerButtonLightBgColour)
  
  return (
    <div className="TR_table">
      <ScrollIntoView>
        <HeaderAndFooterLayout >
          <div>
            <MediaQuery minWidth={786} >
              <div style={{ paddingTop: '108px' }}></div>
              <div style={{padding:'0px 30px'}}>
              <div className="transactionTable__container">
                <div className="TR__table__content">
                  <div className="table__heading">
                    <div className="heading">
                      <i 
                          onClick={() => navigate('/user-wallet-home')} 
                      className="fa fa-chevron-left" aria-hidden="true"></i>
                      <span>All Transactions</span>
                    </div>
                    <div className={b2cTheme === 'dark' ? 'searchInput searchInputDark' : 'searchInput'}>
                      <input type="text" value={searchText} 
                        onChange={(e) => {
                              setSearhText(e.target.value);
                            }}
                      placeholder="Search Transaction" data-e2e="search-transaction-textbox" />
                      {/* <img src={`${image_base_url}${searchicon}`} alt="searchicon" data-e2e="search icon" /> */}
                      <i className="fa fa-search serchIcon" aria-hidden="true"></i>
                    </div>
                  </div>
                  {/* <div>
                    <B2CTransactionFilters 
                                fromDate={props.fromDate}
                                toDate={props.toDate}
                                setFromDate={props.setFromDate}
                                setToDate={props.setToDate}
                                allTypes={allTypes}
                                setAlltypes={setAlltypes}
                                selectedTypes={props.selectedTypes}
                                setSelectedTypes={props.setSelectedTypes}
                                allStatus={allStatus}
                                setAllStatus={setAllStatus}
                                selectedStatus={props.selectedStatus}
                                setSelectedStatus={props.setSelectedStatus}
                                allCryptos={allCryptos}
                                setAllCryptos={setAllCryptos}
                                selectedCryptos={props.selectedCryptos}
                                setSelectedCryptos={props.setSelectedCryptos}
                                dateSelected={dateSelected}
                                setDateSelected={setDateSelected}

                    />
                  </div> */}

                  {transactionTableData && transactionTableData.length > 0 ? (
                    <div className="TransactionTable" >
                      {transactionTableData.map((TData, i) => {
                        return (
                            <SingleTransactionTableRow
                              data={TData}
                              key={i}
                              />
                        );
                      })}
                    </div>
                  ):(<div className='No_Transactions_Found text__color'>
                    NO Transaction Found.
                  </div>)}
                </div>
              </div>
              {totalTransations && transactionTableData && transactionTableData.length < totalTransations ? <div className="Show_more_transactions_wrap mt-50"><div className="Show_more_transactions"
                style={{...headerButtonBorderStyle}}
                  onClick={() => {
                      setPage(page + 1)
                      getB2CUserTransactions(page + 1)
                      }} data-e2e="showMore">Show More</div></div> : null
              }
              </div> 
            </MediaQuery>
            

          
            <MediaQuery maxWidth={786} >
              <div>
                <div style={{ paddingTop: '66px' }}></div>
                <div className='transaction_mobile'>
                {
                  !showFilterModel && 
                    <div className="transactionTable__container">
                    <div className="TR__table__content">
                      <div className="table__heading">
                        <div className="heading">
                          <i 
                          onClick={() =>navigate('/user-wallet-home')} 
                          className="fa fa-chevron-left" aria-hidden="true"></i>
                          <span>All Transactions</span>
                        </div>

                        <div onClick={()=> setShowFilterModel(!showFilterModel)}>
                          <img src={`${image_base_url}${filterImg}`} alt='filter' />
                        </div>

                      </div>
        
                     

                      {transactionTableData && transactionTableData.length > 0 ? (
                        <div className="TransactionTable" >
                          {transactionTableData.map((TData, i) => {
                            return (
                                <SingleTransactionTableRow
                                  data={TData}
                                  key={i}
                                  />
                            );
                          })}
                          {totalTransations && transactionTableData && transactionTableData.length < totalTransations ? 
                          <div className="showMore_mobile_wrap"><div className="showMore_mobile"
                            onClick={() => {
                            setPage(page + 1)
                            getB2CUserTransactions(page + 1)
                            }} data-e2e="showMore">Show More</div></div> : null
                          }
                        </div>
                      ):(<div className='No_Transactions_Found text__color'>
                        NO Transaction Found.
                      </div>)}
                    </div>
                    </div>
                }
                </div> 
              </div> 
            </MediaQuery>
          </div>  
          {
            showFilterModel && (
            <div className='menu_container menu_container_filter' style={{position:'fixed'}}>
              <div className="menu_content_container">
                <div className="transactionTable__container m-0 shadow-none ">
                    <div className="TR__table__content">
                      <div className="table__heading p-0 d-block bg-transparent border-bottom-0">
                        <div className="transaction_filters">
                          <i 
                            onClick={() => { setShowFilterModel(!showFilterModel) }} 
                          className="fa fa-chevron-left" aria-hidden="true"></i>
                          <span>{`Transaction Filters`}</span> <span className='clear_transaction_filter' onClick={clearFilter}>{`Clear`}</span>
                        </div>

                        {/* <div className='mobile_transaction_content'>
                              <B2CTransactionFilters
                                showFilterModel = {showFilterModel}
                                setShowFilterModel = {setShowFilterModel}
                                fromDate={props.fromDate}
                                toDate={props.toDate}
                                setFromDate={props.setFromDate}
                                setToDate={props.setToDate}
                                allTypes={allTypes}
                                setAlltypes={setAlltypes}
                                selectedTypes={props.selectedTypes}
                                setSelectedTypes={props.setSelectedTypes}
                                allStatus={allStatus}
                                setAllStatus={setAllStatus}
                                selectedStatus={props.selectedStatus}
                                setSelectedStatus={props.setSelectedStatus}
                                allCryptos={allCryptos}
                                setAllCryptos={setAllCryptos}
                                selectedCryptos={props.selectedCryptos}
                                setSelectedCryptos={props.setSelectedCryptos}
                                dateSelected={dateSelected}
                                setDateSelected={setDateSelected}
                                totalTransations={totalTransations}

                              />
                        </div> */}

                      </div>                      
                    </div>
                </div>

              </div>
            </div>
          )
        }
           
        </HeaderAndFooterLayout>
      </ScrollIntoView>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    toDate: state.common.toDate,
    fromDate: state.common.fromDate,
    selectedTypes: state.common.selectedTypes,
    selectedStatus: state.common.selectedStatus,
    selectedCryptos: state.common.selectedCryptos,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setSearchValue: (value) => dispatch(actions.setSearchValue(value)),
    setFromDate: (value) => dispatch(actions.setFromDate(value)),
    setToDate: (value) => dispatch(actions.setToDate(value)),
    setSelectedTypes: (value) => dispatch(actions.setSelectedTypes(value)),
    setSelectedStatus: (value) => dispatch(actions.setSelectedStatus(value)),
    setSelectedCryptos: (value) => dispatch(actions.setSelectedCryptos(value)),
  }  
};

export default connect(mapStateToProps, mapDispatchToProps)(B2cTransactionTable) ;

