import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const CorrectUploadImage = '/homeb2c/upload_photo_correct.webp';
const InCorrectUploadImageOne = '/homeb2c/upload_photo_incorrect_one.webp';
const InCorrectUploadImageTwo = '/homeb2c/upload_photo_incorrect_two.webp';
const PhoneIcon = '/homeb2c/phone_icon.webp';
const Selfi = '/homeb2c/selfi.webp';

const VerifyModel = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [openCountryOption, setOpenCountryOption] = useState(false);

  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [openDocumentOption, setOpenDocumentOption] = useState(false);

  const country = [
    {
      country_name: 'UK',
    },
    {
      country_name: 'AUS',
    },
  ];

  const document = [
    {
      document_name: 'Drivers Licence',
    },
    {
      document_name: 'Voter ID',
    },
  ];

  const handleClose = () => {
    props.onClose();
  };

  const handleBack = () => {
    if (stepTwo) {
      setStepOne(true)
      setStepTwo(false)
    } else if (stepThree) {
      setStepTwo(true)
      setStepThree(false)
    } else if (stepFour) {
      setStepThree(true)
      setStepFour(false)
    } 
  };

  const handleFirtStep = () => {
    setStepFour(false);
    setStepThree(false);
    setStepTwo(true);
    setStepOne(false);
  };

  const handleContinue = () => {
    props.onClose();
  };

  const handleIamReady = () => {
    setStepThree(false);
    setStepFour(true);
    setStepTwo(false);
    setStepOne(false);
  };
  const handleUpload = () => {
    setStepThree(true);
    setStepFour(false);
    setStepTwo(false);
    setStepOne(false);
  };
  const handleCountry = () => {
    setOpenCountryOption(!openCountryOption);
    setOpenDocumentOption(false)
  };
  const handleSelectCountryOptions = (country) => {
    setSelectedCountry(country);
    setOpenCountryOption(false);
  };
  const handleDocument = () => {
    setOpenDocumentOption(!openDocumentOption);
    setOpenCountryOption(false)
  };
  const handleSelectDocumentOptions = (document) => {
    setSelectedDocumentType(document);
    setOpenDocumentOption(false);
  };
  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} className="b2c-signup-model b2c-login-model receive_model_mobile">
        <div className="enable-authenticator-model">
          <Card style={{ width: '461px'}} className='card-bg'>
            <div className="enable-authenticator-header">
              {!stepOne && (
                <div className="signup-close-icon cursor-pointer">
                  <ArrowBackIcon onClick={() => handleBack()} className="icon-close" style={{ color: '#FFFFFF' }} />
                </div>
              )}
              <div>{`Verify ID`}</div>
              <div className="signup-close-icon">
                <ClearIcon onClick={() => handleClose()} className="icon-close" style={{ color: '#FFFFFF' }} data-e2e="close-icon" />
              </div>
            </div>
            <div className="enable-authenticator-content">
              <div className="verify-onboard-heading">
                {stepTwo ? 'Upload a photo of your drivers license.' : stepThree ? 'Selfie' : stepFour ? 'Thank you' : ''}
              </div>
              <div className="verify-onboard-sub-heading mt-20 mb-20">
                {stepTwo
                  ? 'The photo should be clearand the whole ID visible.'
                  : stepThree
                  ? 'Face the Camera. Ensure your face is wthin the frame. Then slowly turn your head around circle.'
                  : stepFour
                  ? 'We are currently checking your data. The verification status will update automatically.'
                  : ''}
              </div>
              {stepOne && (
                <div>
                  <div id="sumsub-websdk-container"></div>
                  {/* <div className="select-options-container mb-30">
                    <div className="verify-onboard-sub-heading mb-20">Select Country that issued your document</div>
                    <div
                      className={openCountryOption ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
                      onClick={() => handleCountry()}
                    >
                      <div className="verify-onboard-sub-heading">{selectedCountry && selectedCountry.length > 0 ? selectedCountry : 'Select option'}</div>
                      <div>{openCountryOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
                    </div>
                    {openCountryOption && (
                      <div className="kyc-options-container verify-onboard-sub-heading">
                        {country.map((ele, index) => {
                          return (
                            <div className="kyc-options cursor-pointer">
                              <div key={index} onClick={() => handleSelectCountryOptions(ele.country_name)}>
                                {ele.country_name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div> */}
                  {/* <div className="select-options-container">
                    <div className="verify-onboard-sub-heading mb-20">Choose your document type</div>
                    <div
                      className={openDocumentOption ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
                      onClick={() => handleDocument()}
                    >
                      <div className="verify-onboard-sub-heading">
                        {selectedDocumentType && selectedDocumentType.length > 0 ? selectedDocumentType : 'Select option'}
                      </div>
                      <div>{openDocumentOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
                    </div>
                    {openDocumentOption && (
                      <div className="kyc-options-container verify-onboard-sub-heading">
                        {document.map((ele, index) => {
                          return (
                            <div className="kyc-options cursor-pointer">
                              <div key={index} onClick={() => handleSelectDocumentOptions(ele.document_name)}>
                                {ele.document_name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <button className="b2c-create-account kyc-upload mb-20" onClick={() => handleFirtStep()} style={{position: 'absolute',bottom: '0px',width: '90%'}}>
                    Continue
                  </button> */}
                </div>
              )}
              {stepTwo && (
                <div>
                  <div className="verfiy-onboard-pictures" style={{ marginBottom: '100px' }}>
                    <div>
                      <img src={`${image_base_url}${CorrectUploadImage}`} alt="upload-image" width="110px" height={'84px'} />
                    </div>
                    <div>
                      <img src={`${image_base_url}${InCorrectUploadImageOne}`} alt="upload-image" width="110px" height={'84px'} />
                    </div>
                    <div>
                      <img src={`${image_base_url}${InCorrectUploadImageTwo}`} alt="upload-image" width="110px" height={'84px'} />
                    </div>
                  </div>
                  <div className="mt-20">
                    <button className="b2c-create-account kyc-upload mb-30" onClick={() => handleUpload()}>
                      Upload
                    </button>
                    <button data-e2e="continue-on-your-phone" className="b2c-create-account kyc-continue-on-phn mb-10" onClick={() => handleUpload()}>
                      <span className="mr-10">
                        <img src={`${image_base_url}${PhoneIcon}`} alt="upload-image" width="17px" height={'25px'} />
                      </span>
                      Continue on your phone
                    </button>
                  </div>
                </div>
              )}
              {stepThree && (
                <div style={{ textAlign: 'center' }}>
                  <div className="mb-20">
                    <img src={`${image_base_url}${Selfi}`} alt="upload-image" width="171px" height={'171px'} />
                  </div>
                  <button className="b2c-create-account mb-20 mt-20" style={{ width: '160px', height: '50px' }} onClick={() => handleIamReady()}>
                    I'm Ready
                  </button>
                </div>
              )}
              {stepFour && (
                <button className="b2c-create-account kyc-upload mb-20 kyc-continue-btn" style={{position: 'absolute',bottom: '0px',width: '90%'}} onClick={() => handleContinue()}>
                  Continue
                </button>
              )}
            </div>
          </Card>
        </div>
      </Dialog>
    </div>
  );
};

export default VerifyModel;