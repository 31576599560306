import * as actionTypes from '../actions/actionTypes'

const initialState = {
    cryptos: [],
};
const saveCryptos = (state, action) => {
    return {
        ...state,
        cryptos: action.data,
    }
}
const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_CRYPTOS: return saveCryptos(state, action);
        default:
            return state;
    }
};

export default transactionReducer;