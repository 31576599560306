import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import * as actions from '../../../../Redux/actions';
import { checkValueInSS, getHeaderButtonBorderStyle } from '../../../../common/theme';
import EmailVerfication from '../EmailVerfication';
import { saveToSessionStorage } from '../../../../common/LoadingAndRedirection';
import { removeHttp } from '../../../../common/getIP';

function SingUp({ open, handleLoginModelOpen, setLoading, handleOpenLoginModel = {} }) {
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		privacyPolicy: false,
	});
	const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	const [openEmailVerifycation, setOpenEmailVerification] = useState(false);
	const [emailForVerifycation, setEmailForVerification] = useState("");
  
	const handleEmailValidation = (email) => {
		setEmailForVerification(email)
		setOpenEmailVerification(true)
	}

	const navigate = (path) => {
		window.open(path, '_blank');
	};
	const signUpFooterArr = [
		{
			title: "External Privacy Policy Notice",
			method: ()=>navigate("/external-privacy-policy-notice"),
		},
		{
			title: "Terms of Use",
			method: ()=>navigate("/terms-of-use"),
		},
	]
	useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
		if (!localStorage.getItem("domainNameUrl")|| !sessionStorage.getItem("domainId")) {
			getPaymentProviderForDomain()
		}
	}, [])

	const getPaymentProviderForDomain = async () => {
		try {
			await apiService((window?.location?.hostname === "localhost" ? api_url : "") + '/restapi/get-payment-provider-for-domain', {
				redirectUrl: removeHttp(window?.location?.hostname)
			}, (data) => {
				saveToSessionStorage(data)
				if (checkValueInSS("headerButtonDarkBgColour")) {
					setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
				}
				if (checkValueInSS("headerButtonLightBgColour")) {
					setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
				}
			}, (err) => {
				alert("Domain is not configured to any payment provider!")
				console.error(err);
			})
		} catch (err) {
			console.error(err)
		}
	}
	const handleChange = (e) => {
		setError('');
		setData({ ...data, [e.target.name]: e.target.value });
	};
	const handlePrivacyPolicy = (e) => {
		setError('');
		setData({ ...data, [e.target.name]: !data.privacyPolicy });
	};

	const handleLogin = () => {
		handleLoginModelOpen();
	};
	const [error, setError] = useState('');

	const disabled =
		error !== '' ||
			data.firstName.length < 0 ||
			data.firstName === '' ||
			data.lastName.length < 0 ||
			data.lastName === '' ||
			data.email.length < 0 ||
			data.email === '' ||
			data.password.length < 0 ||
			data.password === '' ||
			data.privacyPolicy === false
			? true
			: false;
	const api_url = useSelector((state) => state.config.api_url);


	const createAccountHandler = (dataObj) => {
		setLoading(true);
		apiService(
			(window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-signup',
			dataObj,
			(data) => {
				setLoading(false);
				if (data) {
					if (data.success === true && (data.code === 1 || data.code === 2)) {
						handleEmailValidation(dataObj.user_email);
					} else {
						setError(data.msg ? data.msg : 'Something went wrong! Failed to create account.');
					}
				} else {
					setError('Something went wrong! Failed to create account.');
				}
			},
			(err) => {
				setLoading(false);
				setError('Something went wrong! Failed to create account.');
				console.log(err);
			},
		);
	};

	let headerButtonStyle = {}
	if (b2cColorMode === "dark") {
		if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
			headerButtonStyle.background = headerButtonDarkBgColour;
			headerButtonStyle.borderColor = headerButtonDarkBgColour;
		}
		if (headerDarkTextColour && headerDarkTextColour !== "") {
			headerButtonStyle.color = headerDarkTextColour;
		}
	} else {
		if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonLightBgColour;
			headerButtonStyle.background = headerButtonLightBgColour;
			headerButtonStyle.borderColor = headerButtonLightBgColour;
		}
		if (headerLightTextColour && headerLightTextColour !== "") {
			headerButtonStyle.color = headerLightTextColour;
		}
	}

	let headerButtonBorderStyle = getHeaderButtonBorderStyle(b2cColorMode, headerButtonDarkBgColour, headerButtonLightBgColour)

	return (
		<div>
			{!emailForVerifycation && <Dialog open={open} className="theme-modal">
				<div className="theme-modal-body">
					<p className="theme-modal-title">Create Account</p>
					<div className="theme-modal-field mb">
						<p className="theme-modal-field-label">First Name</p>
						<input
							type="text"
							className="theme-modal-field-input"
							placeholder="First name"
							name="firstName"
							defaultValue={data.firstName}
							onChange={(e) => handleChange(e)}
							data-e2e="firstName"
						/>
					</div>
					<div className="theme-modal-field mb">
						<p className="theme-modal-field-label">Last Name</p>
						<input
							type="text"
							className="theme-modal-field-input"
							placeholder="Last name"
							name="lastName"
							defaultValue={data.lastName}
							onChange={(e) => handleChange(e)}
							data-e2e="lastName"
						/>
					</div>
					<div className="theme-modal-field mb">
						<p className="theme-modal-field-label">Email</p>
						<input
							type="text"
							className="theme-modal-field-input"
							placeholder="johnsmith@payment.com"
							name="email"
							defaultValue={data.email}
							onChange={(e) => handleChange(e)}
							data-e2e="email"
						/>
					</div>
					<div className="theme-modal-field mb">
						<p className="theme-modal-field-label">Password</p>
						<input
							type="password"
							className="theme-modal-field-input"
							placeholder="Password (at least 8 characters)"
							name="password"
							defaultValue={data.password}
							onChange={(e) => handleChange(e)}
							data-e2e="password"
						/>
					</div>
					<label htmlFor="accept" className="theme-modal-checkbox">
						<input id="accept" data-e2e="checkbox" type="checkbox" name="privacyPolicy" checked={data.privacyPolicy} onChange={(e) => handlePrivacyPolicy(e)} />
						I certify that I am 18 years of age or older and agree to the User Agreement and Privacy Policy
					</label>
					{error !== '' && <div className="errorMessage" data-e2e={error}>{error}</div>}
					<div className="theme-btn-block full-width theme-modal-submit">
						<button className={`theme-btn ${disabled ? 'disabled' : ''}`} data-e2e="createAccount" disabled={disabled}
							onClick={() => {
								const domainName = localStorage.getItem("domainNameUrl")
								const domainNameUrl = domainName.replace(/^www\./, '')
								createAccountHandler({
									firstName: data.firstName,
									lastName: data.lastName,
									user_email: data.email,
									password: data.password,
									domainNameUrl: domainNameUrl,
									paymentProviderName: sessionStorage.getItem("paymentProviderName"),
									...(sessionStorage.getItem("paymentProviderId") && {
										paymentProviderId: sessionStorage.getItem("paymentProviderId"),
									}),
								});
							}}
							style={{ ...headerButtonStyle }}
						>
							<p>Create Account</p>
						</button>
					</div>
					<div className="theme-modal-account">
						Already have an account?{' '}
						<button type="button" className="theme-modal-account-btn" onClick={handleLogin} data-e2e="logIn" style={{ ...headerButtonBorderStyle }}>Log In</button>
					</div>
					{
						signUpFooterArr && signUpFooterArr.map((footer, i)=>(
							<div key={i} className="theme-modal-account">
							<button type="button" className="theme-modal-account-btn" onClick={footer.method}  style={{ ...headerButtonBorderStyle }}>{footer.title}</button>
						</div>
						))
					}
				</div>
			</Dialog>
		}
			{openEmailVerifycation && (
        <EmailVerfication
          user_email={emailForVerifycation}
          openLogin={() => {
            handleOpenLoginModel();
          }}
          open={openEmailVerifycation}
          onClose={()=>{
			  setEmailForVerification(false)
			  handleLogin();
		  }}
        />
      )}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.common.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (value) => dispatch(actions.setLoading(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingUp);
