import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions/index';
import { constants } from '../../../common/constants';
// const WAValidator = require('wallet-address-validator');
const logoLight = "/common/crytoicons/cryptonpay_logo_light.svg";
const logoDark = "/common/crytoicons/cryptonpay_logo_dark.svg";
const approveIcon = "/common/crytoicons/approve.png"
class RefundDeposit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lightFlag: false,
            depositFlag:true,
            merchantUserId:"",
            cryptoCurrency:"",
            refundAmount:"",
            address:"",
            uniqueId:"",
            transactionId:"",
            status:"initiated",
            error: "",
        }
    }

    componentDidMount = async() => {
        if (sessionStorage.getItem("lightFlag")) {
            this.setState({ lightFlag: (sessionStorage.getItem("lightFlag") === "true") })
        } else {
            sessionStorage.setItem("lightFlag", false);
        }
        if (queryString.parse(this.props.location.search).uniqueId) {
            this.setState({ uniqueId: queryString.parse(this.props.location.search).uniqueId });
            await this.getRefundDetails(queryString.parse(this.props.location.search).uniqueId);
          } else {
            alert("uniqueId is missing! Try again with correct URL.")
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
              uniqueId: null,
              component: "QuickDepositDialog",
              severity: null,
              errorCode: "406",
              errorMessage: "Landing Error: UniqueId is missing! Try again with correct URL.",
              postMvpComment: null,
            }, (data) => {
            }, (err) => {
              console.log("err:", err)
            })
          }
    }

    submitRefundTransaction = async () => {
        let body = {
          email: localStorage.getItem('email'),
          address: this.state.address,
          uniqueId:this.state.uniqueId,
          transactionId:this.state.transactionId
        };
        this.props.setLoading(true)
        await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/submit-refund-transaction', 
        body
        , async (data) => {
          if(data){
            this.setState({
                depositFlag: false
            })
          }
          
          this.props.setLoading(false)
        }, (err) => {
          console.log("errrr", err)
          apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
            uniqueId: this.state.uniqueId,
            component: "RefundDialog",
            severity: null,
            errorCode: err.code ? err.code : "406",
            errorMessage: err.message ? "GetRefundDetails API Error: " + err.message : "Error in GetRefundDetails API",
            postMvpComment: null,
          }, (data) => {
          },
            (err) => {
              console.log("err:", err)
            })
          this.props.setLoading(false)
        })
      };

    getRefundDetails = async(uniqueId) => {
          this.props.setLoading(true)
          await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-refund-link', {
            uniqueId,
            email: localStorage.getItem("user_name"),
          }, async (data) => {
            if(data){
                let refundLink = data.refundLink;
                let statusCode = refundLink.transactions.status.code;
                let status = this.getStatus(statusCode);
                let flag = false;
                if((statusCode == "DepositMonitor:DepositRejected") || (statusCode == "DepositMonitor:GetRefundLinkClicked")){
                    flag =  true;
                }
                this.setState({
                    merchantUserId:refundLink.merchantUserId,
                    cryptoCurrency:refundLink.cryptoCurrency,
                    refundAmount:refundLink.refundAmount,
                    transactionId:refundLink.transactions.transactionId,
                    depositFlag:flag,
                    status:status
                })
            }
            
            this.props.setLoading(false)
          }, (err) => {
            console.log("errrr", err)
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
              uniqueId,
              component: "RefundDialog",
              severity: null,
              errorCode: err.code ? err.code : "406",
              errorMessage: err.message ? "GetRefundDetails API Error: " + err.message : "Error in GetRefundDetails API",
              postMvpComment: null,
            }, (data) => {
            },
              (err) => {
                console.log("err:", err)
              })
            this.props.setLoading(false)
          })
      }

    toggleModal = () =>{
        this.setState({
            depositFlag: false
        })
    }
    getStatus = (status) => {
        switch (status) {
            case "DepositMonitor:GetRefundRequestSubmitted":
                return "initiated"
            case "DepositMonitor:DepositRefunded":
                return "success";  
            default:
                return "inprocess";
        }
    }
    getCryptoName = (crypto) => {
        switch (crypto) {
            case "BTC":
                return "Bitcoin";
            case "ETH":
            case "USDC":
            case "USDT":
            case "DAI":
                return "Ethereum";
            case "LTC":
                return "Litecoin";
            case "XRP":
                return "Ripple";  
            case constants?.TRC20_USDT_CRYPTO_ID:
            case constants?.TRX_CRYPTO_ID:
                return "Tron"  
            default:
                return "";
        }
    }  
    inputChange = (e) =>{
        this.setState({ error: "" })
        this.setState({ address: e.target.value }, () => {
            // if (this.state.cryptoCurrency && this.state.cryptoCurrency !== "") {
            //     let validateCurrency = ["USDT", "USDC", "DAI"].includes(this.state.cryptoCurrency) ? "ETH" : this.state.cryptoCurrency
            //     var valid = WAValidator.validate(e.target.value, validateCurrency);
            //     if (validateCurrency === "LTC") {
            //         valid = true;
            //     }
            //     if (!valid) {
            //         console.error(`INVALID ${validateCurrency} address!`);
            //         this.setState({ error: `INVALID ${validateCurrency} address!` })
            //     }
            // }
        });
    }
    render() {
        return (
            <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " refund_screen_new"}>
                <div className="refund_screen_wrap">
                {this.state.depositFlag ?<div className={this.state.lightFlag ? "background_color_white refund_wrap_box_white refund_deposit_card" : "refund_deposit_card"}>
                         <div className='refund_deposit_div'>
                            <div className='refund_deposit_header'>Refund Deposit</div>
                            <div className='refund_deposit_content1'>In order to make a deposit refund all you need is to fill the wallet address and we will do the rest.</div>
                                <div className='refund_deposit_address'>
                                    Address
                                </div>
                                <input
                                    type='text'
                                    name="address"
                                    className='refund_deposit_input'
                                    value={this.state.address}
                                    onChange={(e)=> this.inputChange(e)}
                                    placeholder="Enter wallet address…"
                                />
                            <div className='refund_deposit_border'></div>
                            <div className='refund_deposit_box'>
                            <div className='refund_deposit_text'>
                                <div>
                                    Currency:
                                </div>
                                <div className='refund_right_align'>
                                    {this.state.cryptoCurrency }
                                </div>
                            </div>
                            <div className='refund_deposit_border'></div>
                            <div className='refund_deposit_text'>
                                <div>
                                    User ID:
                                </div>
                                <div className='refund_right_align'>
                                {this.state.merchantUserId }
                                </div>
                            </div>
                            <div className='refund_deposit_border'></div>
                            <div className='refund_deposit_text'>
                                <div>
                                    Refund Amount
                                </div>
                                <div className='refund_right_align'>
                                {this.state.refundAmount+" "+this.state.cryptoCurrency}
                                </div>
                            </div>
                            </div>
                            {this.state.error != "" && <div style={{ color: "red" }}>{this.state.error}</div>}
                            <div className='refund_deposit_border'></div>
                            {this.state.address && this.state.error == "" ? <div className='refund_btn'>
                                <div className='refund_btn_text' onClick={() => this.submitRefundTransaction()}>Submit</div>
                            </div>
                                : <div className='refund_btn_disable'>
                                    <div className='refund_btn_text' >Submit</div>
                                </div>}
                            
                        </div>

                    </div>:
                        <div className={this.state.lightFlag ? "background_color_white refund_wrap_box_white refund_summary_card" : "refund_summary_card"}>
                         <div className='refund_image_div'>
                         <img className="refund_top_image" src={`${this.props.image_base_url}${approveIcon}`} alt="logo" />
                         </div>
                         
                        <div className='refund_deposit_div'>
                            {this.state.status == "success" && <div className='refund_deposit_header'>Refund Successful</div>}
                            {this.state.status == "initiated" && <div className='refund_deposit_header'>Refund Initiated</div>}
                            {this.state.status == "inprocess" && <div className='refund_deposit_header'>Refund In Process</div>}
                            {this.state.status != "success" && <div className='refund_deposit_header_content'>We will send your refund to selected address shortly</div>}
                            <div className='refund_deposit_box'>
                            <div className='refund_deposit_text'>
                                <div>
                                Address:
                                </div>
                                <div className='refund_right_align'>
                                {this.state.address ? (this.state.address.substring(0, 7) + "..." + this.state.address.substring(this.state.address.length - 3, this.state.address.length)) : ""}
                                </div>
                            </div>
                            <div className='refund_deposit_border'></div>
                            <div className='refund_deposit_text'>
                                <div>
                                Currency:
                                </div>
                                <div className='refund_right_align'>
                                {this.state.cryptoCurrency} / ({this.getCryptoName(this.state.cryptoCurrency)})
                                </div>
                            </div>
                            <div className='refund_deposit_border'></div>
                            <div className='refund_deposit_text'>
                                <div>
                                Amount:
                                </div>
                                <div className='refund_right_align'>
                                {this.state.refundAmount+" "+this.state.cryptoCurrency}
                                </div>
                            </div>
                            <div className='refund_deposit_border'></div>
                            <div className='refund_deposit_text'>
                                <div>
                                    User ID:
                                </div>
                                <div className='refund_right_align'>
                                {this.state.merchantUserId }
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        image_base_url: state.config.image_base_url,
        payment_api_url: state.config.payment_api_url,
        api_url: state.config.api_url,
    }
}
  

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch(actions.setLoading(value)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RefundDeposit);
