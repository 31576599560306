import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import localDb from "../../../../common/localDb";
import { checkValueInSS } from "../../../../common/theme";

export default function PsB2cSafe(props) {
	const { handleOpenLoginModel } = props;
	const image_base_url = useSelector((state) => state.config.image_base_url);
	const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])

	let headerButtonStyle = {}
	if (b2cColorMode === "dark") {
		if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
			headerButtonStyle.background = headerButtonDarkBgColour;
			headerButtonStyle.borderColor = headerButtonDarkBgColour;
		}
		if (headerDarkTextColour && headerDarkTextColour !== "") {
			headerButtonStyle.color = headerDarkTextColour;
		}
	} else {
		if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonLightBgColour;
			headerButtonStyle.background = headerButtonLightBgColour;
			headerButtonStyle.borderColor = headerButtonLightBgColour;
		}
		if (headerLightTextColour && headerLightTextColour !== "") {
			headerButtonStyle.color = headerLightTextColour;
		}
	}

	return (
		<section className="section psB2cSafe">
			<div className="content-container">
				<div className="safe__content">
					<AnimationOnScroll animateIn="animate__fadeIn">
						<img width="840" height="450" className="safe__bg" loading="lazy" src={`${image_base_url}/paysu/safe-frame.webp`} />
					</AnimationOnScroll>
					<AnimationOnScroll animateIn="animate__fadeInDown">
						<div className="section-upperTitle">
							<p>Wallet</p>
						</div>
					</AnimationOnScroll>
					<AnimationOnScroll animateIn="animate__fadeInUp">
						<p className="section-title mb-subtitle">Safe & Transparent</p>
					</AnimationOnScroll>
					<p className="section-text t-center">Pro-level control to manage your cryptocurrency in one beautiful dashboard.<br />Easy to use All-in One Solution.</p>
					<div className="theme-btn-block mt-subtitle center full-on-mobile">
						{!localDb.getVal("token") &&
							<button type="button" onClick={handleOpenLoginModel} className="paysu-btn" style={{ ...headerButtonStyle }}>
								<p>Get started</p>
							</button>
						}
					</div>
				</div>
			</div>
		</section>
	)
}