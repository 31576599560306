import React, { useState } from 'react';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Table } from 'reactstrap';
import MediaQuery from 'react-responsive';
import copy from 'copy-to-clipboard';

const InvoiceTable = (props) => {
  const { sortBy, orderBy, data, changeOrder } = props;
  const getFiatCurrencySymbol = (fiatCurrency) => {
    switch (fiatCurrency) {
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'EUR':
        return '€';
      case 'RUB':
        return 'р.';
      case 'JPY':
        return '¥';
      default:
        return fiatCurrency;
    }
  };
  return (
    <div className="TransactionTableMain">
      {data && data.length > 0 && (
        <div>
          <MediaQuery minWidth={481}>
            <Table responsive>
              <tbody className="TransactionTable_BODY">
                <tr>
                  <th className="TransactionTable_heading" width="11%">
                    REFERENCE
                  </th>
                  <th className="TransactionTable_heading" width="10%">
                    INVOICE ID
                  </th>
                  <th className="TransactionTable_heading" width="15%">
                    ASSET
                  </th>
                  <th className="TransactionTable_heading" width="20%">
                    INVOICE URL
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="13%"
                    onClick={() => {
                      changeOrder('creationDate');
                    }}
                  >
                    DATE & TIME {sortBy === 'creationDate' && <SortByIcon orderBy={orderBy} />}
                  </th>
                  <th className="TransactionTable_heading" width="12%">
                    FIAT AMOUNT
                  </th>
                  <th className="TransactionTable_heading" width="15%">
                    CRYPTO AMOUNT
                  </th>
                </tr>
                {data &&
                  data.map((TData, i) => {
                    return <SingleTransactionTableRow index={i} data={TData} key={i} getFiatCurrencySymbol={getFiatCurrencySymbol} />;
                  })}
              </tbody>
            </Table>
          </MediaQuery>
          <MediaQuery maxWidth={480}>
            <div className="TrasactionsMobileTable">
              {data &&
                data.map((TData, i) => {
                  return <SingleTransactionMobileRow data={TData} key={i} index={i} />;
                })}
            </div>
          </MediaQuery>
        </div>
      )}
    </div>
  );
};

export default InvoiceTable;

const SortByIcon = (props) => {
  return <i className={`fa fa-chevron-${props.orderBy === 'desc' ? 'down' : 'up'} TransactionTable_heading_sortIcon`} />;
};
const SingleTransactionTableRow = (props) => {
  let { data } = props;
  const [copied, setCopied] = useState(false);
  const openURLInNewTab = () => {
    data.invoiceURL && window.open(data.invoiceURL + `?invoiceId=${data.orderId}`, '_blank');
  };
  const url = (data.invoiceURL + `?invoiceId=${data.orderId}`);
  return (
    <tr className="CP_TableRow">
      <td className="transactionsTable_item">
        <span>
          <span data-tip data-for={data.transactionId}>
            {data.paymentReference || 'N/A'}
          </span>
          <ReactTooltip id={data.paymentReference} place="right" effect="solid" className="transactionTooltip">
            <div className="transactionTooltipContent">{data.paymentReference}</div>
          </ReactTooltip>
        </span>
      </td>
      <td className="transactionsTable_item">{data.orderId || 'N/A'}</td>
      <td className="transactionsTable_item">{data.cryptoCurrencies.join(', ') || 'N/A'}</td>
      <td className="invoice-url-color transactionsTable_item">
        <span onClick={openURLInNewTab} >{(url && url.substring(0, 25) + '...' + url.slice(-2)) || 'N/A'}</span> 
        <span
          className={`copy__icon copy-button-margin copy-text ${copied ? 'active address' : ''}`}
          onClick={() => {
            copy(data.invoiceURL + `?invoiceId=${data.orderId}`);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
        >
          <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
        </span>
      </td>
      <td className="transactionsTable_item">{(data.creationDate && moment(data.creationDate).format('MM/DD/YYYY HH:mm')) || 'N/A'}</td>
      <td className="transactionsTable_item">{(data.fiatCurrency || '$') + (data.USDAmount || 'N/A')}</td>
      <td className="transactionsTable_item">{'N/A'}</td>
    </tr>
  );
};
const SingleTransactionMobileRow = (props) => {
  let { data } = props;
  return (
    <div className="CP_MobileWrap">
      <div className="CP_MobileRow">
        <div className="transactionsMobile_itemWrap">
          <span>
            <span data-tip data-for={data.paymentReference}>
              {data.paymentReference || 'N/A'}
            </span>
            <ReactTooltip id={data.paymentReference} place="right" effect="solid" className="transactionTooltip">
              <div className="transactionTooltipContent">{data.paymentReference}</div>
            </ReactTooltip>
          </span>
          <span>&nbsp;|&nbsp;</span>
          <div className="transactionsMobile_item">{data.orderId || 'N/A'} </div>
          <span>&nbsp;|&nbsp;</span>
          <div className="transactionsMobile_item">{data.cryptoCurrencies.join(', ') || 'N/A'} </div>
        </div>
        <div className="transactionsMobile_itemWrap">
          <div className="invoice-url-color transactionsMobile_item transactionsMobile_item_small">
            {data.invoiceURL + `?invoiceId=${data.orderId}` || 'N/A'}
          </div>
          <span style={{ marginTop: '3px' }}>&nbsp;-&nbsp;</span>
          <div className="transactionsMobile_item transactionsMobile_item_small">
            {(data.creationDate && moment(data.creationDate).format('MM/DD/YYYY HH:mm')) || 'N/A'}
          </div>
        </div>
      </div>
      <div className="CP_MobileViewDetails">
        <div className="CP_MobileViewDetailsIcon">
          <i className="fa fa-chevron-right" />
        </div>
      </div>
    </div>
  );
};
