import React from 'react';

class Loading extends React.Component {
   render() {
      let Logo = window.location.origin + "/img/bit.gif"
      return (
         <div className="app-content-loading">
            <div className='loading_main row' >
               <img id="loadingImg" height="80px" className="loading-spinner" src={Logo} alt="Loading..." />
            </div>
         </div>
      );
   }
}

export default Loading;
