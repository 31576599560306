import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import localDb from "../../../../common/localDb";
import { checkValueInSS, getHeaderButtonStyle } from "../../../../common/theme";

export default function Safe(props) {
	const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])
	const { handleOpenLoginModel } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);

	let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

	return (
		<section className="section safe">
			<div className="safe__gradient"></div>
			<img width="1500" height="983" className="safe__glow" src={`${image_base_url}/upd/glow.svg`} />
			<div className="content-container">
				<div className="safe__content">
					
					{/* <AnimationOnScroll animateIn="animate__fadeInDown">
						<div className="section-upperTitle">
							<p>Wallet</p>
						</div>
					</AnimationOnScroll> */}
					<AnimationOnScroll animateIn="animate__fadeInUp">
						<p className="section-title mb-subtitle">Safe & Transparent</p>
					</AnimationOnScroll>
					<p className="section-text t-center"><span className="grey-text">Pro-level control to manage your cryptocurrency in one beautiful dashboard.<br/>Easy to use All-in One Solution.</span></p>
					<div className="theme-btn-block mt-subtitle center full-on-mobile">
						{
							!localDb.getVal("token") && <button type="button" onClick={handleOpenLoginModel} className="theme-btn" style={{ ...headerButtonStyle }}>
							<p>Buy Bitcoin</p>
						</button>
						}
						
					</div>	
					<AnimationOnScroll animateIn="animate__fadeIn">
						<img width="840" height="450" className="safe__bg" src={`${image_base_url}/upd/safe-bg.webp`} />
					</AnimationOnScroll>
				</div>
			</div>
		</section>
	)
}