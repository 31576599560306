import { Card, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const b2cSendCryptoStatus = (props) => {
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const title=props.sendStatus ? 'Sending' :'Sending Unsuccessful';
  const statusIcon = props.sendStatus ?'/homeb2c/b2cwalletsendcryptosuccess.svg':'/homeb2c/b2cwalletsendcryptofailed.svg';

  useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])

  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} className="send__crypto__status">
        <div className="status__img__content">
          <img className="status__imgs cursor-pointer" src={`${image_base_url}${statusIcon}`} alt="statusIcon" onClick={props.onClose} />
          <div className="enable-authenticator-model">
            <Card className="card-bg crypto__status__content">
              <div className="enable-authenticator-content text-center">
                <div className="status__title" style={{ marginTop: '50px' }}>
                  {title}
                </div>
                <div className="status__msg" data-e2e="status" data-e2e-msg={props.msg}>{props.msg}</div>
              </div>
              <div className="receive-wallet-bottom status__button">
                <div onClick={props.onClose} className="send__continue" data-e2e-transactionId={props.datae2eTransactionId} data-e2e="continue-btn" style={{ ...headerButtonStyle }}>{`Continue`}</div>
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default b2cSendCryptoStatus;
