import React, { useEffect, useState } from 'react';
import RoutesList from './client//Routes.js';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './client/Loading';
import { keepTheme } from './client/common/theme';
import localDb from './client/common/localDb';
import { setAndGetB2CTheme, setAndGetTheme } from './client/Redux/actions/commonAction';
import i18n from './locale/Localisation';
import { I18nextProvider } from 'react-i18next';
import "./scss/css/bootstrap.min.css"
import "./scss/css/font-awesome.css"
import "./scss/style.scss"
import "./scss/deposit.scss"
import { clearStorageData } from './client/common/getIP.js';
import { axiosCallService, apiService } from './client/common/apiCallService.js';

function App(props) {
  const dispatch = useDispatch();
  const [fontFamily, setFontFamily] = useState("Open Sans")
  const activity_tracking_api_url = useSelector((state) => state.config.activity_tracking_api_url);
  const api_url = useSelector((state) => state.config.api_url);
  const loading = useSelector((state) => state.common.loading);

  const logout = () => {
    const colorMode = sessionStorage.getItem("colorMode");
    const b2cColorMode = sessionStorage.getItem("b2cColorMode");
    const depositMerchantId = localStorage.getItem('depositMerchantId');
    const depositMethod = localStorage.getItem('depositMethod');
    const transactionId = localStorage.getItem('transactionId');
    const lightFlag = sessionStorage.getItem("lightFlag");
    const activity_tracking_token = localStorage.getItem('activity_tracking_token');
    const activity_tracking_api_url = localStorage.getItem('activity_tracking_api_url');
    const api_url = localStorage.getItem('api_url');
    clearStorageData();
    localStorage.setItem('reloadedOnce', "Yes");
    sessionStorage.setItem("colorMode", colorMode);
    sessionStorage.setItem("b2cColorMode", b2cColorMode);
    localStorage.setItem('depositMerchantId', depositMerchantId);
    localStorage.setItem('depositMethod', depositMethod);
    localStorage.setItem('transactionId', transactionId);
    sessionStorage.setItem("lightFlag", lightFlag);
    localStorage.setItem('activity_tracking_token', activity_tracking_token);
    localStorage.setItem('activity_tracking_api_url', activity_tracking_api_url);
    localStorage.setItem('api_url', api_url);
    // reloading to refresh fonts
    window.location.href = '/home-merchant';
  };

  useEffect(() => {
    localStorage.setItem('api_url', window.location.hostname === 'localhost' ? api_url : '');
    localStorage.setItem('activity_tracking_api_url', window.location.hostname === 'localhost' ? activity_tracking_api_url : '');
    setFontFamily(sessionStorage.getItem("font") && sessionStorage.getItem("font") !== "" && sessionStorage.getItem("font") !== "null" ? sessionStorage.getItem("font") : fontFamily)
    dispatch(setAndGetTheme(localDb.getSSVal("colorMode")));
    dispatch(setAndGetB2CTheme(localDb.getSSVal("b2cColorMode")));

    if (localDb.getSSVal("colorMode")) {
      if (localDb.getSSVal("colorMode") === 'dark') {
        dispatch(setAndGetTheme('dark'));
      } else if (localDb.getSSVal("colorMode") === 'light') {
        dispatch(setAndGetTheme('light'));
      }
    }
    if (localDb.getSSVal("b2cColorMode")) {
      if (localDb.getSSVal("b2cColorMode") === 'dark') {
        dispatch(setAndGetB2CTheme('dark'));
      } else if (localDb.getSSVal("b2cColorMode") === 'light') {
        dispatch(setAndGetB2CTheme('light'));
      }
    }
    let viewPermissionOnly = localDb.getVal("viewPermissionOnly")
    const loginType = localDb.getVal('loginType');
    if (loginType === 'merchant' || loginType === 'Merchant'){
      const merchantId = localDb.getVal("merchantId");
      const merchantUserEmailId = localDb.getVal("user_name");
      let interval = setInterval(()=>{
        if(localDb.getVal("viewPermissionOnly") !== viewPermissionOnly){
          const payload = {
            merchantId,
            merchantUserEmailId
          }
          apiService(
            (window.location.hostname === 'localhost' ? localStorage.getItem('api_url') : '') + '/restapi/expire-merchant-session',
            payload,
              (data) => {
                if(data?.status == 403){
                  clearInterval(interval)
                  logout()
                }
              },
              (err) => {
                console.error("End merchant user session : ", err);
              },
            );
        }
      }, 1500)
    }
  }, []);

  useEffect(() => {
    keepTheme();
  });

  return (
    <I18nextProvider i18n={i18n}>
        <div id="ProjectApp" className="projectApp">
          {loading && <Loading />}
          <RoutesList/>
        </div>
    </I18nextProvider>
  );
}

export default App;
