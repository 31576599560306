import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";

export default function PaysuIntroduce() {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section" id="paysu-introduce">
			<div className="content-container">
				<div className="ps-section-title-line"></div>
				<div className="ps-section-grid no-gap-mobile">
					<AnimationOnScroll animateIn="animate__fadeIn">
						<p className="ps-section-title mb">Why introduce cryptocurrencies to my <br/>store or shop?</p>
					</AnimationOnScroll>
					<div>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">According to BIG Commerce, 66% of those that have used a cryptocurrency to shop have stated a desire to re-use it as a payment method. That is quite a majority.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Through QR codes and simple payments screens – not too different to card payments, accepting crypto is easier than you think, especially with PaySu, online or in store.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="600" animateIn="animate__fadeInUp">
							<p className="ps-section-text">One of the main blockers to the uptake of crypto is that merchants do not offer crypto as a payment option. Don’t let that be you, stay open to opportunities, it is how you got to where you are today!</p>
						</AnimationOnScroll>
					</div>
				</div>
				<img className="paysu-fullImage" src={`${image_base_url}/paysu/ps-open-shop.webp`} />
			</div>
		</section>
	)
}