import React from 'react';
import { connect } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import TransactionDetails from './TransactionDetails';
import BalanceTable from './BalanceTable';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import * as actions from '../../../Redux/actions';
import ExchangeModal from './ExchangeModal';
import B2CTransactionFilters from './TransactionFilters';
import SingleTransactionTableRow from './TransactionSingleRow';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';
const searchicon = "/common/crytoicons/searchicon1.png";
let interval1 = null

const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Transactions:',
    filename: "Transactions",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ['Column 1', 'Column 2', "col 3", "col 4", "col 5"]
};

const csvExporter = new ExportToCsv(options);

class Transactions extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchText: "",
            page: 1,
            transactions: [],
            allTransactions: [],
            orderBy: "desc",
            sortBy: "createdDate",
            totalCount: 0,
            dateSelected: false,
            autoUpdateTransactions: true,
            selectedCryptos: [],
            fromExchangeCurrency: null,
            statusList: ["Success", "Pending", "Require Approval", "Rejected", "Processing", "Timed Out"],
            selectedStatusList: ["Success", "Pending", "Require Approval", "Rejected", "Processing", "Timed Out"],
            filterModel: false,
            fiatCurrencies: [
                {
                    icon: "/common/crytoicons/euro.png",
                    name: "Euro",
                    symbol: "EUR",
                },
                {
                    icon: "/common/crytoicons/usa.png",
                    name: "US Dollar",
                    symbol: "USD",
                },
                {
                    icon: "/common/crytoicons/uk.png",
                    name: "British Pound",
                    symbol: "GBP",
                },

            ],
            // selectedFiatCurrencies: ["EUR", "USD", "GBP", "RUB", "JPY"],
            selectedFiatCurrencies: ["EUR", "USD", "GBP"],
            openTransactionDetails: false,
            showDownloadAlertModal: false,
            showSuccess: false,
            showError: false,
            transactionDtls: [],
            allData: [],
            totalNumberOfTransactions: 0,
            totalUsdAmount: 0,
            sumOfEachCrypto: [],
            balances: [],
            openExchangeModal: false,
            allTypes: ['Autotrade', 'Withdrawal', 'Deposit', 'Swap', 'Settlement', "AdminWithdrawal", "AdminDeposit"],
            allStatus: ['completed', 'rejected', 'Pending'],
            allCryptos: [],
            page: 1,
            searchText: '',
            dateSelected: false,
            showFilterModel: false,
            pageMode: 'balance',
            filterBalances: [],
            transactionFilters: {
                fromDate: '',
                toDate: '',
                selectedCryptos: [],
                selectedStatus: ['completed', 'rejected', 'Pending'],
                selectedTypes: ['Autotrade', 'Withdrawal', 'Deposit', 'Swap', 'Settlement', "AdminWithdrawal", "AdminDeposit"]
            },
            transactionTableData: [],
            search: "",
            merchantWalletList: [],
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token")) {
            if (this.props.cryptos && this.props.cryptos.length === 0) {
                this.getCryptoCurrencies()
            }
        }
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
        this.getCryptoCoins()
        this.getBalances()
        if (!interval1) {
            interval1 = setInterval(() => {
                this.setIntervalCalls();
            }, 30000)
        }
    }

    componentWillUnmount() {
        if (interval1) {
            clearInterval(interval1)
            interval1 = null;
        }
    }

    setIntervalCalls = () => {
        if (this.state.pageMode === "balance") {
            this.getBalances();
        } else {
            if (this.state.autoUpdateTransactions) {
                this.getTransactions(1)
            }
        }
    }

    componentDidUpdate(preProps, prevState) {
        if (this.state.transactionFilters !== prevState.transactionFilters) {
            this.getTransactions(1)
        }
    }

    getTransactions = (pageNo) => {
        let page = 1
        if (pageNo) {
            page = pageNo;
            this.setState({ page: pageNo });
        }

        let requestBody = {
            merchantId: localStorage.getItem('merchantId'),
            page,
        }

        if (this.state.dateSelected) {
            requestBody = {
                ...requestBody,
                fromDate: this.state.transactionFilters.fromDate,
                toDate: this.state.transactionFilters.toDate,
            };
        }
        if (this.state.transactionFilters.selectedCryptos.length === 0 || this.state.transactionFilters.selectedCryptos.length === this.state.allCryptos.length) {
            requestBody.CryptoCurrency = "All"
        } else {
            requestBody.CryptoCurrency = this.state.transactionFilters.selectedCryptos
        }

        if (this.state.transactionFilters.selectedStatus.length === 0 || this.state.transactionFilters.selectedStatus.length === this.state.allStatus.length) {
            requestBody.status = "All"
        } else {
            requestBody.status = this.state.transactionFilters.selectedStatus
        }
        if (this.state.transactionFilters.selectedTypes.length === 0 || this.state.transactionFilters.selectedTypes.length === this.state.allTypes.length) {
            requestBody.type = "All"
        } else {
            requestBody.type = this.state.transactionFilters.selectedTypes
        }
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-merchant-transactions', requestBody,
            (data) => {
                if (pageNo === 1) {
                    this.setState({
                        transactionTableData: data.transactionsList,
                        totalCount: data.totalCount,
                    });
                } else {
                    this.setState({
                        transactionTableData: this.state.transactionTableData.concat(data.transactionsList),
                        totalCount: data.totalCount,
                    });
                }
            }, (err) => {
                console.log(err);
            })
    }

    getCryptoCoins = () => {
        let requestBody = {};
        apiService(
            (window.location.hostname === 'localhost' ? this.props.api_url : '') + '/restapi/get-cryptos',
            requestBody,
            (data) => {
                this.setState({ allCryptos: data.cryptos });
                const newCryptoList = data.cryptos.map((obj)=>obj.symbol)
                this.setState({ transactionFilters : { ...this.state.transactionFilters, selectedCryptos : newCryptoList } },()=>{
                    this.getTransactions(1)
                })
            },
            (err) => {
                console.log(err);
            },
        );
    };

    openTransactionDetails = (details) => {
        let requestBody = {
            transactionId: details.transactionId,
        }
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/view-transaction', requestBody,
            (data) => {
                this.props.setLoading(false)
                if (data && data.transaction) {
                    this.setState({
                        transactionDtls: [data.transaction],
                        openTransactionDetails: true
                    })
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }


    getCryptoCurrencies = () => {
        let requestBody = {}
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-cryptos', requestBody,
            (data) => {
                this.props.setLoading(false)
                if (data && data.cryptos) {
                    this.props.saveCryptos(data.cryptos)
                    let cryptos = data.cryptos.map(crypto => { return crypto.symbol })
                    this.setState({ selectedCryptos: cryptos });
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }

    getBalances = () => {
        let requestBody = {
            merchantId: localStorage.getItem("merchantId")
        }
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-merchant-wallet-balances', requestBody,
            (data) => {
                this.setState({
                    balances: data.merchantBalanceList,
                    merchantWalletList: data.merchantWalletList,
                });
            }, (err) => {
                console.log(err);
            })
    }



    saveSettings = (settings) => {
        this.setState({
            page: 1,
            transactions: [],
            totalCount: 0,
            fromDate: settings.dateSelected ? settings.fromDate : this.state.fromDate,
            toDate: settings.dateSelected ? settings.toDate : this.state.toDate,
            dateSelected: settings.dateSelected,
            selectedCryptos: settings.selectedCryptos,
            selectedFiatCurrencies: settings.selectedFiatCurrencies,
            selectedStatusList: settings.selectedStatusList,
        }, () => {
            // this.getTransactions(1);
        })
    }



    setExchangeModal = (value) => {
        if (value === false) {
            this.setState({ fromExchangeCurrency: null })
        }
        this.setState({ openExchangeModal: value })
    }

    sortList = (sortBy, type) => {
        let cpy = [...this.state.balances]
        if (type == 'asc') {
            if (sortBy == "crypName") {
                cpy.sort()
            } else {
                cpy.sort((a, b) => {
                    return a[sortBy] - b[sortBy]
                })
            }

        } else {
            if (sortBy == "crypName") {
                cpy.sort().reverse()
            } else {
                cpy.sort((a, b) => {
                    return b[sortBy] - a[sortBy]
                })
            }

        }

        this.setState({ balances: cpy })

    }


    render() {
        let headerButtonBorderStyle = getHeaderButtonBorderStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerButtonLightBgColour)
        let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)
        return (
            <div className="myAccTransactionsMain mer">
                <ExchangeModal
                    image_base_url={this.props.image_base_url}
                    cryptos={this.props.cryptos}
                    statusList={this.state.statusList}
                    fromExchangeCurrency={this.state.fromExchangeCurrency}
                    fiatCurrencies={this.state.fiatCurrencies}
                    getBalances={this.getBalances}
                    show={this.state.openExchangeModal}
                    merchantBalanceList={this.state.balances}
                    merchantWalletList={this.state.merchantWalletList}
                    saveSettings={this.saveSettings}
                    setExchangeModal={(value) => { this.setExchangeModal(value); }}
                    settings={{
                        fromDate: moment(this.state.fromDate),
                        toDate: moment(this.state.toDate),
                        selectedCryptos: this.state.selectedCryptos,
                        dateSelected: this.state.dateSelected,
                        selectedFiatCurrencies: this.state.selectedFiatCurrencies,
                        selectedStatusList: this.state.selectedStatusList,
                    }}
                />


                <div className='switchraoo-container s'>
                    <div className='switcharoo'>
                        <div data-e2e="balances-tab"
                            onClick={() => {
                                this.setState({ pageMode: 'balance' }, () => {
                                    this.getBalances()
                                })
                            }}
                            className={`normal-switcharoo ${this.state.pageMode == 'balance' ? 'selected-switcharoo' : ''}`}
                            style={{ ...(this.state.pageMode == 'balance' ? headerButtonStyle : {}) }}
                        >
                            Balances
                        </div>

                        <div data-e2e="transactions-tab"
                            onClick={() => {
                                this.setState({ pageMode: 'transaction' }, () => {
                                    this.getTransactions(1)
                                })
                            }}
                            className={`normal-switcharoo ${this.state.pageMode == 'transaction' ? 'selected-switcharoo' : ''}`}
                            style={{ ...(this.state.pageMode == 'transaction' ? headerButtonStyle : {}) }}
                        >
                            Transactions
                        </div>

                    </div>
                    {this.state.pageMode == 'balance' ? (
                        <div className='search-ctr'>
                            <div><input value={this.state.search} onChange={(e) => {
                                let search = e.target.value

                                this.setState({ search })
                                let found = this.state.balances.filter((item) => {
                                    return item.crypName.toLowerCase().includes(search.toLowerCase()) || item.coin.toLowerCase().includes(search.toLowerCase())
                                })
                                this.setState({ filterBalances: found })
                            }} placeholder='Search CryptoCurrency' /></div>
                            <img src={`${this.props.image_base_url}${searchicon}`} width={18} height={18} />
                        </div>
                    ) : (
                        null
                    )
                    }
                </div>
                {this.state.pageMode == 'balance' ? (
                    <div className="subContainer s">
                        {
                            this.state.openTransactionDetails
                                ? this.state.transactionDtls.length > 0 && <TransactionDetails
                                    rejectTransaction={this.rejectTransaction}
                                    approveTransaction={this.approveTransaction}
                                    showSuccess={this.state.showSuccess}
                                    showError={this.state.showError}
                                    toggleShowSuccess={(value) => {
                                        this.setState({ showSuccess: value })
                                    }}
                                    toggleShowError={(value) => {
                                        this.setState({ showError: value })
                                    }}
                                    clearTransactionDtlsScreen={this.clearTransactionDtlsScreen} transactionDtls={this.state.transactionDtls} />
                                : <div>
                                    <BalanceTable
                                        setFromExchangeCurrency={(value) => { this.setState({ fromExchangeCurrency: value }) }}
                                        sortList={this.sortList}
                                        data={this.state.search.length == 0 ? this.state.balances : this.state.filterBalances}
                                        setExchangeModal={(value) => { this.setExchangeModal(value) }}
                                        openTransactionDetails={(data) => this.openTransactionDetails(data)}
                                        image_base_url={this.props.image_base_url}
                                        sortBy={this.state.sortBy}
                                        orderBy={this.state.orderBy}
                                        changeOrder={(sortBy) => {
                                            if (this.state.sortBy !== sortBy) {
                                                this.setState({
                                                    sortBy, orderBy: "desc"
                                                })
                                            } else {
                                                this.setState({
                                                    orderBy: this.state.orderBy === "desc" ? "asc" : "desc"
                                                })
                                            }
                                        }}
                                        merchantWalletList={this.state.merchantWalletList}
                                        getBalances = {() => this.getBalances()}
                                    />
                                    {(this.state.search.length == 0 ? this.state.balances < 1 : this.state.filterBalances < 1) && <div className="No_Transactions_Found">No balance Found</div>}
                                </div>
                        }
                    </div>
                )
                    :
                    (
                        <div className="subContainer s">
                            <B2CTransactionFilters
                                filterModel={this.state.filterModel}
                                image_base_url={this.props.image_base_url}
                                setFilterModel={(val) => { this.setState({ filterModel: val }) }}
                                fromDate={this.state.transactionFilters.fromDate}
                                toDate={this.state.transactionFilters.toDate}
                                setFromDateAndToDate={(from, to) => {
                                    this.setState({ transactionFilters: { ...this.state.transactionFilters, fromDate: from, toDate: to } });
                                }}
                                allTypes={this.state.allTypes}
                                setAlltypes={() => { }}
                                selectedTypes={this.state.transactionFilters.selectedTypes}
                                setSelectedTypes={(val) => {
                                    this.setState({transactionFilters: {...this.state.transactionFilters , selectedTypes : val  }});

                                 }}
                                allStatus={this.state.allStatus}
                                setAllStatus={() => { }}
                                selectedStatus={this.state.transactionFilters.selectedStatus}
                                setSelectedStatus={(val) => {
                                    this.setState({transactionFilters: {...this.state.transactionFilters , selectedStatus : val  }});
                                 }}
                                allCryptos={this.state.allCryptos}
                                setAllCryptos={() => {

                                }}
                                selectedCryptos={this.state.transactionFilters.selectedCryptos}
                                setSelectedCryptos={(val) => {
                                    this.setState({transactionFilters: {...this.state.transactionFilters , selectedCryptos : val  }});
                                }}
                                dateSelected={this.state.dateSelected}
                                setDateSelected={(val) => {
                                    this.setState({ dateSelected: val })
                                }}

                            />
                            {this.state.transactionTableData && this.state.transactionTableData.length > 0 ? (
                                <div className="TransactionTable" >
                                    <table className='transaction-merchant-table'>
                                        <tr className='trow-first'>
                                            <th className='t-header p-30-l'>DATE</th>
                                            <th className='t-header'>TYPE</th>
                                            <th className='t-header'>ASSET</th>
                                            <th className='t-header'>ASSET AMOUNT</th>
                                            <th className='last-header'>FIAT AMOUNT</th>
                                        </tr>
                                        {this.state.transactionTableData.map((TData, i) => {
                                            return (
                                                <SingleTransactionTableRow
                                                    data={TData}
                                                    key={i}
                                                    index={i}
                                                />
                                            );
                                        })}
                                    </table>
                                </div>
                            ) : (<div className='No_Transactions_Found text__color'>
                                NO Transaction Found.
                            </div>)}
                            {this.state.totalCount && this.state.transactionTableData && this.state.transactionTableData.length < this.state.totalCount ? <div className="Show_more_transactions_wrap" style={{ marginTop: "50px" }}><div className="Show_more_transactions s" style={{ ...headerButtonBorderStyle }}onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                                this.getTransactions(this.state.page)
                                this.setState({ autoUpdateTransactions: false })
                            })} data-e2e="showMore">Show More</div></div> : null}
                        </div>
                    )
                }

            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
        cryptos: state.transaction.cryptos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
        saveCryptos: (data) => dispatch(actions.saveCryptos(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)

