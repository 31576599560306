import React, { useEffect, useRef } from 'react';
import ReactLoading from 'react-loading';
import { useSelector } from "react-redux";
import { useState } from 'react';
import { constants } from '../../../../common/constants';
import moment from 'moment'
import { apiService } from '../../../../common/apiCallService';

let trustPaymentEventsOut = [];

const PayLoading = (props) => {
   const cardAPIType = useSelector((state) => state.deposit.cardAPIType)
   const liveFlag = useSelector((state) => state.config.TRUST_PAYMENTS_LIVE_FLAG_B2C);
   const [brava3DSContent, setBrava3DSContent] = useState(false);
   const [altPayNet3DSContent, setAltPayNet3DSContent] = useState(false);
   const [worldCard3DSContent, setWorldCard3DSContent] = useState(false);
   const eventsHiddenFieldRef = useRef(null);
   const [preWorldCard3DSContent, setPreWorldCard3DSContent] = useState(false);
   const [unicorn3DSContent, setUnicorn3DSContent] = useState(false);

   useEffect(() => {
      if (cardAPIType === "Trust Payments") {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "UIPageLoaded",
            eventData: { time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         creditDeposit(props.token);
         window.onbeforeunload = () => {
            saveTrustSingleEvent({
               transactionId: props.transactionId,
               eventName: "UIPageClosed",
               eventData: { time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            });
            return null;
         };
         window.onunload = () => {
            saveTrustSingleEvent({
               transactionId: props.transactionId,
               eventName: "UIPageClosedOnUnload",
               eventData: { time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            });
            return null;
         };
      } else if (cardAPIType === "Axcess Payments") {
         console.log("before submit")
         setTimeout(() => {
            document.getElementById('st-form-axcess').submit();
         }, 2000)
      }else if(cardAPIType === constants.SWITCH_FIN_INTEGRATION_TYPE){
         setTimeout(() => {
            document.getElementById('st-form-switch-fin').submit();
         }, 2000)
      } else if (cardAPIType === "Brava Payments") {
         setBrava3DSContent(props.token)
         setTimeout(() => {
            document.getElementById('st-form-brava3').submit();
            // console.log("called brava pay call")
         }, 500)
      } else if (cardAPIType === constants.WORLD_CARD_INTEGRATION_TYPE) {
         if (props.token.redirect.preconditions && props.token.redirect.preconditions.length > 0) {
            setPreWorldCard3DSContent(props.token.redirect.preconditions[0])
         }
         setWorldCard3DSContent(props.token)
         setTimeout(() => {
            if (props.token.redirect.preconditions && props.token.redirect.preconditions.length > 0) {
               document.getElementById('st-form-pre-world-card3').submit();
               console.log("worldCard pre API called")
            }
            document.getElementById('st-form-world-card3').submit();
            console.log("worldCard payment API called")
         }, 500)
      } else if (cardAPIType === "AltPayNet") {
         setAltPayNet3DSContent(props.token)
      } else if (cardAPIType === constants.UNICORN_INTEGRATION_TYPE) {
         if(props?.token?.transactionFailed && !props?.token?.acsUrl){
            window.location.href = props?.token?.redirectUrl
         }
         else if(props?.token?.acsUrl){
            setUnicorn3DSContent(props?.token);
            setTimeout(() => {
               document.getElementById('st-form-unicorn-b2c').submit();
            }, 20000);
         }
      }
      let saveTrustPaymentEventApiCallInterval = setInterval(async()=>{
         if(trustPaymentEventsOut.length > 0){
            await saveTrustPaymentEvents(trustPaymentEventsOut);
            trustPaymentEventsOut=[];
         }
      },2*60*1000)
      return ()=>{
         clearInterval(saveTrustPaymentEventApiCallInterval);
      }
   }, [])
   const creditDeposit = () => {
      let st = SecureTrading({
         jwt: props.token,
         submitOnError: true,
         formId: "st-form",
         livestatus: parseInt(liveFlag ? liveFlag : 0),
      })
      st.Components({ startOnLoad: true });
      st.on("paymentInitStarted", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentInitStarted",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentInitStarted", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentInitCompleted", (data) => {
         const newDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
         setTimeout(()=>{
            saveTrustSingleEvent({
               transactionId: props.transactionId,
               eventName: "paymentInitCompleted",
               eventData: { ...data, time: newDate },
            });
            trustPaymentEventsOut.push({ eventName: "paymentInitCompleted", time: newDate});
            eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
         }, 150)
      })
      st.on("paymentInitFailed", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentInitFailed",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentInitFailed", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentStarted", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentStarted",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentStarted", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentPreCheck", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentPreCheck",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentPreCheck", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentCompleted", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentCompleted",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentCompleted", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentFailed", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentFailed",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentFailed", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentCanceled", (data) => {
         saveTrustSingleEvent({
            transactionId: props.transactionId,
            eventName: "paymentCanceled",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentCanceled", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
   }

   const saveTrustSingleEvent = (dataObj) => {
      const stForm = document.getElementById("st-form");
      const transactionReference = stForm?.elements["transactionreference"]?.value
      if (transactionReference) {
         dataObj.eventData.transactionReference = transactionReference
      }
      apiService("/restapi/save-trust-payment-event", dataObj, (data) => {
         console.log("event saved for trust:", data)
      }, (err) => {
         console.error(err);
      })
   }

   const saveTrustPaymentEvents = async (eventName) => {
      try {
         await apiService("/restapi/save-card-3ds-event", {
            transactionId: props.transactionId,
            eventName
         }, (data) => {
            console.log("events saved for trust:", data)
         }, (err) => {
            console.error(err);
         })
      } catch (error) {
         console.error('error insidePayloading ',error);
      }
   }
   const SWITCH_FIN_API_URL = useSelector((state)=>state.config.SWITCH_FIN_API_URL)
   console.log("process.env.SWITCH_FIN_API_URL", SWITCH_FIN_API_URL)
   return (
      <div >
         <div className='loading_pay_main row' >
            <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
         </div>

         <div id="st-notification-frame"></div>
         {cardAPIType === "Trust Payments" &&
            <form id="st-form" action={window.location.origin + "/restapi/trust-callback"} method="POST">
               {props.saveCard && <input type="hidden" name="credentialsonfile" value="1" />}
               {props.routingId && <input type="hidden" name="routingId" value={props.routingId} />}
               {props.acquirerName && <input type="hidden" name="acquirerName" value={props.acquirerName} />}
               {props.previousCard && <input type="hidden" name="credentialsonfile" value="2" />}
               {props.transactionId && <input type="hidden" name="transactionId" value={props.transactionId ? props.transactionId : localStorage.getItem("transactionId")} />}
               {props.securitycode && <input type="hidden" name="securitycode" value={props.securitycode} />}
               {props.parenttransactionreference && <input type="hidden" name="parenttransactionreference" value={props.parenttransactionreference} />}
               <input type="hidden" name="events" ref={eventsHiddenFieldRef}/>
            </form>}
         {cardAPIType === "Axcess Payments" &&
            <form id="st-form-axcess" action={props.axcess_hosted_url} method="POST"
               novalidate="novalidate"
               enctype="application/x-www-form-urlencoded"
               data-hostedform-tokenize='{"#form-customer-name": "customerName"}'
            >
               {Object.keys(props.token).map((field, i) => {
                  return (<input key={i} type="hidden" name={`${field}`} value={props.token[field]}></input>)
               })}
            </form>}
         {cardAPIType === constants.SWITCH_FIN_INTEGRATION_TYPE &&
            <form id="st-form-switch-fin" action={SWITCH_FIN_API_URL} method='POST'>
               {Object.entries(props?.token).map(([key, value]) => ({ name: key, value }))?.length > 0 &&
                  Object.entries(props?.token).map(([key, value]) => ({ name: key, value })).map((param) => {
                     return <input type="hidden" name={param?.name} value={param?.value} />
                  })
               }
            </form>
         }
         {cardAPIType === "Brava Payments" &&
            <form id="st-form-brava3" action={brava3DSContent.redirect_url} method={brava3DSContent.redirect_method}>
               {brava3DSContent.redirect_params && Object.keys(brava3DSContent.redirect_params).length > 0 &&
                  Object.keys(brava3DSContent.redirect_params).map((key) => {
                     return <input type="hidden" name={key} value={brava3DSContent.redirect_params[key]} />
                  })}
            </form>}
         {cardAPIType === constants.WORLD_CARD_INTEGRATION_TYPE && preWorldCard3DSContent && preWorldCard3DSContent.url &&
            <form id="st-form-pre-world-card3" action={preWorldCard3DSContent.url} method={preWorldCard3DSContent.method ? preWorldCard3DSContent.method : "POST"}>
               {preWorldCard3DSContent.parameters && preWorldCard3DSContent.parameters.length > 0 &&
                  worldCard3DSContent.parameters.map((param) => {
                     return <input type="hidden" name={param.name} value={param.value} />
                  })}
            </form>}
         {cardAPIType === constants.WORLD_CARD_INTEGRATION_TYPE && worldCard3DSContent && worldCard3DSContent.redirect &&
            <form id="st-form-world-card3" action={worldCard3DSContent.redirect.url} method={worldCard3DSContent.redirect.method ? worldCard3DSContent.redirect.method : "POST"}>
               {worldCard3DSContent.redirect.parameters && worldCard3DSContent.redirect.parameters.length > 0 &&
                  worldCard3DSContent.redirect.parameters.map((param) => {
                     return <input type="hidden" name={param.name} value={param.value} />
                  })}
            </form>}
         {cardAPIType === "AltPayNet" &&
            <iframe src={altPayNet3DSContent.redirect_url_3ds} width="500" height="600" style={iframeStyle} />
         }
          {cardAPIType === constants.UNICORN_INTEGRATION_TYPE && unicorn3DSContent && unicorn3DSContent?.acsUrl &&
            <form id="st-form-unicorn-b2c" action={unicorn3DSContent?.acsUrl} method="post" >
               <input name="transactionId" type="hidden" value={unicorn3DSContent?.acquirerTransactionReferenceId} /><br />
               <input name="AuthType" type="hidden" value={unicorn3DSContent?.authType} />
               <input name="TermUrl" type="hidden" value={`${window?.location?.origin}/pga/unicornB2CCallback`} />
            </form>
         }
      </div>
   );
}

export default PayLoading;