import React, { useEffect, useState } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';

const Authentication = (props) => {
    const [paymentProviderName, setPaymentProviderName] = useState(null)
    useEffect(() => {
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
        }
    }, []);
    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <div className="tool_main">
                        <div className="tool_heading" data-e2e="Authentication">Authentication</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    {`The ${paymentProviderName ? paymentProviderName : "Crypton Pay"} API uses API Keys to authenticate requests. You can view your API keys in the merchant dashboard. Be sure to keep your API keys secure! Do not share your secret API keys in publicly accessible areas such as GitHub, client-side code, etc.`}
                                </div>
                                <div className="tools_content_text">
                                    Authentication is performed via HTTP basic auth. Provide your API key as the basic auth username value.
                                </div>
                                <div className="tools_content_text">
                                    All API requests should be in HTTPS using TLS1.2. HTTP requests will be rejected.
                                </div>
                            </div>
                            <div className="tools_right_part">
                            </div>
                        </div>
                    </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout>
    )
}

export default Authentication