import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function PaysuFaq() {
	return (
		<section className="ps-section paysu-faq-section">
			<div className="content-container">
				<div className="ps-section-title-line"></div>
				<AnimationOnScroll animateIn="animate__fadeIn">
					<p className="ps-section-title mb">FAQs.</p>
				</AnimationOnScroll>
				<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
					<p className="ps-section-text max-width">Everything you need to know about the product and billing. Can’t find the answer you’re looking for? Please <u>chat to our friendly team.</u></p>
				</AnimationOnScroll>
				<div className="paysu-faq-divider"></div>
				<div className="paysu-faq">
					<div>
						<p className="paysu-faq-title">What about market fluctuation and price changes?</p>
						<p className="ps-section-grey-text">PaySu has multiple liquidity partners, we protect you from any market fluctuation. We settle direct to you in your chosen currency, from your own merchant portal.</p>
					</div>
					<div>
						<p className="paysu-faq-title">What are my settlement options?</p>
						<p className="ps-section-grey-text">You can hold cryptocurrencies with us or, set up an automated sweep and settle. Whatever you choose, you will get 100% of your sale minus our small fee.</p>
					</div>
					<div>
						<p className="paysu-faq-title">How do I integrate?</p>
						<p className="ps-section-grey-text">Once we have completed KYC, you have two options, either start using our simplest payment methods for cryptocurrencies using our QR code generator or, integrate using our APIs to build our payment options into your checkout.</p>
					</div>
					<div>
						<p className="paysu-faq-title">How do I create a refund or void for a sale made with a cryptocurrency?</p>
						<p className="ps-section-grey-text">Cryptocurrency transactions are final as they are sent by the customer. You will be able to see the customer’s wallet address as part of the transaction and can send funds back to them from the platform.</p>
					</div>
					<div>
						<p className="paysu-faq-title">How many types of cryptocurrency can PaySu support?</p>
						<p className="ps-section-grey-text">PaySu currently supports Ethereum, Bitcoin, USDT, USDC, Ripple and DAI, we have a roadmap of further currencies to add in future but if you need a specific cryptocurrency please speak to us.</p>
					</div>
					<div>
						<p className="paysu-faq-title">How secure are my funds with PaySu?</p>
						<p className="ps-section-grey-text">Cryptocurrency stored on PaySu uses many layers of protection and industry standard practices to protect our private keys and merchant funds.</p>
					</div>
				</div>
				<div className="paysu-intouch">
					<div>
						<p className="paysu-faq-title">Still have questions?</p>
						<p className="ps-section-text">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
					</div>
					<button className="paysu-btn" onClick={()=>{
						document.getElementById("paysuContactSectionId").focus();
					}}>Get in touch</button>
				</div>
			</div>
		</section>
	)
}