import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { useNavigate } from 'react-router-dom';
import { removeHttp } from '../../../common/getIP';
const depositError = "/common/crytoicons/depositerror.svg";
class DepositFailedForCreditCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lightFlag: false,
            merchantName: "",
            merchantRedirectUrl: "",
            isB2c: null
        }
    }

    componentDidMount = async () => {
        const queryString = window && window.location && window.location.search;
        const searchParams = new URLSearchParams(queryString);
        const {merchantRedirectUrl, isB2c } = Object.fromEntries(searchParams.entries());
        this.setState({merchantRedirectUrl: merchantRedirectUrl, isB2c: (isB2c && isB2c !== "false" ? true : false)})
        this.props.setLoading(false);
        if (sessionStorage.getItem("lightFlag")) {
            this.setState({ lightFlag: (sessionStorage.getItem("lightFlag") === "true") })
        } else {
            sessionStorage.setItem("lightFlag", false);
        }
        let name = "Wallet"
        // if(localStorage.getItem("firstName")){
        //     name = localStorage.getItem("firstName");
        // }
        // if(localStorage.getItem("lastName")){
        //     name = name +" "+localStorage.getItem("lastName");
        // }
        if(this.state.isB2c){
            this.setState({ merchantName: name})
        }else{
            this.setState({ merchantName: localStorage.getItem("depositMerchantName") ? localStorage.getItem("depositMerchantName") : "Merchant"})
        }
    }

    clickRedirect = () =>{
        if(this.state.merchantRedirectUrl){
            window.top.location.href = `https://${removeHttp(this.state.merchantRedirectUrl)}`;
        }
        else if(this.state.isB2c){
            this.props.navigate('/user-wallet-home');
        }else{
            window.close();
        }
    }

    render() {
        return (
            <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                <div className="deposit_screen_wrap">
                    <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"} style={{ paddingBottom: "30.6px" }}>
                        {/* <TopHeading onClose={this.clickRedirect} refund={this.props.refund} /> */}
                        <div className="successimageWrap">
                            <img className="successimage" src={`${this.props.image_base_url}${depositError}`} />
                        </div>
                        <div className={`depositSuccessTitle ${this.state.lightFlag ? "text_color_black" : ""}`}>
                            {this.props.refund ? "Refund" : "Deposit"} Error
                        </div>
                         <div className={`depositSuccessDescription ${this.state.lightFlag ? "text_color_black" : ""}`}>
                            Your {this.props.refund ? "refund" : "deposit"} has been denied!
                        </div>
                        <div data-e2e="return to wallet" className="returnToMerchant" onClick={() => this.clickRedirect()}>{this.state.isB2c ? "Redirect to wallet" : `Return to ${this.state.merchantName}`}</div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        image_base_url: state.config.image_base_url,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
      setLoading: (value) => dispatch(actions.setLoading(value)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)((props)=><DepositFailedForCreditCard {...props} navigate={useNavigate()} />);
