import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import localDb from "../../../../common/localDb";
import { checkValueInSS, getHeaderButtonStyle } from "../../../../common/theme";


gsap.registerPlugin(ScrollTrigger);

export default function Hero(props) {
	const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	const { handleOpenLoginModel } = props;

	const image_base_url = useSelector((state) => state.config.image_base_url);

	const [cursorX, setCursorX] = useState(0);
	const [cursorY, setCursorY] = useState(0);

	useEffect(() => {
		setCursorX(window.innerWidth / 2);
		setCursorY(window.innerHeight / 2);

		ScrollTrigger.matchMedia({
			// desktop
			"(min-width: 1059px)": function () {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: ".hero",
						start: "center center",
						end: "bottom top",
						scrub: 1.2,
					}
				});

				tl.to(".coin-2", { ease: "none", y: -300 }, 0);
				tl.to(".coin-3", { ease: "none", y: 100 }, 0);
				tl.to(".coin-5", { ease: "none", y: -250 }, 0);
				tl.to(".coin-6", { ease: "none", y: 50 }, 0);
				tl.to(".coin-1", { ease: "none", y: 50 }, 0);
				tl.to(".coin-4", { ease: "none", y: 70 }, 0);
			},
		});
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, []);

	function handleMouseMove(e) {
		setCursorX(e.pageX || e.touches[0].pageX);
		setCursorY(e.pageY || e.touches[0].pageY);
	}

	let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

	return (
		<section className="hero" onMouseMove={(e) => handleMouseMove(e)}>
			<div className="hero__cursor" style={{
				background: `radial-gradient( circle 20vmax at ${cursorX}px ${cursorY}px, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 80%, rgba(0,0,0,.7) 100% )`,
			}}></div>
			<div className="hero__bg"></div>
			<div className="hero__gradient"></div>
			<img width="1270" height="680" className="hero__main on-desktop" src={`${image_base_url}/upd/hero-main.webp`} />
			<div className="content-container">
				<div className="hero__coin coin-1"></div>
				<div className="hero__coin coin-2"></div>
				<div className="hero__coin coin-3"></div>
				<div className="hero__coin coin-4"></div>
				<div className="hero__coin coin-5"></div>
				<div className="hero__coin coin-6"></div>
				<div className="hero__content">
					<AnimationOnScroll animateIn="animate__fadeInDown">
						<h1 className="hero__title">Buy and send <br /> Bitcoin with your card</h1>
					</AnimationOnScroll>
					<div className="hero__main_m">
						<img width="800" height="429" className="hero__main on-mobile" src={`${image_base_url}/upd/hero-main.webp`} />
					</div>
					<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
						<p className="section-text">Use any credit or debit card to buy cryptocurrencies in minutes, <br /> using our simple and trustworthy platform.</p>
					</AnimationOnScroll>
					<AnimationOnScroll delay="400" animateIn="animate__fadeIn">
						<div className="theme-btn-block full-on-mobile mt-subtitle">
							{
								!localDb.getVal("token") && <button onClick={handleOpenLoginModel} type="button" className="theme-btn" style={{ ...headerButtonStyle }}>
									<p>Buy Bitcoin</p>
								</button>
							}
						</div>
					</AnimationOnScroll>
				</div>
			</div>
		</section>
	)
}