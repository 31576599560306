import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';

class MyAccountDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            displayName: 'GG Poker',
            emailId: 'oliver@ggpoker.com',
            password: 'oliv@ggpoker',
            alertMsg: null
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
    }

    change = (type) => {
        alert("change " + type + " flow here")
    }

    render() {
        return (
            <HeaderAndFooterLayout>
                <div className="myAccoutDetailsMainWrap">
                    <div className="myAccoutDetailsMain">
                        <div className="myAccoutDetailsCard">
                            <h5 className="myAccoutOptModalHeading card-title text-center">Account Details</h5>
                            <form>
                                <div className="myAccoutOptModal_form_group">
                                    <label className="myAccoutOptModalLable">Display Name</label>
                                    <div className="myAccoutOptModalLable_input_wrap">
                                        <input type="text" data-e2e="accountName" className="myAccoutOptModalLable_input" id="myAccoutOptModal_displayName"
                                            value={this.state.displayName} placeholder="Name" onChange={this.handleInputChange} name="displayName" />
                                        <div className="myAccoutOptModalInputChange" data-e2e="changeName" onClick={() => this.change("displayName")}>Change</div>
                                    </div>
                                </div>
                                <div className="myAccoutOptModal_form_group">
                                    <label className="myAccoutOptModalLable">Email Address</label>
                                    <div className="myAccoutOptModalLable_input_wrap">
                                        <input type="text" data-e2e="accountEmail" className="myAccoutOptModalLable_input" id="myAccoutOptModal_emailId"
                                            value={this.state.emailId} placeholder="Email" onChange={this.handleInputChange} name="emailId" />
                                        <div className="myAccoutOptModalInputChange" data-e2e="changeEmail" onClick={() => this.change("emailId")}>Change</div>
                                    </div>
                                </div>
                                <div className="myAccoutOptModal_form_group">
                                    <label className="myAccoutOptModalLable">Current Pass</label>
                                    <div className="myAccoutOptModalLable_input_wrap">
                                        <input type="password" data-e2e="currentPassword" className="myAccoutOptModalLable_input" id="myAccoutOptModal_password"
                                            value={this.state.password} placeholder="password" onChange={this.handleInputChange} name="password" />
                                        <div className="myAccoutOptModalInputChange" data-e2e="changePassword" onClick={() => this.change("password")}>Change</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </HeaderAndFooterLayout>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDetails)