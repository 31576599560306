import React, { useEffect, useRef, useState } from 'react';
const TruevoForm = (props) => {
    const formRef = useRef(null);
    const [truevoContent, setTruevoContent] = useState(props.truevoContent);
    useEffect(() => {
        if (formRef?.current) {
            formRef.current.submit();
            setTimeout(() => {
                handleClose();
            }, 1000)
        }

    }, [])
    const handleClose = () => {
        props.handleTruevoFlag();
    }
    return (
        <div>
            <iframe type="hidden" name="iFrame"></iframe>
            {truevoContent?.length > 0 &&
                <form ref={formRef} name="frm" method="POST" target="iFrame" action={truevoContent[0]?.value}>
                    <input type="hidden" name={truevoContent[1]?.key}
                        value={truevoContent[1]?.value} />
                </form>}
        </div>
    )
}

export default TruevoForm;