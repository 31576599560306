import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import React from 'react';

function DisableEnableGoogleAuthticator(props) {
  const handleVerify = () => {
    props.type === 'enable'
      ? props.verifyAndChangeActivatation(props.activation2faToken, 'activate')
      : props.verifyAndChangeActivatation(props.deactivation2faToken, 'deactivate');
  };

  const handleClose = () => {
    props.hadnleSetAuthenticateModel()
    props.onClose();
  };

  let disable =
    props.type === 'enable'
      ? props.activation2faToken.length < 0 || props.activation2faToken === ''
      : props.deactivation2faToken.length < 0 || props.deactivation2faToken === '';

  return (
    <div>
      <Dialog onClose={handleClose} open={props.open} className="b2c-signup-model">
        <div className="enable-authenticator-model" style={{ width: '461px' }}>
          <Card style={{ width: '461px' }}>
            <div className="enable-authenticator-header">
              <div>{`${props.type === 'enable' ? 'Enable' : 'Disable'}  Authenticator Support`}</div>
              <div className="signup-close-icon">
                <ClearIcon onClick={() => handleClose()} className="icon-close" style={{ color: '#FFFFFF' }} />
              </div>
            </div>
            <div className="enable-authenticator-content">
              {props.type === 'enable' ? (
                <div className="authenticator-scanner">
                  <img src={props.imageData}></img>
                </div>
              ) : null}
              <div className="authenticator-text">Scan the QR Code in Googles authenticator app and the input verification code. </div>
              <div className="download-app-here">Download app here</div>
              <div className="email-margin">
                <div className="signup-input-label font-bold">Verification Code</div>
                <div>
                  <input
                    type="text"
                    className="signup-input-field"
                    placeholder=""
                    onChange={(e) => {
                      props.setError('');
                      props.type === 'enable' ? props.setSetActivation2faToken(e.target.value) : props.setSetDeactivation2faToken(e.target.value);
                    }}
                  />
                </div>
              </div>
              {props.error && props.error !== '' ? <div className="errorMessage" data-e2e={props.error}>{props.error}</div> : null}
              <div
                //    disabled={disbled ? true : false}
                className={disable ? 'email-margin b2c-create-account b2c-create-account-disabled mt mb' : 'email-margin b2c-create-account mt mb'}
                onClick={() => handleVerify()}
              >
                {props.type === 'enable' ? 'VERIFY' : 'CONFIRM'}
              </div>
              {props.type === 'enable' ? null : (
                <div className="b2c-already-account mt mb">
                  Didn’t recieve email? <span className="alredy-login">Re-send</span>
                </div>
              )}
            </div>
          </Card>
        </div>
      </Dialog>
    </div>
  );
}

export default DisableEnableGoogleAuthticator;