import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function WhyChoose() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  
	const [paymentProviderName, setPaymentProviderName] = useState(null)
	useEffect(()=>{
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
    }
  }, []);


	return (
		<section className="section whyChoose">
			<div className="content-container">
				{/* <AnimationOnScroll animateIn="animate__fadeInDown">
					<div className="section-upperTitle">
						<p>About us</p>
					</div>
				</AnimationOnScroll> */}
				<AnimationOnScroll animateIn="animate__fadeInUp">
					<p className="section-title mb">Digital Currencies</p>
				</AnimationOnScroll>
				<div className="whyChoose__grid">
					<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
						<div className="whyChoose__card">
							<div className="whyChoose__card_bg"></div>
							<img width="412" height="146" className="whyChoose__card_img" src={`${image_base_url}/upd/buyCryptoNew.png`} />
							<div>
								<p className="whyChoose__card_title">Buy crypto instantly</p>
								<p className="section-text">Purchase your crypto in minutes with just a few clicks.</p>
							</div>
						</div>
					</AnimationOnScroll>
					<AnimationOnScroll delay="400" animateIn="animate__fadeIn">
						<div className="whyChoose__card">
							<div className="whyChoose__card_bg"></div>
							<img width="412" height="146" className="whyChoose__card_img" src={`${image_base_url}/upd/why-choose-2.webp`} />
							<div>
								<p className="whyChoose__card_title">Credit or debit card</p>
								<p className="section-text">Use your Visa or Mastercard card from anywhere in the world.</p>
							</div>
						</div>
					</AnimationOnScroll>
					<AnimationOnScroll delay="600" animateIn="animate__fadeIn">
						<div className="whyChoose__card">
							<div className="whyChoose__card_bg"></div>
							<img width="412" height="146" className="whyChoose__card_img" src={`${image_base_url}/upd/why-choose-3.webp`} />
							<div>
								<p className="whyChoose__card_title">Convenient</p>
								<p className="section-text">Access the most popular cryptocurrencies in one place.</p>
							</div>
						</div>
					</AnimationOnScroll>
				</div>
			</div>
		</section>
	)
}