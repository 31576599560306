import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import localDb from "../../../../common/localDb";
import { checkValueInSS } from "../../../../common/theme";

export default function PsB2cHero(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, [])

  let headerButtonStyle = {}
  if (b2cColorMode === "dark") {
    if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
      headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
      headerButtonStyle.background = headerButtonDarkBgColour;
      headerButtonStyle.borderColor = headerButtonDarkBgColour;
    }
    if (headerDarkTextColour && headerDarkTextColour !== "") {
      headerButtonStyle.color = headerDarkTextColour;
    }
  } else {
    if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
      headerButtonStyle.backgroundColor = headerButtonLightBgColour;
      headerButtonStyle.background = headerButtonLightBgColour;
      headerButtonStyle.borderColor = headerButtonLightBgColour;
    }
    if (headerLightTextColour && headerLightTextColour !== "") {
      headerButtonStyle.color = headerLightTextColour;
    }
  }
  return (
    <div className="psB2cHero section">
      <div className="content-container">
        <div className="ps-section-grid">
          <div>
            <div className="ps-section-title-line"></div>
            <p className="ps-section-title mb">The easiest way to buy cryptocurrency using Pay<span style={{color:"#9F1F63"}}>Su</span> Direct</p>
            <p className="ps-section-text">Buy, sell or trade cryptocurrencies from a single easy to use location. Buy crypto with your card instantly. Pay our network of merchants with zero gas fees. Send cryptocurrency to other app users for free.</p>
						<div className="theme-btn-block">
							{
								!localDb.getVal("token") && <button className="paysu-btn" onClick={() => {
									props.handleOpenLoginModel();
                }} style={{ ...headerButtonStyle }}>Get started</button>
							}
						</div>
					</div>
          <img loading="lazy" className="ps-section-img" src={`${image_base_url}/paysu/hero-b2c-img.webp`} />
        </div>
      </div>
    </div>
  )
}
