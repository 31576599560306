import ClearIcon from '@mui/icons-material/Clear';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import qrcode from 'qrcode-generator';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions/index';
import { useTranslation, withTranslation } from 'react-i18next';
import NavbarLang from '../../../../locale/NavbarLang';
import { t } from 'i18next';
import MediaQuery from 'react-responsive';
import { Navigate, useNavigate } from 'react-router-dom';
import { constants } from '../../../common/constants';
let waitingMinutes = 10;
const darkToggle = "/common/crytoicons/dark_toggle_icon2x.png";
const lightToggle = "/common/crytoicons/light_toggle_icon2x.png";
const back_icon = "/common/crytoicons/back_icon.png";
const back_icon_light = "/common/crytoicons/back_icon_light.png";
// const logoLight = "/common/crytoicons/cryptonpay_logo_light.svg";
// const logoDark = "/common/crytoicons/cryptonpay_logo_dark.svg";
const successIcon = "/common/crytoicons/success_changes.png";
const typeNumber = 4;
const errorCorrectionLevel = 'L';
let interval = null;
let interval2 = null;
let interval3 = null;
let timeOut2 = null;


class Deposit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qrCodeText: "",
            startTime: moment(),
            crypto: "BTC",
            durationInSec: moment().startOf("day").add((60 * waitingMinutes), "seconds"),
            errorAlertDisplyed: false,
            showQRCode: false,
            listOfCopyProperties: [],
            depositStatus: "DepositFlow:AwaitingDeposit",
            cryptoImage: "",
            secs: (60 * waitingMinutes),
            showTimer: true,
            lightFlag: false,
            fiatAmount: 0,
            fiatCurrency: "USD",
            loginType: "Merchant",
        }
    }
    didMount = async () => {
        if (localStorage.getItem("loginType") && localStorage.getItem("loginType") !== "") {
            this.setState({ loginType: localStorage.getItem("loginType") })
        }
        await this.getTransactionStatus()
        this.setState({ cryptoImage: localStorage.getItem("cryptoImage"), lightFlag: (sessionStorage.getItem("lightFlag") === "true") })
        this.setState({
            startTime: localStorage.getItem("startTime") ? moment(localStorage.getItem("startTime"), "YYYY-MM-DDTHH:mm:ss.SSSZ") : this.props.startTime ? this.props.startTime : moment(),
            durationInSec: moment().startOf("day").add((60 * waitingMinutes) - parseInt(moment.duration(moment()
                .diff(moment(
                    this.props.startTime
                        ? this.props.startTime
                        : localStorage.getItem("startTime")
                            ? moment(localStorage.getItem("startTime"), "YYYY-MM-DDTHH:mm:ss.SSSZ")
                            : moment()
                ))).asSeconds()), "seconds"),
            crypto: localStorage.getItem("selectedCrypto") ? localStorage.getItem("selectedCrypto") : this.props.currency,
        }, () => {
            const startTime = this.state.startTime;
            const endTime = moment(startTime);
            this.getCryptoDepositWalletAddress();
            const timeRemaining = ((60 * waitingMinutes) - parseInt(moment.duration(moment().diff(endTime)).asSeconds())) * 1000
            interval = setInterval(() => {
                const secs = (60 * waitingMinutes) - parseInt(moment.duration(moment().diff(endTime)).asSeconds())
                this.setState({
                    durationInSec: moment().startOf("day").add((secs < 0 ? 0 : secs), "seconds"),
                    secs
                })
            }, 1000)
            timeOut2 = setTimeout(() => {
                if (!window.location.pathname.includes("/depositTracking")) {
                    if (this.state.showTimer) {
                        localStorage.setItem("status", "noDeposit")
                        localStorage.setItem("referenceCode", this.state.referenceCode)
                        this.props.navigate(`/depositStatus`)
                    }
                } else if (this.props.trackingPage === "true") {
                    if (this.state.showTimer) {
                        this.props.changeStatus("noDeposit")
                        if (interval3) {
                            clearInterval(interval3)
                        }
                    }
                }
            }, timeRemaining)
            interval3 = setInterval(() => {
                this.getTransactionStatus()
            }, (this.props.deposit_refresh_interval ? (parseInt(this.props.deposit_refresh_interval) * 1000) : 60000))
        })
    }
    componentDidMount() {
        this.didMount();
    }

    componentWillUnmount() {
        clearInterval(interval)
        clearInterval(interval2)
        clearInterval(interval3)
        clearTimeout(timeOut2)
    }

    getCryptoDepositWalletAddress = async () => {
        let list = this.state.listOfCopyProperties;
        this.setState({ listOfCopyProperties: list })

        if (!this.props.transaction) {
            if (localStorage.getItem("transactionId") && localStorage.getItem("depositMerchantId")) {
                await this.getTransaction()
            } else {
                alert("Transaction not found!")
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId: null,
                    merchantId: null,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: "406",
                    errorMessage: "getCryptoDepositWalletAddress API Error: transaction not found!",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                }, (err) => {
                    console.log("err:", err)
                })
            }
        }
        list[0] = {
            name: "Currency",
            value: localStorage.getItem("selectedCrypto")
        };
        list[1] = {
            name: 'Amount',
            crypto: localStorage.getItem("selectedCrypto"),
            value: localStorage.getItem("depositAmount"),
        };
        list[2] = {
            name: `${localStorage.getItem("fiatAmount")[0]} Amount`,
            value: localStorage.getItem("fiatAmount"),
        };
        if (this.props.transaction) {
            // this.props.setLoading(true)
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetCryptoDepositWalletAddress', {
                transactionId: this.props.transaction.transactionId,
                merchantId: this.props.transaction.merchantId,
                hostname: window.location.hostname,
                cryptocurrency: localStorage.getItem("selectedCrypto")
            }, (data) => {
                // this.props.setLoading(false)
                if (data) {
                    let list = this.state.listOfCopyProperties;
                    list[3] = {
                        name: 'Address',
                        value: data.walletAddress,
                    }
                    if (this.props.trackingPage === "true") {
                        this.props.setReferenceCode(data.referenceId)
                    }
                    this.setState({
                        listOfCopyProperties: list,
                        referenceCode: data.referenceId,
                        qrCodeText: data.walletAddress
                    })
                };
            }, (err) => {
                // this.props.setLoading(false)
                console.log(err);
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId: this.props.transaction.transactionId,
                    merchantId: this.props.transaction.merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "GetCryptoDepositWalletAddress API Error: " + err.message : "Error in GetCryptoDepositWalletAddress API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                if (!this.state.errorAlertDisplyed) {
                    this.setState({ errorAlertDisplyed: true });
                    alert("Something went wrong!")
                    // window.history.back();
                }
            })
        }
    }

    getTransaction = () => {
        return new Promise(async (resolve, reject) => {
            const transactionId = localStorage.getItem("transactionId");
            const merchantId = localStorage.getItem("depositMerchantId");
            // this.props.setLoading(true)
            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetTransactionDetails', {
                transactionId,
                merchantId,
                depositMethod: "other-wallet",
                hostname: window.location.hostname
            }, (data) => {
                // this.props.setLoading(false)
                if (data) {
                    if (data.merchantName) {
                        localStorage.setItem("depositMerchantName", data.merchantName);
                    }
                    this.props.setTransaction(data);
                    // console.log("res");
                }
                resolve(true)
            }, (err) => {
                console.log("errrr", err)
                // this.props.setLoading(false)
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId,
                    merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "GetTransactionDetails API Error: " + err.message : "Error in GetTransactionDetails API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                resolve(false)
            })
        })
    }

    getTransactionStatus = () => {
        return new Promise(async (resolve, reject) => {
            const transactionId = localStorage.getItem("transactionId");
            const merchantId = localStorage.getItem("depositMerchantId");
            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetTransactionStatus', {
                transactionId,
                merchantId,
                hostname: window.location.hostname
            }, (data) => {
                if (data) {
                    waitingMinutes = data.rateExpiryPeriod
                    // if (['DepositMonitor:DepositCompleted', 'DepositMonitor:DepositManuallyCompleted'].includes(data.status)) {
                    //     if (this.props.trackingPage === "true") this.props.changeStatus(data.status)
                    //     if (this.props.trackingPage === "true") {
                    //         if (interval3) {
                    //             clearInterval(interval3);
                    //         }
                    //     } else {
                    //         localStorage.setItem("status", data.status)
                    //         localStorage.setItem("referenceCode", this.state.referenceCode)
                    //         this.props.navigate(`/depositStatus`)
                    //     }
                    // } else {
                    if (data.status && data.status !== "" &&
                        ![
                            "DepositFlow:AwaitingDeposit",
                            "DepositFlow:Launched",
                            "CreatePayment",
                            "DepositMonitor:MonitoringMemPool",
                            "DepositFlow:DepositByCardFlowLaunched",
                            "DepositFlowViaWallet:Launched",
                            "CreateSecurePaymentConfirmation",
                            "CreateSecurePayment",
                            "DepositFlow:CardWhiteListingCheck",
                            "DepositFlow:RoutingService",
                            "DepositFlow:AMLRulesCheck",
                            "DepositFlow:AcquirerNames",
                            "DepositFlow:LoadBalanceCheck",
                            "DepositFlow:AcquirerNamesBeforeRandomSelection",
                            "DepositFlow:JwtTokenGenerated",
                            "TPEvent:UIPageLoaded",
                            "TPEvent:paymentInitStarted",
                            "TPEvent:paymentStarted",
                            "TPEvent:paymentInitCompleted",
                            "TPEvent:paymentCompleted",
                            "DepositFlow:Acquirer3dsEvent",
                            "DepositFlow:TrustNotificationSecurityCheckPassed",
                            "DepositFlow:CallbackResponseReceivedFromAcquirer",
                            "DepositMonitor:ValidCardRequest",
                            "DepositMonitor:DepositScheduledToSettle",
                            "DepositMonitor:DepositSecurityCodeOK",
                            "DepositMonitor:CryptoOrder",
                            "DepositMonitor:SelectedLiquidityProvider",
                            "DepositMonitor:WalletStatus",
                            "DepositeMoniter:MerchantBalanceUpdated",
                        ].includes(data.status)) {
                        this.setState({ showTimer: false })
                        clearInterval(interval)
                        clearTimeout(timeOut2)
                    }
                    this.setState({ depositStatus: data.status })
                    if (this.props.trackingPage === "true") this.props.changeStatus(data.status)
                    // }
                }
                resolve(true)
            }, (err) => {
                console.log("errrr", err)
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId,
                    merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "getTransactionStatus API Error: " + err.message : "Error in getTransactionStatus API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                resolve(false)
            })
        })
    }

    redirectToTracking = () => {
        window.open(`/depositTracking`)
    }

    getStatusColor = (status) => {
        switch (status) {
            case "DepositMonitor:DepositManuallyCompleted":
            case "DepositMonitor:DepositCompleted":
            case "WalletManagementService:WithdrawalCompleted":
            case "success":
            case "DepositMonitor:DepositManuallyCompleted":
            case "WalletManagementService:TransferCompleted":
            case "DepositFlow:AwaitingDeposit":
            case "DepositFlow:Launched":
            case "CreatePayment":
            case "DepositMonitor:MonitoringMemPool":
            case "DepositFlow:DepositByCardFlowLaunched":
            case "DepositFlowViaWallet:Launched":
            case "CreateSecurePaymentConfirmation":
            case "CreateSecurePayment":
            case "DepositFlow:CardWhiteListingCheck":
            case "DepositFlow:RoutingService":
            case "DepositFlow:AMLRulesCheck":
            case "DepositFlow:AcquirerNames":
            case "DepositFlow:LoadBalanceCheck":
            case "DepositFlow:AcquirerNamesBeforeRandomSelection":
            case "DepositFlow:JwtTokenGenerated":
            case "TPEvent:UIPageLoaded":
            case "TPEvent:paymentInitStarted":
            case "TPEvent:paymentStarted":
            case "TPEvent:paymentInitCompleted":
            case "TPEvent:paymentCompleted":
            case "DepositFlow:Acquirer3dsEvent":
            case "DepositFlow:TrustNotificationSecurityCheckPassed":
            case "DepositFlow:CallbackResponseReceivedFromAcquirer":
            case "DepositMonitor:ValidCardRequest":
            case "DepositMonitor:DepositScheduledToSettle":
            case "DepositMonitor:DepositSecurityCodeOK":
            case "DepositMonitor:CryptoOrder":
            case "DepositMonitor:SelectedLiquidityProvider":
            case "DepositMonitor:WalletStatus":
            case "DepositeMoniter:MerchantBalanceUpdated":
                return "#13FF34";
            case "DepositFlow:AwaitingDeposit":
            case "DepositFlow:Launched":
            case "CreatePayment":
            case "DepositMonitor:MonitoringMemPool":
            case "DepositMonitor:DepositPendingManualApproval":
            case "WithdrawalFlow:PendingApproval":
            case "DepositFlow:DepositByCardFlowLaunched":
            case "DepositFlowViaWallet:Launched":
                return "#FFA900";
            case "DepositMonitor:WalletStatusAvailable":
            case "DepositMonitor:WalletStatusLocked":
            case "DepositMonitor:UpdateWalletStatus":
            case "DepositMonitor:NotfiyMerchant":
            case "DepositMonitor:DepositManualApprove":
            case "DepositMonitor:KYTCheckFail":
            case "DepositMonitor:KYTCheckPass":
            case "DepositMonitor:KYTCheck":
            case "DepositMonitor:DepositConfirmed":
            case "DepositMonitor:GasTooLow":
            case "DepositMonitor:ExcessTransactionExecuted":
            case "DepositMonitor:TransactionExecuted":
            case "DepositMonitor:TransactionExecuting":
            case "DepositMonitor:DepositReceivedToMemPool":
            case "DepositMonitor:TransactionComplete":
            case "DepositFlow:AcquirerSelected":
            case "DepositFlow:RequestingBINNDetails":
            case "DepositFlow:ReturnedBINNDetails":
            case "DepositFlow:CardSubmittedToAcquirer":
            case "DepositFlow:ResponseReceivedFromAcquirer":
            case "DepositMonitor:ReviseDepositExchangeRate":
            case "DepositMonitor:GasCheck":
            case "DepositMonitor:ReducedDepositReceivedToMemPool":
            case "DepositMonitor:SurplusDepositReceivedToMemPool":
            case "DepositMonitor:MerchantAutomationSetting":
            case "DepositMonitor:NoAutoTradeRequired":
            case "DepositMonitor:NoAutoTradeRequiredForTestnet":
            case "DepositMonitor:AutoTradeExecuting":
            case "DepositMonitor:AutoTradeFailed":
            case "DepositMonitor:AutoTradeExecuted":
            case "DepositMonitor:ExcessAutoTradeExecuted":
            case "DepositMonitor:AutoTradeComplete":
            case "DepositMonitor:MerchantAutoTradeTransactionCreated":
            case "DepositMonitor:AwaitingDepositConfirmation":
            case "DepositMonitor:DepositManualApproved":
            case "DepositMonitor:CalculateRevisedDepositAmount":
            case "DepositMonitor:NotfiyMerchantFromAdminUI":
            case "DepositMonitor:UpdateWalletStatus":
            case "DepositMonitor:UpdateWalletBalance":
            case "DepositMonitor:UpdateMerchantWalletBalance":
            case "DepositMonitor:DepositRefundLinkSent":
            case "DepositMonitor:GetRefundLinkClicked":
            case "DepositMonitor:GetRefundRequestSubmitted":
            case "DepositMonitor:CalculateRefundAmount":
            case "DepositMonitor:RefundSent":
            case "DepositMonitor:RefundConfirmed":
            case "DepositMonitor:DepositRefunded":
            case "DepositMonitor:NoKYTCheckRequired":
            case "WithdrawalFlow:NotifyMerchant":
            case "WithdrawalFlow:ApprovedForProcessing":
            case "WalletManagementService:ProcessingWithdrawal":
            case "WalletManagementService:CalculateWithdrawalAmount":
            case "WalletManagementService:UnableToSourceWithdrawalWallet":
            case "WalletManagementService:SourcedWithdrawalWallet":
            case "WalletManagementService:MerchantAutomationSetting":
            case "WalletManagementService:SendToRecipientWallet":
            case "WalletManagementService:NotifyMerchant":
            case "WalletManagementService:NotifyAdmin":
            case "WalletManagementService:UpdateWalletBalance":
            case "WalletManagementService:WithdrawalConfirmed":
            case "DepositFlowViaWallet:NoFiatSettlementTradeRequired":
            case "DepositFlowViaWallet:SettlementTradeExecuting":
            case "DepositFlowViaWallet:SettlementTradeFailed":
            case "DepositFlowViaWallet:SettlementTradeFailedNotifyAdmin":
            case "DepositFlowViaWallet:SettlementTradeExecuted":
            case "DepositFlowViaWallet:NotfiyMerchant":
            case "MerchantSettlementService:NoSettlementRequired":
            case "MerchantSettlementService:SettlementCompleted":
            case "DepositMonitor:DepositProcessing":
            case "DepositFlow:RedirectingTo3DS":
            case "DepositFlow:RedirectedTo3DS":
            case "DepositFlow:3dsProcessed":
            case "DepositMonitor:GasCheckInvalid":
            case "DepositMonitor:MerchantBalanceUpdated":
            case "DepositMonitor:GasCheckValid":
            case "WalletTransferFlow:TransferRequested":
            case "WalletManagementService:ProcessingTransferRequest":
            case "WalletManagementService:ProcessingTransfer":
            case "WalletManagementService:TransferWallet":
            case "WithdrawalManagementService:UpdateWalletBalance":
            case "DepositMonitor:MerchantAutoTradeExececuted":
            case "DepositMonitor:NotfiyMerchantFailed":
            case "DepositMonitor:UnableToSourceRefundWallet":
            case "DepositMonitor:DepositHashAlreadyExist":
            case "DepositMonitor:DepositCommunicationsFailure":
            case "DepositMonitor:DepositSoftDeclined":
            case "DepositMonitor:InvalidCardRequest":
            case "DepositMonitor:DepositSuspended":
            case "DepositMonitor:DepositCancelled":
            case "DepositMonitor:DepositSecurityCodeIncorrect":
            case "DepositMonitor:DepositSecurityCodeNotSent":
            case "DepositMonitor:DepositSecurityCodeQuery":
            case "DepositMonitor:DepositSecurityCodeOK":
            case "DepositMonitor:DepositSettled":
            case "DepositMonitor:DepositScheduledToSettle":
            case "DepositMonitor:ValidCardRequest":
                return "#FFBE3E"
            case "DepositMonitor:DepositRejected":
            case "WalletManagementService:WithdrawalRejectionCompleted":
            case "DepositMonitor:NotfiyRejectionToMerchant":
            case "DepositMonitor:DepositManualRejected":
            case "WalletManagementService:WithdrawalRejectedDueToMerchantBalance":
            case "WalletManagementService:WithdrawalRejectedDueToInsufficientFunds":
                return "#FF0000"
            default:
                return "#FFBE3E"
        }
    }

    getStatusText = (status) => {
        switch (status) {
            case "success":
            case "DepositMonitor:DepositCompleted":
            case "DepositMonitor:DepositManuallyCompleted":
            case "WalletManagementService:WithdrawalCompleted":
            case "DepositFlowViaWallet:DepositCompleted":
            case "WalletManagementService:TransferCompleted":
            return "Deposit Successful";
            case "DepositFlow:AwaitingDeposit":
            case "DepositFlow:Launched":
            case "CreatePayment":
            case "DepositMonitor:MonitoringMemPool":
                return "Awaiting Deposit";
            case "DepositMonitor:WalletStatusAvailable":
            case "DepositMonitor:WalletStatusLocked":
            case "DepositMonitor:UpdateWalletStatus":
            case "DepositMonitor:NotfiyMerchant":
            case "DepositMonitor:DepositManualApprove":
            case "DepositMonitor:KYTCheckPass":
            case "DepositMonitor:KYTCheckFail":
            case "DepositMonitor:KYTCheck":
            case "DepositMonitor:DepositConfirmed":
            case "DepositMonitor:TransactionComplete":
            case "DepositMonitor:GasTooLow":
            case "DepositMonitor:ExcessTransactionExecuted":
            case "DepositMonitor:TransactionExecuted":
            case "DepositMonitor:TransactionExecuting":
            case "DepositMonitor:DepositReceivedToMemPool":
                return "Processing your deposit..."
            case "DepositMonitor:DepositPendingManualApproval":
            case "WithdrawalFlow:PendingApproval":
                return "Require Approval"
            case "DepositMonitor:GetRefundLinkClicked":
            case "DepositMonitor:GetRefundRequestSubmitted":
            case "DepositMonitor:SourcedRefundWallet":
            case "DepositMonitor:CalculateRefundAmount":
            case "DepositMonitor:RefundSent":
                return "Processing Refund"
            case "DepositMonitor:DepositRejected":
                return "Deposit Rejected"
            case "DepositMonitor:DepositRefunded":
                return "Refund Completed"
            default:
                return "Processing your deposit..."
        }
    }

    getStatusBarBG = (status) => {
        switch (status) {
            case "DepositMonitor:DepositManuallyCompleted":
            case "DepositMonitor:DepositCompleted":
            case "WalletManagementService:WithdrawalCompleted":
                return "/common/successful_8_bars.png";
            case "DepositFlow:AwaitingDeposit":
            case "DepositFlow:Launched":
            case "CreatePayment":
            case "DepositMonitor:MonitoringMemPool":
                return "/common/awaiting_2_bars.png";
            case "DepositMonitor:WalletStatusAvailable":
            case "DepositMonitor:WalletStatusLocked":
            case "DepositMonitor:UpdateWalletStatus":
            case "DepositMonitor:NotfiyMerchant":
            case "DepositMonitor:DepositManualApprove":
            case "DepositMonitor:DepositPendingManualApproval":
            case "WithdrawalFlow:PendingApproval":
            case "DepositMonitor:KYTCheckPass":
            case "DepositMonitor:KYTCheckFail":
            case "DepositMonitor:KYTCheck":
            case "DepositMonitor:DepositConfirmed":
            case "DepositMonitor:TransactionComplete":
            case "DepositMonitor:GasTooLow":
            case "DepositMonitor:ExcessTransactionExecuted":
            case "DepositMonitor:TransactionExecuted":
            case "DepositMonitor:TransactionExecuting":
            case "DepositMonitor:DepositReceivedToMemPool":
                return "/common/processing_4_bars.png"
            default:
                return "/common/processing_4_bars.png"
        }
    }

    handleToggle = () => {
        sessionStorage.setItem("lightFlag", !this.state.lightFlag);
        this.setState({ lightFlag: !this.state.lightFlag })

    }
    redirectToHome = () => {
        window.open("/")
    }

    getCryptoName = (crypto) => {
        switch (crypto) {
            case "BTC":
                return "Bitcoin";
            case "ETH":
            case "USDC":
            case "USDT":
            case "DAI":
                return "Ethereum";
            case "LTC":
                return "Litecoin";
            case constants?.TRC20_USDT_CRYPTO_ID:
            case constants?.TRX_CRYPTO_ID:
                return "Tron"
            default:
                return "";
        }
    }
    handleBitcoinName = (crypto) => {
        const newstr = t('transaction_warning')
        const CoinName = `${crypto} (${this.getCryptoName(crypto)})`
        return newstr.replace(/coinName/g, CoinName)
    }
    render() {
        const { t } = this.props
        return (
            <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                <div className="deposit_screen_wrap">
                    <div className={this.state.lightFlag ? "background_color_white deposit_card deposit_wrap_box_white" : "deposit_card "}>
                        {/* {this.props.disableTrack !== "true" && <TopHeading />} */}
                        <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                            {this.props.hideButtons !== "false" && this.props.trackingPage !== "true" ? <div data-e2e="backBtn" className={this.state.lightFlag ? "text_color_black deposit_heading_btn margin_right" : "deposit_heading_btn"} style={{ marginRight: "50px" }}>
                                <img src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.setItem('isGoingBack', true)
                                    window.history.back();
                                }} />
                            </div> : <div style={{ width: "21px", marginRight: "50px" }} />}
                            <span data-e2e="sendDeposit">{t('Transaction Summary')}</span>
                            <div className="d-flex align-items-center">
                                <NavbarLang />
                                <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                            </div>
                        </div>
                        <CustomProgressBar depositStatus={this.getStatusText(this.state.depositStatus)} image_base_url={this.props.image_base_url} lightFlag={this.state.lightFlag} />
                        <div className='deposit_card_body'>
                            {this.state.listOfCopyProperties.map((data, i) => {
                                return <CopyPropertyItem lightFlag={this.state.lightFlag} data={data} key={i} fontType={this.props.fontType} />
                            })}
                            <div className={(this.state.lightFlag ? "deposit_details_border_bottom_white " : "") + 'deposit_crypto_block pb-2 pt-3 deposit_details_border_bottom'}>
                                <span className={this.state.lightFlag ? "text_color_title deposit_title" : "deposit_title"}>{t('Time left')}: <i className="fa fa-info-circle deposit_title_icon" data-tip data-for="timeRemainId"></i>
                                    {/* <img src={`${this.props.image_base_url}${info_icon}`} alt="infoicon" className="myAcc_settingItemIcon" data-tip data-for="timeRemainId" /> */}
                                    <ReactTooltip id="timeRemainId" place='right' effect='solid' className="tool-tip-window-black" arrowColor="#000">
                                        <div className='tool-tip-text-content' data-e2e="Allowable Crypto tooltip">{t('Time left until the order expires')}</div>
                                    </ReactTooltip></span>
                                <span className={this.state.lightFlag ? "text_color_black deposit_description" : "deposit_description"}>{this.state.durationInSec.format("mm:ss")}</span>
                            </div>
                            <div className={(this.state.lightFlag ? "deposit_details_border_bottom_white " : "") + 'deposit_crypto_block pb-2 pt-3 deposit_details_border_bottom'}>
                                <span className={this.state.lightFlag ? "text_color_title deposit_title" : "deposit_title"}>{t('Status')}:</span>
                                <span className="deposit_description" style={{ color: this.getStatusColor(this.state.depositStatus) }} data-e2e="status" data-e2e-status={this.getStatusText(this.state.depositStatus)}>{t(this.getStatusText(this.state.depositStatus))}</span>
                            </div>

                            {this.props.disableTrack !== "true" && <MediaQuery minWidth={768} ><div className={'deposit_crypto_block pb-3 pt-3'}>
                                <div className={this.state.lightFlag ? "depositTrackBtn text_color_black" : "depositTrackBtn"} data-e2e="depositTrackBtn" onClick={() => {
                                    this.redirectToTracking()
                                }}>
                                    {t('Open in New Tab')}<i className="fa fa-external-link ml-2 depositTrackBtnIcon" />
                                </div>
                            </div></ MediaQuery>}
                            <div className='deposit_note'>
                                <span className='deposit_note_span'><i className="fa fa-exclamation-triangle deposit_note_span_icon"></i></span>
                                <span className={`deposit_note_desc ${this.state.lightFlag ? "text_color_black" : ""}`}>{this.handleBitcoinName(this.state.crypto)}</span>

                            </div>
                            <div className={`deposit_FAQ ${this.state.lightFlag ? "text_color_black" : ""}`}>{t('Questions')}?<a className="ml-2" target="_blank" href={`${this.state.loginType === "Merchant" ? "/faq-home" : "/faq-b2c-home"}`}>{t('Visit our FAQ page')}</a></div>
                        </div>
                    </div>
                    {/* <div className='m-5 text-center'>
                        <div className={`sendTokendeposit_card_body_footer_content ${this.state.lightFlag ? "text_color_black" : ""}`}>Powered by <img className='ml-2 cursor-pointer sendTokendeposit_card_body_footer_icon' src={`${this.props.image_base_url}${this.state.lightFlag ? logoLight : logoDark}`} alt="logo"
                            onClick={() => {
                                this.redirectToHome();
                            }} /></div>
                    </div> */}
                </div>
                {/* {this.props.disableTrack !== "true" && <img src={`${this.props.image_base_url}${this.state.lightFlag ? logoLight : logoDark}`} alt="logo" className="cptreadmarkLogo" />} */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        payment_api_url: state.config.payment_api_url,
        image_base_url: state.config.image_base_url,
        deposit_refresh_interval: state.config.deposit_refresh_interval,
        transaction: state.deposit.transaction,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // setLoading: (value) => dispatch(actions.setLoading(value)),
        setTransaction: (value) => dispatch(actions.setTransaction(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()((props) => <Deposit {...props} navigate={useNavigate()} />));

const CopyPropertyItem = (props) => {
    const { crypto, name, value } = props.data;
    const [showCoppied, toggleCoppied] = useState(false);
    return (
        <div className={(props.lightFlag ? "deposit_details_border_bottom_white " : "") + 'deposit_crypto_block pb-2 pt-3 deposit_details_border_bottom'}>
            <span className={props.lightFlag ? "text_color_title deposit_title" : "deposit_title"}>{t(name)}:</span>
            {(name === "Address" || name === "Amount") ? <span className={props.lightFlag ? "text_color_black deposit_description" : "deposit_description"}>{value ? name === "Amount" ? (value + " " + crypto) : (value.substring(0, 7) + "..." + value.substring(value.length - 3, value.length))
                : "N/A"}{showCoppied ? <i className="fa fa-clone pl-2 cursor-pointer" {...{ [`data-e2e-${name}`]: value }} data-e2e={name} style={{ color: "#0091FF" }}></i> : <i className="fa fa-clone pl-2 cursor-pointer" {...{ [`data-e2e-${name}`]: value }} data-e2e={name} onClick={() => {
                    copy(value)
                    toggleCoppied(true)
                    setTimeout(() => {
                        toggleCoppied(false)
                    }, 3000)
                }}></i>}</span> :
                <span className={props.lightFlag ? "text_color_black deposit_description" : "deposit_description"}>{t(value)}</span>}
        </div>
    )
}

// const TopHeading = () => {
//     const { t } = useTranslation()
//     return (<div className="deposit_topHeading_wrap" >
//         <div className="deposit_topHeading_title" >
//             {t('Make_deposit')}
//         </div>
//         <ClearIcon onClick={() => {
//             localStorage.removeItem("depositMethod")
//             window.close();
//         }} className="deposit_topHeading_close" />
//     </div>
//     )
// }
const CustomProgressBar = (props) => {

    const getBarPercent = (status) => {
        switch (status) {
            case "Awaiting Deposit":
                return "27%";
            case "Deposit Successful":
                return "100%";
            default:
                return "74%";
        }
    }

    return (<div className="df_progress_wrap" >
        <div className="df_progreesBarWrap" >
            <div className={`df_progress_track ${props.lightFlag ? "df_progress_tracklight" : ""}`}>
                <div className="df_progress_bar" style={{ width: getBarPercent(props.depositStatus) }} />
            </div>
        </div>
        <div className="df_progreesIconWrap" >
            {["Awaiting Deposit", "Deposit Successful", "Processing your deposit..."].includes(props.depositStatus) ? <img className="df_progressIcon" src={`${props.image_base_url}${successIcon}`} /> : <div className="df_step_count">1</div>}
            <div>{["Awaiting Deposit"].includes(props.depositStatus) ? <i className="fa fa-angle-double-right df_progressArrows" /> : ""}</div>

            {["Deposit Successful", "Processing your deposit..."].includes(props.depositStatus) ? <img className="df_progressIcon" src={`${props.image_base_url}${successIcon}`} /> : <div className="df_step_count">2</div>}
            <div>{["Processing your deposit..."].includes(props.depositStatus) ? <i className="fa fa-angle-double-right df_progressArrows" /> : ""}</div>

            {["Deposit Successful"].includes(props.depositStatus) ? <img className="df_progressIcon" src={`${props.image_base_url}${successIcon}`} /> : <div className="df_step_count">3</div>}
        </div>
        <div className="df_progreesStepNameWrap" >
            <div className={`df_progressStep mr-2 ${props.lightFlag ? "text_color_black" : ""}`}>{t('Awaiting Payment')}</div>
            {["Deposit Successful", "Processing your deposit..."].includes(props.depositStatus) ? <div className={`df_progressStep ${props.lightFlag ? "text_color_black" : ""}`}>{t('Processing Payment')}</div> : <div className={` df_progressStep df_progressStepIncomplete`}>{t('Processing Payment')}</div>}
            {["Deposit Successful"].includes(props.depositStatus) ? <div className={`df_progressStep ${props.lightFlag ? "text_color_black" : ""}`}>{t('Deposit Successful')}</div> : <div className={` df_progressStep df_progressStepIncomplete`}>{t('Deposit Successful')}</div>}
        </div>
    </div>
    )
}
