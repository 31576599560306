import React from 'react';
import B2CHeaderAndFooter from '../../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import HomeB2c from '../../Home/HomeB2c';
import ScrollIntoView from '../../../../common/scrollIntoView';

function B2CHome() {
  return (
    <div>
      <ScrollIntoView>
        <B2CHeaderAndFooter>
          <HomeB2c />
        </B2CHeaderAndFooter>
      </ScrollIntoView>
    </div>
  );
}

export default B2CHome;
