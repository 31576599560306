import React from 'react';
import Header from './Header';
import B2CFooter from '../B2CHeaderAndFooter/B2CFooter';
import ScrollIntoView from '../../../common/scrollIntoView';
import { useLocation } from 'react-router-dom';
import PaysuFooter from '../../Pages/Paysu/components/PaysuFooter';
import PaysuHeader from './PaysuHeader';
const HeaderAndFooterLayout = (props) => {

	const location = useLocation();

	const pageComponents = [
		{ header: PaysuHeader, footer: PaysuFooter, regexp: /paysu-home-merchant/ }
	]

	const currentComponent = pageComponents.find(component => {
		const regexp = component.regexp;
		return regexp.test(location.pathname)
	})

	function renderComponent(type) {
		if (type === 'header') {
			if (currentComponent) {
				const Component = currentComponent.header;
				return <Component {...props} />
			}
			else return <Header {...props} />
		}
		if (type === 'footer') {
			if (currentComponent) {
				const Component = currentComponent.footer;
				return <Component {...props} />
			} else return <B2CFooter {...props} />
		}
	}

	return (
		<div>
			<ScrollIntoView>
				{renderComponent('header')}
				{props.children}
				{renderComponent('footer')}
			</ScrollIntoView>
		</div>
	);
};

export default HeaderAndFooterLayout;
