import ClearIcon from '@mui/icons-material/Clear';
// import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import { getCryptoIcon } from "../../../../common/getCryptoIcon";
import * as actions from '../../../../Redux/actions';
import BuyCryptoModel from './BuyCryptoModel';
import CreditCardModal from './CreditCardModal';
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const b2cSendCryptoModel = (props) => {
  let type = props && props.type && props.type;
  const api_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const [selectedCrypto, setSelectedCrypto] = useState({});
  const [openCryptoOption, setOpenCryptoOption] = useState(false);
  const [cryptoWallet, setCryptoWallet] = useState([]);
  const [selectedToAddress, setSelectedToAddress] = useState('');
  const [openToAddressOption, setOpenToAddressOption] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [toAddress, setToAddress] = useState([]);
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  const [dollarValue, setDollerValue] = useState();
  const [cryptoValue, setCryptoValue] = useState();
  const [fromCurrency, setFromCurrency] = useState('USD');
  const [toCurrency, setToCurrency] = useState(props.coin);
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const [error, setError] = useState('');
  const [openBuyCryptoModel, setOpenBuyCryptoModel] = useState(false);
  const [resTotal,setResTotal]=useState(0)

  const [gasFeeMapper,setGasFeeMapper]=useState({
    'BTC':0,
    'ETH':0
  })

  const [gasValidation,setGasValidation]=useState({
    type:'',
    chargeGas:true,
    allowAhead:false
  })
  const [errorMessage,setErrorMessage]=useState('')

  const [openCardOptions, setOpenCardOptions] = useState(false);
  const [selectedCard, setSelectedCard] = useState([]);
  // const [removeCard, setRemoveCard] = useState(false);
  // const [addNewCardModel, setAddNewCardModel] = useState(false);
  const exchangeIcon = b2cTheme === 'light' ? '/homeb2c/b2cwalletexchangecryptolight.svg' : '/homeb2c/b2cwalletexchangecryptodark.svg';


  // const handleOpenAddNewCardToBuyCrypto = () => {
  //   setAddNewCardModel(true);
  // };

  const handleCloseCreditCardModal = () => {
    console.log("close flag");
    setShowCreditCard(false);
  };

  // const handleOpenRemoveCardModel = () => {
  //   setRemoveCard(true);
  // };

  const handleClose = () => {
    props.onClose();
  };
  
  useEffect(() => {
    getCryptos();
    getGasFee()
    getExchangeRate(fromCurrency, toCurrency, 0);
    if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
  }, []);

  useEffect(()=>{
    gasFeeValidation()
  },[inputValue])

  useEffect(()=>{
      if(gasValidation.type==='duplicateUser'){
        return setErrorMessage('Cannot Send Money to self')
      }
      setErrorMessage('')
  },[gasValidation.type])



    useEffect(()=>{
      if(gasValidation.chargeGas){
        return setResTotal(parseFloat(cryptoValue)+parseFloat(calcCrypFee()))
      }
      setResTotal(parseFloat(cryptoValue))
    },[cryptoValue,gasFeeMapper,gasValidation.chargeGas])

    useEffect(()=>{
      let interval=setInterval(getGasFee,30000)
      return ()=>clearInterval(interval)
    },[])
 



  const handleCrypto = () => {
    setOpenCryptoOption(!openCryptoOption);
  };

  const handleCardOptions = () => {
    setOpenCardOptions(!openCardOptions);
  };

  const handleSelectCryptoOptions = (ele) => {
    setError('');
    setSelectedCrypto(ele);
    setToCurrency(getSymbol(ele.symbol));
    setOpenCryptoOption(false);
    setDollerValue(0);
    setCryptoValue(0);
  };

  const handleToAddress = () => {
    setOpenToAddressOption(!openToAddressOption);
  };
  const handleSelectToAddressOptions = (ele) => {
    setInputValue('');
    setSelectedToAddress(ele);
    setOpenToAddressOption(false);
  };
  
  const disabled = type === 'receive' ? (inputValue === '' && selectedToAddress === '')  || dollarValue === undefined  || (dollarValue !== undefined && dollarValue <= 0)||error !== '' : selectedCrypto === '' || error !== '' ;

  const getSymbol = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return 'BTC';
      case 'ETH':
        return 'ETH';
      case 'USDT':
      case 'TRC20-USDT':
        return 'USDT';
      case 'DAI':
        return 'DAI';
      case 'USDC':
        return 'USDC';
      case 'LTC':
        return 'LTC';
      case 'XRP':
        return 'XRP';
      case 'TRX':
        return 'TRX'
      default:
        return 'BTC';
    }
  };

  const [dataSetter, setDataSetter] = useState('crypto');

  const handleDollerChange = (e) => {
    setError('');
    setDollerValue(e.target.value);
    setDataSetter('crypto');
    if (e.target.value) {
      getExchangeRate(fromCurrency, toCurrency, e.target.value);
    } else {
      getExchangeRate(fromCurrency, toCurrency, 0);
    }
  };
  const handleCryptoChange = (e) => {
    setError('');
    setCryptoValue(e.target.value);
    setDataSetter('doller');
    if (e.target.value) {
      getExchangeRate(toCurrency, fromCurrency, e.target.value);
    } else {
      getExchangeRate(toCurrency, fromCurrency, 0);
    }
  };
  const getExchangeRate = (fromCurrency, toCurrency, currencyAmount) => {
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-crypto-or-amount-exchange-rate',
      {
        fromCurrency: fromCurrency,
        toCurrency: toCurrency,
        currencyAmount: currencyAmount,
      },
      (data) => {
        if (data) {
          if (dataSetter === 'crypto') {
            setCryptoValue(parseFloat(data).toFixed(8));
          }
          if (dataSetter === 'doller') {
            setDollerValue(parseFloat(data).toFixed(8));
          }
        }
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const getCryptos = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-cryptos',
      {
        user_email: localStorage.getItem("user_name"),
      },
      (data) => {
        props.setLoading(false);
        if (data.cryptos) {
          data.cryptos.map((ele) =>{ if(ele.symbol === props.coin) setSelectedCrypto(ele)});
          setCryptoWallet(data.cryptos);
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  const getGasFee = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/fetch-gas',
      {  
      },
      (data) => {
        props.setLoading(false);
        if (data) {
          setGasFeeMapper(data)
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  const gasFeeValidation = () => {
    props.setLoading(true);

    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/check-cryp-user',
      {
        address:inputValue.length>0 ? inputValue : '',
        email:localStorage.getItem("user_name"),
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (data) => {
        props.setLoading(false);
        setGasValidation(data)
        console.log(data)
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  const handleOpenBuyCryptoModel = () => {
    if (dollarValue === undefined || dollarValue === 0) {
      setError('Enter amount.')
    } else {
      setOpenBuyCryptoModel(true);
      if (selectedCard && selectedCard.length !== 0) {
        setOpenBuyCryptoModel(true);
      }
    }
  };

  const handleSelectCard = (ele) => {
    setSelectedCard({ name: 'card' });
    setOpenCardOptions(false);
  };

  const handleCloseCryptoModel = () => {
    // props.onClose();
    setOpenBuyCryptoModel(false);
  };
  const handalSaveCryptosSend = () => {
    if (dollarValue === undefined || dollarValue === 0) {
      setError('Enter amount.');
    } else {
      const toAddress = selectedToAddress !== '' ? selectedToAddress.address : inputValue;
      props.saveCryptoBySelectedAddress(localStorage.getItem("user_name"), toAddress, fromCurrency, toCurrency, currencyAmount, dollarValue, resTotal,gasValidation.chargeGas ?calcCrypFee() :0 );
    }
  };

  useEffect(() =>{
    if(Object.keys(selectedCrypto).length !== 0 ){
      getToAddress();
    }
  },[selectedCrypto]);
  
  const getToAddress = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-all-address-books-by-userId',
      {
        userId:localStorage.getItem('b2cUserId'),
        cryptoId:selectedCrypto._id,
      },
      (data) => {
        props.setLoading(false);
        if (data) {
          setToAddress(data);
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  const calcCrypFee=()=>{
    if(toCurrency==="BTC"){
      return gasFeeMapper['BTC']
    }else{
      return gasFeeMapper['ETH']
    }
  }
  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)
  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} style={{zIndex: 1500}} className="b2c-signup-model receive_model_mobile">
        <div className="enable-authenticator-model" style={{ width: '461px' }}>
          <Card style={{ width: '461px' }} className="card-bg">
            <div className="enable-authenticator-header">
              <div></div>
              <div data-e2e={`${type === 'receive' ? 'send' : 'buy'}-crypto`}>{`${type === 'receive' ? 'Send' : 'Buy'} Crypto`}</div>
              <div className="signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={() => handleClose()} className="icon-close" style={{ color: '#FFFFFF' }} />
              </div>
            </div>
            <div className="send__lable">{`${type === 'receive' ? 'From wallets' : 'Choose Crypto'}`}</div>
            <div className="enable-authenticator-content" style={{ paddingTop: '0rem' }}>
              <div
                className={openCryptoOption ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
                onClick={() => 
                 !props.coinFridge ? handleCrypto() : null}
              >
                <div className="verify-onboard-sub-heading">
                  {selectedCrypto && selectedCrypto !== '' ? (
                    <div>
                      {' '}
                      <img src={`${image_base_url}${getCryptoIcon(selectedCrypto.symbol)}`} className = "currencyImg"  alt="bitcoin" height={30} width={30} />{' '}
                      <span className="ml-10" data-e2e={selectedCrypto.name}>{selectedCrypto.name}</span>
                    </div>
                  ) : (
                    cryptoWallet &&
                    cryptoWallet.length > 0 && (
                      <div>
                        {' '}
                        <img src={`${image_base_url}${getCryptoIcon(cryptoWallet[0].symbol)}`}  className = "currencyImg"   alt="bitcoin" height={30} width={30} />{' '}
                        <span className="ml-10" data-e2e={cryptoWallet[0].name}>{cryptoWallet[0].name}</span>
                      </div>
                    )
                  )}
                </div>
                <div>{openCryptoOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
              </div>
              {openCryptoOption && (
                <div className="kyc-options-container verify-onboard-sub-heading">
                  {cryptoWallet &&
                    cryptoWallet.length > 0 &&
                    cryptoWallet.map((ele, index) => {
                         return (
                        <div key={index} className="kyc-options cursor-pointer">
                          <div key={index} onClick={() => handleSelectCryptoOptions(ele)}>
                            <div>
                              <img src={`${image_base_url}${getCryptoIcon(ele.symbol)}`}  className = "currencyImg" alt="bitcoin" data-e2e={index} height={30} width={30} />{' '}
                              <span className="ml-10" data-e2e={ele.name}>{ele.name}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            {/* ) : (
              <div className="signup-input-label font-bold mt-20 receive-crypto-text-header">No wallets available </div>
            )} */}
            {type === 'receive' ? (
              <div>
                <div className="send__lable">{`To`}</div>
                <div className="enable-authenticator-content" style={{ paddingTop: '0rem' }}>
                  <div
                    className={openToAddressOption ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
                    // onClick={() => handleToAddress()}
                  >
                    <div className="verify-onboard-sub-heading" style={{ width: '99%' }}>
                      {selectedToAddress && selectedToAddress ? (
                        openToAddressOption ? (
                          <div>
                            <input
                              className="ml-10 selection__input"
                              type="text"
                              placeholder="Enter or select address"
                              data-e2e="enter-or-select-address"
                              value={inputValue}
                              onChange={(e) => {
                                setInputValue(e.target.value)
                                setError('')
                                setSelectedToAddress('');
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <span className="ml-10">
                              {selectedToAddress.nickName + ': ' + selectedToAddress.address.substr(0, 10) + '...' + selectedToAddress.address.substr(-3, 3)}
                            </span>
                          </div>
                        )
                      ) : (
                        toAddress &&
                        toAddress.length >= 0 && (
                          <div>
                            <input
                              className="ml-10 selection__input"
                              value={inputValue}
                              onChange={(e) =>{ setInputValue(e.target.value)
                              }}
                              type="text"
                              placeholder="Enter or select address"
                              data-e2e="enter-or-select-address"
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div onClick={() => handleToAddress()}>
                      {openToAddressOption ? <ExpandLessIcon className="kyc-progress" data-e2e="expansion-icon"/> : <ExpandMoreIcon className="kyc-progress" data-e2e="expansion-icon"/>}
                    </div>
                  </div>
                  {openToAddressOption && (
                    <div className="kyc-options-container select__to__address">
                      {toAddress &&
                        toAddress.length > 0 &&
                        toAddress.map((ele, index) => {
                          return (
                            <div className="kyc-options cursor-pointer" style={{ paddingLeft: '0.7rem' }}>
                              <div key={index} onClick={() => handleSelectToAddressOptions(ele)}>
                                <div>
                                  <div style={{ fontWeight: 'bold' }}>{ele.nickName}</div>
                                  <div data-e2e={ele.address} style={{ opacity: '0.8' }}>{ele.address}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            ) : type === 'buy' ? (
              // <div>
              //   <div className="send__lable">Choose card</div>
              //   <div className="enable-authenticator-content" style={{ paddingTop: '0rem' }}>
              //     <div
              //       className={openCardOptions ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
              //       onClick={() => handleCardOptions()}
              //     >
              //       <div className="verify-onboard-sub-heading">
              //         {selectedCard && selectedCard !== '' ? (
              //           <div>
              //             {' '}
              //             <img src={''} alt="cardName" height={30} width={30} /> <span className="ml-10">{'****1234'}</span>
              //           </div>
              //         ) : (
              //           <div>{'Select Card'}</div>
              //         )}
              //       </div>
              //       <div>{openCardOptions ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
              //     </div>
              //     {openCardOptions && (
              //       <div className="kyc-options-container verify-onboard-sub-heading">
              //         {cryptoWallet &&
              //           cryptoWallet.length > 0 &&
              //           cryptoWallet.map((ele, index) => {
              //             return (
              //               <div key={index} className="kyc-options cursor-pointer">
              //                 <div key={index} onClick={() => handleSelectCard(ele)}>
              //                   <div className="send-buy-model-label">
              //                     <div>
              //                       <img src={''} alt="cardName" data-e2e={index} height={30} width={30} /> <span className="ml-10">{'***1234'}</span>
              //                     </div>
              //                     <div onClick={() => handleOpenRemoveCardModel()}>
              //                       <DeleteIcon className="delete-icon-clr"/>
              //                     </div>
              //                   </div>
              //                 </div>
              //               </div>
              //             );
              //           })}
              //         <div className="send__continue buy-crypto-add-now-btn-hght" onClick={() => handleOpenAddNewCardToBuyCrypto()}>{`Add Now`}</div>
              //       </div>
              //     )}
              //   </div>
              // </div>
              null
            ) : null}

            <div className="send-buy-model-label">
              <div className="send__lable">{`You ${type === 'receive' ? `send` : 'Deposit'}`}</div>
              <div className="send__lable buy-right-side-label">{`${type === 'receive' ? '' : 'You get'}`}</div>
            </div>
            <div className="enable-authenticator-content" style={{ paddingTop: '0rem' }}>
              <div className="you__send">
                <div className="you__send__left">
                  <span>{`$`} </span>
                  <input type="number" placeholder="0" data-e2e="amount-field" onChange={handleDollerChange} value={dollarValue} />
                </div>
                <div className="you__send__mid">
                <img src={`${image_base_url}${exchangeIcon}`} alt="exchangeIcon"  />
                </div>

                <div className="you__send__left">
                  <span>{toCurrency} </span>
                  <input type="number" placeholder="0" data-e2e="amount-in-crypto" data-e2e-cryp={cryptoValue} value={cryptoValue} onChange={handleCryptoChange} style={{ width: '116px' }} />
                </div>
              </div>

              {/* {
                dollarValue && dollarValue>0 ?
                <div className='gas-fee-container'>
                  { gasValidation.chargeGas===true ?
                <div className='gas-fee-child-container '>
                  <div>Estimated Gas Fee</div>
                  <div>{calcCrypFee()} {toCurrency}</div>
                </div>
                :null
                  }

                  <div className='gas-fee-child-container'>
                    <div>Estimated Total</div>
                    <div>{resTotal} {toCurrency}</div>
                  </div>
               
              </div>
              :null
              } */}
             
            </div>
            <div className='send-cryp-error' data-e2e={errorMessage}>
                  {errorMessage}
            </div>


            <div className="enable-authenticator-content" style={{ paddingTop: '0rem' }}>
              {error !== '' && <div className="error__msg">{error}</div>}
              <button
              data-e2e="continue-btn"
                disabled={ type === 'receive' ? disabled : false}
                onClick={() => {
                  type === 'receive' ? handalSaveCryptosSend() : handleOpenBuyCryptoModel();
                }}
                className={type === 'receive' ? !disabled ? 'send__continue' : 'send__continue send__continue__opacity' : 'send__continue'}
                style={type === 'receive' ? !disabled ? { ...headerButtonStyle } : {} : { ...headerButtonStyle }}
              >{`Continue`}</button>
            </div>
          </Card>
        </div>
      </Dialog>
      {openBuyCryptoModel && <BuyCryptoModel  setShowCreditCardModal={() => {
          setShowCreditCard(true)
        }}
        dollarValue={dollarValue}
        cryptoValue={cryptoValue}
        fromCurrency={fromCurrency}
        cryptoCurrency={toCurrency}
        cryptoName={selectedCrypto.name}
        cryptoIcon={getCryptoIcon(selectedCrypto.symbol)} 
        open={openBuyCryptoModel} 
        onClose={handleCloseCryptoModel} 
        type="buy" />}
      {showCreditCard && <CreditCardModal
        depositAmount={dollarValue}
        cryptoId={toCurrency}
        fromCurrency={fromCurrency}
        // saveCard={saveCard}
        cryptoAmount={cryptoValue}
        cryptoIcon={getCryptoIcon(selectedCrypto.symbol)}
        cryptoName={selectedCrypto.name}
        open={showCreditCard} onClose={handleCloseCreditCardModal} />}
        </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    checkKycToButCrypto: (value) => dispatch(actions.checkBuyCryptoStatus(value)),
  };
};
export default connect(null, mapDispatchToProps)(b2cSendCryptoModel);
