import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import qrcode from 'qrcode-generator';
import html2canvas from 'html2canvas';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';

const typeNumber = 0;
const errorCorrectionLevel = 'L';
const InvoicePaymentRequest = (props) => {
  const { open, handleClose, invoiceDetailsData, invoiceURL, orderId, image_base_url, allCryptos, selectedCryptos } = props;
  const [copySuccess, setCopySuccess] = useState(false);
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [colorMode, setColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
    if (checkValueInSS("colorMode")) {
      setColorMode(sessionStorage.getItem("colorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, []);


  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`${invoiceURL}?invoiceId=${orderId}`)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error copying link:', error);
      });
  };
  const generateQRCode = (qrCodeText) => {
    try {
      const qr = qrcode(typeNumber, errorCorrectionLevel);
      qr.addData(qrCodeText);
      qr.make();
      const qrImageBase64 = qr.createDataURL();
      setQrCodeSrc(qrImageBase64);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadClick = () => {
    html2canvas(document.getElementById('qrCodeImg')).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'qr_code.png';
      link.click();
    });
  };
  useEffect(() => {
    const urlValue = invoiceURL + `?invoiceId=${orderId}`;
    generateQRCode(urlValue);
  }, [orderId]);
  const iconsDisplay = () => {
    return (
      <div className="currency-icons-display-details">
        {selectedCryptos &&
          selectedCryptos.map((obj, index) => {
            const foundObject = allCryptos.find((symbol) => symbol.symbol === obj);
            return <img key={index} src={image_base_url + foundObject.icon} alt={index} width={25} height={25} />;
          })}
      </div>
    );
  };
  const url = invoiceURL + `?invoiceId=${orderId}`;
  let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog open={open} className="b2c-signup-model deposit_model_mobile">
        <div className="enable-authenticator-model" style={{ width: '461px' }}>
          <Card style={{ width: '461px' }} className="card-bg">
            <div className="p-0 mt-4 invoice-modal-header enable-authenticator-header">
              <div className="create-invoice-modal-header">{`Payment Request`}</div>
              <div className="invoice-close-icon signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={handleClose} className="" />
              </div>
            </div>
            <div className="authenticator-scanner">
              <img id="qrCodeImg" className="cryptoWalletQRCode invoice_qrCode" alt="QR Code" src={qrCodeSrc} />
              <div>
                <button className="invoice-download-qr-code" onClick={handleDownloadClick} style={{ ...headerButtonStyle }}>
                  DOWNLOAD QR CODE
                </button>
              </div>
            </div>
            <div className="invoice-link-box">
              <div className="invoice-link"> {url && (url.substring(0, 35) + '...' + url.slice(-2)) || "N/A"}</div>
              <div>
                <button onClick={handleCopyClick} data-e2e="copy-btn" data-e2e-url={url || "N/A"} className="invoice-copy-btn" style={{ ...headerButtonStyle }}>
                  COPY
                </button>
              </div>
            </div>
            {copySuccess && <p className="copy-link-message">Link copied successfully!</p>}
            {invoiceDetailsData &&
              invoiceDetailsData.map((detail, index) => {
                if (index < 3) {
                  return (
                    <div key={index + 1} className="invoice-details-container">
                      <div className="invoice-details-key">{detail.key}:</div>
                      <div className="invoice-details-value">
                        {index === 0 && (
                          <div className="invoice-details-icons">
                            {iconsDisplay()}
                            <div>{detail.value}</div>
                          </div>
                        )}
                        {index !== 0 && detail.value}
                      </div>
                    </div>
                  );
                }
              })}
            <button className={'create-invoice-btn-handler mt-4'} style={{ ...headerButtonStyle }} onClick={handleClose}>
              <div>CLOSE</div>
            </button>
          </Card>
        </div>
      </Dialog>
    </div>
  );
};

export default InvoicePaymentRequest;
