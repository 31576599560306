import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import queryString from 'query-string';
import { apiService } from '../../../common/apiCallService';
import { useSelector } from 'react-redux';
import { getRedirectUrlBasedOnUiFlow } from '../../../common/getRedirectUrlBasedOnUiFlow';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeHttp } from '../../../common/getIP';
const REFUND_SUCCESSFUL_URL = "/creditCard/refundSuccessful"
const REFUND_FAILED_URL = "/creditCard/refundFailed"

let interval1 = null;
let timeOut1 = null

const TransactionStatusCheckLoading = (props) => {
   const paymentApiUrl = useSelector((state) => state.config.payment_api_url);
   const navigate = useNavigate()
   
   useEffect(() => {
      let transactionId = queryString.parse(props.location.search).transactionId
      let merchantId = queryString.parse(props.location.search).merchantId
      if (merchantId && merchantId.includes("?")) {
         const splitMerchantId = merchantId.split("?")
         merchantId = splitMerchantId[0]
      }
      if (transactionId && transactionId.includes("?")) {
         const splitTransactionId = transactionId.split("?")
         transactionId = splitTransactionId[0]
      }
      getTransactionStatus(transactionId, merchantId) //first call
      timeOut1 = setTimeout(() => {
         if (transactionId) {
            getTransactionStatus(transactionId, merchantId)
         }
      }, 15000) // 15 sec
      interval1 = setInterval(() => {
         if (transactionId) {
            getTransactionStatus(transactionId, merchantId)
         }
      }, 30000)
      return () => {
         if (interval1) {
            clearInterval(interval1)
            clearTimeout(timeOut1)
         }
      }
   }, [])
   
   const getTransactionStatus = (transactionId, merchantId) => {
      try {
         apiService((window.location.hostname === "localhost" ? paymentApiUrl : "") + '/pga/GetTransactionDetails', {
         transactionId,
            merchantId: merchantId && merchantId !== "undefined" && merchantId !== "null" ? merchantId : null,
            hostname: window.location.hostname
         }, async (data) => {
            if (data) {
               const redirectUrlObj = await getRedirectUrlBasedOnUiFlow(data)
               if (data.redirectUrl3DS) {
                  window.location.href = data.redirectUrl3DS
               } else if (
                  data.status &&
                  (
                     data.status.message === "Deposit completed" ||
                     data.status.message === "Deposit manually completed" ||
                     data.status.message === "Deposit completed via wallet" ||
                     data.status.message === "Withdrawal completed" ||
                     data.status.message === "Transfer completed"
                  )
               ) {
                  if (interval1) {
                     clearInterval(interval1)
                  }
                  if (window.top !== window.self) {
                     // Check if we can safely access window.top and it's not the same as the current window
                        window.top.location.href = `/redirectToUrl?redirectUrlParam=${redirectUrlObj?.successUrl}`;
                  } else {
                     // Fallback to navigate or other redirection if window.top isn't available
                     window.location.href = redirectUrlObj?.successUrl
                  }
               } else if (
                  data.status &&
                  (
                     data.status.message === "Deposit refunded"
                  )
               ) {
                  if (interval1) {
                     clearInterval(interval1)
                  }
                  navigate(REFUND_SUCCESSFUL_URL);
               } else if (data.status &&
                  (
                     data.status.message === "Withdrawal Rejected" ||
                     data.status.message === "Deposit Rejected" ||
                     data.status.message === "Rejecting Deposit" ||
                     data.status.message === "Deposit manual rejected" ||
                     data.status.message === "Withdrawal rejection completed" ||
                     data.status.message === "Notify rejection to merchant" ||
                     data.status.message === "Withdrawal rejected due to merchant balance" ||
                     data.status.message === "Withdrawal rejected due to insufficient funds"
                  )) {
                  if (interval1) {
                     clearInterval(interval1)
                  }
                   if (window.top !== window.self) {
                     // Check if we can safely access window.top and it's not the same as the current window
                        window.top.location.href = `/redirectToUrl?redirectUrlParam=${redirectUrlObj?.failedUrl}`;
                 } else {
                     // Fallback to navigate or other redirection if window.top isn't available
                     window.location.href = redirectUrlObj?.failedUrl
                 }
               } else if (data.status &&
                  (
                     data.status.message === "Refund Failed"
                  )) {
                  if (interval1) {
                     clearInterval(interval1)
                  }
                  navigate(REFUND_FAILED_URL);
               }
            }
         }, (err) => {
            console.error(err);
         })
      } catch (err) {
         console.error(err)
      }
   }

   return (
      <div >
         <div className='loading_pay_main row' >
            <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
            <div className='loading_pay_main_title'>
               Stay on this page! don't go back or exit.
               <p>Checking Transaction Status...</p>
            </div>
         </div>
      </div>
   );
}

export default (props) => <TransactionStatusCheckLoading {...props} location={useLocation()} />;