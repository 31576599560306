import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import validator from 'validator';
import * as actions from '../../../Redux/actions';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import { apiService } from '../../../common/apiCallService';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
const homeMainBG = "/home/homemainbg.png";
const email = "/common/crytoicons/email.png"


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            redirectToLogin: 0,
            alertMsg: null,
            showSuccessAlertModal: false,
            isModalHide: false,
            emailError: "",
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token")) {
            this.setState({ redirectToLogin: 1 })
        }
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
    }

    forgotPasswordEmail = () => {
        if (this.state.emailId && this.state.emailId !== "" && validator.isEmail(this.state.emailId)) {
            this.setState({ alertMsg: null })
            this.props.setLoading(true)
            apiService((window.location.hostname === 'localhost' ? this.props.api_url : '') + '/restapi/forgot-password', {
                email: this.state.emailId,
                hostname: window.location.hostname,
                domainNameUrl: localStorage.getItem("domainNameUrl"),
		        paymentProviderName: sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"
            }, (data) => {
                this.props.setLoading(false)
                if (data && data.success) {
                    this.setState({
                        showSuccessAlertModal: true,
                        isModalHide: true,
                    })
                } else if (data && data.msg) {
                    this.setState({ alertMsg: "Something Went Wrong!", emailError: data.msg })
                } else {
                    this.setState({ alertMsg: "Something Went Wrong!" })
                    // console.log(data)
                }
            }, (err) => {
                this.props.setLoading(false)
                this.setState({ alertMsg: "There is no account associated with this mailid."})
                console.log(err);
            })
        } else {
            this.setState({ emailError: "Please enter valid email address!" })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
            emailError: "",
            alertMsg:""
        });
    }

    render() {
        let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)
			return (
				<div>
					{!this.state.isModalHide &&
						<Dialog className="theme-modal" open={this.props.show} onClose={() => { this.props.closeModal() }}>
							<div className="theme-modal-body">
								<div className="theme-modal-close">
									<ClearIcon onClick={() => this.props.closeModal()} className="icon-close" />
								</div>
								<p className="theme-modal-title">{this.props.title}</p>
								
								{this.state.redirectToLogin == 1 && <Navigate to='/login' />}
								{this.state.alertMsg &&
									<div className="alert alert-danger" role="alert">
											{this.state.alertMsg}
									</div>}
								<form>
									<p className="theme-modal-text">Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
									<p className="theme-modal-text">For security reasons, we do NOT store your password.</p>
									<br />
									<div className="theme-modal-field">
										<p className="theme-modal-field-label">Email</p>
										<input type="email" 
											className={`theme-modal-field-input ${this.state.emailError !== "" ? "project_emailId_errorBorder" : ""}`} id="project_emailId"
                      value={this.state.emailId} placeholder="Email Address" onChange={this.handleInputChange} name="emailId" />
										<div className="errorMessage">{this.state.emailError}</div>
									</div>
									
									<div className="theme-btn-block theme-modal-submit full-width">
										<button type="button"
											disabled={this.state.emailError !== ""}
											className={`theme-btn ${this.state.emailError === "" ? '' : 'disabled'}`}
											onClick={() => {this.forgotPasswordEmail()}}
                                            style={{ ...(this.state.emailError === "" ? headerButtonStyle : {}) }}
										><p>Reset Password</p>
										</button>
									</div>
								</form>
							</div>
						</Dialog>
					}
					<Dialog open={this.state.showSuccessAlertModal} onClose={() => {
						this.props.closeModal()
						this.setState({ showSuccessAlertModal: false })
					}} className="theme-modal">
						<div className="theme-modal-body">
							<div className="theme-modal-close">
								<ClearIcon onClick={() => this.props.closeModal()} className="icon-close" />
							</div>
							<p className="theme-modal-title">{this.props.title}</p>
							
							<div className="theme-modal-thanks">
								<svg className="theme-modal-thanks-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="#ECFDF3"/><circle cx="30" cy="30" r="15" fill="#62CA7A"/><path d="M22 30.25L23.5 28.75L27.25 32.5L35.5 24.25L37 25.75L27.25 35.5L22 30.25Z" fill="white"/><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke="#D1FADF"/></svg>
								<p className="theme-modal-thanks-title">Thanks!</p>
								<p className="section-text theme-modal-text t-center">{`If a ${sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"} account exists for that email address, we will email you instructions for resetting your password.`}</p>
							</div>
						
							<div className="theme-btn-block theme-modal-submit full-width">
								<button type="button"
									className="theme-btn"
									onClick={() => {this.props.changeModalType("login")}}
                                    style={{ ...headerButtonStyle }}
								><p>Back to Login</p>
								</button>
							</div>
						</div>
					</Dialog>
				</div>
			)
    }

}
const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)