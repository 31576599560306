import React from 'react';
import { connect } from 'react-redux'
const noDepositImage = "/common/nodepositstatus.png";
class DepositStatus extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            referenceCode: this.props.referenceCode,
            lightFlag: false,
            loginType: "Merchant",
        }
    }

    componentDidMount = () => {
        if (localStorage.getItem("loginType") && localStorage.getItem("loginType") !== "") {
            this.setState({ loginType: localStorage.getItem("loginType") })
        }
        this.setState({
            referenceCode: localStorage.getItem("referenceCode") ? localStorage.getItem("referenceCode") : this.props.referenceCode,
            lightFlag: (sessionStorage.getItem("lightFlag") === "true"),
            transactionId:localStorage.getItem("transactionId") 
        })
    }
    redirectToHome = () => {
        window.open("/")
    }
    render() {
        return (
            <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                <div className="deposit_screen_wrap">
                    <div className={this.state.lightFlag ? "background_color_white deposit_card deposit_wrap_box_white" : "deposit_card"}>
                        <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                            <div style={{ width: "21px", marginRight: "50px" }} />
                            <span data-e2e="sendDeposit">Transaction Summary</span>
                            <div style={{ width: "21px", marginRight: "50px" }} />
                        </div>
                        <div className='deposit_card_body mb-5 ml-5 mr-5 deposit_status_screen'>
                            <div className={`depositStatusImageWrap ${this.state.lightFlag ? "depositStatusImageWrapLight" : ""}`}>
                                <img src={`${this.props.image_base_url}${noDepositImage}`} className="depositStatusImage" />
                            </div>
                            <div className={this.state.lightFlag ? "text_color_black no-deposit" : "no-deposit"}>We haven't detected a payment yet.</div>
                            <div className={(this.state.lightFlag ? "text_color_black " : "") + "deposit_status_description"}>
                            We haven’t detected a payment yet.We will keep checking for the next 24 hours. <br/><br/> 

                            Your transaction will be processed at the current rate when it arrives. <br/><br/> 

                            Please close this window to return to the merchant.<br/><br/> 
                            </div>
                            <div className={(this.state.lightFlag ? "text_color_black " : "") + "deposit_status_description transaction-word"}>
                            Transaction Id :&nbsp;<span>{this.state.transactionId }</span>
                            </div>
                            <div className={`deposit_FAQ ${this.state.lightFlag ? "text_color_black" : ""}`}>Questions?<a className="ml-2" target="_blank"  href={`${this.state.loginType === "Merchant" ? "/faq-home" : "/faq-b2c-home"}`}>Visit our FAQ page</a></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        image_base_url: state.config.image_base_url,
    }
}

export default connect(mapStateToProps, null)(DepositStatus);