import React, { useEffect } from 'react'

const GainDropdDown=({dropdownData,setDropDown,fetchLatestTrendData,mode})=>{
    let times=mode=='trending' ? ['1M','1Y'] :['24H','1M','1Y']
    return (
        <div className="title__right dropdown-chosen ">
                        <div onClick={()=>setDropDown({...dropdownData,open:!dropdownData.open})}> 
                            {dropdownData.data}{' '}
                            <span>
                              <i className="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                            </div>
                        {dropdownData.open ? (
                        <div className="dropdown-container">
                            {
                                times.map((string)=>{
                                    return <div className='dropdown-child ' onClick={()=>setDropDown({open:false,data:string})}>{string}</div>
                                })
                            }
    
                      </div>)
                      :(null)
                        }
            </div>
    )
}

export default GainDropdDown