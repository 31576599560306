import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";

export default function PaysuSimplified() {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section grey-background">
			<div className="content-container">
				<div className="ps-section-grid align-center">
					<div>
						<div className="ps-section-title-line"></div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="ps-section-title mb">Crypto Payments …<br/>simplified.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Currently, e-commerce fiat payments have been a staple of your business growth. You are connected to probably multiple service providers to achieve this, and they do all the work for you. That’s fine, but what about crypto?</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Now, crypto might not be on your horizon, stability, complexity and in general, a lack of familiarity might be preventing you from accessing a growing revenue channel for you. With new coins and crypto currencies launching all the time, it’s an understandable concern.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="600" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">With PaySu though, we can awaken that market for you. We provide a simple end to end service for you to accept either fiat or crypto, we manage the whole process end to end, nothing could be simpler…. And it is not expensive!</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="800" animateIn="animate__fadeInUp">
							<p className="ps-section-text"><span>*Simply put, fiat is banking speak for your typical local bank currency</span></p>
						</AnimationOnScroll>
					</div>
					<img className="ps-section-img" src={`${image_base_url}/paysu/ps-crypto-payments.webp`} />
				</div>
			</div>
		</section>
	)
}