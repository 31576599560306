import { Card, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const BuyCryptoModel = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const buyIcon = "/homeb2c/buy_crypto.png";
  const removeIcon = "/homeb2c/remove_card.png"
  const buyAlert = "/cryptonpay/alert_buy_crypto.svg"
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
  }, []);
  
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const getButtonStyle = () => {
    if (props?.type === "remove") {
      return headerButtonStyle;
    }
    return !isChecked ? { background: "#828282", cursor:"not-allowed" } : headerButtonStyle;
  };

  const createTransaction = () =>{
    let exchageRate = null;
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-crypto-or-amount-exchange-rate',
      {
        fromCurrency: props.fromCurrency,
        toCurrency: props.cryptoCurrency,
        currencyAmount: 1,
      },
      (data) => {
        if (data) {
          exchageRate = parseFloat(data).toFixed(8);
          let domainUrl = window.location.hostname === 'localhost' ? api_url : (window.location.protocol +"//"+ window.location.hostname);
          let request = {
            user_email: localStorage.getItem("user_name"),
            depositAmount: props.dollarValue,
            cryptoId: props.cryptoCurrency,
            cryptoAmount: props.cryptoValue,
            domainUrl,
            exchageRate,
            domainNameUrl: localStorage.getItem("domainNameUrl"),
            domainId: sessionStorage?.getItem("domainId"),
            paymentProviderName: sessionStorage?.getItem("paymentProviderName")
          }
          apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/create-buy-transaction-using-credit-card',
            request,
            (data) => {
              if (data && data.transactionId) {
                localStorage.setItem("transactionId",data.transactionId);
                props.onClose();
                props.setShowCreditCardModal();
              }
            },
            (err) => {
              alert("Create buy transaction request failed!")
              console.log(err);
            },
          );
        }
        },
        (err) => {
          alert("Create buy transaction request failed!")
          console.log(err);
        },
      );
      
  }
  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)
  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} style={{zIndex: 1500}} className="send__crypto__status">
        <div className="status__img__content text-center">
          <img className="status__imgs" src={`${image_base_url}${props.type === 'remove' ? removeIcon : buyIcon}`} alt="statusIcon" />
          <div className="enable-authenticator-model">
            <Card className="card-bg crypto__status__content">
              <div className="enable-authenticator-content text-center">
                <div className="status__title" style={{ marginTop: '50px' }}>
                  {props.type === 'remove' ? 'Remove credit card' : 'Buy Crypto'}
                </div>
                <div className="status__msg__buy_crypto mt-20">
                  {props.type === 'remove'
                    ? 'Are you sure you want to remove selected credit card? This action cannot be undone.'
                    : 'Please, doublecheck the summary below, before making a purchase'}
                </div>
                {props.type === 'remove' ? null : (
                  <div className="status__msg__buy_crypto mt-30">
                    <div className="send-buy-model-label mb-10">
                      <div className="buy-currency-field">Currency</div>
                      <div className="status__msg__buy_crypto_value">
                        {props.cryptoCurrency} / {props.cryptoName}&nbsp;
                        <span>
                          <img className="cryptoImgBuy" src={`${image_base_url}${props.cryptoIcon}`} alt="bitcion" height={30}/>
                        </span>
                      </div>
                    </div>
                    <div className="buy-currency-border-bottom mb-30"></div>
                    <div className="send-buy-model-label mb-10">
                      <div className="buy-currency-field">Amount</div>
                      <div className="status__msg__buy_crypto_value" data-e2e={props.cryptoValue}>{props.cryptoValue} {props.cryptoCurrency} ({props.dollarValue} $) </div>
                    </div>
                    <div className="buy-currency-border-bottom mb-20"></div>
                    <div className="alert-container">
                        <img
                          src={`${image_base_url}${buyAlert}`}
                          alt="buy-alert"
                          className="alert-image"
                          loading="lazy"
                        />
                      <div>
                        <p className="alert-text">Buying crypto is inherently risky</p>
                        <p className="alert-subtext">
                          Don’t invest unless you are prepared to lose all the money you invested. You should not expect to be protected if something goes wrong
                        </p>
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className='alert-checkbox'
                          />
                          <a href={`${window?.location?.origin}/terms-of-use`} target="_blank" rel="noopener noreferrer">
                            Accept T&C’s
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {props.type === 'remove' ? (
                <div className="enable-authenticator-content" style={{ paddingTop: '0rem' }}>
                  <input
                    placeholder={props.cardNumberMaskSelectedForDelete}
                    name="cardNumber"
                    disabled={true}
                    type="text"
                    //   value={emailId}
                    //   onChange={(e) => setEmailId(e.target.value)}
                    className="change-password-input"
                  />
                </div>
              ) : null}
              <div className="receive-wallet-bottom status__button">
                <div onClick={() => (isChecked || props?.type === 'remove') ? createTransaction() : ""} className="send__continue" style={getButtonStyle()} data-e2e={props.type === 'remove' ? 'Remove' : 'Buy Now'}>
                  {props.type === 'remove' ? 'Remove' : 'Buy Now'}
                </div>
              </div>
              <div className="cursor-pointer text-center status__msg__buy_crypto_value status__go_back" onClick={props.onClose} data-e2e="go-back-btn">
                Go Back
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default BuyCryptoModel;