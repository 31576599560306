import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { removeHttp } from '../../../../common/getIP';

const getCurrencySymbol = (currency) => {
  if (currency === 'USD') {
    return '$';
  } else if (currency === 'EUR') {
    return '€';
  } else if (currency === 'GBP') {
    return '£';
  } else {
    return null;
  }
};

const KalonpaySuccessScreen = () => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const { amount, currency, merchantRedirectUrl } = Object.fromEntries(searchParams.entries());
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  useEffect(() => {
    document?.body?.classList?.add('kalonpay-theme');
    return () => {
      document?.body?.classList?.remove('kalonpay-theme');
    };
  }, []);
  const handleRedirectMerchant = () => {
    window?.location?.replace(`https://${removeHttp(merchantRedirectUrl)}`, '_self');
  };
  return (
    <>
      <div className="kal-deposit-container">
        <div className="kal-deposit-form-container">
          <div className="kal-deposit-body">
            <div className="success-content">
              <div>
                <img src={`${imageBaseUrl}/kalonpay/kalonpay_deposit_success.svg`} width={500} height={200} alt="kalonpay_deposit_success" />
              </div>

              <div className="margin-tb mb-0">
                <p className="success-text mb-2">Successful Deposit!</p>
                <p className="description mb-0">Your deposit has been made successfully!</p>
                {currency && amount && <div className="amount-text">{getCurrencySymbol(currency) + ' ' + amount + ' ' + currency}</div>}
              </div>
            </div>
          </div>
          {merchantRedirectUrl && (
            <div className="kal-form-footer">
              <button onClick={handleRedirectMerchant}>Continue</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KalonpaySuccessScreen;
