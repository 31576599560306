import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import copy from 'copy-to-clipboard';
import qrcode from 'qrcode-generator';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { apiService } from '../../../../common/apiCallService';
import { getCryptoIcon } from "../../../../common/getCryptoIcon";
import { setLoading } from "../../../../Redux/actions";

function ReceiveCryptoModel(props) {
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const api_url = useSelector((state) => state.config.api_url);
  const [cryptoWallet, setCryptoWallet] = useState([]);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const typeNumber = 4;
  const errorCorrectionLevel = 'L';

  const image_base_url = useSelector((state) => state.config.image_base_url);

  const [selectedCrypto, setSelectedCrypto] = useState('');
  const [openCryptoOption, setOpenCryptoOption] = useState(false);
  const [preSelectedBlockchain, setPreSelectedBlockchain] = useState(props.blockchain)
  const [updatedcryptoWallet, setupdatedcryptoWallet] = useState([])

  const copyIcon = b2cTheme === 'light' ? '/homeb2c/copy_light.webp' : '/homeb2c/copy_dark.webp';

  const getSymbol = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return 'Bitcoin (BTC) ';
      case 'ETH':
        return 'Ethereum (ETH) ';
      case 'USDC':
        return 'USD Coin (USDC) ';
      case 'USDT':
        return 'USDT (USDT)';
      case 'DAI':
        return 'DAI (DAI)';
      case 'XRP':
        return 'XRP (XRP)';
      case 'LTC':
        return 'LiteCoin (LTC)';
      case 'TRX':
        return 'Tron (TRX)';
      case 'TRC20-USDT':
        return 'Tron (TRC20-USDT)'
      default:
        return 'Bitcoin (BTC) ';
    }
  };

  const handleClose = () => {
    props.setCoinFridge(false)
    props.onClose();
  };

  const handleCrypto = () => {
    setOpenCryptoOption(!openCryptoOption);
  };

  const handleSelectCryptoOptions = (ele) => {
    setSelectedCrypto(ele);
    props.setCoin(ele.cryptoId.cryptoId)
    setOpenCryptoOption(false);
  };

  const getCryptoCoins = async () => {
    dispatch(setLoading(true));
    await apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-user-wallets',
      {
        user_email: localStorage.getItem("user_name"),
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (data) => {
        if (data) {
          setupdatedcryptoWallet(data);
          dispatch(setLoading(false));
          let preSelectedBlockchainList = data.filter(obj => {
            return obj.blockchain === props.blockchain
          })
          if (preSelectedBlockchainList.length > 0) {
            setSelectedCrypto(preSelectedBlockchainList && preSelectedBlockchainList[0]);
          } else if (data.length > 0) {
            alert("Wallet is not available for selected blockchain");
          } else {
            console.error("Wallet list is empty! please check with admin.")
          }
        } else {
          console.error("Wallet list is empty! please check with admin.")
        }
      },
      (err) => {
        dispatch(setLoading(false));
        console.error(err);
      },
    );
  };
  const generateQRCode = (qrCodeText) => {
    try {
      var qr = qrcode(typeNumber, errorCorrectionLevel);
      qr.addData(qrCodeText);
      qr.make();
      document.getElementById('cryptoWalletQRCode').innerHTML = qr.createImgTag();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  useEffect(() => {
    getCryptoCoins();
  }, []);

  useEffect(() => {
    if (updatedcryptoWallet.length > 0) {
      const newdata = updatedcryptoWallet.reduce((accumulator, item) => {
        if (item.blockchain === "Ethereum") {
          const supportedCryptos = ["USDT", "USDC", "DAI"];
          const supportedCryptoIds = props.allCryptos
            .filter((crypto) => supportedCryptos.includes(crypto.name))
          const filteredItems = supportedCryptoIds.map((cryptoId) => ({
            ...item,
            blockchain: cryptoId.name,
            cryptoId: {
              ...cryptoId,
              cryptoId:cryptoId.name,
            },
          }));
          return [...accumulator, ...filteredItems];
        } else {
          return accumulator;
        }
      }, []);
      setCryptoWallet([...updatedcryptoWallet, ...newdata]);
    }
  }, [updatedcryptoWallet]);

  useEffect (()=> {
    if (selectedCrypto !== '' && cryptoWallet && cryptoWallet.length > 0) {
      generateQRCode(selectedCrypto.address.key);
    }
  }, [selectedCrypto, cryptoWallet])

  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} className="b2c-signup-model receive_model_mobile">
        <div className="enable-authenticator-model" style={{ width: '461px' }}>
          <Card style={{ width: '461px' }} className="card-bg">
            <div className="enable-authenticator-header">
              <div></div>
              <div data-e2e="receive-crypto-window">{`Receive Crypto`}</div>
              <div className="signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={() => handleClose()} className="icon-close" style={{ color: '#FFFFFF' }} />
              </div>
            </div>
            {cryptoWallet && cryptoWallet.length > 0 ? (
              <div className="enable-authenticator-content ">
                <div
                  className={openCryptoOption ? 'kyc-seleted-options kyc-select-options cursor-pointer' : 'kyc-select-options kyc-select cursor-pointer'}
                  onClick={() => {
                    if (!props.coinFridge) { handleCrypto() }
                  }}
                >
                  <div className="verify-onboard-sub-heading">
                    {selectedCrypto && selectedCrypto !== '' ? (
                      <div>
                        {' '}
                        <img src={`${image_base_url}${getCryptoIcon(selectedCrypto.cryptoId.cryptoId)}`}  className="currencyImg" alt="bitcoin" height={30} width={30} />{' '}
                        <span className="ml-10" data-e2e={selectedCrypto.blockchain.toLowerCase().replaceAll(' ', '-')}>{selectedCrypto.blockchain}</span>
                      </div>
                    ) : (
                      cryptoWallet &&
                      cryptoWallet.length > 0 && (
                        <div>
                          {' '}
                          <img
                            src={`${image_base_url}${getCryptoIcon(cryptoWallet.filter((obj => {
                              return obj.blockchain === preSelectedBlockchain
                            }))[0].cryptoId.cryptoId)}`}
                           className="currencyImg" alt="bitcoin" height={30} width={30} />{' '}
                          <span className="ml-10">{cryptoWallet.filter((obj => {
                            return obj.blockchain === preSelectedBlockchain
                          }))[0].cryptoId.blockchain}</span>
                        </div>
                      )
                    )}
                  </div>
                  <div>{openCryptoOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
                </div>
                {openCryptoOption && (
                  <div className="kyc-options-container verify-onboard-sub-heading">
                    {cryptoWallet &&
                      cryptoWallet.length > 0 &&
                      cryptoWallet.map((ele, index) => {
                        return (
                          <div className="kyc-options cursor-pointer">
                            <div key={index} onClick={() => { handleSelectCryptoOptions(ele) }}>
                              <div>
                                {' '}
                                <img src={`${image_base_url}${getCryptoIcon(ele.cryptoId.cryptoId)}`} className="currencyImg" alt="bitcoin" height={30} width={30} />{' '}
                                <span className="ml-10" data-e2e={ele.blockchain.toLowerCase().replaceAll(' ', '-')}>{ele.blockchain}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                <div className="authenticator-scanner">
                  <style>{`\
                      .cryptoWalletQRCode > img { width: 170px; height: 170px; margin : auto }\
                  `}</style>
                  <div id="cryptoWalletQRCode" data-e2e="qr-code" className="cryptoWalletQRCode deposit_qrCode" />
                </div>
                <p>
                  <div className="signup-input-label font-bold mt-20 receive-crypto-text-header" data-e2e={
                    selectedCrypto && selectedCrypto !== ''
                      ? getSymbol(props.coin)
                      : getSymbol(cryptoWallet && cryptoWallet.length > 0 && cryptoWallet[0].cryptoId.cryptoId)}>
                    Only send{' '}
                    {selectedCrypto && selectedCrypto !== ''
                      ? getSymbol(props.coin)
                      : getSymbol(cryptoWallet && cryptoWallet.length > 0 && cryptoWallet[0].cryptoId.cryptoId)}{' '}
                    &nbsp;to this address
                  </div>
                </p>
                {props.coin === "ETH" ?
                  <div className="authenticator-text mb-20 receive-crypto-text">
                    Only send Ethereum using the ERC-20 network. Sending other tokens or from other networks will result in them being permanently lost.
                  </div>
                  : <div className="authenticator-text mb-30 receive-crypto-text">
                    Sending any other asset, other than{' '}
                    {selectedCrypto && selectedCrypto !== ''
                      ? getSymbol(props.coin)
                      : getSymbol(cryptoWallet && cryptoWallet.length > 0 && cryptoWallet[0].cryptoId.cryptoId)}{' '}
                    , will result in permanent loss.
                  </div>}
                <div className={`kyc-select-options kyc-select cursor-pointer mb-10 copy-text ${copied ? 'active' : ''}`}>
                  <div className="verify-onboard-sub-heading">
                    <MediaQuery minWidth={767} data-e2e={selectedCrypto && selectedCrypto !== ''
                      ? selectedCrypto &&
                      selectedCrypto.address &&
                      selectedCrypto.address.key &&
                      selectedCrypto.address.key.substring(0, 18) +
                      '....' +
                      selectedCrypto.address.key.substring(selectedCrypto.address.key.length - 6, selectedCrypto.address.key.length)
                      : cryptoWallet[0] &&
                      cryptoWallet[0].address &&
                      cryptoWallet[0].address.key &&
                      cryptoWallet[0].address.key.substring(0, 18) +
                      '....' +
                      cryptoWallet[0].address.key.substring(cryptoWallet[0].address.key.length - 6, cryptoWallet[0].address.key.length)}>
                      {selectedCrypto && selectedCrypto !== ''
                        ? selectedCrypto &&
                        selectedCrypto.address &&
                        selectedCrypto.address.key &&
                        selectedCrypto.address.key.substring(0, 18) +
                        '....' +
                        selectedCrypto.address.key.substring(selectedCrypto.address.key.length - 6, selectedCrypto.address.key.length)
                        : cryptoWallet[0] &&
                        cryptoWallet[0].address &&
                        cryptoWallet[0].address.key &&
                        cryptoWallet[0].address.key.substring(0, 18) +
                        '....' +
                        cryptoWallet[0].address.key.substring(cryptoWallet[0].address.key.length - 6, cryptoWallet[0].address.key.length)}
                    </MediaQuery>

                    <MediaQuery maxWidth={768}>
                      {selectedCrypto && selectedCrypto !== ''
                        ? selectedCrypto &&
                        selectedCrypto.address &&
                        selectedCrypto.address.key &&
                        selectedCrypto.address.key.substring(0, 21) +
                        '....'
                        : cryptoWallet[0] &&
                        cryptoWallet[0].address &&
                        cryptoWallet[0].address.key &&
                        cryptoWallet[0].address.key.substring(0, 21) +
                        '....'
                      }
                    </MediaQuery>
                    {/* {' '}
                    {selectedCrypto && selectedCrypto !== ''
                      ? selectedCrypto &&
                        selectedCrypto.address &&
                        selectedCrypto.address.key &&
                        selectedCrypto.address.key.substring(0, 18) +
                          '....' +
                          selectedCrypto.address.key.substring(selectedCrypto.address.key.length - 6, selectedCrypto.address.key.length)
                      : cryptoWallet[0] &&
                        cryptoWallet[0].address &&
                        cryptoWallet[0].address.key &&
                        cryptoWallet[0].address.key.substring(0, 18) +
                          '....' +
                          cryptoWallet[0].address.key.substring(cryptoWallet[0].address.key.length - 6, cryptoWallet[0].address.key.length)} */}
                  </div>
                  <div
                    onClick={() => {
                      copy(selectedCrypto && selectedCrypto !== '' ? selectedCrypto.address.key : cryptoWallet[0].address.key);
                      handleCopy();
                    }}
                  >
                    <img src={`${image_base_url}${copyIcon}`} alt="bitcoin" height={20} data-e2e-address={selectedCrypto && selectedCrypto !== ''
                      ? selectedCrypto &&
                      selectedCrypto.address &&
                      selectedCrypto.address.key
                      : cryptoWallet[0] &&
                      cryptoWallet[0].address &&
                      cryptoWallet[0].address.key} data-e2e="copy-icon" width={17} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="signup-input-label font-bold mt-20 receive-crypto-text-header">No wallets available </div>
            )}
          </Card>
        </div>
      </Dialog>
    </div>
  );
}

export default ReceiveCryptoModel;
