import * as actionTypes from '../actions/actionTypes'

const initialState = {
    transaction: null,
    cardAPIType: "Trust Payments",
};
const setTransaction = (state, action) => {
    return {
        ...state,
        transaction: action.data,
    }
}
const setCardAPIType = (state, action) => {
    return {
        ...state,
        cardAPIType: action.data,
    }
}
const depositReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TRANSACTION: return setTransaction(state, action);
        case actionTypes.SET_CARD_API_TYPE: return setCardAPIType(state, action);
        default:
            return state;
    }
};

export default depositReducer;