import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import {apiService} from '../../../common/apiCallService';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import B2CHeaderAndFooterLayout from '../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import MediaQuery from 'react-responsive';


const FaqDetails = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [faqData, setFaqData] = useState([]);
  const [question, setQuestion] = useState('');
  const [steps, setSteps] = useState([]);
  const [description, setDescription] = useState('');
  const [descriptionList, setDescriptionList] = useState([]);
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  const [loginType, setLoginType] = useState("Merchant")


  const [loginTypeIsMerchant, setLoginTypeIsMerchant] = useState(false);
  useEffect(() =>{
    if (localStorage.getItem("loginType") && localStorage.getItem("loginType") !== "") {
      setLoginType(localStorage.getItem("loginType"))
    }
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
    }   
    if(localStorage.getItem('faqType') === 'Merchant'){
      setLoginTypeIsMerchant(true);
    }
    if(localStorage.getItem('faq_topic')){
      setName(localStorage.getItem('faq_topic'));
      faq();
      if(Object.keys(props.faqQuestionData).length !== 0){
        setQuestion(props.faqQuestionData.question ? props.faqQuestionData.question :'' );
        setSteps(props.faqQuestionData.steps ? props.faqQuestionData.steps  :[]);
        setDescription(props.faqQuestionData.description ? props.faqQuestionData.description  :'' );
        setDescriptionList(props.faqQuestionData.descriptionList ? props.faqQuestionData.descriptionList :[]);
      }
    }
  },[])

  const getPPNameReplaced = (text) => {
    if (paymentProviderName && paymentProviderName !== "" && paymentProviderName !== "null") {
      return text.replaceAll('Cryptonpay', paymentProviderName).replaceAll('cryptonpay', paymentProviderName);
    } else {
      return text
    }
  }

  const faq = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-topic-wise-faq',
      {
        topic:localStorage.getItem('faq_topic'),
      },
      (data) => {
        if (data) {
          props.setLoading(false);
          setFaqData(data.questionList);
          if(Object.keys(props.faqQuestionData).length === 0 ){
            setQuestion(data.questionList[0].question);
            setSteps(data.questionList[0].steps ? data.questionList[0].steps :[]);
            setDescription(data.questionList[0].description ? data.questionList[0].description  :'' );
            setDescriptionList(data.questionList[0].descriptionList ? data.questionList[0].descriptionList :[]);
          }
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    )
  }

  return (
    <div className='faq_detail_container'>
      {loginTypeIsMerchant ? (
        <HeaderAndFooterLayout>
      <div className='faq_detail_subContainer'>
        <MediaQuery minWidth={900} >
          <div className='faq_detail'>
            <div className='question_list'>
              <div className='back_arrow'><i onClick={() =>{navigate('/faq-home'); props.setFaqQuestionData({})}} className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{name}</span>
              </div>
              {
                faqData.length > 0 && faqData.map((ele,i) =>
                  <div key={i} className={ele.question !== question ? 'question': 'question question_active'}
                  onClick={() =>{
                    setQuestion(ele.question); 
                    setSteps(ele.steps);
                    if(ele.description) setDescription(ele.description);
                    if(ele.descriptionList) setDescriptionList(ele.descriptionList);
                    }} >
                    {"- " + getPPNameReplaced(ele.question)}
                  </div>
                )
              }  
            </div>
            <div className='question_content'>
              <div className='content_title'>{getPPNameReplaced(question)}</div>
              {steps.length > 0 && steps.map((stp, i) =>
                <div className='question_steps' key={i}>
                  <div className='step_content'>
                    <span>{`Step ${i+1}: `}</span>
                    {getPPNameReplaced(stp.step)}
                  </div>
                  <div className='step_img'>
                    <div className='step_content' style={{borderBottom:'none', paddingBottom:'20px'}}>{`Fig. ${i+1}`}</div>
                      <img src={`${image_base_url}${stp.image}`}  alt="" />
                  </div>
                </div>
              )}
              {description && steps.length ===0 && description !== '' &&
                <div style={{paddingBottom:'15px'}}>{getPPNameReplaced(description)}</div>
              }
              {
                descriptionList && steps.length ===0 && descriptionList.length > 0 && descriptionList.map((des, i) =>
                  <div key={i} style={{paddingBottom:'15px'}} >{"- " + des}</div>
                )
              }
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={899}>
            <div className='question_list'>
              <MediaQuery maxWidth={480}>
                <div className='back_arrow'><i onClick={() =>{navigate('/faq-home');                 
                  props.setFaqQuestionData({})}} 
                  className="fa fa-chevron-left" aria-hidden="true"></i>
                  <span>{name}</span>
                </div>
              </MediaQuery>
              <MediaQuery minWidth={481} maxWidth={899}>
              <div className='back_arrow'><i onClick={() =>{navigate('/faq-home');                 
                  props.setFaqQuestionData({})}} 
                  className="fa fa-long-arrow-left" aria-hidden="true"></i>
                  <span>{name}</span>
                </div>
              </MediaQuery>


              <div className='sub_header'>
                <span className='heading' 
                  onClick={() =>{navigate('/faq-home');                 
                  props.setFaqQuestionData({})}}
                >{`FAQ's`}</span>
                <span className='right_arrow'><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                <span
                    className={showDetailPage ? 'topic_name_not_active':'topic_name'}                    
                    onClick={()=> showDetailPage===true && setShowDetailPage(false)}
                >{name}</span>
                {
                  showDetailPage && 
                  <span>
                    <span className='right_arrow'><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                    <span  className='topic_name' >{question}</span>
                    <div className='botttom_border'></div>
                  </span>
                }
              </div>
              {
                !showDetailPage && faqData.length > 0 && faqData.map((ele,i) =>
                  <div key={i} className='question'
                    onClick={() =>{
                    setQuestion(ele.question); 
                    setSteps(ele.steps);
                    if(ele.description) setDescription(ele.description);
                    if(ele.descriptionList) setDescriptionList(ele.descriptionList);
                    setShowDetailPage(!showDetailPage);
                    }} >
                    {getPPNameReplaced(ele.question)}

                    <MediaQuery minWidth={481} maxWidth={899}>
                          <span><i className="fa fa-angle-right question_rightArrow" aria-hidden="true"></i></span>
                    </MediaQuery>
                  </div>
                )
              }
              {
                showDetailPage && 
                <div className='question_content'>
                  {steps.length > 0 && steps.map((stp, i) =>
                    <div className='question_steps' key={i}>
                      <div className='step_content'>
                        <span>{`Step ${i+1}: `}</span>
                        {getPPNameReplaced(stp.step)}
                      </div>
                      <div className='step_img'>
                        <div className='step_content' style={{borderBottom:'none', paddingBottom:'20px'}}>{`Fig. ${i+1}`}</div>
                          <img src={`${image_base_url}${stp.image}`}  alt="" />
                      </div>
                    </div>
                  )}
                  {description && steps.length ===0 && description !== '' &&
                    <div style={{paddingBottom:'15px'}}>{getPPNameReplaced(description)}</div>
                  }
                  {
                    descriptionList && steps.length ===0 && descriptionList.length > 0 && descriptionList.map((des, i) =>
                      <div key={i} style={{paddingBottom:'15px'}} >{"- " + des}</div>
                    )
                  }
                </div>
              }

          </div>
        </MediaQuery>
      </div>

        </HeaderAndFooterLayout>
      ):(
        <B2CHeaderAndFooterLayout>
      <div className='faq_detail_subContainer'>
        <MediaQuery minWidth={900} >
          <div className='faq_detail'>
            <div className='question_list'>
              <div className='back_arrow'><i onClick={() =>{navigate(this.state.loginType === "Merchant" ? "/faq-home" : "/faq-b2c-home"); props.setFaqQuestionData({})}} className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{name}</span>
              </div>
              {
                faqData.length > 0 && faqData.map((ele,i) =>
                  <div key={i} className={ele.question !== question ? 'question': 'question question_active'}
                  onClick={() =>{
                    setQuestion(ele.question); 
                    setSteps(ele.steps);
                    if(ele.description) setDescription(ele.description);
                    if(ele.descriptionList) setDescriptionList(ele.descriptionList);
                    }} >
                    {"- " + getPPNameReplaced(ele.question)}
                  </div>
                )
              }  
            </div>
            <div className='question_content'>
              <div className='content_title'>{getPPNameReplaced(question)}</div>
              {steps.length > 0 && steps.map((stp, i) =>
                <div className='question_steps' key={i}>
                  <div className='step_content'>
                    <span>{`Step ${i+1}: `}</span>
                    {getPPNameReplaced(stp.step)}
                  </div>
                  <div className='step_img'>
                    <div className='step_content' style={{borderBottom:'none', paddingBottom:'20px'}}>{`Fig. ${i+1}`}</div>
                      <img src={`${image_base_url}${stp.image}`}  alt="" />
                  </div>
                </div>
              )}
              {description && steps.length ===0 && description !== '' &&
                <div style={{paddingBottom:'15px'}}>{getPPNameReplaced(description)}</div>
              }
              {
                descriptionList && steps.length ===0 && descriptionList.length > 0 && descriptionList.map((des, i) =>
                  <div key={i} style={{paddingBottom:'15px'}} >{"- " + des}</div>
                )
              }
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={899}>
            <div className='question_list'>
              <MediaQuery maxWidth={480}>
                <div className='back_arrow'><i onClick={() =>{navigate(this.state.loginType === "Merchant" ? "/faq-home" : "/faq-b2c-home");                 
                  props.setFaqQuestionData({})}} 
                  className="fa fa-chevron-left" aria-hidden="true"></i>
                  <span>{name}</span>
                </div>
              </MediaQuery>
              <MediaQuery minWidth={481} maxWidth={899}>
              <div className='back_arrow'><i onClick={() =>{navigate(this.state.loginType === "Merchant" ? "/faq-home" : "/faq-b2c-home");                 
                  props.setFaqQuestionData({})}} 
                  className="fa fa-long-arrow-left" aria-hidden="true"></i>
                  <span>{name}</span>
                </div>
              </MediaQuery>


              <div className='sub_header'>
                <span className='heading' 
                  onClick={() =>{navigate(this.state.loginType === "Merchant" ? "/faq-home" : "/faq-b2c-home");                 
                  props.setFaqQuestionData({})}}
                >{`FAQ's`}</span>
                <span className='right_arrow'><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                <span
                    className={showDetailPage ? 'topic_name_not_active':'topic_name'}                    
                    onClick={()=> showDetailPage===true && setShowDetailPage(false)}
                >{name}</span>
                {
                  showDetailPage && 
                  <span>
                    <span className='right_arrow'><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                    <span  className='topic_name' >{question}</span>
                    <div className='botttom_border'></div>
                  </span>
                }
              </div>
              {
                !showDetailPage && faqData.length > 0 && faqData.map((ele,i) =>
                  <div key={i} className='question'
                    onClick={() =>{
                    setQuestion(ele.question); 
                    setSteps(ele.steps);
                    if(ele.description) setDescription(ele.description);
                    if(ele.descriptionList) setDescriptionList(ele.descriptionList);
                    setShowDetailPage(!showDetailPage);
                    }} >
                    {getPPNameReplaced(ele.question)}

                    <MediaQuery minWidth={481} maxWidth={899}>
                          <span><i className="fa fa-angle-right question_rightArrow" aria-hidden="true"></i></span>
                    </MediaQuery>
                  </div>
                )
              }
              {
                showDetailPage && 
                <div className='question_content'>
                  {steps.length > 0 && steps.map((stp, i) =>
                    <div className='question_steps' key={i}>
                      <div className='step_content'>
                        <span>{`Step ${i+1}: `}</span>
                        {getPPNameReplaced(stp.step)}
                      </div>
                      <div className='step_img'>
                        <div className='step_content' style={{borderBottom:'none', paddingBottom:'20px'}}>{`Fig. ${i+1}`}</div>
                          <img src={`${image_base_url}${stp.image}`}  alt="" />
                      </div>
                    </div>
                  )}
                  {description && steps.length ===0 && description !== '' &&
                    <div style={{paddingBottom:'15px'}}>{getPPNameReplaced(description)}</div>
                  }
                  {
                    descriptionList && steps.length ===0 && descriptionList.length > 0 && descriptionList.map((des, i) =>
                      <div key={i} style={{paddingBottom:'15px'}} >{"- " + des}</div>
                    )
                  }
                </div>
              }

          </div>
        </MediaQuery>
      </div>

        </B2CHeaderAndFooterLayout>
      )}
      
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    faqQuestionData: state.common.faqQuestionData,
    api_url: state.config.api_url,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFaqQuestionData: (data) => dispatch(actions.setFaqQuestionData(data)),
    setLoading: (value) => dispatch(actions.setLoading(value)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqDetails);