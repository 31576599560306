import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import KalonPayContactForm from '../global/KalonPayContactForm';

const Login = ({ handleLogin, email, setEmail, password, setPassword, setForgetPassword, loading, error }) => {
  return (
    <div className="kalonpay-modal">
      <h2 className='login-heading'>Sign in to you account</h2>
      <p className='kal-error-login' >{error}</p>
      <form onSubmit={(e) => handleLogin(e)} className='login-form'>
        <input type="email" placeholder='Email' required value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" placeholder='password' required value={password} onChange={(e) => setPassword(e.target.value)} />
        <div className='forget-pass-text' onClick={() => setForgetPassword(true)}>Forgot Password ?</div>
        <div><button type='submit' className='kalonpay-btn'>{loading ? <RefreshIcon className='spin' /> : "Sign In"}</button></div>
      </form>
      <div style={{ width: "100%" }} className='kal-desktop-hidden'><KalonPayContactForm /></div>
    </div>
  )
}

export default Login