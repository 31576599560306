import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import { apiService } from "../../../../common/apiCallService";
import { checkValueInSS } from "../../../../common/theme";

export default function PaysuContact() {
	const image_base_url = useSelector((state) => state.config.image_base_url);
	const api_url = useSelector((state) => state.config.api_url);
	const [contactUsForm, setContactUsForm] = useState({})
	const [flag, setFlag] = useState(false);
	const [error, setError] = useState("")
	const [colorMode, setColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	useEffect(() => {
		if (checkValueInSS("colorMode")) {
			setColorMode(sessionStorage.getItem("colorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])

	const handleChange = (e) => {
		setError("")
		setContactUsForm({ ...contactUsForm, [e.target.name]: e.target.value })
		// console.log(contactUsForm)
	}
	const handleSendMessage = () => {
		if (!contactUsForm.email || !contactUsForm.message || !contactUsForm.firstName || !contactUsForm.lastName) {
			return setError("Please enter required fields")
		}
		setFlag(false)
		apiService(
			(window.location.hostname === 'localhost' ? api_url : '') + '/restapi/contactus',
			{ 
				...contactUsForm,
				paymentProviderId:sessionStorage.getItem("paymentProviderId") 
			},
			async (data) => {
				setContactUsForm({})
			},
			async (err) => {
				setContactUsForm({})
				console.log("err while sending mail", err);
				//   setError('Error While sending mail!')
			},
		);
	}


	let headerButtonStyle = {}
	if (colorMode === "dark") {
		if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
			headerButtonStyle.background = headerButtonDarkBgColour;
			headerButtonStyle.borderColor = headerButtonDarkBgColour;
		}
		if (headerDarkTextColour && headerDarkTextColour !== "") {
			headerButtonStyle.color = headerDarkTextColour;
		}
	} else {
		if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonLightBgColour;
			headerButtonStyle.background = headerButtonLightBgColour;
			headerButtonStyle.borderColor = headerButtonLightBgColour;
		}
		if (headerLightTextColour && headerLightTextColour !== "") {
			headerButtonStyle.color = headerLightTextColour;
		}
	}


	return (
		<section className="ps-section">
			<div className="content-container">
				<div className="ps-section-title-line"></div>
				<AnimationOnScroll animateIn="animate__fadeIn">
					<p className="ps-section-title mb">Chat to our friendly team.</p>
				</AnimationOnScroll>
				<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
					<p className="ps-section-text max-width">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
				</AnimationOnScroll>
				<div className="ps-section-grid paysu-contact-grid">
					<div className="paysu-contact-blocks">
						<div className="paysu-contact mb">
							<img width="68" height="68" className="paysu-contact-icon" src={`${image_base_url}/paysu/ps-email-icon.svg`} />
							<div>
								<p className="paysu-contact-title">Email</p>
								<p className="ps-section-grey-text">Our friendly team is here to help.</p>
								<p className="paysu-contact-value">welcome@paysu.net</p>
							</div>
						</div>
						<div className="paysu-contact">
							<img width="68" height="68" className="paysu-contact-icon" src={`${image_base_url}/paysu/ps-office-icon.svg`} />
							<div>
								<p className="paysu-contact-title">Office</p>
								<p className="ps-section-grey-text">Come say hello at our office HQ.</p>
								<p className="paysu-contact-value">Architektų g. 56-101,Vilnius,<br /> Lithuania #306205545</p>
							</div>
						</div>
					</div>
					<div className="paysu-contact-form">
						<div className="ps-field-grid">
							<div className="ps-field-row">
								<div className="ps-field">
									<p className="ps-label">First name</p>
									<input type="text" placeholder="First name" className="ps-input" id="paysuContactSectionId" name="firstName" onChange={(e) => handleChange(e)} value={contactUsForm.firstName || ""} />
								</div>
								<div className="ps-field">
									<p className="ps-label">Last name</p>
									<input type="text" placeholder="Last name" className="ps-input" name="lastName" onChange={(e) => handleChange(e)} value={contactUsForm.lastName || ""} />
								</div>
							</div>
							<div className="ps-field">
								<p className="ps-label">Email</p>
								<input type="email" placeholder="you@company.com" className="ps-input" name="email" onChange={(e) => handleChange(e)} value={contactUsForm.email || ""} />
							</div>
							<div className="ps-field">
								<p className="ps-label">Message</p>
								<textarea type="text" placeholder="Leave us a message..." className="ps-textarea" name="message" onChange={(e) => handleChange(e)} value={contactUsForm.message || ""} />
							</div>
							<div className="ps-agree">
								<label className="ps-agree-label">
									<span className="ps-section-text">You agree to our friendly privacy policy.</span>
									<input type="checkbox" checked={flag} onChange={(e) => setFlag(e.target.checked)} />
									<span className="ps-agree-check"></span>
								</label>
							</div>
						</div>
						{error && <div style={{ color: "red" }}>{error}</div>}
						<button className={`paysu-btn ${flag ? "cursor-pointer" : "cursor-not-allowed"}`} disabled={!flag} onClick={() => handleSendMessage()} style={{ ...headerButtonStyle }}>Send message</button>
					</div>
				</div>
			</div>
		</section>
	)
}