import React from 'react';
import DepositStatus from './DepositStatus';
import Deposit from './Deposit';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

class DepositTracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referenceCode: "N/A",
            startTime: moment(),
            depositStatus: "DepositFlow:AwaitingDeposit",
        }
    }

    componentDidMount() {
        this.setState({ startTime: localStorage.getItem("startTime") })
    }
    render() {

        return (
            <div>
                <style>{`\
                            .deposit_screen_new { padding-top: 90px; }\
                    `}</style>
                <div className="TrackingDeposit_wrap">
                    {[
                        "noDeposit",
                    ].includes(this.state.depositStatus)
                        ?
                        <DepositStatus
                            startTime={this.state.startTime ? moment(this.state.startTime, "YYYY-MM-DDTHH:mm:ss.SSSZ") : moment()}
                            pageStyle="false"
                            trackingPage="true"
                            hideButtons="false"
                            depositStatus={this.state.depositStatus}
                            restart={() => {
                                this.props.navigate(`/cryptoToDeposit`)
                            }}
                            referenceCode={this.state.referenceCode} />
                        : <Deposit
                            startTime={this.state.startTime ? moment(this.state.startTime, "YYYY-MM-DDTHH:mm:ss.SSSZ") : moment()}
                            pageStyle="false"
                            disableTrack="true"
                            hideButtons="true"
                            trackingPage="true"
                            changeStatus={(status) => {
                                this.setState({ depositStatus: status })
                            }}
                            setReferenceCode={(code) => {
                                this.setState({ referenceCode: code })
                            }}
                        />}
                </div>
            </div>
        )
    }
}

export default (props)=> <DepositTracking {...props} navigate={useNavigate()}/>;