import React, { useEffect, useState } from 'react';
import B2CHeaderAndFooter from '../../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import AddAddressTable from './AddAddressTable';
import NoAddress from './NoAddress';
import RemoveAddressModal from './RemoveAddressModal';
import AddAddressModal from './AddAddressModal';
import EditAddressModal from './EditAddressModal';
import { apiService } from '../../../../common/apiCallService'
import { connect } from 'react-redux';
import AlertMessage from '../../../Layouts/B2CSettings/AlertMessage';
import EmailVerfication from '../../B2C/EmailVerfication/index'
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const AddAdress = (props) => {
    let [openAddModal, setOpenAddModal] = useState(false)
    let [openDeleteModal, setOpenDeleteModal] = useState({
        open: false,
        data: null
    })
    let [openEditModalAndData, setOpenEditModalAndData] = useState({
        open: false,
        data: {}
    })

    let [allAddress, setAllAddress] = useState([])
    let [showAlert, setShowAlert] = useState(false)
    let [alertData, setAlertData] = useState('')
    let [openEmailVerifycation, setOpenEmailVerification] = useState(false);
    const [emailForVerification, setEmailForVerification] = useState('');
    let [firstName, setFirstName] = useState('')
    let [userVerified, setUserVerified] = useState(false)
    let [coinsList, setCoinsList] = useState([])
    const [b2cColorMode, setB2CColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);

    const closeAddModal = () => {
        setOpenAddModal(false)
    }
    const closeAlert = () => {
        setShowAlert(false)
        setAlertData('')
    }

    useEffect(() => {
        loadAddress()
        let email = localStorage.getItem("user_name")
        let fname = localStorage.getItem('firstName')
        setEmailForVerification(email)
        setFirstName(fname)
        getCryptoCoins()
        if (checkValueInSS("b2cColorMode")) {
            setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, [])

    const handleCloseEmailVerification = () => {
        setOpenEmailVerification(false);
    };

    useEffect(() => {
        if (openEmailVerifycation) {
            sendEmailCode()
        }
    }, [openEmailVerifycation])


    const getCryptoCoins = () => {
        apiService(
            (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-cryptos',
            {
                user_email: localStorage.getItem("user_name")
            },
            (data) => {
                if (data) {
                    setCoinsList(data.cryptos);
                }
            },
            (err) => {
                console.log(err);
            },
        );
    };
    const loadAddress = () => {
        apiService((window.location.hostname === "localhost" ? props.api_url : "") + '/restapi/get-all-address-books', {
            userId: localStorage.getItem('b2cUserId')
        }, (data) => {
            setAllAddress(data)
        },
            (err) => {
                console.log('the error from API get address is ', err);
            })


    }


    const sendEmailCode = () => {
        apiService((window.location.hostname === "localhost" ? props.api_url : "") + '/restapi/b2c-verify-user-for-address', {
            user_email: emailForVerification,
            first_name: firstName,
            domainNameUrl: localStorage.getItem("domainNameUrl")
        }, (data) => {
        },
            (err) => {
                console.log('the error from API get address is ', err);
            })


    }

    let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

    return (
        <B2CHeaderAndFooter>
            {showAlert ? <AlertMessage data={alertData} handleCloseAuthenticateModel={closeAlert} extraDimensions={{ right: 72, marginTop: '20px' }} /> : null}
            <AddAddressModal
                openAddModal={openAddModal}
                onClose={closeAddModal}
                loadAddress={loadAddress}
                setShowAlert={setShowAlert}
                setAlertData={setAlertData}
                setOpenEmailVerification={setOpenEmailVerification}
                userVerified={userVerified}
                setUserVerified={setUserVerified}
                setOpenAddModal={setOpenAddModal}
                coinsList={coinsList}
            />
            <RemoveAddressModal
                openAddModal={openDeleteModal}
                onClose={setOpenDeleteModal}
                loadAddress={loadAddress}
                setShowAlert={setShowAlert}
                setAlertData={setAlertData}
            />
            <EditAddressModal
                openAddModalData={openEditModalAndData}
                onClose={setOpenEditModalAndData}
                loadAddress={loadAddress}
                setShowAlert={setShowAlert}
                setAlertData={setAlertData}
                coinsList={coinsList}
            />
            <div className="b2c-settings address-container">
                <div>
                    <div className='nothing-to-show-header-container'>
                        <span className='address-book'>Address Book</span>

                        {allAddress.length > 0 ? (
                        <div className='add-new-small set-button-width' 
                        style = {{...headerButtonStyle}}
                        onClick={() => setOpenAddModal(true)} data-e2e="add-new-btn">
                                Add New
                            </div>
                        ) : (
                            <div className='add-new-small' style={{ display: 'none' }}>
                            </div>
                        )
                        }
                    </div>

                    <div style={{ marginTop: '15.5px' }}>
                        {
                            allAddress.length > 0 ? (
                                <AddAddressTable
                                    setOpenDeleteModal={setOpenDeleteModal}
                                    data={allAddress}
                                    openEditModalAndData={openEditModalAndData}
                                    setOpenEditModalAndData={setOpenEditModalAndData}
                                    openDeleteModal={openDeleteModal}
                                />
                            ) : (
                                <NoAddress setOpenAddModal={setOpenAddModal} />
                            )
                        }

                    </div>

                </div>
            </div>
            {openEmailVerifycation && (
                <EmailVerfication
                    user_email={emailForVerification}
                    openLogin={() => {
                        setUserVerified(true)
                    }}
                    open={openEmailVerifycation}
                    onClose={handleCloseEmailVerification}
                    modal="addressBook"
                />
            )}
        </B2CHeaderAndFooter>
    );
}


const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
    }
}



export default connect(mapStateToProps)(AddAdress);
