import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import localDb from "../../../../common/localDb";

export default function PaysuHero(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [paymentProviderId, setPaymentProviderId] = useState('');
  const [domainId, setDomainId] = useState('');
  const theme = useSelector((state) => state.common.theme);
  useEffect(() => {
	if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== "" && sessionStorage.getItem("paymentProviderId") !== "null") {
		setPaymentProviderId(sessionStorage.getItem("paymentProviderId"));
	  }
	  if (sessionStorage.getItem("domainId") && sessionStorage.getItem("domainId") !== "" && sessionStorage.getItem("domainId") !== "null") {
		setDomainId(sessionStorage.getItem("domainId"));
	  }
  }, []);
  return (
		<section className="ps-hero">
			<AnimationOnScroll animateIn="animate__fadeIn">
				<div className="ps-hero-bg"></div>
			</AnimationOnScroll>
			<div className="content-container">
				<div className="ps-hero-content">
					<AnimationOnScroll animateIn="animate__fadeIn">
						<h1 className="ps-hero-title"> Welcome to <img src={`${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/logo${theme === 'light' ? "" : "dark"}.png` : ""}`} /></h1>
					</AnimationOnScroll>
					<AnimationOnScroll delay="300" animateIn="animate__fadeInUp">
						<p className="ps-hero-subtitle">Easing you into a fuss free world of crypto payments alongside your local payment currencies! <br/>We support via one simple integration a whole world of fiat and cryptocurrencies for you to use to grow your business.</p>
					</AnimationOnScroll>
					<AnimationOnScroll delay="700" animateIn="animate__fadeInUp">
						{
							!localDb.getVal("token") && <div className="theme-btn-block center">
							<button className="paysu-btn" onClick={() => {
								props.showModal();
							}}>Get started</button>
						</div>
						}
					</AnimationOnScroll>
				</div>
			</div>
		</section>
	)
}