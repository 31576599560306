import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import B2CHeaderAndFooterLayout from '../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';


const faqHome = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const navigate = useNavigate();
  const [faqQuestion, setFaqQuestion] = useState([]);
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  const [imageSrc, setImageSrc] = useState({
    gettingStart:'',
    buy:'',
    transaction:'',
    deposit:'',
    security:'',
    wallet:'',
  });

  let result=[];
  const popularQuestionData= faqQuestion.map((ele) =>  ele.questionList);
  popularQuestionData.forEach((e) =>{
     e.forEach((ele) => {
       if(ele.popular ===true){
         result.push(ele);
       }
     })  
  });
  
  const setImage =(iconGray, name)=>{
    switch(name){
      case 'Getting Started':{
        if (imageSrc.gettingStart === '') { setImageSrc({ ...imageSrc, gettingStart: iconGray }) };
        return imageSrc.gettingStart;
      }
      case 'Buy / Send / Withdraw':{
        if (imageSrc.buy === '') { setImageSrc({ ...imageSrc, buy: iconGray }) };
      return imageSrc.buy;
      }
      case 'Transaction Status':{
        if (imageSrc.transaction === '') { setImageSrc({ ...imageSrc, transaction: iconGray }) };
      return imageSrc.transaction;
      }
      case 'Deposit':{
        if (imageSrc.deposit === '') { setImageSrc({ ...imageSrc, deposit: iconGray }) };
      return imageSrc.deposit;
      }
      case 'Account / Security':{
        if (imageSrc.security==='') { setImageSrc({...imageSrc,security:iconGray}) };
      return imageSrc.security;
      }
      case 'Wallet':{
        if (imageSrc.wallet === '') { setImageSrc({ ...imageSrc, wallet: iconGray }) };
      return imageSrc.wallet;
      }
    }
  }

  const pushDetails =(name) =>{
    localStorage.setItem("faq_topic", name );
    navigate('/faq-details');
  }

 
  const [loginTypeIsMerchant, setLoginTypeIsMerchant] = useState(false);
  useEffect(() => { 
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
    }   
    if(localStorage.getItem('faqType') === 'Merchant'){
      setLoginTypeIsMerchant(true);
    }
    faq();
  },[])

  const faq = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-all-faqs',
      {},
      (data) => {
        if (data) {
          props.setLoading(false);
          setFaqQuestion(data);
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    )
  }



  const getPPNameReplaced = (text) => {
    if (paymentProviderName && paymentProviderName !== "" && paymentProviderName !== "null") {
      return text.replaceAll('Cryptonpay', paymentProviderName).replaceAll('cryptonpay', paymentProviderName);
    } else {
      return text
    }
  }

  return (
    <div className='faq_container'>
     {loginTypeIsMerchant  ? (
        <HeaderAndFooterLayout>
        <div className='faqs'>
          <div className='faq_heading'>
            <h1>{`Frequently Asked Questions`}</h1>
          </div>

          <div className='faq_content'>
            <div className='faq_content_container'>
              <div className='content_title'>{'Popular Topics'}</div>
              <div className='content_card_container'>
                {faqQuestion.length> 0 && faqQuestion.slice(0,3).map((ele,i) =>
                  <div className='content_card' key={i}  
                    onMouseOver={() =>setImageSrc({...imageSrc,[ele.name]:ele.onhoverIcon})} 
                    onMouseOut={() =>setImageSrc({...imageSrc,[ele.name]:ele.icon})} 
                    onClick={()=> pushDetails(ele.topic)} >
                    <div className='card_img'>
                      <img src={`${image_base_url}${setImage(ele.icon, ele.topic)}`} alt={ele.name} />
                    </div>
                    <span>
                      <div className='card_heading'>{ele.topic}</div>
                      <div className='card_para'>{getPPNameReplaced(ele.description)}</div>
                    </span>
                  </div>
                )}
              </div>

              <div className='content_card_container'>
                {faqQuestion.length >3 && faqQuestion.slice(3,6).map((ele,i) =>
                  <div className='content_card' key={i}  
                    onMouseOver={() =>setImageSrc({...imageSrc,[ele.name]:ele.onhoverIcon})} 
                    onMouseOut={() =>setImageSrc({...imageSrc,[ele.name]:ele.icon})}
                    onClick={()=> pushDetails(ele.topic)}  >
                    <div className='card_img'>
                    <img src={`${image_base_url}${setImage(ele.icon, ele.topic)}`} className={ele.title} />
                    </div>
                    <span>
                      <div className='card_heading'>{ele.topic}</div>
                      <div className='card_para'>{getPPNameReplaced(ele.description)}</div>
                    </span>
                  </div>
                )}
              </div>

              <div className='faq_question_section'>
                <div className='content_title'>{'Popular Topics'}</div>
                  {
                    result.map((ele, index) =>
                    <div className="faq_question cursor-pointer" key={index} onClick={() =>{
                      navigate('/faq-details');
                      localStorage.setItem("faq_topic", ele.parent );
                      props.setFaqQuestionData(ele);
                      }}>
                      <div className='question'>{getPPNameReplaced(ele.question)}</div>
                      <div className='right_arrow'><i className="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                    </div>
                    )
                  }
                </div>
              </div>
          </div>

        </div>
        </HeaderAndFooterLayout>
       ) :
      ( 
        <B2CHeaderAndFooterLayout>
        <div className='faqs' style={{paddingTop:'80px'}}>
          <div className='faq_heading'>
            <h1>{`Frequently Asked Questions`}</h1>
          </div>

          <div className='faq_content'>
            <div className='faq_content_container'>
              <div className='content_title'>{'Popular Topics'}</div>
              <div className='content_card_container'>
                {faqQuestion.length> 0 && faqQuestion.slice(0,3).map((ele,i) =>
                  <div className='content_card' key={i}  
                    onMouseOver={() =>setImageSrc({...imageSrc,[ele.name]:ele.onhoverIcon})} 
                    onMouseOut={() =>setImageSrc({...imageSrc,[ele.name]:ele.icon})} 
                    onClick={()=> pushDetails(ele.topic)} >
                    <div className='card_img'>
                      <img src={`${image_base_url}${setImage(ele.icon, ele.topic)}`} alt={ele.name} />
                    </div>
                    <span>
                      <div className='card_heading'>{ele.topic}</div>
                      <div className='card_para'>{getPPNameReplaced(ele.description)}</div>
                    </span>
                  </div>
                )}
              </div>

              <div className='content_card_container'>
                {faqQuestion.length >3 && faqQuestion.slice(3,6).map((ele,i) =>
                  <div className='content_card' key={i}  
                    onMouseOver={() =>setImageSrc({...imageSrc,[ele.name]:ele.onhoverIcon})} 
                    onMouseOut={() =>setImageSrc({...imageSrc,[ele.name]:ele.icon})}
                    onClick={()=> pushDetails(ele.topic)}  >
                    <div className='card_img'>
                    <img src={`${image_base_url}${setImage(ele.icon, ele.topic)}`} className={ele.title} />
                    </div>
                    <span>
                      <div className='card_heading'>{ele.topic}</div>
                      <div className='card_para'>{getPPNameReplaced(ele.description)}</div>
                    </span>
                  </div>
                )}
              </div>

              <div className='faq_question_section'>
                <div className='content_title'>{'Popular Topics'}</div>
                  {
                    result.map((ele, index) =>
                    <div className="faq_question cursor-pointer" key={index} onClick={() =>{
                      navigate('/faq-details');
                      localStorage.setItem("faq_topic", ele.parent );
                      props.setFaqQuestionData(ele);
                      }}>
                      <div className='question'>{getPPNameReplaced(ele.question)}</div>
                      <div className='right_arrow'><i className="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                    </div>
                    )
                  }
                </div>
              </div>
          </div>

        </div>
        </B2CHeaderAndFooterLayout>       
       )}  
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    faqQuestionData: state.common.faqQuestionData,
    api_url: state.config.api_url,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFaqQuestionData: (data) => dispatch(actions.setFaqQuestionData(data)),
    setLoading: (value) => dispatch(actions.setLoading(value)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(faqHome)
