import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';

import { styled } from '@mui/material/styles';
import { getCryptoIcon } from "../../../../common/getCryptoIcon";
import { constants } from '../../../../common/constants';

const useStyles = styled(Tooltip)({
  arrow: {
    "&:before": {
      backgroundColor: '#497cf9',
    },
  },
  tooltip: {
    fontSize: "1em",
    backgroundColor: '#497cf9',
    borderRadius:'32px',
    padding: '4px 18px',
    marginLeft:'-35px',
    marginBottom: '8px !important'
  },
});

const B2cTransactionTableRow = (props) => {
  const [transactionInformation, setTransactionInformation] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [showCoppied, toggleCoppied] = useState({
    copy1:false,
    copy2:false,
    copy3:false,
  });

  const [TransactionDetailModal,setTransactionDetailModal] = useState(false);
  const [detailsData,setDetailsData] = useState({});
  


  const getTransactionStatus = (status, type) => {
    if(type ==='send' || type ==='buy'){
      switch(status){
        case 'B2CSendFlow:SendCompleted':
        case 'CardBuyFlow:DepositCompleted':  
          return '/homeb2c/b2cwalletsendaprroved.svg';
        case 'B2CSendFlow:ErrorEncountered':
        case 'CardBuyFlow:DepositFailed':
          return '/homeb2c/b2cwalletsendrejected.svg';
        default:
          return '/homeb2c/b2cwalletsendinprogress.svg';        
      }
    }else{
      switch(status){
        case 'B2CWalletMonitor:ReceiveCompleted':
        case 'B2CWalletMonitor:ReceiveManuallyCompleted':
          return '/homeb2c/b2cwalletreceiveaprroved.svg';
        case 'B2CWalletMonitor:ErrorEncountered':
          return '/homeb2c/b2cwalletreceiverejected.svg';
        default:
            return '/homeb2c/b2cwalletreceiveinprogress.svg';      
      }
    }
  };

  const getStatusMsg=(status,type) =>{
    if(type ==='send'){
      switch(status){
        case 'B2CSendFlow:SendCompleted':
          return 'Send Successfully';
        case 'B2CSendFlow:ErrorEncountered':
          return 'Send Rejected';
        default:
          return 'Send in progress';        
      }
    }else if(type ==='buy'){
      switch (status) {
        case 'CardBuyFlow:DepositCompleted': 
          return 'Buy Successfully';
        case 'CardBuyFlow:DepositFailed':
          return 'Buy Rejected';
        default:
          return 'Buy in progress';
      }
    }else{
      switch(status){
        case 'B2CWalletMonitor:ReceiveCompleted':
        case 'B2CWalletMonitor:ReceiveManuallyCompleted':
          return 'Received';
        case 'B2CWalletMonitor:ErrorEncountered':
          return 'Recive Rejected';
        default:
            return 'In Progress';      
      }
    }
  }

  const getCryptoName = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return 'Bitcoin';
      case 'ETH':
        return 'Ethereum';
      case 'USDT':
        return "Tether";
      case constants?.TRC20_USDT_CRYPTO_ID:
      case constants?.TRX_CRYPTO_ID:
        return "Tron"
      case 'DAI':
        return 'DAI';
      default:
        return 'Ethereum';
    }
  };

  const handleCopy = (e) => {
    toggleCoppied({...showCoppied, [e]: true});
    setTimeout(() => {
      toggleCoppied(false);
    }, 2000);
  };
  const classes = useStyles;

  const getShortAddress = (str) => {
    if (str !== undefined) {
      if (str.length > 20) {
        return str.substr(0, 12) + "..." + str.substr(-4, 4);
      }
      return "N/A";
    }
  }

  return (
    <div className="singleRow__data">
      <MediaQuery maxWidth={786}>
      <div
          className="transaction__history ml-3 mr-3"
           onClick={() => {
            setTransactionDetailModal(true);
            setDetailsData(props.data);
          }}
          >
          <div className="history__coin">
           <div className='status__img'>
              <img src={`${image_base_url}${getTransactionStatus(props.data.status.code, props.data.type)}`} alt={props.data.type}  />
           </div>
           <div className='status__Address'>
              <div className='address'>{props.data.fromWallet && props.data.fromWallet.substr(0, 4) + "..." + props.data.fromWallet.substr(-4)}</div>
              <div className="status" data-e2e={props.data.fromWallet}>
                {getStatusMsg(props.data.status.code, props.data.type)}
              </div>
           </div>
           </div>
          <div className="history__coin__value">
              <div
                className={props.data.type === 'receive' ? 'coin__amount recive__coin' : props.data.type === 'reject' ? 'coin__amount reject__coin' : 'coin__amount send__coins'}
                >
                <span style={{ paddingRight: '5px' }}>{props.data.type === 'receive' ? "+" : props.data.type === 'send' ? '-' : null}</span>
                {parseFloat(props.data.amount).toFixed(8)} {props.data.cryptoId}
              </div>
              <span>
                <img src={`${image_base_url}${getCryptoIcon(props.data && props.data.cryptoId)}`} alt="bitcoin" />
              </span>
              <div className="forward__arrow">
                <i className="fa fa-angle-right" aria-hidden="true" data-e2e="arrow"></i>
              </div>
          </div>
      </div>

        {
                  <div>
                    <Dialog open={TransactionDetailModal} className="transaction__Detail__model">
                      <div className="details__model" >
                        <div className="details__close__icon">
                          <ClearIcon
                            onClick={() => {
                              setTransactionDetailModal(false);
                              setDetailsData({});
                            }}
                            className="icon__close"
                          />
                        </div>
                        <div className="details__date">{detailsData.createdDate ? moment(detailsData.createdDate).format("MMMM Do YYYY , HH:mm") : "-"}</div>
                        <div className="details__status">
                          <span style={{ textTransform: 'capitalize' }}>{detailsData.type}</span> from {detailsData.type === "receive" && detailsData.transactionRefLink && detailsData.transactionRefLink !== "" ? "merchant" : "wallet"}
                        </div>
                        <div className='details__address'>{detailsData.fromWallet && detailsData.fromWallet.substr(0, 4) + "..." + detailsData.fromWallet.substr(-4)}</div>
                        <div className={detailsData.type == 'send' ? "details__amount details__amount__send__BG" : "details__amount details__amount__BG"}>
                          {detailsData.type === 'receive' ? "+" : detailsData.type === 'send' ? '-' : null}
                          {detailsData.amount ? parseFloat(detailsData.amount).toFixed(6) : '0'} {detailsData.cryptoId}
                        </div>
                        <div className="detail__Orignal__amount">{detailsData && detailsData.USDAmount ? parseFloat(detailsData.USDAmount).toFixed(2) : '0'} {"  USD"}</div>
                        <div className="transaction__Details__status">
                          Completed transaction
                        </div>
                      </div>
                      <div className="transaction__Details">
                        <div className="from__address">
                          <h1>From</h1>
                          <div className="details__row">
                            <h1>{getShortAddress(detailsData.fromWallet)}</h1>
                            <Tooltip open={showCoppied.copy1} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy(detailsData.fromWallet);
                                  handleCopy('copy1');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="from__address">
                          <h1>To</h1>
                          <div className="details__row">
                            <h1>{getShortAddress(detailsData.toWallet)}</h1>
                            <Tooltip open={showCoppied.copy2} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy(detailsData.toWallet);
                                  handleCopy('copy2');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="from__address">
                          <h1>Txid</h1>
                          <div className="details__row">
                            <h1>{getShortAddress(detailsData.transactionId)}</h1>
                            <Tooltip open={showCoppied.copy3} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy(detailsData.transactionId);
                                  handleCopy('copy3');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="from__address">
                          <h1>Balance after transaction</h1>
                          <div className="details__row">
                            <h1>
                              {detailsData.overallBalance && detailsData.overallBalance.btcAmount ? parseFloat(detailsData.overallBalance.btcAmount).toFixed(6) : '0'} {detailsData.cryptoId}/ <span>{"$ "}{detailsData.overallBalance && detailsData.overallBalance.usdAmount ? parseFloat(detailsData.overallBalance.usdAmount).toFixed(2) : '0'}</span>
                            </h1>
                            <Tooltip open={showCoppied.copy4} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <div
                                className="copy__icon"
                                onClick={() => {
                                  copy((detailsData.overallBalance && detailsData.overallBalance.btcAmount ? detailsData.overallBalance.btcAmount : '0') + ' ' + detailsData.cryptoId + '/' + "$" + (detailsData.overallBalance && detailsData.overallBalance.usdAmount ? detailsData.overallBalance.usdAmount : '0'));
                                  handleCopy('copy4');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        {detailsData.Merchant && detailsData.Merchant.name != null &&
                          <div className="from__address">
                            <h1>{`Merchant Name`}</h1>
                            <div className="details__row">
                              <h1>
                                {detailsData.Merchant.name}
                              </h1>
                            </div>
                          </div>
                        }
                      </div>
                    </Dialog>
                  </div>
                }
      </MediaQuery>

      <MediaQuery minWidth={786}>
           <div
          className={transactionInformation ? 'transactionRow borderBottomNone' : 'transactionRow'}
          onClick={() => (transactionInformation ? setTransactionInformation(false) : setTransactionInformation(true))}
        >
          <div className="transaction_item_name">
            <img className="USTImages" src={`${image_base_url}${getCryptoIcon(props.data.cryptoId)}`} alt="bitcoin" />
            <span>
              {' '}
              {props.data.cryptoId ? props.data.cryptoId : 'N/A'} / <span>{getCryptoName(props.data.cryptoId)}</span>
            </span>
          </div>
          <div className="transaction_item_status">
          <div className="status__div">
            <img src={`${image_base_url}${getTransactionStatus(props.data.status.code, props.data.type)}`} alt={props.data.type} />
            <div>
              <div className="status__type" data-e2e-status="status" data-e2e={getStatusMsg(props.data.status.code, props.data.type)}>{getStatusMsg(props.data.status.code, props.data.type)}</div>
              {props.data.Merchant && props.data.Merchant.name != null && 
                <div className='merchant_name'>
                  {props.data.type === "receive" && props.data.transactionRefLink && props.data.transactionRefLink !== "" ? `(Merchant Withdrawal)` : `(Merchant Deposit)`}
                </div>           
              }
            </div>
          </div>
          </div>
          <div className="transaction_item_status">
            {props.data.fromWallet ? props.data.fromWallet.substr(0, 7) + '...' + props.data.fromWallet.substr(props.data.fromWallet.length - 3) : '-'}
          </div>
          <div className="transaction_item_amount">{props.data.amount ? parseFloat(props.data.amount).toFixed(6) + ' ' + props.data.cryptoId : '-'}</div>
          <div className="transaction_item__amountInUsd">{"$ "}{props.data.USDAmount ? parseFloat(props.data.USDAmount).toFixed(2) :'0' }</div>
          <div className="transaction_item_date">{props.data.createdDate ? moment(props.data.createdDate).format("DD.MM.YY / HH:mm") : "-"}</div>
          <div className="transaction_item_next">
            <i className={`fa fa-angle-${transactionInformation ? 'up' : 'right'} next__arrow`} aria-hidden="true"></i>
          </div>
        </div>
      </MediaQuery>


      {transactionInformation && (
        <div className="singleTR__row">
          <div className="title">
            {props.data.cryptoId} / {getCryptoName(props.data.cryptoId)} {props.data.type}
            <span>
              {` - `}
              {props.data.amount ? parseFloat(props.data.amount).toFixed(6) :"0"} {props.data.cryptoId ? props.data.cryptoId : "N/A"} ( {"$ "}{ props.data.USDAmount ? parseFloat(props.data.USDAmount).toFixed(2) : '0'} )
            </span>

            
            <span className="title" style={{ paddingLeft: '15px', fontWeight: 'bold' }}>
              <span >{`| `}</span>
              {`Actual Amount`} {props.data.type}
              <span>
                {` - `}
                {props.data.actualAmount ? parseFloat(props.data.actualAmount.crypto).toFixed(6) : '0'} {props.data.cryptoId ? props.data.cryptoId : "N/A"}  ( {"$ "}{props.data.actualAmount ? parseFloat(props.data.actualAmount.usd).toFixed(2) : '0'} )
              </span>
            </span>
            <span className="title" style={{ paddingLeft: '15px', fontWeight: 'bold' }}>
              <span >{`| `}</span>
              {`Gas Fee`}
              <span>
                {` - `}
                {props.data.gasFee || "N/A"}
              </span>
            </span>
          </div>
          <div className="TR__address">
            <div className="from__address">
              <div className="address__title">From</div>
              <div className="address__data">
                {props.data && props.data.fromWallet ? props.data.fromWallet.substr(0,15) + "..."+props.data.fromWallet.substr(-4) :'N/A'}
                <span>
                  <Tooltip open={showCoppied.copy1} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip}} title={`copied`} placement="top" >
                    <span
                      onClick={() => {
                          copy(props.data && props.data.fromWallet ? props.data.fromWallet : 'N/A');
                          handleCopy('copy1');
                      }}
                      >
                      <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                    </span>
                  </Tooltip> 
                </span>
              </div>
            </div>
            <div className="from__address">
              <div className="address__title">To</div>
              <div className="address__data">
                {props.data && props.data.toWallet ? props.data.toWallet.substr(0,15) + "..."+props.data.toWallet.substr(-4) : 'N/A'}
                <span>
                  <Tooltip open={showCoppied.copy2} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip}} title={`copied`} placement="top" >
                    <span
                      onClick={() => {
                        copy(props.data && props.data.toWallet ? props.data.toWallet : 'N/A');
                          handleCopy('copy2');
                      }}
                      >
                      <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                    </span>
                  </Tooltip> 
                </span>
              </div>
            </div>
            <div className="from__address">
              <div className="address__title">Txid</div>
              <div className="address__data">
                {props.data && props.data.transactionId ? props.data.transactionId.substr(0,15) + "..."+props.data.transactionId.substr(-4) : 'N/A'}
                <span>
                  <Tooltip open={showCoppied.copy3} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip}} title={`copied`} placement="top" >
                    <span
                      onClick={() => {
                        copy(props.data && props.data.transactionId ? props.data.transactionId : 'N/A');
                          handleCopy('copy3');
                      }}
                      >
                      <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                    </span>
                  </Tooltip> 
                </span>
              </div>
            </div>
          </div>
          

          <div className="d-flex">
            <div className="balanceAfterTR" style={{width:'43%'}}>
              <div className="balanceAfterTR__heading">Balance after transaction</div>
              <div className="balanceAfterTR__data">
                {props.data.overallBalance && props.data.overallBalance.btcAmount ? parseFloat(props.data.overallBalance.btcAmount).toFixed(6) :'0'}{` `}
                {`BTC`}/ <span>{` $ `}
                {props.data.overallBalance && props.data.overallBalance.usdAmount? parseFloat(props.data.overallBalance.usdAmount).toFixed(2) :'0'}</span>
              </div>
            </div>
            
            {props.data.Merchant && props.data.Merchant.name != null && 
              <div className="balanceAfterTR">
              <div className="balanceAfterTR__heading">{`Merchant Name`}</div>
                <div className="balanceAfterTR__data">
                  {props.data.Merchant.name }
                </div>
              </div>                         
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default B2cTransactionTableRow;
