import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import queryString from 'query-string';
import { apiService } from '../../../common/apiCallService';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const TruevoCallback = (props) => {
    const paymentApiUrl = useSelector((state) => state.config.payment_api_url);
    const navigate = useNavigate()

    useEffect(() => {
        let transactionId = queryString.parse(props.location.search).tnxid
        if (transactionId) {
            getTruevoStatus(transactionId)
        }
    }, [])

    const getTruevoStatus = async (transactionUniqueIdentifier) => {
        try {
            await apiService((window.location.hostname === "localhost" ? paymentApiUrl : "") + '/pga/getTruevoStatus', {
                transactionUniqueIdentifier,
            }, async (data) => {
                if (data?.redirectUrl) {
                    // navigate(data.redirectUrl)
                    if (window.top !== window.self) {
                        // Check if we can safely access window.top and it's not the same as the current window
                           window.top.location.href = `/redirectToUrl?redirectUrlParam=${data?.redirectUrl}`;
                    } else {
                        // Fallback to navigate or other redirection if window.top isn't available
                        window.location.href = data?.redirectUrl
                    }
                }else{
                    await getTruevoStatus(transactionUniqueIdentifier);
                }
            }, (err) => {
                console.error(err);
            })
        } catch (error) {
            console.error('error getTruevoStatus ', error);
        }
    }

    return (
        <div >
            <div className='loading_pay_main row' >
                <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
                <div className='loading_pay_main_title'>
                    Stay on this page! don't go back or exit.
                    <p>Checking Transaction Status...</p>
                </div>
            </div>
        </div>
    );
}

export default (props) => <TruevoCallback {...props} location={useLocation()} />;