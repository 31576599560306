import React, { useEffect } from 'react'
import B2CHeader from '../../../Layouts/B2CHeaderAndFooter/B2CHeader'
import Header from '../../../Layouts/HeaderAndFooter/Header'
import B2CFooter from '../../../Layouts/B2CHeaderAndFooter/B2CFooter'

const CookiesPolicy = () => {
    let domain = 'CryptonPay.com'
    let IsB2c = true
    if (typeof window !== 'undefined') {
        domain = localStorage.getItem('domainNameUrl') && localStorage.getItem('domainNameUrl') !== null ? localStorage.getItem('domainNameUrl') : 'CryptonPay.com'
        domain = removeHttpsFromUrl(domain)
        if(domain === null){
            domain = 'CryptonPay.com'
        }
        IsB2c = localStorage.getItem('loginType') && localStorage.getItem('loginType') === "Merchant" ? false : true
    }
    return (
        <React.Fragment>
            {IsB2c ? <B2CHeader /> : <Header />}
            <div className="cookiesPolicy">
                <div className="header__layout"></div>
                <section className="section legal">
                    <div style={{ lineHeight: "150%" }} className="content-container">
                        <p className="section-title mb">Cookies Policy</p>
                        {/* Section Start form here*/}
                        <p>
                            <br />
                            &nbsp;
                        </p>
                        <p>
                            This Notice is issued by {domain} (“<strong>{domain}</strong>”,
                            “<strong>we</strong>”, “<strong>us</strong>” and “<strong>our</strong>”).
                        </p>
                        <p>Websites to which this Notice applies</p>
                        <p>
                            This Notice applies to the {domain} website available at
                            [{domain}] (the “<strong>Site</strong>”):
                        </p>
                        <p style={{ fontWeight: "bold" }} className=' my-2'>What are cookies?</p>
                        <p>
                            Cookies are small text files that may be stored on your computer or other
                            device when you visit a website. They are generally used to make websites
                            work, to keep track of your movements within the website, to remember your
                            login details, and so on.
                        </p>
                        <p>
                            There are different types of cookies, and they can be distinguished on the
                            basis of their origin, function and lifespan. Important characteristics of
                            cookies include the following:
                        </p>
                        <div className='ml-4 my-4'>
                            <ul>
                                <li>
                                    <p>
                                        <strong>First party cookies</strong> are cookies that are placed by the
                                        website you are visiting, while&nbsp;
                                        <strong>third party cookies&nbsp;</strong>are placed by a website other
                                        than the one you are visiting. Please note that we do not control the
                                        collection or further use of data by third parties.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Necessary cookies</strong> are necessary to allow the technical
                                        operation of a website (e.g., they enable you to move around on a
                                        website and to use its features).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Performance cookies&nbsp;</strong>collect data on the
                                        performance of a website such as the number of visitors, the time spent
                                        on the website and error messages.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Functionality cookies&nbsp;</strong>increase the usability of a
                                        website by remembering your choices (e.g. language, region, login, and
                                        so on).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Targeting/advertising cookies&nbsp;</strong>enable a website to
                                        send you personalised advertising.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Session cookies&nbsp;</strong>are temporary cookies that are
                                        erased once you close your browser while&nbsp;
                                        <strong>persistent or permanent cookies</strong> stay on your device
                                        until you manually delete them or until your browser deletes them based
                                        on the duration period specified in the persistent cookie file.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <p>
                            More information on all aspects of cookies can be found on&nbsp;
                            <a href="http://www.allaboutcookies.org/">
                                <u>www.allaboutcookies.org</u>
                            </a>
                            .&nbsp;Please note that {domain} has no affiliation with, and is not
                            responsible for, this third party website.
                        </p>
                        <p style={{ fontWeight: "bold" }} className='my-2'>Why do we use cookies?</p>
                        <p>We may use cookies to:</p>
                        <div className='ml-4 my-4'>
                            <ul>
                                <li>
                                    <p>distinguish between visitors.</p>
                                </li>
                                <li>
                                    <p>improve the use and the functionality of our Site.</p>
                                </li>
                                <li>
                                    <p>to provide services to you.</p>
                                </li>
                                <li>
                                    <p>to give effect to your orders and requests</p>
                                </li>
                                <li>
                                    <p>tailor our Site and products to your needs and preferences; and</p>
                                </li>
                                <li>
                                    <p>
                                        analyse how our Site is used and compile anonymous and aggregate
                                        statistics.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <p>
                            <br />
                        </p>
                        <table width="100%" className='border' cellPadding={10} cellSpacing={0}>
                            <tbody>
                                {/* Col */}
                                <tr className='border-b'>
                                    <td width="15%"  >
                                        <p>
                                            <strong>Name</strong>
                                        </p>
                                    </td>
                                    <td width="20%" >
                                        <p>
                                            <strong>First Party or Third Party?</strong>
                                        </p>
                                    </td>
                                    <td width="15%" >
                                        <p>
                                            <strong>Duration</strong>
                                        </p>
                                    </td>
                                    <td width="20%" >
                                        <p>
                                            <strong>Purpose</strong>
                                        </p>
                                    </td>
                                    <td width="30%" >
                                        <p>
                                            <strong>Types of data collected</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td width="15%"  >
                                        <p>_dc_gtm_</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Third party (Google Tag Manager)</p>
                                    </td>
                                    <td width="15%" >
                                        <p>10 minutes</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (site analytics)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>Visitor ID data used to distinguish visitors.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>_ga</p>
                                    </td>
                                    <td width="20%">
                                        <p>Third party (Google Analytics)</p>
                                    </td>
                                    <td width="15%">
                                        <p>2 years</p>
                                    </td>
                                    <td width="20%">
                                        <p>Functionality (site analytics)</p>
                                    </td>
                                    <td width="30%">
                                        <p>Visitor ID data used to distinguish visitors.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%" >
                                        <p>_gat</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Third party (Google Analytics)</p>
                                    </td>
                                    <td width="15%" >
                                        <p>1 minute</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (limiting request rate)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>
                                            Visitor ID data used to limit request rate, to minimise bandwidth
                                            impact.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>_gid</p>
                                    </td>
                                    <td width="20%">
                                        <p>Third party (Google Analytics)</p>
                                    </td>
                                    <td width="15%">
                                        <p>24 hours</p>
                                    </td>
                                    <td width="20%">
                                        <p>Functionality (site analytics)</p>
                                    </td>
                                    <td width="30%">
                                        <p>Visitor ID data used to distinguish visitors.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%" >
                                        <p>ai_user</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Third party (Microsoft)</p>
                                    </td>
                                    <td width="15%" >
                                        <p>1 year</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (Performance)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>Visitor ID data used to distinguish visitors.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>catAccCookies</p>
                                    </td>
                                    <td width="20%">
                                        <p>First Party</p>
                                    </td>
                                    <td width="15%">
                                        <p>30 days</p>
                                    </td>
                                    <td width="20%">
                                        <p>Functionality (cookie consent)</p>
                                    </td>
                                    <td width="30%">
                                        <p>
                                            Visitor ID data used to record acceptance that the site uses
                                            cookies.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%" >
                                        <p>exp_csrf_token</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Third party (CMS)</p>
                                    </td>
                                    <td width="15%" >
                                        <p>I hour</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (Cross Site Request Forgery)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>
                                            Visitor ID data used to confirm the submission from the site is
                                            genuine and protect against hacking.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>exp_last_activity</p>
                                    </td>
                                    <td width="20%">
                                        <p>Third party (CMS)</p>
                                    </td>
                                    <td width="15%">
                                        <p>1 year</p>
                                    </td>
                                    <td width="20%">
                                        <p>Functionality (site activity)</p>
                                    </td>
                                    <td width="30%">
                                        <p>
                                            Visitor ID data used to identify the date of the user’s last
                                            activity on the site.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%" >
                                        <p>exp_tracker</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Third party (CMS)</p>
                                    </td>
                                    <td width="15%" >
                                        <p>Session</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (site activity)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>Visitor ID data used to track pages visited on the site.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>has_js</p>
                                    </td>
                                    <td width="20%">
                                        <p>Third party (Google Analytics)</p>
                                    </td>
                                    <td width="15%">
                                        <p>Session</p>
                                    </td>
                                    <td width="20%">
                                        <p>Functionality (site analytics)</p>
                                    </td>
                                    <td width="30%">
                                        <p>Visitor ID data used to distinguish visitors.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%" >
                                        <p>id</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Third party (DoubleClick)</p>
                                    </td>
                                    <td width="15%" >
                                        <p>2 years</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (advertising)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>Visitor ID data used in connection with advertising.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>Typekit by Adobe</p>
                                    </td>
                                    <td width="20%">
                                        <p>Third party (Adobe)</p>
                                    </td>
                                    <td width="15%">
                                        <p>2 years</p>
                                    </td>
                                    <td width="20%">
                                        <p>Widget, Font system</p>
                                    </td>
                                    <td width="30%">
                                        <p>Enables display of fonts</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%" >
                                        <p>cookie_consent</p>
                                    </td>
                                    <td width="20%" >
                                        <p>First party</p>
                                    </td>
                                    <td width="15%" >
                                        <p>1 week</p>
                                    </td>
                                    <td width="20%" >
                                        <p>Functionality (cookies)</p>
                                    </td>
                                    <td width="30%" >
                                        <p>
                                            Recording the user’s consent to the dropping and reading of cookies.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="15%">
                                        <p>cookie_consent_time</p>
                                    </td>
                                    <td width="20%">
                                        <p>First party</p>
                                    </td>
                                    <td width="15%">
                                        <p>1 week</p>
                                    </td>
                                    <td width="20%">
                                        <p>Functionality (cookies)</p>
                                    </td>
                                    <td width="30%">
                                        <p>
                                            Recording the user’s consent to the dropping and reading of cookies.
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            <br />
                            &nbsp;
                        </p>
                        <p>We do not use the collected information to create visitor profiles.</p>
                        <p className='my-2 bold'>What types of cookies do we use?</p>
                        <p className='my-2'>
                            An overview of the cookies used on our Site is set out in the following
                            table.
                        </p>
                        <p className='my-2'>
                            Please note that the processing of your personal data in connection with
                            cookies, and for other purposes, is governed by our External Privacy
                            Notice&nbsp;
                            <a href="https://luxon.com/privacy-policy/">
                                <u>{domain}/privacy-policy/</u>
                            </a>
                        </p>
                        <p className='my-2'>
                            In addition, we may also use web beacons (or clear GIFs) and other similar
                            technologies in addition to, or in combination with, cookies. A web beacon
                            is typically a transparent graphic image (usually 1 pixel x 1 pixel) that is
                            placed on a website or in an email and it helps us to understand the
                            behaviour of visitors to our Site [and our App].
                        </p>
                        <p className='my-2'>
                            More information on web beacons can be found at&nbsp;
                            <a href="http://www.allaboutcookies.org/faqs/beacons.html">
                                <u>http://www.allaboutcookies.org/faqs/beacons.html</u>
                            </a>
                            .&nbsp;Please note that {domain} has no affiliation with, and is not
                            responsible for, this third-party website.
                        </p>
                        <p className='my-2'>
                            The Site and the App use Google Analytics, a web analytics service provided
                            by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text
                            files placed on your device, to help our Site [and our App] analyse how
                            users use the site.&nbsp;
                        </p>
                        <p className='my-2'>
                            The information generated by the cookie about your use of our Site [and our
                            App] (including your IP address) will be transmitted to and stored by Google
                            on servers in the United States.
                        </p>
                        <p className='my-2'>
                            Google will use this information for the purpose of evaluating your use of
                            our Site [and our App], compiling reports on usage activity for service
                            operators and providing other services relating to Site [and App] activity
                            and internet usage. Google may also transfer this information to third
                            parties where required to do so by law, or where such third parties process
                            the information on Google’s behalf. Google will not associate your IP
                            address with any other data held by Google. You may refuse the use of
                            cookies by selecting the appropriate settings on your browser, however
                            please note that if you do this you may not be able to use the full
                            functionality of our Site [and our App]. By using our Site [and our App],
                            you consent to the processing of data about you by Google in the manner and
                            for the purposes set out above.
                        </p>
                        <p>
                            For more information, please visit&nbsp;
                            <a href="http://www.google.com/analytics/">
                                <u>http://www.google.com/analytics/</u>
                            </a>
                            {`]`}
                        </p>
                        <p className='bold my-2'>How can you control cookies and web beacons?</p>
                        <p className='my-2'>
                            Most internet browsers are set to automatically accept cookies. Depending on
                            your browser, you can set your browser to warn you before accepting cookies,
                            or you can set it to refuse them. Please refer to the ‘help’ button (or
                            similar) on your browser to learn more about how you can do this.
                        </p>
                        <p className='my-2'>Disabling cookies may impact your experience on our Site.</p>
                        <p className='my-2'>
                            If you use different devices to access our Site, you will need to ensure
                            that each browser of each device is set to your cookie preference.
                        </p>
                        <p className='my-2'>
                            More information on how to manage cookies is available from&nbsp;
                            <a href="http://www.allaboutcookies.org/manage-cookies/">
                                <u>http://www.allaboutcookies.org/manage-cookies/</u>
                            </a>
                            . Please note that {domain} has no affiliation with, and is not
                            responsible for, this third-party website.
                        </p>
                        <p className='my-2'>
                            In addition, you may opt-out from cookies by visiting the following sites
                            and selecting which company cookies you would like to opt-out from:
                            <a href="http://www.aboutads.info/choices/#completed">
                                <u>http://www.aboutads.info/choices/#completed</u>
                            </a>{" "}
                            and&nbsp;
                            <a href="http://www.youronlinechoices.com/">
                                <u>http://www.youronlinechoices.com/</u>
                            </a>
                            . Please note that {domain} has no affiliation with, and is not
                            responsible for, these third-party websites.
                        </p>
                    </div>
                </section>
            </div>
            <B2CFooter />
        </React.Fragment>
    )
}

export default CookiesPolicy


function removeHttpsFromUrl(url) {
    if (url.startsWith("https://")) {
        url = url.slice("https://".length);
    } else if (url.startsWith("http://")) {
        url = url.slice("http://".length);
    }

    if (url.startsWith("www.")) {
        url = url.slice("www.".length);
    }
    if (url.endsWith("/")) {
        url = url.slice(0, -1);
    }
    return url;
}
