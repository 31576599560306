import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

function AlertMessage(props) {
  return (
    <div>
      <div className="b2c-settings-header pl-5" >
        <span className="authenticate-alert-model" style={props.extraDimensions ? props.extraDimensions : {}}>
          <div className="wallet_status_alert_model_icon">
            <DoneIcon style={{ color: 'white' }} />
          </div>
          <div data-e2e={props.data.toLowerCase().replaceAll(' ', '-')}>{props.data}</div>
          <div>
            {' '}
            <ClearIcon className="authenticator-close-icon" onClick={() => props.handleCloseAuthenticateModel()} />
          </div>
        </span>
      </div>
    </div>
  );
}

export default AlertMessage;
