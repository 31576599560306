import browser from 'browser-detect';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveActivity } from "../../../common/activityTrackingService";
import { apiService } from '../../../common/apiCallService';
import { clearStorageData, getDeviceIP } from "../../../common/getIP";
import localDb from '../../../common/localDb';
import { checkValueInSS, setB2CTheme } from '../../../common/theme';
import * as actions from '../../../Redux/actions';
import { setAndGetB2CTheme } from '../../../Redux/actions/commonAction';
import EmailVerfication from '../../Pages/B2C/EmailVerfication';
import VerifyModel from '../../Pages/B2C/KYC/VerifyModel';
import SendCryptoModel from '../../Pages/B2C/UserWalletsHome/b2cSendCryptoModel';
import Dialog from '@mui/material/Dialog';
import useOutsideClick from '../../../../helpers/outsideClick';
import B2CLogin from '../../Pages/B2CLogin/B2CLogin';
import B2CSignUp from '../../Pages/B2CSignUp/B2CSignUp';
import { constants } from '../../../common/constants';
import snsWebSdk from '@sumsub/websdk';
import { saveToSessionStorage } from '../../../common/LoadingAndRedirection';
import { removeHttp } from '../../../common/getIP';


function B2CHeader({ setToken, token, set2faActivationStatus, openKYCVerificationModal, setOpenKYCVerificationModal }) {
  const browserInfo = browser();
  const dispatch = useDispatch();
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [b2cUserName, setB2CUserName] = useState('');
  const [initials, setInitials] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerDarkBgColour, setheaderDarkBgColour] = useState(null);
  const [headerLightBgColour, setheaderLightBgColour] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerDarkLinkColour, setheaderDarkLinkColour] = useState(null);
  const [headerLightLinkColour, setheaderLightLinkColour] = useState(null);
  const [themeToggleButton, setthemeToggleButton] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [paymentProviderId, setPaymentProviderId] = useState(null)
  const [paymentProviderIdName, setPaymentProviderIdName] = useState(null)
  const [domainId, setDomainId] = useState(null)
  const [coin, setCoin] = useState('BTC');
  const KYC_ACTIVATE = useSelector((state) => state.config.KYC_ACTIVATE);
  const [logoWidth, setlogoWidth] = useState(150);
  const COIN_SPIRIT_HOST_NAME = useSelector((state)=>state.config.COIN_SPIRIT_HOST_NAME);
  const COIN_SPIRIT_BASE_PATH = useSelector((state)=>state.config.COIN_SPIRIT_BASE_PATH);
  const COIN_SWAP_CENTER_HOST_NAME  = useSelector((state)=>state.config.COIN_SWAP_CENTER_HOST_NAME );
  const COIN_SWAP_CENTER_BASE_PATH = useSelector((state)=>state.config.COIN_SWAP_CENTER_BASE_PATH)
  const COINS_TRADE_HUB_HOST_NAME = useSelector((state)=>state.config.COINS_TRADE_HUB_HOST_NAME)
  const COINS_TRADE_HUB_BASE_PATH = useSelector((state)=>state.config.COINS_TRADE_HUB_BASE_PATH)
  const apiUrl = useSelector((state) => state.config.api_url);




  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const navigate = useNavigate();

  const logo = b2cTheme === 'light' ? '/homeb2c/cryptoLightLogo.png' : '/common/cryptonpay_logo.svg';
  // const themeLogo = b2cTheme === 'light' ? <DarkModeTwoToneIcon style={{ color: 'white' }} /> : <LightModeTwoToneIcon style={{ color: 'white' }} />;

  // const walletSelected = b2cTheme === 'light' ? '/homeb2c/wallet_Selected_Light.svg' : '/homeb2c/wallet_Selected_Dark.svg';
  // const walletNotSelected = b2cTheme === 'light' ? '/homeb2c/wallet_Light.svg' : '/homeb2c/wallet_Dark.svg';

  const [openSignUpModel, setOpenSignUpModel] = useState(false);
  const search = useLocation().search;
  const signUp = new URLSearchParams(search).get('signUp');
  const location = new useLocation();
  const [verfiyModel, setVerifyModel] = useState(false);
  const [host, setHost] = useState("");
  const [modelType, setModelType] = useState('');
  const [sendModel, setSendModel] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showBuyButton, setShowBuyButton] = useState(true);
  const api_url = useSelector((state) => state.config.api_url);

  useEffect(() => {
    setHost(window.location.hostname);
    }, []);

  function checkCoinsSwapCenter() {
    return host.includes(COIN_SWAP_CENTER_HOST_NAME );
  }
  function checkCoinsTradeHub() {
    return host.includes(COINS_TRADE_HUB_HOST_NAME );
  }

  const handleCloseSendModel = () => {
    setSendModel(false);
  };

	const [isProfileMenuActive, setProfileMenuState] = useState(false);
	function toggleProfileMenu() {
		setProfileMenuState(prev => !prev);
	}
	const refProfileBlock = useRef();
  useOutsideClick(refProfileBlock, () => {
    setProfileMenuState(false);
  });

	const [isMobileMenuActive, setMobileMenuState] = useState(false);
	function toggleMobileMenu() {
		setMobileMenuState(prev => !prev);
	}

	function historyPushHandler(link) {
		setMobileMenuState(false);
		navigate(link); 
	}

  const handleClickOpenSignUpModel = async () => {
    setOpenSignUpModel(true);
    navigate(constants.B2C_SIGNUP_PATH)
    
    let deviceIp = await getDeviceIP()
    saveActivity({
      EventPage: "Home-B2C",
      EventType: "ClickSignup",
      attribute1: localDb.getVal("b2cUserId") === undefined ? "" : localDb.getVal("b2cUserId"),
      attribute2: localDb.getVal("user_name") === undefined ? "" : localDb.getVal("user_name"),
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    })
  };

  const handleCloseSignUpModel = () => {
    if (signUp === "open") {
      navigate(location.pathname)
    }
    setOpenSignUpModel(false);
  };

  const [openEmailVerifycation, setOpenEmailVerification] = useState(false);
  const [emailForVerifycation, setEmailForVerification] = useState(null);


  const handleEmailValidation = (email) => {
    setLoginModel(false)
    setOpenEmailVerification(true)
    setEmailForVerification(email)
  }

  const handleOpenEmailVerification = () => {
    setOpenEmailVerification(true);
  };

  const handleLoginModelOpen = () => {
    handleOpenLoginModel();
    handleCloseSignUpModel();
  };

  const signUpModelOpenInLogin = () => {
    handleClickOpenSignUpModel();
    handleCloseLoginModel();
  };

  const handleCloseEmailVerification = () => {
    setOpenEmailVerification(false);
  };

  const handleEmailVerification = (user_email) => {
    setEmailForVerification(user_email);
    handleOpenEmailVerification();
    handleCloseSignUpModel();
  };

  const getSingleFeatureConfig = (featureName) => {
    return new Promise(async (resolve, reject) => {
      await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/get-single-feature-config', {
        featureName,
      }, async (data) => {
        if (data) {
          resolve(data)
        }
      }, (err) => {
        console.log("errrr", err)
        resolve(false)
      })
    })
  }
  const checkImageUrl = (imageUrl, onSuccess, onError)=> {
    const img = new Image();
    img.onload = function() {
      // Image loaded successfully
      onSuccess();
    };
    img.onerror = function() {
      // Error loading image
      onError();
    };
    img.src = imageUrl;
  }
  useEffect(() => {
    if (!sessionStorage.getItem("domainNameUrl")) {
			getPaymentProviderForDomain()
		}
  }, []);
  useEffect(() => {
    let paymentProviderName = sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null"
    let provideID;
    let domainID;
    if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== "" && sessionStorage.getItem("paymentProviderId") !== "null") {
      setPaymentProviderId(sessionStorage.getItem("paymentProviderId"));
      provideID = sessionStorage.getItem("paymentProviderId")
    }
    if (sessionStorage.getItem("b2cColorMode") && sessionStorage.getItem("b2cColorMode") !== "" && sessionStorage.getItem("b2cColorMode") !== "null") {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
      const themeName = sessionStorage.getItem("b2cColorMode");
      document.documentElement.className = themeName;
    }
    if (sessionStorage.getItem("headerDarkBgColour") && sessionStorage.getItem("headerDarkBgColour") !== "" && sessionStorage.getItem("headerDarkBgColour") !== "null") {
      setheaderDarkBgColour(sessionStorage.getItem("headerDarkBgColour"));
    }
    if (sessionStorage.getItem("headerLightBgColour") && sessionStorage.getItem("headerLightBgColour") !== "" && sessionStorage.getItem("headerLightBgColour") !== "null") {
      setheaderLightBgColour(sessionStorage.getItem("headerLightBgColour"));
    }
    if (sessionStorage.getItem("headerButtonDarkBgColour") && sessionStorage.getItem("headerButtonDarkBgColour") !== "" && sessionStorage.getItem("headerButtonDarkBgColour") !== "null") {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (sessionStorage.getItem("headerButtonLightBgColour") && sessionStorage.getItem("headerButtonLightBgColour") !== "" && sessionStorage.getItem("headerButtonLightBgColour") !== "null") {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (sessionStorage.getItem("headerDarkTextColour") && sessionStorage.getItem("headerDarkTextColour") !== "" && sessionStorage.getItem("headerDarkTextColour") !== "null") {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (sessionStorage.getItem("headerLightTextColour") && sessionStorage.getItem("headerLightTextColour") !== "" && sessionStorage.getItem("headerLightTextColour") !== "null") {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
    if (sessionStorage.getItem("themeToggleButton") && sessionStorage.getItem("themeToggleButton") !== "" && sessionStorage.getItem("themeToggleButton") !== "null") {
      setthemeToggleButton(sessionStorage.getItem("themeToggleButton"));
    }
    if (sessionStorage.getItem("headerDarkLinkColour") && sessionStorage.getItem("headerDarkLinkColour") !== "" && sessionStorage.getItem("headerDarkLinkColour") !== "null") {
      setheaderDarkLinkColour(sessionStorage.getItem("headerDarkLinkColour"));
    }
    if (sessionStorage.getItem("headerLightLinkColour") && sessionStorage.getItem("headerLightLinkColour") !== "" && sessionStorage.getItem("headerLightLinkColour") !== "null") {
      setheaderLightLinkColour(sessionStorage.getItem("headerLightLinkColour"));
    }
    if (sessionStorage.getItem("logoWidth") && sessionStorage.getItem("logoWidth") !== "" && sessionStorage.getItem("logoWidth") !== "null") {
     setlogoWidth(sessionStorage.getItem("logoWidth"));
    }
    if (paymentProviderName) {
      setPaymentProviderIdName(sessionStorage.getItem("paymentProviderName"));
      document.title = sessionStorage.getItem("paymentProviderName")
    } else {
      if (localStorage.getItem("reloadedOnce") !== "Yes") {
        
        setTimeout(()=>{
          localStorage.setItem("reloadedOnce", "Yes")
          window.location.reload();
        }, 1000)
      }
    }
    if (sessionStorage.getItem("domainId") && sessionStorage.getItem("domainId") !== "" && sessionStorage.getItem("domainId") !== "null") {
      setDomainId(sessionStorage.getItem("domainId"));
      domainID =  sessionStorage.getItem("domainId")
    }
    if (window.location.pathname === '/faq-b2c-home') {
      localStorage.setItem("faqType", "B2C");
    }
    if (window.location.pathname === '/faq-home') {
      localStorage.setItem("faqType", "Merchant");
    }
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    checkImageUrl(
      `${image_base_url}${provideID && provideID !== "" && provideID !== "null" ? `/payment-provider/${provideID}${domainID ? ("/" + domainID) : ""}/favicon.png` : ""}`,
      () => {
        link.href = `${image_base_url}${provideID && provideID !== "" && provideID !== "null" ? `/payment-provider/${provideID}${domainID ? ("/" + domainID) : ""}/favicon.png` : ""}`
      },
      () => {
        link.href = `${image_base_url}${provideID && provideID !== "" && provideID !== "null" ? `/payment-provider/${provideID}${domainID ? ("/" + domainID) : ""}/logo${b2cTheme === 'light' ? "" : "dark"}.png` : ""}`
      }
    );
    setTokenValue();
    redirect();
  }, []);
  const getPaymentProviderForDomain = async () => {
		try {
			await apiService((window?.location?.hostname === "localhost" ? apiUrl : "") + '/restapi/get-payment-provider-for-domain', {
				redirectUrl: removeHttp(window?.location?.hostname)
			}, (data) => {
        // setB2CTheme(["Dark Theme", "Light Theme"].includes(data?.defaultB2CTheme) ? (data?.defaultB2CTheme === "Dark Theme" ? "dark" : "light") : (data?.defaultTheme === "Dark Theme" ? "dark" : "light"));
				saveToSessionStorage(data)
			}, (err) => {
				alert("Domain is not configured to any payment provider!")
				console.error(err);
			})
		} catch (err) {
			console.error(err)
		}
	}

  const setTokenValue = async () => {
    if (localStorage.getItem("token") && !token) {
      setToken(localStorage.getItem("token"));
      setLoaded(true)
    } else {
      setLoaded(true)
    }
    if (
      localStorage.getItem("firstName") &&
      localStorage.getItem("lastName") &&
      localStorage.getItem("firstName") !== '' &&
      localStorage.getItem("lastName") !== '' &&
      (b2cUserName === '' || initials === '')
    ) {
      const firstName = localStorage.getItem("firstName").toString().trim()
      const lastName = localStorage.getItem("lastName").toString().trim()
      setB2CUserName(
        firstName.charAt(0).toUpperCase() +
        firstName.substring(1, firstName.length) +
        ' ' +
        lastName.charAt(0).toUpperCase() +
        lastName.substring(1, lastName.length),
      );
      setInitials(firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase());
    }
    if (localStorage.getItem("user_name") && userEmail === '') {
      setUserEmail(localStorage.getItem("user_name"));
    }
    let res = await getSingleFeatureConfig("Buy Crypto")
    if (res) {
      if (res.enabled === false) {
        setShowBuyButton(false);
      }
    }
  };

  const redirect = () => {
    if (signUp === "open") {
      signUpModelOpenInLogin();
    }
    if (localStorage.getItem("loginType") === "Merchant" && window.location.pathname !== '/faq-home' && window.location.pathname !== '/faq-details') {
      if(location.pathname !== '/cookies-policy'){
        console.log('B2cHeader redirecting to merchant.....');
        navigate('/home-merchant');
      }
    }
    const publicPaths = [
      '/deposit',
      '/cryptoToDeposit',
      '/aboutUs',
      '/merchantTools',
      '/forgotPassword',
      '/loginPopup',
      '/terms-of-use',
      '/home-merchant',
      '/faq-home',
      '/faq-b2c-home',
      '/faq-details',
      '/home-b2c',
      '/coinsspirit-privacy-policy',
      '/coinsswapcenter-privacy-policy',
      '/cookies-policy',
      '/external-privacy-policy-notice',
      constants.B2C_LOGIN_PATH,
      constants.B2C_SIGNUP_PATH
    ]
    if (!localDb.getVal('token') && !publicPaths.some(path => window.location.pathname.includes(path))) {
        console.log('B2CHeader redirecting.....');
        navigate('/');
    }
  };

  const handleTheme = () => {
    if (localDb.getSSVal("b2cColorMode") === 'dark') {
      setB2CTheme('light');
      dispatch(setAndGetB2CTheme('light'));
      window.location.reload()
    } else {
      setB2CTheme('dark');
      dispatch(setAndGetB2CTheme('dark'));
      window.location.reload()
    }
  };
  const handleLogin = async () => {
    handleOpenLoginModel();
    let deviceIp = await getDeviceIP()
    saveActivity({
      EventPage: "Home-B2C",
      EventType: "ClickLogin",
      attribute1: localDb.getVal("b2cUserId") === undefined ? "" : localDb.getVal("b2cUserId"),
      attribute2: localDb.getVal("user_name") === undefined ? "" : localDb.getVal("user_name"),
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    })
  };

  const [loginModel, setLoginModel] = useState(false);

  const handleOpenLoginModel = () => {
    setLoginModel(true);
    navigate(constants.B2C_LOGIN_PATH)
  };

  const handleCloseLoginModel = () => {
    setLoginModel(false);
  };

  const getKycStatusCheckAndContinue = (type) => {
    if (type === "buy") {
      if (localStorage.getItem("token") && localStorage.getItem("token") !== "" && KYC_ACTIVATE==="true") {
        apiService(
          (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getKycStatus',
          { domainNameUrl: localStorage.getItem("domainNameUrl") },
          (data) => {
            // setLoading(false)
            if (data) {
              if (data.reviewAnswer === "GREEN") {
                setModelType(type)
                setSendModel(true);
              } else {
                handleVerify()
              }
            } else {
              handleVerify()
            }
          },
          (err) => {
            setOpenKYCVerificationModal(true)
            getNewAccessToken();
            console.log(err);
          },
        );
      }else {
        setModelType(type)
        setSendModel(true);
      }
    } else {
      setModelType(type)
      setSendModel(true);
    }
  };

  const getNewAccessToken = () => {
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getAccessToken',
      { domainNameUrl: localStorage.getItem("domainNameUrl") },
      (data) => {
        if (data) {
          //SDKADDED
          let snsWebSdkInstance = snsWebSdk
            .init(data.token, () => getNewAccessToken())
            .withConf({
              lang: 'en',
              uiConf: {
                customCss: 'https://url.com/styles.css',
              },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.stepCompleted', (payload) => {
              console.log('stepCompleted', payload);
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error);
            })
            .build();

          snsWebSdkInstance.launch('#sumsub-websdk-container2');
        }
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const handleCloseVerifyModel = () => {
    setVerifyModel(false);
  };

  const handleVerifyProgress = () => {
  };

	const handleSend = (type) => {
    getKycStatusCheckAndContinue(type);
  };

  const handleVerify = () => {
    console.log("handleVerify called")
    setVerifyModel(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getAccessToken',
      { domainNameUrl: localStorage.getItem("domainNameUrl") },
      (data) => {
        if (data) {
          let snsWebSdkInstance = snsWebSdk
            .init(data.token, () => getNewAccessToken())
            .withConf({
              lang: 'en',
              uiConf: {
                customCss: 'https://url.com/styles.css',
              },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.stepCompleted', (payload) => {
              console.log('stepCompleted', payload);
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error);
            })
            .build();

          snsWebSdkInstance.launch('#sumsub-websdk-container');
        }
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const logout = () => {
    const colorMode = sessionStorage.getItem("colorMode");
    const b2cColorMode = sessionStorage.getItem("b2cColorMode");
    const depositMerchantId = localStorage.getItem("depositMerchantId");
    const depositMethod = localStorage.getItem("depositMethod");
    const transactionId = localStorage.getItem("transactionId");
    const lightFlag = sessionStorage.getItem("lightFlag");
    const activity_tracking_token = localStorage.getItem("activity_tracking_token");
    const activity_tracking_api_url = localStorage.getItem("activity_tracking_api_url");
    const api_url = localStorage.getItem("api_url");
    setToken(null);
    clearStorageData();
    localStorage.setItem("reloadedOnce", "Yes");
    sessionStorage.setItem("colorMode", colorMode);
    sessionStorage.setItem("b2cColorMode", b2cColorMode);
    localStorage.setItem("depositMerchantId", depositMerchantId);
    localStorage.setItem("depositMethod", depositMethod);
    localStorage.setItem("transactionId", transactionId);
    sessionStorage.setItem("lightFlag", lightFlag);
    localStorage.setItem("activity_tracking_token", activity_tracking_token);
    localStorage.setItem("activity_tracking_api_url", activity_tracking_api_url);
    localStorage.setItem("api_url", api_url);
    set2faActivationStatus(false);
    if(window && window.location && window.location.hostname && window.location.hostname.includes(COIN_SPIRIT_HOST_NAME)){
      window.location.href = COIN_SPIRIT_BASE_PATH;
    }
    else {
      if (checkCoinsSwapCenter()) {
        window.location.href = COIN_SWAP_CENTER_BASE_PATH;
      }else if(checkCoinsTradeHub()){
        window.location.href = COINS_TRADE_HUB_BASE_PATH;
      } else {
        window.location.href = "/home-b2c";
      }
    }
    // window.location.reload();   
  };

	const profileMenu = [
		{ title: "Settings", dataE2e: "settings", history: "/user-settings-b2c" },
		{ title: "Wallet", dataE2e: "wallet", history: "/user-wallet-home" },
		{ title: "Transactions", dataE2e: "transaction", history: "/user-transaction-history-b2c" },
		{ title: "Address Book", dataE2e: "addressBook", history: "/add-address" }
	];

  let headerStyle = {}
  let headerButtonStyle = {}
  let headerButtonBorderStyle = {}
  let headerLinkStyle = {}
  if (b2cColorMode === "dark") {
    if (headerDarkBgColour && headerDarkBgColour !== "") {
      headerStyle.backgroundColor = headerDarkBgColour;
    }
    if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
      headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
      headerButtonStyle.background = headerButtonDarkBgColour;
      headerButtonStyle.borderColor = headerButtonDarkBgColour;
      headerButtonBorderStyle.borderColor = headerButtonDarkBgColour;
      headerButtonBorderStyle.color = headerButtonDarkBgColour;
    }
    if (headerDarkTextColour && headerDarkTextColour !== "") {
      headerButtonStyle.color = headerDarkTextColour;
    }
    if (headerDarkLinkColour && headerDarkLinkColour !== "") {
      headerLinkStyle.color = headerDarkLinkColour;
    }
  } else {
    if (headerLightBgColour && headerLightBgColour !== "") {
      headerStyle.backgroundColor = headerLightBgColour;
    }
    if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
      headerButtonStyle.backgroundColor = headerButtonLightBgColour;
      headerButtonStyle.background = headerButtonLightBgColour;
      headerButtonStyle.borderColor = headerButtonLightBgColour;
      headerButtonBorderStyle.borderColor = headerButtonLightBgColour;
      headerButtonBorderStyle.color = headerButtonLightBgColour;
    }
    if (headerLightTextColour && headerLightTextColour !== "") {
      headerButtonStyle.color = headerLightTextColour;
    }
    if (headerLightLinkColour && headerLightLinkColour !== "") {
      headerLinkStyle.color = headerLightLinkColour;
    }
  }
	function checkPathForHeaderTheme() {
		const pathname = location.pathname.replace('/', '');
		const urlsToEnableHeaderTheme = ['user-settings-b2c', 'user-wallet-home', 'user-transaction-history-b2c', 'add-address'];
		if (urlsToEnableHeaderTheme.includes(pathname)) {
			return headerStyle;
		} else return null;
	}
	const headerStyleEnabled = checkPathForHeaderTheme();
	function renderThemeLogo() {
		if (headerStyleEnabled) {
			return b2cColorMode === "light" ? "" : "dark"
		}
		else {
			return "dark";
		}
	}
  return (
    <div>
      {openKYCVerificationModal && <Dialog onClose={() => { setOpenKYCVerificationModal(false) }} open={openKYCVerificationModal} className="b2c-signup-model b2c-login-model receive_model_mobile">
        <div style={{ minWidth: "461px", maxHeight: "800px", overflowY: "auto" }}>
          <div id="sumsub-websdk-container2"></div>
        </div>
       </Dialog>}
      <header className="header" style={{...headerStyleEnabled}}>
        <div className="content-container">
					<div className="header__row">
						<div className=" header__logo" onClick={() => checkCoinsSwapCenter() || checkCoinsTradeHub() ? null : navigate('/')}>
							{paymentProviderIdName && 
								<img 
                className=''
                width={logoWidth}
									src={`${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/logo${renderThemeLogo()}.png` : logo}`} alt="logo"
									onError={() => {
										setPaymentProviderId(null);
										setDomainId(null);
										setPaymentProviderIdName(null)
									}}
								/>
							}
						</div>
						<div className="header__right">
							{showBuyButton && token &&
								<button className="theme-btn" style={{...headerButtonStyle}} data-e2e="buyNow" onClick={() => {
									setCoin('BTC'); handleSend('buy');
								}}>
                  <p>Buy crypto</p>
								</button>
							}
							<button className="header__mobileBtn" onClick={toggleMobileMenu}>
								<div style={{...headerButtonStyle}}></div>
								<div style={{...headerButtonStyle}}></div>
							</button>  
              {/* {themeToggleButton === "Disabled" ? null : <div className="moon-theme" onClick={() => handleTheme()} data-e2e="logo">
								switch theme
							</div>} */}
							{!token ? (
								<div className="header__actions">
									{loaded &&
										<div className="header__actions_start">
											<button className="theme-btn" style={{...headerButtonStyle}} onClick={handleLogin} data-e2e="logIn">
                        <p>Log In</p>
											</button>
                      <button className="theme-btn theme-btn-transparent" style={{ border: '1px solid', ...headerButtonBorderStyle}} onClick={handleClickOpenSignUpModel} data-e2e="signUp">
												<p>Sign Up</p>
											</button>
										</div>
									}
								</div>
							) : (
								<div className="header__actions">
                  <div className="header__wallet grey-bg-hover" style={{...headerButtonStyle}} onClick={() => navigate('/user-wallet-home')} data-e2e="wallet">
										<img width="19" height="19" src={`${image_base_url}/upd/icons/wallet.svg`} />
                  </div>

                    <div ref={refProfileBlock} className="header__profile_block">
                      <button className={`header__profile grey-bg-hover ${isProfileMenuActive ? 'active' : ''}`} style={localDb.getSSVal("b2cColorMode") === "dark" ? {} : { borderColor: "rgba(128,128,128, 0.2)" }} onClick={toggleProfileMenu} data-e2e="user-profile">
                        <div className="header__profile_name" style={{ ...headerLinkStyle }} data-e2e={b2cUserName}>{b2cUserName}</div>
                        <div className="header__profile_icon" style={{ ...headerButtonStyle }} data-e2e={initials}>{initials}</div>
                      </button>

										<div className={`header__profile_menu ${isProfileMenuActive ? 'active' : ''}`}>
											<p className="header__profile_email">{userEmail}</p>
											<div className="theme-switch-wrapper">
												<p className="theme-switch-label">Dark Mode</p>
												<button type="button" onClick={handleTheme} className={`theme-switch ${localDb.getSSVal("b2cColorMode")==='dark' ? 'checked' : ''}`}>
													<div className="slider round" style={localDb.getSSVal("b2cColorMode") === 'dark' ? { ...headerButtonStyle } : {}}></div>
												</button>
											</div>
											<div className="divider"></div>
											{
												profileMenu.map(x => {
													return (
														<div key={x.title} className="header__profile_item" data-e2e={x.dataE2e} onClick={() => navigate(x.history)}>
															{x.title}
															<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.250162 9.41675C0.0973847 9.26397 0.0209961 9.06953 0.0209961 8.83342C0.0209961 8.5973 0.0973847 8.40286 0.250162 8.25008L3.50016 5.00008L0.250162 1.75008C0.0973847 1.5973 0.0209961 1.40286 0.0209961 1.16675C0.0209961 0.930637 0.0973847 0.736192 0.250162 0.583414C0.40294 0.430637 0.597385 0.354248 0.833496 0.354248C1.06961 0.354248 1.26405 0.430637 1.41683 0.583414L5.25016 4.41675C5.3335 4.50008 5.39266 4.59036 5.42766 4.68758C5.46266 4.7848 5.47989 4.88897 5.47933 5.00008C5.47933 5.11119 5.46183 5.21536 5.42683 5.31258C5.39183 5.4098 5.33294 5.50008 5.25016 5.58341L1.41683 9.41675C1.26405 9.56952 1.06961 9.64592 0.833496 9.64592C0.597385 9.64592 0.40294 9.56952 0.250162 9.41675Z" fill="#D0D5DD"/></svg>
														</div>
													)
												})
											}
											<div className="divider"></div>
											<div className="theme-btn-block full-width">
                          <button className="theme-btn theme-btn-red" style={{ ...headerButtonStyle }} onClick={logout} data-e2e="log-out">
													<p>Log Out</p>
												</button>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
          
				<div className={`mobileMenu ${isMobileMenuActive ? 'active' : ''}`}>
					<button onClick={() => setMobileMenuState(false)} className="mobileMenu__close">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 8.4L2.1 13.3C1.91667 13.4833 1.68334 13.575 1.4 13.575C1.11667 13.575 0.883336 13.4833 0.700003 13.3C0.516669 13.1167 0.425003 12.8833 0.425003 12.6C0.425003 12.3167 0.516669 12.0833 0.700003 11.9L5.6 7L0.700003 2.1C0.516669 1.91667 0.425003 1.68333 0.425003 1.4C0.425003 1.11667 0.516669 0.883332 0.700003 0.699999C0.883336 0.516666 1.11667 0.424999 1.4 0.424999C1.68334 0.424999 1.91667 0.516666 2.1 0.699999L7 5.6L11.9 0.699999C12.0833 0.516666 12.3167 0.424999 12.6 0.424999C12.8833 0.424999 13.1167 0.516666 13.3 0.699999C13.4833 0.883332 13.575 1.11667 13.575 1.4C13.575 1.68333 13.4833 1.91667 13.3 2.1L8.4 7L13.3 11.9C13.4833 12.0833 13.575 12.3167 13.575 12.6C13.575 12.8833 13.4833 13.1167 13.3 13.3C13.1167 13.4833 12.8833 13.575 12.6 13.575C12.3167 13.575 12.0833 13.4833 11.9 13.3L7 8.4Z" fill="#D0D5DD"/></svg>
					</button>
					{!token ? 
						(
							<div className="mobileMenu__start">
								<div className="mobileMenu__start_content">
									<div className="logo mobileMenu__logo" onClick={() => navigate('/')}>
										{paymentProviderIdName && 
											<img 
												src={`${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/logo${b2cTheme === 'light' ? "" : "dark"}.png` : logo}`} alt="crypton-logo-dark-mode"
												onError={() => {
													setPaymentProviderId(null);
													setDomainId(null);
													setPaymentProviderIdName(null)
												}}
											/>
										}
									</div>
									<p className="mobileMenu__start_text t-center">Experience the best of Web3 using {paymentProviderIdName}.</p>
								</div>
								
								<div className="mobileMenu__actions">
									<div className="divider"></div>
									<div className="theme-btn-block mb full-width">
										<button className="theme-btn" style={{...headerButtonStyle}}  onClick={handleLogin} data-e2e="logIn">
											<p>Log In</p>
										</button>
									</div>
									<div className="theme-btn-block full-width">
                    <button className="theme-btn theme-btn-transparent" style={{ border: '1px solid', ...headerButtonBorderStyle }} onClick={handleClickOpenSignUpModel} data-e2e="signUp">
											<p>Sign Up</p>
										</button>
									</div>
								</div>
							</div>
						) : (
							<div className="mobileMenu__loggedIn">
								<div className="mobileMenu__menu">
                  <div className="header__profile_icon" style={{ ...headerButtonStyle }} data-e2e={initials}>{initials}</div>
									<p className="header__profile_email">{userEmail}</p>
									<div className="theme-switch-wrapper">
										<p className="theme-switch-label">Dark Mode</p>
										<button type="button" onClick={handleTheme} className={`theme-switch ${localDb.getSSVal("b2cColorMode")==='dark' ? 'checked' : ''}`}>
											<div className="slider round" style={localDb.getSSVal("b2cColorMode") === 'dark' ? { ...headerButtonStyle } : {}} ></div>
										</button>
									</div>
									
									<div className="divider"></div>
									{
										profileMenu.map(x => {
											return (
												<div key={x.title} className="header__profile_item" data-e2e={x.dataE2e} onClick={() => {historyPushHandler(x.history)}}>
													{x.title}
													<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.250162 9.41675C0.0973847 9.26397 0.0209961 9.06953 0.0209961 8.83342C0.0209961 8.5973 0.0973847 8.40286 0.250162 8.25008L3.50016 5.00008L0.250162 1.75008C0.0973847 1.5973 0.0209961 1.40286 0.0209961 1.16675C0.0209961 0.930637 0.0973847 0.736192 0.250162 0.583414C0.40294 0.430637 0.597385 0.354248 0.833496 0.354248C1.06961 0.354248 1.26405 0.430637 1.41683 0.583414L5.25016 4.41675C5.3335 4.50008 5.39266 4.59036 5.42766 4.68758C5.46266 4.7848 5.47989 4.88897 5.47933 5.00008C5.47933 5.11119 5.46183 5.21536 5.42683 5.31258C5.39183 5.4098 5.33294 5.50008 5.25016 5.58341L1.41683 9.41675C1.26405 9.56952 1.06961 9.64592 0.833496 9.64592C0.597385 9.64592 0.40294 9.56952 0.250162 9.41675Z" fill="#D0D5DD"/></svg>
												</div>
											)
										})
									}
								</div>
								<div className="mobileMenu__logout">
									<div className="divider"></div>
									<div className="theme-btn-block full-width">
                    <button className="theme-btn theme-btn-red" style={{ ...headerButtonStyle }} onClick={logout} data-e2e="log-out">
											<p>Log Out</p>
										</button>
									</div>
								</div>
							</div>
						)
					}
				</div>
    
        {/* {
          showMenu && (
            <div className='menu_container'>
              <div className="menu_content_container">
                <div className='upper_menu'>
                    {token && <div className="dark-toggle"       
                        onClick={() => {
                          navigate('/user-settings-b2c');
                        }}
                        data-e2e="settings">{`Settings`}</div>}
                    <div className="dark-toggle">
                        <div className="cursor-pointer" data-e2e="darkMode">
                          Dark mode
                        </div>
                        <div className="cursor-pointer change_mode" onClick={() =>handleTheme()}>
                          <img src={`${image_base_url}${switchLightDarkMode}`} alt="switchLightDarkMode"  />
                        </div>
                    </div>
                    {
                      token && (
                        <span>
                          <div className="dark-toggle" data-e2e="wallet" onClick={() => navigate('/user-wallet-home')} >{`Wallet`}</div>
                          <div className="dark-toggle" data-e2e="addressBook" 
                          onClick={() => {
                          navigate('/add-address');
                        }}>{`Address Book`}</div>
                        </span>                   
                      )
                    }
                </div>
                {token &&
                  <div className='middle_menu'>
                    {showBuyButton && 
                      <div className="common-btn  btn-filled" 
                        onClick={() => navigate({
                          pathname: '/user-wallet-home',
                          data: {
                            modalName : 'buy'
                          }
                        },)} data-e2e="buy">
                        <img src={`${image_base_url}${buyIcon}`} alt="buyIcon" />
                        <span>Buy</span>
                      </div>
                    }

                    <div className="common-btn  btn-filled" style={{margin:"0 8px"}} data-e2e="send"
                      onClick={() => navigate({
                          pathname: '/user-wallet-home',
                          data: {
                            modalName : 'send'
                          }
                        },)}>
                      <img src={`${image_base_url}${sendIcon}`} alt="sendIcon" />
                      <span>Send</span>
                    </div>

                    <div className="common-btn  btn-filled" data-e2e="receive"
                       onClick={() => navigate({
                          pathname: '/user-wallet-home',
                          data: {
                            modalName : 'receive'
                          }
                        },)}
                      >
                      <img src={`${image_base_url}${receiveIcon}`} alt="receiveIcon" />
                      <span>Receive</span>
                    </div>
                  </div>
                }
                <div className='lower_menu'>
                {!token ? (
                    <div >
                      <div className="common-btn  btn-filled w-100 mt-2 text-uppercase" onClick={handleClickOpenSignUpModel} data-e2e="signUp">
                        Sign Up
                      </div>
                      <div className="common-btn btn-outline w-100 mt-3 text-uppercase" onClick={() => handleLogin()} data-e2e="logIn">
                        Log in
                      </div>
                   </div>
                  ):(
                    <div className="common-btn btn-outline w-100 mt-3 text-uppercase" onClick={() => logout()} style={{...headerButtonStyle}} data-e2e="log-out">
                      Log out
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        } */}
      {/* -----> MENU END <------ */}
				<div className="section-border"></div>
      </header>
      {openSignUpModel && (
        <B2CSignUp
          open={openSignUpModel}
          onClose={handleCloseSignUpModel}
          handleEmailVerification={handleEmailVerification}
          handleLoginModelOpen={handleLoginModelOpen}
        />
      )}
      {openEmailVerifycation && (
        <EmailVerfication
          user_email={emailForVerifycation}
          openLogin={() => {
            handleOpenLoginModel();
          }}
          open={openEmailVerifycation}
          onClose={handleCloseEmailVerification}
        />
      )}
      {sendModel && <SendCryptoModel open={sendModel}
        onClose={handleCloseSendModel}
        handleSendStatus={() => { }}
        saveCryptoBySelectedAddress={() => { }}
        type={modelType}
        coin={"BTC"}
      />}
      {loginModel && <B2CLogin open={loginModel}  onClose={handleCloseLoginModel} signUpModelOpenInLogin={signUpModelOpenInLogin} handleOpenLoginModel={handleOpenLoginModel} />}
      {verfiyModel && <VerifyModel open={verfiyModel} onClose={handleCloseVerifyModel}/>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.common.token,
    openKYCVerificationModal: state.common.openKYCVerificationModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setToken: (data) => dispatch(actions.setToken(data)),
    set2faActivationStatus: (data) => dispatch(actions.set2faActivationStatus(data)),
    setOpenKYCVerificationModal: (data) => dispatch(actions.setOpenKYCVerificationModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(B2CHeader);
