const debounce = (func, wait) => {
    let timeout;
    const debounced = function(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };

    debounced.cancel = () => {
        clearTimeout(timeout);
    };

    return debounced;
};

export default debounce;