import React from 'react';

const SingleParameter = (props) => {
    const {name, description} = props.data;
    return (
                <div className="SingleParameterWrap">
                    <div className="SingleParameter_title">
                        {name}
                    </div> 
                    <div className="SingleParameter_description">
                        {description}
                    </div>
                </div>
    )
}

export default SingleParameter