const REFUND_SUCCESSFUL_URL = "/creditCard/refundSuccessful"
const REFUND_FAILED_URL = "/creditCard/refundFailed"

export const getRedirectUrlBasedOnUiFlow = async (data) => {
    const { uiFlowType, depositAmount, fiatCurrency, redirectUrl, returnToMerchantUrl, redirectUrlFail, redirectUrlSuccess, } = data
    try {
        let redirectUrlObj = {
            successUrl: redirectUrlSuccess ??  (data?.domainUrl || "") + `/creditCard/depositSuccessful?amount=${depositAmount}&currency=${fiatCurrency}&merchantRedirectUrl=${returnToMerchantUrl ?? redirectUrlSuccess}`,
            failedUrl: redirectUrlFail ??  (data?.domainUrl || "") + `/creditCard/depositFailed?merchantRedirectUrl=${returnToMerchantUrl ?? redirectUrlFail}`,
            refundSuccessUrl: (data?.domainUrl || "") + `${REFUND_SUCCESSFUL_URL}?merchantRedirectUrl=${returnToMerchantUrl ? returnToMerchantUrl : redirectUrlSuccess}`,
            refundFailureUrl: (data?.domainUrl || "") + `${REFUND_FAILED_URL}?merchantRedirectUrl=${returnToMerchantUrl ? returnToMerchantUrl : redirectUrlFail}`, 
        }
        if (!returnToMerchantUrl && !redirectUrlFail && !redirectUrlSuccess) {
            redirectUrlObj = {
                ...redirectUrlObj,
                successUrl: `/creditCard/depositSuccessful?isB2c=true`,
                failedUrl: `/creditCard/depositFailed?isB2c=true`,
            }
        }
        console.log("=================", uiFlowType)
        if (uiFlowType === "KalonPay") {
            redirectUrlObj = {
                successUrl: (data?.domainUrl || "") + `/kalonpay-payment/depositSuccess?amount=${depositAmount}&currency=${fiatCurrency}&merchantRedirectUrl=${returnToMerchantUrl ? returnToMerchantUrl : redirectUrlSuccess}`,
                failedUrl: (data?.domainUrl || "") + `/kalonpay-payment/depositFailed`
            }
        }
        return redirectUrlObj
    } catch (err) {
        throw err
    }
}