import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import browser from 'browser-detect';
import { saveActivity } from "../../../common/activityTrackingService";
import { getDeviceIP } from "../../../common/getIP";
import localDb from "../../../common/localDb";
import EmailVerfication from '../../Pages/B2C/EmailVerfication';
import Login from '../../Pages/B2C/Login';
import Signup from '../../Pages/B2C/SignUp/index';

import GetStarted from './components/GetStarted';
import Hero from './components/Hero';
import Safe from './components/Safe';
import WhyChoose from './components/WhyChoose';

const browserInfo = browser();

function HomeB2c(props) {
  const [openSignUpModel, setOpenSignUpModel] = useState(false);
  const [openEmailVerifycation, setOpenEmailVerification] = useState(false);
  const [emailForVerifycation, setEmailForVerification] = useState(null);
  const [loginModel, setLoginModel] = useState(false);
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  
  const handleCloseLoginModel = () => {
    setLoginModel(false);
  };

  const handleClickOpenSignUpModel = async () => {
    setOpenSignUpModel(true);
    let deviceIp = await getDeviceIP()
    saveActivity({
      EventPage: "Home-B2C",
      EventType: "ClickSignup",
      attribute1: localDb.getVal("b2cUserId") === undefined ? "" : localDb.getVal("b2cUserId"),
      attribute2: localDb.getVal("user_name") === undefined ? "" : localDb.getVal("user_name"),
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
  })
  };
  const handleCloseSignUpModel = () => {
    setOpenSignUpModel(false);
  };
  const handleOpenEmailVerification = () => {
    setOpenEmailVerification(true);
  };
  const handleCloseEmailVerification = () => {
    setOpenEmailVerification(false);
  };
  const handleEmailVerification = (user_email) => {
    setEmailForVerification(user_email);
    handleOpenEmailVerification();
    handleCloseSignUpModel();
  };

  const signUpModelOpenInLogin = () => {
    handleCloseLoginModel();
    handleClickOpenSignUpModel();
  };

  const handleEmailValidation = (email) => {
    setLoginModel(false)
    setOpenEmailVerification(true)
    setEmailForVerification(email)
  }
  
  const handleOpenLoginModel = () => {
    setLoginModel(true);
  };

  const handleLoginModelOpen = () => {
    handleOpenLoginModel();
    handleCloseSignUpModel();
  };

  const initHomeB2C = async () => {
    let deviceIp = await getDeviceIP()
    saveActivity({
      EventPage: "Home-B2C",
      EventType: "PageView",
      attribute1: localDb.getVal("b2cUserId") === undefined ? "" : localDb.getVal("b2cUserId"),
      attribute2: localDb.getVal("user_name") === undefined ? "" : localDb.getVal("user_name"),
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    })
  }
  useEffect(() => {
    initHomeB2C();
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
  }
  }, [])
  return (
    <div>
      <Hero handleOpenLoginModel={handleOpenLoginModel} />
			<div className="section-border"></div>
			<WhyChoose />
			<div className="section-border"></div>
			<Safe handleOpenLoginModel={handleOpenLoginModel} />
			<div className="section-border"></div>
			<GetStarted handleClickOpenSignUpModel={handleClickOpenSignUpModel} />
			<div className="section-border"></div>

      {openSignUpModel && (
        <Signup
          open={openSignUpModel}
          onClose={handleCloseSignUpModel}
          handleEmailVerification={handleEmailVerification}
          handleLoginModelOpen={handleLoginModelOpen}
        />
      )}
      {openEmailVerifycation && (
        <EmailVerfication
          user_email={emailForVerifycation}
          openLogin={() => {
            handleOpenLoginModel();
          }}
          open={openEmailVerifycation}
          onClose={handleCloseEmailVerification}
        />
      )}
      {loginModel && <Login open={loginModel} onClose={handleCloseLoginModel} signUpModelOpenInLogin={signUpModelOpenInLogin} handleEmailValidation={handleEmailValidation}/>}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    api_url: state.config.api_url,
    token: state.common.token,
    image_base_url: state.config.image_base_url,
  };
};

export default connect(mapStateToProps, null)(HomeB2c);

