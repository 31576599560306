import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import speakeasy from 'speakeasy';
import { apiService } from '../../../common/apiCallService';
import localDb from "../../../common/localDb";
import * as actions from '../../../Redux/actions';
import B2CHeaderAndFooter from '../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import AlertMessage from './AlertMessage';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import DisableEnableGoogleAuthticator from './DisableEnableGoogleAuthticator';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
import { getEmailVal } from '../../../common/commonMethod';
const imageUrl = '/homeb2c/delete-address.png';

const B2CSettings = (props) => {
  const [imageData, setImageData] = useState(null);
  const [activationModal, open2faActivationModal] = useState(false);
  const [activation2faToken, setSetActivation2faToken] = useState('');
  const [deactivation2faToken, setSetDeactivation2faToken] = useState('');
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [error, setError] = useState('');
  const api_url = useSelector((state) => state.config.api_url);
  const [updateError, setUpdateError] = useState('');
  const [marketCommunicationFlagSuccess, setMarketCommunicationFlagSuccess] = useState(false);
  const [removeIpAddress, setRemoveIpAddress] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const [athunticateModel, setAuthenticateModel] = useState(false);
  const [athunticateSuccessAlert, setAuthenticateSuccessAlert] = useState(false);
  const [alertData, setAlertData] = useState('');
  const [selectedIpAddress, setSelectedIpAddress] = useState('');
  const [secret, setSecret] = useState('');

  const handleCloseChangePasswordModal = () => setShowChangePasswordModal(false);
  const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);

  const hadnleSetAuthenticateModel = () => setAuthenticateModel(!athunticateModel);

  const handleCloseChangeEmailModal = () => setShowChangeEmailModal(false);
  const handleShowChangeEmailModal = () => setShowChangeEmailModal(true);

  const handleAlertData = (data) => {
    setAlertData(data);
  };

  const handleCloseAuthenticateModel = () => {
    setAuthenticateSuccessAlert(false);
  };

  const handleOpenAuthenticateModel = () => {
    setAuthenticateSuccessAlert(true);
  };

  const [enbledScreen, setEnabledScreen] = useState(false);

  const handleOepnEnabledScreen = () => {
    setEnabledScreen(true);
  };

  const handleCloseEnabledScreen = () => {
    setEnabledScreen(false);
  };

  let providerName =   localDb.getSSVal("paymentProviderName")

  const generateQRcode = async () => {
    try {
        const emailId = localStorage.getItem("user_name") || props.merchantEmail;
        const paymentProviderName = localDb.getSSVal("paymentProviderName") || "CryptonPay";

        const response = await fetch((window.location.hostname === 'localhost' ? api_url : '') + '/restapi/genrateQrCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(localStorage.getItem("token") == null && { token: props.merchantData.token })
            },
            body: JSON.stringify({ email: emailId, paymentProviderName })
        });

        if (!response.ok) {
            throw new Error('Failed to generate QR code');
        }

        const responseData = await response.json();
        const { qrCode , secret} = responseData;
        setImageData(qrCode);
        setSecret(secret);
        return secret;
    } catch (error) {
        console.error('Error generating QR code:', error.message);
        alert('Failed to generate QR code');
    }
};
  
  const save2FASetting = async () => {
    props.setLoading(true);
    const secretResult  = await generateQRcode()
    await apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-save-2fa-secret',
      {
        user_email: localStorage.getItem("user_name"),
        secret: secretResult?.base32,
        encoding: 'base32',
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (data) => {
        props.setLoading(false);
        if (data.success === true) {
          open2faActivationModal(true);
        } else {
          alert('Something went wrong.');
        }
      },
      (err) => {
        props.setLoading(false);
        alert('Something went wrong!');
        console.log(err);
      },
    );
  };
  const verifyAndChangeActivatation = (user2faToken, enabled) => {
    if (user2faToken && user2faToken.length === 6) {
      props.setLoading(true);
      apiService(
        (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-verify-2fa-token',
        {
          user_email: localStorage.getItem("user_name"),
          user2faToken: user2faToken,
          changeActivation: enabled,
          domainNameUrl: localStorage.getItem("domainNameUrl")
        },
        (data) => {
          props.setLoading(false);
          if (data.success === true) {
            // console.log(`2FA ${enabled}d successfully!`);
            handleCloseEnabledScreen();
            handleAlertData(`2FA authentication has been ${props.is2faActivated ? 'disabled' : 'enabled'} `);
            setAuthenticateSuccessAlert(true);
            setTimeout(() => {
              setAuthenticateSuccessAlert(false);
            }, 3000);
            localStorage.setItem("2FAStatus", enabled === 'activate' ? true : false);
            props.set2faActivationStatus(enabled === 'activate' ? true : false);
            if (enabled === 'deactivate') {
              setImageData(null);
            }
          } else {
            setError('Invalid verification token!');
          }
        },
        (err) => {
          props.setLoading(false);
          alert('Something went wrong!');
          console.log(err);
        },
      );
    } else {
      setError('Invalid token! 2FA token must have 6-digit only');
    }
  };

  const handleToggle2FA = () => {
    if (props.is2faActivated) {
      setAuthenticateModel(false);
    } else {
      setAuthenticateModel(true);
    }
    // hadnleSetAuthenticateModel()
    handleOepnEnabledScreen();
    if(!props.is2faActivated) {
      save2FASetting();
    }
  };

  // const email = typeof window !== 'undefined' ? localStorage.getItem("user_name"): null;
  const [b2cUserData, setB2cUserData] = useState();

  useEffect(() => {
    getUserSettingDetails();
  }, [marketCommunicationFlagSuccess]);

  useEffect(() => {
    let status = localStorage.getItem("2FAStatus");
    if (status) {
      props.set2faActivationStatus(status == 'true' ? true : false);
    }
  }, []);

  const getUserSettingDetails = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-user-details',
      {
        email: localStorage.getItem("user_name"),
        domainNameUrl: localStorage.getItem("domainNameUrl"),
      },
      (data) => {
        props.setLoading(false);
        if (data) {
          const emailVal = getEmailVal(data?.email)
          data = { ...data, email: emailVal }
          setB2cUserData(data);
        } else {
          setError('Something went wrong!..');
        }
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  // const [b2cUpdatedUserData, setB2cUpdatedUserData]= useState();
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  const handleChange = (e) => {
    setB2cUserData({ ...b2cUserData, [e.target.name]: e.target.value });
    setShowUpdateButton(true);
    setUpdateError('');
  };

  const updateB2CSetting = () => {
    let paylod = {
      email: b2cUserData?.email,
      domainNameUrl: b2cUserData?.domainNameUrl,
      country: b2cUserData?.country,
      city: b2cUserData?.city,
      street: b2cUserData?.street,
      zipCode: b2cUserData?.zipCode,
    };

    if (b2cUserData?.name !== undefined) {
      let fullName = b2cUserData?.name?.trim();
      let index = fullName.indexOf(' ');
      paylod.firstName = fullName.substr(0, index);
      paylod.lastName = fullName?.substr(index+1)?.trim();
    } else if (b2cUserData?.firstName) {
      paylod.firstName = b2cUserData?.firstName;
      paylod.lastName = b2cUserData?.lastName;
    } else {
      setUpdateError('Please enter your full name properly.');
    }

    if (paylod?.firstName) {
      props.setLoading(true);
      apiService(
        (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/update-b2c-user-details',
        {
          paylod,
        },
        (data) => {
          props.setLoading(false);
          if (data) {
            setUpdateError('');
            const emailVal = getEmailVal(data?.email)
            data = { ...data, email: emailVal }
            setB2cUserData(data);
            setShowUpdateButton(false);
            setMarketCommunicationFlagSuccess(!marketCommunicationFlagSuccess)
          } else {
            setError('Something went wrong!..');
          }
        },
        (err) => {
          props.setLoading(false);
          setShowUpdateButton(false);
          console.log(err);
        },
      );
    }
  };

  const handleMarketCommunicationFlag = (flag) => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/enable-disable-market-flag',
      {
        email: b2cUserData && b2cUserData.email,
        marketingFlag: flag,
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (res) => {
        props.setLoading(false);
        setMarketCommunicationFlagSuccess(!marketCommunicationFlagSuccess);
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };

  const handleRemoveIPAddress = () => {
    props.setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/remove-b2c-user-ip-address',
      {
        email: b2cUserData && b2cUserData.email,
        ipAddress: selectedIpAddress,
        domainNameUrl: localStorage.getItem("domainNameUrl"),
      },
      (res) => {
        props.setLoading(false);
        setRemoveIpAddress(false);
        setMarketCommunicationFlagSuccess(!marketCommunicationFlagSuccess);
      },
      (err) => {
        props.setLoading(false);
        console.log(err);
      },
    );
  };


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 43,
  height: 22,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 40,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(26px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: localDb.getSSVal("b2cColorMode") === "dark" ? localDb.getSSVal("headerButtonDarkBgColour") : localDb.getSSVal("headerButtonLightBgColour"),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 23,
    height: 21,
    position: 'relative',
    bottom: 1.5,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

useEffect(()=>{
  if (checkValueInSS("b2cColorMode")) {
    setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
  }
  if (checkValueInSS("headerButtonDarkBgColour")) {
    setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
  }
  if (checkValueInSS("headerButtonLightBgColour")) {
    setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
  }
  if (checkValueInSS("headerDarkTextColour")) {
    setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
  }
  if (checkValueInSS("headerLightTextColour")) {
    setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
  }
}, [])

let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <B2CHeaderAndFooter>
      <div className="b2c-settings">
        <div className="left-margin">
          <div className="alert-model-container">
            {athunticateSuccessAlert && <AlertMessage data={alertData} handleCloseAuthenticateModel={handleCloseAuthenticateModel} />}
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-5">
            <div className="b2c-settings-header">Personal Information</div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-5 setting-input-Container">
            <div className="col-6 p-0 single-input">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">Full Name</div>
                <div className="col-6 ">
                  <input
                    type="text"
                    className="settings-input"
                    placeholder="Enter Full Name"
                    data-e2e="enter-full-name-text-box"
                    name="name"
                    defaultValue={b2cUserData && b2cUserData.firstName + ' ' + b2cUserData.lastName}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-3 setting-input-Container">
            <div className="col-6 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">Email</div>
                <div className="col-6 ">
                  <div className="settings-input">
                    <div>{b2cUserData && b2cUserData.email}</div>
                    <div className="text-primary change cursor-pointer" onClick={handleShowChangeEmailModal} data-e2e="change-email">
                      Change
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-3 setting-input-Container">
            <div className="col-6 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">Password</div>
                <div className="col-6 ">
                  <div className="settings-input">
                    <div style={{ opacity: '0.5' }}>******</div>
                    <div className="text-primary change cursor-pointer" onClick={handleShowChangePasswordModal} data-e2e="change-password">
                      Change
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-3 setting-input-Container">
            <div className="col-6 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">Country</div>
                <div className="col-6 ">
                  <input
                    type="text"
                    className="settings-input"
                    placeholder="United Kingdom"
                    data-e2e="country"
                    name="country"
                    defaultValue={b2cUserData && b2cUserData.country}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 p-0"></div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-3 setting-input-Container">
            <div className="col-6 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">City</div>
                <div className="col-6 ">
                  <input
                    type="text"
                    className="settings-input"
                    placeholder="City"
                    name="city"
                    data-e2e="city"
                    defaultValue={b2cUserData && b2cUserData.city}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 p-0"></div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-3 setting-input-Container">
            <div className="col-6 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">Street</div>
                <div className="col-6 ">
                  <input
                    type="text"
                    className="settings-input"
                    data-e2e="street"
                    placeholder="Street"
                    name="street"
                    defaultValue={b2cUserData && b2cUserData.street}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 p-0"></div>
          </div>

          <div className="row w-90 ml-5 mr-5 pb-3 mt-3 setting-input-Container">
            <div className="col-6 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-6 settings-input-label">ZIP-Code</div>
                <div className="col-6 single-input">
                  <input
                    type="text"
                    className="settings-input"
                    placeholder="Zip Code"
                    data-e2e="zip-code"
                    name="zipCode"
                    defaultValue={b2cUserData && b2cUserData.zipCode}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 p-0"></div>
          </div>
          {updateError != '' && <div className="errorMessage">{updateError}</div>}
          {showUpdateButton && (
            <div data-e2e="update" className="common-btn btn-filled ml-auto mr-auto mt-3" style={{ ...headerButtonStyle }} onClick={updateB2CSetting}>
              Update
            </div>
          )}

          <div className="b2c-settings-header2">{`IP Address History`}</div>
          <div className="b2c-settings-header3 pb-3">{`We will notify you of any user login access from Ip addresses other than the list below.`}</div>
          <table className="settings-table">
            <tbody>
              <tr>
                <th style={{ width: '20%' }}>IP Addresses</th>
                <th></th>
              </tr>
              {b2cUserData &&
                b2cUserData.loginDetails &&
                b2cUserData.loginDetails.ipaddress &&
                b2cUserData.loginDetails.ipaddress.map((ipAdress, index) => {
                  return (
                    <tr key={index}>
                      <td data-e2e={ipAdress}>{ipAdress}</td>
                      <td
                        className="text-primary text-right pr-4 cursor-pointer"
                        onClick={() => {
                          setSelectedIpAddress(ipAdress);
                          setRemoveIpAddress(true);
                        }}
                        data-e2e={`${ipAdress}-remove`}
                      >
                        Remove
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <MediaQuery minWidth={786}>
            <div className="ml-5 mr-5 pb-4 container2">
            <div className="b2c-settings-header2 pl-0">Protection</div>
            <div className="row ">
              <div className="col-5 b2c-settings-header3 ">
                Google Authenticator two factor log in authentication - <span className="text-success">High Security</span>
              </div>
              <div className="col-2">
                <div className="cursor-pointer">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={() => {
                        handleToggle2FA();
                      }}
                      checked={props.is2faActivated ? true : false}
                      data-e2e={props.is2faActivated ? true : false}
                    />
                  </Stack>
                </div>
              </div>
              <div className="col-5"></div>
            </div>
            </div>

            <div className="ml-5 mr-5 pb-4 container2">
            <div className="b2c-settings-header2 pl-0">Marketing</div>
            <div className="row ">
              <div className="col-5 b2c-settings-header3 ">{`Allow ${providerName ? providerName : "CryptonPay"} to send marketing communications.`}</div>
              <div className="col-2">
                <div className="cursor-pointer">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={() => {
                        handleMarketCommunicationFlag(b2cUserData && b2cUserData.marketingFlag && b2cUserData.marketingFlag ? false : true);
                      }}
                      checked={b2cUserData && b2cUserData.marketingFlag && b2cUserData.marketingFlag ? true : false}
                      data-e2e="drag"
                    />
                  </Stack>
                </div>
                {showChangePasswordModal && (
                  <ChangePassword
                    open={showChangePasswordModal}
                    onClose={handleCloseChangePasswordModal}
                    handleAlertData={handleAlertData}
                    hadnleSetAuthenticateModel={hadnleSetAuthenticateModel}
                    handleOpenAuthenticateModel={handleOpenAuthenticateModel}
                  />
                )}
                {showChangeEmailModal && <ChangeEmail open={showChangeEmailModal} onClose={handleCloseChangeEmailModal} />}
              </div>
              <div className="col-5"></div>
            </div>
          </div>
          </MediaQuery>

          <MediaQuery maxWidth={786}>
            <div className="ml-5 mr-5 pb-4 container2">
            <div className="b2c-settings-header2 pl-0">Protection</div>
            <div className="d-flex justify-content-between">
              <div className="b2c-settings-header3 ">
                Google Authenticator two factor log in authentication - <span className="text-success">High Security</span>
              </div>
                <div className="cursor-pointer">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={() => {
                        handleToggle2FA();
                      }}
                      checked={props.is2faActivated ? true : false}
                      data-e2e={props.is2faActivated ? true : false}
                    />
                  </Stack>
              </div>
            </div>
            </div>

            <div className="ml-5 mr-5 pb-4 container2">
            <div className="b2c-settings-header2 pl-0">Marketing</div>
            <div className="d-flex justify-content-between">
              <div className="b2c-settings-header3 ">{`Allow ${providerName ? providerName : "CryptonPay"} to send marketing communications.`}</div>
              <div className="col-2">
                <div className="cursor-pointer">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={() => {
                        handleMarketCommunicationFlag(b2cUserData && b2cUserData.marketingFlag && b2cUserData.marketingFlag ? false : true);
                      }}
                      checked={b2cUserData && b2cUserData.marketingFlag && b2cUserData.marketingFlag ? true : false}
                      data-e2e="drag"
                    />
                  </Stack>
                </div>
                {showChangePasswordModal && (
                  <ChangePassword
                    open={showChangePasswordModal}
                    onClose={handleCloseChangePasswordModal}
                    handleAlertData={handleAlertData}
                    hadnleSetAuthenticateModel={hadnleSetAuthenticateModel}
                    handleOpenAuthenticateModel={handleOpenAuthenticateModel}
                  />
                )}
                {showChangeEmailModal && <ChangeEmail open={showChangeEmailModal} onClose={handleCloseChangeEmailModal} />}
              </div>
            </div>
          </div>
          </MediaQuery>
         

        </div>
        {props.is2faActivated && !athunticateModel ? (
          <div>
            <DisableEnableGoogleAuthticator
              open={enbledScreen}
              onClose={handleCloseEnabledScreen}
              setError={setError}
              error={error}
              deactivation2faToken={deactivation2faToken}
              setSetDeactivation2faToken={setSetDeactivation2faToken}
              verifyAndChangeActivatation={verifyAndChangeActivatation}
              hadnleSetAuthenticateModel={hadnleSetAuthenticateModel}
              type="disable"
            />
          </div>
        ) : (
          <div>
            {!props.is2faActivated && athunticateModel && (
              <DisableEnableGoogleAuthticator
                open={enbledScreen}
                onClose={handleCloseEnabledScreen}
                imageData={imageData}
                setSetActivation2faToken={setSetActivation2faToken}
                setError={setError}
                error={error}
                verifyAndChangeActivatation={verifyAndChangeActivatation}
                activation2faToken={activation2faToken}
                save2FASetting={save2FASetting}
                hadnleSetAuthenticateModel={hadnleSetAuthenticateModel}
                type="enable"
              />
            )}
          </div>
        )}
      </div>
      <Dialog onClose={() => setRemoveIpAddress(false)} open={removeIpAddress} className="remove-address-model-dialog" style={{ overflow: 'visible' }}>
        <div className="p-4 remove-address-modal remove-ip-address-model">
          <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <img src={`${image_base_url}${imageUrl}`} className="remove-btn-image" />
          </div>
          <div>
            <div className="remove-content-container ">
              <div className="remove-address-header">Remove IP Address</div>
              <p className="remove-address-header-child">Are you sure you want to remove selected Address?</p>
            </div>
          </div>

          <div className="mt-4 align-button-container">
            <button className="add-address-modal-button remove-button-width mb-10" style={{ ...headerButtonStyle }} onClick={() => handleRemoveIPAddress()}>
              Remove
            </button>
            <button className="remove-address-go-back-btn remove-button_border" onClick={() => setRemoveIpAddress(false)}>
              Go Back
            </button>
          </div>
        </div>
      </Dialog>
    </B2CHeaderAndFooter>
  );
};

const mapStateToProps = (state) => {
  return {
    is2faActivated: state.common.is2faActivated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    set2faActivationStatus: (value) => dispatch(actions.set2faActivationStatus(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(B2CSettings);
