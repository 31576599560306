import React from 'react';

const CodeCard = (props) => {
    const { type, method, url, code } = props.data;
    return (
        <div className={`tools_code_card ${props.marginTopZero ? "mt-0" : ""}`}>
            <div className="tools_code_card_url">
                {type} {method && method !== "" ? (method) : null} {url}
            </div>
            <div className="tools_code_card_url">
                {code}
            </div>
        </div>
    )
}

export default CodeCard