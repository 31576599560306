import { apiService } from '../common/apiCallService';
import axios from 'axios';
import localDb from "./localDb";

async function generateActivityToken() {
  if(localDb.getVal("token") && !localDb.getVal("activity_tracking_token") || localDb.getVal("activity_tracking_token") == '') {
    return await apiService(localDb.getVal("api_url") + '/restapi/get-activity-trackingToken', {}, (data) => {
      localStorage.setItem("activity_tracking_token", data.token)
    }, (err) => {
      console.log(err);
    })
  }
}

export async function saveActivity(activityObject){
  try {
    const token = localDb.getVal("token");
    let activityObj = {
      EventPage: activityObject.EventPage,
      EventType: activityObject.EventType,
      attribute1: activityObject.attribute1 ? activityObject.attribute1 : "",
      attribute2: activityObject.attribute2 ? activityObject.attribute2 : "",
      attribute3: activityObject.attribute3 ? activityObject.attribute3 : "",
      attribute4: activityObject.attribute4 ? activityObject.attribute4 : "",
      attribute5: activityObject.attribute5 ? activityObject.attribute5 : "",
      attribute6: activityObject.attribute6 ? activityObject.attribute6 : "",
    };
    activityObj.deviceType =  activityObject.device_type ? activityObject.device_type : ""
    activityObj.browserType =  activityObject.browser_type ? activityObject.browser_type : ""
    activityObj.browserVersion =  activityObject.browser_version ? activityObject.browser_version : activityObject.browser_version
    activityObj.language =  activityObject.language ? activityObject.language : ""
    activityObj.ipAddress =  activityObject.ip_address ? activityObject.ip_address :""
    activityObj.sessionId = token;
    activityObj.trackerId = localDb.getVal("trackerId");
    const deviceInfo = JSON.parse(localDb.getVal("deviceInfo"));
    activityObj = { ...activityObj, ...deviceInfo };
    const activity_tracking_token = localDb.getVal("activity_tracking_token");
    if (!activity_tracking_token || activity_tracking_token == '') {
      await generateActivityToken()
    } 
    if (window.location.hostname !== 'localhost') {
      await axios({
        method: 'post',
        url: `${localDb.getVal("activity_tracking_api_url")}/activitytrk/activitytracking`,
        data: { activity_tracking_token, activityTrack: [activityObj] },
      });
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }


  // // console.log("activityObj", activityObj)
  // try {
  //   let activityTrack = [], actTrackStorage = []
  //   let track = {}
  //   // track.deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"))
  //   track.EventPage = activityObj.EventPage
  //   track.EventType = activityObj.EventType
  //   track.attribute1 = activityObj.attribute1 ? activityObj.attribute1 : ''
  //   track.attribute2 = activityObj.attribute2 ? activityObj.attribute2 : ''
  //   track.attribute3 = activityObj.attribute3 ? activityObj.attribute3 : ''
  //   track.attribute4 = activityObj.attribute4 ? activityObj.attribute4 : ''
  //   track.attribute5 = activityObj.attribute5 ? activityObj.attribute5 : ''
  //   track.attribute6 = activityObj.attribute6 ? activityObj.attribute6 : ''
  //   activityTrack.push(track)
    
  //   if(localStorage.getItem("activityTrack"))  
  //     actTrackStorage = JSON.parse(localStorage.getItem("activityTrack"))

  //   console.log("track.EventPage==="+track.EventPage)
  //   if((!localStorage.getItem("activity_tracking_token") || localStorage.getItem("activity_tracking_token") == '')) {
  //     console.log("====inside if=======")
  //     if(actTrackStorage && actTrackStorage.length > 0) activityTrack = [...activityTrack, ...actTrackStorage]
  //     localStorage.setItem("activityTrack", JSON.stringify(activityTrack))
  //     generateActivityToken()
  //     return
  //   }

  //   if(actTrackStorage && actTrackStorage.length > 0) {
  //     activityTrack = [...activityTrack, ...actTrackStorage]
  //     localStorage.removeItem("activityTrack");
  //   }
  //   // console.log("activityTrack", activityTrack)
  //   apiService(localStorage.getItem("activity_tracking_api_url") + '/activitytracking', {
  //     activityTrack, activity_tracking_token: localStorage.getItem("activity_tracking_token")
  //   }, (data) => {
  //     console.log(data);
  //     if(data && data.activity_tracking_token) {
  //       localStorage.setItem("activity_tracking_token", data.activity_tracking_token)
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   })
  // } catch (error) {
  //   console.log(error)
  //   throw new Error(error)
  // }
}

// export function getTrackId() {
//   const trackerId = localStorage.getItem("trackerId");
//   if (trackerId) {
//     return trackerId;
//   } else {
//     var newTrackerId = "";
//     var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     for (var i = 0; i < 15; i++)
//       newTrackerId += possible.charAt(Math.floor(Math.random() * possible.length));
//     localStorage.setItem("trackerId", newTrackerId);
//     return newTrackerId;
//   }
// }