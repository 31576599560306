import axios from 'axios';
import localDb from '../client/common/localDb';

export const call = async (config = { method: 'GET' }, data = {}, params = {}, isDownloadCsv = false) => {
    let url = config.url;
    const token = localDb.getVal('token');
    data = {
      ...data,
    };
    if (config.method === 'GET' && data && Object.keys(data).length > 0) {
      url += '?';
      Object.entries(data).forEach(([key, value]) => {
        url += `${key}=${value}&`;
      });
    }
    if (params && Object.keys(params).length > 0) {
      url += '?';
      Object.entries(params).forEach(([key, value]) => {
        url += `${key}=${value}&`;
      });
    }
    if (url.substr(url.length - 1, url.length) === '&') {
      url = url.slice(0, url.length - 1);
    }
    const options = {
      method: config.method,
      url,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-access-token': token || '',
      },
      mode: 'cors',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      timeout: isDownloadCsv ? 300000 : 60000,
    };
    if (['POST', 'PUT', 'DELETE'].indexOf(config.method) > -1) {
      options.data = data;
    }
    return axios(options);
  };