import { createStyles, styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const nohistoryicon = '/common/nohistoryicon.svg';

const WalletHistory = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const [showDaterange, setDateRange] = useState(false);
  const [value, setValue] = useState(["", ""]);
  const classes = useStyles;

  const calendar = b2cTheme === 'light' ? '/homeb2c/calendar_light.svg' : '/homeb2c/calendar_dark.svg';
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: 'black', color: 'white', padding: '2px', width: '70px', borderRadius: '5px', fontSize: '12px', textAlign: 'center' }}
        >
          <div>{`$ ${payload[0].payload.amount}`}</div>
          <div>{`${payload[0].payload.toolDate}`}</div>
        </div>
      );
    }
    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const dateTip = moment(payload.value).format("DD-MM-YY")
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={23} y={0} dy={14} fontSize="0.90em" fontFamily="bold" textAnchor="end" fill="#666">
          {dateTip}</text>
      </g>
    );
  }

  return (
    <div>
      <div className="wallet-history-header">
        <div className="wallet-history-chart-heading font-bold">Wallet History</div>
        {props.totalWalletHistoryData && props.totalWalletHistoryData > 0 ? (
          <div 
            onClick={() => {
              setDateRange((prev)=>!prev)
            }} >
            <img src={`${image_base_url}${calendar}`} alt="calendar" className="calendar-icon" />
          </div>
        ):(null)}
      </div>
      {props.walletHistoryData && props.walletHistoryData.length > 0 ? (
        <ResponsiveContainer height={300} >
          <AreaChart height={300} data={props.walletHistoryData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.3} />
                <stop offset="90%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis axisLine={false} tick={CustomizedAxisTick} dataKey='date' />
            <YAxis axisLine={false} tickLine={false} type='number'
              domain={[0, dataMax => {
                let n = props.walletHistoryData.length;
                let maxVal = 0;
                for (let i = 0; i < n; i++) {
                  if (parseFloat(props.walletHistoryData[i].amount) > maxVal) {
                    maxVal = parseFloat(props.walletHistoryData[i].amount);
                  }
                }
                return parseFloat(maxVal + 50).toFixed(0);
              }]} />
            <CartesianGrid strokeDasharray="7 7" stroke="#9e9e9e6b" />
            <Tooltip content={<CustomTooltip />} />
            <Area dataKey="amount" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
          </AreaChart>
        </ResponsiveContainer>

      ) : (
        <div className="nohistorycomponent">
          <img src={`${image_base_url}${nohistoryicon}`} alt="nohistoryicon" className="nohistoryicon" />
          <div className="nohistorytext">No Wallet History</div>
        </div>
      )}

      {showDaterange && (
         <Modal
         show={showDaterange}
         className="CP_calendar_modal"
         onHide={() => {
           setDateRange(false)
         }}
       >
         <Modal.Body className="wallets-history-date-select">
        <div>
            <MediaQuery maxWidth={786} >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                displayStaticWrapperAs="mobile"
                inputFormat='MMMM Do YYYY, h:mm:ss a'
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  if (newValue[0] && newValue[1]) {
                    props.setFromDate(moment(newValue[0]).format('MMMM Do YYYY, h:mm:ss a'));
                    props.setToDate(moment(newValue[1]).format('MMMM Do YYYY, h:mm:ss a'));
                    props.setChangeWalletDate((prev) => !prev)
                    setDateRange(false)
                    setValue(["", ""])
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
                InputProps={{
                  classes: { notchedOutline: classes.noBorder },
                }}
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
              />
              </LocalizationProvider>
            </MediaQuery>
            <MediaQuery minWidth={786} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  inputFormat='MMMM Do YYYY, h:mm:ss a'
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    if (newValue[0] && newValue[1]) {
                      props.setFromDate(moment(newValue[0]).format('MMMM Do YYYY, h:mm:ss a'));
                      props.setToDate(moment(newValue[1]).format('MMMM Do YYYY, h:mm:ss a'));
                      props.setChangeWalletDate((prev) => !prev)
                      setDateRange(false)
                      setValue(["", ""])
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  InputProps={{
                    classes: { notchedOutline: classes.noBorder },
                  }}
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
                </LocalizationProvider>
            </MediaQuery>
        </div>
         </Modal.Body>
       </Modal>
      )}
    </div>
  );
};

export default WalletHistory;

const useStyles = styled(StaticDateRangePicker)(() =>
  createStyles({
    noBorder: {
      outline: 'none',
      border: 'none',
      color: '#fff',
    },
  }),
);
