import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import queryString from "query-string";
import { useLocation } from 'react-router-dom';

const KalonpayFailedSecreen = () => {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const { search } = useLocation();
  const { retryUrl, jwtData, merchantid  } = queryString.parse(search);
  useEffect(() => {
    document?.body?.classList?.add('kalonpay-theme');
    return () => {
      document?.body?.classList?.remove('kalonpay-theme');
    };
  }, []);
  return (
    <div className="kal-deposit-container">
      <div className="kal-deposit-form-container">
        <div className="kal-deposit-body">
          <img src={`${imageBaseUrl}/kalonpay/kalonpay_deposit_failed.svg`} alt="kalonpay_deposit_failed" />
          <div className="kal-deposit-choosen">
            <p>Payment failed</p>
          </div>
          <div className="kal-deposit-equivalent">
            <p>Your card has not been charged</p>
          </div>
          <div className="kal-form-footer">
            {retryUrl && <button onClick={()=>window.location.replace(retryUrl+`&jwtData=${jwtData}&merchantid=${merchantid}`)}>Try again</button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KalonpayFailedSecreen;
