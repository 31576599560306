import React from 'react';
import B2CFooter from './B2CFooter';
import B2CHeader from './B2CHeader';

const B2CHeaderAndFooter = (props) => {
  return (
    <div>
      <B2CHeader {...props} />
      {props.children}
      {props.type === 'b2cTransactions' ? null : <B2CFooter />}
    </div>
  );
};

export default B2CHeaderAndFooter;
