import React, { Children, useEffect, useState } from 'react'
import KalonpayLayout from './layout';
import Dialog from '@mui/material/Dialog';
import { apiService } from '../../../common/apiCallService';
import { useSelector } from 'react-redux';
import Login from './modals/Login';
import ForgetPassword from './modals/ForgetPassword';
import KalonPayAuthenticatorModal from './global/KalonpayAuthenticator';
import KalonPayVerificationModal from './global/KalonpayVerifyFA';


const KalonpayHomeMerchant = () => {
    const onVerificationSucess = (data, user_name) => {
        localStorage.setItem("token", data.token)
        if (!sessionStorage.getItem("colorMode") || sessionStorage.getItem("colorMode") === "") {
            sessionStorage.setItem("colorMode", 'light')
        }
        if (data.is2faActivated && data.is2faActivated.secret) {
            if (data.is2faActivated.secret != null) {
                localStorage.setItem("2FAStatus", true);
            } else {
                localStorage.setItem("2FAStatus", false);
            }
        }
        if (data.merchantId) {
            localStorage.setItem("merchantId", data.merchantId)
        }
        if (data.merchantName) {
            localStorage.setItem("merchantName", data.merchantName)
        }
        if (data.merchant_Id) {
            localStorage.setItem("merchant_Id", data.merchant_Id)
        }
        localStorage.setItem("merchantWalletActivation", data.merchantWalletActivation ? data.merchantWalletActivation : false)
        // props.setToken(data.token)
        // closeModal();
        localStorage.setItem("loginType", "Merchant")
        localStorage.setItem("user_name", user_name)
        if (localStorage.getItem("merchantWalletActivation") && localStorage.getItem("merchantWalletActivation") != "false") {
            window.location.href = '/myAccount/balances'
        } else {
            window.location.href = '/myAccount/transactions'
        }
    }
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")
    const [autheniticatorModal, setAutheniticatorModal] = useState(false)
    const [forgetPassword, setForgetPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [forgetEmail, setForgetEmail] = useState("")
    const [authVerifyModal, setAuthVerifyModal] = useState(false)
    const [merchantData, setMerchantData] = useState({})
    const api_url = useSelector((state) => state.config.api_url)
    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        onLogin()
    }
    const onLogin = () => {
        apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/merchant-login', {
            user_name: email,
            password: password,
            domainNameUrl: window.location.hostname,
            paymentProviderName: sessionStorage.getItem("paymentProviderName")
        }, async (data) => {
            setLoading(false)
            localStorage.setItem("domainNameUrl", data.domainNameUrl ? data.domainNameUrl : null);
            localStorage.setItem("2FAStatus", data.is2faActivated && data.is2faActivated.enabled ? data.is2faActivated.enabled : false);
            setMerchantData(data)
            if (data.is2faActivated && data.is2faActivated.enabled === false) {
                onVerificationSucess(data, email);
            } else if (data.is2faActivated && data.is2faActivated.enabled === true && data.is2faActivated.secret != null) {
                setAuthVerifyModal(true);
            } else if (data.is2faActivated && data.is2faActivated.enabled === true && data.is2faActivated.secret == null) {
                setAutheniticatorModal(true)
            }
        }, (err) => {
            setLoading(false)
            setLoginError("Invalid username/password")
            console.log(err);
        })
    }

    return (
        <KalonpayLayout>
            {forgetPassword ?
                <ForgetPassword loading={loading} setLoading={setLoading} forgetEmail={forgetEmail} setForgetPassword={setForgetPassword} /> :
                <Login error={loginError} loading={loading} handleLogin={handleLogin} email={email} setEmail={setEmail} password={password} setPassword={setPassword} setForgetPassword={setForgetPassword} forgetPassword={forgetPassword} />
            }
            {autheniticatorModal &&
                <KalonPayAuthenticatorModal
                    setLoading={setLoading} open={autheniticatorModal}
                    onClose={() => setAutheniticatorModal(false)}
                    merchantData={merchantData}
                    merchantEmail={email}
                    onVerificationSucess={onVerificationSucess}
                    component={"head"}
                />

            }
            {authVerifyModal &&
                <KalonPayVerificationModal
                    setLoading={setLoading}
                    open={authVerifyModal}
                    onClose={() => setAuthVerifyModal(false)}
                    onVerificationSucess={onVerificationSucess}
                    merchantData={merchantData}
                    merchantEmail={email}
                />
            }
        </KalonpayLayout>
    )
}

export default KalonpayHomeMerchant