import React from 'react';
import { connect } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import { clearStorageData } from '../../../common/getIP';

class ChangePassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data : {                 
                    currentPassword : {
                        value : '',
                        validation: '', //reg exp
                        isError : false,
                        errorMsg : '',
                        isRequired : true
                    },
                    newPassword : {
                        value : '',
                        validation : '' ,
                        isError : false,
                        errorMsg : '',
                        isRequired : true
                    },
                    confirmNewPassword : {
                        value : '',
                        validation : '' ,
                        isError : false,
                        errorMsg : '',
                        isRequired : true
                    },
                },
                isPasswordMatch:true
        }
    }

    validation = (e) => {

        let {name,value} = e.target
        let stateObj = {...this.state.data}
        let validation = stateObj[name].validation
        let isRequired = stateObj[name].isRequired
        let isError = false
        let RegEx = new RegExp(validation)

        if(isRequired){
            if(value === ''){
                 isError = true
            }else{
                if(validation !== ''){
                    if(!value.match(RegEx)){
                        isError = true
                    }
                }
            }
        }else{
            if(value !== '' & validation !== ''){
                if(!value.match(RegEx)){
                     isError = true
                }
            }
        }

        return stateObj[name].isError = isError
    }

    changeHandler = (e) => {
    
        this.setState({isPasswordMatch:true})
        let {name,value} = e.target
        let stateObj = {...this.state.data}
    
        this.validation(e)
    
        stateObj[name].value = value
    
        this.setState({...stateObj})
    
        }

      submitHandler = (e) => {
            let stateObj = {...this.state.data}
            let isSubmit = true
            
            Object.values(stateObj).map(e  => {
               
                if((e.value === '' && e.isRequired) || e.isError){
                    e.isError = true
                    isSubmit = false
            }
            return this.setState({...stateObj})
            })
            if(!isSubmit){
                this.setState({...stateObj})
                return false    
            }

            if(this.state.data.newPassword.value !== this.state.data.confirmNewPassword.value){
                // alert("Passwords do not match.");
                this.setState({isPasswordMatch:false})
                return false
            }

        this.props.setLoading(true);

         apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/change-password', {
                oldPassword: this.state.data.currentPassword.value,
                newPassword: this.state.data.newPassword.value
            },(data) => {
                this.props.setLoading(false)
                if(data.msg == 'Password Changed Successfully'){
                    clearStorageData()
                    // this.props.setToken(null);
                    window.location.reload();
                }
            },
            (err) => {
                this.props.setLoading(false)
                alert("Something went wrong!");
                console.log(err);
            })
           
            this.setState({...stateObj})
        }     

    render() {
        return (
            <HeaderAndFooterLayout>
                <div className="myAccoutDetailsMain">
                    <div className="myAccoutDetailsCard">
                        <h5 className="myAccoutOptModalHeading card-title text-center w-100">Change Password</h5>
                        <form>
                            <div className="myAccoutOptModal_form_group">
                                <label className="myAccoutOptModalLable">Current Password</label>
                                <div className="myAccoutOptModalLable_input_wrap">
                                    <input type="password" name='currentPassword' data-e2e="currentPassword" className="myAccoutOptModalLable_input" id=""
                                        value={this.state.data.currentPassword.value} placeholder="Current Password" onChange={this.changeHandler}  />

                                </div>
                                { this.state.data.currentPassword.isError &&
                                     <div className=''>
                                       { 
                                        this.state.data.currentPassword.value==='' ? 
                                            <div className='text-danger small position-absolute'>{'This field is mandatory'}</div> 
                                            :
                                            <div className='text-danger small position-absolute'>{this.state.data.currentPassword.errorMsg}</div>
                                        }
                                    </div>
                                    }
                            </div>
                            <div className="myAccoutOptModal_form_group">
                                <label className="myAccoutOptModalLable">New Password</label>
                                <div className="myAccoutOptModalLable_input_wrap">
                                    <input type="password" name='newPassword' data-e2e="newPassword" className="myAccoutOptModalLable_input" id=""
                                         value={this.state.data.newPassword.value} placeholder="New Password" onChange={this.changeHandler}  />
                                   
                                </div>
                                { this.state.data.newPassword.isError &&
                                     <div className=''>
                                       { 
                                        this.state.data.newPassword.value==='' ? 
                                            <div className='text-danger small position-absolute'>{'This field is mandatory'}</div> 
                                            :
                                            <div className='text-danger small position-absolute'>{this.state.data.newPassword.errorMsg}</div>
                                        }
                                    </div>
                                    }
                            </div>
                            <div className="myAccoutOptModal_form_group">
                                <label className="myAccoutOptModalLable">Confirm New Password</label>
                                <div className="myAccoutOptModalLable_input_wrap">
                                    <input type="password" name='confirmNewPassword' data-e2e="confirmNewPassword" className="myAccoutOptModalLable_input" id=""
                                        value={this.state.data.confirmNewPassword.value} placeholder="Confirm New Password" onChange={this.changeHandler}  />
                                  
                                </div>

                                { this.state.data.confirmNewPassword.isError &&
                                     <div className=''>
                                       { 
                                        this.state.data.confirmNewPassword.value==='' ? 
                                            <div className='text-danger small position-absolute'>{'This field is mandatory'}</div> 
                                            :
                                            <div className='text-danger small position-absolute'>{this.state.data.confirmNewPassword.errorMsg}</div>
                                        }
                                    </div>
                                    }
                                { !this.state.isPasswordMatch && <div><div className='text-danger small position-absolute'>{'Passwords do not match!'}</div> </div> }
                                   
                            </div>
                    
                            <div className='d-flex justify-content-center'>
                                <div data-e2e="submit-btn" className="changePassword" onClick={this.submitHandler}>Submit</div>
                            </div>                            
                        </form>
                    </div>
                </div>
            </HeaderAndFooterLayout>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)