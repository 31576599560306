import React from 'react';
import { connect } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import { clearStorageData } from '../../../common/getIP';
import { useParams } from 'react-router-dom';

class NewPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data : {                 
                    newPassword : {
                        value : '',
                        validation : '' ,
                        isError : false,
                        errorMsg : '',
                        isRequired : true
                    },
                    confirmNewPassword : {
                        value : '',
                        validation : '' ,
                        isError : false,
                        errorMsg : '',
                        isRequired : true
                    },
                },
                isPasswordMatch:true,
                email:' ',
                verified: false,
                verifyToken: this.props.params,
        }
    }
    
    componentDidMount(){      
        this.props.setLoading(true);
        const { token } = this.props.params
        this.verifyToken(token)
    }

    verifyToken = (token) => {
        apiService((window.location.hostname === 'localhost' ? this.props.api_url : '') + '/restapi/forgot-password', {
            verifyToken: token,
            hostname: window.location.hostname,
            domainNameUrl: localStorage.getItem("domainNameUrl"),
        },(data) => {
            this.props.setLoading(false)
            console.log(data.success)
            if(data.success){
                this.setState({email: data.email, verified:true})
            }else{
                alert(data);
            }
        },
        (err) => {
            this.props.setLoading(false)
            alert("Something went wrong!");
            console.log(err);
        })
       
    }

    validation = (e) => {

        let {name,value} = e.target
        let stateObj = {...this.state.data}
        let validation = stateObj[name].validation
        let isRequired = stateObj[name].isRequired
        let isError = false
        let RegEx = new RegExp(validation)

        if(isRequired){
            if(value === ''){
                 isError = true
            }else{
                if(validation !== ''){
                    if(!value.match(RegEx)){
                        isError = true
                    }
                }
            }
        }else{
            if(value !== '' & validation !== ''){
                if(!value.match(RegEx)){
                     isError = true
                }
            }
        }

        return stateObj[name].isError = isError
    }

    changeHandler = (e) => {
    
        this.setState({isPasswordMatch:true})
        let {name,value} = e.target
        let stateObj = {...this.state.data}
    
        this.validation(e)
    
        stateObj[name].value = value
    
        this.setState({...stateObj})
    
        }

      submitHandler = (e) => {

            let stateObj = {...this.state.data}
            let isSubmit = true
            
            Object.values(stateObj).map(e  => {
               
                if((e.value === '' && e.isRequired) || e.isError){
                    e.isError = true
                    isSubmit = false
            }
            return this.setState({...stateObj})
            })
            if(!isSubmit){
                this.setState({...stateObj})
                return false    
            }

            if(this.state.data.newPassword.value !== this.state.data.confirmNewPassword.value){
                // alert("Passwords do not match.");
                this.setState({isPasswordMatch:false})
                return false
            }

        this.props.setLoading(true);

         apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/new-password', {
                email: this.state.email,
                newPassword: this.state.data.newPassword.value,
                verifyToken: this.props.params.token,
                domainNameUrl: localStorage.getItem("domainNameUrl"),
            },(data) => {
                this.props.setLoading(false)
                if(data.msg == 'Password Changed Successfully'){
                    clearStorageData()
                    window.location.href = '/loginPopup'
                }
            },
            (err) => {
                this.props.setLoading(false)
                alert("Something went wrong!");
                console.log(err);
            })
           
            this.setState({...stateObj})
        }     

    render() {
        return (
            <HeaderAndFooterLayout>
            { this.state.verified &&
                <div className="myAccoutDetailsMain">
                <div className="myAccoutDetailsCard">
                    <h5 className="myAccoutOptModalHeading card-title text-left mb-1 mt-1" style={{width:'100%'}}>New Password</h5>

                    <div className="form-group-payment">
                                 <label className="project_emailId pt-4" style={{textAlign:'left'}}>{`Enter New password below , password must be 8 characters long with one special character and capital letter.`}</label>
                                 <label className="project_emailId mb-4" style={{textAlign:'left'}}>{`Do not share your password with anyone else to secure your account.`}</label>
                             </div>
                    <form>
                        <div className="myAccoutOptModal_form_group">
                            <label className="myAccoutOptModalLable">New Password</label>
                            <div className="myAccoutOptModalLable_input_wrap">
                                <input type="password" name='newPassword' data-e2e="newPassword" className="myAccoutOptModalLable_input" id=""
                                     value={this.state.data.newPassword.value} placeholder="New Password" onChange={this.changeHandler}  />
                               
                            </div>
                            { this.state.data.newPassword.isError &&
                                 <div className=''>
                                   { 
                                    this.state.data.newPassword.value==='' ? 
                                        <div className='text-danger small position-absolute'>{'This field is mandatory'}</div> 
                                        :
                                        <div className='text-danger small position-absolute'>{this.state.data.newPassword.errorMsg}</div>
                                    }
                                </div>
                                }
                        </div>
                        <div className="myAccoutOptModal_form_group">
                            <label className="myAccoutOptModalLable">Confirm New Password</label>
                            <div className="myAccoutOptModalLable_input_wrap">
                                <input type="password" name='confirmNewPassword' data-e2e="confirmNewPassword" className="myAccoutOptModalLable_input" id=""
                                    value={this.state.data.confirmNewPassword.value} placeholder="Confirm New Password" onChange={this.changeHandler}  />
                              
                            </div>

                            { this.state.data.confirmNewPassword.isError &&
                                 <div className=''>
                                   { 
                                    this.state.data.confirmNewPassword.value==='' ? 
                                        <div className='text-danger small position-absolute'>{'This field is mandatory'}</div> 
                                        :
                                        <div className='text-danger small position-absolute'>{this.state.data.confirmNewPassword.errorMsg}</div>
                                    }
                                </div>
                                }
                            { !this.state.isPasswordMatch && <div><div className='text-danger small position-absolute'>{'Passwords do not match!'}</div> </div> }
                               
                        </div>
                
                        <div className='d-flex justify-content-center'>
                            <div data-e2e="submit-btn" className="changePassword" onClick={this.submitHandler}>Submit</div>
                        </div>                            
                    </form>
                </div>
            </div>
    }
        </HeaderAndFooterLayout>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)((props)=> <NewPassword {...props} params={useParams()} />)