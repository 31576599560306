import React, { useEffect } from 'react'
import KalonPayHeader from './global/KalonPayHeader';

const KalonpayLayout = (props) => {
    useEffect(() => {
        document.body.classList.add('kalonpay-theme');
        return () => {
            document.body.classList.remove('kalonpay-theme');
        };
    });
    return (
        <div className='kalonpay-theme'>
            <div className='kalonpay-container'>
                <KalonPayHeader />
                <div className='kalonpay-main'>
                    <div className='left-side'>
                        <div style={{ width: "100%" }}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KalonpayLayout