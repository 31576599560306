import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';

const RedirectToUrl = (props) => {
    useEffect(() => {
        const redirectUrlParam = queryString?.parse(props?.location?.search)?.redirectUrlParam
        if (redirectUrlParam) {
            getRedirectUrl(redirectUrlParam)
        }
    }, [])

   const getRedirectUrl = async (redirectUrl) => {
        setTimeout(()=>{
            window.location.href = redirectUrl
        },300)
   };
   
   return (
      <div >
         <div className='loading_pay_main row' >
            <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
         </div>
      </div>
   );
}

export default (props) => <RedirectToUrl {...props} location={useLocation()} />;