import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import { clearStorageData } from '../../../common/getIP';
import * as actions from '../../../Redux/actions';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
import { useNavigate } from 'react-router-dom';
import { set2faActivationStatus } from '../../../Redux/actions';

function ChangePassword(props) {
  const api_url = useSelector((state) => state.config.api_url);
  const [visibility, setVisibility] = useState(true);
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  let disabled = Object.values(data).some((values) => values.trim() === '' || values.length === 0);

  useEffect(()=>{
    if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
  }, [])

  const handleChangePassword = () => {
    if (data.confirmPassword !== data.newPassword) {
      return alert(`Password doesn't matched`);
    }
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-user-change-email-or-password',
      {
        email: localStorage.getItem("user_name"),
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        paymentProviderName:sessionStorage.getItem("paymentProviderName"),
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (data) => {
        // setLoading(false)
        if (data.msg == 'Password Changed Successfully') {
          props.handleAlertData('Password has been changed!')
          props.handleOpenAuthenticateModel()
          props.onClose()
          setTimeout(() => {
            const colorMode = sessionStorage.getItem("colorMode");
            const b2cColorMode = sessionStorage.getItem("b2cColorMode");
            const depositMerchantId = localStorage.getItem("depositMerchantId");
            const depositMethod = localStorage.getItem("depositMethod");
            const transactionId = localStorage.getItem("transactionId");
            const lightFlag = sessionStorage.getItem("lightFlag");
            const activity_tracking_token = localStorage.getItem("activity_tracking_token");
            const activity_tracking_api_url = localStorage.getItem("activity_tracking_api_url");
            const api_url = localStorage.getItem("api_url");
            props.setToken(null);
            clearStorageData();
            localStorage.setItem("reloadedOnce", "Yes");
            sessionStorage.setItem("colorMode", colorMode);
            sessionStorage.setItem("b2cColorMode", b2cColorMode);
            localStorage.setItem("depositMerchantId", depositMerchantId);
            localStorage.setItem("depositMethod", depositMethod);
            localStorage.setItem("transactionId", transactionId);
            sessionStorage.setItem("lightFlag", lightFlag);
            localStorage.setItem("activity_tracking_token", activity_tracking_token);
            localStorage.setItem("activity_tracking_api_url", activity_tracking_api_url);
            localStorage.setItem("api_url", api_url);
            set2faActivationStatus(false);
            navigate('/home-b2c');
           }, 3000);
        }
      },
      (err) => {
        // setLoading(false)
        // setError('Error');
        alert('Something went wrong!');
        console.log(err);
      },
    );
  };

  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog onClose={()=>props.onClose()} open={props.open} className="b2c-signup-model">
        <div className="change-password-modal-header">
          <div className="d-flex justify-content-between ">
            <div className="change-password-heading">Change Password</div>
            <div className="signup-close-icon" data-e2e="close-icon">
              <ClearIcon onClick={()=>props.onClose()} className="icon-close" />
            </div>
          </div>
        </div>
        <div className="p-4 mobile-height">
          <div>
            <div className="change-Password-input-label">Current Password</div>
            <div className="mt-3">
              <input
                type="password"
                className="change-password-input"
                placeholder="Enter current password..."
                data-e2e="current-password"
                name="oldPassword"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div>
            <div className="change-Password-input-label mt-4">New Password</div>
            <div className="mt-3">
              <input
                type={visibility ? 'password' : 'text'}
                className="change-password-input"
                placeholder="Enter new password..."
                name="newPassword"
                onChange={(e) => handleChange(e)}
                data-e2e="new-password"
              />
              <div className="visibility-icon" onClick={() => setVisibility(!visibility)}>
                {visibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            </div>
          </div>

          <div>
            <div className="change-Password-input-label mt-4">Confirm New Password</div>
            <div className="mt-3">
              <input
                type="password"
                className="change-password-input"
                placeholder=" Confirm new password..."
                name="confirmPassword"
                onChange={(e) => handleChange(e)}
                data-e2e="confirm-password"
              />
            </div>
          </div>
          <div className="mt-4">
            <button
            disabled={disabled}
              className={`${disabled ? 'change-password-disabled-button' : 'change-password-enabled-button'} mt-2 `}
              onClick={() => handleChangePassword()}
              style={{ ...headerButtonStyle }}
              data-e2e="change-btn"
            >
              CHANGE
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setToken: (data) => dispatch(actions.setToken(data)),
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
