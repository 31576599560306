import * as actionTypes from './actionTypes'

export const setTransaction = (data) => {
    return {
        type: actionTypes.SET_TRANSACTION,
        data
    }
}
export const setCardAPIType = (data) => {
    return {
        type: actionTypes.SET_CARD_API_TYPE,
        data
    }
}