import React, { useEffect, useState } from 'react'
import { apiService } from './apiCallService';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { removeHttp } from './getIP';
import MaintainceMode from './MaintainceMode';

//save to session storage
export const saveToSessionStorage = (data) => {
  sessionStorage.setItem("domainId", data?.domainId ? data?.domainId : null);
  localStorage.setItem("domainNameUrl", data?.domainNameUrl ? data?.domainNameUrl : null);
  sessionStorage.setItem("paymentProviderId", data?.paymentProviderId ? data?.paymentProviderId : null);
  sessionStorage.setItem("paymentProviderName", data?.paymentProviderName ? data?.paymentProviderName : null);
  sessionStorage.setItem("font", data?.font);
  sessionStorage.setItem("homeMerchantPath", data?.homeMerchantPath)
  sessionStorage.setItem("homeB2CPath", data?.homeB2CPath)
  sessionStorage.setItem("defaultTheme", data?.defaultTheme);
  if (sessionStorage.getItem("colorMode") === "default") {
    sessionStorage.setItem("colorMode", data?.defaultTheme === "Dark Theme" ? "dark" : "light");
  }
  sessionStorage.setItem("defaultB2CTheme", data?.defaultB2CTheme);
  if (sessionStorage.getItem("b2cColorMode") === "default") {
    sessionStorage.setItem("b2cColorMode", ["Dark Theme", "Light Theme"].includes(data?.defaultB2CTheme) ? (data?.defaultB2CTheme === "Dark Theme" ? "dark" : "light") : (data?.defaultTheme === "Dark Theme" ? "dark" : "light"));
  }
  sessionStorage.setItem("headerLightBgColour", data?.headerLightBgColour);
  sessionStorage.setItem("headerDarkBgColour", data?.headerDarkBgColour);
  sessionStorage.setItem("headerButtonLightBgColour", data?.headerButtonLightBgColour);
  sessionStorage.setItem("headerButtonDarkBgColour", data?.headerButtonDarkBgColour);
  sessionStorage.setItem("headerLightTextColour", data?.headerLightTextColour);
  sessionStorage.setItem("headerDarkTextColour", data?.headerDarkTextColour);
  sessionStorage.setItem("headerLightLinkColour", data?.headerLightLinkColour);
  sessionStorage.setItem("headerDarkLinkColour", data?.headerDarkLinkColour);
  sessionStorage.setItem("themeToggleButton", data?.themeToggleButton);
  sessionStorage.setItem("bodyLightTextColour", data?.bodyLightTextColour);
  sessionStorage.setItem("bodyDarkTextColour", data?.bodyDarkTextColour);
  sessionStorage.setItem("logoWidth", data?.logoWidth);
  sessionStorage.setItem("bannerText", data?.bannerText)
  sessionStorage.setItem("bannerTextDark", data?.bannerTextDark)
}

const LoadingAndRedirection = (props) => {
  const [showFullPageLoading, setShowFullPageLoading] = useState(true)
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  const [loadingText, setLoadingText] = useState("loading...")
  const navigate = useNavigate()
  const api_url = useSelector((state) => state.config.api_url)
 
  const redirectBasedOnConditions = (data) => {
    const token = localStorage.getItem('token');
    const loginType = localStorage.getItem('loginType');
    const pathname = window.location.pathname;
    const defaultLandingPage = data.defaultLandingPage;

    const redirectToPath = (path) => {
      navigate(`/${path}`);
    };

    if (!token && pathname === "/") {
      const enabledConditions = [{
        condition: data.homeB2C === "Enabled",
        path: data.homeB2CPath,
        redirectPath: "home-b2c"
      },
      {
        condition: data.homeMerchant === "Enabled",
        path: data.homeMerchantPath,
        redirectPath: "home-merchant"
      },
      {
        condition: data.homeClient === "Enabled",
        path: data.homeClientPath,
        redirectPath: "home-client"
      }
      ];

      for (const condition of enabledConditions) {
        if ((defaultLandingPage === condition.redirectPath || defaultLandingPage === condition.path) && condition.condition) {
          if (condition.path) {
            navigate(`/${condition.path}`);
          } else {
            navigate(`/${condition.redirectPath}`);
          }

          return;
        }
      }
      navigate(`/${defaultLandingPage.replace("/", "")}`);
    } else if (token) {
      const pathMap = {
        Merchant: data.homeMerchantPath ? data.homeMerchantPath : 'home-merchant',
        B2C: data.homeB2CPath ? data.homeB2CPath : 'home-b2c',
      };
      const path = pathMap[loginType] || defaultLandingPage.replace('/', '');
      if (pathname === path) {
        return redirectToPath(path);
      } else if (pathname === '/') {
        return redirectToPath(path);
      }
    } else {
      const enabledConditions = [{
        key: "homeB2C",
        path: data.homeB2CPath,
        redirectPath: "home-b2c",
        isEnabled: data.homeB2C === "Enabled"
      },
      {
        key: "homeMerchant",
        path: data.homeMerchantPath,
        redirectPath: "home-merchant",
        isEnabled: data.homeMerchant === "Enabled"
      },
      {
        key: "homeClient",
        path: data.homeClientPath,
        redirectPath: "home-client",
        isEnabled: data.homeClient === "Enabled"
      }
      ];

      // Sort the conditions by priority: Enabled first, then check if there's a default page
      enabledConditions.sort((a, b) => {
        if (data[a.key] === "Enabled" && data[b.key] !== "Enabled") return 1;
        if (data[a.key] !== "Enabled" && data[b.key] === "Enabled") return -1;
        return 0;
      });

      for (const condition of enabledConditions) {
        if ((pathname === `/${condition.redirectPath}` || pathname === `/${condition.path}`) && condition.path !== null && condition && condition.isEnabled) {
          navigate(`/${condition.path}`);
          return;
        } else if ((pathname === `/${condition.redirectPath}` || pathname === `/${condition.path}`) && condition && condition.isEnabled) {
          navigate(`/${condition.redirectPath}`);
          return;
        } else if ((pathname === `/${condition.redirectPath}` || pathname === `/${condition.path}`)) {
          navigate(`/${defaultLandingPage.replace("/", "")}`);
        } else if ((pathname.includes('home-b2c') && condition.key === "homeB2C" && !condition.isEnabled) || (pathname.includes('home-merchant') && condition.key === "homeMerchant" && !condition.isEnabled) || (pathname.includes('home-client') && condition.key === "homeClient" && !condition.isEnabled)) {
          navigate(`/${defaultLandingPage.replace("/", "")}`);
        }
      }
    }

  }
  const getPaymentProviderName = async () => {
    try {
      await apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/get-payment-provider-for-domain', {
        redirectUrl: removeHttp(window.location.hostname)
      }, (data) => {
        saveToSessionStorage(data)
        if (data.paymentProviderName && data.paymentProviderName !== "" && data.paymentProviderName !== "null") {
          setPaymentProviderName(data.paymentProviderName);
        }
        redirectBasedOnConditions(data)
        setShowFullPageLoading(false)
      }, (err) => {
        setLoadingText("Website is under maintenance. Please try again after some time")
        console.log(err);
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getPaymentProvider = async () => {
    getPaymentProviderName();
  }
  useEffect(() => {
    getPaymentProvider()
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
    }
  }, [])
  return (
    <div>
      {showFullPageLoading &&
        <div>
          {loadingText === "loading..." ?
            <div className="fullPageLoading">
              {loadingText}
            </div> :
            <MaintainceMode />
          }
        </div>
      }
    </div>
  )
}

export default LoadingAndRedirection