import * as actionTypes from '../actions/actionTypes';


const initialState = {
  loading: false,
  token: null,
  faqQuestionData:{},
  theme: 'dark',
  b2cTheme: 'dark',
  is2faActivated: false,
  toDate:'',
  fromDate:'',
  selectedTypes:[],
  selectedStatus:[],
  selectedCryptos:[],
  selectedPaymentSettings:[],
  walletPageTableName:'balance',
  kycCheckToBuyCrypto: false,
  openKYCVerificationModal: false,
};
const setWalletPageTableName = (state, action) => {
  return {
    ...state,
    walletPageTableName: action.data,
  };
};
const setFromDate = (state, action) => {
  return {
    ...state,
    fromDate: action.data,
  };
};
const setToDate = (state, action) => {
  return {
    ...state,
    toDate: action.data,
  };
};
const setSelectedTypes = (state, action) => {
  return {
    ...state,
    selectedTypes: action.data,
  };
};
const setSelectedStatus = (state, action) => {
  return {
    ...state,
    selectedStatus: action.data,
  };
};
const setSelectedCryptos = (state, action) => {
  return {
    ...state,
    selectedCryptos: action.data,
  };
};
const setSelectedPaymentSettings = (state, action) => {
  return {
    ...state,
    selectedPaymentSettings: action.data,
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.data,
  };
};
const checkBuyCryptoStatus = (state, action) => {
  return {
    ...state,
    kycCheckToBuyCrypto: action.data,
  };
};
const setOpenKYCVerificationModal = (state, action) => {
  return {
    ...state,
    openKYCVerificationModal: action.data,
  };
};
const set2faActivationStatus = (state, action) => {
  return {
    ...state,
    is2faActivated: action.data,
  };
};

const setFaqQuestionData = (state, action) => {
  return {
      ...state,
      faqQuestionData: action.data,
  }
}

const setToken = (state, action) => {
  return {
    ...state,
    token: action.data,
  };
};

const setAndGetTheme = (state, action) => {
  return {
    ...state,
    theme: action.data,
  };
};

const setAndGetB2CTheme = (state, action) => {
  return {
    ...state,
    b2cTheme: action.data,
  };
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_2FA_ACTIVATION_STATUS:
      return set2faActivationStatus(state, action);
    case actionTypes.SET_TOKEN:
      return setToken(state, action);
    case actionTypes.SET_AND_GET_THEME:
      return setAndGetTheme(state, action);
    case actionTypes.SET_AND_GET_B2C_THEME:
      return setAndGetB2CTheme(state, action);
    case actionTypes.SET_FROM_DATE:
      return setFromDate(state, action);
    case actionTypes.SET_TO_DATE:
      return setToDate(state, action);
    case actionTypes.SELECTED_TYPES:
        return setSelectedTypes(state, action);
    case actionTypes.SELECTED_STATUS:
      return setSelectedStatus(state, action);
    case actionTypes.SELECTED_CRYPTOS:
      return setSelectedCryptos(state, action);
    case actionTypes.SELECTED_PAYMENT_SETTINGS:
      return setSelectedPaymentSettings(state, action);
    case actionTypes.SET_AND_GET_TABLE:
      return setWalletPageTableName(state, action);
    case actionTypes.CHECK_KYC_STATUS_BUY_CRYPTO:
      return checkBuyCryptoStatus(state, action)
    case actionTypes.SET_OPEN_KYC_MODAL:
      return setOpenKYCVerificationModal(state, action)
    case actionTypes.SET_FAQ_QUESTION_DATA: 
      return setFaqQuestionData(state, action);
    default:
      return state;
  }
};

export default commonReducer;
