import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { checkValueInSS, getHeaderButtonStyle } from "../../../../common/theme";


export default function GetStarted(props) {
	const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	const image_base_url = useSelector((state) => state.config.image_base_url);
	const token = useSelector((state) => state.common.token);
	const { handleClickOpenSignUpModel } = props;
	useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])

	let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)
	return (
		<section className="section getStarted">
			<div className="content-container">
				{/* <AnimationOnScroll animateIn="animate__fadeInDown">
					<div className="section-upperTitle">
						<p>Easy to start</p>
					</div>
				</AnimationOnScroll> */}
				<AnimationOnScroll animateIn="animate__fadeInUp">
					<p className="section-title mb">Get started in minutes</p>
				</AnimationOnScroll>
				<div className="getStarted__grid">
					<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
						<div className="getStarted__block">
							<p className="getStarted__num">1</p>
							<p className="getStarted__title">Create an account</p>
							<p className="section-text t-center">Sign up and begin your account registration.</p>
							<img width="407" height="396" className="getStarted__card" src={`${image_base_url}/upd/get-started-1.webp`} />
						</div>
					</AnimationOnScroll>
					<AnimationOnScroll delay="400" animateIn="animate__fadeIn">
						<div className="getStarted__block">
							<p className="getStarted__num">2</p>
							<p className="getStarted__title">Verify your account</p>
							<p className="section-text t-center">Upload your ID, proof of address and take a selfie. </p>
							<img width="407" height="396" className="getStarted__card" src={`${image_base_url}/upd/verifyAccountNew.png`} />
						</div>
					</AnimationOnScroll>
					<AnimationOnScroll delay="600" animateIn="animate__fadeIn">
						<div className="getStarted__block">
							<p className="getStarted__num">3</p>
							<p className="getStarted__title">Buy with card</p>
							<p className="section-text t-center">Use your credit or debit card to buy your favourite crypto.</p>
							<img width="407" height="396" className="getStarted__card" src={`${image_base_url}/upd/get-started-3.webp`} />
						</div>
					</AnimationOnScroll>
				</div>
				{!token && (<div className="theme-btn-block center full-on-mobile">
					<button type="button" onClick={handleClickOpenSignUpModel} className="theme-btn" style={{ ...headerButtonStyle }}>
						<p>Sign up now</p>
					</button>
				</div>)}
			</div>
		</section>
	)
}