import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

export default function PortFoilioDonoutChart(props) {
  
  const options = {
    responsive: true,
    cutout: props.cutout,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      usePointStyle: 'true',
      labels: {
        fontSize: 12,
        padding: 25,
        fontColor: '#6D7278',
        fontFamily: 'kanit light',
      },  
    },
    plugins:{   
      legend: {
        display: false
      },
    },     
  };

  return <Doughnut data={props.balanceData} options={options} height={props.chartHeight} width={props.chartHeight}/>;
}
