import React from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { apiService } from "../../../common/apiCallService";
import { removeHttp } from "../../../common/getIP";
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';
const logo = "/common/logo_samll.svg"

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user_name: "",
            password: "",
            goToHome: 0,
            alertMsg: null,
            passwordChangedSuccessful: false,
            paymentProviderName: null,
            paymentProviderId: null,
            domainId: null,
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
            logoWidth:150
        }
    }

    componentDidMount() {
        this.setState({ passwordChangedSuccessful: this.props.passwordChangedSuccessful })
        if (checkValueInSS("paymentProviderName")) {
            this.setState({ paymentProviderName: sessionStorage.getItem("paymentProviderName") });
        }
        if (checkValueInSS("domainId")) {
            this.setState({ domainId: sessionStorage.getItem("domainId") });
        }
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
        if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== "" && sessionStorage.getItem("paymentProviderId") !== "null") {
            this.setState({ paymentProviderId: sessionStorage.getItem("paymentProviderId") });
        } 
        if (checkValueInSS("logoWidth")) {
            this.setState({ logoWidth: sessionStorage.getItem("logoWidth") });
        }
    }
    getPaymentProviderDetails = async () => {
		await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-payment-provider-for-domain', {
		  redirectUrl: removeHttp(window.location.hostname)
		}, (data) => {
		  sessionStorage.setItem("domainId", data.domainId ? data.domainId : null);
		  sessionStorage.setItem("paymentProviderId", data.paymentProviderId ? data.paymentProviderId : null);
		  sessionStorage.setItem("paymentProviderName", data.paymentProviderName ? data.paymentProviderName : null);
		  sessionStorage.setItem("font", data.font);
		  sessionStorage.setItem("defaultTheme", data.defaultTheme);
		  if (sessionStorage.getItem("colorMode") === "default") {
			sessionStorage.setItem("colorMode", data.defaultTheme === "Dark Theme" ? "dark" : "light");
		  }
          sessionStorage.setItem("defaultB2CTheme", data.defaultB2CTheme);
          if (sessionStorage.getItem("b2cColorMode") === "default") {
            sessionStorage.setItem("b2cColorMode",["Dark Theme", "Light Theme"].includes(data.defaultB2CTheme) ? (data.defaultB2CTheme === "Dark Theme" ? "dark" : "light") : (data.defaultTheme === "Dark Theme" ? "dark" : "light"));
          }
		  sessionStorage.setItem("headerLightBgColour", data.headerLightBgColour);
		  sessionStorage.setItem("headerDarkBgColour", data.headerDarkBgColour);
		  sessionStorage.setItem("headerButtonLightBgColour", data.headerButtonLightBgColour);
		  sessionStorage.setItem("headerButtonDarkBgColour", data.headerButtonDarkBgColour);
		  sessionStorage.setItem("headerLightTextColour", data.headerLightTextColour);
		  sessionStorage.setItem("headerDarkTextColour", data.headerDarkTextColour);
		  sessionStorage.setItem("headerLightLinkColour", data.headerLightLinkColour);
		  sessionStorage.setItem("headerDarkLinkColour", data.headerDarkLinkColour);
		  sessionStorage.setItem("themeToggleButton", data.themeToggleButton);
		  sessionStorage.setItem("bodyLightTextColour", data.bodyLightTextColour);
		  sessionStorage.setItem("bodyDarkTextColour", data.bodyDarkTextColour);
		  sessionStorage.setItem("logoWidth", data.logoWidth);
		}, (err) => {
		  console.log(err);
		})
	  }
    onLogin = () => {
        this.setState({ alertMsg: null })
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/merchant-login', {
            user_name: this.state.user_name,
            password: this.state.password,
            domainNameUrl: window.location.hostname,
        }, async (data) => {
            this.props.setLoading(false);
		    await this.getPaymentProviderDetails();
            localStorage.setItem("domainNameUrl", data.domainNameUrl ? data.domainNameUrl : null );
            localStorage.setItem("2FAStatus", data.is2faActivated && data.is2faActivated.enabled ? data.is2faActivated.enabled : false);
            if(data.is2faActivated && data.is2faActivated.enabled === false){
                this.props.onVerificationSucess(data, this.state.user_name);
            }else if (data.is2faActivated && data.is2faActivated.enabled === true && data.is2faActivated.secret != null) {
                this.props.closeModal();
                this.props.setVerifyModal(data, this.state.user_name);
            } else if (data.is2faActivated && data.is2faActivated.enabled === true && data.is2faActivated.secret == null) {
                this.props.closeModal();
                this.props.setAuthenticatorModal(data, this.state.user_name);
            }
        }, (err) => {
            this.props.setLoading(false)
            this.setState({ alertMsg: "Invalid username/password" })
            console.log(err);
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
    }

    render() {
        let headerButtonBorderStyle = getHeaderButtonBorderStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerButtonLightBgColour)
        let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)

			return (
				<div>
					<Dialog className="theme-modal" onClose={() => this.props.closeModal()} open={this.props.show}>
						<div className="theme-modal-body">
							<div className="theme-modal-close">
								<ClearIcon onClick={() => this.props.closeModal()} className="icon-close" />
							</div>
							<div className="w-100 ">
                                <img src={`${this.props.image_base_url}${this.state.paymentProviderId && this.state.paymentProviderId !== "" && this.state.paymentProviderId !== "null" ? `/payment-provider/${this.state.paymentProviderId}${this.state.domainId ? ("/" + this.state.domainId) : ""}/logo${this.props.theme === 'light' ? "" : "dark"}.png` : logo}`} alt="crypton-logo-dark-mode"
                                 width={this.state.logoWidth} 
                                 className='m-auto pb-2'
                                 />
							</div>
							<form>
                                <p className="theme-modal-title">Log In to {this.state.paymentProviderName ? this.state.paymentProviderName : sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"}</p>
								{this.state.alertMsg &&
									<div className="alert alert-danger" role="alert">
											{this.state.alertMsg} 
									</div>}
								{this.state.passwordChangedSuccessful &&
									<div className="alert alert-success" role="success">
										Password has been changed!
									</div>
								}
								<div className="theme-modal-field mb">
									<p className="theme-modal-field-label">Email</p>
									<input type="email" className="theme-modal-field-input" data-e2e="userName"
												value={this.state.user_name} placeholder="Email Address" onChange={this.handleInputChange} name="user_name" />
								</div>
								<div className="theme-modal-field">
									<p className="theme-modal-field-label">Password</p>
									<input type="password" className="theme-modal-field-input" data-e2e="passWord"
												value={this.state.password} placeholder="Password" onChange={this.handleInputChange} name="password" />
								</div>
								<div className="theme-btn-block">
                                    <div className="theme-modal-forgot" data-e2e="forget-password" onClick={() => { this.props.changeModalType("forgotpassword") }} style={{ ...headerButtonBorderStyle }}>Forgot Password?</div>
								</div>
								<div className="theme-btn-block full-width theme-modal-submit">
                                    <div data-e2e="continue" className="theme-btn" style={{ ...headerButtonStyle }} onClick={() => { this.onLogin() }}> <p>Log In</p></div>
								</div>
								<div className="theme-modal-account">
									Don't have an account?{' '}
									<button type="button" className="theme-modal-account-btn" onClick={() => {this.props.changeModalType("signup")}} style={{ ...headerButtonBorderStyle }} >Sign Up</button>
								</div>
							</form>
						</div>
					</Dialog>
				</div>
			)
    }

}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
        theme: state.common.theme,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: (data) => dispatch(actions.setToken(data)),
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);