import React from 'react';
import SideToolsMenu from './SideToolsMenu';
import SideToolsMobileMenu from './SideToolsMobileMenu';
import { useNavigate } from 'react-router-dom';

const SideToolsMenuLayout = (props) => {
    return (
        <div className="sideToolsMenuLayout">
            <SideToolsMobileMenu {...props} />
            <SideToolsMenu {...props} navigate={useNavigate()} />
            {props.children}
        </div>
    )
}

export default SideToolsMenuLayout;