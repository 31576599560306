import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";

export default function PaysuDo() {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section">
			<div className="content-container">
				<div className="ps-section-title-line"></div>
				<div className="ps-section-grid no-gap-mobile">
					<AnimationOnScroll animateIn="animate__fadeIn">
						<p className="ps-section-title mb">What We Do?</p>
					</AnimationOnScroll>
					<div>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">At PaySu, we’re focused on empowering businesses in using a mix of crypto and traditional fiat payments to support their sales, marketing and growth objectives. We work with our clients to maximise opportunities. Our services cover the full spectrum, from integrating our payment completion hub to providing consultancy and payments development planning. We get you to market fast, with payments tools that cover all aspects of today’s payments sphere.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							<p className="ps-section-text">We provide tailored support that is designed to meet the unique needs of your business. Our innovative and technology-focused solutions will help you stay ahead of the competition and achieve your goals.</p>
						</AnimationOnScroll>
					</div>
				</div>
				<img className="paysu-fullImage" src={`${image_base_url}/paysu/ps-we-do.webp`} />
			</div>
		</section>
	)
}