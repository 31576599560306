import React, { useEffect, useState } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';
import CodeCard from './CodeCard';
import SingleParameter from './SingleParameter';

const parameters = [
    {
        name: "200 - OK",
        description: "Everything worked as expected"
    },
    {
        name: "400 - Bad Request",
        description: "Request may have a missing parameter or be badly configured"
    },
    {
        name: "401 - Unauthorised",
        description: "Invalid Authentication"
    },
    {
        name: "402 - Request Failed",
        description: "The request failed but was valid"
    },
    {
        name: "403 - Forbidden",
        description: "The API key doesn’t have permission to perform that request"
    },
    {
        name: "404 - Not Found",
        description: "The resource does not exist"
    },
]
const tabSpace = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
const request = {
    type: "Request",
    method: "Post",
    url: ".../pga/CreatePayment",
    code: <div>{`{`}<br />
        {tabSpace}{`"merchantTransactionid": "string",`}<br />
        {tabSpace}{`"merchantUserId": "string",`}<br />
        {tabSpace}{`"merchantId": "string",`}<br />
        {tabSpace}{`"amount": "number",`}<br />
        {tabSpace}{`"currency": "string",`}<br />
        {tabSpace}{`"brand": "string",`}<br />
        {tabSpace}{`"useBrand": "boolean",`}<br />
        {`}`}</div>,
}
const codeDetails = [
    {
        code: "general.not.authorized",
        description: "Not authorized for the given operation",
    },
    {
        code: "auth.bad.credentials",
        description: "Bad credentials",
    },
    {
        code: "transaction.invalid",
        description: "Invalid transaction",
    },
    {
        code: "merchant.not.found",
        description: "Merchant not found",
    },
    {
        code: "transaction.id.exist",
        description: "Merchant transaction id not found",
    },
    {
        code: "transaction.expired",
        description: "The session has expired",
    },
    {
        code: "invalid.amount",
        description: "Invalid amount",
    },
    {
        code: "invalid.transfer.status",
        description: "Invalid transfer status",
    },
    {
        code: "invalid.transfer.id",
        description: "Invalid transfer id",
    },
    {
        code: "currency.not.supported",
        description: "The currency sent is not a supported one.",
    },
];

const ErrorHandling = (props) => {
    const [paymentProviderName, setPaymentProviderName] = useState(null)

    useEffect(() => {
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
        }
    }, []);

    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <div className="tool_main">
                        <div className="tool_heading" data-e2e="Error Handling">Error Handling</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    {`${paymentProviderName ? paymentProviderName : "Crypton Pay"} users conventional HTTP codes to indicate the success or failure of an API request`}
                                </div>
                                {parameters.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <div className="tools_content_text">
                                    After successful payment merchant gets a callback to a configured URL when the transaction is finalised.
                                </div>
                                <CodeCard data={request} marginTopZero={true} />
                            </div>
                        </div>
                        <div style={{ marginTop: "82px"}}>
                        <CodeDetails data={{code: "Code", description: "Description"}} heading={true}/>
                        </div>
                        {codeDetails.map((data, i) => {
                            return (<CodeDetails key={i} data={data} />)
                        })}
                </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout >
    )
}

export default ErrorHandling

const CodeDetails = (props) => {
    const { code, description } = props.data;
    return (
        <div className="tools_EH_tableWrap">
            <div className={`codeText ${props.heading ? "codeTextHeading" : ""}`}>
                {code}
            </div>
            <div className={`codeText ${props.heading ? "codeTextHeading" : ""}`}>
                {description}
            </div>
        </div>
    )
}