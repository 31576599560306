import React, { useState } from 'react';
import ScrollIntoView from '../../../common/scrollIntoView';
import SignUp from '../B2C/SignUp';
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../common/constants';

const B2CSignUp = () => {
  const navigate = useNavigate();
  const [signUpModal, setSignUpModal] = useState(true);
  const [loginModal, setLoginModal] = useState(false)

  const redirectToLogin = () => {
    navigate(constants.B2C_LOGIN_PATH);
  };
  const handleClose = () => {
    setSignUpModal(false);
    navigate('/');
  };
  return (
    <ScrollIntoView>
        <SignUp open={signUpModal} setLoginModel={()=>setLoginModal(false)} onClose={handleClose} handleLoginModelOpen={redirectToLogin} />
    </ScrollIntoView>
  );
};

export default B2CSignUp;
