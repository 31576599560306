export const getCryptoIcon = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return '/common/crytoicons/bitcoin1.png';
      case 'ETH':
        return '/common/crytoicons/eth1.png';
      case 'USDT':
      case 'TRC20-USDT':
        return '/common/usdt.png';
      case 'DAI':
        return '/common/crytoicons/dai.png';
      case 'USDC':
        return '/common/crytoicons/usd.png';
      case 'LTC':
        return '/common/crytoicons/ltc.png';
      case 'XRP':
        return '/common/crytoicons/xrp.png';
      case 'TRX':
        return '/common/crytoicons/trx.png';
      default:
        return '';
    }
  };