import React from 'react';
import { useSelector } from 'react-redux';
import { constants } from '../../../../common/constants';

const PaymentSummaryForCreditCardBuy = (props) => {
  const coinSpiritHostName = useSelector((state) => state.config.COIN_SPIRIT_HOST_NAME);
  const coinSpiritMailSupport = useSelector((state) => state.config.COIN_SPIRIT_MAIL_SUPPORT);
  const fromCurrencySymbolFromProps = (props && props.cryptoId && props.cryptoId) || (props && props.cryptosWithExchangeRates && props.cryptosWithExchangeRates[0] && props.cryptosWithExchangeRates[0].symbol && props.cryptosWithExchangeRates[0].symbol) || constants.USDT_CRYPTO_ID;
  const fiatCurrencyFromProps = (props && props.fiatCurrency && props.fiatCurrency) || constants.USD_CURRENCY;
  const cryptocurrencyAmountFromProps = (props && props.cryptoAmount && props.cryptoAmount) || (props && props.cryptosWithExchangeRates && props.cryptosWithExchangeRates[0] && props.cryptosWithExchangeRates[0].cryptocurrencyAmount && props.cryptosWithExchangeRates[0].cryptocurrencyAmount) || 0;
  const fiatAmountFromProps = (props && props.fiatAmount && props.fiatAmount) || 0;
  const fiatCurrencySymbolFromProps = (props && props.fiatCurrencySymbol && props.fiatCurrencySymbol) || constants.USD_SYMBOL;
  
  const checkCoinsSpirit = () => {
    return (window && window.location && window.location.hostname && window.location.hostname.includes(coinSpiritHostName)) || false;
  };
  if (checkCoinsSpirit()) {
    return (
      <div className="creditCardPaymentSummaryContainer">
        <div className="creditCardPaymentSummaryDetails">
          <div className="creditCardPaymentTitle">Payment summary</div>
          <div className="paymentSummarySubText paymentSummaryCryptoContainer">
            Crypto purchase:{' '}
            <strong className={`inputField ${props.lightFlag ? 'text_color_black' : 'text_color_white'}`}>
              {cryptocurrencyAmountFromProps}
              {fromCurrencySymbolFromProps}
            </strong>
          </div>
          <div className="divider mt-5 mb-5"></div>
          <p className="paymentSummarySubText">
            Fx Rate{' '}
            <strong className={`inputField ${props.lightFlag ? 'text_color_black' : 'text_color_white'}`}>
              1 {fromCurrencySymbolFromProps} = {(Math.round((cryptocurrencyAmountFromProps / fiatAmountFromProps) * 100000000) / 100000000).toFixed(8)}{' '}
              {fiatCurrencyFromProps}
            </strong>
          </p>
          <div className="divider mt-5 mb-5"></div>
          <p className="paymentSummarySubText">
            Total:{' '}
            <strong className={`inputField ${props.lightFlag ? 'text_color_black' : 'text_color_white'}`}>
              {cryptocurrencyAmountFromProps}
              {fromCurrencySymbolFromProps}({fiatAmountFromProps}
              {fiatCurrencySymbolFromProps})
            </strong>
          </p>
          <div className='mt-50'>
          <p className='mt-50'>
            <div className="creditCardPaymentTitle mt-50">Merchant Information</div>
            <div className="creditCardPaymentMerchantInfo">{constants.COIN_SPIRIT_FOOTER_ADDRESS}</div>
            <div className="mt-50 mb-20">{`The Charge will appear on your statement as ${coinSpiritHostName}.com / Warsaw`}</div>
          </p>
          </div>
        </div>
        <div className="creditCardFooterImageDesk">
          <p>{`Contact support on ${coinSpiritMailSupport}`}</p>
          <p className="footer__copyright__images">
            <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode.png`} alt="" width="96" height="54" />
            <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode1.png`} alt="" width="96" height="54" />
          </p>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default PaymentSummaryForCreditCardBuy;
