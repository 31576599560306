import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import localDb from "../../../../common/localDb";

export default function PaysuMore(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section grey-background">
			<div className="content-container">
				<div className="ps-section-title-line"></div>
				<div className="ps-section-grid">
					<AnimationOnScroll animateIn="animate__fadeIn">
						<p className="ps-section-title">Find Out More.</p>
					</AnimationOnScroll>
					<div>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text">Discover how we can help your business grow by getting in touch today. Whether you’re looking to streamline your payment processes, take advantage of crypto payments, expand into new markets, or simply need support navigating the complexities of the global payment landscape, PaySu provide the solutions you need to achieve your goals.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							{
								!localDb.getVal("token") && <div className="theme-btn-block">
								<button onClick={() => {
								props.showModal();
							}} className="paysu-btn mt">Get started</button>
							</div>
							}
							
						</AnimationOnScroll>
					</div>
				</div>
				<img className="paysu-fullImage" src={`${image_base_url}/paysu/ps-more.webp`} />
			</div>
		</section>
	)
}