import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

const menuList = [
    {
        name: "API Guide",
        path: "/merchantTools/APIGuide",
    },
    {
        name: "Authentication",
        path: "/merchantTools/Authentication",
    },
    {
        name: "Create Payment",
        path: "/merchantTools/CreatePayment",
    },
    {
        name: "Create Payout",
        path: "/merchantTools/CreatePayout",
    },
    {
        name: "Get Transactions",
        path: "/merchantTools/GetTransactions",
    },
    {
        name: "Webhooks",
        path: "/merchantTools/Webhooks",
    },
    {
        name: "Error Handling",
        path: "/merchantTools/ErrorHandling",
    },
];

class SideToolsMobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pathname: "",
            activeMenu:  {
                name: "API Guide",
                path: "/merchantTools/APIGuide",
            },
            showMenuOptions: false,
        }
    }

    componentDidMount() {
        this.setState({ pathname: window.location.pathname })
        menuList.map(menu => {
            if (window.location.pathname.includes(menu.path)) {
                this.setState({ activeMenu: menu });
            }
        })
    }

    render() {
        return (<div className="sideToolsMobileMenuMain">
            <div className="sideToolsMobileMenu_Selector" onClick={()=>{
                this.setState({showMenuOptions: true});
            }}><span>{this.state.activeMenu.name}</span><i className="fa fa-chevron-down" /></div>
            {this.state.showMenuOptions && <div className="menuOptionsList">
                <div className="toolsOverview"><span>Tools Overview</span><i 
                onClick={()=>{
                    this.setState({showMenuOptions: false});
                }}
                class="fa fa-times CloseMenuListIcon" /></div>
                <div className="sideToolsMobileMenu_listWrap">
                    {menuList.map((menu, i) => {
                        return (<div key={i}
                            data-e2e={menu.name}
                            className={`sideToolsMobileMenu_item ${this.state.pathname.indexOf(menu.path) !== -1 ? "sideToolsMenuMobile_item_active" : ""}`}
                            onClick={() => {
                                this.props.navigate(menu.path)
                            }}>
                            <span>{menu.name}</span><i className="fa fa-chevron-right sideToolsMobileMenu_arrowIcon" />
                        </div>)
                    })}
                </div>
            </div>}
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        image_base_url: state.config.image_base_url,
    }
}

export default connect(mapStateToProps, null)((props)=><SideToolsMobileMenu {...props} navigate={useNavigate()}/>)