import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import Login from '../Login/Login';
import Signup from '../Signup/Signup';
import * as actions from '../../../Redux/actions';
import { apiService } from '../../../common/apiCallService';
import { saveToSessionStorage } from '../../../common/LoadingAndRedirection';
import { removeHttp } from '../../../common/getIP';
const DeveloperToolImage = '/home/21.png';
const GST_background = '/home/3.png';
const tick_icon = '/common/tick_icon.png';
const mobileImage = '/common/mobile1.png';
const b2csmallCircle = '/home/homeleftcircle.png';
const b2csmallCircleDark = '/home/homeleftcircledark.png';
const homeLaptopBackground = '/home/homelaptopbackground.png';
const homeLaptopBackgroundDark = '/home/homelaptopbackgrounddark.png';
const homeLaptopDark = '/home/homelaptopdark.png';
const homeLaptop = '/home/homelaptop.png';

const listOfadventages = [
  {
    description: 'Real time global payments',
  },
  {
    description: 'Instant fiat conversion, zero exposure risk',
  },
  {
    description: 'Low fees, zero chargeback risk',
  },
  {
    description: 'Simple integration with easy-to-use APIs',
  },
];

const whyCryptonPayFeature = [
  {
    name: 'Safe & Secure',
    description: 'Our best-in-class anti-fraud solution checks each transaction is free from money laundering, terrorist and criminal association.',
    icon: '/home/safe.png',
    backgroundImg: '/home/safe_bg.png',
    backgroundPositionX: 'initial',
    backgroundPositionY: 'initial',
  },
  {
    name: 'Lightning Fast',
    description: 'Accept cryptocurrency instantly, without the frustration of account creation for the end user.',
    icon: '/home/fast.png',
    backgroundImg: '/home/fast_bg.png',
    backgroundPositionX: 'right',
    backgroundPositionY: 'initial',
  },
  {
    name: 'Stress Free',
    description: 'We instantly convert crypto into your FIAT currency of choice meaning you don’t have to worry about market exposure.',
    icon: '/home/stress.png',
    backgroundImg: '/home/stress_bg.png',
    backgroundPositionX: 'initial',
    backgroundPositionY: 'bottom',
  },
];

function HomeMerchant(props) {
  const navigate = useNavigate()
  const [modalType, setModalType] = useState('signup');
  const [showModal, setShowModal] = useState(false);
  const [passwordChangedSuccessful, setPasswordChangedSuccessful] = useState(false);
  const [colorMode, setcolorMode] = useState(null);
  const [bodyLightTextColour, setbodyLightTextColour] = useState(null);
  const [bodyDarkTextColour, setbodyDarkTextColour] = useState(null);
  const apiUrl = useSelector((state) => state.config.api_url);

  const theme = useSelector((state) => state.common.theme);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  useEffect(() => {
    if (!sessionStorage.getItem("domainNameUrl")) {
			getPaymentProviderForDomain()
		}
  }, []);
  useEffect(() => {
    if (window.location.pathname === '/loginPopup') {
      setShowModal(true);
      setModalType('login');
      setPasswordChangedSuccessful(true);
    }
    if (sessionStorage.getItem("colorMode") && sessionStorage.getItem("colorMode") !== "" && sessionStorage.getItem("colorMode") !== "null") {
      setcolorMode(sessionStorage.getItem("colorMode"));
    }
    if (sessionStorage.getItem("bodyLightTextColour") && sessionStorage.getItem("bodyLightTextColour") !== "" && sessionStorage.getItem("bodyLightTextColour") !== "null") {
      setbodyLightTextColour(sessionStorage.getItem("bodyLightTextColour"));
    }
    if (sessionStorage.getItem("bodyDarkTextColour") && sessionStorage.getItem("bodyDarkTextColour") !== "" && sessionStorage.getItem("bodyDarkTextColour") !== "null") {
      setbodyDarkTextColour(sessionStorage.getItem("bodyDarkTextColour"));
    }
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
    }
    if (sessionStorage.getItem("homeMerchantPath") && sessionStorage.getItem("homeMerchantPath") !== "" && sessionStorage.getItem("homeMerchantPath") !== "null") {
      console.log("Redirecting to:", `/${sessionStorage.getItem("homeMerchantPath").replace("/", "")}`)
      navigate(`/${sessionStorage.getItem("homeMerchantPath").replace("/", "")}`);
    }
  }, []);
  const getPaymentProviderForDomain = async () => {
		try {
			await apiService((window?.location?.hostname === "localhost" ? apiUrl : "") + '/restapi/get-payment-provider-for-domain', {
				redirectUrl: removeHttp(window?.location?.hostname)
			}, (data) => {
				saveToSessionStorage(data)
			}, (err) => {
				alert("Domain is not configured to any payment provider!")
				console.error(err);
			})
		} catch (err) {
			console.error(err)
		}
	}

  const changeModalType = (type) => {
    setModalType(type);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const onVerificationSucess = (data, user_name) => {
    localStorage.setItem("token", data.token)
    if (!sessionStorage.getItem("colorMode") || sessionStorage.getItem("colorMode") === "") {
      sessionStorage.setItem("colorMode", 'light')
    }
    if (data.is2faActivated && data.is2faActivated.secret) {
      if (data.is2faActivated.secret != null) {
        localStorage.setItem("2FAStatus", true);
      } else {
        localStorage.setItem("2FAStatus", false);
      }
    }
    if (data.merchantId) {
      localStorage.setItem("merchantId", data.merchantId)
    }
    if (data.merchantName) {
      localStorage.setItem("merchantName", data.merchantName)
    }
    if (data.merchant_Id) {
      localStorage.setItem("merchant_Id", data.merchant_Id)
    }
    localStorage.setItem("merchantWalletActivation", data.merchantWalletActivation ? data.merchantWalletActivation : false)
    props.setToken(data.token)
    closeModal();
    localStorage.setItem("loginType", "Merchant")
    localStorage.setItem("user_name", user_name)
    if (localStorage.getItem("merchantWalletActivation") && localStorage.getItem("merchantWalletActivation") != "false") {
      window.location.href = '/myAccount/balances'
    } else {
      window.location.href = '/myAccount/transactions'
    }
  }


  let bodyTextStyle = {}
  if (colorMode === "dark") {
    if (bodyDarkTextColour && bodyDarkTextColour !== "") {
      bodyTextStyle.color = bodyDarkTextColour;
    }
  } else {
    if (bodyLightTextColour && bodyLightTextColour !== "") {
      bodyTextStyle.color = bodyLightTextColour;
    }
  }
  return (
    <HeaderAndFooterLayout>
      <div className={`merchant__home__lightMood ${theme}`}>
        <div className="CP_Home_mainBG">
          <style>{`\
                            body { overflow-x: hidden; }\
                    `}</style>
          <div className="CP_Home_main">
            <div className="CP_Home_main_bg">
              <div className="CP_Home_all_content">
                <div className="CP_home_content_wrap">
                  <div className="CP_left_circle">
                    {theme === 'light' ? (
                      <span>
                        <img src={`${props.image_base_url}${b2csmallCircle}`} alt="b2csmallCircle" />
                      </span>
                    ) : (
                      <img src={`${props.image_base_url}${b2csmallCircleDark}`} alt="b2csmallCircleDark" className="left__circle__dark" />
                    )}
                  </div>
                  <div className={theme === 'light' ? 'CP_home_left_partition' : 'CP_home_left_partition CP_left_dark'}>
                    <div className="CP_paymentModeHeading">
                      <div className="CP_paymentModeHeading_wrap">
                        Accept&nbsp;
                        <div className="CP_paymentModeHeading_bitcoin" style={{ ...bodyTextStyle }}>
                          <ul className="dynamic-txts">
                            <li className="dynamicTxtsItem" style={{ borderRightColor: bodyTextStyle && bodyTextStyle.color ? bodyTextStyle.color : "xyz" }}>
                              <span>Bitcoin</span>
                            </li>
                            <li className="dynamicTxtsItem" style={{ borderRightColor: bodyTextStyle && bodyTextStyle.color ? bodyTextStyle.color : "xyz" }}>
                              <span>Ethereum</span>
                            </li>
                            {/* <li className="dynamicTxtsItem"><span>Litecoin</span></li> */}
                            <li className="dynamicTxtsItem" style={{ borderRightColor: bodyTextStyle && bodyTextStyle.color ? bodyTextStyle.color : "xyz" }}>
                              <span>USD Coin</span>
                            </li>
                            <li className="dynamicTxtsItem" style={{ borderRightColor: bodyTextStyle && bodyTextStyle.color ? bodyTextStyle.color : "xyz" }}>
                              <span>USD Tether</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      within minutes
                    </div>
                    <div className="CP_advantages_list_wrap">
                      {listOfadventages.map((data, i) => {
                        return <SingleAdvantage index={i} image_base_url={props.image_base_url} key={i} data={data} />;
                      })}
                    </div>

                    {/* <GetStarted token={props.token} showModal={() => {setShowModal(true)}} /> */}
                  </div>

                  <div className="CP_home_right_partition">
                    <div className="CP_home_rightImage">
                      {theme === 'light' ? (
                        <span>
                          <img src={`${image_base_url}${homeLaptopBackground}`} className="CP_home_rightLaptopBackground" alt="homeLaptopBackground" />
                          <img src={`${image_base_url}${homeLaptop}`} className="CP_home_rightLaptop" alt="homeLaptop" />
                        </span>
                      ) : (
                        <span>
                          <img style={{height:'661px'}} src={`${image_base_url}${homeLaptopBackgroundDark}`} className="CP_home_rightLaptopBackground" alt="homeLaptopBackgroundDark" />
                          <img src={`${image_base_url}${homeLaptopDark}`} className="CP_home_rightLaptopDark" alt="homeLaptopDark" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="whyPaymentPayWrap">
                  <div className="WCP_aboutUs" style={{ ...bodyTextStyle }}>ABOUT US</div>
                  <div className="WCP_heading">Why choose { paymentProviderName ? paymentProviderName : "Cryptonpay"}?</div>
                  <div className="WCP_cryptoFeatureListWrap">
                    {whyCryptonPayFeature.map((data, i) => {
                      return <SingleCryptoFeature image_base_url={image_base_url} data={data} key={i} />;
                    })}
                  </div>
                </div>
                <DeveloperTools
                  bodyTextStyle={bodyTextStyle}
                  paymentProviderName={paymentProviderName}
                  image_base_url={image_base_url}
                  token={props.token}
                  showModal={() => {
                    setShowModal(true);
                  }}
                />
                <GetStartedToday
                  bodyTextStyle={bodyTextStyle}
                  paymentProviderName={paymentProviderName}
                  image_base_url={image_base_url}
                  token={props.token}
                  showModal={() => {
                    setShowModal(true);
                  }}
                />
              </div>
            </div>
            {showModal && (
              <div>
                {modalType === 'login' ? (
                  <Login show={true} onVerificationSucess={onVerificationSucess} changeModalType={changeModalType} closeModal={closeModal} passwordChangedSuccessful={passwordChangedSuccessful} />
                ) : (
                  <Signup title={'LOG IN'} show={true} changeModalType={changeModalType} closeModal={closeModal} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderAndFooterLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    api_url: state.config.api_url,
    token: state.common.token,
    image_base_url: state.config.image_base_url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (data) => dispatch(actions.setToken(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeMerchant);

const SingleAdvantage = (props) => {
  const { description } = props.data;
  return (
    <div className="home_single_advantage_wrap" style={{ animationName: `listItemUp${props.index}` }}>
      <img src={`${props.image_base_url}${tick_icon}`} alt="tickicon" className="home_single_advantage_icom" />
      <div className="home_single_advantage_description">{description}</div>
    </div>
  );
};

const SingleCryptoFeature = (props) => {
  const { name, description, icon, backgroundImg, backgroundPositionX, backgroundPositionY } = props.data;
  return (
    <div
      className="home_single_cryptoFeature_wrap"
      style={{
        backgroundImage: `url(${props.image_base_url}${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: backgroundPositionX,
        backgroundPositionY: backgroundPositionY,
      }}
    >
      <img src={`${props.image_base_url}${icon}`} alt="featuresymbol" className="home_single_cryptoFeature_icon" />
      <div className="home_single_cryptoFeature_name">{name}</div>
      <div className="home_single_cryptoFeature_description">{description}</div>
    </div>
  );
};

const DeveloperTools = (props) => {
  return (
    <div className="homeDeveloperToolWrap">
      <div className="HDTLeftPartionWrap">
        <div className="HDT_aboutSDK" style={{ ...props.bodyTextStyle }}>ABOUT SDK</div>
        <div className="HDT_heading">Developer tools</div>
        <div className="HDT_descryption">{`Our SDK toolkits make adding ${props.paymentProviderName ? props.paymentProviderName : "Crypton"} Payments to your application as easy as possible.`} </div>
        <div className="HDT_mobileImage" style={{ backgroundImage: `url(${props.image_base_url}${mobileImage}` }}>
          <div className="HDT_mobileImageFader" />
        </div>
      </div>
      <div className="HDTRightPartionWrap">
        <img src={`${props.image_base_url}${DeveloperToolImage}`} className="HDTRightPartion_Img" />
      </div>
    </div>
  );
};

const GetStartedToday = (props) => {
  return (
    <div className="GST_main">
      <div className="GST_main_wrap" style={{ backgroundImage: `url(${props.image_base_url}${GST_background})` }}>
        <div className="GST_left_wrap">
          <div className="GST_daily_transactions" style={{ ...props.bodyTextStyle }}>100,000+ DAILY TRANSACTIONS</div>
          <div className="GST_heading">Get started today</div>
          <div className="GST_description">
            {`Our simple API and integrations to market leading payment aggregators let you add ${props.paymentProviderName ? props.paymentProviderName : "Crypton"} Payments to your cashier quickly and easily.`}
          </div>
        </div>
      </div>
    </div>
  );
};