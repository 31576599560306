import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import speakeasy from 'speakeasy';
import { apiService } from "../../../../common/apiCallService";
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const KalonPayAuthenticatorModal = (props) => {
    const api_url = useSelector((state) => state.config.api_url);
    const [error, setError] = useState('');
    const [verificationCode2fa, change2faVerificationCode] = useState('');
    const [imageData, setImageData] = useState(null);
    const [secret, setSecret] = useState(null);
    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);
    useEffect(() => {
        generateQRcode();
        if (checkValueInSS("colorMode")) {
            setColorMode(sessionStorage.getItem("colorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, [])


    const generateQRcode = async () => {
        try {
            const emailId = localStorage.getItem("user_name") || props.merchantEmail;
            const paymentProviderName = sessionStorage.getItem("paymentProviderName") || "CryptonPay";
    
            const response = await fetch((window.location.hostname === 'localhost' ? api_url : '') + '/restapi/genrateQrCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(localStorage.getItem("token") == null && { token: props.merchantData.token })
                },
                body: JSON.stringify({ email: emailId, paymentProviderName })
            });
    
            if (!response.ok) {
                throw new Error('Failed to generate QR code');
            }
    
            const responseData = await response.json();
            const { qrCode , secret} = responseData;
            setImageData(qrCode);
            setSecret(secret);
        } catch (error) {
            console.error('Error generating QR code:', error.message);
            alert('Failed to generate QR code');
        }
    };


    const onVerifyCode = async () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            props.setLoading(true);
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/merchant-verify-2fa-token',
                {
                    email: localStorage.getItem("user_name") ? localStorage.getItem("user_name") : props.merchantEmail,
                    merchantId: localStorage.getItem('merchantId') ? localStorage.getItem('merchantId') : props.merchantData.merchantId,
                    user_email: localStorage.getItem("user_name") ? localStorage.getItem("user_name") : props.merchantEmail,
                    merchant2faToken: verificationCode2fa,
                    secret: secret.base32,
                    encoding: 'base32',
                    authenticator: true,
                    ...(localStorage.getItem("token") == null && { token: props.merchantData.token })
                },
                (data) => {
                    props.setLoading(false);
                    if (data.success === true) {
                        if (props.component === "head") {
                            props.onVerificationSucess(props.merchantData, props.merchantEmail);
                        } else {
                            props.setIs2faActivated();
                            localStorage.setItem("2FAStatus", true);
                        }
                        props.onClose();
                    } else {
                        setError('Invalid verification code!');
                    }
                },
                (err) => {
                    props.setLoading(false);
                    alert('Something went wrong!');
                    console.log(err);
                },
            );
        } else {
            setError('Invalid token! 2FA token must have 6-digit only');
        }
    };

    const disabledVerify = verificationCode2fa.length !== 6;
    return (
        <Dialog open={props.open} className='kalonpay-theme-modal '>
            <div className='kalonpay-modal-body' >
                <div className='kalonpay-icon'>
                    <ClearIcon style={{ color: "black" }} onClick={props.onClose} className='icon' />
                </div>
                <form>
                    <p className="theme-modal-title">Authenticator</p>
                    <div className="theme-modal-authenticator">
                        <img src={imageData}></img>
                    </div>
                    <p className="auth-modal-text">Scan the QR Code in Googles authenticator app and the input verification code.</p>
                    <br />
                    <div className="theme-modal-field">
                        <p className='lebal-text pb-4'>Verification Code</p>
                        <input
                            value={verificationCode2fa}
                            type="text"
                            placeholder="Verification Code"
                            onChange={(e) => {
                                setError('');
                                change2faVerificationCode(e.target.value);
                            }}
                        />
                        {error !== '' && <div className="errorMessage">{error}</div>}
                    </div>
                    <div >
                        <button
                            type="button"
                            disabled={disabledVerify}
                            className={"kalonpay-btn kal-auth-btn pt-2"}
                            onClick={() => {
                                if (!disabledVerify) {
                                    onVerifyCode();
                                }
                            }}
                        ><p>Verify</p>
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}

export default KalonPayAuthenticatorModal