import React from "react";
import App from "./App.js";
import createStore from "./helpers/createStore";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { createRoot } from 'react-dom/client';

window.global = window;
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

const initialState =
  window && process.env.NODE_ENV && process.env.NODE_ENV !== "development"
    ? {
      config: {
        // These variable must be use for only local testing perpose
        // api_url: "http://localhost:8081",
        // payment_api_url: "http://localhost:8080",
        // activity_tracking_api_url: "http://localhost:8082",
        deposit_refresh_interval: window.INITIAL_STATE?.config?.DEPOSIT_REFRESH_INTERVAL,
        image_base_url: window.INITIAL_STATE?.config?.IMAGE_BASE_URL + "/images",
        geetest_captcha_id: window.INITIAL_STATE?.config?.GEETEST_CAPTCHA_ID,
        merchantID: window.INITIAL_STATE?.config?.AXCESS_MERCHANT_ID,
        merchantSecret: window.INITIAL_STATE?.config?.AXCESS_SECRET_KEY,
        axcess_hosted_url: window.INITIAL_STATE?.config?.AXCESS_HOSTED_URL,
        TRUST_PAYMENTS_POST_URL: window.INITIAL_STATE?.config?.TRUST_PAYMENTS_POST_URL,
        TRUST_PAYMENTS_REST_API_POST_URL: window.INITIAL_STATE?.config?.TRUST_PAYMENTS_REST_API_POST_URL,
        TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT: window.INITIAL_STATE?.config?.TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT,
        TRUST_PAYMENTS_LIVE_FLAG_B2C: window.INITIAL_STATE?.config?.TRUST_PAYMENTS_LIVE_FLAG_B2C,
        KYC_ACTIVATE: window.INITIAL_STATE?.config?.KYC_ACTIVATE ? window.INITIAL_STATE?.config?.KYC_ACTIVATE : true,
        COIN_SPIRIT_BASE_PATH: window.INITIAL_STATE?.config?.COIN_SPIRIT_BASE_PATH,
        COIN_SPIRIT_HOST_NAME: window.INITIAL_STATE?.config?.COIN_SPIRIT_HOST_NAME,
        COIN_SPIRIT_SUPPORT_MAIL: window.INITIAL_STATE?.config?.COIN_SPIRIT_SUPPORT_MAIL,
        COIN_SWAP_CENTER_BASE_PATH: window.INITIAL_STATE?.config?.COIN_SWAP_CENTER_BASE_PATH,
        COIN_SWAP_CENTER_HOST_NAME: window.INITIAL_STATE?.config?.COIN_SWAP_CENTER_HOST_NAME,
        COIN_SWAP_CENTER_SUPPORT_MAIL: window.INITIAL_STATE?.config?.COIN_SWAP_CENTER_SUPPORT_MAIL,
        COINS_TRADE_HUB_HOST_NAME: window.INITIAL_STATE?.config?.COINS_TRADE_HUB_HOST_NAME,
        COINS_TRADE_HUB_BASE_PATH: window.INITIAL_STATE?.config?.COINS_TRADE_HUB_BASE_PATH,
        COINS_TRADE_HUB_SUPPORT_MAIL: window.INITIAL_STATE?.config?.COINS_TRADE_HUB_SUPPORT_MAIL,
        SWITCH_FIN_API_URL : window.INITIAL_STATE?.config?.SWITCH_FIN_API_URL,
      }
    }
    : {
      config: {
        // These variable must be use for only local testing perpose
        api_url: "http://localhost:8081",
        payment_api_url: "http://localhost:8080",
        activity_tracking_api_url: "http://localhost:8082",
        deposit_refresh_interval: process.env.DEPOSIT_REFRESH_INTERVAL,
        image_base_url: "https://dev.cryptonyte.org/images",
        geetest_captcha_id: process.env.GEETEST_CAPTCHA_ID,
        merchantID: process.env.AXCESS_MERCHANT_ID,
        merchantSecret: process.env.AXCESS_SECRET_KEY,
        axcess_hosted_url: process.env.AXCESS_HOSTED_URL,
        TRUST_PAYMENTS_POST_URL: process.env.TRUST_PAYMENTS_POST_URL,
        TRUST_PAYMENTS_REST_API_POST_URL: process.env.TRUST_PAYMENTS_REST_API_POST_URL,
        TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT: process.env.TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT,
        TRUST_PAYMENTS_LIVE_FLAG_B2C: process.env.TRUST_PAYMENTS_LIVE_FLAG_B2C,
        KYC_ACTIVATE: process.env.KYC_ACTIVATE ? process.env.KYC_ACTIVATE : true,
        COIN_SPIRIT_BASE_PATH: window.INITIAL_STATE?.config?.COIN_SPIRIT_BASE_PATH,
        COIN_SPIRIT_HOST_NAME: window.INITIAL_STATE?.config?.COIN_SPIRIT_HOST_NAME,
        COIN_SPIRIT_SUPPORT_MAIL: window.INITIAL_STATE?.config?.COIN_SPIRIT_SUPPORT_MAIL,
        COIN_SWAP_CENTER_BASE_PATH: window.INITIAL_STATE?.config?.COIN_SWAP_CENTER_BASE_PATH,
        COIN_SWAP_CENTER_HOST_NAME: window.INITIAL_STATE?.config?.COIN_SWAP_CENTER_HOST_NAME,
        COIN_SWAP_CENTER_SUPPORT_MAIL: window.INITIAL_STATE?.config?.COIN_SWAP_CENTER_SUPPORT_MAIL,
        COINS_TRADE_HUB_HOST_NAME: window.INITIAL_STATE?.config?.COINS_TRADE_HUB_HOST_NAME,
        COINS_TRADE_HUB_BASE_PATH: window.INITIAL_STATE?.config?.COINS_TRADE_HUB_BASE_PATH,
        COINS_TRADE_HUB_SUPPORT_MAIL: window.INITIAL_STATE?.config?.COINS_TRADE_HUB_SUPPORT_MAIL,
        SWITCH_FIN_API_URL : window.INITIAL_STATE?.config?.SWITCH_FIN_API_URL,
      },
    };

const store = createStore(initialState);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <App />
    </ErrorBoundary>
  </Provider>
);