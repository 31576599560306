import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';

const ConfirmationModal = (props) => {
    const { testVariable } = props //for e2e purpose don't remove
    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);

    useEffect(() => {
        if (checkValueInSS("colorMode")) {
          setColorMode(sessionStorage.getItem("colorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
          setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
          setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
          setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
          setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, []);
    const handleClose = () => {
      props.onClose();
    }

    const headerButtonStyle = getHeaderButtonStyle(
        colorMode,
        headerButtonDarkBgColour,
        headerDarkTextColour,
        headerButtonLightBgColour,
        headerLightTextColour
    )

  return (
      <React.Fragment >
          <Dialog open={props.open} className="b2c-signup-model deposit_model_mobile">
              <div className="enable-authenticator-model" style={{ width: '461px' }}>
                  <Card style={{ width: '461px' }} className="card-bg">
                      <div className="enable-authenticator-header">
                          <div>Withdrawal in progress</div>
                          <div className="signup-close-icon">
                            <ClearIcon onClick={() => handleClose()} className="" />
                          </div>
                      </div>
                      <div className="font-bold mt-20 receive-crypto-text-header">
                          Withdrawal request will be processed shortly
                      </div>
                      <button  className="confirm_btn mt-30"
                          onClick={() => handleClose()}
                          style={{ ...headerButtonStyle }}
                      >
                          <div data-e2e="ok" data-e2e-transaction={testVariable}>Ok</div>
                      </button>
                    </Card>
              </div>
          </Dialog>
      </React.Fragment>
    );
}

export default ConfirmationModal;