import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import KalonPayContactForm from './KalonPayContactForm';


const KalonPayHeader = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url)
  const navigate = useNavigate()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem("token") && localStorage.getItem("user_name")) {
        console.log("Redirecting to the merchant page .....")
        navigate("/myAccount/balances")
      }
    }
  }, [])
  return (
    <div className='kalonpay-header'>
      <Link to="/kalonpay-home-merchant">
        <img src={`${image_base_url}/kalonpay/logo.svg`} alt="logo" className='kalonpay-logo' />
      </Link>
      <div className='kalonPay-mobile-hide '><KalonPayContactForm /></div>
    </div>
  )
}

export default KalonPayHeader