import React, { useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
const TruevoSecondForm = (props) => {
    const formRef = useRef(null);
    const iframeRef = useRef(null);
    const [truevoContent, setTruevoContent] = useState(props.truevoContent);

    useEffect(() => {
        const submitForm = () => {
            formRef?.current.submit();
        };
        submitForm();
    }, []);
    const handleClose = () => {
        props.handleTruevoFlag();
    }
    return (
        <div>
            <ClearIcon onClick={handleClose} className="icon-close" />
            Merchant's Site <br />
            <iframe
                id="iFrame"
                name="iFrame"
                width="700px"
                height="700px"
                ref={iframeRef}
            />
            <form
                name="frmChallenge"
                method="POST"
                target="iFrame"
                action={truevoContent[0]?.value}
                ref={formRef}
            >
                <input
                    type="hidden"
                    name="creq"
                    value={truevoContent[1]?.value} />
            </form>
        </div>
    );
};

export default TruevoSecondForm;