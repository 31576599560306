import React, { useEffect, useState } from "react";
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import PaysuIntroduce from "./components/PaysuIntroduce";
import PaysuSimplified from "./components/PaysuSimplified";
import PaysuSteps from "./components/PaysuSteps";
import PaysuWho from "./components/PaysuWho";
import PaysuDo from "./components/PaysuDo";
import PaysuCompletion from "./components/PaysuCompletion";
import PaysuPlanning from "./components/PaysuPlanning";
import PaysuMore from "./components/PaysuMore";
import PaysuFaq from "./components/PaysuFaq";
import PaysuContact from "./components/PaysuContact";
import PaysuHero from "./components/PaysuHero";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import { useNavigate } from "react-router-dom";


export default function PaysuHomeMerchant() {
	const [modalType, setModalType] = useState('signup');
	const [showModal, setShowModal] = useState(false);
	const [passwordChangedSuccessful, setPasswordChangedSuccessful] = useState(false);
	const pathname = window.location.pathname
	const navigate = useNavigate()

	useEffect(() => {
		if (window.location.pathname === '/loginPopup') {
		  setShowModal(true);
		  setModalType('login');
		  setPasswordChangedSuccessful(true);
		}
	  }, []);

	const changeModalType = (type) => {
		setModalType(type);
	};
	const closeModal = () => {
		setShowModal(false);
	};
	
	useEffect(()  => {
    document.body.classList.add('paysu-theme');
	//Redirect to home Merchant if bespoke page is not there
	if(sessionStorage.getItem('homeMerchantPath') === "null" && pathname !== sessionStorage.getItem('homeMerchantPath')){
        navigate('/home-merchant')
    }
	if (sessionStorage.getItem("headerButtonDarkBgColour")) {
		document.getElementsByTagName('body')[0].style.setProperty('--btn-background', sessionStorage.getItem("headerButtonDarkBgColour"));
	}
    return () => { document.body.classList.remove('paysu-theme'); };
	});
	
	
	return (
		<div className="paysu">
			<HeaderAndFooterLayout>
				<PaysuHero showModal={() => {
                    setShowModal(true);
                  }} />
				<PaysuIntroduce />
				<PaysuSimplified />
				<PaysuSteps showModal={() => {
                    setShowModal(true);
                  }} />
				<PaysuWho />
				<PaysuDo />
				<PaysuCompletion />
				<PaysuPlanning />
				<PaysuMore showModal={() => {
                    setShowModal(true);
                  }} />
				<PaysuFaq />
				<PaysuContact />
				{showModal && (
					<div>
						{modalType === 'login' ? (
							<Login show={true} changeModalType={changeModalType} closeModal={closeModal} passwordChangedSuccessful={passwordChangedSuccessful} />
						) : (
							<Signup title={'LOG IN'} show={true} changeModalType={changeModalType} closeModal={closeModal} />
						)}
					</div>
            	)}
			</HeaderAndFooterLayout>
		</div>
	)
}





