import React from 'react';
import DepositFailedForCreditCard from "./DepositFailedForCreditCard.js"
class RefundFailedForCreditCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <DepositFailedForCreditCard refund="true" />
            </div>
        )
    }
}

export default RefundFailedForCreditCard;