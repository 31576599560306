import * as actionTypes from './actionTypes'

export const setLoading = (data) => {
    return {
        type: actionTypes.SET_LOADING,
        data
    }
}
export const checkBuyCryptoStatus = (data) => {
    return {
        type: actionTypes.CHECK_KYC_STATUS_BUY_CRYPTO,
        data
    }
}
export const setOpenKYCVerificationModal = (data) => {
    return {
        type: actionTypes.SET_OPEN_KYC_MODAL,
        data
    }
}
export const set2faActivationStatus = (data) => {
    return {
        type: actionTypes.SET_2FA_ACTIVATION_STATUS,
        data
    }
}
export const setToken = (data) => {
    return {
        type: actionTypes.SET_TOKEN,
        data
    }
}
export const setAndGetTheme = (data) => {
    return {
        type: actionTypes.SET_AND_GET_THEME,
        data
    }
}
export const setAndGetB2CTheme = (data) => {
    return {
        type: actionTypes.SET_AND_GET_B2C_THEME,
        data
    }
}
export const setSelectedCryptos = (data) => {
    return {
        type: actionTypes.SELECTED_CRYPTOS,
        data
    }
}
export const setSelectedPaymentSettings = (data) => {
    return {
        type: actionTypes.SELECTED_PAYMENT_SETTINGS,
        data
    }
}
export const setSelectedStatus = (data) => {
    return {
        type: actionTypes.SELECTED_STATUS,
        data
    }
}
export const setSelectedTypes = (data) => {
    return {
        type: actionTypes.SELECTED_TYPES,
        data
    }
}
export const setFromDate = (data) => {
    return {
        type: actionTypes.SET_FROM_DATE,
        data
    }
}
export const setToDate = (data) => {
    return {
        type: actionTypes.SET_TO_DATE,
        data
    }
}
export const setWalletPageTableName = (data) => {
    return {
        type: actionTypes.SET_AND_GET_TABLE,
        data
    }
}
export const setFaqQuestionData = (data) => {
    return {
        type: actionTypes.SET_FAQ_QUESTION_DATA,
        data
    }
}
export const setImageBaseUrl = (imageUrl) => {
    return {
      type: actionTypes.SET_IMAGE_BASE_URL,
      payload: imageUrl,
    };
  };