import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '../client/Redux/reducers/rootReducer';
  
export default preloadState => {
  const middleware = []; // Add any middleware you use here

  const enhancers = [
    applyMiddleware(...middleware),
  ];

  // Conditionally apply Redux Dev Tools extension only in development environment and browser environment
  if ((process.env.REDUX_DEV_TOOL === 'true'|| process.env.REDUX_DEV_TOOL === true) && typeof window !== 'undefined') {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const composedEnhancers = compose(...enhancers);

  const store = createStore(
    reducers,
    preloadState,
    composedEnhancers
  );
  return store;
};
