import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from "../../../../common/apiCallService";
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';

const KalonPayVerificationModal = (props) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const api_url = useSelector((state) => state.config.api_url);
    const theme = useSelector((state) => state.common.theme);
    const [error, setError] = useState('');
    const [verificationCode2fa, change2faVerificationCode] = useState('');
    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);
    useEffect(() => {
        if (checkValueInSS("colorMode")) {
            setColorMode(sessionStorage.getItem("colorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, [])


    const logo = theme === 'light' ? '/homeb2c/cryptoLightLogo.png' : '/common/cryptonpay_logo.svg';

    const onVerifyCode = () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            props.setLoading(true);
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/merchant-verify-2fa-token',
                {
                    email: localStorage.getItem("user_name") ? localStorage.getItem("user_name") : props.merchantEmail,
                    merchantId: localStorage.getItem('merchantId') ? localStorage.getItem('merchantId') : props.merchantData.merchantId,
                    merchant2faToken: verificationCode2fa,
                    authenticator: false,
                    is2faActivated: props.is2faActivated ? props.is2faActivated : false,
                    ...(localStorage.getItem("token") == null && { token: props.merchantData.token })
                },
                (data) => {
                    props.setLoading(false);
                    if (data.success === true) {
                        if (props.is2faActivated && props.is2faActivated) {
                            localStorage.setItem("2FAStatus", false);
                            props.setIs2faActivated();
                        } else {
                            props.onVerificationSucess(props.merchantData, props.merchantEmail);
                        }
                        props.onClose();
                    } else {
                        setError('Invalid verification code!');
                    }
                },
                (err) => {
                    props.setLoading(false);
                    alert('Something went wrong!');
                    console.log(err);
                },
            );
        } else {
            setError('Invalid token! 2FA token must have 6-digit only');
        }
    };
    const disabledVerify = verificationCode2fa.length !== 6;
    return (
        <Dialog onClose={props.onClose} open={props.open} className='kalonpay-theme-modal '>
            <div className='kalonpay-modal-body'>
                <div className='kalonpay-icon'>
                    <ClearIcon style={{ color: "black" }} onClick={props.onClose} className='icon' />
                </div>
                <form>
                    <p className="theme-modal-title">2FA Verification</p>
                    <p className="auth-modal-text">Open Google Authenticator app and enter the secret key to continue</p>
                    <div className="theme-modal-field">
                        <input
                            value={verificationCode2fa}
                            type="text"
                            placeholder="Verification code"
                            onChange={(e) => {
                                setError('');
                                change2faVerificationCode(e.target.value);
                            }}
                        />
                        {error !== '' && <div className="errorMessage">{error}</div>}
                        <div>
                            <button
                                style={{ width: "100%", marginTop: "10px", color: "white" }}
                                disabled={disabledVerify}
                                type="button"
                                className={`kalonpay-btn`}
                                onClick={() => {
                                    if (!disabledVerify) {
                                        onVerifyCode();
                                    }
                                }}
                            >
                                <p>Verify</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}

export default KalonPayVerificationModal;