import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { apiService } from '../../../common/apiCallService';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';
import FiltersModal from './FilterModal';
import TransactionDetails from './TransactionDetails';
import TransactionTable from './TransactionTable';
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../common/constants';
import debounce from '../../../common/useDebounceClassComp';
const filterIcon = "/common/crytoicons/filters1.png"
const downloadIcon = "/common/crytoicons/downloadicon1.png"
const downloadAlertIcon = "/common/crytoicons/downloadalerticon.png"
const searchicon = "/common/crytoicons/searchicon1.png";
const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Transactions:',
    filename: "Transactions",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ['Column 1', 'Column 2', "col 3", "col 4", "col 5"]
};

const csvExporter = new ExportToCsv(options);


class Transactions extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
						isLoadingTransactions: true,
            searchText: "",
            merchantTransactionId:"",
            page: 1,
            transactions: [],
            allTransactions: [],
            orderBy: "desc",
            sortBy: "status.updated",
            totalCount: 0,
            fromDate: moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            dateSelected: false,
            selectedCryptos: [],
            statusList: ["Success", "Pending", "Require Approval", "Withdraw Request", "Rejected", "Processing", "Timed Out"],
            selectedStatusList: ["Success", "Pending", "Require Approval", "Withdraw Request", "Rejected", "Processing", "Timed Out"],
            fiatCurrencies: [
                {
                    icon: "/common/crytoicons/euro.png",
                    name: "Euro",
                    symbol: "EUR",
                },
                {
                    icon: "/common/crytoicons/usa.png",
                    name: "US Dollar",
                    symbol: "USD",
                },
                {
                    icon: "/common/crytoicons/uk.png",
                    name: "British Pound",
                    symbol: "GBP",
                },
                // {
                //     icon: "/common/crytoicons/rub.png",
                //     name: "Russian Ruble",
                //     symbol: "RUB",
                // },
                // {
                //     icon: "/common/crytoicons/japan.png",
                //     name: "Japanese Yen",
                //     symbol: "JPY",
                // },
            ],
            // selectedFiatCurrencies: ["EUR", "USD", "GBP", "RUB", "JPY"],
            selectedFiatCurrencies: ["EUR", "USD", "GBP"],
            openTransactionDetails: false,
            showDownloadAlertModal: false,
            showSuccess: false,
            showError: false,
            transactionDtls: [],
            allData:[],
            totalNumberOfTransactions:0,
            totalUsdAmount:0,
            sumOfEachCrypto:[],
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
            timeout :  0,
            viewPermissionOnly: null,
        };
        this.debouncedGetTransactions = debounce(this.getTransactions, constants?.SEARCH_DELAY_TIME);
        this.debouncedGetTransactionSummary = debounce(this.getTransactionSummary, constants?.SEARCH_DELAY_TIME);
    }

    componentDidMount() {
        const userPermission = JSON.parse(localStorage.getItem("viewPermissionOnly"))
        this.setState({ viewPermissionOnly : userPermission })
        if (localStorage.getItem("token")) {
            this.getTransactions()
            this.getTransactionSummary()
            if (this.props.cryptos && this.props.cryptos.length === 0) {
                this.getCryptoCurrencies()
            }
        }
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
        if (this.debouncedGetTransactions) {
            this.debouncedGetTransactions.cancel();
        }
        if(this.debouncedGetTransactionSummary) {
            this.debouncedGetTransactionSummary.cancel();
        }
    }

    handleDownloadTransaction = () => {
        this.getTransactions('all')
        this.setState({ showDownloadAlertModal: true })
    }

		
		
    getTransactions = (pageNo) => {
        let page = 1
        let allMode=false;
        if (pageNo) {
            page = pageNo;
            this.setState({ page: pageNo });
        }
        if(pageNo==="all"){
            allMode=true
        }
        const { searchText, orderBy, sortBy, merchantTransactionId } = this.state;
        let requestBody = {
            merchantId: localStorage.getItem("merchantId"),
            transactionId: searchText?.toString(),
            merchantUserId: searchText?.toString(),
            merchant: searchText?.toString(),
            merchantTransactionId: merchantTransactionId?.toString(),
            merchantUserEmailAddress: searchText?.toString(),
            walletAddress: searchText?.toString(),
            trafficType: searchText?.toString(),
            page,
            orderBy,
            sortBy,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
        }
        if (allMode) {
            delete requestBody.page
            requestBody = {
                ...requestBody,
                findAll: true
            }
        }
        if (this.state.selectedCryptos.length === 0 || this.state.selectedCryptos.length === this.props.cryptos.length) {
            requestBody.CryptoCurrency = "All"
        } else {
            requestBody.CryptoCurrency = this.state.selectedCryptos
        }
        if (this.state.selectedFiatCurrencies.length === 0 || this.state.selectedFiatCurrencies.length === this.state.fiatCurrencies.length) {
            requestBody.FiatCurrency = "All"
        } else {
            requestBody.FiatCurrency = this.state.selectedFiatCurrencies
        }
        if (this.state.selectedStatusList.length === 0 || this.state.selectedStatusList.length === this.state.statusList.length) {
            requestBody.status = "All"
        } else {
            requestBody.status = this.state.selectedStatusList
        }
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-transactions', requestBody,
            (data) => {
                this.props.setLoading(false)
                if(allMode){
                    this.setState({allData:data.transactionsList})
                    return
                }
                if (pageNo === 1) {
                    this.setState({
											transactions: data.transactionsList,
											totalCount: data.totalCount,
                    });
										this.setState({ isLoadingTransactions: false });
										
                } else {
                    this.setState({
											transactions: data.transactionsList,
											totalCount: data.totalCount,
                    });
										this.setState({ isLoadingTransactions: false });
                }
                if (this.props.refTransactionId) {
                    data.transactionsList.map((tObj) => {
                        if (tObj.transactionId === this.props.refTransactionId) {
                            this.openTransactionDetails({ transactionId: this.props.refTransactionId });
                        }
                        return tObj
                    })
                }
            }, (err) => {
                this.props.setLoading(false)
								this.setState({ isLoadingTransactions: false });
                console.log(err);
            })
    }
    openTransactionDetails = (details) => {
        let requestBody = {
            transactionId: details.transactionId,
        }
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/view-transaction', requestBody,
            (data) => {
                this.props.setLoading(false)
                if (data && data.transaction) {
                    this.setState({
                        transactionDtls: [data.transaction],
                        openTransactionDetails: true
                    })
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }

    approveTransaction = (details) => {
        if (this?.state?.viewPermissionOnly) {
            return
        }
        let requestBody = {
            transactionId: details.transactionId,
            approveComment: details.approveComment,
            merchantId: localStorage.getItem("merchantId"),
            merchantEmail: localStorage.getItem("user_name"),
        }
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/approve-transaction', requestBody,
            (data) => {
                this.props.setLoading(false)
                if (data && data.transaction) {
                    this.setState({
                        transactionDtls: [data.transaction],
                        openTransactionDetails: true,
                        showSuccess: true,
                    }, () => {
                        this.getTransactions(this.state.page)
                    })
                    setTimeout(() => {
                        this.setState({showSuccess: false})
                    },2000)
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }

    rejectTransaction = (details) => {
        if (this?.state?.viewPermissionOnly) {
            return
        }
        let requestBody = {
            transactionId: details.transactionId,
            rejectComment: details.rejectComment,
            merchantId: localStorage.getItem("merchantId"),
            merchantEmail: localStorage.getItem("user_name"),
        }
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/reject-transaction', requestBody,
            (data) => {
                this.props.setLoading(false)
                if (data && data.transaction) {
                    this.setState({
                        transactionDtls: [data.transaction],
                        openTransactionDetails: true,
                        showError: true,
                    }, () => {
                        this.getTransactions(this.state.page)
                    })
                    setTimeout(() => {
                        this.setState({showError: false})
                    },2000)
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }

    getCryptoCurrencies = () => {
        let requestBody = {}
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-cryptos', requestBody,
            (data) => {
                this.props.setLoading(false)
                if (data && data.cryptos) {
                    this.props.saveCryptos(data.cryptos)
                    let cryptos = data.cryptos.map(crypto => { return crypto.symbol })
                    this.setState({ selectedCryptos: cryptos });
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }

    getTransactionSummary = () => {
        const { searchText, orderBy, sortBy, merchantTransactionId } = this.state;
        let requestBody = {
            merchantId: localStorage.getItem("merchantId"),
            transactionId: searchText.toString(),
            merchantUserId: searchText.toString(),
            merchant: searchText.toString(),
            merchantTransactionId: merchantTransactionId?.toString(),
            merchantUserEmailAddress: searchText.toString(),
            walletAddress: searchText.toString(),
            trafficType: searchText.toString(),
            orderBy,
            sortBy,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
        }
        if (this.state.selectedCryptos.length === 0 || this.state.selectedCryptos.length === this.props.cryptos.length) {
            requestBody.CryptoCurrency = "All"
        } else {
            requestBody.CryptoCurrency = this.state.selectedCryptos
        }
        if (this.state.selectedFiatCurrencies.length === 0 || this.state.selectedFiatCurrencies.length === this.state.fiatCurrencies.length) {
            requestBody.FiatCurrency = "All"
        } else {
            requestBody.FiatCurrency = this.state.selectedFiatCurrencies
        }
        if (this.state.selectedStatusList.length === 0 || this.state.selectedStatusList.length === this.state.statusList.length) {
            requestBody.status = "All"
        } else {
            requestBody.status = this.state.selectedStatusList
        }
       
        this.props.setLoading(true);
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/summary-of-transactions',requestBody,
            (data) => {
                this.props.setLoading(false)    
                if (data) {
                    this.setState({
                        totalNumberOfTransactions: data.numberOfTransaction,
                        totalUsdAmount:data.totalDepositAmount && data.totalDepositAmount.length > 0 ? data.totalDepositAmount[0].depositAmount :0,
                        sumOfEachCrypto:data.sumOfCrypto,
                    });
                }
            }, (err) => {
                this.props.setLoading(false)
                console.log(err);
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (e.target.name === "searchText") {
                this.debouncedGetTransactions(1);
                this.debouncedGetTransactionSummary();
            }
        });
    };

    resetFilters = () => {
        this.setState({
            searchText: "",
            page: 1,
            transactions: [],
            orderBy: "desc",
            totalCount: 0,
            fromDate: moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            dateSelected: false,
            selectedCryptos: [],
            selectedFiatCurrencies: [],
            selectedStatusList: [],
            merchantTransactionId: ""
        }, () => {
            this.getTransactions(1);
            this.getTransactionSummary();
        })
    }
    saveSettings = (settings) => {
        this.setState({
            page: 1,
            transactions: [],
            totalCount: 0,
            fromDate: settings.dateSelected ? settings.fromDate : this.state.fromDate,
            toDate: settings.dateSelected ? settings.toDate : this.state.toDate,
            dateSelected: settings.dateSelected,
            selectedCryptos: settings.selectedCryptos,
            selectedFiatCurrencies: settings.selectedFiatCurrencies,
            selectedStatusList: settings.selectedStatusList,
        }, () => {
            this.getTransactions(1);
            this.getTransactionSummary();
        })
    }

    getTransactionStatus = (status) => {
        switch (status) {
          case 'DepositMonitor:DepositPendingManualApproval':
          case 'WithdrawalFlow:PendingApproval':
            return "REQUIRED APPROVAL";
          case 'DepositMonitor:WalletStatusAvailable':
          case 'DepositMonitor:WalletStatusLocked':
          case 'DepositMonitor:UpdateWalletStatus':
          case 'DepositMonitor:NotfiyMerchant':
          case 'DepositMonitor:DepositManualApprove':
          case 'DepositMonitor:KYTCheckFail':
          case 'DepositMonitor:KYTCheckPass':
          case 'DepositMonitor:KYTCheck':
          case 'DepositMonitor:DepositConfirmed':
          case 'DepositMonitor:GasTooLow':
          case 'DepositMonitor:ExcessTransactionExecuted':
          case 'DepositMonitor:TransactionExecuted':
          case 'DepositMonitor:TransactionExecuting':
          case 'DepositMonitor:DepositReceivedToMemPool':
          case 'DepositMonitor:TransactionComplete':
            return "PROCESSING";
          case 'DepositMonitor:DepositManuallyCompleted':
          case 'DepositMonitor:DepositCompleted':
          case "MerchantWallet:BalanceUpdated":
          case "WalletManagementService:WithdrawalCompleted":
          case "WalletManagementService:TransferCompleted":
          case "DepositFlowViaWallet:DepositCompleted":
            return "SUCCESS";
          case 'CreatePayment':
          case 'DepositFlow:Launched':
          case 'DepositFlow:AwaitingDeposit':
          case 'DepositMonitor:MonitoringMemPool': 
          case 'DepositMonitor:DepositPendingManualApproval' :
            return "PENDING";
          default:
            return "PROCESSING";
        }
      };
    clearTransactionDtlsScreen = () => {
        this.setState({
            transactionDtls: [],
            openTransactionDetails: false,
        })
        if(this.props.refTransactionId) {
            this.props.navigate("/myAccount/transactions/")
        }
    }    

    render() {
        let headerButtonBorderStyle = getHeaderButtonBorderStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerButtonLightBgColour)
        let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)
				return (
            <div className="myAccTransactionsMain">
                <div className="myAccTopPadder">
                    <div className="myAccTopShadow" />
                </div>
                <div className="subContainer">
                    {
                        this.state.openTransactionDetails
                            ? this.state.transactionDtls.length > 0 && <TransactionDetails 
                            rejectTransaction={this.rejectTransaction}
                            approveTransaction={this.approveTransaction}
                            showSuccess={this.state.showSuccess}
                            showError={this.state.showError}
                            toggleShowSuccess={(value)=> {
                                this.setState({showSuccess: value})
                            }}
                            toggleShowError={(value)=> {
                                this.setState({showError: value})
                            }}
                            clearTransactionDtlsScreen={this.clearTransactionDtlsScreen} transactionDtls={this.state.transactionDtls} />
                            : <div>
                                <div className="transactions_container">
                                    <div className="transactionsFiltersWrap">
                                        <TransactionFilters
                                            image_base_url={this.props.image_base_url}
                                            handleChange={this.handleChange}
                                            searchText={this.state.searchText}
                                            getTransactions={this.getTransactions}
                                        />
                                        <div className="filterBtn" onClick={() => {
                                            this.setState({ openFilterModal: true })
                                        }}>
                                            <img data-e2e="filterIcon" src={`${this.props.image_base_url}${filterIcon}`} alt="filterIcon" className="filterBtnIcon" />
                                        </div>
                                        <div className="filterBtn" onClick={() => {
                                            this.handleDownloadTransaction()
                                        }}>
                                            <img data-e2e="downloadIcon" src={`${this.props.image_base_url}${downloadIcon}`} alt="filterIcon" className="downloadBtnIcon" />
                                        </div>
                                        {<Modal show={this.state.showDownloadAlertModal} className="ClearAlertModal"
                                            onHide={() => { this.setState({ showDownloadAlertModal: false }) }}>
                                            <Modal.Body className="ClearAlertModalBody">
                                                <style>{`\
                                                    body {
                                                        width: 100vw;
                                                    }
                                                `}</style>
                                                <div className="ClearAlertModalIconWrap">
                                                    <img src={`${this.props.image_base_url}${downloadAlertIcon}`} alt="downloadAlertIcon" className="ClearAlertModalIcon" />
                                                </div>
                                                <div className="ClearAlertModalTitle">Download in CSV</div>
                                                <div className="ClearAlertModalDescription">Are you sure you want to download CSV file with all the information? </div>
                                                <div className="ClearAlertModalClearBtn"
                                                    style={{ ...headerButtonStyle }}
                                                    onClick={() => {
                                                        this.setState({ showDownloadAlertModal: false });
                                                        if (this.state.allData && this.state.allData.length > 0) {
                                                            let transactionList = []
                                                            this.state.allData.map((tr) => {
                                                                let transactionObj = {
                                                                    "Transaction Id": tr.transactionId ? tr.transactionId : "N/A",
                                                                    "TX type": tr.type ? tr.type : "N/A",
                                                                    "Merchant User Id": tr.merchantUserId ? tr.merchantUserId : '-',
                                                                    "Merchant TransactionId": tr.merchantTransactionId ? tr.merchantTransactionId : '-',
                                                                    "Crypto Currency": tr.selected ? tr.selected.cryptoId : "-",
                                                                    "Crypto Amount": tr.selected ? tr.selected.amount : "-",
                                                                    "Crypto Sent amount": tr.selected ? tr.selected.actualAmountDeposited : "-",
                                                                    "Fiat Currency": tr.depositFiatCurrency ? tr.depositFiatCurrency : "-",
                                                                    "Fiat Amount": tr.depositAmount ? tr.depositAmount : "-",
                                                                    "Fiat Sent Amount": "-",
                                                                    "Transaction Fee": tr.transactionFee || tr.transactionFee == 0 ? tr.transactionFee : "-",
                                                                    "Gross Amount": tr.depositAmount ? tr.depositAmount : "-",
                                                                    "Net Amount": tr.depositAmount ? (tr.depositAmount - tr.transactionFee) : "-",
                                                                    "Status":tr.status ? this.getTransactionStatus(tr.status.code) : 'N/A',
                                                                }
                                                                transactionList.push(transactionObj)
                                                            })
                                                            csvExporter.generateCsv(transactionList)
                                                        }
                                                    }} data-e2e="download"
                                                >Download</div>
                                                <div className="ClearAlertModalGoBackBtn" onClick={() => { this.setState({ showDownloadAlertModal: false }); }} data-e2e="goBack">Go Back</div>
                                            </Modal.Body>
                                        </Modal>}
                                    </div>
                                </div>
                                {this.state.openFilterModal && <FiltersModal
                                    image_base_url={this.props.image_base_url}
                                    settings={{
                                        fromDate: moment(this.state.fromDate),
                                        toDate: moment(this.state.toDate),
                                        selectedCryptos: this.state.selectedCryptos,
                                        dateSelected: this.state.dateSelected,
                                        selectedFiatCurrencies: this.state.selectedFiatCurrencies,
                                        selectedStatusList: this.state.selectedStatusList,
                                    }}
                                    cryptos={this.props.cryptos}
                                    statusList={this.state.statusList}
                                    fiatCurrencies={this.state.fiatCurrencies}
                                    show={this.state.openFilterModal}
                                    saveSettings={this.saveSettings}
                                    resetFilters={this.resetFilters}
                                    closeModal={() => this.setState({ openFilterModal: false })}
                                    merchantTransactionId= {this?.state?.merchantTransactionId}
                                    setMerchantTransactionId= {value=> {this.setState({ merchantTransactionId: value })}}
                                />}

                                <div className='transaction_summary transaction_summary_display_block'>
                                    <div className='summary_title'>{`Summary: `}</div>
                                    <div className="summary_content">
							        Date Range  From:&nbsp;<span>{moment(this.state?.fromDate).format("YYYY-MM-DD")}</span>&nbsp; &nbsp;To:&nbsp;<span>{moment(this.state?.toDate).format("YYYY-MM-DD")}</span></div>
                                    <div className='transaction_summary_line' >
                                        <div className='summary_content'>{` Number of transactions: `} <span>{this.state.totalNumberOfTransactions}</span> </div>
                                        <div className='summary_content'>{`| Net USD Equivalent: `} <span>{parseFloat(this.state.totalUsdAmount).toFixed(2)}</span> </div>
                                    </div>
                                    <div className='transaction_summary_line' >
                                        <div className='summary_content'>{`Sum by Crypto Amount: `} 
                                            <span>{
                                                this.state.sumOfEachCrypto.length > 0 && this.state.sumOfEachCrypto.map((ele, index) => (
                                                    ele._id !== null &&
                                                    <span key={index}>{ele._id + ": " + parseFloat(ele.sum).toFixed(4) + (index != (this.state.sumOfEachCrypto.length - 1)? "| " : "")}</span>
                                                ))
                                            }
                                            </span>
                                        </div>
                                    </div>
                                </div>                               

                                <TransactionTable 
																	totalCount={this.state.totalCount} 
																	data={this.state.transactions} 
																	setPage={(page) => {
																		this.getTransactions(page)
																	}}
																	isLoadingTransactions={this.state.isLoadingTransactions}
																	openTransactionDetails={(data) => this.openTransactionDetails(data)}
                                    sortBy={this.state.sortBy}
                                    orderBy={this.state.orderBy}
                                    changeOrder={(sortBy) => {
                                        if (this.state.sortBy !== sortBy) {
                                            this.setState({
                                                sortBy, orderBy: "desc"
                                            }, () => {
                                                this.getTransactions(1)
                                            })
                                        } else {
                                            this.setState({
                                                orderBy: this.state.orderBy === "desc" ? "asc" : "desc"
                                            }, () => {
                                                this.getTransactions(1)
                                            })
                                        }
                                    }}
                                />
                            </div>
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
        cryptos: state.transaction.cryptos,
				loading: state.common.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
        saveCryptos: (data) => dispatch(actions.saveCryptos(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)((props)=> <Transactions {...props} navigate={useNavigate()} />)

const TransactionFilters = (props) => {
    return (
        <div className="transactionsSearchFilters">
            <div className="transactionsFiltersItem transactionsSearchFilter">
                <div className="transactionsFilterTextWrap">
                    <input type="text" className="transactionsFilterInput" value={props.searchText}
                        name="searchText"
                        onChange={(e) => {
                            props.handleChange(e)
                        }}
                        placeholder="Search Transactions"
                        data-e2e="Search Transaction or User ID"
                    />
                </div>
                <div className="transactionsFilterSingleIconWrap">
                    <img src={`${props.image_base_url}${searchicon}`} alt="searchicon" className="transactionsFilterSingleIcon" data-e2e="searchIcon" onClick={() => {
                        props.getTransactions(1)
                    }} />
                </div>
            </div>
        </div>
    )
}