import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiService } from '../../../common/apiCallService';
import localDb from "../../../common/localDb";
import { setLoading } from '../../../Redux/actions';
import { getEmailVal } from '../../../common/commonMethod';

function NewPassword() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  let disabled = data.newPassword === '' || data.newPassword.length === 0 || data.confirmPassword === '' || data.confirmPassword.length === 0 ? true : false;
 
  const api_url = useSelector((state) => state.config.api_url);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(true);
  const [stepOne, setStepOne] = useState(false); 
  const [email, setEmail] = useState('');
  const [verified, setVerified] = useState(false); 
  const [userType, setUserType] = useState();
  const loading = useSelector((state) => state.common.loading);
  const image_base_url = useSelector((state) => state.config.image_base_url);


  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleReturn = () => {
    window.location.href = '/';
  };

 const verifyToken = (token) => {
  dispatch(setLoading(true));
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-forgot-password',
      {
        verifyToken: token,
        domainNameUrl: localStorage.getItem("domainNameUrl"),
        hostname: window.location.hostname,
      },
      (data) => {
        dispatch(setLoading(false));
        if (data?.success) {
          const emailVal = getEmailVal(data?.email)
          setEmail(emailVal);
          setVerified(true);
          setUserType(data?.userType);
        } else {
          alert(data);
        }
      },
      (err) => {
        dispatch(setLoading(false));
        alert('Something went wrong!');
        console.log(err);
      },
    );
  };
  useEffect(() => {
    verifyToken(token);
  }, []);

  const handleResetPassowrd = () => {
    if (data.newPassword !== data.confirmPassword) {
        setError(`Password doesn't match`)
      return;
    }
    dispatch(setLoading(true));
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/new-password',
      {
        email: email,
        newPassword: data.newPassword,
        verifyToken: token,
        userType: 'b2cUser',
        domainNameUrl: localStorage.getItem("domainNameUrl"),
      },
      (data) => {
        dispatch(setLoading(false));
        if (data.msg == 'Password Changed Successfully') {
          setStepOne(true);
        }
      },
      (err) => {
        dispatch(setLoading(false));
        alert('Something went wrong!');
        console.log(err);
      },
    );
  };
  return (
    <div>
      {verified && (
        <Dialog onClose={handleClose} open={open} className="theme-modal">
          <div className="theme-modal-body">
						<div className="theme-modal-close">
							<ClearIcon onClick={handleClose} className="icon-close" />
						</div>
						<p className="theme-modal-title">New password</p>
						{!stepOne && 
							<div className="flex-col-between">
								<div>
									<div className="theme-modal-field mb">
										<p className="theme-modal-field-label">Enter your new password</p>
										<input
											type="password"
											placeholder="Enter password"
											name="newPassword"
											value={data.newPassword}
											onChange={(e) => handleChange(e)}
											className="theme-modal-field-input"
										/>
									</div>
									<div className="theme-modal-field">
										<p className="theme-modal-field-label">Confirm new password</p>
										<input
											type="password"
											placeholder="Enter password"
											name="confirmPassword"
											value={data.confirmPassword}
											onChange={(e) => handleChange(e)}
											className="theme-modal-field-input"
										/>
									</div>
									<p className="errorMessage">{error}</p>
								</div>
								<div className="theme-btn-block full-width theme-modal-submit">
									<button className={`theme-btn ${disabled ? 'disabled' : ''}`} disabled={disabled}
										onClick={handleResetPassowrd}>
										<p>Save</p>
									</button>
								</div>
							</div>
						}
					{stepOne && 
						<div>
							<div className="theme-modal-thanks">
								<img width="60" height="60" className="theme-modal-thanks-icon" src={`${image_base_url}/upd/icons/thank-you.svg`} />
								<p className="theme-modal-thanks-title">Password updated</p>
								<p className="section-text grey-text t-center">Your password has been updated, return and log in to {localDb.getSSVal("paymentProviderName") ? localDb.getSSVal("paymentProviderName") : "Cryptonpay" }</p>
							</div>
							<div className="theme-btn-block full-width theme-modal-submit">
								<button className="theme-btn" onClick={handleReturn}>
									<p>Return</p>
								</button>
							</div>
						</div>
					}
					</div>
        </Dialog>
      )}
    </div>
  );
}

export default NewPassword;
