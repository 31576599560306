import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';


const FourZeroFour = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url)
    useEffect(() => {
        setTimeout(() =>
            window.location.href = "/", 2000)
    }, [])
    return (
        <div style={{ margin: "auto" }} className='maintaincePage'>
            <div className='maintainceContainer'>
                {/* <div className='maintainceLogo'>
                    <img src={`${image_base_url}/common/cryptonpay_logo.svg`} alt="cryptonpay logo" loading='lazy' />
                </div> */}
                <div className='maintainceContent'>
                    <div>
                        <div style={{ marginBottom: "20px" }} className='maintainceImage'>
                            <img src={`${image_base_url}/maintenance/maintenanceIcon.svg`} width={100} alt="maintaince image" />
                        </div>
                        <div className='maintainceText'>
                           Landed at Wrong Place
                        </div>
                        <div className='maintainceSubtext'>
                            Redirecting to home page
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourZeroFour