import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { apiService } from '../../../common/apiCallService';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
let interval = null

const closeImage = "/common/crytoicons/close.png"
const swapIcon = "/common/crytoicons/exchange-balance.svg"

const ExchangeModal = (props) => {
    const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false)
    const [showCurrencyDropdown2, setShowCurrencyDropdown2] = useState(false)
    const [fromCrypto, setFromCrypto] = useState(null)
    const [toCrypto, setToCrypto] = useState(null)
    const [error, setError] = useState("")
    const [fromAmount, setFromAmount] = useState('')
    const [fromAmountLimit, setFromAmountLimit] = useState('')
    const [toAmount, setToAmount] = useState('')
    const [exchangeRate, setExchangeRate] = useState(null)
    const [exchangeSpread, setExchangeSpread] = useState(null)
    const [proceedBtnFlag, setProceedBtnFlag] = useState(false)
    const [exchangeInprogress, setExchangeInprogress] = useState(false)

    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);

    const getCryptoSwap = (fromCurrency, toCurrency, currencyAmount) => {
        if (currencyAmount && fromCurrency && toCurrency) {
            props.setLoading(true);
            setProceedBtnFlag(false);
            apiService(
                (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/get-merchant-crypto-or-amount-swap',
                {
                    fromCurrency: fromCurrency,
                    toCurrency: toCurrency,
                    currencyAmount: currencyAmount,
                    merchantId: localStorage.getItem("merchantId"),
                },
                (data) => {
                    let amount = data.amount * currencyAmount;
                    props.setLoading(false);
                    setExchangeSpread(data.exchangeSpread);
                    setExchangeRate(data.amount)
                    setToAmount(parseFloat(amount).toFixed(8))
                    setProceedBtnFlag(true)
                },
                (err) => {
                    console.log(err);
                    props.setLoading(false);
                },
            );
        }
    };

    const toggleFirstSelection = (payCrypto) => {
        setFromCrypto(payCrypto)
        setFromAmount(payCrypto.amount.toFixed(6))
        setFromAmountLimit(payCrypto.amount)
        setShowCurrencyDropdown(false)
        if (toCrypto) {
            let fromCurrency = payCrypto.coin;
            let toCurrency = toCrypto.coin;
            let currencyAmount = payCrypto.amount.toFixed(6);
            if (fromCurrency === "USDC" || fromCurrency === "DAI") {
                fromCurrency = "USDT"
            }
            if (toCurrency === "USDC" || toCurrency === "DAI") {
                toCurrency = "USDT"
            }
            if (fromCurrency === "USDT" && toCurrency === "USDT") {
                let amount = currencyAmount;
                amount = parseFloat(amount) - parseFloat(amount / 100);
                setExchangeRate(1)
                setToAmount(parseFloat(amount).toFixed(8))

            } else {
                getCryptoSwap(payCrypto.coin, toCrypto.coin, payCrypto.amount.toFixed(6));
            }
        }
    }

    const toggleSecondSelection = (receiveCrypto) => {
        setToCrypto(receiveCrypto)
        setShowCurrencyDropdown2(false)
        if (fromCrypto) {
            let fromCurrency = fromCrypto.coin;
            let toCurrency = receiveCrypto.coin;
            let currencyAmount = fromAmount;
            if (fromCurrency === "USDC" || fromCurrency === "DAI") {
                fromCurrency = "USDT"
            }
            if (toCurrency === "USDC" || toCurrency === "DAI") {
                toCurrency = "USDT"
            }
            if (fromCurrency === "USDT" && toCurrency === "USDT") {
                let amount = currencyAmount;
                amount = parseFloat(amount) - parseFloat(amount / 100);
                setExchangeRate(1)
                setToAmount(parseFloat(amount).toFixed(8))
            } else {
                getCryptoSwap(fromCrypto.coin, receiveCrypto.coin, fromAmount);
            }
        }
    }

    const confirmMerchantSwapTransaction = (transactionId, merchantEmail) => {
        apiService(
            (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/confirm-merchant-swap-transaction',
            {
                transactionId: transactionId,
                merchantEmail: merchantEmail,
                user_email: merchantEmail,
            }, (data) => {
                if (data === "success") {
                    if (interval) {
                        clearInterval(interval)
                        interval = null
                    }
                    setExchangeInprogress(false)
                    resetValues()
                    props.setExchangeModal(false);
                    props.setLoading(false);
                    props.getBalances();
                    setFromCrypto(null)
                    setToCrypto(null)
                    setToAmount('')
                    setFromAmount('')
                } else {
                    props.setLoading(false);
                    setError("Swap transaction failed while confirming.")
                    setExchangeInprogress(false)
                }
            }, (err) => {
                setError("Swap transaction failed while confirming.(error)")
                props.setLoading(false);
                setExchangeInprogress(false)
                console.log(err.message, "err");
            });
    };

    const handleExchange = () => {
        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/create-merchant-swap-transaction',
            {
                blockChain: fromCrypto.cryptoName,
                fromAmount,
                toAmount,
                fromCrypto: fromCrypto.coin,
                toCrypto: toCrypto.coin,
                exchangeRate: exchangeRate,
                exchangeSpread,
                merchantId: localStorage.getItem("merchantId"),
                merchantEmail: localStorage.getItem("user_name")
            },
            (data) => {
                if (data && data.status && data.status.code === "MerchantSwapFlow:CreateSwapRequest") {
                    confirmMerchantSwapTransaction(data.transactionId, localStorage.getItem("user_name"));
                } else if (data && data.msg && data.msg !== "") {  
                    setError(data.msg)
                    setExchangeInprogress(false)
                    props.setLoading(false);
                } else {
                    setError("Create Exchange Transaction Failed!")
                    setExchangeInprogress(false)
                    props.setLoading(false);
                }
            },
            (err) => {
                props.setLoading(false);
                console.log(err.message, "err");
                setExchangeInprogress(false)
                setError("Exchange Transaction Failed!")
            });
    };

    const callSwapForRefresh = () => {
        if (fromCrypto && toCrypto && fromAmount && !exchangeInprogress) {
            getCryptoSwap(fromCrypto.coin, toCrypto.coin, fromAmount)
        }
    }

    useEffect(() => {
        if (interval) {
            clearInterval(interval)
        }
        if (!interval && fromCrypto && toCrypto && fromAmount) {
            interval = setInterval(() => {
                callSwapForRefresh()
            }, 30000)
        }
    }, [fromCrypto, toCrypto, fromAmount])

    useEffect(() => {
        if (!fromCrypto) {
            let fCryptoList = props.merchantBalanceList.filter(crypto => crypto.coin === props.fromExchangeCurrency)
            if (fCryptoList.length > 0) {
                setToCrypto(null)
                setToAmount('')
                setFromAmount('')
                toggleFirstSelection(fCryptoList[0]);
            }
        }
        if (checkValueInSS("colorMode")) {
            setColorMode(sessionStorage.getItem("colorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, []);

    const resetValues = () => {
        setFromCrypto(null)
        setToCrypto(null)
        setToAmount('')
        setFromAmount('')
    }

    let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

    return (
            <Dialog onClose={() => {
                if (interval) {
                    clearInterval(interval)
                    interval = null
                }
                resetValues()
                setError("")
                props.setExchangeModal(false)
            }} open={props.show} >
                <div className="exchange-modal-div balance-exchange-modal">
                    <div className='balance-header'>
                        <div className="exchange-modal-header">Exchange</div>
                        <div className="FilterModalCloseButtonWrap balance-close"
                            onClick={() => {
                                if (interval) {
                                    clearInterval(interval)
                                    interval = null
                                }
                                resetValues()
                                props.setExchangeModal(false);
                                setError("")
                            }}
                        ><img src={`${props.image_base_url}${closeImage}`} alt="closeImage" className="FilterModalCloseButton" /></div>
                    </div>
                    <div className='exchange-parent'>
                        <div className='FilterModalContent'>
                            <div className='FilterModalBody'>
                                <div className="FilterModalInputGroup balance">
                                    <div className="FilterModalInputLabel">
                                        Crypto Currency
                                    </div>
                                    <div className="FilterModalInputDropdown" onClick={() => {
                                        setShowCurrencyDropdown(true)
                                        setShowCurrencyDropdown2(false)
                                    }}>
                                        <div className="FilterModalInputText">
                                            {!fromCrypto ? ('Select Crypto') : (
                                                <div className="currencyListIconNameWrap">
                                                    <div className="currencyList_Name" >
                                                        {fromCrypto.crypName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                                    </div>
                                    {showCurrencyDropdown && <div className="FilterModalCurrencyDropdownlist">
                                        <div className="FM_currencyDrop_All FM_currencyDrop_AllEM" onClick={() => {
                                            setShowCurrencyDropdown(false)
                                        }}>
                                            {!fromCrypto ? ('Select Crypto') : (
                                                <div className="currencyListIconNameWrap">
                                                    <div className="currencyList_Name" >
                                                        {fromCrypto.crypName}
                                                    </div>
                                                </div>
                                            )}
                                            <span className="FilterModalInputDropUpIconWrap FilterModalInputDropUpIconWrapEM">
                                                <i className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                            </span>
                                        </div>
                                        {props.merchantBalanceList.map((crypto, index) => {
                                            let { crypName, icon, coin } = crypto;
                                            return (
                                                <div key={index} className="currencyListItemWrap" onClick={() => {
                                                    toggleFirstSelection(crypto)
                                                    setShowCurrencyDropdown(false)
                                                }}>
                                                    <div className="currencyListIconNameWrap">
                                                        <img src={`${props.image_base_url}${icon}`} alt={coin} className="currencyList_Icon" />
                                                        <div className="currencyList_Name">
                                                            {crypName}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='FilterModalContent'>
                            <div className='FilterModalBody'>
                                <div className="FilterModalInputGroup balance">
                                    <div className="FilterModalInputLabel">
                                        Amount
                                    </div>
                                    <div className="FilterModalInputDropdown" onClick={() => {
                                        setShowCurrencyDropdown2(false)
                                        setShowCurrencyDropdown(false)
                                    }}>
                                        <div className="FilterModalInputText">
                                            <input placeholder='Enter Amount' value={fromAmount} type='text' className='exchange-modal-input' onChange={(e) => {
                                                let regex = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$")
                                                setFromAmount(e.target.value);
                                                if (regex.test(e.target.value.trim())) {
                                                    if (e.target.value > parseFloat(fromAmountLimit)) {
                                                        setError(`Insufficient funds in account.`)
                                                    } else {
                                                        setError("");
                                                    }
                                                    setFromAmount(e.target.value)
                                                    if (fromCrypto && toCrypto && parseFloat(e.target.value.trim()) > 0) {
                                                        getCryptoSwap(fromCrypto.coin, toCrypto.coin, parseFloat(e.target.value.trim()));
                                                    }
                                                }
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='seperator-div'>
                        <img src={`${props.image_base_url}${swapIcon}`} />
                        <div className='bar'></div>
                    </div>
                    <div className='exchange-parent'>
                        <div className='FilterModalContent'>
                            <div className='FilterModalBody'>
                                <div className="FilterModalInputGroup balance">
                                    <div className="FilterModalInputLabel">
                                        To Crypto Currency
                                    </div>
                                    <div className="FilterModalInputDropdown" onClick={() => {
                                        setShowCurrencyDropdown2(true)
                                        setShowCurrencyDropdown(false)
                                    }}>
                                        <div className="FilterModalInputText">
                                            {!toCrypto ? ('Select Crypto') : (
                                                <div className="currencyListIconNameWrap">
                                                    <div className="currencyList_Name" >
                                                        {toCrypto.crypName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                                    </div>
                                    {showCurrencyDropdown2 && <div className="FilterModalCurrencyDropdownlist">
                                        <div className="FM_currencyDrop_All FM_currencyDrop_AllEM" onClick={() => {
                                            setShowCurrencyDropdown2(false)
                                        }}>
                                            {!toCrypto ? ('Select Crypto') : (
                                                <div className="currencyListIconNameWrap">
                                                    <div className="currencyList_Name" >
                                                        {toCrypto.crypName}
                                                    </div>
                                                </div>
                                            )}
                                            <span className="FilterModalInputDropUpIconWrap FilterModalInputDropUpIconWrapEM">
                                                <i className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                            </span>
                                        </div>
                                        <div className="FilterModalCurrencyDropdownlistWrap FilterModalCurrencyDropdownlistWrapEM">
                                            {props.merchantBalanceList.map((crypto, index) => {
                                                let { crypName, icon, coin } = crypto;
                                                return (
                                                    <div key={index} className="currencyListItemWrap" onClick={() => {
                                                        toggleSecondSelection(crypto)
                                                        setShowCurrencyDropdown2(false)
                                                    }}>
                                                        <div className="currencyListIconNameWrap">
                                                            <img src={`${props.image_base_url}${icon}`} alt={coin} className="currencyList_Icon" />
                                                            <div className="currencyList_Name">
                                                                {crypName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='FilterModalContent'>
                            <div className='FilterModalBody'>
                                <div className="FilterModalInputGroup balance">
                                    <div className="FilterModalInputLabel">
                                        You get
                                    </div>
                                    <div className="FilterModalInputDropdown" onClick={() => {
                                        setShowCurrencyDropdown(false)
                                        setShowCurrencyDropdown2(false)
                                    }}>
                                        <div className="FilterModalInputText">
                                            <input placeholder='to amount' value={toAmount} disabled type='text' className='exchange-modal-input' onChange={(e) => { setToAmount(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='FilterModalContent'>
                        <div className='FilterModalBody'>
                            <div className="FilterModalInputGroup">
                                <div className="FilterModalInputLabel">
                                    Rate
                                </div>
                                <div className="FilterModalInputDropdown" onClick={() => {
                                    setShowCurrencyDropdown(false)
                                    setShowCurrencyDropdown2(false)
                                }}>
                                    <div className="FilterModalInputText">
                                        <input placeholder='Rate' value={fromCrypto && toCrypto ?
                                            fromAmount <= 0 ? "From amount must be greater than zero"
                                                : `1${fromCrypto.coin ? fromCrypto.coin : "N/A"}=${exchangeRate ? exchangeRate : "N/A"} ${toCrypto.coin}`
                                            : "Select both the currencies"} disabled type='text' className='exchange-modal-input' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {error !== "" && <div className="d-flex justify-content-center text-danger mt-3">{error}</div>}
                    <div className='FilterModalContent'>
                        <div className='FilterModalBody'>
                            <div className={`FilterModalSave ${!fromCrypto || !toCrypto || fromAmount <= 0 || !proceedBtnFlag || props.loading || exchangeInprogress || error !== "" ? "DisableFilterModalSave mt-2" : ""}`} onClick={() => {
                                if (!fromCrypto || !toCrypto || fromAmount <= 0 || !proceedBtnFlag || props.loading || exchangeInprogress || error !== "") {
                                    return
                                }
                                setExchangeInprogress(true)
                                handleExchange();
                            }}
                            style={ !fromCrypto || !toCrypto || fromAmount <= 0 || !proceedBtnFlag || props.loading || exchangeInprogress || error !== "" ? {} : {...headerButtonStyle}}
                            >
                                EXCHANGE
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        loading: state.common.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(actions.setLoading(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeModal)

