import React from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';
import CodeCard from './CodeCard';
import SingleParameter from './SingleParameter';
const parameters = [
    {
        name: "transactionId",
        description: "A unique transaction identifier that can be used as a reference in other API calls."
    },
    {
        name: "merchantId",
        description: "A unique ID of the merchant."
    },
    {
        name: "depositMethod",
        description: "An optional parameter which indicate method for transaction  for e.g. wallet, other, card. "
    },
    {
        name: "hostname",
        description: "An optional parameter which use for redirecting url."
    },
]

const returns = [
    {
        name: "merchantId",
        description: "A unique ID of the merchant."
    },
    {
        name: "merchantName",
        description: "A merchant name."
    },
    {
        name: "redirectUrl",
        description: "The url used for redirecting to merchant."
    },
    {
        name: "depositAmount",
        description: "A positive integer in cents representing the value of the transaction. e.g. 100 EUR would be 10000 / 50 USD would be 5000"
    },
    {
        name: "fiatCurrency",
        description: "The currency name used in the transaction. e.g USD, EUR etc."
    },
    {
        name: "merchantUserId",
        description: "The user ID sent by the merchant when this transaction was created."
    },
    {
        name: "transactionStatus",
        description: "current transaction status"
    },
    {
        name: "transactionId",
        description: "The transaction ID sent by the merchant when this transaction was created."
    },
    {
        name: "status",
        description: "A status object with the status of the transaction."
    },
]

const tabSpace = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
const request = {
    type: "Request",
    method: "Post",
    url: ".../pga/GetTransactionDetails",
    code: <div>{`{`}<br />
        {tabSpace}{`"transactionId": "string",`}<br />
        {tabSpace}{`"merchantId": "string",`}<br />
        {tabSpace}{`"depositMethod": "string",`}<br />
        {tabSpace}{`"hostname": "string",`}<br />
        {`}`}</div>,
}

const response = {
    type: "Return",
    method: "Post",
    url: ".../pga/GetTransactionDetails",
    code: <div>{`{`}<br />
        {tabSpace}{`"merchantId": "string",`}<br />
        {tabSpace}{`"merchantName": "string",`}<br />
        {tabSpace}{`"redirectUrl": "string",`}<br />
        {tabSpace}{`"depositAmount": "number",`}<br />
        {tabSpace}{`"fiatCurrency": "string",`}<br />
        {tabSpace}{`"merchantUserId": "string",`}<br />
        {tabSpace}{`"transactionStatus": "string",`}<br />
        {tabSpace}{`"transactionId": "string",`}<br />
        {tabSpace}{`"status": {`}<br />
        {tabSpace}{tabSpace}{`"code": "string",`}<br />
        {tabSpace}{tabSpace}{`"message": "string",`}<br />
        {tabSpace}{`}`}<br />
        {`}`}</div>,
}

const GetTransactions = (props) => {
    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <div className="tool_main">
                        <div className="tool_heading" data-e2e="Get Transactions">GET TRANSACTIONS</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    Get transaction allows a merchant to retrieve the status of a transaction. <br />
                                    GET …/pga/GetTransactionDetails <br />
                                </div>
                            </div>
                            <div className="tools_right_part">
                            </div>
                        </div>
                        <div className="tools_content_wrap mt-0">
                            <div className="tools_left_part">
                                <div className="tools_sub_heading">Parameters</div>
                                {parameters.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <CodeCard data={request} />
                            </div>
                        </div>
                        <div className="tools_content_wrap tools_content_wrap_margin" >
                            <div className="tools_left_part">
                                <div className="tools_sub_heading">Returns</div>
                                {returns.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <CodeCard data={response} />
                            </div>
                        </div>
                    </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout>
    )
}

export default GetTransactions