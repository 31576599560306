import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import * as actions from '../../../../Redux/actions';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../../common/theme';

function EmailVerfication({ user_email, open, onClose, setLoading, openLogin, modal, loginSetError }) {
  const api_url = useSelector((state) => state.config.api_url);
  const [emailVerificationCode, changeEmailVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [fetching, setFetching] = useState('')
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  useEffect(() => {
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, [])
  const onVerifyCode = () => {
    setLoading(true);
    if (emailVerificationCode && emailVerificationCode !== '') {
      let payload = {
        user_email: user_email,
        domainNameUrl: localStorage.getItem("domainNameUrl")
      };
      if (modal && modal === 'addressBook') {
        payload = {
          ...payload,
          addressVerificationCode: emailVerificationCode,
        };
      } else {
        payload = {
          ...payload,
          emailVerificationCode: emailVerificationCode,
        };
      }
      apiService(
        (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-verify-email',
        {
          payload,
        },
        (data) => {
          setLoading(false);
          setError('');
          setSuccess('')
          if (data) {
            if (data.success === true && data.code === 2) {
              onClose();
              openLogin();
              loginSetError("");
            } else {
              setError(data.msg ? data.msg : 'Something went wrong! Email verification failed.');
            }
          } else {
            setError('Something went wrong! Email verification failed.');
          }
        },
        (err) => {
          setLoading(false);
          setSuccess('')
          setError('Something went wrong! Email verification failed.');
          console.log(err);
        },
      );
    } else {
      setError('Please enter verification code recieved by mail!');
    }
  };

  const onResendBtnClick = () => {
    setSuccess('')
    setFetching('...Sending Secret Code')
    let payload = {
      email: user_email,
      domainNameUrl: localStorage.getItem("domainNameUrl")
    };

    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/generate-new-token',
      {
        payload,
      },
      (data) => {
        setFetching('')
        setLoading(false);
        setError('');
        if (data) {
          if (data.success === true) {
            setError('')
            setSuccess("Sent code on Email")
          } else {
            setSuccess('')
            setError('Something went wrong! Email Resend failed.');
          }
        } else {
          setSuccess('')
          setError('Something went wrong! Email Resend failed.');
        }
      },
      (err) => {
        setLoading(false);
        setFetching('')
        setSuccess('')
        setError('Something went wrong! Email Resend failed.');
        console.log(err);
      },
    );

  }
  const disabled = emailVerificationCode.length !== 6;
  let headerButtonBorderStyle = getHeaderButtonBorderStyle(b2cColorMode, headerButtonDarkBgColour, headerButtonLightBgColour)
  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog onClose={onClose} open={open} className="theme-modal">
        <div className="theme-modal-body">
          <div className="theme-modal-close">
            <ClearIcon onClick={onClose} className="icon-close" />
          </div>
          <p className="theme-modal-title">Email Verification</p>
          <p className="theme-modal-text t-center">Open your email <span className="blue-text">{`(${user_email})`}</span> and enter the secret code to continue <br />Please check your junk/spam mail folder</p>
          <br />
          <div className="theme-modal-field">
            <p className="theme-modal-field-label">Secret Code</p>
            <input
              value={emailVerificationCode}
              type="text"
              className="theme-modal-field-input"
              placeholder=""
              onChange={(e) => {
                setError('');
                changeEmailVerificationCode(e.target.value);
              }}
            />
          </div>
          <div className="theme-btn-block">
            <div className="theme-modal-forgot" onClick={onResendBtnClick} style={{...headerButtonBorderStyle}} >Resend Confirmation</div>
          </div>

          {error !== '' && <div className="errorMessage">{error}</div>}
          {success !== '' && <div className="successMessage">{success}</div>}
          {fetching !== '' && <div className="fetchingMessage">{fetching}</div>}

          <div className="theme-btn-block full-width theme-modal-submit">
            <button disabled={disabled} className="theme-btn" style={{ ...headerButtonStyle }} onClick={() => { if (!disabled) { onVerifyCode(); } }}>
              <p>Verify</p>
            </button>
          </div>
        </div>

      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
  };
};

export default connect(null, mapDispatchToProps)(EmailVerfication);
