import * as actionTypes from '../actions/actionTypes'

const initialState = {
    searchValue: '',
};
const setSearchValue = (state, action) => {
    return {
        ...state,
        searchValue: action.data,
    }
}
const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_VALUE: return setSearchValue(state, action);
        default:
            return state;
    }
};

export default walletReducer;