import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import browser from 'browser-detect';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../../../Redux/actions';
import { saveActivity } from '../../../../common/activityTrackingService';
import { apiService } from '../../../../common/apiCallService';
import { getDeviceIP, removeHttp } from '../../../../common/getIP';
import localDb from "../../../../common/localDb";
import ForgotPassword from '../ForgotPassword';
import { checkValueInSS, getHeaderButtonBorderStyle } from '../../../../common/theme';
import EmailVerfication from '../EmailVerfication';
import snsWebSdk from '@sumsub/websdk';
import $ from 'jquery';
import { useGeeTest as GeeTest } from 'react-geetest-v4';
import { saveToSessionStorage } from '../../../../common/LoadingAndRedirection';
import { getEmailVal } from '../../../../common/commonMethod';

const browserInfo = browser();

function Login({
  open,
  onClose,
  signUpModelOpenInLogin,
  setLoading,
  setToken,
  set2faActivationStatus,
  is2faActivated,
  handleOpenLoginModel = {}
}) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const b2cTheme = useSelector((state) => state.common.b2cTheme);
  const [error, setError] = useState('');
  const [verificationCode2fa, change2faVerificationCode] = useState('');
  const [is2faModalOpen, set2faModalOpen] = useState(false);
  const [loginSuccessData, setLoginSuccessData] = useState(null);
  const [forgotPassWordModel, setForgotPasswordModel] = useState(false);
  const navigate = useNavigate();
  const [ipAddress, setIpAddress] = useState('');
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [captchaData, setCaptchaData] = useState(null);
  const captchaId = useSelector((state) => state.config.geetest_captcha_id);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [res, setRes] = useState({ enabled: false });
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const [logoWidth, setlogoWidth] = useState(150);
  const [openEmailVerifycation, setOpenEmailVerification] = useState(false);
  const [emailForVerifycation, setEmailForVerification] = useState("");

  const handleEmailValidation = (email) => {
    setOpenEmailVerification(true)
    setEmailForVerification(email)
  }

  const getSingleFeatureConfig = (featureName) => {
    return new Promise(async (resolve, reject) => {
      await apiService(
        (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-single-feature-config',
        {
          featureName,
        },
        async (data) => {
          if (data) {
            resolve(data);
          }
        },
        (err) => {
          resolve(false);
        },
      );
    });
  };

  useEffect(() => {
    checkCaptcha();
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
    if (checkValueInSS("logoWidth")) {
      setlogoWidth(sessionStorage.getItem("logoWidth"))
    }
    if (!localStorage.getItem("domainNameUrl") || !sessionStorage.getItem("domainId")) {
			getPaymentProviderForDomain()
		}
  }, []);

  const getPaymentProviderForDomain = async () => {
		try {
			await apiService((window?.location?.hostname === "localhost" ? api_url : "") + '/restapi/get-payment-provider-for-domain', {
				redirectUrl: removeHttp(window?.location?.hostname)
			}, (data) => {
				saveToSessionStorage(data)
        if (checkValueInSS("headerButtonDarkBgColour")) {
          setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
          setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
			}, (err) => {
				alert("Domain is not configured to any payment provider!")
				console.error(err);
			})
		} catch (err) {
			console.error(err)
		}
	}

  const checkCaptcha = async () => {
    setCaptchaData(null);
    setShowCaptcha(false);
    setIsCaptcha(false);
    const result = await getSingleFeatureConfig('Login Captcha');
    setRes(result);
    if (result) {
      if (result.enabled === true) {
        setShowCaptcha(result.enabled);

        var product = 'float';
        if (product !== 'bind') {
          $('#btn').remove();
        }

        // initGeetest4(
        //   {
        //     captchaId: captchaId,
        //     product: product,
        //   },
        //   function (gt) {
        //     window.gt = gt;
        //     gt.appendTo('#captcha').onSuccess(function (e) {
        //       var result = gt.getValidate();
        //       setCaptchaData(result);
        //       setIsCaptcha(true);
        //     });

        //     $('#btn').click(function () {
        //       gt.showBox();
        //     });
        //     $('#reset_btn').click(function () {
        //       gt.reset();
        //     });
        //   },
        // );

        const captchaResult = GeeTest(captchaId,
          {
            product: product,
            containerId: 'captcha',
          }
        );

        if (captchaResult.state === "success") {
          setCaptchaData(result);
          setIsCaptcha(true);
        }

        return () => {
          setCaptchaData(null);
        };
      }

      return () => {
        setCaptchaData(null);
      };
    }
};

const getNewAccessToken = () => {
  apiService(
    (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/kyc-getAccessToken',
    { domainNameUrl: localStorage.getItem("domainNameUrl") },
    (data) => {
      // setLoading(false)
      if (data) {
        //SDKADDED
        let snsWebSdkInstance = snsWebSdk
          .init(data.token, () => getNewAccessToken())
          .withConf({
            lang: 'en',
            uiConf: {
              customCss: 'https://url.com/styles.css',
            },
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on('idCheck.stepCompleted', (payload) => {
            console.log('stepCompleted', payload);
          })
          .on('idCheck.onError', (error) => {
            console.log('onError', error);
          })
          .build();

        snsWebSdkInstance.launch('#sumsub-websdk-container2');
      }
    },
    (err) => {
      // setLoading(false)
      // setError('Error');
      console.log(err);
    },
  );
};

const logo = b2cTheme === 'light' ? '/homeb2c/cryptoLightLogo.png' : '/common/cryptonpay_logo.svg';

const [data, setData] = useState({
  email: '',
  password: '',
});
const handleChange = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
};

let disabled = data.email.length < 0 || data.email === '' || data.password.length < 0 || data.password === '' ? true : false;
if (res.enabled === true) {
  disabled = (disabled || !isCaptcha);
}

const getIp = (userEmail) => {
  axios
    .get('https://api.ipify.org?format=json')
    .then((res) => {
      getB2CUserIpAddress(userEmail, res.data.ip);
    })
    .catch((err) => err);
};

const getB2CUserIpAddress = async (userEmail, ip) => {
  setLoading(true);
  apiService(
    (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-b2c-user-ip-address',
    {
      email: userEmail,
      ipAddress: ip,
      paymentProviderName: sessionStorage.getItem("paymentProviderName"),
      domainNameUrl: localStorage.getItem("domainNameUrl"),
    },
    (data) => {
      setLoading(false);
      if (data) {
        if (data.success === true) {
          console.log('ip address avaiable');
        } else {
          setError('Unable to get user Ip Address');
        }
      } else {
        setError('Unable to get user Ip Address');
      }
    },
    (err) => {
      setLoading(false);
      console.log(err);
      if (err && err.responseJSON && err.responseJSON.msg && err.responseJSON.msg === 'email verification pending!') {
        setError('Email verification pending!');
        handleEmailValidation(data.email);
      }
    },
  );
};
const handleGetStarted = async () => {
  const hostName = window.location.hostname.replace(/^www\./, '')
  setLoading(true);
  apiService(
    (hostName === 'localhost' ? api_url : '') + '/restapi/b2c-login',
    {
      user_email: data.email,
      password: data.password,
      captchaData: captchaData,
      domainNameUrl: localStorage.getItem("domainNameUrl")?localStorage.getItem("domainNameUrl"): hostName 
    },
    async (data) => {
      setLoading(false);
      if (data) {
        if (data.success === true) {
          setLoginSuccessData(data);
          const emailVal = getEmailVal(data?.user_email)
          data.user_email = emailVal
          localStorage.setItem('user_name', data.user_email);
          localStorage.setItem("domainNameUrl", data.domainNameUrl ? data.domainNameUrl : null);
          sessionStorage.setItem("domainId", data.domainId ? data.domainId : null);
          sessionStorage.setItem("paymentProviderId", data.paymentProviderId ? data.paymentProviderId : null);
          sessionStorage.setItem("paymentProviderName", data.paymentProviderName ? data.paymentProviderName : null);
          sessionStorage.setItem("font", data.font);
          sessionStorage.setItem("defaultTheme", data.defaultTheme);
          if (sessionStorage.getItem("colorMode") === "default") {
            sessionStorage.setItem("colorMode", data.defaultTheme === "Dark Theme" ? "dark" : "light");
          }
          sessionStorage.setItem("defaultB2CTheme", data.defaultB2CTheme);
          if (sessionStorage.getItem("b2cColorMode") === "default") {
            sessionStorage.setItem("b2cColorMode", ["Dark Theme", "Light Theme"].includes(data.defaultB2CTheme) ? (data.defaultB2CTheme === "Dark Theme" ? "dark" : "light") : (data.defaultTheme === "Dark Theme" ? "dark" : "light"));
          }
          sessionStorage.setItem("headerLightBgColour", data.headerLightBgColour);
          sessionStorage.setItem("headerDarkBgColour", data.headerDarkBgColour);
          sessionStorage.setItem("headerButtonLightBgColour", data.headerButtonLightBgColour);
          sessionStorage.setItem("headerButtonDarkBgColour", data.headerButtonDarkBgColour);
          sessionStorage.setItem("headerLightTextColour", data.headerLightTextColour);
          sessionStorage.setItem("headerDarkTextColour", data.headerDarkTextColour);
          sessionStorage.setItem("headerLightLinkColour", data.headerLightLinkColour);
          sessionStorage.setItem("headerDarkLinkColour", data.headerDarkLinkColour);
          sessionStorage.setItem("themeToggleButton", data.themeToggleButton);
          sessionStorage.setItem("bodyLightTextColour", data.bodyLightTextColour);
          sessionStorage.setItem("bodyDarkTextColour", data.bodyDarkTextColour);
          sessionStorage.setItem("logoWidth", data.logoWidth);

          if (data.is2faActivated === true) {
            setLoginData(data, 'verify');
          } else {
            setLoginData(data);
          }
          let deviceIp = await getDeviceIP();
          saveActivity({
            EventPage: 'Home-B2C',
            EventType: 'LoginSuccess',
            attribute1: data.b2cUserId ? data.b2cUserId : '',
            attribute2: data.user_email ? data.user_email : '',
            attribute3: navigator.language,
            device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
            browser_type: browserInfo.name,
            browser_version: browserInfo.version,
            language: navigator.language,
            ip_address: deviceIp,
          });
        } else {
          setError(data.msg ? data.msg : 'Something went wrong! login failed.');
          let deviceIp = await getDeviceIP();
          saveActivity({
            EventPage: 'Home-B2C',
            EventType: 'LoginFail',
            attribute1: data.b2cUserId ? data.b2cUserId : '',
            attribute2: data.email ? data.email : '',
            attribute3: navigator.language,
            device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
            browser_type: browserInfo.name,
            browser_version: browserInfo.version,
            language: navigator.language,
            ip_address: deviceIp,
          });
        }
      } else {
        setError('Something went wrong! login failed.');
      }
      setCaptchaData(null);
    },
    async (err) => {
      setLoading(false);
      console.log(err);
      if (err && err.responseJSON && err.responseJSON.msg && err.responseJSON.err && err.responseJSON.err === 'Invalid login') {
        setError('Invalid login')
      }
      else if (err && err.responseJSON && err.responseJSON.msg && err.responseJSON.msg === 'email verification pending!') {
        setError('Email verification pending!');
        handleEmailValidation(data.email);
      }
      else {
        setError('login failed! Invalid email or password.');
        let deviceIp = await getDeviceIP();
        saveActivity({
          EventPage: 'Home-B2C',
          EventType: 'LoginFail',
          attribute1: data.b2cUserId ? data.b2cUserId : '',
          attribute2: data.email ? data.email : '',
          attribute3: navigator.language,
          device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      }
      setCaptchaData(null);
      checkCaptcha();
    },
  );
};

const setLoginData = async (data, verify) => {
  if (verify === "verify") {
    set2faModalOpen(true);
  }
  else {
    let loginData = data ? data : loginSuccessData;
    localStorage.setItem('token', loginData.token);
    if (!sessionStorage.getItem("b2cColorMode") || sessionStorage.getItem("b2cColorMode") === '') {
      sessionStorage.setItem("b2cColorMode", 'light');
    }
    localStorage.setItem('firstName', loginData.firstName);
    localStorage.setItem('lastName', loginData.lastName);
    if (loginData.is2faActivated === true || loginData.is2faActivated === false) {
      set2faActivationStatus(loginData.is2faActivated);
      localStorage.setItem('2FAStatus', loginData.is2faActivated);
    } else {
      localStorage.setItem('2FAStatus', null);
    }
    if (loginData.b2cUserId && loginData.b2cUserId !== '') {
      localStorage.setItem('b2cUserId', loginData.b2cUserId);
    }
    localStorage.setItem('loginType', 'B2C');
    setToken(loginData.token);
    await getIp(loginData.user_email);
    onClose();
    navigate('/user-wallet-home');
    getNewAccessToken()
  }
};

const onVerifyCode = (token) => {
  if (verificationCode2fa && verificationCode2fa.length === 6) {
    setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-verify-2fa-token',
      {
        email: data.email,
        token,
        user2faToken: verificationCode2fa,
        domainNameUrl: localStorage.getItem("domainNameUrl")
      },
      (data) => {
        setLoading(false);
        if (data.success === true) {
          setLoginData();
        } else {
          setError('Invalid verification code!');
        }
      },
      (err) => {
        setLoading(false);
        alert('Something went wrong!');
        console.log(err);
      },
    );
  } else {
    setError('Invalid token! 2FA token must have 6-digit only');
  }
};

const handleKeypress = (e) => {
  if (e.charCode === 13) {
    handleGetStarted();
  }
};

const handleSignUp = () => {
  signUpModelOpenInLogin();
};
const handleForgotPasswordModel = () => {
  setError('');
  setForgotPasswordModel(true);
};

const handleCloseForgotModel = () => {
  setForgotPasswordModel(false);
};
const disabledVerify = verificationCode2fa.length !== 6 ? true : false;

let paymentProviderName = localDb.getSSVal("paymentProviderName");
let paymentProviderId = localDb.getSSVal("paymentProviderId")
let domainId = localDb.getSSVal("domainId")

let headerButtonStyle = {}
if (b2cColorMode === "dark") {
  if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
    headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
    headerButtonStyle.background = headerButtonDarkBgColour;
    headerButtonStyle.borderColor = headerButtonDarkBgColour;
  }
  if (headerDarkTextColour && headerDarkTextColour !== "") {
    headerButtonStyle.color = headerDarkTextColour;
  }
} else {
  if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
    headerButtonStyle.backgroundColor = headerButtonLightBgColour;
    headerButtonStyle.background = headerButtonLightBgColour;
    headerButtonStyle.borderColor = headerButtonLightBgColour;
  }
  if (headerLightTextColour && headerLightTextColour !== "") {
    headerButtonStyle.color = headerLightTextColour;
  }
}
let headerButtonBorderStyle = getHeaderButtonBorderStyle(b2cColorMode, headerButtonDarkBgColour, headerButtonLightBgColour)
return (
  <form>
    {!forgotPassWordModel && !emailForVerifycation && <Dialog open={open} className="theme-modal">
      <div className="theme-modal-body">
        <div className="w-100">
          <img src={`${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/logo${b2cTheme === 'light' ? "" : "dark"}.png` : logo}`} alt="crypton-logo-dark-mode"
            width={logoWidth}
            className='m-auto pb-2'
          />
        </div>
        {is2faModalOpen ? (
          <div>
            <p className="theme-modal-title">2FA Verification</p>
            <p className="theme-modal-text mb t-center">Open Google Authenticator app and enter the secret key to continue</p>
            <div className="theme-modal-field">
              <p className="theme-modal-field-label" data-e2e="verificationCode">Verification Code</p>
              <input
                value={verificationCode2fa}
                type="text"
                className="theme-modal-field-input"
                placeholder=""
                onChange={(e) => {
                  setError('');
                  change2faVerificationCode(e.target.value);
                }}
              />
              {error !== '' && <div className="errorMessage">{error}</div>}
            </div>
            <div className="theme-btn-block full-width theme-modal-submit">
              <button disabled={disabledVerify}
                className={`theme-btn ${disabledVerify ? 'disabled' : ''}`}
                onClick={() => { if (!disabledVerify) { onVerifyCode(loginSuccessData.token); } }}
                data-e2e="verify"
                style={{ ...headerButtonStyle }}
              >
                <p>Verify</p>
              </button>
            </div>
          </div>
        ) : (
          <form>
            <p className="theme-modal-title">Log In to {paymentProviderName ? paymentProviderName : "Cryptonpay"}</p>
            <div className="theme-modal-field mb">
              <p className="theme-modal-field-label">Email</p>
              <input
                className="theme-modal-field-input"
                placeholder="johnsmith@payment.com"
                name="email"
                type="email"
                defaultValue={data.email}
                onChange={(e) => handleChange(e)}
                data-e2e="email"
              />
            </div>
            <div className="theme-modal-field">
              <p className="theme-modal-field-label">Password</p>
              <input
                className="theme-modal-field-input"
                placeholder="Your password"
                name="password"
                type="password"
                defaultValue={data.password}
                onKeyPress={(e) => handleKeypress(e)}
                onChange={(e) => handleChange(e)}
                data-e2e="passWord"
              />
            </div>
            <div className="theme-btn-block">
              <div className="theme-modal-forgot" data-e2e="forgotPassWord" onClick={() => handleForgotPasswordModel() && onClose()} style={{ ...headerButtonBorderStyle }}>Forgot Password</div>
            </div>
            {showCaptcha && (
              <div className="theme-modal-captcha">
                <div id="captcha">
                  <div id="btn" class="btn-geet">
                    Captcha
                  </div>
                </div>
                <br />
              </div>
            )}
            {error !== '' && (
              <div className="errorMessage" data-e2e="errorMessage">
                {error}
              </div>
            )}
            <div className="theme-btn-block full-width theme-modal-submit">
              <div disabled={disabled} data-e2e="getStarted" className={`theme-btn ${disabled ? 'disabled' : ''}`}
                onClick={() => { if (!disabled) { handleGetStarted(); } }} style={{ ...headerButtonStyle }}>
                <p>Log In</p>
              </div>
            </div>

            <div className="theme-modal-account">
              Don't have an account?{' '}
              <button type="button" className="theme-modal-account-btn" onClick={handleSignUp} style={{ ...headerButtonBorderStyle }} data-e2e="signUp">Sign Up</button>
            </div>
          </form>
        )}
      </div>
    </Dialog>}
    {forgotPassWordModel && <ForgotPassword onClose={handleCloseForgotModel} open={forgotPassWordModel} />}
    {openEmailVerifycation && (
      <EmailVerfication
        user_email={emailForVerifycation}
        openLogin={() => {
          handleOpenLoginModel();
        }}
        open={openEmailVerifycation}
        onClose={() => {
          handleOpenLoginModel()
          setEmailForVerification(false)
        }
        }
        loginSetError={setError}
      />
    )}
  </form>
)
}
const mapStateToProps = (state) => {
  return {
    is2faActivated: state.common.is2faActivated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setToken: (data) => dispatch(actions.setToken(data)),
    set2faActivationStatus: (value) => dispatch(actions.set2faActivationStatus(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
