import React from 'react';
import { connect } from 'react-redux';
import { removeHttp } from '../../../common/getIP';
const depositsuccesfuldark = "/common/crytoicons/depositsuccesfuldark.png";
const depositsuccesfullight = "/common/crytoicons/depositsuccesfullight.png";

class DepositSuccessful extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lightFlag: false,
            merchantName: "",
            merchantRedirectUrl: ""
        }
    }

    componentDidMount = async () => {
        const queryString = window && window.location && window.location.search;
        const searchParams = new URLSearchParams(queryString);
        const {merchantRedirectUrl } = Object.fromEntries(searchParams.entries());
        this.setState({merchantRedirectUrl})
        
        this.setState({ merchantName: localStorage.getItem("depositMerchantName") ? localStorage.getItem("depositMerchantName") : "Merchant"})
        if (sessionStorage.getItem("lightFlag")) {
            this.setState({ lightFlag: (sessionStorage.getItem("lightFlag") === "true") })
        } else {
            sessionStorage.setItem("lightFlag", false);
        }
    }

    redirectToHome = () => {
        // window.open("/")
        if(this.state.merchantRedirectUrl){
            window.location.replace(`https://${removeHttp(this.state.merchantRedirectUrl)}`, "_self");
        }
        else if(localStorage.getItem("loginType") && (localStorage.getItem("loginType") == "B2C")){
            window.location.replace(`/user-wallet-home`, "_self");
        }else{
            window.close();
        }
    }

    render() {
        return (
            <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                <div className="deposit_screen_wrap">
                    <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"} style={{ paddingBottom: "30.6px" }}>
                        <div className="successimageWrap">
                            <img className="successimage" src={`${this.props.image_base_url}${this.state.lightFlag ? depositsuccesfullight : depositsuccesfuldark}`}/>
                        </div>
                        <div className={`depositSuccessTitle ${this.state.lightFlag ? "text_color_black" : ""}`}>
                            Deposit Successful
                        </div>
                        <div className={`depositSuccessDescription ${this.state.lightFlag ? "text_color_black" : ""}`}>
                            Your deposit has been transferred <br/> successfully!
                        </div>
                        <div className="returnToMerchant" onClick={() => {
                                this.redirectToHome();
                            }} data-e2e="return to wallet">Return to {this.state.merchantName}</div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        image_base_url: state.config.image_base_url,
    }
}

export default connect(mapStateToProps, null)((DepositSuccessful));
