import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { Tooltip } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';
const selectedCheckBoxIcon = '/common/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/common/crytoicons/checkbox_empty.png';

const currencyList = [
  {
    icon: `/common/crytoicons/usa.png`,
    value: 'USD',
    label: 'United States dollar',
    symbol: '$',
  },
  {
    icon: `/common/crytoicons/euro.png`,
    value: 'EUR',
    label: 'Euro',
    symbol: '€',
  },
  {
    icon: `/common/crytoicons/uk.png`,
    value: 'GBP',
    label: 'British pound',
    symbol: '£',
  },
];
const CreateInvoice = (props) => {
  const {
    open,
    selectedCryptos,
    allCryptos,
    handleClose,
    selectedPaymentSettings,
    allPaymentSettings,
    setPaymentSettings,
    setOpen,
    setOpenInvoiceDetails,
    setSelectedCryptos,
    setInvoiceDetailsData,
    clearInputFields,
  } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [amount, setAmount] = useState();
  const [email, setEmail] = useState('');
  const [paymentReference, setPaymentReference] = useState('');
  const [error, setError] = useState('');
  const [showCoinDropdown, setCoinDropdown] = useState(false);
  const [showPaymentDropdown, setPaymentDropdown] = useState(false);
  const [prefix, setPrefix] = useState('$');
  const [selectedCurrency, setselectedCurrency] = useState('USD');
  const [colorMode, setColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
    if (checkValueInSS("colorMode")) {
      setColorMode(sessionStorage.getItem("colorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      setAmount();
      setEmail('');
      setPaymentReference('');
      setSelectedCryptos([]);
      setPaymentSettings([]);
      setselectedCurrency('USD');
      setPrefix('$');
    }
  }, [clearInputFields]);
  const onAmountInputChange = (evt) => {
    const value = evt.target.value;
    setAmount(parseFloat(value));
  };
  const handleCreateInvoice = () => {
    setOpen(false);
    setOpenInvoiceDetails(true);
    const invoiceDetailsData = [
      { key: 'Asset', value: `${selectedCryptos.length} selected` },
      { key: 'Amount', value: `${amount} ${prefix}`, amount: amount },
      { key: 'Reference id', value: paymentReference || 'N/A' },
      { key: 'Payment type', value: 'Crypto and Card Deposits' },
      { key: 'Payment Settings', value: `${selectedPaymentSettings.length} Payment Selected`, amount: selectedPaymentSettings },
      { key: 'selectedCryptos', value: `${selectedCryptos.length} Coins Selected`, amount: selectedCryptos },
      { key: 'prefix', value: prefix },
      { key: 'email', value: email },
    ];
    setInvoiceDetailsData(invoiceDetailsData);
  };
  const onCurrencyChange = (event) => {
    currencyList.map((data) => {
      if (data.value === event.target.value) {
        setPrefix(data.symbol);
        setselectedCurrency(data.value);
      }
    });
  };
  const toggleSelectionCrypto = (symbol) => {
    if (props.selectedCryptos.includes(symbol)) {
      let newList = props.selectedCryptos.filter((symbolObj) => {
        return symbolObj !== symbol;
      });
      setSelectedCryptos(newList);
    } else {
      let newList = [...props.selectedCryptos];
      newList.push(symbol);
      newList = newList.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
      setSelectedCryptos(newList);
    }
  };

  const toggleSelectionStatus = (payment) => {
    let newList = [];
    newList.push(payment);
    setPaymentSettings(newList);
  };
  const disable = selectedCryptos.length > 0 && selectedPaymentSettings.length > 0 && amount;
  let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)
  let headerButtonBorderStyle = getHeaderButtonBorderStyle(colorMode, headerButtonDarkBgColour, headerButtonLightBgColour)

  return (
    <div>
      <Dialog open={open} className="b2c-signup-model deposit_model_mobile">
        <div className="enable-authenticator-model" style={{ width: '461px' }}>
          <Card style={{ width: '461px' }} className="card-bg">
            <div className="p-0 mt-4 invoice-modal-header enable-authenticator-header">
              <div className="create-invoice-modal-header">{`Create New Invoice`}</div>
              <div className="invoice-close-icon signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={handleClose} className="" />
              </div>
            </div>
            <div className="invoice-fields-header crypto_heading">
              Payment Reference<span className="optional-text-color">(Optional)</span>
            </div>
            <div className="input_container">
              <input
                className="invoice-input-value box-width-height input_box pl-3"
                type="text"
                placeholder="Input unique refenece Id"
                data-e2e="input-unique-refenece-id"
                value={paymentReference}
                onChange={(e) => {
                  setError('');
                  setPaymentReference(e.target.value);
                }}
              />
            </div>
            <div className="invoice-fields-header crypto_heading">
              Email<span className="optional-text-color">(Optional)</span>
            </div>
            <div className="input_container">
              <input
                className="invoice-input-value box-width-height input_box pl-3"
                type="email"
                placeholder="Enter your email address"
                data-e2e="email-input"
                value={email}
                onChange={(e) => {
                  const inputEmail = e.target.value;
                  setEmail(inputEmail);

                  // Email validation regex pattern
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                  if (inputEmail && !emailRegex.test(inputEmail)) {
                    setError('Invalid email address');
                  } else {
                    setError('');
                  }
                }}
              />
              {error !== '' && <div className="text-danger pl-4">{error}</div>}
            </div>

            <div className="invoice-fields-header crypto_heading pb-2">{`Select Currency`}</div>
            <div className="box-width-height coins__filter create-invoice-fields-border">
              <div
                className="heading___icon"
                onClick={() => {
                  setCoinDropdown(true);
                  setPaymentDropdown(false);
                }}
              >
                <div className="invoice-input-value">
                  <div>
                    {selectedCryptos && selectedCryptos.length === allCryptos && allCryptos.length
                      ? 'All Coins Selected'
                      : selectedCryptos && selectedCryptos.length === 0
                      ? 'Select Crypto Currencies'
                      : `${selectedCryptos && selectedCryptos.length} Coins Selected`}
                  </div>
                  <div data-e2e="currency-options" className="currency-icons-display">
                    {selectedCryptos &&
                      selectedCryptos.map((obj, index) => {
                        const foundObject = allCryptos.find((symbol) => symbol.symbol === obj);
                        return <img src={image_base_url + foundObject.icon} alt={index} width={25} height={25} />;
                      })}
                  </div>
                </div>
                <div className="invoice-arrow-icon text-dark">
                  <i className={`fa fa-angle-${showCoinDropdown ? 'up' : 'down'}`} data-e2e={`currency-${showCoinDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
                </div>
              </div>
              {showCoinDropdown && (
                <div className="showDropdown">
                  <div
                    className="showCoinDropdown__heading"
                    onClick={() => {
                      setCoinDropdown(false);
                    }}
                  >
                    <div className="heading__name">
                      {selectedCryptos && selectedCryptos.length === allCryptos && allCryptos.length
                        ? 'All Coins Selected'
                        : selectedCryptos && selectedCryptos.length === 0
                        ? 'Select Crypto Currencies'
                        : `${selectedCryptos && selectedCryptos.length} Coins Selected`}
                    </div>
                    <div className="dropdown__icon text-dark">
                      <i data-e2e={`cureency-option-${showCoinDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showCoinDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="allCrypto__rows" style={{ height: '200px' }}>
                    {allCryptos &&
                      allCryptos.map((crypto, index) => {
                        let { name, symbol, icon } = crypto;
                        return (
                          <div key={index} className="coins__rowData" onClick={() => toggleSelectionCrypto(symbol)}>
                            <div className="rowData__name__image">
                              <img src={`${image_base_url}${icon}`} alt="bitcoin" />
                              <span data-e2e={name}>
                                {name} / {symbol}
                              </span>
                            </div>
                            <img
                              src={`${image_base_url}${selectedCryptos && selectedCryptos.includes(symbol) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                              alt="checkbox"
                              className="CryptoCheckBox"
                            />
                          </div>
                        );
                      })}
                  </div>

                  <div className="refresh__and__selectAll">
                    <div className="refreshSelectAll">
                      <Tooltip title="Deselect All">
                        <div className="refresh" style={{ ...headerButtonStyle }} onClick={() => setSelectedCryptos([])}>
                          <div className="refresh__icon">
                            <i class="fa fa-square-o" aria-hidden="true"></i>
                          </div>
                        </div>
                      </Tooltip>
                      <div
                        className="selectAll"
                        onClick={() => {
                          let allCoins = [];
                          allCryptos && allCryptos.map((c) => allCoins.push(c.symbol));
                          props.setSelectedCryptos(allCoins);
                        }}
                        style={{ ...headerButtonBorderStyle }}
                      >
                        <h1 data-e2e="refresh-icon">Select all</h1>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-fields-header crypto_heading pb-2 pt-4">{`Payment Settings`}</div>
            <div className="box-width-height coins__filter create-invoice-fields-border">
              <div
                className="heading___icon"
                onClick={() => {
                  setPaymentDropdown(true);
                  setCoinDropdown(false);
                }}
              >
                <div data-e2e="payment-settings" className="invoice-input-value">
                  {(selectedPaymentSettings && selectedPaymentSettings.length === allPaymentSettings && allPaymentSettings.length) ||
                  (selectedPaymentSettings && selectedPaymentSettings.length === 0)
                    ? 'Choose your payment settings'
                    : selectedPaymentSettings}
                </div>
                <div className="invoice-arrow-icon text-dark">
                  <i data-e2e={`payment-${showPaymentDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showPaymentDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
                </div>
              </div>
              {showPaymentDropdown && (
                <div className="showDropdown" style={{ height: 'fit-content' }}>
                  <div
                    className="showCoinDropdown__heading"
                    onClick={() => {
                      setPaymentDropdown(false);
                    }}
                  >
                    <div className="heading__name">
                      {(selectedPaymentSettings && selectedPaymentSettings.length === allPaymentSettings && allPaymentSettings.length) ||
                      (selectedPaymentSettings && selectedPaymentSettings.length === 0)
                        ? 'Choose your payment settings'
                        : 'Choose your payment settings'}
                    </div>
                    <div className="dropdown__icon text-dark">
                      <i data-e2e={`payment-option-${showPaymentDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showPaymentDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="allCrypto__rows">
                    {allPaymentSettings &&
                      allPaymentSettings.map((payment, index) => {
                        return (
                          <div key={index} className="coins__rowData" onClick={() => toggleSelectionStatus(payment)}>
                            <div className="rowData__name__image">
                              <span style={{ textTransform: 'capitalize', paddingLeft: '0px' }} data-e2e={payment}>{payment}</span>
                            </div>
                            <img
                              src={`${image_base_url}${
                                selectedPaymentSettings && selectedPaymentSettings.includes(payment) ? selectedCheckBoxIcon : emptyCheckBoxIcon
                              }`}
                              alt="checkbox"
                              className="CryptoCheckBox"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-fields-header crypto_heading pt-4 pb-1">{`Amount to be paid`}</div>
            <div className="invoice-amout deposit_amount_body invoice-amout">
              <div className="deposit_amount_content pt-2 pb-2">
                <div className="box-width-height create-invoice-fields-border deposit_amount_selection">
                  <span className="font-weight-normal pr-1" data-e2e={prefix}>{prefix}</span>
                  <input
                    className="font-weight-normal invoice-input-value border-none"
                    type="number"
                    placeholder="Enter amount"
                    name="amount"
                    data-e2e="depositAmount"
                    value={parseFloat(amount)}
                    onChange={onAmountInputChange}
                    aria-label="Text input with dropdown button"
                  />
                  <Select
                    value={selectedCurrency}
                    className="invoice-select-style font-weight-unset invoice-input-value pl-0 form control"
                    id="selectCurrency"
                    name="selectMerchant"
                    data-e2e="selectMerchant"
                    onChange={onCurrencyChange}
                  >
                    {currencyList &&
                      currencyList.map((data, index) => (
                        <MenuItem className="invoice-select-option" key={index} value={data.value}>
                          <div>
                            <img src={image_base_url + data.icon} alt={data.label} width={25} height={25} />
                          </div>
                          <div data-e2e={data.value}>{data.value}</div>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
            </div>
            <button
              disabled={!disable}
              className={!disable ? 'disable-create-invoice-btn-handler' : 'create-invoice-btn-handler'}
              style={{ ...headerButtonStyle }}
              onClick={handleCreateInvoice}
            >
              <div data-e2e="create-invoice-button1">{`CREATE INVOICE`}</div>
            </button>
          </Card>
        </div>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
  };
};

export default connect(null, mapDispatchToProps)(CreateInvoice);
