import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../common/apiCallService';
import { getDeviceIP, removeHttp } from '../../../common/getIP';
import PayLoading from "./PayLoading";
import * as actions from '../../../Redux/actions/index';
import { useTranslation } from 'react-i18next';
import { constants } from '../../../common/constants';
import PaymentSummaryForCreditCardBuy from '../B2C/UserWalletsHome/PaymentSummaryForCreditCardBuy';
import { getRedirectUrlBasedOnUiFlow } from '../../../common/getRedirectUrlBasedOnUiFlow';

const SelectCreditCard = (props) => {
    const [savedCardList, setSaveCardList] = useState(props.saveCardList);
    const [CVVValue, setCVVValue] = useState("");
    const [payerAddress, setPayerAddress] = useState(props.selectedCard && props.selectedCard.address ? props.selectedCard.address : "");
    const [payerState, setPayerState] = useState(props.selectedCard && props.selectedCard.state ? props.selectedCard.state : "");
    const [payerCountry, setPayerCountry] = useState(props.selectedCard && props.selectedCard.country ? props.selectedCard.country : "");
    const [payerCity, setPayerCity] = useState(props.selectedCard && props.selectedCard.city ? props.selectedCard.city : "");
    const [payerZip, setPayerZip] = useState(props.selectedCard && props.selectedCard.zip ? props.selectedCard.zip : "");
    const [payerPhone, setPayerPhone] = useState(props.selectedCard && props.selectedCard.phone ? props.selectedCard.phone : "");

    const payment_api_url = useSelector((state) => state.config.payment_api_url);
    const api_url = useSelector((state) => state.config.api_url);
    const coinSpiritHostName = useSelector((state) => state.config.COIN_SPIRIT_HOST_NAME);
    const coinSpiritMailSupport = useSelector((state) => state.config.COIN_SPIRIT_MAIL_SUPPORT);
    const [selectedCard, setSelectedCard] = useState(props.selectedCard ? props.selectedCard : null);
    const [openCardDropdown, setOpenCardDropdown] = useState(false);
    const [error, setError] = useState("");
    const cvvInput = useRef(null);
    const [token, setToken] = useState("");
    const [getTokenLoading, setGetTokenLoading] = useState(false)
    const [payFlag, setPayFlag] = useState(false);
    const [truevoFlag, setTruevoFlag] = useState(false);
    const [truevoSubmit, setTruevoSubmit] = useState(false);
    const [secondTruevoFlag, setSecondTruevoFlag] = useState(false);
    const navigate = useNavigate();
    const [acquirerName, setAcquirerName] = useState("");
    const [routingId, setRoutingId] = useState("");
    const { t } = useTranslation()
    const [countryCode, setcountryCode] = useState([]);

    useEffect(() => {
        if (cvvInput.current) {
            cvvInput.current.focus();
        }
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-country-code',
            {},
            (data) => {
                data.map((country) => {
                    if (country.code == payerCountry) {
                        setPayerCountry(country.name);
                    }
                })
                setcountryCode(data)
            })
    }, [])

    useEffect(() => {
        if (selectedCard && savedCardList.length > 0) {
            if (savedCardList.filter(obj => obj.id === selectedCard.id).length === 0 && savedCardList.length > 0) {
                const newCard = savedCardList[0];
                setPayerAddress(newCard.address ? newCard.address : "");
                setPayerState(newCard.state ? newCard.state : "")
                setPayerCountry(newCard.country ? newCard.country : "")
                setPayerCity(newCard.city ? newCard.city : "")
                setPayerZip(newCard.zip ? newCard.zip : "")
                setPayerPhone(newCard.phone ? newCard.phone : "")
                setSelectedCard(newCard)
            }
        } else if (savedCardList.length === 0) {
            setSelectedCard(null)
            props.setEmptyCardListFlag(true)
            redirectToAddNewCard()
        }
    }, [savedCardList, selectedCard])

    const redirectToAddNewCard = () => {
        props.isAddNewCard(true)
    }

    const submitData = async () => {
        let ipAddress = await getDeviceIP()
        let query = {
            CVVValue,
            fiatAmount: props.fiatAmount,
            fiatCurrency: props.fiatCurrency,
            payerAddress,
            payerState,
            payerCountry,
            payerCity,
            payerZip,
            ipAddress,
            payerPhone,
            maskedPan: selectedCard.mask,
            parenttransactionreference: selectedCard.transactionreference,
            transactionId: localStorage.getItem("transactionId"),
            "browser_timezone": new Date().toString().match(/([-\+][0-9]+)\s/)[1],
            "browser_color_depth": window.screen.colorDepth,
            "browser_language": navigator.language,
            "browser_screen_height": window.innerHeight,
            "browser_screen_width": window.innerWidth,
            "os": navigator.platform,
            "browserUserAgent": navigator.userAgent,
            "IsJavaEnabled": navigator?.javaEnabled()
        }
        setGetTokenLoading(true)
        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/getCardStatus', query, async (data) => {
            if (data) {
                query = {
                    ...query,
                    acquirerUniqueId: data?.acquirerUniqueId
                }
                if (data.code == "ACCEPT") {
                    query.fiatAmount = data.finalAmount ? data.finalAmount : props.fiatAmount;
                    setRoutingId(data.routingId)
                    setAcquirerName(data.acquirerName)

                    props.setCardAPIType(data.integrationType)
                    //fintech does not support save card functionality so changes are not added here
                    if (data.integrationType === "Trust Payments") {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/getTokenForPreviousCard', { ...query, routingId: data.routingId, acquirerName: data.acquirerName }, async (data) => {
                            handleCommonSuccessApiResponseSimple(data)
                        }, (err) => {
                            commomErrorResponseHandler(err, data)
                        })
                    } else if (data.integrationType === "Brava Payments") {
                        let filterdata = countryCode.filter((e) => (e.name === query.payerCountry || e.code === query.payerCountry))
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/createCardPaymentBrava',
                            {
                                ...query,
                                payerCountry: filterdata[0].code,
                                routingId: data.routingId,
                                acquirerName: data.acquirerName,
                                domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                            }, async (data) => {
                                handleCommonSuccessApiResponse(data)
                            }, (err) => {
                                commomErrorResponseHandler(err, data)
                            })
                    } else if (data.integrationType === constants.GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE) {
                        setPayFlag(true);
                        let reqData = {
                            ...query,
                            "amount": data.finalAmount ? data.finalAmount : props.fiatAmount,
                            "fiatCurrency": props.fiatCurrency,
                            "description": "Payment",
                            "cardToken": selectedCard.transactionreference,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                            "name": selectedCard.name,
                            "nameOnCard": selectedCard.name,
                        }
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/goldenGatewayCardPayment', reqData, async (data) => {
                            if (data) {
                                if (data.acquirerRedirectUrl) {
                                    console.log("data.redirectUrl 10", data.redirectUrl, data.acquirerRedirectUrl)
                                    window.location.href = data.acquirerRedirectUrl
                                } else {
                                    console.log("data.redirectUrl", data.redirectUrl, data.acquirerRedirectUrl)
                                    if (window.top !== window.self) {
                                        // Check if we can safely access window.top and it's not the same as the current window
                                        window.top.location.href = `/redirectToUrl?redirectUrlParam=${data?.redirectUrl}`;
                                    } else {
                                        // Fallback to navigate or other redirection if window.top isn't available
                                        window.location.href = data?.redirectUrl
                                    }
                                    props.handleLoadingModal();
                                }
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err, data)
                        })
                    } else if (data.integrationType === "Pay.com") {
                        setPayFlag(true);
                        let reqData = {
                            ...query,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/createCardPaymentPayDotCom', reqData, async (data) => {
                            handleCommonSuccessApiResponse(data);
                        }, (err) => {
                            commomErrorResponseHandler(err, data);
                        })
                    } else if (data.integrationType === "Axcess Payments") {
                        console.log("InsideAxcess")
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/getAxcessPaymentSignature', {
                            ...query,
                            routingId: data.routingId, acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            if (data) {
                                setToken(data);
                                setPayFlag(true);
                                props.handleLoadingModal();
                                setTimeout(() => {
                                    document.getElementById('st-form-axcess').submit();
                                }, 1000)
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err, data)
                        })
                    } else if (data.integrationType === constants.WORLD_CARD_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/cardPaymentWorldCard', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err, data);
                        })
                    } else if (data?.integrationType === constants.BAERS_CREST_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/cardPaymentBaersCrest', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            name: selectedCard?.name,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err, data);
                        })
                    } else if (data?.integrationType === constants.TRUEVO_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/truevoCardPayment', {
                            ...query,
                            routingId: data?.routingId,
                            acquirerName: data?.acquirerName,
                            browser_timezone: new Date().getTimezoneOffset(),
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            console.log("data truevo", data)
                            getTruevoStatus();
                        }, (err) => {
                            commomErrorResponseHandler(err, data);
                        })
                    } else {
                        setError("This card is blocked")
                        setGetTokenLoading(false)
                    }
                } else {
                    setError("This card is blocked")
                    setGetTokenLoading(false)
                    const redirectUrlObj = await getRedirectUrlBasedOnUiFlow(data)
                    if (window.top !== window.self) {
                        // Check if we can safely access window.top and it's not the same as the current window
                        window.top.location.href = `/redirectToUrl?redirectUrlParam=${redirectUrlObj?.failedUrl}`;
                    } else {
                        // Fallback to navigate or other redirection if window.top isn't available
                        window.location.href = redirectUrlObj?.failedUrl
                    }
                }
            }
        }, (err) => {
            console.log("errrr", err)
        })
    }

    const getTruevoStatus = async () => {
        try {
            let query = {
                transactionId: localStorage.getItem("transactionId")
            }
            await apiService("/pga/getTruevoStatus", query, (data) => {
                if (data && data?.redirectUrl) {
                    navigate(data.redirectUrl)
                } else {
                    if (data?.outcome?.transactionStatus !== "PENDING") {
                        return
                    } else if ((data?.outcome?.action?.actionRequired === "IdentifyCardholder") && !truevoSubmit) {
                        if (data?.outcome?.action?.additionalData?.length > 0) {
                            setToken(data.outcome.action.additionalData);
                        }
                        setTruevoFlag(true);
                        return;
                    } else if (data?.outcome?.action?.actionRequired === "Challenge") {
                        console.log("in Challenge");
                        if (data?.outcome?.action?.additionalData?.length > 0) {
                            setToken(data.outcome.action.additionalData);
                        }
                        setSecondTruevoFlag(true);
                        return;
                    } else {
                        getTruevoStatus();
                    }
                }
            }, (err) => {
                console.error(err);
            })
        } catch (error) {
            console.error('error getTruevoStatus ', error);
        }
    }

    const handleCommonSuccessApiResponse = (data) => {
        setPayFlag(false);
        if (data) {
            setGetTokenLoading(false)
            if (data.redirectUrl) {
                console.log("data.redirectUrl 7", data.redirectUrl, data.acquirerRedirectUrl)
                if (window.top !== window.self) {
                    // Check if we can safely access window.top and it's not the same as the current window
                    window.top.location.href = `/redirectToUrl?redirectUrlParam=${data?.redirectUrl}`;
                } else {
                    // Fallback to navigate or other redirection if window.top isn't available
                    window.location.href = data?.redirectUrl
                }
            } else if (data.acquirerRedirect) {
                console.log("data.redirectUrl 8", data.redirectUrl, data.acquirerRedirectUrl)
                window.location.href = data.acquirerRedirect
            } else {
                setToken(data);
                setPayFlag(true);
                props.handleLoadingModal();
            }
        }
    }

    const handleCommonSuccessApiResponseSimple = (data) => {
        if (data) {
            setToken(data);
            setPayFlag(true);
            props.handleLoadingModal();
        }
    }
    const checkCoinsSpirit = () => {
        return (window && window.location && window.location.hostname &&
            window.location.hostname.includes(coinSpiritHostName)) || false;
    };

    const commomErrorResponseHandler = async (err, data) => {
        setError("Something Went Wrong!")
        console.error("errrr", err)
        setGetTokenLoading(false)
        setPayFlag(false);
        const redirectUrlObj = await getRedirectUrlBasedOnUiFlow(data)
        if (window.top !== window.self) {
            // Check if we can safely access window.top and it's not the same as the current window
            window.top.location.href = `/redirectToUrl?redirectUrlParam=${redirectUrlObj?.failedUrl}`;
        } else {
            // Fallback to navigate or other redirection if window.top isn't available
            window.location.href = redirectUrlObj?.failedUrl
        }
    }


    let requiredComp = <span style={{ color: "red" }}>*</span>
    let disableCondition = (selectedCard && CVVValue && CVVValue.length === 3 && !isNaN(CVVValue)
        && payerAddress && payerAddress !== ""
        && payerState && payerState !== ""
        && payerCity && payerCity !== ""
        && payerCountry && payerCountry.length >= 2
        && payerZip && payerZip !== ""
        && payerPhone && payerPhone !== ""
        && !getTokenLoading) ? true : false

    return (
        <div className={`creditCardContainer ${props.lightFlag ? "text_color_black" : "text_color_white"}`} id={checkCoinsSpirit() ? "creditCardContainerWidth" : ""}>
            <div className='creditCardPaymentSummary header__profile_block'>
            <PaymentSummaryForCreditCardBuy {...props} />
                <div className={checkCoinsSpirit() ? "creditCardPaymentSummaryDetailsPaymentMethodSelection" : ""}>
                {!payFlag && <React.Fragment>{props.deleteCard ? <React.Fragment>
                <div className="creditCardRemoveWrap">
                    <div>
                        <div className={`ConfirmationMsg ${props.lightFlag ? "" : "text_color_white"}`}>{t('Are you sure you want to remove selected card? This action cannot be undone.')}</div>
                        <div className={`savedCardDropdown ${props.lightFlag ? "savedCardDropdownLight" : ""}`} onClick={() => {
                            setOpenCardDropdown(true)
                        }}>
                            {props.deleteCard ? <img className="savedCardSelectedIcon" src={`${props.image_base_url}${selectedCard.icon}`} alt="cardimg" /> : null}
                            <div className="savedCardSelected">
                                {props.deleteCard ? props.deleteCard.mask : "Select Card"}
                            </div>
                        </div>
                    </div>
                    <div className={`ANC_Continue_btn`}
                        onClick={() => {
                            const newList = savedCardList.filter((obj) => obj.id !== props.deleteCard.id)
                            setSaveCardList(newList)
                            props.deleteCardFormDB(props.deleteCard.id, props.deleteCard.transactionId)
                            props.setDeleteCard(null)
                        }} data-e2e="remove">{t('Remove')}</div>
                </div>
            </React.Fragment>
                :
                <React.Fragment>
                    <div className="savedCardContainer">
                        <div className="savedCardWrap">
                            <div className="savedCardDropdownWrap cursor-pointer">
                                <div className="savedCardTitle">{t('Use Saved Cards')}</div>
                                <div className={`savedCardDropdown ${props.lightFlag ? "savedCardDropdownLight" : ""}`} onClick={() => {
                                    setOpenCardDropdown(true)
                                }}>
                                    {selectedCard ? <img className="savedCardSelectedIcon" src={`${props.image_base_url}${selectedCard.icon}`} alt="cardimg" /> : null}
                                    <div className="savedCardSelected">
                                        {selectedCard ? selectedCard.mask : "Select Card"}
                                    </div>
                                    {selectedCard ? <i data-e2e="card-select-option" className={`fa fa-chevron-down savedCardSelecetionIcon`} /> : null}
                                </div>
                                {openCardDropdown && <div className={`savedCardDropdownList ${props.lightFlag ? "savedCardDropdownListLight" : ""}`}>
                                    <div className="savedCardSelectedTitle" onClick={() => {
                                        setOpenCardDropdown(false)
                                    }} ><div>{t('Select Card')}</div> <i className={`fa fa-chevron-up savedCardDropdownIcon`} /> </div>
                                    {savedCardList.map((card, i) => {
                                        return <div key={i} className={`savedCardDropdownItem`} >
                                            <img className="savedCardSelectedIcon" src={`${props.image_base_url}${card.icon}`} alt="cardimg" />
                                            <div className="savedCardSelected" onClick={() => {
                                                setCVVValue("")
                                                setSelectedCard(card);
                                                setPayerAddress(card.address ? card.address : "");
                                                setPayerState(card.state ? card.state : "")
                                                setPayerCountry(card.country ? card.country : "")
                                                setPayerCity(card.city ? card.city : "")
                                                setPayerZip(card.zip ? card.zip : "")
                                                setPayerPhone(card.phone ? card.phone : "")
                                                setOpenCardDropdown(false);
                                                setError("")
                                            }} data-e2e={card ? card.mask : "XXXX_XXXX"}>
                                                {card ? card.mask : "XXXX_XXXX"}
                                            </div>
                                            <img className="savedCardDeleteIcon" src={`${props.image_base_url}${props.lightFlag ? "/common/delete_light.svg" : "/common/delete.svg"}`}
                                                onClick={() => {
                                                    props.setDeleteCard(card)
                                                }}
                                                alt="deleteimg" />
                                        </div>
                                    })}
                                    <div className={`addNewCardBtn`} onClick={() => {
                                        redirectToAddNewCard();
                                    }} data-e2e="add-new-btn">
                                        {t('Add new card')}
                                    </div>
                                </div>}
                            </div>
                            <div className="savedCardCVVWrap">
                                <div className="savedCardTitle">CVC {requiredComp}</div>
                                <div className={`savedCardCVV ${props.lightFlag ? "savedCardCVVLight" : ""} ${error === "Invalid CVC / CVV" ? "errorBorder" : ""}`} >
                                    <input data-e2e="cvv-field" className={`${props.lightFlag ? "text_color_black" : "text_color_white"}`} id="CVC" type="text" value={CVVValue} onChange={(e) => {
                                        setCVVValue((e.target.value.replace(/\s/g, '')).replace(/[^0-9.]/g, "").substring(0, 3));
                                        setError("")
                                    }}
                                        ref={cvvInput}
                                        placeholder={t("Input CVC")} />
                                </div>
                                {error === "Invalid CVC / CVV" && <div className="errorIcon">!</div>}
                            </div>
                        </div>
                        <div className="addNewCardBtnWrap">
                            <div className={`addNewCardWrap ${props.lightFlag ? "addNewCardWrapLight" : ""}`} onClick={() => {
                                redirectToAddNewCard();
                            }}>
                                <div className="ANC_iconWrap">
                                    <img className="ANC_icon" src={`${props.image_base_url}/common/credit_card_icon.png`} />
                                </div>
                                <div className="ANC_text" data-e2e="add-new-card-btn">{t('Add new card')}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">Address {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={payerAddress}
                                onChange={(e) => {
                                    setPayerAddress(e.target.value)
                                    setError("")
                                }}
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="Enter address"
                                data-e2e="Enter address" />
                        </div>
                    </div>
                    <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">State {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={payerState}
                                onChange={(e) => {
                                    setPayerState(e.target.value)
                                    setError("")
                                }}
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="Enter State" />
                        </div>
                    </div>
                    <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">Country Code {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <select
                                value={payerCountry}
                                className={`${payerCountry !== "" ? props.lightFlag ? "text_color_black " : "text_color_white " : "select-default-option"} ${props.lightFlag ? "inputField" : "selectField"}`}
                                onChange={(e) => {
                                    setPayerCountry(e.target.value)
                                    setError("")
                                }}
                            >
                                {payerCountry === "" &&
                                    <option style={{ height: "50px" }} value={""} disabled className='font-weight-light' data-e2e="country">Select 2-digit contry code (e.g. US)</option>
                                }
                                {countryCode && countryCode.length > 0 && countryCode.map((data, index) => {
                                    return (
                                        <option key={index} style={{ height: "50px" }} className={`${props.lightFlag ? "text_color_black " : "text_color_white "}`} data-e2e={data.code} value={data.name}>{data.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">City {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={payerCity}
                                onChange={(e) => {
                                    setPayerCity(e.target.value)
                                    setError("")
                                }}
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="Enter city name"
                                data-e2e="Enter city name" />
                        </div>
                    </div>
                    <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">Zip Code {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={payerZip}
                                onChange={(e) => {
                                    setPayerZip(e.target.value)
                                    setError("")
                                }}
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="Enter zip code"
                                data-e2e="Enter zip code" />
                        </div>
                    </div>
                    <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                        <div className="title">Phone {requiredComp}</div>
                        <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                            <input type="text"
                                value={payerPhone}
                                onChange={(e) => {
                                    setPayerPhone(e.target.value)
                                    setError("")
                                }}
                                className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                placeholder="Enter phone number"
                                data-e2e="Enter phone number" />
                        </div>
                    </div>

                    {error !== "" && <div className="errorMsg">{error}</div>}

                    <div className={`ANC_Continue_btn ${disableCondition ? "" : "ANC_Continue_btn_disabled"}`} onClick={() => {
                        if (disableCondition) {
                            submitData();
                        } else if (!selectedCard) {
                            setError("No Card Selected")
                        } else {
                            if (CVVValue.length !== 3) {
                                setError("Invalid CVC / CVV")
                            } else {
                                setError("Invalid Details")
                            }
                        }
                    }} data-e2e="continue-btn">Continue</div>
                    <div className='creditCardFooterImages mt-10'>
                        <p>{`Contact support on ${coinSpiritMailSupport}`}</p>
                        <p className="footer__copyright__images">
                            <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode.png`} alt="" width="96" height="54" />
                            <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode1.png`} alt="" width="96" height="54" />
                        </p>
                    </div>
                </React.Fragment>
            }</React.Fragment>}
            {payFlag && <PayLoading
                routingId={routingId} acquirerName={acquirerName}
                securitycode={CVVValue}
                transactionId={localStorage.getItem("transactionId")}
                parenttransactionreference={selectedCard.transactionreference}
                previousCard={true}
                token={token}
                axcess_hosted_url={props.axcess_hosted_url}
            />}
             {truevoFlag && <TruevoForm
                token={token}
                handleTruevoFlag={() => {
                    setTruevoSubmit(true);
                    setTruevoFlag(false);
                    setTimeout(() => {
                        getTruevoStatus()
                    }, 2000);
                    return
                }
                }
            />}
            {secondTruevoFlag && <TruevoSecondForm
                token={token}
                handleTruevoFlag={() => {
                    setTimeout(() => {
                        getTruevoStatus()
                    }, 500);
                    return
                }
                }
            />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cardAPIType: state.deposit.cardAPIType,
        axcess_hosted_url: state.config.axcess_hosted_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCardAPIType: (value) => dispatch(actions.setCardAPIType(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCreditCard);