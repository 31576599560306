import React from 'react';
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "./Localisation";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

const NavbarLang = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const [language, setlanguage] = useState('en')
    const HandleSelectLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
        if (typeof window !== 'undefined') {
            localStorage.setItem("i18nextLng", e.target.value);
        }
    }
    const { t } = useTranslation()

    useEffect(() => {
        const lang = localStorage.getItem("i18nextLng")
        if (lang) {
            setlanguage(lang)
        }
    }, [])
    const languagesDropdown = [
        { key: "en", value: "English", img: `${image_base_url}/common/language/en.svg` },
        { key: "po", value: "Polish", img: `${image_base_url}/common/language/po.svg` },
        { key: "ja", value: "Japanese", img: `${image_base_url}/common/language/ja.svg` },
        { key: "ru", value: "Russian", img: `${image_base_url}/common/language/ru.svg` },
    ]
    return (
        <div className="bg-light lang_container">
            <Select defaultValue={language} value={i18n.language} onChange={HandleSelectLanguage} className='lang_select'>
                {languagesDropdown.map((e, i) => (
                    <MenuItem key={i} value={e.key} className='lang_options' sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <img src={e.img} alt="" width={30} height={30} />
                        {t(e.value)}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
export default NavbarLang