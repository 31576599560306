import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import { useTranslation } from 'react-i18next';
import { getEmailVal } from '../../../common/commonMethod';

function LoginScreen({ lightFlag, onSuccess, setLoading, setToken, set2faActivationStatus, handleEmailValidation }) {
    const api_url = useSelector((state) => state.config.api_url);
    const [error, setError] = useState("");
    const [verificationCode2fa, change2faVerificationCode] = useState("");
    const [is2faModalOpen, set2faModalOpen] = useState(false);
    const [loginSuccessData, setLoginSuccessData] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const disabled = data.email.length < 0 || data.email === '' || data.password.length < 0 || data.password === '' ? true : false;

    const handleGetStarted = () => {
        const hostName = window.location.hostname.replace(/^www\./, '')

        setLoading(true)
        apiService((hostName === "localhost" ? api_url : "") + '/restapi/b2c-login', {
            user_email: data.email,
            password: data.password,
            domainNameUrl: localStorage.getItem("domainNameUrl") ? localStorage.getItem("domainNameUrl") : hostName 
        },
            (data) => {
                setLoading(false)
                if (data) {
                    const emailVal = getEmailVal(data?.email)
                    data = {...data, email: emailVal}
                    if (data.success === true) {
                        setLoginSuccessData(data)
                        setError("");
                        localStorage.setItem("user_name", data.user_email)
                        if (data.is2faActivated === true) {
                            set2faModalOpen(true)
                        } else {
                            onSuccess();
                            setLoginData(data);
                        }
                    } else {
                        setError(data.msg ? data.msg : "Something went wrong! login failed.");
                    }
                } else {
                    setError("Something went wrong! login failed.");
                }
            }, (err) => {
                setLoading(false)
                console.log(err);
                if (err && err.responseJSON && err.responseJSON.msg && err.responseJSON.msg === 'email verification pending!') {
                    setError("Email verification pending!");
                    handleEmailValidation(data.email);
                }
                else if(err && err.responseJSON && err.responseJSON.msg && err.responseJSON.err && err.responseJSON.err === 'Invalid login') {
                    setError('Invalid login')
                }
                else {
                    setError("login failed! Invalid email or password.");
                }
            })
    };

    const setLoginData = (data) => {
        let loginData = data ? data : loginSuccessData;
        localStorage.setItem("token", loginData.token)
        if (!sessionStorage.getItem("colorMode") || sessionStorage.getItem("colorMode") === "") {
            sessionStorage.setItem("colorMode", 'light')
        }
        localStorage.setItem("firstName", loginData.firstName)
        localStorage.setItem("lastName", loginData.lastName)
        if (loginData.is2faActivated === true || loginData.is2faActivated === false) {
            set2faActivationStatus(loginData.is2faActivated);
        }
        if (loginData.b2cUserId && loginData.b2cUserId !== '') {
            localStorage.setItem("b2cUserId", loginData.b2cUserId)
        }
        localStorage.setItem("loginType", "B2C")
        setToken(loginData.token)
        navigate("/cryptopayWalletToDeposit")
    }

    const onVerifyCode = (token) => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            setLoading(true);
            apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/b2c-verify-2fa-token', {
                user_email: localStorage.getItem("user_name"),
                token,
                user2faToken: verificationCode2fa,
                domainNameUrl: localStorage.getItem("domainNameUrl")
            }, (data) => {
                setLoading(false)
                if (data.success === true) {
                    setLoginData();
                    onSuccess();
                } else {
                    setError("Invalid verification token!")
                }
            },
                (err) => {
                    setLoading(false)
                    alert("Something went wrong!");
                    console.log(err);
                })
        } else {
            setError("Invalid token! 2FA token must have 6-digit only")
        }
    }

    const handleKeypress = (e) => {
        if (e.charCode === 13) {
            handleGetStarted();
        }
    };

    const disabledVerify = verificationCode2fa.length !== 6 ? true : false;
    return (
        <form className="LoginScreen">
            <div className="">
                <div className="email-margin "></div>
                {is2faModalOpen ? <span>
                    <div className={`signup-heading ${lightFlag ? "text_color_black" : "text_color_white"}`}>2FA Verification</div>
                    <div className="email-margin "></div>
                    <div className={`accept-privacy-policy-text email-text ${lightFlag ? "text_color_black" : "text_color_white"}`}>
                        Open Google Authenticator app and enter the secret key to continue.
                    </div>
                    <div className="email-margin "></div>
                    <div>
                        <div className={`signup-input-label font-bold ${lightFlag ? "text_color_black" : "text_color_white"}`} data-e2e="verificationCode">Verification Code</div>
                        <div>
                            <input value={verificationCode2fa} type="text" className={`signup-input-field ${lightFlag ? "signup-input-field-light" : "signup-input-field-dark"}`} placeholder=""
                                onChange={(e) => {
                                    setError("")
                                    change2faVerificationCode(e.target.value);
                                }} />
                        </div>
                    </div>
                    <div className="email-margin "></div>
                    {error !== "" && <div className="errorMessage" data-e2e={error}>{error}</div>}
                    <div disabled={disabledVerify ? true : false} className={disabledVerify ? `b2c-create-account b2c-create-account-disabled mt mb ${lightFlag ? "b2c-create-account-disabled-light" : "b2c-create-account-disabled-dark"}` : 'b2c-create-account mt mb'}
                        onClick={() => { if (!disabledVerify) { onVerifyCode(loginSuccessData.token) } }} data-e2e="verify">
                        VERIFY
                    </div>
                    <p></p>
                </span>
                    : <form>
                        <div>
                            <div className={`signup-input-label font-bold leftAlignText ${lightFlag ? "text_color_black" : "text_color_white"}`}>{t('Email')}</div>
                            <div>
                                <input
                                    className={`signup-input-field  ${lightFlag ? "signup-input-field-light" : "signup-input-field-dark"}`}
                                    placeholder="johnsmith@payment.com"
                                    name="email"
                                    type="email"
                                    defaultValue={data.email}
                                    onChange={(e) => handleChange(e)}
                                    data-e2e="email"
                                />
                            </div>
                        </div>
                        <div className="email-margin "></div>
                        <div>
                            <div className={`signup-input-label font-bold leftAlignText ${lightFlag ? "text_color_black" : "text_color_white"}`}>{t('Password')}</div>
                            <div>
                                <input
                                    className={`signup-input-field  ${lightFlag ? "signup-input-field-light" : "signup-input-field-dark"}`}
                                    placeholder={t("Your password")}
                                    name="password"
                                    type='password'
                                    defaultValue={data.password}
                                    onKeyPress={(e) => handleKeypress(e)}
                                    onChange={(e) => handleChange(e)}
                                    data-e2e="passWord"
                                />
                            </div>
                        </div>
                        <div className="email-margin "></div>
                        {error !== "" && <div className="errorMessage" data-e2e="errorMessage">{error}</div>}
                        <div disabled={disabled ? true : false} className={disabled ? `b2c-create-account b2c-create-account-disabled mt mb ${lightFlag ? "b2c-create-account-disabled-light" : "b2c-create-account-disabled-dark"}` : 'b2c-create-account mt mb'} onClick={() => {
                            if (!disabled) { handleGetStarted() }
                        }} data-e2e="getStarted">
                            {t('Log in')}
                        </div>
                        <div className={`b2c-already-account mt mb ${lightFlag ? "text_color_black" : "text_color_white"}`}>
                            {t("Don't have an account")}? <span className="alredy-login" onClick={() => {
                                sessionStorage.setItem("colorMode", lightFlag ? "light" : "dark");
                                window.open("/?signUp=open")
                            }} data-e2e="signUp">{t('Sign Up')}</span>
                        </div>
                    </form>
                }
            </div>
        </form>
    );
}

const mapStateToProps = (state) => {
    return {
        is2faActivated: state.common.is2faActivated,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch(actions.setLoading(value)),
        setToken: (data) => dispatch(actions.setToken(data)),
        set2faActivationStatus: (value) => dispatch(actions.set2faActivationStatus(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
