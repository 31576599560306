import React from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';
import CodeCard from './CodeCard';
import SingleParameter from './SingleParameter';

const parameters = [
    {
        name: "merchantId",
        description: "Unique merchant identifier assigned to merchant upon registration."
    },{
        name: "merchantTransactionId",
        description: "A Unique ID to identify the transaction."
    },
    {
        name: "merchantUserId",
        description: "A unique ID to identify the user."
    },
    {
        name: "recipientWallet",
        description: "The wallet address of the user that the payout is being sent to."
    },
    {
        name: "comment",
        description: "An optional parameter comment that accompanies the withdrawal (visible to the user and merchant)."
    },
    {
        name: "amount",
        description: "A positive integer in cents representing how much the user wishes to withdraw. e.g. 100 EUR would be 10000 / 50 USD would be 5000"
    },
    {
        name: "currency",
        description: "The ISO 3 letter currency code in lowercase, see the supported currency list for details."
    },
    {
        name: "cryptoCurrency",
        description: "The cryptocurrency that the merchant user wishes to withdraw to. 3 letter crypto-currency code in lowercase, see the supported currency list under settings."
    },
    {
        name: "brand",
        description: "An optional string that can be used to send identifying information for the merchant to allow grouping of transactions."
    },
    {
        name: "usebrand",
        description: "An optional string that can be used to send identifying information for the User to allow grouping of transactions."
    },
    {
        name: "network",
        description: "The blockchain network upon which the transaction will take place e.g. mainnet, testnet, goerli. Defaults to mainnet on production and testnet on test servers. "
    },
]
const returns = [
    {
        name: "transactionID",
        description: "A unique transaction identifier that can be used as a reference in other API calls."
    },
    {
        name: "status",
        description: "A status object with the results of the call."
    },
]

const tabSpace = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
const request = {
    type: "Request",
    method: "Post",
    url: ".../pga/CreatePayout",
    code: <div>{`{`}<br />
        {tabSpace}{`"merchantId": "string",`}<br />
        {tabSpace}{`"merchantTransactionId": "string",`}<br />
        {tabSpace}{`"merchantUserId": "string",`}<br />
        {tabSpace}{`"recipientWallet": "string",`}<br />
        {tabSpace}{`"comment": "string",`}<br />
        {tabSpace}{`"amount": "number",`}<br />
        {tabSpace}{`"currency": "string",`}<br />
        {tabSpace}{`"brand": "string",`}<br />
        {tabSpace}{`"usebrand": "string",`}<br />
        {tabSpace}{`"network": "string",`}<br />
        {`}`}</div>,
}

const response = {
    type: "Return",
    method: "Post",
    url: ".../pga/CreatePayout",
    code: <div>{`{`}<br />
    {tabSpace}{`"transactionID": "string",`}<br />
    {tabSpace}{`"status": {`}<br />
    {tabSpace}{tabSpace}{`"code": "string",`}<br />
    {tabSpace}{tabSpace}{`"message": "string",`}<br />
    {tabSpace}{`}`}<br />
    {`}`}</div>,
}

const CreatePayout = (props) => {
    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <div className="tool_main">
                        <div className="tool_heading">Create Payout</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    Create payout allows a merchant to initiate a withdrawal from a merchant wallet to a user
                                </div>
                            </div>
                            <div className="tools_right_part">
                            </div>
                        </div>
                        <div className="tools_content_wrap mt-0">
                            <div className="tools_left_part">
                                <div className="tools_sub_heading">Parameters</div>
                                {parameters.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <CodeCard data={request} />
                            </div>
                        </div>
                        <div className="tools_content_wrap tools_content_wrap_margin" >
                            <div className="tools_left_part">
                                <div className="tools_sub_heading">Returns</div>
                                {returns.map((data, i) => {
                                    return (<SingleParameter key={i} data={data} />)
                                })}
                            </div>
                            <div className="tools_right_part">
                                <CodeCard data={response} />
                            </div>
                        </div>
                    </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout>
    )
}

export default CreatePayout