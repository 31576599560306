
export const SET_LOADING = 'SET_LOADING';
export const SET_2FA_ACTIVATION_STATUS = 'SET_2FA_ACTIVATION_STATUS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_CARD_API_TYPE = 'SET_CARD_API_TYPE';
export const SAVE_CRYPTOS = 'SAVE_CRYPTOS';
export const SET_AND_GET_THEME = 'SET_AND_GET_THEME';
export const SET_AND_GET_B2C_THEME = 'SET_AND_GET_B2C_THEME';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_FROM_DATE = 'SET_FROM_DATE';
export const SET_TO_DATE = 'SET_TO_DATE';
export const SELECTED_TYPES = 'SELECTED_TYPES';
export const SELECTED_STATUS = 'SELECTED_STATUS';
export const SELECTED_CRYPTOS = 'SELECTED_CRYPTOS';
export const SELECTED_PAYMENT_SETTINGS = 'SELECTED_PAYMENT_SETTINGS';
export const SET_AND_GET_TABLE = 'SET_AND_GET_TABLE';
export const CHECK_KYC_STATUS_BUY_CRYPTO = 'CHECK_KYC_STATUS_BUY_CRYPTO';
export const SET_OPEN_KYC_MODAL = 'SET_OPEN_KYC_MODAL';
export const SET_FAQ_QUESTION_DATA = 'SET_FAQ_QUESTION_DATA';
export const SET_IMAGE_BASE_URL = 'SET_IMAGE_BASE_URL';


