import { combineReducers } from 'redux';
import commonReducer from './commonReducer';
import configReducer from './configReducer';
import depositReducer from './depositReducer';
import transactionReducer from './transactionReducer';
import walletReducer from './walletReducer';

const rootReducer = combineReducers({
  config: configReducer,
  common: commonReducer,
  deposit: depositReducer,
  transaction: transactionReducer,
  wallet: walletReducer,
});

export default rootReducer;
