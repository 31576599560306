import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Table } from 'reactstrap';
import MediaQuery from 'react-responsive';
import DepositModal from './DepositModal';
import WithdrawModal from './WithdrawModal';
import ConfigureModal from './ConfigureModal'
import ConfirmationModal from './ConfirmationModal';
const exhangeIcon = "/homeb2c/merchant-exchange.svg"
const depositIcon = "/homeb2c/merchant-deposit.svg"
const withdrawIcon = "/homeb2c/merchant-upload.svg"

const BalanceTable = (props) => {
    const [depositModal, setDepositModal] = useState(false);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [coin, setCoin] = useState("BTC");
    const [blockchain, setBlockchain] = useState("Bitcoin");
    const [merchantWalletList, setMerchantWalletList] = useState();
    const [blockchainAddress, setBlockchainAddress] = useState("");
    const [blockchainNetwork, setBlockchainNetwork] = useState("");
    const [fromDepositCurrency, setFromDepositCurrency] = useState(null);
    const [showConfigureModal, setShowConfigureModal] = useState(false);
    const [configureData, setConfigureData] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [testVariable, setTestVariable] = useState('')//for e2e purpose don't remove
    const userPermission = JSON.parse(localStorage.getItem("viewPermissionOnly"))
    const [viewPermissionOnly, setViewPermissionOnly]=useState(userPermission) ;
    
    useEffect(() => {
        setMerchantWalletList(props.merchantWalletList);
        let blockchainAddress = "";
        let network = "";
        props.merchantWalletList.length > 0 && props.merchantWalletList.find((ele) => {
            if (ele.blockchain === blockchain === "Bitcoin" ? "Bitcoin" : "Ethereum") {
                blockchainAddress = ele.address.key;
                network = ele.network;
            }
        });
        setBlockchainAddress(blockchainAddress);
        setBlockchainNetwork(network);
    }, [props.merchantWalletList]);

    const handleCoinChange = (cryp, blockchain) => {
        setCoin(cryp);
        let blockchainAddress = "";
        let network = "";
        merchantWalletList.length > 0 && merchantWalletList.find((ele) => {
            if (ele.blockchain === (blockchain === "Bitcoin" ? "Bitcoin" : "Ethereum")) {
                blockchainAddress = ele.address.key;
                network = ele.network;
            }
        });
        setBlockchainAddress(blockchainAddress);
        setBlockchainNetwork(network);
        setBlockchain(blockchain);
    };

    const handleCloseDepositModel = () => {
        setDepositModal(false);
    };
    const handleCloseWithdrawModel = () => {
        setWithdrawModal(false);
    };

    const getStatusComponent = (status, index) => {
        switch (status) {
            case "CreatePayment":
            case "DepositFlow:Launched":
            case "DepositFlow:AwaitingDeposit":
            case "DepositMonitor:MonitoringMemPool":
            case "DepositFlow:DepositByCardFlowLaunched":
            case "DepositFlowViaWallet:Launched":
            case "CreateSecurePaymentConfirmation":
            case "CreateSecurePayment":
            case "DepositFlow:CardWhiteListingCheck":
            case "DepositFlow:RoutingService":
            case "DepositFlow:AMLRulesCheck":
            case "DepositFlow:AcquirerNames":
            case "DepositFlow:LoadBalanceCheck":
            case "DepositFlow:AcquirerNamesBeforeRandomSelection":
            case "DepositFlow:JwtTokenGenerated":
            case "TPEvent:UIPageLoaded":
            case "TPEvent:paymentInitStarted":
            case "TPEvent:paymentStarted":
            case "TPEvent:paymentInitCompleted":
            case "TPEvent:paymentCompleted":
            case "DepositFlow:Acquirer3dsEvent":
            case "DepositFlow:TrustNotificationSecurityCheckPassed":
            case "DepositFlow:CallbackResponseReceivedFromAcquirer":
            case "DepositMonitor:ValidCardRequest":
            case "DepositMonitor:DepositScheduledToSettle":
            case "DepositMonitor:DepositSecurityCodeOK":
            case "DepositMonitor:CryptoOrder":
            case "DepositMonitor:SelectedLiquidityProvider":
            case "DepositMonitor:WalletStatus":
            case "DepositeMoniter:MerchantBalanceUpdated":
                return <div data-e2e="pending" className="Tstatus_witheld">PENDING</div>;
            case "DepositMonitor:TimedOutMonitoringMemPool":
                return <div data-e2e="timeOut" className="Tstatus_witheld">TIMED OUT</div>;
            case "success":
            case "DepositMonitor:DepositCompleted":
            case "DepositMonitor:DepositManuallyCompleted":
            case "WalletManagementService:WithdrawalCompleted":
            case "DepositFlowViaWallet:DepositCompleted":
            case "WalletManagementService:TransferCompleted":
            case "success":
                return <div data-e2e="success" className="Tstatus_success">SUCCESS</div>;
            case "DepositMonitor:DepositRejected":
            case "WalletManagementService:WithdrawalRejectionCompleted":
            case "DepositMonitor:NotfiyRejectionToMerchant":
            case "DepositMonitor:DepositManualRejected":
            case "WalletManagementService:WithdrawalRejectedDueToMerchantBalance":
            case "WalletManagementService:WithdrawalRejectedDueToInsufficientFunds":
                return <div className="Tstatus_failed" data-e2e="rejected">REJECTED</div>;
            case "DepositMonitor:DepositPendingManualApproval":
            case "WithdrawalFlow:PendingApproval":
                return <div data-e2e={`${index}-require-approval`} className="Tstatus_witheld">REQUIRE APPROVAL</div>;
            default:
                return <div data-e2e="processing" className="Tstatus_pending">PROCESSING</div>;
        }
    }
    return (
        <div className="TransactionTableMain s">
            {props.data && props.data.length > 0 && <div><MediaQuery minWidth={481}><Table responsive>
                <tbody className="TransactionTable_BODY">
                    <tr>
                        <th className="TransactionTable_heading" width="20%" onClick={() => {
                            props.changeOrder("crypName")
                            props.sortList("crypName", props.orderBy)
                        }} data-e2e="TransactionSort">
                            <div className='first-table-header'>
                                ASSET {props.sortBy === "crypName" && <SortByIcon orderBy={props.orderBy} />}
                            </div>
                        </th>
                        <th className="TransactionTable_heading padding-balance" width="14%" onClick={() => {
                            props.changeOrder("holding")
                            props.sortList("holding", props.orderBy)
                        }} data-e2e="TypeSort">HOLDING (%) {props.sortBy === "holding" && <SortByIcon orderBy={props.orderBy} />}</th>
                        <th className="TransactionTable_heading padding-balance" width="14%" onClick={() => {
                            props.changeOrder("amount")
                            props.sortList("amount", props.orderBy)
                        }} data-e2e="userId">AMOUNT {props.sortBy === "amount" && <SortByIcon orderBy={props.orderBy} />}</th>
                        {/* <th className="TransactionTable_heading padding-balance" width="14%" onClick={() => {
                            props.changeOrder("pendingAmount")
                            props.sortList("pendingAmount", props.orderBy)
                        }} data-e2e="userId">Pending Amount {props.sortBy === "pendingAmount" && <SortByIcon orderBy={props.orderBy} />}</th> */}
                        <th className="TransactionTable_heading padding-balance" width="14%" onClick={() => {
                            props.changeOrder("exchangeRate")
                            props.sortList("exchangeRate", props.orderBy)
                        }} data-e2e="dateTime">PRICE{props.sortBy === "exchangeRate" && <SortByIcon data-e2e="DateAndTimeSort" orderBy={props.orderBy} />}</th>
                        <th className="TransactionTable_heading padding-balance" width="20%" data-e2e="FiatAmountSort"></th>

                    </tr>
                    {props.data.map((TData, i) => {
                        return (
                            <SingleTransactionTableRow
                                viewPermissionOnly={viewPermissionOnly}
                                image_base_url={props.image_base_url}
                                setExchangeModal={props.setExchangeModal}
                                setFromExchangeCurrency={props.setFromExchangeCurrency}
                                setFromDepositCurrency={setFromDepositCurrency}
                                index={i} data={TData} key={i}
                                setDepositModal={setDepositModal}
                                setWithdrawModal={setWithdrawModal}
                                handleCoinChange={handleCoinChange}
                                setShowConfigureModal={setShowConfigureModal}
                                setConfigureData={setConfigureData}
                            />
                        )
                    })}
                </tbody>
            </Table></MediaQuery>
                <MediaQuery maxWidth={480}>
                    <div className="TrasactionsMobileTable">
                        {props?.data.map((TData, i) => {
                            return (
                                <SingleTransactionMobileRow data={TData} key={i}
                                    viewPermissionOnly={viewPermissionOnly}
                                    getStatusComponent={getStatusComponent}
                                    openTransactionDetails={(data) => props?.openTransactionDetails(data)}
                                    image_base_url={props?.image_base_url}
                                    setExchangeModal={props?.setExchangeModal}
                                    setFromExchangeCurrency={props?.setFromExchangeCurrency}
                                    setFromDepositCurrency={setFromDepositCurrency}
                                    setDepositModal={setDepositModal}
                                    setWithdrawModal={setWithdrawModal}
                                    handleCoinChange={handleCoinChange}
                                    setShowConfigureModal={setShowConfigureModal}
                                    setConfigureData={setConfigureData}
                                    index={i} />
                            )
                        })}
                    </div>
                </MediaQuery>
            </div>}
            {depositModal
              && <DepositModal
                fromDepositCurrency={fromDepositCurrency}
                coin={coin} setCoin={setCoin}
                blockchain={blockchain}
                open={depositModal}
                onClose={handleCloseDepositModel}
              />}
            {withdrawModal
              && <WithdrawModal
                blockchainAddress={blockchainAddress}
                blockchainNetwork={blockchainNetwork}
                coin={coin}
                handleCoinChange={handleCoinChange}
                open={withdrawModal}
                onClose={handleCloseWithdrawModel}
                openConfirmationModal={() => setShowConfirmationModal(true)}
                testVariable={testVariable}//for e2e purpose don't remove
                setTestVariable={setTestVariable}//for e2e purpose don't remove
              />}
            {showConfigureModal
              && <ConfigureModal
                getBalances={props.getBalances}
                data={configureData}
                open={showConfigureModal}
                onClose={() => setShowConfigureModal(false)}
                viewPermissionOnly={viewPermissionOnly}
              />}
            {showConfirmationModal
              && <ConfirmationModal
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                testVariable={testVariable}//for e2e purpose don't remove
              />}
        </div>
    )
}

export default BalanceTable;

const SortByIcon = (props) => {
    return (
        <i data-e2e="dropDownIcon" className={`fa fa-chevron-${props.orderBy === "desc" ? "down" : "up"} TransactionTable_heading_sortIcon`} />
    )
}
const SingleTransactionTableRow = (props) => {
    let { data, index } = props;
    return (
        <tr className="CP_TableRow" key={index} onClick={() => { }}>
            <td className="transactionsTable_item bl">
                <div className='balance-row-el crypto-row'>
                    <img className="USTImages" src={`${props.image_base_url}${data.icon}`} alt="bitcoin" />
                    <span>
                        {' '}
                        {props.data.coin ? props.data.coin : 'N/A'} / <span>{props.data.crypName ? props.data.crypName : 'N/A'}</span>
                    </span>
                </div>
            </td>
            <td className="transactionsTable_item bl">
                <div className='balance-row-el crypto-row-col'>
                    <div>{parseFloat(data.holding).toFixed(2)}%</div>
                    <div className="barParant">
                        <div className="barChild" style={{ width: `${data.holding}%` }}></div>
                    </div>
                </div>
            </td>
            <td className="transactionsTable_item bl">
                <div className='balance-row-el crypto-row-col'>
                    <div data-e2e-amount={parseFloat(data.amount).toFixed(6)} data-e2e={props&&props.data&&props.data.crypName&&props.data.crypName}>{parseFloat(data.amount).toFixed(6)}</div>
                    <div style={{ fontSize: '12px', opacity: '0.5', position: 'absolute', bottom: '0px', color: '#999999' }}>
                        {Number(parseFloat(data.USDAmount).toFixed(6)).toLocaleString()} USD
                    </div>
                </div>
            </td>
            {/* <td className="transactionsTable_item bl">
                <div className='balance-row-el crypto-row-col'>
                    <div>{data.pendingAmount ? parseFloat(data.pendingAmount).toFixed(6) : "-"}</div>                    
                </div>
            </td> */}
            <td className="transactionsTable_item bl">
                <div className='balance-row-el crypto-row-col'>
                    <div>${Number(data.exchangeRate).toLocaleString()}</div>
                </div>
            </td>

            <td className="transactionsTable_item bl">
                <div className={props?.viewPermissionOnly?'balance-row-el crypto-row':'balance-row-el crypto-row imgCol'}>
                    <div className='merchant-img-container'>
                        <div className='balance-button' onClick={() => {
                            props.setShowConfigureModal(true);
                            props.setConfigureData(data);
                        }}>
                            <i className="fa fa-cog" style={{ fontSize: "17px" }} aria-hidden="true"></i>
                            <div data-e2e={`${props &&props.data &&props.data.crypName && props.data.crypName}-configure`}>{`Configure`} </div>
                        </div>
                        <div hidden = { props?.viewPermissionOnly ? true : false } className='balance-button' onClick = { props?.viewPermissionOnly ? () => {} : () => {
                            props.setFromExchangeCurrency(data.coin)
                            props.setExchangeModal(true);
                        }}>
                            <img
                                className='merchant-balance-icons'
                                src={`${props.image_base_url}${exhangeIcon}`}
                            />
                            <div data-e2e={`${props&&props.data&&props.data.crypName}-exchange`}>
                                Exchange
                            </div>
                        </div>
                        <div hidden = { props?.viewPermissionOnly ? true : false } className='balance-button' onClick = { props?.viewPermissionOnly ? () => {} : () => {
                            props.setFromDepositCurrency(data.cryptoName);
                            props.setDepositModal(true)
                        }}>
                            <img
                                className='merchant-balance-icons'
                                src={`${props.image_base_url}${depositIcon}`}
                            />
                            <div data-e2e={`${props&&props.data&&props.data.crypName}-deposit`}>
                                Deposit
                            </div>
                        </div>
                        <div hidden = { props?.viewPermissionOnly ? true : false } className='balance-button'
                            onClick = { props?.viewPermissionOnly ? () => {} : () => { props.setWithdrawModal(true); props.handleCoinChange(data.coin, props.data.cryptoName) }}>
                            <img

                                className='merchant-balance-icons'
                                src={`${props.image_base_url}${withdrawIcon}`}
                            />
                            <div data-e2e={`${props&&props.data&&props.data.crypName&&props.data.crypName}-withdraw`}>
                                Withdraw                                        </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}

const SingleTransactionMobileRow = (props) => {
    let { data, getStatusComponent, index } = props;
    return (
      <div style={{ overflow: 'scroll' }} className="CP_MobileWrap" onClick={props?.viewPermissionOnly ? () => {} : () => props?.openTransactionDetails(data)}>
        <tr className="CP_TableRow" key={index} onClick={() => {}}>
          <td className="transactionsTable_item bl">
            <div
              className="balance-row-el crypto-row"
              width="20%"
              onClick={() => {
                props?.changeOrder('crypName');
                props?.sortList('crypName', props?.orderBy);
              }}
              data-e2e="TransactionSort"
            >
              ASSET {props?.sortBy === 'crypName' && <SortByIcon orderBy={props?.orderBy} />}
            </div>
            <div
              className="balance-row-el crypto-row"
              width="14%"
              onClick={() => {
                props?.changeOrder('holding');
                props?.sortList('holding', props?.orderBy);
              }}
              data-e2e="TypeSort"
            >
              HOLDING (%) {props?.sortBy === 'holding' && <SortByIcon orderBy={props?.orderBy} />}
            </div>
            <div
              className="balance-row-el crypto-row"
              width="14%"
              onClick={() => {
                props?.changeOrder('amount');
                props?.sortList('amount', props?.orderBy);
              }}
              data-e2e="userId"
            >
              AMOUNT {props?.sortBy === 'amount' && <SortByIcon orderBy={props?.orderBy} />}
            </div>
            <div
              className="balance-row-el crypto-row"
              width="14%"
              onClick={() => {
                props?.changeOrder('exchangeRate');
                props?.sortList('exchangeRate', props?.orderBy);
              }}
              data-e2e="dateTime"
            >
              PRICE{props?.sortBy === 'exchangeRate' && <SortByIcon data-e2e="DateAndTimeSort" orderBy={props?.orderBy} />}
            </div>
          </td>
          <td className="transactionsTable_item bl">
            <div className="balance-row-el crypto-row">
              <div>
                <img className="USTImages" src={`${props?.image_base_url}${data?.icon}`} alt="bitcoin" />
              </div>
              <div>
                {' '}
                {props?.data?.coin ? props?.data?.coin : 'N/A'} / <span>{props?.data?.crypName ? props?.data?.crypName : 'N/A'}</span>
              </div>
            </div>
            <div className="balance-row-el crypto-row">
              <div>
                {parseFloat(data?.holding).toFixed(2)}%
                <div className="barParant">
                  <div className="barChild" style={{ width: `${data?.holding}%` }}></div>
                </div>
              </div>
            </div>
            <div className="balance-row-el crypto-row">
              <div>
                <div data-e2e-amount={parseFloat(data?.amount).toFixed(6)} data-e2e={props && props?.data && props?.data?.crypName && props?.data?.crypName}>
                  {parseFloat(data?.amount).toFixed(6)}
                </div>
                <div style={{ fontSize: '12px', opacity: '0.5', position: 'absolute', bottom: '0px', color: '#999999' }}>
                  {Number(parseFloat(data?.USDAmount).toFixed(6)).toLocaleString()} USD
                </div>
              </div>
            </div>
            {/* <td className="transactionsTable_item bl">
                <div className='balance-row-el crypto-row-col'>
                    <div>{data.pendingAmount ? parseFloat(data.pendingAmount).toFixed(6) : "-"}</div>                    
                </div>
            </td> */}
            <div className="balance-row-el crypto-row">
              <div>${Number(data?.exchangeRate).toLocaleString()}</div>
            </div>
          </td>
          <td className="transactionsTable_item bl">
            <div className={props?.viewPermissionOnly ? 'balance-row-el crypto-row' : 'balance-row-el crypto-row imgCol'}>
              <div className="merchant-img-container">
                <div
                  className="balance-button"
                  onClick={() => {
                    props?.setShowConfigureModal(true);
                    props?.setConfigureData(data);
                  }}
                >
                  <i className="fa fa-cog" style={{ fontSize: '17px' }} aria-hidden="true"></i>
                  <div data-e2e={`${props && props?.data && props?.data?.crypName && props?.data?.crypName}-configure`}>{`Configure`} </div>
                </div>
              </div>
            </div>
            <div className={props?.viewPermissionOnly ? 'balance-row-el crypto-row' : 'balance-row-el crypto-row imgCol'}>
              <div className="merchant-img-container">
                <div
                  hidden={props?.viewPermissionOnly ? true : false}
                  className="balance-button"
                  onClick={
                    props?.viewPermissionOnly ? () => {} : () => {
                          props?.setFromExchangeCurrency(data?.coin);
                          props?.setExchangeModal(true);
                        }
                  }
                >
                  <img className="merchant-balance-icons" src={`${props?.image_base_url}${exhangeIcon}`} />
                  <div data-e2e={`${props && props?.data && props?.data?.crypName}-exchange`}>Exchange</div>
                </div>
              </div>
            </div>
            <div className={props?.viewPermissionOnly ? 'balance-row-el crypto-row' : 'balance-row-el crypto-row imgCol'}>
              <div className="merchant-img-container">
                <div
                  hidden={props?.viewPermissionOnly ? true : false}
                  className="balance-button"
                  onClick={
                    props?.viewPermissionOnly ? () => {} : () => {
                          props?.setFromDepositCurrency(data?.cryptoName);
                          props?.setDepositModal(true);
                        }
                  }
                >
                  <img className="merchant-balance-icons" src={`${props?.image_base_url}${depositIcon}`} />
                  <div data-e2e={`${props && props?.data && props?.data?.crypName}-deposit`}>Deposit</div>
                </div>
              </div>
            </div>
            <div className={props?.viewPermissionOnly ? 'balance-row-el crypto-row' : 'balance-row-el crypto-row imgCol'}>
              <div className="merchant-img-container">
                <div
                  hidden={props?.viewPermissionOnly ? true : false}
                  className="balance-button"
                  onClick={
                    props?.viewPermissionOnly ? () => {} : () => {
                          props?.setWithdrawModal(true);
                          props?.handleCoinChange(data?.coin, props?.data?.cryptoName);
                        }
                  }
                >
                  <img className="merchant-balance-icons" src={`${props?.image_base_url}${withdrawIcon}`} />
                  <div data-e2e={`${props && props?.data && props?.data?.crypName && props?.data?.crypName}-withdraw`}>Withdraw</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </div>
    );
}

