import React from 'react';

const menuList = [
    {
        name: "API Guide",
        path: "/merchantTools/APIGuide",
    },
    {
        name: "Authentication",
        path: "/merchantTools/Authentication",
    },
    {
        name: "Create Payment",
        path: "/merchantTools/CreatePayment",
    },
    {
        name: "Create Payout",
        path: "/merchantTools/CreatePayout",
    },
    {
        name: "Get Transactions",
        path: "/merchantTools/GetTransactions",
    },
    {
        name: "Webhooks",
        path: "/merchantTools/Webhooks",
    },
    {
        name: "Error Handling",
        path: "/merchantTools/ErrorHandling",
    },
];

class SideToolsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pathname: ""
        }
    }

    componentDidMount() {
        this.setState({ pathname: window.location.pathname })
    }

    render() {
        return (<div className="sideToolsMenuMain">
            <div className="sideToolsMenu_heading">Merchant Tools</div>
            {menuList.map((menu, i) => {
                return (<div key={i}
                    data-e2e={menu.name}
                    className={`sideToolsMenu_item ${this.state.pathname.indexOf(menu.path) !== -1 ? "sideToolsMenu_item_active" : ""}`}
                    onClick={() => {
                        this.props.navigate(menu.path)
                    }}>
                    {menu.name}
                </div>)
            })}
        </div>)
    }
}

export default SideToolsMenu