import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { constants } from '../../../common/constants';

const useStyles = styled(Tooltip)({
  arrow: {
    "&:before": {
      backgroundColor: '#497cf9',
    },
  },
  tooltip: {
    fontSize: "1em",
    backgroundColor: '#497cf9',
    borderRadius: '32px',
    padding: '4px 18px',
    marginLeft: '-35px',
    marginBottom: '8px !important'
  },
});

const MerchantTransactionTableRow = (props) => {
  const [transactionInformation, setTransactionInformation] = useState(false);
  const [showCoppied, toggleCoppied] = useState({
    copy1: false,
    copy2: false,
    copy3: false,
    copy4: false
  });
  
  const getCryptoName = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return 'Bitcoin';
      case 'ETH':
        return 'Ethereum';
      case 'USDT':
        return "Ethereum";
      case 'DAI':
        return 'Ethereum';
      case constants?.TRC20_USDT_CRYPTO_ID:
      case constants?.TRX_CRYPTO_ID:
        return "Tron"
      default:
        return 'Ethereum';
    }
  };

  const handleCopy = (e) => {
    toggleCoppied({ ...showCoppied, [e]: true });
    setTimeout(() => {
      toggleCoppied(false);
    }, 2000);
  };
  const classes = useStyles;

  const getToFixedValue = (value) => {
    if (value) {
      return parseFloat(value).toFixed(6);
    } else if (value === 0 || value === "0") {
      return 0
    } else {
      return parseFloat(value).toFixed(6);
    }
  }

  return (
    <React.Fragment>
      <tr onClick={() => (transactionInformation ? setTransactionInformation(false) : setTransactionInformation(true))}>
        <td className='p-30-l'>{
          moment(props.data.createdDate).format("YYYY-MM-DD HH:mm:ss")
        }</td>
        <td>{props.data.type}</td>
        {["Swap", "Autotrade"].includes(props.data.type) ? <td>({props.data.fromCrypto} - {props.data.toCrypto})</td> : (["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? <td>{props.data.selected.cryptoId}</td> : <td>{props.data.cryptoId}</td>)}
        {["Swap", "Autotrade"].includes(props.data.type) ? <td>({getToFixedValue(props.data.fromAmount)} - {getToFixedValue(props.data.toAmount)})</td> : (["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? <td>{getToFixedValue(props.data.selected.amount)}</td> : <td>{getToFixedValue(props.data.amount)}</td>)}
        <td className='last-row'>
          <div>
            <p>${["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? Number(getToFixedValue(props.data.depositAmount)).toLocaleString() : Number(getToFixedValue(props.data.USDAmount)).toLocaleString()}</p>
            <div className="transaction_item_next p">
              <i data-e2e={`${props&&props.data&&props.data.type&&props.data.type}-icon-${transactionInformation ? 'up' : 'down'}`} className={`fa fa-lg fa-angle-${transactionInformation ? 'up' : 'down'} next__arrow`} aria-hidden="true"></i>
            </div>
          </div>
        </td>

      </tr>
      {transactionInformation && (
        <tr>
          <td colSpan={5}>
            <div>

              <div className="singleTRRowMerchant s">
                {["Swap", "Autotrade"].includes(props.data.type) ? <div className="title">
                  {`(${props.data.fromCrypto}-${props.data.toCrypto}) / (${getCryptoName(props.data.fromCrypto)}-${getCryptoName(props.data.toCrypto)}) ${props.data.type}`}
                  <span>
                    {` - `}
                    {`(${props.data.fromAmount ? getToFixedValue(props.data.fromAmount) : "0"} ${props.data.fromCrypto ? props.data.fromCrypto : "N/A"} - ${props.data.toAmount ? getToFixedValue(props.data.toAmount) : "0"} ${props.data.toCrypto ? props.data.toCrypto : "N/A"})`} ( {"$ "}{props.data.USDAmount ? getToFixedValue(props.data.USDAmount) : '0'} )
                  </span>
                </div> : <div className="title">
                  {`${["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? props.data.selected.cryptoId : props.data.cryptoId} / ${getCryptoName(["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? props.data.selected.cryptoId : props.data.cryptoId)} ${props.data.type} `}
                  <span>
                    {` - `}
                    {["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? getToFixedValue(props.data.selected.amount) : (props.data.amount ? getToFixedValue(props.data.amount) : "0")} {["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? props.data.selected.cryptoId : (props.data.cryptoId ? props.data.cryptoId : "N/A")} ( {"$ "}{["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? getToFixedValue(props.data.depositAmount) : (props.data.USDAmount ? getToFixedValue(props.data.USDAmount) : '0')} )
                  </span>

                </div>}
                <div className="TR__address">
                  {!["AdminDeposit", "AdminWithdrawal", "settlementOut"].includes(props.data.type) &&
                    <React.Fragment>
                      <div className="from__address">
                        <div className="address__title">From</div>
                        <div className="address__data">
                          {props.data && props.data.fromWallet ? props.data.fromWallet.substr(0, 15) + "..." + props.data.fromWallet.substr(-4) : 'N/A'}
                          <span>
                            <Tooltip open={showCoppied.copy1} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <span
                                onClick={() => {
                                  copy(props.data && props.data.fromWallet ? props.data.fromWallet : 'N/A');
                                  handleCopy('copy1');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>

                      <div className="from__address">
                        <div className="address__title">To</div>
                        <div className="address__data">
                          {["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? (props.data && props.data.withdrawalWalletAddress ? props.data.withdrawalWalletAddress.substr(0, 15) + "..." + props.data.withdrawalWalletAddress.substr(-4) : 'N/A') : (props.data && props.data.toWallet ? props.data.toWallet.substr(0, 15) + "..." + props.data.toWallet.substr(-4) : 'N/A')}
                          <span>
                            <Tooltip open={showCoppied.copy2} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                              <span
                                onClick={() => {
                                  copy(["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? (props.data && props.data.withdrawalWalletAddress ? props.data.withdrawalWalletAddress : "N/A") : (props.data && props.data.toWallet ? props.data.toWallet : 'N/A'));
                                  handleCopy('copy2');
                                }}
                              >
                                <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>

                    </React.Fragment>
                  }


                  <div className="from__address">
                    <div className="address__title">Txid</div>
                    <div className="address__data">
                      {props.data && props.data.transactionId ? props.data.transactionId.substr(0, 15) + "..." + props.data.transactionId.substr(-4) : 'N/A'}
                      <span>
                        <Tooltip open={showCoppied.copy3} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                          <span
                            onClick={() => {
                              copy(props.data && props.data.transactionId ? props.data.transactionId : 'N/A');
                              handleCopy('copy3');
                            }}
                          >
                            <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>


                <div className="d-flex">

                  {props.data.Merchant && props.data.Merchant.name != null &&
                    <div className="balanceAfterTR">
                      <div className="balanceAfterTR__heading">{`Merchant Name`}</div>
                      <div className="balanceAfterTR__data">
                        {props.data.Merchant.name}
                      </div>
                    </div>
                  }

                  {props.data.transactionRefLink && props.data.type === "Autotrade" &&
                    <div className="balanceAfterTR">
                      <div className="balanceAfterTR__heading">{props.data.refTransactionType === "merchantWalletTransaction"? `Ref TransactionId` : `Transaction Ref Link`}</div>
                      <div className="balanceAfterTR__data">
                        {props.data.refTransactionType === "merchantWalletTransaction" ?
                           <Tooltip title={props.data && props.data.transactionRefLink} placement="top">
                            <div className="transaction_items cursor-pointer">
                              {props.data && props.data.transactionRefLink &&
                                props.data.transactionRefLink
                                ? props.data.transactionRefLink.substring(0, 10) +
                                "....." +
                                props.data.transactionRefLink.substring(
                                  props.data.transactionRefLink.length - 6,
                                  props.data.transactionRefLink.length
                                )
                                : "N/A"}
                            </div>
                           </Tooltip>
                          :
                          <Tooltip title={props.data && props.data.transactionRefLink} placement="top">
                            <Link
                              to={`/myAccount/transactions/${props.data && props.data.transactionRefLink}`}
                            >
                              <div
                                className="transaction_items cursor-pointer"
                                style={{
                                  color: "#0091FF",
                                  textDecoration: "underline",
                                }}
                              >
                                {props.data && props.data.transactionRefLink &&
                                  props.data.transactionRefLink
                                  ? props.data.transactionRefLink.substring(0, 10) +
                                  "....." +
                                  props.data.transactionRefLink.substring(
                                    props.data.transactionRefLink.length - 6,
                                    props.data.transactionRefLink.length
                                  )
                                  : "N/A"}
                              </div>
                            </Link>
                          </Tooltip>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>

            </div>
          </td>
        </tr>

      )}
    </React.Fragment>
  );
};

export default MerchantTransactionTableRow;
