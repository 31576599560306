import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../common/constants';
import { checkValueInSS } from '../../../common/theme';

function B2CFooter(props) {
  const [host, setHost] = useState("")
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  const [headerButtonDarkBgColour, setHeaderButtonDarkBgColour] = useState(null);
  const navigate = useNavigate();
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const COIN_SPIRIT_BASE_PATH = useSelector((state)=>state.config.COIN_SPIRIT_BASE_PATH);
  const COIN_SPIRIT_SUPPORT_MAIL = useSelector((state)=>state.config.COIN_SPIRIT_SUPPORT_MAIL);
  const COIN_SWAP_CENTER_BASE_PATH = useSelector((state)=>state.config.COIN_SWAP_CENTER_BASE_PATH)
  const COIN_SWAP_CENTER_SUPPORT_MAIL = useSelector((state)=>state.config.COIN_SWAP_CENTER_SUPPORT_MAIL)
  const COINS_TRADE_HUB_BASE_PATH = useSelector((state)=>state.config.COINS_TRADE_HUB_BASE_PATH)
  const COINS_TRADE_HUB_SUPPORT_MAIL = useSelector((state)=>state.config.COINS_TRADE_HUB_SUPPORT_MAIL)
  const coinsspiritFooterLinks = [
    {
      path: `${COIN_SPIRIT_BASE_PATH}external-privacy-policy-notice/`,
      name: "Privacy Policy"
    },
    {
      path: `${COIN_SPIRIT_BASE_PATH}terms-of-use/`,
      name: "Terms of Use"
    },
    {
      path: `${COIN_SPIRIT_BASE_PATH}cookies-policy/`,
      name: "Cookies Policy"
    },
    {
      path: `${COIN_SPIRIT_BASE_PATH}contact-us/`,
      name: "Contact us"
    },
    {
      path: `${COIN_SPIRIT_BASE_PATH}kyc-and-aml-policy/`,
      name: "KYC and AML Policy"
    }
  ]
  const coinsTradeHubFooterLinks = [
    {
      path: `${COINS_TRADE_HUB_BASE_PATH}cookie-policy/`,
      name: "Cookie Policy"
    },
    {
      path: `${COINS_TRADE_HUB_BASE_PATH}privacy-policy/`,
      name: "Privacy Policy"
    },
    {
      path: `${COINS_TRADE_HUB_BASE_PATH}terms-and-conditions/`,
      name: "Terms and Conditions"
    },
    {
      path: `${COINS_TRADE_HUB_BASE_PATH}kyc-policy/`,
      name: "KYC Policy"
    },
    {
      path: `${COINS_TRADE_HUB_BASE_PATH}aml-policy/`,
      name: "AML Policy"
    },
    {
      path: `${COINS_TRADE_HUB_BASE_PATH}risk-advisory/`,
      name: "Risk Advisory"
    }
  ]
  const coinsSwapCenterFooterLinks = [
    {
      path: `${COIN_SWAP_CENTER_BASE_PATH}terms-and-conditions`,
      name: "Terms and conditions"
    },

    {
      path: `${COIN_SWAP_CENTER_BASE_PATH}cookies-policy`,
      name: "Cookies policy"
    },
    {
      path: `${COIN_SWAP_CENTER_BASE_PATH}external-privacy-policy-notice`,
      name: "External privacy policy notice"
    },

    {
      path: `${COIN_SWAP_CENTER_BASE_PATH}contact-us`,
      name: "Contact us"
    },
    {
      path: `${COIN_SWAP_CENTER_BASE_PATH}kyc-and-aml-policy`,
      name: "KYC and AML Policy"
    }
    ,
    {
      path: `${COIN_SWAP_CENTER_BASE_PATH}fee-schedule`,
      name: "Fee schedule"
    }
  ]

  useEffect(() => {
    setHost(window.location.hostname);
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
      if (checkValueInSS("headerButtonDarkBgColour")) {
        setHeaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"))
      }
    }
  }, []);

  function checkCoinsSpirit() {
    return host.includes("coinsspirit");
  }

  function checkCoinsSwapCenter() {
    return host.includes("coinsswapcenter");
  }

  function checkCoinsTradeHub() {
    return host.includes("coinstradehub");
  }

  return (
    <footer className="footer">
      <div className="content-container">{
        checkCoinsTradeHub() ? (
          <div className='coins-trade-main'>
            <div className='coins-trade-left'>
              <p className="footer__copyright__images"><img loading="lazy" decoding="async" src={`${imageBaseUrl}/upd/icons/visa-mastercard-logo.svg`} alt="" width="160" height="110" /></p>
            </div>
            <div className='coins-trade-center'>
              <ul >
                <div>
                <li >
                  Coins Trade Hub is a brand of RLT Services S.R.O (<span className='sro-code' >21223131</span>) and is registered as a Virtual Asset Service Provider to offer provision of services connected with a virtual asset. For more information contact <a href={`mailto:${COINS_TRADE_HUB_SUPPORT_MAIL}`}>{COINS_TRADE_HUB_SUPPORT_MAIL}</a>
                </li>
                </div>
                <li className='coins-trade-copyright' >
                  Coins Trade Hub 2024. All rights reserved.
                </li>
              </ul>
            </div>
            <div className='coins-trade-right'>
              <b>
                Licensing and Compliance
              </b>
              <table>
                {
                    coinsTradeHubFooterLinks.map((footer)=>{
                      if(footer.path.includes("risk-advisory")){
                        return(
                          <tr>
                            <td className='warning-emoji' >
                              {"⚠️"}
                            </td>
                            <td >
                              <a href={footer?.path} target="_blank">{footer?.name}</a>
                            </td>
                          </tr>
                        )
                      }
                      return (
                          <tr>
                            <td>
                              <b>
                              {">"}
                              </b>
                            </td>
                            <td >
                              <a href={footer?.path} target="_blank">{footer?.name}</a>
                            </td>
                          </tr>
                      )
                    })
                }
              </table>
            </div>
          </div>
        ):(
        <div className='footer-copyright-main-container'>
          <div className='footer-copyright-main-left'>
            <p className="footer__copyright">© {paymentProviderName ? paymentProviderName : "Cryptonpay"} {new Date().getFullYear()}. All rights reserved.</p>
             {
              checkCoinsSpirit() && <div>
                <p className="footer__copyright">{constants.COIN_SPIRIT_FOOTER_TEXT}<a href={`mailto:${COIN_SPIRIT_SUPPORT_MAIL}`}>{`mailto:${COIN_SPIRIT_SUPPORT_MAIL}`}</a></p>
                  <p className="footer__copyright">{constants.COIN_SPIRIT_FOOTER_ADDRESS}</p>
                  <p className="footer__copyright__images"><img loading="lazy" decoding="async" src={`${imageBaseUrl}/upd/icons/mastercard-securecode.png`} alt="" width="96" height="54" /><img loading="lazy" decoding="async" src={`${imageBaseUrl}/upd/icons/mastercard-securecode1.png`} alt="" width="96" height="54" /></p>
              </div>
             }
                          {
              checkCoinsSwapCenter() && <div>
                <p className="footer__copyright">{constants.COIN_SWAP_CENTER_FOOTER_TEXT}<a style={{color:headerButtonDarkBgColour}} href={`mailto:${COIN_SWAP_CENTER_SUPPORT_MAIL}`}>{`mailto:${COIN_SWAP_CENTER_SUPPORT_MAIL}`}</a></p>
                  <p className="footer__copyright">{constants.COIN_SWAP_CENTER_FOOTER_ADDRESS}</p>
                  <p className="footer__copyright">{constants?.COIN_SWAP_CENTER_FOOTER_NOTE}</p>
                  <p className="footer__copyright__images"><img loading="lazy" decoding="async" src={`${imageBaseUrl}/upd/icons/mastercard-securecode.png`} alt="" width="96" height="54" /><img loading="lazy" decoding="async" src={`${imageBaseUrl}/upd/icons/mastercard-securecode1.png`} alt="" width="96" height="54" /></p>
              </div>
             }


              {
                !checkCoinsSwapCenter() && !checkCoinsSpirit() && (
                  <p className="footer__copyright">
                    Integrate {paymentProviderName ? paymentProviderName : "Cryptonpay"} with your business.
                  </p>
                )
              }
          </div>
          {!checkCoinsSpirit() && !checkCoinsSwapCenter() &&  <button className='footer-cookie-policy' onClick={() => {  navigate('/cookies-policy'), window.scrollTo(0, 0); }}> <a >Cookies Policy</a></button> }
          {checkCoinsSpirit() && (
            <div className="menu-footer-links-container">
              <ul>
                {
                  coinsspiritFooterLinks.map((footer)=>{
                    return (
                      <li key={footer.path}>
                        <a href={footer.path} target="_blank">{footer.name}</a>
                      </li>
                    )
                  })
                }
                </ul>
            </div>
          )}
          {checkCoinsSwapCenter() && (
            <div className="menu-footer-links-container">
              <ul>
                {
                  coinsSwapCenterFooterLinks?.map((footer)=>{
                    return (
                      <li key={footer?.path}>
                        <a style={{color:headerButtonDarkBgColour}} href={footer?.path} target="_blank">{footer?.name}</a>
                      </li>
                    )
                  })
                }
                </ul>
            </div>
          )}
        </div>
        )
      }
      </div>
    </footer>
  );
}
const mapDispatchToProps = dispatch => {
  return {
    setToken: (data) => dispatch(actions.setToken(data)),
    set2faActivationStatus: (data) => dispatch(actions.set2faActivationStatus(data)),
  }
};

export default connect(null, mapDispatchToProps)(B2CFooter);
