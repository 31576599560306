import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';
const resetIcon = "/common/crytoicons/reset_icon.png"
const dateImage = "/common/crytoicons/date.png"
const closeImage = "/common/crytoicons/close.png"
const selectedCheckBoxIcon = "/common/crytoicons/checkbox_filled.png"
const emptyCheckBoxIcon = "/common/crytoicons/checkbox_empty.png"

class FiltersModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAlertModal: false,
            showCurrencyDropdown: false,
            showFiatCurrencyDropdown: false,
            showStatusDropdown: false,
            settings: this.props.settings,
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
        }
    }
    componentDidMount = () => {
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
    }
    toggleAlertModal = (value) => {
        this.setState({ showAlertModal: value });
    }

    handleCallback = async (newValue) => {
        if (newValue[0] && newValue[1]) {
            this.setState({
                settings: {
                    ...this.state.settings,
                    fromDate: moment(newValue[0].$d).utc(true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    toDate: moment(newValue[1].$d).utc(true).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    dateSelected: true,
                }
            })
        }
    }


    toggleSelection = (symbol) => {
        if (this.state.settings.selectedCryptos.includes(symbol)) {
            // remove
            let newList = this.state.settings.selectedCryptos.filter(symbolObj => symbolObj !== symbol);
            this.setState({ settings: { ...this.state.settings, selectedCryptos: newList } });
        } else {
            // add
            let newList = this.state.settings.selectedCryptos;
            newList.push(symbol);
            this.setState({ settings: { ...this.state.settings, selectedCryptos: newList } });
        }
    }

    toggleFiatSelection = (symbol) => {
        if (this.state.settings.selectedFiatCurrencies.includes(symbol)) {
            // remove
            let newList = this.state.settings.selectedFiatCurrencies.filter(symbolObj => symbolObj !== symbol);
            this.setState({ settings: { ...this.state.settings, selectedFiatCurrencies: newList } });
        } else {
            // add
            let newList = this.state.settings.selectedFiatCurrencies;
            newList.push(symbol);
            this.setState({ settings: { ...this.state.settings, selectedFiatCurrencies: newList } });
        }
    }

    toggleStatusSelection = (symbol) => {
        if (this.state.settings.selectedStatusList.includes(symbol)) {
            // remove
            let newList = this.state.settings.selectedStatusList.filter(symbolObj => symbolObj !== symbol);
            this.setState({ settings: { ...this.state.settings, selectedStatusList: newList } });
        } else {
            // add
            let newList = this.state.settings.selectedStatusList;
            newList.push(symbol);
            this.setState({ settings: { ...this.state.settings, selectedStatusList: newList } });
        }
    }

    resetFiltersInternal = () => {
        this.setState({
            settings: {
            searchText: "",
            page: 1,
            transactions: [],
            orderBy: "desc",
            totalCount: 0,
            fromDate: moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            dateSelected: false,
            selectedCryptos: [],
            selectedFiatCurrencies: [],
            selectedStatusList: [],
            }
        })
    }
    
    render() {
        let headerButtonBorderStyle = getHeaderButtonBorderStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerButtonLightBgColour)
        let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)
        
        return (
            <div className="FiltersModalWrap">
                <Modal show={this.props.show} className="FilterModalContent"
                    onHide={() => { this.props.closeModal() }}>
                    <Modal.Body className="FilterModalBody"
                        style={{ backgroundColor: this.state.showAlertModal ? "#D8D8D8" : "#FFFFFF" }}>
                        <style>{`\
                            body {
                                width: 100vw;
                            }
                        `}</style>
                        <div className="FilterModalHeadingWrap">
                            <div data-e2e="clear" className="FilterModalClear" style={{ ...headerButtonBorderStyle }} onClick={() => {
                                this.toggleAlertModal(true)
                                this.setState({
                                    showCurrencyDropdown: false,
                                    showFiatCurrencyDropdown: false,
                                    showStatusDropdown: false,
                                })
                            }}
                            >
                                Clear
                            </div>
                            <div className="FilterModalTitle">Filters</div>
                            <div className="FilterModalCloseButtonWrap"
                                onClick={() => {
                                    this.props.closeModal();
                                }}
                            ><img src={`${this.props.image_base_url}${closeImage}`} data-e2e="closeFilter" alt="closeImage" className="FilterModalCloseButton" /></div>
                            {<Modal show={this.state.showAlertModal} className="ClearAlertModal"
                                onHide={() => { this.toggleAlertModal(false) }}>
                                <Modal.Body className="ClearAlertModalBody">
                                    <style>{`\
                                        body {
                                            width: 100vw;
                                        }
                                    `}</style>
                                    <div className="ClearAlertModalIconWrap">
                                        <img src={`${this.props.image_base_url}${resetIcon}`} alt="resetIcon" className="ClearAlertModalIcon" />
                                    </div>
                                    <div className="ClearAlertModalTitle">Are you sure?</div>
                                    <div className="ClearAlertModalDescription">Are you sure you want to reset applied filters? This action cannot be undone.</div>
                                    <div className="ClearAlertModalClearBtn"
                                        style={{ ...headerButtonStyle }}
                                        onClick={() => {
                                            this.toggleAlertModal(false)
                                            this.resetFiltersInternal()
                                            this.props.resetFilters()
                                            // this.props.closeModal()
                                        }} data-e2e="clear">Clear</div>
                                    <div className="ClearAlertModalGoBackBtn" onClick={() => { this.toggleAlertModal(false) }}>Go Back</div>
                                </Modal.Body>
                            </Modal>}
                        </div>

                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel">
                                Crypto Currency
                            </div>
                            <div className="FilterModalInputDropdown" onClick={() => {
                                this.setState({
                                    showCurrencyDropdown: true,
                                    showFiatCurrencyDropdown: false,
                                    showStatusDropdown: false,
                                })
                            }}>
                                <div className="FilterModalInputText">
                                    {this.state.settings.selectedCryptos.length === this.props.cryptos.length || this.state.settings.selectedCryptos.length === 0 ? "All" : `${this.state.settings.selectedCryptos.length} Selected`}
                                </div>
                                <i data-e2e="dropDown" className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>
                            {this.state.showCurrencyDropdown && <div className="FilterModalCurrencyDropdownlist">
                                <div className="FM_currencyDrop_All" onClick={() => {
                                    this.setState({ showCurrencyDropdown: false })
                                }}>
                                    <span className="FM_currencyDrop_AllSelector">{this.state.settings.selectedCryptos.length === this.props.cryptos.length || this.state.settings.selectedCryptos.length === 0
                                        ? "All" : `${this.state.settings.selectedCryptos.length} Selected`}</span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                    </span>
                                </div>
                                {this.props.cryptos.map((crypto, index) => {
                                    let { name, symbol, icon } = crypto;
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            this.toggleSelection(symbol)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <img src={`${this.props.image_base_url}${icon}`} alt={symbol} className="currencyList_Icon" />
                                                <div className="currencyList_Name" data-e2e={name}>
                                                    {name}
                                                </div>
                                            </div>
                                            <img src={`${this.props.image_base_url}${this.state.settings.selectedCryptos.includes(symbol) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                                <div className='filterModelSelectButtonContainer' >
                                    <div className='filterModelDeselectButton' style={{...headerButtonStyle}} onClick={() => this.setState({ settings: { ...this.state.settings, selectedCryptos: [] } })}>
                                        <Tooltip title="Deselect All">
                                            <div >
                                                <div className="DeSelectIcon">
                                                    <i class="fa fa-square-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className='filterModelSelectButton'
                                        onClick={() => {
                                            let newCryptos = [];
                                            this.props.cryptos.map((item) => {
                                                newCryptos.push(item.symbol);
                                            })
                                            this.setState({
                                                settings: {
                                                    ...this.state.settings, selectedCryptos: newCryptos
                                                }
                                            })
                                        }
                                        }
                                        style={{...headerButtonBorderStyle}}
                                    ><h1>Select All</h1></div>
                                </div>
                            </div>}
                        </div>

                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel">Date Range</div>
                            <div className="FilterModalInputDropdown" onClick={() => {
                                this.setState({
                                    showCurrencyDropdown: false,
                                    showFiatCurrencyDropdown: false,
                                    showStatusDropdown: false,
                                })
                            }}>
                                <img src={`${this.props.image_base_url}${dateImage}`} alt="dateImage" className="FilterModalInputDropdownDateImage" />
                                <div className="FilterModalInputDropdownDateTextWrap">
                                    <span className="cursor-pointer">{`${this.state.settings.dateSelected ? moment(this.state.settings.fromDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "Start Date"}`}</span>
                                    <span>{` to `}</span>
                                    <span className="cursor-pointer" style={{ marginRight: "15%" }}>{`${this.state.settings.dateSelected ? moment(this.state.settings.toDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "End Date"}`}</span>
                                </div>
                            </div>
                            <div className='b2cUserTransactionFilterDateRange'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateRangePicker
                                        startText="Check-in"
                                        endText="Check-out"
                                        value={[null, null]}
                                        onChange={(newValue) => {
                                            this.handleCallback(newValue);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <div className="datePickerInputClass" style={{
                                                display: "flex",
                                                marginTop: "38px",
                                                borderRadius: "20px",
                                                opacity: 0,
                                                height: "40px",
                                                overflow: "hidden",
                                            }}> <React.Fragment>
                                                    <TextField className="cursor-pointer" {...startProps} />
                                                    <Box sx={{ mx: 2 }}> to </Box>
                                                    <TextField className="cursor-pointer" {...endProps} />
                                                </React.Fragment>
                                            </div>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel">
                                Fiat Currency
                            </div>
                            <div className="FilterModalInputDropdown" onClick={() => {
                                this.setState({
                                    showFiatCurrencyDropdown: true,
                                    showCurrencyDropdown: false,
                                    showStatusDropdown: false,
                                })
                            }}>
                                <div className="FilterModalInputText">
                                    {this.state.settings.selectedFiatCurrencies.length === this.props.fiatCurrencies.length || this.state.settings.selectedFiatCurrencies.length === 0
                                        ? "All" : `${this.state.settings.selectedFiatCurrencies.length} Selected`}
                                </div>
                                <i data-e2e="dropDownFlatCurrency" className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>
                            {this.state.showFiatCurrencyDropdown && <div className="FilterModalCurrencyDropdownlist">
                                <div className="FM_currencyDrop_All" onClick={() => {
                                    this.setState({ showFiatCurrencyDropdown: false })
                                }}>
                                    <span className="FM_currencyDrop_AllSelector">{this.state.settings.selectedFiatCurrencies.length === this.props.fiatCurrencies.length || this.state.settings.selectedFiatCurrencies.length === 0
                                        ? "All" : `${this.state.settings.selectedFiatCurrencies.length} Selected`}</span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i className="fa fa-chevron-up FilterModalInputDropUpIcon" data-e2e="iconUp" />
                                    </span>
                                </div>
                                {this.props.fiatCurrencies.map((obj, index) => {
                                    let { name, symbol, icon } = obj;
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            this.toggleFiatSelection(symbol)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <img src={`${this.props.image_base_url}${icon}`} alt={symbol} className="currencyList_Icon" />
                                                <div data-e2e={name} className="currencyList_Name">
                                                    {name}
                                                </div>
                                            </div>
                                            <img src={`${this.props.image_base_url}${this.state.settings.selectedFiatCurrencies.includes(symbol) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" data-e2e="checkBox" />
                                        </div>
                                    )
                                })}
                                <div className='filterModelSelectButtonContainer' >
                                    <div className='filterModelDeselectButton' style={{...headerButtonStyle}} onClick={() => this.setState({ settings: { ...this.state.settings, selectedFiatCurrencies: [] } })}>
                                        <Tooltip title="Deselect All">
                                            <div >
                                                <div className="DeSelectIcon">
                                                    <i class="fa fa-square-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className='filterModelSelectButton'
                                        onClick={() => {
                                            let newCurrencies = []
                                            this.props.fiatCurrencies.map((currency) => {
                                                newCurrencies.push(currency.symbol)
                                            })
                                            this.setState({ settings: { ...this.state.settings, selectedFiatCurrencies: newCurrencies } })
                                        }}
                                        style={{...headerButtonBorderStyle}}
                                    ><h1>Select All</h1></div>
                                </div>
                            </div>}
                        </div> 

                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel">
                                Status
                            </div>
                            <div className="FilterModalInputDropdown" onClick={() => {
                                this.setState({
                                    showStatusDropdown: true,
                                    showCurrencyDropdown: false,
                                    showFiatCurrencyDropdown: false,
                                })
                            }}>
                                <div className="FilterModalInputText">
                                    {this.state.settings.selectedStatusList.length === this.props.statusList.length || this.state.settings.selectedStatusList.length === 0
                                        ? "All" : `${this.state.settings.selectedStatusList.length} Selected`}
                                </div>
                                <i data-e2e="statusDropDown" className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>
                            {this.state.showStatusDropdown && <div className="FilterModalCurrencyDropdownlist">
                                <div className="FM_currencyDrop_All" onClick={() => {
                                    this.setState({ showStatusDropdown: false })
                                }}>
                                    <span className="FM_currencyDrop_AllSelector">{this.state.settings.selectedStatusList.length === this.props.statusList.length || this.state.settings.selectedStatusList.length === 0
                                        ? "All" : `${this.state.settings.selectedStatusList.length} Selected`}</span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i data-e2e="iconUp" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                    </span>
                                </div>
                                {this.props.statusList.map((status, index) => {
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            this.toggleStatusSelection(status)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <div className="currencyList_Name" data-e2e={status}>
                                                    {status}
                                                </div>
                                            </div>
                                            <img src={`${this.props.image_base_url}${this.state.settings.selectedStatusList.includes(status) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" data-e2e="checkBox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                                <div className='filterModelSelectButtonContainer' >
                                    <div className='filterModelDeselectButton' style={{...headerButtonStyle}} onClick={() => this.setState({ settings: { ...this.state.settings, selectedStatusList: [] } })}>
                                        <Tooltip title="Deselect All">
                                            <div >
                                                <div className="DeSelectIcon">
                                                    <i class="fa fa-square-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className='filterModelSelectButton'
                                    style={{...headerButtonBorderStyle}}
                                        onClick={() => this.setState({ settings: { ...this.state.settings, selectedStatusList: this.props.statusList } })}
                                    ><h1>Select All</h1></div>
                                </div>
                            </div>}
                        </div>
                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel">
                                MerchantTransactionId
                            </div>
                            <input
                                className="FilterModalInputDropdown"
                                type="text"
                                name="MerchantTransactionId"
                                 value={this?.props?.merchantTransactionId}
                                placeholder="Merchant TransactionId"
                                 onChange={(e)=>{this.props.setMerchantTransactionId(e.target.value)}}

                            />
                            </div>



                        <div data-e2e="save" className="FilterModalSave"
                            style={{ ...headerButtonStyle }}
                            onClick={() => {
                                this.props.saveSettings(this.state.settings)
                                this.props.closeModal();
                            }}>
                            SAVE
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default FiltersModal;