import React, { useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useSelector } from "react-redux";
import queryString from 'query-string';
import TruevoForm from "./TruevoForm";
import TruevoSecondForm from "./TruevoSecondForm";
import jwt from 'jsonwebtoken';
import { apiService } from '../../../common/apiCallService';
import { constants } from '../../../common/constants';
import moment from 'moment';

let trustPaymentEventsOut = [];

const MerchantPayloading = (props) => {
   const [transactionId, setTransactionId] = useState("");
   const [routingId, setRoutingId] = useState("");
   const [isAxcessPayments, setIsAxcessPayments] = useState(false);
   const [isRefund, setIsRefund] = useState(false);
   const [brava3DSContent, setBrava3DSContent] = useState(false);
   const [preWorldCard3DSContent, setPreWorldCard3DSContent] = useState(false);
   const [worldCard3DSContent, setWorldCard3DSContent] = useState(false);
   const [altPayNetContent, setAltPayNetContent] = useState(null);
   const [isBravaPayments3DS, setIsBravaPayments3DS] = useState(false);
   const [isAltPayNet3DS, setIsAltPayNet3DS] = useState(false);
   const [isTruevo, setIsTruevo] = useState(false);
   const [truevoFlag, setTruevoFlag] = useState(false);
   const [truevoSubmit, setTruevoSubmit] = useState(false);
   const [secondTruevoFlag, setSecondTruevoFlag] = useState(false);
   const [truevoContent, setTruevoContent] = useState(false);
   const [axcessData, setAxcessData] = useState(null);
   const [acquirerName, setAcquirerName] = useState("");
   const [isSwitchFin, SetIsSwitchFin] = useState(false);
   const [switchFinContent,setSwitchFinContent] = useState(null);
   const TRUST_PAYMENTS_POST_URL = useSelector((state) => state.config.TRUST_PAYMENTS_POST_URL);
   const liveFlag = useSelector((state) => state.config.TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT);
   const axcess_hosted_url = useSelector((state) => state.config.axcess_hosted_url);
   const payment_api_url = useSelector((state) => state.config.payment_api_url);
   const eventsHiddenFieldRef = useRef(null);
   const [unicorn3DSContent, setUnicorn3DSContent] = useState(null);
   const [isUnicorn, setIsUnicorn] = useState(false);
   const [trustPayment, setTrustPayment] = useState(false)
   useEffect(() => {
      if (axcessData) {
         console.log("trying to submit form")
         document.getElementById("st-form-axcess").submit()
         console.log("after submit")
      }
   }, [axcessData])

   useEffect(() => {
      if (queryString.parse(props.location.search).refund) {
         setIsRefund(queryString.parse(props.location.search).refund)
      }
      const redirectUrlDetails = jwt.decode(queryString?.parse(props?.location?.search)?.redirectUrlToken, queryString?.parse(props?.location?.search)?.transactionid);
      setTransactionId(queryString?.parse(props?.location?.search)?.transactionid)

      if (redirectUrlDetails?.AxcessPayments) {
         setIsAxcessPayments(redirectUrlDetails?.AxcessPayments)
      } else if (redirectUrlDetails?.BravaPayments) {
         setIsBravaPayments3DS(true)
      } else if (redirectUrlDetails?.AltPayNet) {
         setIsAltPayNet3DS(true)
      }else if(redirectUrlDetails?.switchFin){
         SetIsSwitchFin(true)
      }
      else if(redirectUrlDetails?.Unicorn){
         setIsUnicorn(true)
      }
      getTransaction(queryString?.parse(props?.location?.search)?.transactionid, redirectUrlDetails);
      if (redirectUrlDetails?.routingId) {
         setRoutingId(redirectUrlDetails?.routingId)
      }
      if (redirectUrlDetails?.acquirerName) {
         setAcquirerName(redirectUrlDetails?.acquirerName)
      }
      if (redirectUrlDetails?.TrustPayment) {
         setTrustPayment(true)
         window.onbeforeunload = () => {
            saveTrustSingleEvent({
               transactionId: queryString.parse(props.location.search).transactionid,
               eventName: "UIPageClosed",
               eventData: { time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            });
            return null;
         };
         window.onunload = () => {
            saveTrustSingleEvent({
               transactionId: queryString.parse(props.location.search).transactionid,
               eventName: "UIPageClosedOnUnload",
               eventData: { time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
            });
            return null;
         };
      }
      let saveTrustPaymentEventApiCallInterval = setInterval(async()=>{
         if(trustPaymentEventsOut.length > 0){
            await saveTrustPaymentEvents(trustPaymentEventsOut);
            trustPaymentEventsOut=[];
         }
      },2*60*1000)
      return ()=>{
         clearInterval(saveTrustPaymentEventApiCallInterval);
      }
   }, [])

   const getTransaction = async (transId, redirectUrlDetails) => {
      const merchantId = redirectUrlDetails?.merchantid
      await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/GetCardTransactionJWT', {
         transactionId: transId,
         merchantId: merchantId
      }, async (data) => {
         if (data) {
            if (data.payJwtToken === "cleared") {
               alert("Link is not valid! transaction already processed.")
            } else {
               if (data?.integrationType === constants.FINTECH_INTEGRATION_TYPE) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     if (decoded) {
                        if (decoded?.fintechCardResponse?.acquirerRedirect) {
                           const popupWindow = window.open(decoded?.fintechCardResponse?.acquirerRedirect, '_blank')
                           try {
                              popupWindow.focus();
                              props.navigate("/checkCardPayTransactionStatus?transactionId=" + transId + "&merchantId=" + merchantId)
                           } catch (e) {
                              alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                           }
                        } else if (decoded?.fintechCardResponse?.redirectUrl) {
                           props.navigate(decoded?.fintechCardResponse?.redirectUrl)
                        }
                     } else {
                        alert("Fintech-Jwt-token has invalid data.")
                     }
                  } else {
                     alert("Fintech-Jwt-token missing in transaction.")
                  }
               } else if (data?.integrationType === constants.GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     if (decoded) {
                        if (decoded?.goldenGateResponse?.acquirerRedirectUrl) {
                           window.location.href = decoded?.goldenGateResponse?.acquirerRedirectUrl
                        } else if (decoded?.goldenGateResponse?.redirectUrl) {
                           props.navigate(decoded?.goldenGateResponse?.redirectUrl)
                        }
                     } else {
                        alert("GoldenGate-Jwt-token has invalid data.")
                     }
                  } else {
                     alert("GoldenGate-Jwt-token missing in transaction.")
                  }
               } else if (data?.integrationType === constants.WORLD_CARD_INTEGRATION_TYPE) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     if (decoded) {
                        if (decoded?.worldCardResponse?.redirect) {
                           if (decoded?.worldCardResponse?.redirect?.preconditions && decoded?.worldCardResponse?.redirect?.preconditions?.length > 0) {
                              setPreWorldCard3DSContent(decoded?.worldCardResponse?.redirect?.preconditions[0])
                           }
                           setWorldCard3DSContent(decoded?.worldCardResponse)
                           setTimeout(() => {
                              if (decoded?.worldCardResponse?.redirect?.preconditions && decoded?.worldCardResponse?.redirect?.preconditions?.length > 0) {
                                 document.getElementById('st-form-pre-world-card').submit();
                                 console.log("worldCard pre API called")
                              }
                              document.getElementById('st-form-world-card').submit();
                              console.log("worldCard payment API called")
                           }, 500)
                        } else if (decoded?.worldCardResponse?.redirectUrl) {
                           props.navigate(decoded?.worldCardResponse?.redirectUrl)
                        }
                     } else {
                        alert("WorldCard-Jwt-token has invalid data.")
                     }
                  } else {
                     alert("WorldCard-Jwt-token missing in transaction.")
                  }
               } else if (data?.integrationType === constants.BAERS_CREST_INTEGRATION_TYPE) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     if (decoded) {
                        if (decoded?.baersCrestResponse?.acquirerRedirect) {
                           window.location.href = decoded?.baersCrestResponse?.acquirerRedirect
                        } else if (decoded?.baersCrestResponse?.redirectUrl) {
                           props.navigate(decoded?.baersCrestResponse?.redirectUrl)
                        }
                     } else {
                        alert("BaersCrest-Jwt-token has invalid data.")
                     }
                  } else {
                     alert("BaersCrest-Jwt-token missing in transaction.")
                  }
               } else if(data.integrationType === constants.SWITCH_FIN_INTEGRATION_TYPE){
                  if(data.payJwtToken && data.payJwtToken !== ""){
                     let decoded = jwt.decode(data.payJwtToken)
                     if(decoded && decoded.switchFin){
                        delete decoded.iat
                        decoded = Object.entries(decoded.switchFin).map(([key, value]) => ({ name: key, value }))
                        const redirectUrl = `/SecurePayment?transactionid=${transId}&redirectUrlToken=${queryString?.parse(props?.location?.search)?.redirectUrlToken}`;
                        if (window.self !== window.top) {
                            window.parent.location.href = redirectUrl; //Need to move out of i frame
                        }else{
                           setSwitchFinContent(decoded);
                           setTimeout( () => {
                              document.getElementById('st-form-switch-fin').submit();
                              props.navigate("/checkCardPayTransactionStatus?transactionId=" + transId + "&merchantId=" + merchantId)
                           }, 500);
                        }
                     }
                  }else{
                     alert("Switch Fin-Jwt-token missing in transaction.")
                  }
               }else if (redirectUrlDetails?.AxcessPayments) {
                  if (data.payJwtToken && data.payJwtToken !== "") {
                     let decoded = jwt.decode(data.payJwtToken)
                     delete decoded.iat
                     setAxcessData(decoded)
                  } else {
                     alert("Axcess-Jwt-token missing in transaction.")
                  }
               }else if (data?.integrationType === constants.PAYMENT_EMPIRE_INTEGRATION_TYPE) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     if (decoded) {
                        if (decoded?.paymentEmpireResponse?.acquirerRedirect) {
                           window.top.location.href = decoded?.paymentEmpireResponse?.acquirerRedirect
                        } else if (decoded?.paymentEmpireResponse?.redirectUrl) {
                           props.navigate(decoded?.paymentEmpireResponse?.redirectUrl)
                        }
                     } else {
                        alert("PaymentEmpire-Jwt-token has invalid data.")
                     }
                  } else {
                     alert("PaymentEmpire-Jwt-token missing in transaction.")
                  }
               } else if (data?.integrationType === constants.NEVO4_INTEGRATION_TYPE) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     if (decoded) {
                        if (decoded?.nevo4Response?.acquirerRedirect) {
                           window.top.location.href = decoded?.nevo4Response?.acquirerRedirect
                        } else if (decoded?.nevo4Response?.redirectUrl) {
                           props.navigate(decoded?.nevo4Response?.redirectUrl)
                        }
                     } else {
                        alert("Nevo4-Jwt-token has invalid data.")
                     }
                  } else {
                     alert("Nevo4-Jwt-token missing in transaction.")
                  }
               } else if (redirectUrlDetails?.AxcessPayments) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     delete decoded.iat
                     setAxcessData(decoded)
                  } else {
                     alert("Axcess-Jwt-token missing in transaction.")
                  }
               } else if (redirectUrlDetails?.BravaPayments) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     delete decoded.iat
                     if (decoded?.bravaPayResponse?.htmlRedirect) {
                        setBrava3DSContent(decoded?.bravaPayResponse?.htmlRedirect)
                        setTimeout(() => {
                           document.getElementById('st-form-brava').submit();
                           console.log("Brava pay API called")
                        }, 500)
                     } else if (decoded?.bravaPayResponse?.redirectUrl) {
                        props.navigate(decoded?.bravaPayResponse?.redirectUrl)
                     } else if (decoded?.bravaPayResponse?.acquirerRedirect) {
                        window.location.href = decoded?.bravaPayResponse?.acquirerRedirect
                     }
                  } else {
                     alert("Axcess-Jwt-token missing in transaction.")
                  }
               } else if (redirectUrlDetails?.AltPayNet) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     delete decoded.iat
                     if (decoded?.altPayNetResponse?.redirect_url_3ds) {
                        setAltPayNetContent(decoded?.altPayNetResponse)
                     } else if (decoded?.altPayNetResponse?.redirectUrl) {
                        props.navigate(decoded?.altPayNetResponse?.redirectUrl)
                     }
                  } else {
                     alert("Axcess-Jwt-token missing in transaction.")
                  }
               } else if (redirectUrlDetails?.PayDotCom) {
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     delete decoded.iat
                     if (decoded?.payDotComResponse?.acquirerRedirect) {
                        window.location.href = decoded?.payDotComResponse?.acquirerRedirect
                     }
                  } else {
                     alert("Pay.com jwt token missing in transaction.")
                  }
               }else if (data?.integrationType === constants.TRUEVO_INTEGRATION_TYPE) {
                  setIsTruevo(true);
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     delete decoded.iat
                     if (decoded?.truevoResponse?.redirectUrl) {
                        props.navigate(decoded?.truevoResponse?.redirectUrl)
                     }else{
                        getTruevoStatus();
                     }
                  } else {
                     alert("Truevo jwt token missing in transaction.")
                  }
               } else if (data?.integrationType === constants.UNICORN_INTEGRATION_TYPE) {
                  setIsUnicorn(true);
                  if (data?.payJwtToken) {
                     let decoded = jwt.decode(data?.payJwtToken)
                     delete decoded?.iat
                     if(decoded?.unicornCardResponse?.transactionFailed && !decoded?.unicornCardResponse?.acsUrl){
                        if (window.self !== window.top) {
                           window.parent.location.href = redirectUrl; //Need to move out of i frame
                        } else {
                           window.location.href = decoded?.unicornCardResponse?.redirectUrl
                        }
                     }
                     else if(decoded?.unicornCardResponse?.acsUrl){
                        setUnicorn3DSContent(decoded?.unicornCardResponse);
                        setTimeout(() => {
                           document.getElementById('st-form-unicorn-secure').submit();
                        }, 20000);
                     }
                     else{
                        navigate("/checkCardPayTransactionStatus?transactionId=" + decoded?.unicornCardResponse?.transactionId + "&merchantId=" + decoded?.unicornCardResponse?.merchantId)
                     }
                  } else {
                     alert("Unicorn jwt token missing in transaction.")
                  }
               } else {
                  creditDeposit(data?.payJwtToken);
               }
            }
         }
      }, (err) => {
         console.error("error", err)
      })
   }

   const getTruevoStatus = async () => {
      try {
         let query = {
            transactionId: queryString.parse(props.location.search).transactionid,
         }
         await apiService("/pga/getTruevoStatus", query, (data) => {
            if (data?.outcome?.transactionStatus !== "PENDING") {
               return
            } else if ((data?.outcome?.action?.actionRequired === "IdentifyCardholder") && !truevoSubmit) {
               if (data?.outcome?.action?.additionalData?.length > 0) {
                  setTruevoContent(data.outcome.action.additionalData);
               }
               setTruevoFlag(true);
               return;
            } else if (data?.outcome?.action?.actionRequired === "Challenge") {
               if (data?.outcome?.action?.additionalData?.length > 0) {
                  setTruevoContent(data.outcome.action.additionalData);
               }
               setSecondTruevoFlag(true);
               return;
            } else {
               getTruevoStatus();
            }
         }, (err) => {
            console.error(err);
         })
      } catch (error) {
         console.error('error getTruevoStatus ', error);
      }
   }

   const creditDeposit = (token) => {
      let st = SecureTrading({
         jwt: token,
         submitOnError: true,
         formId: "st-form",
         livestatus: parseInt(liveFlag ? liveFlag : 0)
      })
      saveTrustSingleEvent({
         transactionId: queryString.parse(props.location.search).transactionid,
         eventName: "UIPageLoaded",
         eventData: { time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
      });
      st.Components({ startOnLoad: true });
      st.on("paymentInitStarted", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentInitStarted",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentInitStarted", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentInitCompleted", (data) => {
         const newDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
         setTimeout(() => {
            saveTrustSingleEvent({
               transactionId: queryString.parse(props.location.search).transactionid,
               eventName: "paymentInitCompleted",
               eventData: { ...data, time: newDate },
            });
            trustPaymentEventsOut.push({ eventName: "paymentInitCompleted", time: newDate });
            eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
         }, 150)
      })
      st.on("paymentInitFailed", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentInitFailed",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentInitFailed", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentStarted", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentStarted",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentStarted", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentPreCheck", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentPreCheck",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentPreCheck", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentCompleted", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentCompleted",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentCompleted", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentFailed", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentFailed",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentFailed", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
      st.on("paymentCanceled", (data) => {
         saveTrustSingleEvent({
            transactionId: queryString.parse(props.location.search).transactionid,
            eventName: "paymentCanceled",
            eventData: { ...data, time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
         });
         trustPaymentEventsOut.push({ eventName: "paymentCanceled", time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
         eventsHiddenFieldRef.current.value = JSON.stringify(trustPaymentEventsOut);
      })
   }

   const saveTrustSingleEvent = (dataObj) => {
      const stForm = document.getElementById("st-form");
      const transactionReference = stForm?.elements["transactionreference"]?.value
      if (transactionReference) {
         dataObj.eventData.transactionReference = transactionReference
      }
      apiService("/restapi/save-trust-payment-event", dataObj, (data) => {
         console.log("event saved for trust:", data)
      }, (err) => {
         console.error(err);
      })
   }

   const saveTrustPaymentEvents = async(eventNames)=>{
      try {
         await apiService("/restapi/save-card-3ds-event",{
            transactionId: queryString.parse(props.location.search).transactionid,
            eventNames
         },(data) => {
            console.log("events saved for trust:", data)
         }, (err) => {
            console.error(err);
         })
      } catch (error) {
         console.error('error inside MerchantPayloading ',error);
      }
   }
   const SWITCH_FIN_API_URL = useSelector((state)=>state.config.SWITCH_FIN_API_URL)
   console.log("process.env.SWITCH_FIN_API_URL", SWITCH_FIN_API_URL)
   return (
      <div >
        {!isTruevo && <div className='loading_pay_main row' >
            <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
         </div>}

         <div id="st-notification-frame"></div>
         {isAxcessPayments ? <form id="st-form-axcess" action={axcess_hosted_url + (isRefund ? "Refund" : "")} method="POST"
            novalidate="novalidate"
            enctype="application/x-www-form-urlencoded"
            data-hostedform-tokenize='{"#form-customer-name": "customerName"}'
         >
            {axcessData && Object.keys(axcessData).map((field, i) => {
               return (<input key={i} type="hidden" name={`${field}`} value={axcessData[field]}></input>)
            })}
         </form> :
            trustPayment && <form id="st-form" action={window.location.origin + "/pga/trustCallback"} method="POST">
               {routingId && <input type="hidden" name="routingId" value={routingId} />}
               {acquirerName && <input type="hidden" name="acquirerName" value={acquirerName} />}
               {transactionId && <input type="hidden" name="transactionId" value={transactionId ? transactionId : ""} />}
               <input type="hidden" name="events" ref={eventsHiddenFieldRef}/>
            </form>}
         {isBravaPayments3DS && brava3DSContent &&
            <form id="st-form-brava" action={brava3DSContent.redirect_url} method={brava3DSContent.redirect_method}>
               {brava3DSContent.redirect_params && Object.keys(brava3DSContent.redirect_params).length > 0 &&
                  Object.keys(brava3DSContent.redirect_params).map((key) => {
                     return <input type="hidden" name={key} value={brava3DSContent.redirect_params[key]} />
                  })}
            </form>}
         {preWorldCard3DSContent && preWorldCard3DSContent.url &&
            <form id="st-form-pre-world-card" action={preWorldCard3DSContent.url} method={preWorldCard3DSContent.method ? preWorldCard3DSContent.method : "POST"}>
               {preWorldCard3DSContent.parameters && preWorldCard3DSContent.parameters.length > 0 &&
                  worldCard3DSContent.parameters.map((param) => {
                     return <input type="hidden" name={param.name} value={param.value} />
                  })}
            </form>}
         {worldCard3DSContent && worldCard3DSContent.redirect &&
            <form id="st-form-world-card" action={worldCard3DSContent.redirect.url} method={worldCard3DSContent.redirect.method ? worldCard3DSContent.redirect.method : "POST"}>
               {worldCard3DSContent.redirect.parameters && worldCard3DSContent.redirect.parameters.length > 0 &&
                  worldCard3DSContent.redirect.parameters.map((param) => {
                     return <input type="hidden" name={param.name} value={param.value} />
                  })}
            </form>}
            {isSwitchFin && switchFinContent &&
            <form id="st-form-switch-fin" action={SWITCH_FIN_API_URL} method='POST' target="_blank">
               {switchFinContent?.length > 0 &&
                  switchFinContent.map((param) => {
                     return <input type="hidden" name={param?.name} value={param?.value} />
                  })
               }
            </form>
         }
         {isUnicorn && unicorn3DSContent && unicorn3DSContent?.acsUrl &&
            <form id="st-form-unicorn-secure" action={unicorn3DSContent?.acsUrl} method="post" >
               <input name="transactionId" type="hidden" value={unicorn3DSContent?.acquirerTransactionReferenceId} /><br />
               <input name="AuthType" type="hidden" value={unicorn3DSContent?.authType} />
               <input name="TermUrl" type="hidden" value={`${window?.location?.origin}/pga/unicornCallback`} />
            </form>
         }
         {isAltPayNet3DS && altPayNetContent &&
            <iframe src={altPayNetContent.redirect_url_3ds} width="500" height="600" style={iframeStyle} />
         }
          {truevoFlag && <TruevoForm
                truevoContent={truevoContent}
                handleTruevoFlag={() => {
                    setTruevoSubmit(true);
                    setTruevoFlag(false);
                    setTimeout(() => {
                        getTruevoStatus()
                    }, 2000);
                    return
                }
                }
            />}
            {secondTruevoFlag && <TruevoSecondForm
                truevoContent={truevoContent}
                handleTruevoFlag={() => {
                    setTimeout(() => {
                        getTruevoStatus()
                    }, 500);
                    return
                }
                }
            />}
      </div>
   );
}

export default MerchantPayloading;