import axios from 'axios';

export const getDeviceIP = () => new Promise(async (resolve, reject) => {
  let ipAddress = null
  await axios.get("https://ipinfo.io").then((response) => {
    ipAddress = response.data.ip;
  }).catch((err) => {
    console.error(err);
  })
  if (!ipAddress) {
    await axios.get('https://api.ipify.org?format=json')
      .then(function (response) {
        resolve(response.data.ip)
      })
      .catch(function (error) {
        resolve(null)
      });
  } else {
    resolve(ipAddress)
  }
})


export const removeHttp = (url) => {
  if (url.startsWith('https.')) {
    const https = 'https.';
    return url.slice(https.length);
  }
  if (url.startsWith('http.')) {
    const https = 'http.';
    return url.slice(https.length);
  }
  if (url.startsWith('https://')) {
    const https = 'https://';
    return url.slice(https.length);
  }
  if (url.startsWith('http://')) {
    const https = 'http://';
    return url.slice(https.length);
  }
  if (url.startsWith('www.')) {
    const http = 'www.';
    return url.slice(http.length);
  }

  return url;
}

export const clearStorageData = () => {
  let localDetails = {
    domainNameUrl: localStorage.getItem("domainNameUrl"),
    quickdeposit: localStorage.getItem("quickdeposit"),
    depositMethod: localStorage.getItem("depositMethod"),
    depositMerchantId: localStorage.getItem("depositMerchantId"),
    transactionId: localStorage.getItem("transactionId"),
    activity_tracking_token: localStorage.getItem("activity_tracking_token"),
    depositMerchantName: localStorage.getItem("depositMerchantName"),
    redirectUrl: localStorage.getItem("redirectUrl"),
    paymentProviderId: sessionStorage.getItem("paymentProviderId"),
    domainId: sessionStorage.getItem("domainId"),
    paymentProviderName: sessionStorage.getItem("paymentProviderName"),
    font: sessionStorage.getItem("font"),
    colorMode: sessionStorage.getItem("colorMode"),
    b2cColorMode: sessionStorage.getItem("b2cColorMode"),
    defaultTheme: sessionStorage.getItem("defaultTheme"),
    defaultB2CTheme: sessionStorage.getItem("defaultB2CTheme"),
    headerLightBgColour: sessionStorage.getItem("headerLightBgColour"),
    headerDarkBgColour: sessionStorage.getItem("headerDarkBgColour"),
    headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour"),
    headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour"),
    headerLightTextColour: sessionStorage.getItem("headerLightTextColour"),
    headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour"),
    headerLightLinkColour: sessionStorage.getItem("headerLightLinkColour"),
    headerDarkLinkColour: sessionStorage.getItem("headerDarkLinkColour"),
    themeToggleButton: sessionStorage.getItem("themeToggleButton"),
    bodyLightTextColour: sessionStorage.getItem("bodyLightTextColour"),
    bodyDarkTextColour: sessionStorage.getItem("bodyDarkTextColour"),
    logoWidth: sessionStorage.getItem("logoWidth"),
    manageCurrencies: localStorage.getItem("manageCurrencies")
  }
  localStorage.clear()
  if (localDetails.manageCurrencies) {
    localStorage.setItem("manageCurrencies", localDetails.manageCurrencies)
  }
  if (localDetails.domainNameUrl) {
    localStorage.setItem("domainNameUrl", localDetails.domainNameUrl)
  }
  if (localDetails.quickdeposit) {
    localStorage.setItem("quickdeposit", localDetails.quickdeposit)
  }
  if (localDetails.depositMethod) {
    localStorage.setItem("depositMethod", localDetails.depositMethod)
  }
  if (localDetails.depositMerchantId) {
    localStorage.setItem("depositMerchantId", localDetails.depositMerchantId)
  }
  if (localDetails.transactionId) {
    localStorage.setItem("transactionId", localDetails.transactionId)
  }
  if (localDetails.activity_tracking_token) {
    localStorage.setItem("activity_tracking_token", localDetails.activity_tracking_token)
  }
  if (localDetails.depositMerchantName) {
    localStorage.setItem("depositMerchantName", localDetails.depositMerchantName)
  }
  if (localDetails.redirectUrl) {
    localStorage.setItem("redirectUrl", localDetails.redirectUrl)
  }
  if (localDetails.paymentProviderId) {
    sessionStorage.setItem("paymentProviderId", localDetails.paymentProviderId)
  }
  if (localDetails.domainId) {
    sessionStorage.setItem("domainId", localDetails.domainId)
  }
  if (localDetails.paymentProviderName) {
    sessionStorage.setItem("paymentProviderName", localDetails.paymentProviderName)
  }
  if (localDetails.font) {
    sessionStorage.setItem("font", localDetails.font)
  }
  if (localDetails.colorMode) {
    sessionStorage.setItem("colorMode", localDetails.colorMode)
  }
  if (localDetails.b2cColorMode) {
    sessionStorage.setItem("b2cColorMode", localDetails.b2cColorMode)
  }
  if (localDetails.defaultTheme) {
    sessionStorage.setItem("defaultTheme", localDetails.defaultTheme)
  }
  if (localDetails.defaultB2CTheme) {
    sessionStorage.setItem("defaultB2CTheme", localDetails.defaultB2CTheme)
  }
  if (localDetails.headerLightBgColour) {
    sessionStorage.setItem("headerLightBgColour", localDetails.headerLightBgColour)
  }
  if (localDetails.headerDarkBgColour) {
    sessionStorage.setItem("headerDarkBgColour", localDetails.headerDarkBgColour)
  }
  if (localDetails.headerButtonLightBgColour) {
    sessionStorage.setItem("headerButtonLightBgColour", localDetails.headerButtonLightBgColour)
  }
  if (localDetails.headerButtonDarkBgColour) {
    sessionStorage.setItem("headerButtonDarkBgColour", localDetails.headerButtonDarkBgColour)
  }
  if (localDetails.headerLightTextColour) {
    sessionStorage.setItem("headerLightTextColour", localDetails.headerLightTextColour)
  }
  if (localDetails.headerDarkTextColour) {
    sessionStorage.setItem("headerDarkTextColour", localDetails.headerDarkTextColour)
  }
  if (localDetails.headerLightLinkColour) {
    sessionStorage.setItem("headerLightLinkColour", localDetails.headerLightLinkColour)
  }
  if (localDetails.headerDarkLinkColour) {
    sessionStorage.setItem("headerDarkLinkColour", localDetails.headerDarkLinkColour)
  }
  if (localDetails.themeToggleButton) {
    sessionStorage.setItem("themeToggleButton", localDetails.themeToggleButton)
  }
  if (localDetails.bodyLightTextColour) {
    sessionStorage.setItem("bodyLightTextColour", localDetails.bodyLightTextColour)
  }
  if (localDetails.bodyDarkTextColour) {
    sessionStorage.setItem("bodyDarkTextColour", localDetails.bodyDarkTextColour)
  }
  if (localDetails.logoWidth) {
    sessionStorage.setItem("logoWidth", localDetails.logoWidth)
  }
}