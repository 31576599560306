import React, { useEffect, useState } from "react";
import localDb from "../../../../common/localDb";
import { useSelector } from "react-redux";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { checkValueInSS } from "../../../../common/theme";

export default function PsB2cGetStarted(props) {
	const image_base_url = useSelector((state) => state.config.image_base_url);
	const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

	useEffect(() => {
		if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
	}, [])

	let headerButtonStyle = {}
	if (b2cColorMode === "dark") {
		if (headerButtonDarkBgColour && headerButtonDarkBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonDarkBgColour;
			headerButtonStyle.background = headerButtonDarkBgColour;
			headerButtonStyle.borderColor = headerButtonDarkBgColour;
		}
		if (headerDarkTextColour && headerDarkTextColour !== "") {
			headerButtonStyle.color = headerDarkTextColour;
		}
	} else {
		if (headerButtonLightBgColour && headerButtonLightBgColour !== "") {
			headerButtonStyle.backgroundColor = headerButtonLightBgColour;
			headerButtonStyle.background = headerButtonLightBgColour;
			headerButtonStyle.borderColor = headerButtonLightBgColour;
		}
		if (headerLightTextColour && headerLightTextColour !== "") {
			headerButtonStyle.color = headerLightTextColour;
		}
	}

	return (
		<section className="section psB2cGetStart">
			<div className="content-container">
				<AnimationOnScroll animateIn="animate__fadeInDown">
					<div className="section-upperTitle">
						<p>Easy Setup</p>
					</div>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn="animate__fadeInUp">
					<p className="section-title mb">Get started in minutes</p>
				</AnimationOnScroll>
				<div className="paysu-steps">
					<div className="paysu-steps-block">
						<p className="paysu-steps-num">1.</p>
						<div className="paysu-steps-icon has-before-el">
							<img width="96" height="96" loading="lazy" src={`${image_base_url}/paysu/get-started-exam.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Sign up</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Register online and get started with access to our multi-currency crypto wallet.</p>
						</AnimationOnScroll>
					</div>
					<div className="paysu-steps-block">
						<p className="paysu-steps-num">2.</p>
						<div className="paysu-steps-icon has-before-el">
							<img width="96" height="96" loading="lazy" src={`${image_base_url}/paysu/get-started-crypto-wallet.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Verify account</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Verify your identity in minutes using our automated verification system and get instant approval.</p>
						</AnimationOnScroll>
					</div>
					<div className="paysu-steps-block">
						<p className="paysu-steps-num">3.</p>
						<div className="paysu-steps-icon">
							<img width="96" height="96" loading="lazy" src={`${image_base_url}/paysu/get-started-kyc.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Start buying & selling</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Buy crypto with your card, send to friends and pay merchants.</p>
						</AnimationOnScroll>
					</div>
				</div>
				<div className="theme-btn-block center">
					{
						!localDb.getVal("token") && <button className="paysu-btn" onClick={() => {
							props.handleClickOpenSignUpModel();
						}} style={{ ...headerButtonStyle }}>Get started</button>
					}
				</div>
			</div>
		</section>
	)
}