import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function PsB2cWhyChoose() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  
  const [paymentProviderName, setPaymentProviderName] = useState(null)
  useEffect(()=>{
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
    }
  }, []);
  
  return (
    <section className="section psB2cChoose">
     	<div className="content-container"> 
			 <AnimationOnScroll animateIn="animate__fadeInDown">
					<div className="section-upperTitle">
						<p>About us</p>
					</div>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn="animate__fadeInUp">
					<p className="section-title mb">Why choose {paymentProviderName}?</p>
				</AnimationOnScroll>
				<div className="paysu-steps">
					<div className="paysu-steps-block">
						<div className="paysu-steps-icon has-before-el">
							<img width="96" height="96" src={`${image_base_url}/paysu/why-choose-cryptocurrency.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Buy your crypto currencies</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Buy any cryptocurrency with ease and speed, our systems deliver any currency to your wallet now.</p>
						</AnimationOnScroll>
					</div>
					<div className="paysu-steps-block">
						<div className="paysu-steps-icon has-before-el">
							<img width="96" height="96" loading="lazy" src={`${image_base_url}/paysu/why-choose-card.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Pay by card</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Use any bank card pay for your crypto purchase, our rates are exceptional when you use your debit credit card with PaySu.</p>
						</AnimationOnScroll>
					</div>
					<div className="paysu-steps-block">
						<div className="paysu-steps-icon">
							<img width="96" height="96" loading="lazy" src={`${image_base_url}/paysu/why-choose-shopping.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Pay Merchants</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Once you have bought your cryptocurrencies, you can now make purchases at merchants with industry leading low fees!</p>
						</AnimationOnScroll>
					</div>
				</div>
		 	</div>
    </section>
  )
}
