import $ from 'jquery';
import axios from 'axios';
import { clearStorageData } from './getIP';
import localDb from './localDb';

export async function apiService(url, request, successCall, errorCall, pipe = false) {
  request.token = request.token && request.token !== '' ? request.token : localStorage.getItem('token');
  if (pipe) {
    if (localStorage.getItem('user_name')) {
      const emailDomain = localStorage.getItem('user_name');
      if (emailDomain.includes('|')) {
        request.email = emailDomain.split('|')[0];
      } else {
        request.email = localStorage.getItem('user_name');
      }
    }
  } else {
    if (localStorage.getItem('user_name')) {
      request.email = localStorage.getItem('user_name');
    }
  }
  if (localStorage.getItem('domainNameUrl')) {
    let domainNameUrl = localStorage.getItem('domainNameUrl');
    if (window.location.hostname !== 'localhost' && domainNameUrl !== 'null') {
      url =
        (localStorage.getItem('domainNameUrl').includes('https://')
          ? localStorage.getItem('domainNameUrl')
          : 'https://' + localStorage.getItem('domainNameUrl')) + url;
    }
  }
  $.ajax({
    url: url,
    type: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'api-key': 'CorrectSecretKey', //TODO: get rid of hardcoded key
    },
    data: request,
    success: (data) => {
      successCall(data);
    },
    error: (err) => {
      if (err.status === 0) {
        console.log('Payment server down! please try again later.');
        // alert("Payment server down! please try again later.")
      } else if (err.status === 403 || err.status === 401) {
        const loginType = localDb?.getVal('loginType');
        if (['merchant', 'Merchant']?.includes(loginType)){
          clearStorageData();
          window.location.href = '/home-merchant'
        } else if(loginType === "B2C") {
          window.location.href = '/home-b2c'
        }
        console.log('Authentication failed!');
        if (!url.indexOf('sign-up')) {
          window.location.replace('/');
        }
      }
      console.log('Not Able To Call APi.....', err);
      errorCall(err);
    },
  });
}

export function axiosCallService(url, payload = {}) {
    return new Promise((resolve, reject) => {
        let postData = {
            "token": typeof window != 'undefined' ? localStorage.getItem("token") : null,
            ...payload
        }

        let domainNameUrl = localStorage.getItem("domainNameUrl");
        if (window.location.hostname !== "localhost" && domainNameUrl !== "null") {
            url = (localStorage.getItem("domainNameUrl").includes("https://") ? localStorage.getItem("domainNameUrl") : "https://" + localStorage.getItem("domainNameUrl"))  + url;
        }
        axios({
            method: 'POST',
            url: url,
            data: postData
        }).then(resolve)
            .catch(({ response }) => {
                if (response.status == 403) {
                    clearStorageData()
                    window.location.replace('/login')
                }
                console.log('Not Able To Call APi.....', response);
                reject();
            })
    })
}