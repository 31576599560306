import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";

export default function PaysuCompletion() {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section grey-background">
			<div className="content-container">
				<div className="ps-section-grid">
					<div>
						<div className="ps-section-title-line"></div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="ps-section-title mb">Payments Completion.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text">Our payment completion services allow you to take advantage of our suite of globally available payment solutions.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							<div className="ps-section-list">
								<div className="ps-section-list-li">
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.00143 10.0015L0.00120992 5.00128L5.00143 0.00105757L10.0017 5.00128L5.00143 10.0015Z" fill="#9F1F63"/></svg>
									<p className="ps-section-text">Online PCI compliant card payments.</p>
								</div>
								<div className="ps-section-list-li">
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.00143 10.0015L0.00120992 5.00128L5.00143 0.00105757L10.0017 5.00128L5.00143 10.0015Z" fill="#9F1F63"/></svg>
									<p className="ps-section-text">QR code POS support.</p>
								</div>
								<div className="ps-section-list-li">
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.00143 10.0015L0.00120992 5.00128L5.00143 0.00105757L10.0017 5.00128L5.00143 10.0015Z" fill="#9F1F63"/></svg>
									<p className="ps-section-text">Wallet to wallet for existing crypto users.</p>
								</div>
								<div className="ps-section-list-li">
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.00143 10.0015L0.00120992 5.00128L5.00143 0.00105757L10.0017 5.00128L5.00143 10.0015Z" fill="#9F1F63"/></svg>
									<p className="ps-section-text">Guaranteed protection from price fluctuation using our exchange.</p>
								</div>
								<div className="ps-section-list-li">
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.00143 10.0015L0.00120992 5.00128L5.00143 0.00105757L10.0017 5.00128L5.00143 10.0015Z" fill="#9F1F63"/></svg>
									<p className="ps-section-text">Exceptionally quick settlements in your preferred currency.</p>
								</div>
							</div>
						</AnimationOnScroll>
						<AnimationOnScroll delay="600" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">These services encompass the sale of your products via crypto or local ‘fiat’ currency i.e., €, £ etc. or, talk to us about completely outsourcing both your fiat and crypto checkout, within our payments completion hub, we provide complete FX and settlement services, the ‘Hub’ is fully licensed end to end.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="800" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">You simply show your customers the price of your goods, online or in store, we then do the rest, you receive funds in crypto or your preferred currency, direct to your bank or wallet.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="1000" animateIn="animate__fadeInUp">
							<p className="ps-section-text">We handle both crypto and fiat payment completion, providing full integration and support to streamline your operations. Our payment technology is state of the art and connected to local partners as well as the global schemes across all of Europe, Asia as well as the rest of the world. Whatever your payment requirement, talk to us.</p>
						</AnimationOnScroll>
					</div>
					<img loading="lazy" className="ps-section-img" src={`${image_base_url}/paysu/ps-completion-1.webp`} />
				</div>
			</div>
		</section>
	)
}