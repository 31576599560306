import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { useSelector } from 'react-redux';
import * as actions from '../../../../Redux/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { styled, createStyles } from '@mui/material/styles';
import MediaQuery from 'react-responsive';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../../common/theme';
const selectedCheckBoxIcon = '/common/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/common/crytoicons/checkbox_empty.png';


const b2cTransactionFilter = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [showDaterange, setDateRange] = useState(false);
  const [showCoinDropdown, setCoinDropdown] = useState(false); 
  const [showStatusDropdown, setStatusDropdown] = useState(false); 
  const [showTypeDropdown, setTypeDropdown] = useState(false);
  const [value, setValue] = useState([props.fromDate, props.toDate]);
  const classes = useStyles;

  const [selectAllTypes, setSelectAllTypes] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(()=>{
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, [])

  const handleAllTypeSelect =() =>{
    if(selectAllTypes === false){
      props.setSelectedTypes(props.allTypes);
    }else{
      setSelectAllTypes(false);
      props.setSelectedTypes([]);
    }
  }

  const handleAllStatusSelect =() =>{
    if(selectAllStatus === false){
      props.setSelectedStatus(props.allStatus);
    }else{
      setSelectAllStatus(false);
      props.setSelectedStatus([]);
    }
  }


  const toggleSelectionCrypto = (symbol) => {
    if (props.selectedCryptos.includes(symbol)) {
      let newList = props.selectedCryptos.filter((symbolObj) => {
        return symbolObj !== symbol;
      });
      props.setSelectedCryptos(newList);
    } else {
      let newList = [...props.selectedCryptos];
      newList.push(symbol);
      newList = newList.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
      props.setSelectedCryptos(newList);
    }
  };

  const toggleSelectionStatus = (status) => {
    if (props.selectedStatus.includes(status)) {
      let newList = props.selectedStatus.filter((symbolObj) => {
        return symbolObj !== status;
      });
      props.setSelectedStatus(newList);
    } else {
      let newList = [...props.selectedStatus];
      newList.push(status);
      newList = newList.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
      props.setSelectedStatus(newList);
    }
  };
  const toggleSelectionTypes = (type) => {
    if (props.selectedTypes.includes(type)) {
      let newList = props.selectedTypes.filter((symbolObj) => {
        return symbolObj !== type;
      });
      props.setSelectedTypes(newList);
    } else {
      let newList = [...props.selectedTypes];
      newList.push(type);
      newList = newList.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
      props.setSelectedTypes(newList);
    }
  };
  let headerButtonBorderStyle = getHeaderButtonBorderStyle(b2cColorMode, headerButtonDarkBgColour, headerButtonLightBgColour)
  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div className="b2ctransactionFilter">

    <MediaQuery minWidth={786} >
    <div
        className="date__filter"
          onClick={() => {
            if (showDaterange === true) {
              setDateRange(false)
            } else {
              setDateRange(true)
              setStatusDropdown(false)
              setTypeDropdown(false)
              setCoinDropdown(false)
            };
          }}
      >
        <div className="filter__heding" style={{paddingLeft:'10px'}} data-e2e="date-picker">
          {props.fromDate && props.toDate?
          moment(props.fromDate).format("MMM Do YY") + "    -    "+ moment(props.toDate).format("MMM Do YY"):
          'Select Date'
          }
        </div>
        <div className="showFilter__arrow">
          <i className={`fa fa-angle-${showDaterange ? 'up' : 'down'}`} aria-hidden="true" data-e2e="date-picker-icon"></i>
        </div>
      </div>
      {showDaterange && (
        <div className="date__select">
          <LocalizationProvider 
              dateAdapter={AdapterDayjs}
          >
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              inputFormat='YYYY-MM-DDTHH:mm:ss.SSSZ'
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                if (newValue[0] && newValue[1]) {
                  props.setFromDate(moment(newValue[0].$d).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                  props.setToDate(moment(newValue[1].$d).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                  props.setDateSelected(true);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
              }}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              // renderInput={(startProps, endProps) => (
              //   // <React.Fragment>
              //   //   <TextField {...startProps} />
              //   //   <Box sx={{ mx: 2 }}> to </Box>
              //   //   <TextField {...endProps} />
              //   // </React.Fragment>
              // )}
            />
          </LocalizationProvider>
        </div>
      )}
      <div className="coins__filter">
        <div
          className="heading___icon"
          onClick={() => {
            setCoinDropdown(true);
            setDateRange(false);
            setStatusDropdown(false);
            setTypeDropdown(false);
          }}
        >
          <div className="heding" data-e2e="select-coin-drop-down">{props.selectedCryptos.length === props.allCryptos.length ||
                props.selectedCryptos.length === 0
                  ? 'All Coins Selected'
                  : `${props.selectedCryptos.length} Coins Selected`}</div>
          <div className="arrow">
            <i className={`fa fa-angle-${showCoinDropdown ? 'up' : 'down'}`} aria-hidden="true" data-e2e={`${showCoinDropdown ? 'up' : 'down'}`}></i>
          </div>
        </div>
        {showCoinDropdown && (
          <div className="showDropdown">
            <div
              className="showCoinDropdown__heading"
              onClick={() => {
                setCoinDropdown(false);
              }}
            >
              <div className="heading__name" >{props.selectedCryptos.length === props.allCryptos.length ||
                props.selectedCryptos.length === 0
                  ? 'All Coins Selected'
                  : `${props.selectedCryptos.length} Coins Selected`}</div>
              <div className="dropdown__icon">
                <i  data-e2e={`coin-select-${showCoinDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showCoinDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
              </div>
            </div>
            {/* <div className="search__box">
              <input type="text" placeholder="Search for any coin…" />}
              <i className="fa fa-search" aria-hidden="true"></i>
            </div> */}
            <div className="allCrypto__rows" style={{height:'200px'}}>
              {props.allCryptos.map((crypto, index) => {
                let { name, symbol, icon } = crypto;                return (
                  <div key={index} className="coins__rowData" onClick={() => toggleSelectionCrypto(symbol)}>
                    <div className="rowData__name__image">
                      <img src={`${image_base_url}${icon}`} alt="bitcoin" />
                      <span data-e2e={name}>
                        {name} / {symbol}
                      </span>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedCryptos.includes(symbol) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                      alt="checkbox"
                      className="CryptoCheckBox"
                    />
                  </div>
                );
              })}
            </div>

            <div className="refresh__and__selectAll">
              <div className="refreshSelectAll">
                <div className="refresh" style={{ ...headerButtonStyle }} onClick={() => props.setSelectedCryptos([])}>
                  <div className="refresh__icon">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </div>
                </div>
                <div
                  className="selectAll"
                  style={{ ...headerButtonBorderStyle }}
                  onClick={() => {
                    let allCoins = [];
                    props.allCryptos.map((c) => allCoins.push(c.symbol));
                    props.setSelectedCryptos(allCoins);
                  }}
                >
                  <h1 data-e2e="refresh-icon">Select all</h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="coins__filter" style={{ width: '237px' }}>
        <div
          className="heading___icon"
          onClick={() => {
            setStatusDropdown(true);
            setCoinDropdown(false);
            setDateRange(false);
            setTypeDropdown(false);
          }}
        >
          <div className="heding" data-e2e="status-select-drop-down">
          {props.selectedStatus.length === props.allStatus.length ||
                props.selectedStatus.length === 0
                  ? 'All Status Selected'
                  : `${props.selectedStatus.length} Status Selected`}</div>
          <div className="arrow">
            <i data-e2e={`status-select-${showStatusDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showStatusDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
          </div>
        </div>
        {showStatusDropdown && (
          <div className="showDropdown" style={{ height: 'fit-content' }}>
            <div
              className="showCoinDropdown__heading"
              onClick={() => {
                setStatusDropdown(false);
              }}
            >
              <div className="heading__name">{props.selectedStatus.length === props.allStatus.length ||
                props.selectedStatus.length === 0
                  ? 'All Status Selected'
                  : `${props.selectedStatus.length} Status Selected`}</div>
              <div className="dropdown__icon">
                <i data-e2e={`status-select-${showStatusDropdown ? 'up' : 'down'}`}className={`fa fa-angle-${showStatusDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
              </div>
            </div>
            <div className="allCrypto__rows">
              {props.allStatus.map((status, index) => {
                return (
                  <div key={index} className="coins__rowData" onClick={() => toggleSelectionStatus( status)}>
                    <div className="rowData__name__image">
                      <span style={{ textTransform: 'capitalize', paddingLeft: '0px' }} data-e2e={status}>{status}</span>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedStatus.includes(status) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                      alt="checkbox"
                      className="CryptoCheckBox"
                    />
                  </div>
                );
              })}
            </div>

            <div className="refresh__and__selectAll">
              <div className="refreshSelectAll">
                <div className="refresh" style={{ ...headerButtonStyle }} onClick={() => props.setSelectedStatus([])}>
                  <div className="refresh__icon">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </div>
                </div>
                <div
                  style={{ width: '166px', ...headerButtonBorderStyle }}
                  className="selectAll"
                  onClick={() => {
                    props.setSelectedStatus(props.allStatus);
                  }}
                >
                  <h1 data-e2e="refresh-icon">Select all</h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="coins__filter" style={{ width: '237px' }}>
        <div
          className="heading___icon"
          onClick={() => {
            setTypeDropdown(true);
            setStatusDropdown(false);
            setCoinDropdown(false);
            setDateRange(false);
          }}
        >
          <div className="heding" data-e2e="all-type-selected"> {props.selectedTypes.length === props.allTypes.length ||
                props.selectedTypes.length === 0
                  ? 'All Type Selected'
                  : `${props.selectedTypes.length} Type Selected`}</div>
          <div className="arrow">
            <i data-e2e={`all-type-${showTypeDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showTypeDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
          </div>
        </div>
        {showTypeDropdown && (
          <div className="showDropdown" style={{ height: 'fit-content' }}>
            <div
              className="showCoinDropdown__heading"
              onClick={() => {
                setTypeDropdown(false);
              }}
            >
              <div className="heading__name">{props.selectedTypes.length === props.allTypes.length ||
                props.selectedTypes.length === 0
                  ? 'All Type Selected'
                  : `${props.selectedTypes.length} Type Selected`}</div>
              <div className="dropdown__icon">
                <i data-e2e={`all-type-selected-${showTypeDropdown ? 'up' : 'down'}`}className={`fa fa-angle-${showTypeDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
              </div>
            </div>
            <div className="allCrypto__rows">
              {props.allTypes.map((type, index) => {
                return (
                  <div key={index} className="coins__rowData" onClick={() => toggleSelectionTypes(type)}>
                    <div className="rowData__name__image">
                      <span style={{ textTransform: 'capitalize', paddingLeft: '0px' }} data-e2e={type}>{type}</span>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedTypes.includes(type) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                      alt="checkbox"
                      className="CryptoCheckBox"
                    />
                  </div>
                );
              })}
            </div>

            <div className="refresh__and__selectAll">
              <div className="refreshSelectAll">
                <div className="refresh" style={{ ...headerButtonStyle }} onClick={() => props.setSelectedTypes([])}>
                  <div className="refresh__icon">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </div>
                </div>
                <div
                  className="selectAll"
                  style={{ ...headerButtonBorderStyle, width: '166px' }}
                  onClick={() => {
                    props.setSelectedTypes(props.allTypes);
                  }}
                >
                  <h1 data-e2e="refresh-icon">Select all</h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MediaQuery>

    <MediaQuery maxWidth={786} >
      <div className="coins__filter">
        <div
          className="heading___icon"
          onClick={() => {
            setCoinDropdown(true);
            setDateRange(false);
            setStatusDropdown(false);
            setTypeDropdown(false);
          }}
        >
          <div className="heding" data-e2e="select-coin-drop-down">{props.selectedCryptos.length === props.allCryptos.length ||
                props.selectedCryptos.length === 0
                  ? 'All Coins Selected'
                  : `${props.selectedCryptos.length} Coins Selected`}</div>
          <div className="arrow">
            <i className={`fa fa-angle-${showCoinDropdown ? 'up' : 'down'}`} aria-hidden="true" data-e2e={`${showCoinDropdown ? 'up' : 'down'}`}></i>
          </div>
        </div>
        {showCoinDropdown && (
          <div className="showDropdown">
            <div
              className="showCoinDropdown__heading"
              onClick={() => {
                setCoinDropdown(false);
              }}
            >
              <div className="heading__name" >{props.selectedCryptos.length === props.allCryptos.length ||
                props.selectedCryptos.length === 0
                  ? 'All Coins Selected'
                  : `${props.selectedCryptos.length} Coins Selected`}</div>
              <div className="dropdown__icon">
                <i  data-e2e={`coin-select-${showCoinDropdown ? 'up' : 'down'}`} className={`fa fa-angle-${showCoinDropdown ? 'up' : 'down'}`} aria-hidden="true"></i>
              </div>
            </div>
            {/* <div className="search__box">
              <input type="text" placeholder="Search for any coin…" />}
              <i className="fa fa-search" aria-hidden="true"></i>
            </div> */}
            <div className="allCrypto__rows" style={{height:'200px'}}>
              {props.allCryptos.map((crypto, index) => {
                let { name, symbol, icon } = crypto;                return (
                  <div key={index} className="coins__rowData" onClick={() => toggleSelectionCrypto(symbol)}>
                    <div className="rowData__name__image">
                      <img src={`${image_base_url}${icon}`} alt="bitcoin" />
                      <span data-e2e={name}>
                        {name} / {symbol}
                      </span>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedCryptos.includes(symbol) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                      alt="checkbox"
                      className="CryptoCheckBox"
                    />
                  </div>
                );
              })}
            </div>

            <div className="refresh__and__selectAll">
              <div className="refreshSelectAll">
                <div className="refresh" style={{ ...headerButtonStyle }} onClick={() => props.setSelectedCryptos([])}>
                  <div className="refresh__icon">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </div>
                </div>
                <div
                  className="selectAll"
                  style={{ ...headerButtonBorderStyle }}
                  onClick={() => {
                    let allCoins = [];
                    props.allCryptos.map((c) => allCoins.push(c.symbol));
                    props.setSelectedCryptos(allCoins);
                  }}
                >
                  <h1 data-e2e="refresh-icon">Select all</h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      
      <div className="mobile_date_filter">
        <div className="coins__filter" style={{height:'41px', borderRadius:'8px'}}>
          <div className="heading___icon"
            style={{padding:'10px'}}
            >
            {`From : ${props.fromDate && 
          moment(props.fromDate).format("DD.MM.YY") }`}
          </div> 
        </div>
          <div className="calendar_img"
            onClick={() => {
              if (showDaterange === true) {
                setDateRange(false)
              } else {
                setDateRange(true)
                setStatusDropdown(false)
                setTypeDropdown(false)
                setCoinDropdown(false)
              }
            }}>
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </div>
        <div className="coins__filter" style={{height:'41px', borderRadius:'8px', marginLeft:'20px'}}>
          <div className="heading___icon"
          style={{padding:'10px'}}
           >
            {`To : ${props.toDate && 
          moment(props.toDate).format("DD.MM.YY") }`}
          </div>
        </div>

        {showDaterange && (
          <div className='wallets-history-date-select mobile_date_select' >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
              >
            <StaticDateRangePicker
              displayStaticWrapperAs="mobile"
              inputFormat='YYYY-MM-DDTHH:mm:ss.SSSZ'
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                if (newValue[0] && newValue[1]) {
                  props.setFromDate(moment(newValue[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                  props.setToDate(moment(newValue[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                  props.setDateSelected(true);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
              }}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              
            />
          </LocalizationProvider>
          </div>
        )}
      </div>

      <div className="mobile_status_filter">
        <h1>{`Status`}</h1>

        <div className='name_wrap'>
          <div
          className={props.selectedStatus.length === props.allStatus.length ? "status_name activeFilterBtn":"status_name"}
          onClick={() => {
              setSelectAllStatus(!selectAllStatus);
              handleAllStatusSelect();
            }}>{`All`}</div>
          {props.allStatus.map((status, index) =>{
            return(
              <div className={props.selectedStatus.includes(status) ? "status_name activeFilterBtn":"status_name"} key={index} 
              onClick={() => toggleSelectionStatus(status)}>
                <div>{status}</div>
              </div>
            )})
          }
        </div>
    
      </div>

      <div className="mobile_status_filter">
        <h1>{`Type`}</h1>

        <div className='name_wrap'>
          <div className={props.selectedTypes.length === props.allTypes.length ? "status_name activeFilterBtn":"status_name"} 
          onClick={() => {             
              setSelectAllTypes(!selectAllTypes);
              handleAllTypeSelect();            
            }}>{`All`}</div>
          {props.allTypes.map((type, index) =>{
            return(
              <div className={props.selectedTypes.includes(type) ? "status_name activeFilterBtn":"status_name"} key={index} onClick={() => toggleSelectionTypes(type)}>
                <div>{type}</div>
              </div>
            )})
          }
        </div>    
      </div>

      <div className="filter_button" onClick={() => props.setShowFilterModel(false)}>
          <div>{`SEE ${props.totalTransations} TRANSACTIONS`}</div>
      </div>
    </MediaQuery>
     
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.common.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setToken: (data) => dispatch(actions.setToken(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(b2cTransactionFilter);

const useStyles = styled(TextField)(() =>
  createStyles({
    noBorder: {
      outline: 'none',
      border: 'none',
      color: '#fff',
    },
  }),
);
