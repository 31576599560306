import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import browser from 'browser-detect';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import * as actions from '../../../Redux/actions/index';
import { saveActivity } from '../../../common/activityTrackingService';
import { apiService } from '../../../common/apiCallService';
import CreditCardPayment from './CreditCardPayment';
import LoginScreen from './LoginScreen';
import SelectCreditCard from './SelectCreditCard';
import { useTranslation, withTranslation } from 'react-i18next';
import { t } from 'i18next';
import NavbarLang from '../../../../locale/NavbarLang';
import ReactLoading from 'react-loading';
import { constants } from '../../../common/constants';
let timeInterval = null;
const darkToggle = "/common/crytoicons/dark_toggle_icon2x.png";
const lightToggle = "/common/crytoicons/light_toggle_icon2x.png";
const back_icon = "/common/crytoicons/back_icon.png";
const back_icon_light = "/common/crytoicons/back_icon_light.png";
const browserInfo = browser();

class SelectCryptoToDeposit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listOfCryptoToShow: [],
            allCryptosList: [],
            showAll: true,
            transactionId: null,
            lightFlag: false,
            quickDepositFlag: false,
            searchCryptoText: "",
            depositMethod: null,
            hideAll: true,
            userWalletDeposit: false,
            useCreditCard: false,
            methodSet: null,
            method: null,
            addNewCard: false,
            emptyCardList: false,
            saveCardList: [],
            selectedCard: null,
            cardInitialization: false,
            deleteCard: null,
            loadingModalFlag: false,
            invoiceDetails: null,
            deviceIp: null,
            lodingExchangeRates: false,
            showEmailAddressPage: false,
            merchantUserEmail: "",
        }
    }
    componentDidMount = async () => {
        let deviceIp = this.getIp();
        this.setState({ deviceIp })
        if (queryString.parse(this.props.location.search).invoiceId && queryString.parse(this.props.location.search).invoiceId.trim() !== "") {
            this.setState({ depositMethod: "other-wallet" });
            localStorage.removeItem("receivedCryptoCurrency")
            localStorage.setItem("method", 'other')
            localStorage.setItem("depositMethod", "other-wallet");
            const invoiceDetails = await this.getMerchatInvoiceDetails(queryString.parse(this.props.location.search).invoiceId.trim());
            if (invoiceDetails) {
                if (!invoiceDetails.merchantUserEmail || invoiceDetails.merchantUserEmail.trim() === "") {
                    this.setState({ showEmailAddressPage: true })
                }
                if (invoiceDetails.merchantId) {
                    localStorage.setItem("depositMerchantId", invoiceDetails.merchantId)
                }
                if (invoiceDetails.transactionId) {
                    localStorage.setItem("transactionId", invoiceDetails.transactionId)
                } else {
                    localStorage.setItem("transactionId", "null")
                }
                if (invoiceDetails.cryptoCurrencies.length === 1) {
                    localStorage.setItem("receivedCryptoCurrency", invoiceDetails.cryptoCurrencies[0])
                }
                this.setState({ invoiceDetails })
            }
        }
        let ShouldEnableUserWallet = {}
        let ShouldEnableCreditCard = {}
        if (!this.state.invoiceDetails) {
            ShouldEnableUserWallet = await this.getSingleFeatureConfigofMerchant("userWalletActive", queryString.parse(this.props.location.search).merchantid ? queryString.parse(this.props.location.search).merchantid : localStorage.getItem("depositMerchantId"))
            ShouldEnableCreditCard = await this.getSingleFeatureConfigofMerchant("creditCardActive", queryString.parse(this.props.location.search).merchantid ? queryString.parse(this.props.location.search).merchantid : localStorage.getItem("depositMerchantId"))
            if (ShouldEnableUserWallet.config) {
                this.setState({ userWalletDeposit: true })
            }
            if (ShouldEnableCreditCard.config) {
                this.setState({ useCreditCard: true })
            }
            if (ShouldEnableUserWallet.config || ShouldEnableCreditCard.config) {
                if (!ShouldEnableUserWallet.config && !ShouldEnableCreditCard.config) {
                    this.setState({ depositMethod: "other-wallet" });
                    localStorage.setItem("depositMethod", "other-wallet");
                }
            } else {
                this.setState({ depositMethod: "other-wallet" });
                localStorage.setItem("depositMethod", "other-wallet");
            }
        }
        if(localStorage.getItem("depositMethod") && !this.state.depositMethod){
            this.setState({ depositMethod: localStorage.getItem("depositMethod") });
        }

        if (queryString.parse(this.props.location.search).mode) {
            let mode = queryString.parse(this.props.location.search).mode
            if (mode == 'dark' || mode == 'light') {
                this.handleToggle(mode)
            }
        }
        if (localStorage.getItem('token') && !this.props.token) {
            this.props.setToken(localStorage.getItem('token'));
        }
        if (sessionStorage.getItem("lightFlag")) {
            this.setState({ lightFlag: (sessionStorage.getItem("lightFlag") === "true") })
        } else {
            sessionStorage.setItem("lightFlag", false);
        }
        if (queryString.parse(this.props.location.search).quickdeposit) {
            localStorage.setItem("quickdeposit", queryString.parse(this.props.location.search).quickdeposit)
        } else {
            localStorage.setItem("quickdeposit", false);
        }
        if (queryString.parse(this.props.location.search).uniqueId) {
            localStorage.setItem("uniqueId", queryString.parse(this.props.location.search).uniqueId);
        }
        if (localStorage.getItem("quickdeposit") === "true") {
            this.setState({ quickDepositFlag: true });
        }
        if (queryString.parse(this.props.location.search).merchantid && queryString.parse(this.props.location.search).transactionid && queryString.parse(this.props.location.search).activityToken) {
            this.setState({ hideAll: true})
            localStorage.removeItem("isGoingBack")
            localStorage.removeItem("receivedCryptoCurrency")
            localStorage.setItem("depositMethod", null)
            localStorage.removeItem("method")
            localStorage.removeItem("methodSet")
            if (ShouldEnableUserWallet) {
                if (ShouldEnableUserWallet.enabled === false) {
                    this.setState({ userWalletDeposit: false, depositMethod: "other-wallet" })
                    localStorage.setItem("depositMethod", "other-wallet")
                }
            }
            let method = queryString.parse(this.props.location.search).method;
            console.log("method from URL is ", method)

            if (method) {
                localStorage.setItem("method", method.toLocaleLowerCase())
            }
            if (queryString.parse(this.props.location.search).cryptoCurrency === 'CARD') {
                localStorage.setItem("method", 'card')
            }
            localStorage.setItem("depositMerchantId", queryString.parse(this.props.location.search).merchantid)
            localStorage.setItem("transactionId", queryString.parse(this.props.location.search).transactionid)
            if (queryString.parse(this.props.location.search).cryptoCurrency && queryString.parse(this.props.location.search).cryptoCurrency !== "" && queryString.parse(this.props.location.search).cryptoCurrency !== "CARD") {
                localStorage.setItem("receivedCryptoCurrency", queryString.parse(this.props.location.search).cryptoCurrency)
            }
            this.setState({ transactionId: queryString.parse(this.props.location.search).transactionid })
            localStorage.setItem("activity_tracking_token", queryString.parse(this.props.location.search).activityToken)
            timeInterval = setTimeout(() => {
                window.location.href = queryString.parse(this.props.location.search).quickdeposit ? "/cryptoToDeposit?quickdeposit=true" : "/cryptoToDeposit"
            }, 100)
        } else if (localStorage.getItem("transactionId") && localStorage.getItem("depositMerchantId") && (this.state.invoiceDetails || localStorage.getItem("activity_tracking_token"))) {
            try {
                let isGoingBackTo = localStorage.getItem('goingBackTo')
                let cameFromDeposit = localStorage.getItem('isGoingBack')
                let depositMethod = localStorage.getItem('depositMethod')
                if (cameFromDeposit && depositMethod === 'other-wallet') {
                    this.setState({ depositMethod: isGoingBackTo })
                }
                if (localStorage.getItem("depositMethod") === "other-wallet" && localStorage.getItem("receivedCryptoCurrency") && localStorage.getItem("receivedCryptoCurrency") !== "") {
                    this.setState({ hideAll: false})
                } else if (localStorage.getItem("method") === "other" && localStorage.getItem("receivedCryptoCurrency") && localStorage.getItem("receivedCryptoCurrency") !== "") {
                    this.setState({ hideAll: false})
                } else if (!queryString.parse(this.props.location.search).transactionid) {
                    this.setState({ hideAll: false })
                }
                if (localStorage.getItem("transactionId") != "null") {
                    this.getTransactionAndInitializeAll();
                }


            } catch (err) {
                console.log(err)
            }
        } else {
            alert("TransactionId/MerchantId/ActivityToken is missing! Try again with correct URL.")
            this.props.navigate("/")
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                transactionId: null,
                merchantId: null,
                component: "DepositDialog",
                severity: null,
                errorCode: "406",
                errorMessage: "Landing Error: TransactionId/MerchantId/ActivityToken is missing! Try again with correct URL.",
                postMvpComment: null,
            }, (data) => {
                // console.log(data)
            }, (err) => {
                console.log("err:", err)
            })
        }
        var content = document.querySelector('.deposit_card_body_cryptos');
        if (content) {
            content.addEventListener('scroll', function () {
                if (content.scrollTop !== 0) {
                    content.style.boxShadow = "inset 0px 6px 12px -6px rgba(0,0,0,0.5)";
                } else {
                    content.style.boxShadow = "none";
                }
            });
        }
    }

    checkCoinsSpirit = () => {
        return (window && window.location && window.location.hostname &&
            window.location.hostname.includes(this.props.coinSpiritHostName)) || false;
    };
    getTransactionAndInitializeAll = async () => {
        let transaction = await this.getTransaction()
        this.setState({ transactionId: localStorage.getItem("transactionId") })
        if (transaction) {
            await this.init(transaction)
            if (!this.state.invoiceDetails) {
                saveActivity({
                    EventPage: "DepositFlow",
                    EventType: "Launch",
                    attribute1: transaction ? transaction.merchantId : localStorage.getItem("depositMerchantId"),
                    attribute2: transaction ? transaction.merchantUserId : "Transaction Not Found",
                    attribute3: transaction && transaction.merchantName ? transaction.merchantName : "N/A",
                    attribute4: transaction ? transaction.transactionId : queryString.parse(this.props.location.search).transactionid,
                    attribute5: transaction ? transaction.depositAmount.toString() : "Transaction Not Found",
                    attribute6: transaction ? transaction.fiatCurrency : "Transaction Not Found",
                    device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
                    browser_type: browserInfo.name,
                    browser_version: browserInfo.version,
                    language: navigator.language,
                    ip_address: this.state.deviceIp,
                })
            }
            let method = localStorage.getItem("method");
            if (method && ['wallet', 'other', 'card'].includes(method)) {
                if (method === "wallet") {
                    if (ShouldEnableUserWallet && ShouldEnableUserWallet.config == true) {
                        this.setState({ userWalletDeposit: true, depositMethod: "cryptonpay-wallet" })
                        localStorage.setItem("depositMethod", "cryptonpay-wallet")
                        localStorage.setItem("methodSet", true)
                        this.setState({ methodSet: true })
                    }
                } else if (method == "card") {
                    if (ShouldEnableCreditCard && ShouldEnableCreditCard.config == true) {
                        this.updateTransactionMethod("card")
                        this.setState({ userWalletDeposit: false, depositMethod: "credit-card" })
                        localStorage.setItem("depositMethod", "credit-card")
                        localStorage.setItem("methodSet", true)
                        this.setState({ methodSet: true })
                    }
                } else if (method == "other") {
                    this.setState({ userWalletDeposit: false, depositMethod: "other-wallet" })
                    localStorage.setItem("depositMethod", "other-wallet")
                    localStorage.setItem("methodSet", true)
                    this.setState({ methodSet: true })
                }
            }

            this.initializedInterval(this.state.showAll, transaction)

        } else {
            alert("Transaction not found! Try again with correct URL..")
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                transactionId: null,
                merchantId: null,
                component: "DepositDialog",
                severity: null,
                errorCode: "406",
                errorMessage: "Landing Error: Transaction not found! Try again with correct URL.",
                postMvpComment: null,
            }, (data) => {
                // console.log(data)
            }, (err) => {
                console.log("err:", err)
            })
        }
    }
    isEmail(emailAdress) {
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailAdress.match(regex))
            return true;
        else
            return false;
    }

    handleMerchantUserEmailChange = (email) => {
        this.setState({ errorEmail: "" })
        if (email === "") {
            this.setState({ merchantUserEmail: email })
        } else {
            if (!this.isEmail(email)) {
                this.setState({ errorEmail: "Invalid email!" })
            }
            this.setState({ merchantUserEmail: email })
        }
    }

    initializedInterval = async (showAll, transaction) => {
        // console.log("intervalstarted:",
        // "showAll:", showAll,
        // "transction", transaction)
        const listOfAcceptableCurrencies = await this.getMerchantAcceptableCryptoCurrencies();
        timeInterval = setInterval(async () => {
            if (transaction && this.state.depositMethod === "other-wallet") {
                if (this.state.invoiceDetails) {
                    this.GetCryptoExchangeRate(this.props.transaction, this.state.invoiceDetails.cryptoCurrencies, listOfAcceptableCurrencies)
                } else if (!showAll) {
                    this.GetCryptoExchangeRate(transaction)
                } else {
                    if (listOfAcceptableCurrencies && listOfAcceptableCurrencies.length > 0) {
                        const list = listOfAcceptableCurrencies.map((obj) => {
                            return obj.symbol;
                        })
                        this.GetCryptoExchangeRate(transaction, list, listOfAcceptableCurrencies)
                    }
                }
            }
        }, 60000)
    }

    init = async (transaction, continueClick) => {
        if (transaction && this.state.useCreditCard && !this.state.invoiceDetails) {
            const query = {
                merchantUserId: transaction.merchantUserId,
            };
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-saved-credit-card-list', query, async (data) => {
                if (data) {
                    if (data.savedCardList && data.savedCardList.length > 0) {
                        this.setState({
                            saveCardList: data.savedCardList,
                            selectedCard: data.savedCardList[0],
                            cardInitialization: true,
                        }, () => {
                            if (continueClick) {
                                this.updateTransactionMethod("card")
                                this.setState({ depositMethod: "credit-card" }, () => { this.getTransaction() })
                            }
                        });
                    } else {
                        this.setState({ addNewCard: true, emptyCardList: true, cardInitialization: true, }, () => {
                            if (continueClick) {
                                this.updateTransactionMethod("card")
                                this.setState({ depositMethod: "credit-card" }, () => { this.getTransaction() })
                            }
                        });
                    }
                }
            }, (err) => {
                console.log("errrr", err)
            })
        }
    }


    deleteCardFormDB = async (transaction, removeCardId, transactionIdOfCardToken) => {
        if (transaction && this.state.useCreditCard) {
            let integrationTypeForDeletedCard = null, routingIdForDeletedCard = null, acquirerNameForDeletedCard = null, acquirerUniqueId = null;
            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/getIntegrationTypeForTransaction', { transactionId: transactionIdOfCardToken }, async (data) => {
                if (data) {
                    integrationTypeForDeletedCard = data.integrationTypeForDeletedCard
                    routingIdForDeletedCard = data.routingId
                    acquirerNameForDeletedCard = data.acquireName    
                    acquirerUniqueId = data?.acquirerUniqueId
                }
                const query = {
                    merchantUserId: transaction.merchantUserId,
                    removeCardId,
                    acquirerUniqueId
                };
                await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/delete-saved-credit-card', query, async (dataObj) => {
                    if (dataObj) {
                        if (!dataObj.removeCardId) {
                            alert("Card remove failed!");
                        } else {
                            const tokenizeData = {
                                ...query,
                                cardToken: dataObj.cardToken,
                                routingId: routingIdForDeletedCard,
                                acquirerName: acquirerNameForDeletedCard,
                            }
                            switch (integrationTypeForDeletedCard) {
                                case constants.GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE:
                                    await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/goldenGatewayCardTokenize', { ...tokenizeData, typeOfTokenization: "deleteCard" }, (res) => {
                                        if (res) {
                                            console.log("card token deactivation success")
                                        }
                                    }, (err) => {
                                        console.log("errrr", err)
                                    })
                                    break;
                                case constants.WORLD_CARD_INTEGRATION_TYPE:
                                    await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/worldCardDeleteToken',
                                        tokenizeData, (res) => {
                                            if (res) {
                                                console.log("card token deactivation success WorldCard")
                                            }
                                        }, (err) => {
                                            console.error("errrr", err)
                                        })
                                    break;
                                default:
                                    console.error("IntegrationType not found for the token!")
                                    break;
                            }
                        }
                    }
                }, (err) => {
                    console.error("errrr", err)
                })
            }, (err) => {
                console.error("errrr", err)
            })
        }
    }


    componentWillUnmount() {
        clearInterval(timeInterval)
    }

    getTransaction = () => {

        return new Promise(async (resolve, reject) => {
            const transactionId = localStorage.getItem("transactionId");
            const merchantId = localStorage.getItem("depositMerchantId");

            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetTransactionDetails', {
                transactionId,
                merchantId,
                depositMethod: this.state.depositMethod ? this.state.depositMethod : localStorage.getItem("depositMethod"),
                hostname: window.location.hostname
            }, async (data) => {
                if (data) {
                    this.props.setTransaction(data);
                    if (data.merchantName && data.merchantName !== "" && data.merchantName !== "null") {
                        localStorage.setItem("depositMerchantName", data.merchantName);
                    }
                    if (data.redirectUrl && data.redirectUrl !== "" && data.redirectUrl !== "null") {
                        localStorage.setItem("redirectUrl", data.redirectUrl);
                    }
                    if (
                        data.status &&
                        (
                            data.status.message === "Deposit refunded" ||
                            data.status.message === "Deposit completed" ||
                            data.status.message === "Deposit manually completed" ||
                            data.status.message === "Deposit completed via wallet" ||
                            data.status.message === "Withdrawal completed" ||
                            data.status.message === "Transfer completed"
                        )
                    ) {
                        this.props.navigate("/depositSuccessful");
                    }
                    setTimeout(async () => {
                        const listOfAcceptableCurrencies = await this.getMerchantAcceptableCryptoCurrencies();
                        if (this.state.invoiceDetails) {
                            this.GetCryptoExchangeRate(this.props.transaction, this.state.invoiceDetails.cryptoCurrencies, listOfAcceptableCurrencies)
                        } else if (listOfAcceptableCurrencies && listOfAcceptableCurrencies.length > 0) {
                            if (localStorage.getItem("receivedCryptoCurrency") && localStorage.getItem("receivedCryptoCurrency") !== "") {
                                this.GetCryptoExchangeRate(this.props.transaction, [localStorage.getItem("receivedCryptoCurrency")], listOfAcceptableCurrencies)
                            } else {
                                const list = listOfAcceptableCurrencies.map((obj) => {
                                    return obj.symbol;
                                })
                                this.GetCryptoExchangeRate(data, list, listOfAcceptableCurrencies)
                            }
                        }
                    }, 0)
                    resolve(data)
                }
            }, (err) => {
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId,
                    merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "GetTransactionDetails API Error: " + err.message : "Error in GetTransactionDetails API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                resolve(false)
            },
        true
        )
        })
    }

    updateTransactionMethod = (method) => {
        return new Promise(async (resolve, reject) => {
            await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/updateTransactionMethod', {
                transactionId: this.props.transaction.transactionId,
                merchantId: this.props.transaction.merchantId,
                depositMethod: method,
            }, async (data) => {
                if (data) {
                    resolve(data)
                }
            }, (err) => {
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId: this.props.transaction.transactionId,
                    merchantId:  this.props.transaction.merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "updateTransactionMethod API Error: " + err.message : "Error in updateTransactionMethod API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                resolve(false)
            })
        })
    }

    getSingleFeatureConfig = (featureName) => {
        return new Promise(async (resolve, reject) => {
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-single-feature-config', {
                featureName,
            }, async (data) => {
                if (data) {
                    resolve(data)
                }
            }, (err) => {
                console.log("errrr", err)
                resolve(false)
            })
        })
    }

    getSingleFeatureConfigofMerchant = (featureName, merchantId) => {
        return new Promise(async (resolve, reject) => {
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-user-deposit-config', {
                featureName,
                merchantId,
                hostname: window.location.hostname
            }, async (data) => {
                if (data) {
                    resolve(data)
                }
            }, (err) => {
                console.log("errrr", err)
                resolve(false)
            })
        })
    }
    getMerchatInvoiceDetails = (invoiceId) => {
        return new Promise(async (resolve, reject) => {
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-merchant-invoice-details', {
                invoiceId,
                hostname: window.location.hostname,
            }, async (data) => {
                if (data && data.invoice) {
                    resolve(data.invoice)
                } else {
                    alert(data.msg ? data.msg : "something went wron with invoice!")
                    this.props.navigate("/")
                    resolve(false)
                }
            }, (err) => {
                console.log("errrr", err)
                resolve(false)
            })
        })
    }

    createPaymentWithNewEmail = (invoiceId, merchantUserEmail) => {
        return new Promise(async (resolve, reject) => {
            await apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/change-merchant-user-email-of-transaction', {
                invoiceId,
                hostname: window.location.hostname,
                merchantUserEmail,
            }, async (data) => {
                if (data && data.invoice) {
                    resolve(data.invoice)
                    this.setState({ showEmailAddressPage: false })
                } else {
                    this.setState({ errorEmail: data.msg ? data.msg : "something went wrong with invoice!" })
                    resolve(false)
                }
            }, (err) => {
                console.log("errrr", err)
                resolve(false)
            })
        })
    }

    GetCryptoExchangeRate = async (transaction, listOfAcceptableCurrencies, listOfAcceptableCurrenciesObjs) => {
        // console.log("getExchange " + (listOfAcceptableCurrencies ? "multiple" : "one"))
        if (transaction) {
            if (!this.state.invoiceDetails) {
                saveActivity({
                    EventPage: "DepositFlow",
                    EventType: "ExchangeRateApi",
                    attribute1: transaction.merchantId,
                    attribute2: transaction.merchantUserId,
                    attribute3: transaction.merchantName ? transaction.merchantName : "N/A",
                    attribute4: transaction.transactionId,
                    attribute5: transaction.depositAmount.toString(),
                    attribute6: transaction.fiatCurrency,
                    attribute7: listOfAcceptableCurrencies ? listOfAcceptableCurrencies.toString() : ["BTC"].toString(),
                    device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
                    browser_type: browserInfo.name,
                    browser_version: browserInfo.version,
                    language: navigator.language,
                    ip_address: this.state.deviceIp,
                })
            }
            this.setState({ lodingExchangeRates: true })
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetCryptoExchangeRate', {
                transactionId: transaction.transactionId,
                merchantId: transaction.merchantId,
                crypto: listOfAcceptableCurrencies ? listOfAcceptableCurrencies : ["BTC"],
                currency: transaction.fiatCurrency,
                amount: transaction.depositAmount,
                hostname: window.location.hostname
                // emulationCRAPI: true,
            }, (data) => {
                this.setState({ lodingExchangeRates: false })
                if (localStorage.getItem("depositMethod") === "other-wallet" && localStorage.getItem("receivedCryptoCurrency") && localStorage.getItem("receivedCryptoCurrency") !== ""
                    && data && data.cryptoAmounts && Object.keys(data.cryptoAmounts).length > 0
                    && listOfAcceptableCurrenciesObjs && listOfAcceptableCurrenciesObjs.length > 0) {
                    let list = Object.keys(data.cryptoAmounts).map(currency => {
                        let currencyList = listOfAcceptableCurrenciesObjs.filter(currencyObj => localStorage.getItem("receivedCryptoCurrency") === currencyObj.symbol);
                        let currencyObj = listOfAcceptableCurrenciesObjs.filter(currencyObj => currency === currencyObj.symbol)[0];
                        if (currencyList.length > 0) {
                            currencyObj = currencyList[0];
                        }
                        return {
                            name: currencyObj.name,
                            img: currencyObj.icon,
                            symbol: currencyObj.symbol,
                            cryptocurrencyAmount: data.cryptoAmounts[currency] ? data.cryptoAmounts[currency] : "N/A",
                            fiatAmount: data.fiatAmount,
                        }
                    })
                    const recivedCrypto = list[0];
                    this.updateTransactionMethod("crypto")
                    this.bypassCardClick(recivedCrypto.img, recivedCrypto.cryptocurrencyAmount, recivedCrypto.symbol, recivedCrypto.fiatAmount, transaction.fiatCurrency)
                } else {
                    if (data && data.cryptoAmounts && Object.keys(data.cryptoAmounts).length === 1 && data.cryptoAmounts.BTC) {
                        const obj = {
                            symbol: "BTC",
                            name: "Bitcoin",
                            img: "/common/crytoicons/btc.png",
                            cryptocurrencyAmount: data.cryptoAmounts["BTC"],
                            fiatAmount: data.fiatAmount,
                        }
                        this.setState({ listOfCryptoToShow: [obj] })
                        this.setState({ allCryptosList: [obj] })
                    } else
                        if (data && data.cryptoAmounts && Object.keys(data.cryptoAmounts).length > 0) {
                            let list = Object.keys(data.cryptoAmounts).map(currency => {
                                let currencyObj = listOfAcceptableCurrenciesObjs.filter(currencyObj => currency === currencyObj.symbol)[0];
                                return {
                                    name: currencyObj.name,
                                    img: currencyObj.icon,
                                    symbol: currencyObj.symbol,
                                    cryptocurrencyAmount: data.cryptoAmounts[currency] ? data.cryptoAmounts[currency] : "N/A",
                                    fiatAmount: data.fiatAmount,
                                }
                            })
                            this.setState({ allCryptosList: list })
                            this.setState({ listOfCryptoToShow: list });
                        } else {
                            alert("Correncies not found!")
                            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                                transactionId: transaction.transactionId,
                                merchantId: transaction.merchantId,
                                component: "DepositDialog",
                                severity: null,
                                errorCode: "406",
                                errorMessage: "GetCryptoExchangeRate API Error: Correncies not found!",
                                postMvpComment: null,
                            }, (data) => {
                                // console.log(data)
                            }, (err) => {
                                console.log("err:", err)
                            })
                        }
                }
            }, (err) => {
                this.setState({ lodingExchangeRates: false })
                console.log(err);
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId: transaction.transactionId,
                    merchantId: transaction.merchantId,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: err.code ? err.code : "406",
                    errorMessage: err.message ? "GetCryptoExchangeRate API Error: " + err.message : "Error in GetCryptoExchangeRate API",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
            })
        } else {
            alert('transaction not found!')
            apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                transactionId: null,
                merchantId: null,
                component: "DepositDialog",
                severity: null,
                errorCode: "406",
                errorMessage: "GetCryptoExchangeRate API Error: transaction not found!",
                postMvpComment: null,
            }, (data) => {
                // console.log(data)
            }, (err) => {
                console.log("err:", err)
            })
        }
    }

    getIp = () => new Promise((resolve, reject) => {
        axios.get('https://api.ipify.org?format=json')
            .then(function (response) {
                resolve(response.data.ip)
            })
            .catch(function (error) {
                resolve(null)
            });
    })
    getMerchantAcceptableCryptoCurrencies = () => {
        return new Promise(async (resolve, reject) => {
            if (this.props.transaction) {
                const transactionId = this.props.transaction.transactionId;
                const merchantId = this.props.transaction.merchantId;
                await apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/GetMerchantAcceptableCryptoCurrencies', {
                    transactionId,
                    merchantId,
                    hostname: window.location.hostname
                }, (data) => {
                    if (data && data.length > 0) {
                        resolve(data)
                    }
                }, (err) => {
                    console.log("err", err);
                    apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                        transactionId,
                        merchantId,
                        component: "DepositDialog",
                        severity: null,
                        errorCode: err.code ? err.code : "406",
                        errorMessage: err.message ? "GetMerchantAcceptableCryptoCurrencies API Error: " + err.message : "Error in GetMerchantAcceptableCryptoCurrencies API",
                        postMvpComment: null,
                    }, (data) => {
                        // console.log(data)
                    },
                        (err) => {
                            console.log("err:", err)
                        })
                    resolve([])
                })
            } else {
                alert('transaction not found!')
                apiService((window.location.hostname === "localhost" ? this.props.payment_api_url : "") + '/pga/saveErrorLog', {
                    transactionId: null,
                    merchantId: null,
                    component: "DepositDialog",
                    severity: null,
                    errorCode: "406",
                    errorMessage: "GetMerchantAcceptableCryptoCurrencies API Error: transaction not found!",
                    postMvpComment: null,
                }, (data) => {
                    // console.log(data)
                },
                    (err) => {
                        console.log("err:", err)
                    })
                resolve([])
            }
        })
    }


    bypassCardClick = (img, depositAmount, symbol, fiatAmount, fiatCurrency) => {
        if (localStorage.getItem('depositMethod')) {
            localStorage.setItem('goingBackTo', localStorage.getItem('depositMethod'))
        }
        if (timeInterval) {
            clearInterval(timeInterval)
        }
        localStorage.setItem("startTime", moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
        localStorage.setItem("fiatAmount", `${this.getFiatCurrencySymbol(fiatCurrency)}${fiatAmount}`)
        localStorage.setItem("selectedCrypto", symbol)
        localStorage.setItem("depositAmount", depositAmount)
        localStorage.setItem("cryptoImage", img)
        this.props.navigate("/deposit")
    }

    onCardClick = async (img, depositAmount, symbol, fiatAmount) => {
        if (localStorage.getItem('depositMethod')) {
            localStorage.setItem('goingBackTo', localStorage.getItem('depositMethod'))
        }
        if (!this.state.invoiceDetails) {
            saveActivity({
                EventPage: "DepositFlow",
                EventType: "SelectCrypto",
                attribute1: this.props.transaction.merchantId,
                attribute2: this.props.transaction.merchantUserId,
                attribute3: this.props.transaction.merchantName ? this.props.transaction.merchantName : "N/A",
                attribute4: this.props.transaction.transactionId,
                attribute5: this.props.transaction.depositAmount.toString(),
                attribute6: this.props.transaction.fiatCurrency,
                attribute7: symbol,
                device_type: browserInfo.mobile ? 'Mobile' : 'Desktop',
                browser_type: browserInfo.name,
                browser_version: browserInfo.version,
                language: navigator.language,
                ip_address: this.state.deviceIp,
            })
        }
        if (timeInterval) {
            clearInterval(timeInterval)
        }
        localStorage.setItem("startTime", moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
        localStorage.setItem("selectedCrypto", symbol)
        localStorage.setItem("depositAmount", depositAmount)
        localStorage.setItem("fiatAmount", fiatAmount)
        localStorage.setItem("cryptoImage", img)
        await this.updateTransactionMethod('crypto')
        this.props.navigate("/deposit")
    }

    getFiatCurrencySymbol = (fiatCurrency) => {
        switch (fiatCurrency) {
            case "USD":
                return "$";
            case "GBP":
                return "£";
            case "EUR":
                return "€";
            case "RUB":
                return "р.";
            case "JPY":
                return "¥";
            default:
                return fiatCurrency
        }
    }
    handleToggle = (paramMode) => {
        if (paramMode) {
            let res = paramMode == 'light' ? true : false
            sessionStorage.setItem("lightFlag", res);
            this.setState({ lightFlag: res })
            return
        }
        sessionStorage.setItem("lightFlag", !this.state.lightFlag);
        this.setState({ lightFlag: !this.state.lightFlag })
    }
    redirectToHome = () => {
        window.open("/")
    }
    goBack = () => {
        window.location.href = "/quickDeposit?uniqueId=" + localStorage.getItem("uniqueId");
    }
    handleChooseMethod = async (methodName) => {
        localStorage.setItem("depositMethod", methodName)
        if (methodName === "credit-card") {
            await this.updateTransactionMethod("card")
        }
        if (methodName === "credit-card" && this.state.cardInitialization === false) {
            let transaction = await this.getTransaction()
            await this.init(transaction, true);
        } else {
            this.setState({ depositMethod: methodName }, async () => {
                await this.getTransaction()
                if (methodName === "other-wallet") {
                    this.initializedInterval(this.state.showAll, this.props.transaction)
                }
            })
        }
        if (methodName === "cryptonpay-wallet") {
            await this.updateTransactionMethod("wallet")
        }
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value && e.target.value !== "") {
            let newList = this.state.allCryptosList.filter((cryptoObj) => {
                return (cryptoObj.name.toLowerCase().includes(e.target.value.toLowerCase()) || cryptoObj.symbol.toLowerCase().includes(e.target.value.toLowerCase()))
            });
            this.setState({ listOfCryptoToShow: newList })
        } else {
            this.setState({ listOfCryptoToShow: this.state.allCryptosList })
        }
    }
    handleChangeProviderName = (name, lngstr) => {
        const BaseName = sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"
        if (name.includes(BaseName)) {
            const newName = t(lngstr, name)
            return newName.replace(/walletname/g, BaseName)
        } else {
            return t(name)
        }
    }
    render() {
        const { t } = this.props;
        let methods = []
        if (this.state.userWalletDeposit) {
            methods.push({
                name: `${sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"} wallet`,
                logo: this.state.lightFlag ? '/common/logo_samll_light.svg' : '/common/logo_samll.svg',
                description: `Deposit instantly from your ${sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"} wallet with low fees`,
                features: ["Worldwide", "Low fees"],
                bgImage: "/home/homemainbg.png",
                onClickValue: "cryptonpay-wallet",
            })
        }

        methods.push({
            name: "Other exchange",
            logo: this.state.lightFlag ? '/common/park_other_light.png' : '/common/park_other.png',
            description: "Deposit from any other wallet or exchange.",
            onClickValue: "other-wallet",
        })

        if (this.state.useCreditCard) {
            methods.push({
                name: "Card",
                logo: this.state.lightFlag ? '/common/credit_card_icon_light.png' : '/common/credit_card_icon.png',
                description: "Deposit using card of choice.",
                onClickValue: "credit-card",
            })
        }
        if (this.state.hideAll) {
            return (<div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                <div className='loading_pay_main row' >
                    <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
                </div>
            </div>)
        } else {
            if (!this.state.depositMethod || this.state.depositMethod === "null" || localStorage.getItem("depositMethod") === "null") {
                return (<div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                    <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                    <div className="deposit_screen_wrap">
                        <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card deposit_card_gradient_light paddingBottom" : "deposit_card deposit_card_gradient paddingBottom"}>
                            <TopHeading />
                            <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                                <img className='cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                    window.close();
                                }} />
                                <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding paddingAndMinHeight"} >
                                    <span className={this.state.lightFlag ? "text_color_black" : ""} data-e2e="Deposit Method">{t("Deposit_Method")}</span>
                                </div>
                                <div className="d-flex ">
                                    <NavbarLang />
                                    <div className='landing-dark-toggle'><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                                </div>
                            </div>
                            <div className="deposit_card_body pb-0">
                                <div className="deposit_content_wrap">
                                    <div className={this.state.lightFlag ? "text_color_black select-how-you-would" : " select-how-you-would"}>{t("Payment_method")}</div>
                                </div>
                            </div>
                            {this.props.transaction && methods.map((method, i) => {
                                return (
                                    <div key={i} className='deposit-button'><button onClick={() => {
                                        this.handleChooseMethod(method.onClickValue);
                                    }} className={`dMethodRec ${this.state.lightFlag ? "dMethodRecLight text_color_black" : ""}`} style={{ backgroundImage: method.bgImage ? `url(${this.props.image_base_url}${method.bgImage})` : "none" }}>
                                        <img src={`${this.props.image_base_url}${method.logo}`} alt="cpay" className={`dMethodRecIcon ${method.name === "Card" ? "lessHieght" : ""}`} />
                                        <div className="dMethodTextWrap">
                                            <div className="dMethodTitleWrap">
                                                <div className={`dMethodTitle ${this.state.lightFlag ? "text_color_black" : ""}`} data-e2e={method.name}>{this.handleChangeProviderName(method.name, 'Deposit wallet')}</div>
                                                {method.features && <div className="dMethodFeatureWrap">
                                                    {method.features.map((fname, index) => {
                                                        return (<div key={index} className={`dMethodFeature ${this.state.lightFlag ? "dMethodFeatureLight text_color_black" : ""}`}>
                                                            <span>{fname} {fname == 'Low fees' ? <img className='lowest-fee' width={10} height={10} src={`${this.props.image_base_url}${"/homeb2c/bestratesicon.svg"}`} alt="bitcoin" /> : null} </span>
                                                        </div>)
                                                    })}
                                                </div>}
                                            </div>
                                            <div className={`dMethodDesc ${this.state.lightFlag ? "text_color_black" : ""}`}>{this.handleChangeProviderName(method.description, 'Deposit_info')}</div>
                                        </div>
                                    </button>
                                    </div>)
                            })}
                        </div>
                        <img className='background-bottom-mobile' src={`${this.props.image_base_url}/homeb2c/deposit-mobile-bottom.svg`} />
                    </div>
                </div>)
            } else {
                if (this.state.depositMethod === "cryptonpay-wallet") {
                    return (
                        <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                            <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                            <div className="deposit_screen_wrap">
                                <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"}>
                                    <TopHeading />
                                    <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"} >
                                        {this.state.quickDepositFlag ? <img className='cursor-pointer margin_right' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                            this.goBack();
                                        }} /> : <img className='cursor-pointer margin_right' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                            if (localStorage.getItem("methodSet") == "true") {
                                                return
                                            }
                                            localStorage.setItem("depositMethod", null)
                                            this.setState({ depositMethod: null })
                                        }} />}
                                        {localStorage.getItem("token") && (localStorage.getItem("loginType") === "B2C") ? null : <div className={this.state.lightFlag ? "text_color_black ml-5" : "ml-5"}>{`${t('Log In to')} ${sessionStorage.getItem("paymentProviderName") ? sessionStorage.getItem("paymentProviderName") : "Cryptonpay"} `}</div>}
                                        <div className="d-flex align-items-center">
                                            <NavbarLang />
                                            <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                                        </div>
                                    </div>
                                    <div className={` ${this.state.lightFlag ? "text_color_black" : ""}`} >
                                        {localStorage.getItem("token") && (localStorage.getItem("loginType") === "B2C") ? <Navigate to="/cryptopayWalletToDeposit" /> : <LoginScreen onSuccess={() => {
                                            this.props.navigate("/cryptopayWalletToDeposit");
                                        }} lightFlag={this.state.lightFlag} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else if (this.state.depositMethod === "other-wallet") {
                    return (
                        <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                            <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                            <div className="deposit_screen_wrap">
                                <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"}>
                                    <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                                        {<div className="d-flex align-items-center">
                                            {this.state.quickDepositFlag ? <img className='cursor-pointer margin_right' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                                this.goBack();
                                            }} /> : (this.state.userWalletDeposit === false && this.state.useCreditCard === false ? <div></div> : <img className='cursor-pointer margin_right' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                                if (localStorage.getItem("methodSet") == "true") {
                                                    return
                                                }
                                                localStorage.setItem("depositMethod", null)
                                                this.setState({ depositMethod: null })
                                            }} />)}
                                            <span className={` ${this.state.lightFlag ? "text_color_black" : ""}`}>{this.state.showEmailAddressPage ? t('Email Address') : t('Select Crypto')}</span>
                                        </div>}
                                        <div className="d-flex align-items-center">
                                            <NavbarLang />
                                            <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                                        </div>
                                    </div>
                                    <div className={`${this.state.lightFlag ? "text_color_black" : "text_color_white"}`} style={{ marginLeft: "30px" }}>{this.state.showEmailAddressPage ? t('Please input your email address to link it with your payment.') : t('Select which crypto you would like to deposit.')}</div>
                                    {this.state.showEmailAddressPage ? null : <div className={`searchBoxWrap ${this.state.lightFlag ? "searchBoxWrapLight" : ""} pb-1`}>
                                        <div className={`searchBar ${this.state.lightFlag ? "searchBarLight" : ""} pb-1`}>
                                            <i className={`fa fa-search searchIcon ${this.state.lightFlag ? "searchIconLight" : ""}`} />
                                            <input data-e2e="search-box" type="text" value={this.state.searchCryptoText} name="searchCryptoText" className={`searchInput ${this.state.lightFlag ? "searchInputLight" : ""}`} onChange={(e) => {
                                                this.handleChange(e)
                                            }} placeholder={t("Search by name")} />
                                        </div>
                                    </div>}
                                    {this.state.showEmailAddressPage ?
                                        <div className='EmailAddressPageWrap'>
                                            <div className='EmailAddressSection'>
                                                <div className={`${this.state.lightFlag ? "text_color_black" : "text_color_white"}`} style={{ fontWeight: "bold" }}>{t('Email')}</div>
                                                <input
                                                    className={`inputFieldEmail ${this.state.lightFlag ? "text_color_black" : "text_color_white"}`}
                                                    type="text" name="merchantUserEmail"
                                                    data-e2e="email-field"
                                                    value={this.state.merchantUserEmail}
                                                    placeholder='johndoe2@gmail.com'
                                                    onChange={(e) => {
                                                        this.handleMerchantUserEmailChange(e.target.value)
                                                    }}
                                                />
                                                {this.state.errorEmail != "" && <div style={{ color: "red", marginTop: "10px", width: "100%", textAlign: "center" }}>{this.state.errorEmail}</div>}
                                            </div>
                                            <div className='EmailAddressSection'>
                                                <div data-e2e="continue-btn"
                                                    className={`ANC_Continue_btn_email ${this.state.errorEmail != "" || !this.state.merchantUserEmail || this.state.merchantUserEmail === "" ? "ANC_Continue_btn_email_disabled" : ""}`}
                                                    onClick={async () => {
                                                        if (this.state.errorEmail === "" && this.state.merchantUserEmail.length > 0) {
                                                            const invoiceDetails = await this.createPaymentWithNewEmail(this.state.invoiceDetails.orderId, this.state.merchantUserEmail);
                                                            if (invoiceDetails) {
                                                                if (invoiceDetails.transactionId) {
                                                                    localStorage.setItem("transactionId", invoiceDetails.transactionId)
                                                                    this.getTransactionAndInitializeAll();
                                                                }
                                                            }
                                                        }
                                                    }}>{t('CONTINUE')}</div>
                                            </div>
                                        </div>
                                        : <div className="deposit_card_body deposit_card_body_cryptos"
                                            style={{ transition: "box-shadow 0.3s", paddingBottom: "10px", minHeight: "200px" }}>

                                            <div className="deposit_content_wrap">
                                                <div className={this.state.lightFlag ? "text_color_black token-name " : " token-name "}>{t('TOKEN NAME')}</div>
                                                {this.state.listOfCryptoToShow.map((CryptoObj, i) => {
                                                    if (CryptoObj) {
                                                        return (<SingleCryptoCard
                                                            image_base_url={this.props.image_base_url}
                                                            lightFlag={this.state.lightFlag}
                                                            key={i} index={i} data={{
                                                                ...CryptoObj,
                                                                description: ` ${CryptoObj.cryptocurrencyAmount ? CryptoObj.cryptocurrencyAmount.toString().substring(0, 11) : "N/A"}${CryptoObj.cryptocurrencyAmount ? (CryptoObj.cryptocurrencyAmount.toString().length > 11 ? ".." : 0) : ""} ${CryptoObj.symbol}`,
                                                                fiatAmount: `${this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}${CryptoObj.fiatAmount} `
                                                            }} onCardClick={this.onCardClick} />)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                                {!this.props.loading && this.state.listOfCryptoToShow.length === 0 && (this.state.searchCryptoText && this.state.searchCryptoText !== "") && <div className={this.state.lightFlag ? "text_color_black" : "text_color_white"} style={{ opacity: 0.5 }}>No token match with the current search.</div>}
                                                {!this.props.loading && this.state.listOfCryptoToShow.length === 0 && this.state.searchCryptoText === "" && <div className={this.state.lightFlag ? "text_color_black" : "text_color_white"} style={{ opacity: 0.5 }}>{t('Merchant acceptable crypto list is empty!')}</div>}
                                                {this.state.lodingExchangeRates && this.state.listOfCryptoToShow.length === 0 && <div className='loading_pay_main row' >
                                                    <ReactLoading type="spinningBubbles" color="#0091ff90" width={"120px"} />
                                                </div>
                                                }
                                            </div>
                                        </div>}
                                    <div className={`searchBoxWrap ${this.state.lightFlag ? "searchBoxWrapLight" : ""} mb-0 p-0`} style={{ height: "1px" }}></div>
                                    {/* <div className='deposit_card_body_footer deposit_card_body_footer_padding sendTokendeposit_card_body_footer' style={{ height: "76.5px" }}>
                                        <div className={`sendTokendeposit_card_body_footer_content ${this.state.lightFlag ? "text_color_black" : ""}`}>Powered by <img className='ml-2 cursor-pointer sendTokendeposit_card_body_footer_icon' src={`${this.props.image_base_url}${this.state.lightFlag ? logoLight : logoDark}`} alt="logo"
                                            onClick={() => {
                                                this.redirectToHome();
                                            }} /></div>
                                    </div> */}
                                </div>
                                {/* <div className='m-5 text-center'>
                                    <span className='powered_by_footer'>Powered by </span>
                                    <span className='crypton_pay_footer cursor-pointer' onClick={() => {
                                        this.redirectToHome();
                                    }}><img className='ml-2 cursor-pointer sendTokendeposit_card_body_footer_icon' src={`${this.props.image_base_url}${this.state.lightFlag ? logoLight : logoDark}`} alt="logo"
                                    onClick={() => {
                                        this.redirectToHome();
                                    }} /></span>
                                </div> */}
                            </div>
                        </div>
                    )
                } else if (this.state.depositMethod === "credit-card") {

                    return (
                        <div className={(this.state.lightFlag ? "background_color_radial_white" : "") + " deposit_screen_new"}>
                            <div className="transactionId" datatransactionid={this.state.transactionId} style={{ backgroundColor: 'transparent', color: 'transparent', position: 'absolute', width: '10px', height: '10px' }} />
                            <div className="deposit_screen_wrap">
                                <div className={this.state.lightFlag ? "background_color_white deposit_wrap_box_white deposit_card" : "deposit_card"} id={this.checkCoinsSpirit() ? "creditCardPaymentSummaryWidth" : ""}>
                                    {!this.state.loadingModalFlag && <div className={(this.state.lightFlag ? "text_color_black deposit_heading_wrap" : "deposit_heading_wrap") + " deposit_heading_padding"}>
                                        {this.state.quickDepositFlag ? <img className='cursor-pointer margin_right' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                            this.goBack();
                                        }} /> : (this.state.userWalletDeposit === false && this.state.useCreditCard === false ? <div></div> : <img className='cursor-pointer margin_right' src={`${this.props.image_base_url}${this.state.lightFlag ? back_icon_light : back_icon}`} alt="logo" onClick={(e) => {
                                            if (localStorage.getItem("methodSet") == "true") {
                                                return
                                            }
                                            if (this.state.deleteCard) {
                                                this.setState({ deleteCard: null })
                                                return
                                            }
                                            if (this.state.addNewCard && !this.state.emptyCardList) {
                                                this.setState({ addNewCard: false })
                                                return
                                            }
                                            localStorage.setItem("depositMethod", null)
                                            this.setState({ depositMethod: null })
                                        }} />)}
                                        <span className={`select-crypt-title deposit-credit-card-title ${this.state.lightFlag ? "text_color_black" : ""}`}>
                                            {this.checkCoinsSpirit() ? "" : this.state.deleteCard ? t("Remove card") : t("Deposit With Card")}
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <NavbarLang />
                                            <div><img className='float-right toggel_icon cursor-pointer' src={`${this.props.image_base_url}${this.state.lightFlag ? lightToggle : darkToggle}`} alt="logo" onClick={() => this.handleToggle()} /></div>
                                        </div>
                                    </div>}
                                    {(this.checkCoinsSpirit() || this.state.deleteCard || this.state.loadingModalFlag) ? null : <div className={`${this.state.lightFlag ? "text_color_black" : "text_color_white"}`} style={{ marginLeft: "30px" }}>{t('Amount')}: <b>{this.getFiatCurrencySymbol(this.props.transaction ? this.props.transaction.fiatCurrency : "USD")}{this.props.transaction ? this.props.transaction.depositAmount : 0} {this.props.transaction ? this.props.transaction.fiatCurrency : "USD"}</b></div>}
                                    {this.state.addNewCard ?
                                        <CreditCardPayment
                                            transactionId={this.state.transactionId}
                                            handleLoadingModal={() => this.setState({ loadingModalFlag: true })}
                                            fiatCurrency={this.props.transaction ? this.props.transaction.fiatCurrency : "USD"}
                                            fiatAmount={this.props.transaction ? this.props.transaction.depositAmount : 0}
                                            lightFlag={this.state.lightFlag} image_base_url={this.props.image_base_url} 
                                            fiatCurrencySymbol = {this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}
                                            cryptosWithExchangeRates={this.state.allCryptosList && this.state.allCryptosList && this.state.allCryptosList.filter((cryptoObj)=>cryptoObj.symbol === constants.USDT_CRYPTO_ID)}
                                            />
                                        : <SelectCreditCard
                                            transactionId={this.state.transactionId}
                                            fiatCurrency={this.props.transaction ? this.props.transaction.fiatCurrency : "USD"}
                                            fiatAmount={this.props.transaction ? this.props.transaction.depositAmount : 0}
                                            saveCardList={this.state.saveCardList}
                                            selectedCard={this.state.selectedCard}
                                            isAddNewCard={(value) => {
                                                this.setState({ addNewCard: value })
                                            }}
                                            handleLoadingModal={() => this.setState({ loadingModalFlag: true })}
                                            setEmptyCardListFlag={(value) => {
                                                this.setState({ emptyCardList: value })
                                            }}
                                            lightFlag={this.state.lightFlag}
                                            setDeleteCard={(card) => {
                                                this.setState({ deleteCard: card })
                                            }}
                                            deleteCard={this.state.deleteCard}
                                            deleteCardFormDB={(removeCardId, transactionIdOfCardToken) => { this.deleteCardFormDB(this.props.transaction, removeCardId, transactionIdOfCardToken) }}
                                            image_base_url={this.props.image_base_url} 
                                            fiatCurrencySymbol = {this.getFiatCurrencySymbol(this.props.transaction.fiatCurrency)}
                                            cryptosWithExchangeRates={this.state.allCryptosList && this.state.allCryptosList && this.state.allCryptosList.filter((cryptoObj)=>cryptoObj.symbol === constants.USDT_CRYPTO_ID)}
                                            />}
                                </div>
                                </div>
                            </div>
                    )
                }

            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        payment_api_url: state.config.payment_api_url,
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
        loading: state.common.loading,
        token: state.common.token,
        transaction: state.deposit.transaction,
        cardAPIType: state.deposit.cardAPIType,
        coinSpiritHostName: state.config.COIN_SPIRIT_HOST_NAME
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTransaction: (value) => dispatch(actions.setTransaction(value)),
        setToken: (data) => dispatch(actions.setToken(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectCryptoToDeposit));


const SingleCryptoCard = (props) => {
    const { name, img, description, cryptocurrencyAmount, symbol, fiatAmount } = props.data;
    const { onCardClick } = props;
    return (
        <button className="deposit_select_cryptoCard" data-e2e={name} onClick={(e) => {
            e.preventDefault()
            onCardClick(img, cryptocurrencyAmount, symbol, fiatAmount)
        }} >
            <img src={`${props.image_base_url}${img}`} className="deposit_select_crypto_img" />
            <div className="deposit_select_crypto_textWrap">
                <div className={props.lightFlag ? "text_color_black deposit_select_crypto_block" : "deposit_select_crypto_block"}>
                    <span className="deposit_select_crypto_name">{name}</span>
                    <span className='deposit_select_crypto_name deposit_align_right'> {fiatAmount}</span>
                </div>
                <div className={props.lightFlag ? "text_color_black deposit_select_crypto_block" : "deposit_select_crypto_block"}>
                    <span className="description">{symbol}</span>
                    <span className='description deposit_align_right'>{description}</span>
                </div>
            </div>
        </button>
    )
}
const TopHeading = () => {
    const { t } = useTranslation()
    return (<div className="deposit_topHeading_wrap" >
        <div className="deposit_topHeading_title" >
            {t("Make_deposit")}
        </div>
        <ClearIcon onClick={() => {
            localStorage.removeItem("depositMethod")
            window.close();
        }} className="deposit_topHeading_close" />
    </div>
    )
}
