import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import localDb from "../../../../common/localDb";

export default function PaysuSteps(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section">
			<div className="content-container">
				<div className="paysu-steps">
					<div className="paysu-steps-block">
						<p className="paysu-steps-num">1.</p>
						<div className="paysu-steps-icon has-before-el">
							<img width="96" height="96" src={`${image_base_url}/paysu/ps-step-1.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Sign up</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Register online and get started with access to our multi-currency crypto wallet.</p>
						</AnimationOnScroll>
					</div>
					<div className="paysu-steps-block">
						<p className="paysu-steps-num">2.</p>
						<div className="paysu-steps-icon has-before-el">
							<img width="96" height="96" src={`${image_base_url}/paysu/ps-step-2.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Verify account</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Verify your identity in minutes using our automated verification system and get instant approval.</p>
						</AnimationOnScroll>
					</div>
					<div className="paysu-steps-block">
						<p className="paysu-steps-num">3.</p>
						<div className="paysu-steps-icon">
							<img width="96" height="96" src={`${image_base_url}/paysu/ps-step-3.svg`} />
						</div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="paysu-steps-title">Integrate</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeIn">
							<p className="paysu-steps-text">Contact us to discuss your integration or, if you wish, go ahead and utilise our APIs in your store&nbsp; 
								<Link to="/merchantTools/APIGuide">here.</Link>
							</p>
						</AnimationOnScroll>
					</div>
				</div>
				<div className="theme-btn-block center">
					{
						!localDb.getVal("token") && <button className="paysu-btn" onClick={() => {
							props.showModal();
						}}>Get started</button>
					}
				</div>
			</div>
		</section>
	)
}