import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import * as actions from '../../../Redux/actions';
import { clearStorageData } from '../../../common/getIP';
import localDb from '../../../common/localDb';
import { setTheme } from '../../../common/theme';
import ForgotPassword from '../../Pages/ForgotPassword/ForgotPassword';
import Login from '../../Pages/Login/Login';
import AuthenticatorModal from '../../Pages/Merchant2FA/AuthenticatorModal';
import VerificationModal from '../../Pages/Merchant2FA/VerificationModal';
import Signup from '../../Pages/Signup/Signup';
// const logo = '/common/cryptonpay_logo.svg';
const profileicon = '/common/crytoicons/profileicon.svg';
const profileLight = '/common/crytoicons/profile_light.svg';
const navListLogedIn = [
  {
    name: 'Merchant Tools',
    path: '/merchantTools/APIGuide',
  },
];

const profieItemList = [
  {
    name: 'Transactions',
    path: '/myAccount/transactions',
  },
  {
    name: 'Settings',
    path: '/myAccount/settings',
  },
  {
    name: 'API Keys',
    path: '/myAccount/apiKeys',
  },
  {
    name: 'Merchant Tools',
    path: '/merchantTools/APIGuide',
  },
  {
    name: 'Change Password',
    path: '/ChangePassword',
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: 'login',
      showModal: false,
      showMemu: false,
      path: null,
      merchantName: null,
      show2FAVerificationModal: false,
      showAuthenticatorModal: false,
      imageData: null,
      verificationCode2fa: '',
      merchantData: null,
      merchantEmail: '',
      paymentProviderId: null,
      paymentProviderIdName: null,
      domainId: null,
      paymentProviderName: null,
      colorMode: null,
      bodyDarkTextColour: null,
      bodyLightTextColour: null,
      headerDarkBgColour: null,
      headerLightBgColour: null,
      headerButtonDarkBgColour: null,
      headerButtonLightBgColour: null,
      headerDarkTextColour: null,
      headerLightTextColour: null,
      headerLightLinkColour: null,
      headerDarkLinkColour: null,
      themeToggleButton: null,
      loginStatus: false,
      logoWidth: 150,
      passwordChangedSuccessful: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname === '/loginPopup') {
      this.setState({setModalType: 'login', passwordChangedSuccessful: true, showModal: true});
    }
    this.setState({ loginStatus: true })
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      this.setState({ paymentProviderName: sessionStorage.getItem("paymentProviderName") });
    }
    if (window.location.pathname === '/faq-home') {
      localStorage.setItem('faqType', 'Merchant');
    }
    if (window.location.pathname === '/faq-b2c-home') {
      localStorage.setItem('faqType', 'B2C');
    }

    this.setTokenValue();
    this.redirect();
    this.setState({ path: window.location.pathname });
    if (localStorage.getItem('merchantName')) {
      this.setState({ merchantName: localStorage.getItem('merchantName') });
    }
    if (sessionStorage.getItem("domainId") && sessionStorage.getItem("domainId") !== "" && sessionStorage.getItem("domainId") !== "null") {
      this.setState({ domainId: sessionStorage.getItem("domainId") });
    }
    if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== "" && sessionStorage.getItem("paymentProviderId") !== "null") {
      this.setState({ paymentProviderId: sessionStorage.getItem("paymentProviderId") });
    }
    if (sessionStorage.getItem("colorMode") && sessionStorage.getItem("colorMode") !== "" && sessionStorage.getItem("colorMode") !== "null") {
      this.setState({ colorMode: sessionStorage.getItem("colorMode") });
    }
    if (sessionStorage.getItem("bodyDarkTextColour") && sessionStorage.getItem("bodyDarkTextColour") !== "" && sessionStorage.getItem("bodyDarkTextColour") !== "null") {
      this.setState({ bodyDarkTextColour: sessionStorage.getItem("bodyDarkTextColour") });
    }
    if (sessionStorage.getItem("bodyLightTextColour") && sessionStorage.getItem("bodyLightTextColour") !== "" && sessionStorage.getItem("bodyLightTextColour") !== "null") {
      this.setState({ bodyLightTextColour: sessionStorage.getItem("bodyLightTextColour") });
    }
    if (sessionStorage.getItem("headerDarkBgColour") && sessionStorage.getItem("headerDarkBgColour") !== "" && sessionStorage.getItem("headerDarkBgColour") !== "null") {
      this.setState({ headerDarkBgColour: sessionStorage.getItem("headerDarkBgColour") });
    }
    if (sessionStorage.getItem("headerLightBgColour") && sessionStorage.getItem("headerLightBgColour") !== "" && sessionStorage.getItem("headerLightBgColour") !== "null") {
      this.setState({ headerLightBgColour: sessionStorage.getItem("headerLightBgColour") });
    }
    if (sessionStorage.getItem("headerButtonDarkBgColour") && sessionStorage.getItem("headerButtonDarkBgColour") !== "" && sessionStorage.getItem("headerButtonDarkBgColour") !== "null") {
      this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
    }
    if (sessionStorage.getItem("headerButtonLightBgColour") && sessionStorage.getItem("headerButtonLightBgColour") !== "" && sessionStorage.getItem("headerButtonLightBgColour") !== "null") {
      this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
    }
    if (sessionStorage.getItem("headerDarkTextColour") && sessionStorage.getItem("headerDarkTextColour") !== "" && sessionStorage.getItem("headerDarkTextColour") !== "null") {
      this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
    }
    if (sessionStorage.getItem("headerLightTextColour") && sessionStorage.getItem("headerLightTextColour") !== "" && sessionStorage.getItem("headerLightTextColour") !== "null") {
      this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
    }
    if (sessionStorage.getItem("headerLightLinkColour") && sessionStorage.getItem("headerLightLinkColour") !== "" && sessionStorage.getItem("headerLightLinkColour") !== "null") {
      this.setState({ headerLightLinkColour: sessionStorage.getItem("headerLightLinkColour") });
    }
    if (sessionStorage.getItem("headerDarkLinkColour") && sessionStorage.getItem("headerDarkLinkColour") !== "" && sessionStorage.getItem("headerDarkLinkColour") !== "null") {
      this.setState({ headerDarkLinkColour: sessionStorage.getItem("headerDarkLinkColour") });
    }
    if (sessionStorage.getItem("themeToggleButton") && sessionStorage.getItem("themeToggleButton") !== "" && sessionStorage.getItem("themeToggleButton") !== "null") {
      this.setState({ themeToggleButton: sessionStorage.getItem("themeToggleButton") });
    }
    if (sessionStorage.getItem("logoWidth") && sessionStorage.getItem("logoWidth") !== "" && sessionStorage.getItem("logoWidth") !== "null") {
      this.setState({ logoWidth: sessionStorage.getItem("logoWidth") });
    }
    if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
      this.setState({ paymentProviderIdName: sessionStorage.getItem("paymentProviderName") });
      document.title = sessionStorage.getItem("paymentProviderName")

    } else {
      if (localStorage.getItem("reloadedOnce") !== "Yes") {

        setTimeout(() => {
          localStorage.setItem("reloadedOnce", "Yes")
          window.location.reload();
        }, 1000)
      }
    }
  }

  setTokenValue = () => {
    if (localStorage.getItem('token')) {
      this.props.setToken(localStorage.getItem('token'));
    }
  };

  redirect = () => {
    if (localStorage.getItem('loginType') === "B2C") {
      if (window.location.pathname !== '/cookies-policy') {
        console.log('Header.js redirecting to b2c.....', localStorage.getItem('loginType'));
        this.props.navigate('/home-b2c');
      }
    }
    if (!localStorage.getItem('token')) {
      if (
        window.location.pathname.indexOf('/deposit') === -1 &&
        window.location.pathname.indexOf('/cryptoToDeposit') === -1 &&
        window.location.pathname.indexOf('/aboutUs') === -1 &&
        window.location.pathname.indexOf('/forgotPassword') === -1 &&
        window.location.pathname.indexOf('/loginPopup') === -1 &&
        window.location.pathname.indexOf("/faq-home") === -1 &&
        window.location.pathname.indexOf("/terms-of-use") === -1 &&
        window.location.pathname.indexOf("/faq-details") === -1 &&
        window.location.pathname !== '/home-merchant' &&
        window.location.pathname !== '/paysu-home-merchant' &&
        window.location.pathname !== '/paysu-home-merchant/' &&
        window.location.pathname !== '/kalonpay-home-merchant' &&
        window.location.pathname !== '/kalonpay-home-merchant' &&
        window.location.pathname !== '/cookies-policy' &&
        window.location.pathname !== "/"
      ) {
        console.log('Header.js redirecting.....');
        this.props.navigate('/home-merchant');
      }
    }
  };

  logout = () => {
    const colorMode = sessionStorage.getItem("colorMode");
    const b2cColorMode = sessionStorage.getItem("b2cColorMode");
    const depositMerchantId = localStorage.getItem('depositMerchantId');
    const depositMethod = localStorage.getItem('depositMethod');
    const transactionId = localStorage.getItem('transactionId');
    const lightFlag = sessionStorage.getItem("lightFlag");
    const activity_tracking_token = localStorage.getItem('activity_tracking_token');
    const activity_tracking_api_url = localStorage.getItem('activity_tracking_api_url');
    const api_url = localStorage.getItem('api_url');
    this.props.setToken(null);
    clearStorageData();
    localStorage.setItem('reloadedOnce', "Yes");
    sessionStorage.setItem("colorMode", colorMode);
    sessionStorage.setItem("b2cColorMode", b2cColorMode);
    localStorage.setItem('depositMerchantId', depositMerchantId);
    localStorage.setItem('depositMethod', depositMethod);
    localStorage.setItem('transactionId', transactionId);
    sessionStorage.setItem("lightFlag", lightFlag);
    localStorage.setItem('activity_tracking_token', activity_tracking_token);
    localStorage.setItem('activity_tracking_api_url', activity_tracking_api_url);
    localStorage.setItem('api_url', api_url);
    // reloading to refresh fonts
    window.location.href = '/home-merchant';
    // window.location.reload();
  };

  componentDidUpdate = () => {
    this.setTokenValue();
    this.redirect();
    if (window.location.pathname !== this.state.path) {
      this.setState({ path: window.location.pathname });
    }
    if (localStorage.getItem('merchantName') && localStorage.getItem('merchantName') !== this.state.merchantName) {
      this.setState({ merchantName: localStorage.getItem('merchantName') });
    }
  };

  changeModalType = (type) => {
    this.setState({ modalType: type });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleThemeValue = () => {
    if (localDb.getSSVal("colorMode") === 'dark') {
      setTheme('light');
      this.props.setAndGetTheme('light');
      window.location.reload()
    } else {
      setTheme('dark');
      this.props.setAndGetTheme('dark');
      window.location.reload()
    }
  };

  onVerificationSucess = (data, user_name) => {
    localStorage.setItem("token", data.token)
    if (!sessionStorage.getItem("colorMode") || sessionStorage.getItem("colorMode") === "") {
      sessionStorage.setItem("colorMode", 'light')
    }
    if (data.is2faActivated && data.is2faActivated.secret) {
      if (data.is2faActivated.secret != null) {
        localStorage.setItem("2FAStatus", true);
      } else {
        localStorage.setItem("2FAStatus", false);
      }
    }
    if (data.merchantId) {
      localStorage.setItem("merchantId", data.merchantId)
    }
    if (data.merchantName) {
      localStorage.setItem("merchantName", data.merchantName)
    }
    if (data.merchant_Id) {
      localStorage.setItem("merchant_Id", data.merchant_Id)
    }
    localStorage.setItem("merchantWalletActivation", data.merchantWalletActivation ? data.merchantWalletActivation : false)
    this.props.setToken(data.token)
    this.closeModal();
    localStorage.setItem("loginType", "Merchant")
    localStorage.setItem("user_name", user_name)
    localStorage.setItem("viewPermissionOnly",data?.viewPermissionOnly)
    if (localStorage.getItem("merchantWalletActivation") && localStorage.getItem("merchantWalletActivation") != "false") {
      window.location.href = '/myAccount/balances'
    } else {
      window.location.href = '/myAccount/transactions'
    }
  }
  render() {
    const token = this.props.token;
    const props = this.props;
    const navList = token ? navListLogedIn : [];
    const logo = this.props.theme === 'light' ? '/homeb2c/cryptoLightLogo.png' : '/common/cryptonpay_logo.svg';
    const themeLogo = this.props.theme === 'light' ? <DarkModeTwoToneIcon style={{ color: 'white' }} /> : <LightModeTwoToneIcon style={{ color: 'white' }} />;
    let headerStyle = {}
    let headerButtonStyle = {}
    let headerButtonColorStyle = {}
    let headerButtonBorderStyle = {}
    let headerLinkStyle = {}
    if (this.state.colorMode === "dark") {
      if (this.state.headerDarkBgColour && this.state.headerDarkBgColour !== "") {
        headerStyle.backgroundColor = this.state.headerDarkBgColour;
      }
      if (this.state.headerButtonDarkBgColour && this.state.headerButtonDarkBgColour !== "") {
        headerButtonStyle.backgroundColor = this.state.headerButtonDarkBgColour;
        headerButtonColorStyle.color = this.state.headerButtonDarkBgColour;
        headerButtonStyle.borderColor = this.state.headerButtonDarkBgColour;
        headerButtonBorderStyle.borderColor = this.state.headerButtonDarkBgColour;
      }
      if (this.state.headerDarkTextColour && this.state.headerDarkTextColour !== "") {
        headerButtonStyle.color = this.state.headerDarkTextColour;
      }
      if (this.state.headerDarkLinkColour && this.state.headerDarkLinkColour !== "") {
        headerLinkStyle.color = this.state.headerDarkLinkColour;
      }
    } else {
      if (this.state.headerLightBgColour && this.state.headerLightBgColour !== "") {
        headerStyle.backgroundColor = this.state.headerLightBgColour;
      }
      if (this.state.headerButtonLightBgColour && this.state.headerButtonLightBgColour !== "") {
        headerButtonStyle.backgroundColor = this.state.headerButtonLightBgColour;
        headerButtonColorStyle.color = this.state.headerButtonLightBgColour;
        headerButtonStyle.borderColor = this.state.headerButtonLightBgColour;
        headerButtonBorderStyle.borderColor = this.state.headerButtonLightBgColour;
      }
      if (this.state.headerLightTextColour && this.state.headerLightTextColour !== "") {
        headerButtonStyle.color = this.state.headerLightTextColour;
      }
      if (this.state.headerLightLinkColour && this.state.headerLightLinkColour !== "") {
        headerLinkStyle.color = this.state.headerLightLinkColour;
      }
    }
    return (
      <div className={`CP_header_main ${this.state.path === '/home-merchant' ? 'CP_header_main_animation' : ''}`} style={props.style ? { ...props.style, ...headerStyle } : { ...headerStyle }}>
        <div className="CP_header_main_bg">
          <div className="container">
            <nav className={`CP_nav_main ${this.state.path === '/home-merchant' ? 'CP_nav_main_animation' : ''}`}>
              {
                this.state.paymentProviderId ? <img
                  src={`${props.image_base_url}${this.state.paymentProviderId && this.state.paymentProviderId !== "" && this.state.paymentProviderId !== "null" ? `/payment-provider/${this.state.paymentProviderId}${this.state.domainId ? ("/" + this.state.domainId) : ""}/logo${this.props.theme === 'light' ? "" : "dark"}.png` : logo}`}
                  width={this.state.logoWidth}
                  className={'logo__dark cursor-pointer'}
                  onClick={() => {
                    this.props.navigate('/home-merchant');
                  }}
                  data-e2e="cryptonPay"
                /> : <div className='header__logo'>
                  <img
                    src={`${props.image_base_url}${this.props.theme === 'light' ? "/homeb2c/cryptoLightLogo.png" : "/common/cryptonpay_logo.svg"}`}
                    width={this.state.logoWidth}
                    className={'logo__dark cursor-pointer'}
                    onClick={() => {
                      this.props.navigate('/home-merchant');
                    }}
                    data-e2e="cryptonPay"

                  />
                </div>
              }
              <div className="CP_nav_main_wrap">
                <div className="hideOnMobile">
                  <div className="CP_nav_wrap">
                    {token && (
                      <div className="navItemAndSeparatorWrap">
                        <div
                          className={`navItemWrap cursor-pointer ${this.state.path && this.state.path.indexOf('myAccount/') != -1 ? 'navItemWrapActive' : ''}`}
                        >
                          <Link data-e2e="partyPoker" to={localStorage.getItem("merchantWalletActivation") && localStorage.getItem("merchantWalletActivation") !== "false" ? '/myAccount/balances' : '/myAccount/transactions'} style={{ width: 'auto' }}>
                            <button style={{ ...(this.state.path && this.state.path.indexOf('myAccount/') != -1 ? {} : headerLinkStyle) }}>{this.state.merchantName ? this.state.merchantName : 'Merchant'}</button>
                          </Link>
                        </div>
                      </div>
                    )}
                    {navList.map((navItem, index) => {
                      return (
                        <NavItem
                          key={index}
                          headerLinkStyle={this.state.path &&
                            this.state.path.indexOf(
                              [
                                '/merchantTools/APIGuide',
                                '/merchantTools/Authentication',
                                '/merchantTools/CreatePayment',
                                '/merchantTools/CreatePayout',
                                '/merchantTools/GetTransactions',
                                '/merchantTools/Webhooks',
                                '/merchantTools/ErrorHandling',
                              ].includes(navItem.path)
                                ? '/merchantTools'
                                : navItem.path,
                            ) != -1
                            ? {} : headerLinkStyle}
                          navItem={navItem}
                          index={index}
                          activeClass={`${this.state.path &&
                            this.state.path.indexOf(
                              [
                                '/merchantTools/APIGuide',
                                '/merchantTools/Authentication',
                                '/merchantTools/CreatePayment',
                                '/merchantTools/CreatePayout',
                                '/merchantTools/GetTransactions',
                                '/merchantTools/Webhooks',
                                '/merchantTools/ErrorHandling',
                              ].includes(navItem.path)
                                ? '/merchantTools'
                                : navItem.path,
                            ) != -1
                            ? 'navItemWrapActive'
                            : ''
                            }`}
                        />
                      );
                    })}
                    {!token && this.state.loginStatus && (
                      <div className="navItemAndSeparatorWrap">
                        {/* <div className="navSaparator"></div> */}
                        <div className="navItemWrap cursor-pointer signUp__button">
                          <button
                            style={{ ...headerLinkStyle }}
                            onClick={() => {
                              this.changeModalType('signup');
                              this.setState({ showModal: true });
                            }}
                            data-e2e="SignUP"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {token ? (
                  <MediaQuery minWidth={481}>
                    <ProfileOptionsIcons
                      themeToggleButton={this.state.themeToggleButton}
                      headerButtonStyle={headerButtonStyle}
                      headerButtonColorStyle={headerButtonColorStyle}
                      headerButtonBorderStyle={headerButtonBorderStyle}
                      logout={this.logout}
                      image_base_url={props.image_base_url}
                      themeLogo={themeLogo}
                      theme={this.props.theme}
                      handleThemeValue={this.handleThemeValue}
                      redirectToPath={(path) => {
                        this.props.navigate(path);
                      }}
                    />
                  </MediaQuery>
                ) : this.state.loginStatus && <div className="CP_LoginSignup_wrap">
                  <div
                    className="CP_loginButton"
                    style={{ ...headerButtonStyle }}
                    onClick={() => {
                      this.changeModalType('login');
                      this.setState({ showModal: true });
                    }}
                    data-e2e="LogIn"
                  >
                    Log In
                  </div>
                  {this.state.themeToggleButton === "Disabled" ? null : <div style={{ ...headerButtonStyle, marginLeft: '25px' }} className="moon-theme" onClick={() => this.handleThemeValue()}>
                    {themeLogo}
                  </div>}
                </div>
                }
                <MediaQuery maxWidth={480}>
                  <div
                    className="CP_menu_toggler"
                    onClick={() => {
                      this.setState({ showMemu: true });
                    }}
                  >
                    <i className="fa fa-bars" />
                  </div>
                </MediaQuery>
              </div>
            </nav>
          </div>
          {this.state.showModal && (
            <div>
              {this.state.modalType === 'login' ? (
                <Login show={true} changeModalType={this.changeModalType} closeModal={this.closeModal}
                  setVerifyModal={(data, email) => {
                    this.setState({ show2FAVerificationModal: true, merchantData: data, merchantEmail: email })
                  }}
                  setAuthenticatorModal={(data, email) => {
                    this.setState({ showAuthenticatorModal: true, merchantData: data, merchantEmail: email })
                  }}
                  onVerificationSucess={this.onVerificationSucess} />
              ) : this.state.modalType === 'signup' ? (
                <Signup title={`Sign Up to ${this.state.paymentProviderName ? this.state.paymentProviderName : "Cryptonpay"}`} show={true} changeModalType={this.changeModalType} closeModal={this.closeModal} />
              ) : (
                <ForgotPassword title={'Forgot Password?'} show={true} changeModalType={this.changeModalType} closeModal={this.closeModal} />
              )}
            </div>
          )}
          {this.state.show2FAVerificationModal &&
            <VerificationModal setLoading={this.props.setLoading}
              open={this.state.show2FAVerificationModal}
              onClose={() => this.setState({ show2FAVerificationModal: false })}
              onVerificationSucess={this.onVerificationSucess}
              merchantData={this.state.merchantData}
              merchantEmail={this.state.merchantEmail}
            />
          }
          {this.state.showAuthenticatorModal &&
            <AuthenticatorModal setLoading={this.props.setLoading} open={this.state.showAuthenticatorModal}
              onClose={() => this.setState({ showAuthenticatorModal: false })}
              merchantData={this.state.merchantData}
              merchantEmail={this.state.merchantEmail}
              onVerificationSucess={this.onVerificationSucess}
              component={"head"}
            />
          }
        </div>
        {this.state.showMemu && (
          <MediaQuery maxWidth={480}>
            <div className="CP_menu_wrap">
              <div className="container">
                <div className="CP_menu_header">
                  <img
                    src={`${props.image_base_url}${this.state.paymentProviderId && this.state.paymentProviderId !== "" && this.state.paymentProviderId !== "null" ? `/payment-provider/${this.state.paymentProviderId}${this.state.domainId ? ("/" + this.state.domainId) : ""}/logo${this.props.theme === 'light' ? "" : "dark"}.png` : logo}`}
                    className="CP_logo cursor-pointer"
                    onClick={() => {
                      this.props.navigate('/home-merchant');
                    }}
                    data-e2e="cryptonPay"
                  />
                  <div
                    className="CP_menu_toggler"
                    onClick={() => {
                      this.setState({ showMemu: false });
                    }}
                  >
                    <i className="fa fa-times" />
                  </div>
                </div>
                <div className="CP_menu_list_wrapper">
                  {(token ? profieItemList : navList).map((navItem, index) => {
                    return (
                      <div
                        key={index}
                        className={`CP_menu_ItemWrap`}
                        onClick={() => {
                          this.setState({ showMemu: false });
                        }}
                      >
                        <Link to={navItem.path}>
                          <button style={{ ...headerLinkStyle }}>{navItem.name}</button>
                        </Link>
                      </div>
                    );
                  })}
                  {token ? (
                    <div
                      className={`CP_menu_ItemWrap`}
                      onClick={() => {
                        this.setState({ showMemu: false });
                        this.logout();
                      }}
                    >
                      {' '}
                      <button>Log Out</button>
                    </div>
                  ) : (
                    [
                      <div
                        className={`CP_menu_ItemWrap`}
                        onClick={() => {
                          this.changeModalType('signup');
                          this.setState({
                            showModal: true,
                            showMemu: false,
                          });
                        }}
                      >
                        <button>Sign Up</button>
                      </div>,
                      <div
                        className={`CP_menu_ItemWrap`}
                        onClick={() => {
                          this.changeModalType('login');
                          this.setState({
                            showModal: true,
                            showMemu: false,
                          });
                        }}
                      >
                        <button>Log In</button>
                      </div>,
                    ]
                  )}
                </div>
              </div>
            </div>
          </MediaQuery>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.common.token,
    image_base_url: state.config.image_base_url,
    theme: state.common.theme,
    api_url: state.config.api_url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (data) => dispatch(actions.setToken(data)),
    setAndGetTheme: (data) => dispatch(actions.setAndGetTheme(data)),
    setLoading: (data) => dispatch(actions.setLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)((props)=><Header {...props} navigate={useNavigate()} />);

const NavItem = (props) => {
  const { path, name } = props.navItem;
  return (
    <div className="navItemAndSeparatorWrap">
      {/* {props.index !== 0 && <div className="navSaparator"></div>} */}
      <div className={`navItemWrap cursor-pointer ${props.activeClass}`}>
        <Link to={path} style={{ width: 'auto' }}>
          <button style={{ ...props.headerLinkStyle }} data-e2e={name}>{name}</button>
        </Link>
      </div>
    </div>
  );
};

const ProfileOptionsIcons = (props) => {
  const [isProfileDropdownOpen, changeDropdownFlag] = useState(false);

  return (
    <div className="CP_nav_profileOption_wrap">
      {
        <div className="profileNav" style={{ ...props.headerButtonBorderStyle }}
          onClick={() => {
            changeDropdownFlag(!isProfileDropdownOpen);
          }}
        >
          <i className="fa fa-user" style={{ ...props.headerButtonColorStyle }} />
        </div>
      }
      {props.themeToggleButton === "Disabled" ? null : <div style={{ ...props.headerButtonStyle, marginLeft: '25px', height: '35px', width: '35px' }} className="moon-theme" onClick={() => props.handleThemeValue()}>
        {props.themeLogo}
      </div>}
      <ProfileOptionsModal
        logout={props.logout}
        isProfileDropdownOpen={isProfileDropdownOpen}
        redirectToPath={props.redirectToPath}
        handleClose={() => {
          changeDropdownFlag(!isProfileDropdownOpen);
        }}
      />
    </div>
  );
};

const ProfileOptionsModal = (props) => {
  return (
    <div>
      <Modal
        show={props.isProfileDropdownOpen}
        className="CP_profile_modal"
        onHide={() => {
          props.handleClose();
        }}
      >
        <Modal.Body className="CP_profile_modal_container">
          <style>{`\
              .modal-backdrop.show { opacity: 0 }\
              body {
                width: 100vw;
              }
          `}</style>
          {profieItemList.map((item, i) => {
            return (
              <div
                key={i}
                className="CP_profileitem_wrap  cursor-pointer"
                onClick={() => {
                  props.handleClose();
                  props.redirectToPath(item.path);
                }}
                data-dismiss="modal"
                data-e2e={i}
              >
                <span className="CP_profileitem_Name">{item.name}</span>
              </div>
            );
          })}
          <div onClick={() => props.logout()} className="CP_profileitem_wrap cursor-pointer" data-dismiss="modal" style={{ marginBottom: '2px' }}>
            <span className="CP_profileitem_Name" data-e2e="log-out">
              Log Out
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
