import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { apiService } from "../../../../common/apiCallService";
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    minWidth: "350px",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const KalonPayContactForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [contactModal, setContactModal] = useState(false)
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [name, setName] = useState("")
    const [error, setError] = useState(null)
    const api_url = useSelector((state) => state.config.api_url)
    const handleSubmit = () => {
        if (!email || email === '') {
            setError("Email is required")
        }
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/contactus',
            {
                email: email,
                name: name,
                message: message,
                paymentProvider: sessionStorage.getItem("paymentProviderName"),
                paymentProviderId: sessionStorage.getItem("paymentProviderId")
            },
            (data) => {
                if (data) {
                    setContactModal(false)
                    setEmail('')
                    setName('')
                    setMessage('')
                    setLoading(false);
                }
            },
            (err) => {
                setLoading(false);
                alert('Something went wrong!');
                console.log(err);
            },
        );
    }

    return (
        <div>
            <button onClick={() => setContactModal(true)} className='kalonpay-border-button'>Contact Us</button>
            {contactModal && <Modal open={contactModal}
                onClose={() => setContactModal(false)} className='kalonpay-modal'>
                <Box sx={style} >
                    <div className='contact-form-container'>
                        <div onClick={() => setContactModal(false)} style={{ color: "black", float: "right" }}> <CloseIcon /></div>
                        <div className='main-text'>Contact Form</div>
                        <div className=''>
                            <p style={{ color: "red" }}>{error}</p>
                            <div className='kal-input-container'>
                                <input placeholder='Name' type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='kal-input-container'>
                                <input placeholder='Email Address' type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='kal-input-container'>
                                <textarea placeholder='Message' name="" id="" cols="30" rows="10" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            <div style={{ marginTop: "10px" }} className='kal-input-container'>
                                <button onClick={() => handleSubmit()} className='kalonpay-btn'>Send Message</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>}
        </div>

    )
}

export default KalonPayContactForm