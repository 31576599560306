export const getTermsData = (host, paymentProviderName) => {
  const allData = {
    "coinsspirit": [
      {
        heading: 'Who we are and how to contact us',
        description: `These terms and conditions (“Terms” or “Agreement”) govern the use of the Services (as
                  defined below), which are provided by CPAD SERVICES SPÓŁKA Z OGRANICZONĄ
                  ODPOWIED (trading as CoinsSpirit) a company incorporated under the laws of Poland with
                  registered number 0001022832 and having its registered office at Ul Hoza, No. 86, apt. 210
                  seats, 00-682, WARSAW, POL, (“CoinsSpirit”, “Company”, “we”, “us” or “our”) to any person
                  whose application we approve (“you” or “your”).`,
      },
      {
        space: true,
      },
      {
        description: `To contact us, please email: info@coinsspirit.com`,
      },
      {
        space: true,
      },
      {
        heading: 'By using our site, you accept these Terms and Conditions',
        description: `By activating your CoinsSpirit Account (as defined below) with us, you confirm that you
              have read, understood, and agree to these Terms. We recommend that you print a copy
              of these terms for future reference and check this page regularly to stay up to date with
              any changes to these terms. \n\n`,
      },
      {
        description: `There are other Terms that apply to you.`,
      },
      {
        description: `These Terms refer to the following additional terms, which also apply to your use of the
              Services and should be read in conjunction with these Terms, along with any other
              additional terms we may publish from time to time: `,
      },
      {
        description: `Our Terms, which outlines some of the most common risks of trading cryptocurrencies which
              you must read before deciding to make a Digital Currency Transaction.`,
      },
      {
        description: `Our Privacy Policy, which sets out the terms on which we process your personal data.
              You agree that any and all personal data provided by you is accurate and complete.`,
      },
      {
        description: `Our Cookie Notice, relevant to web access to CoinsSpirit Software is found on the
              CoinsSpirit website which sets out information about the cookies used on our website.`,
      },
      {
        description: `Our Fee Schedule, which sets out the terms on which we will charge Fees for the use
              of our Services and any other fees that may apply.`,
      },
      {
        description: `By agreeing to these Terms, you agree that you have read, understood, and accept
              these Terms as well as our Privacy Policy, Cookie Notice and Fee Schedule, and you
              acknowledge and agree that you will be bound by such terms, policies and notices.
              These Terms, together with the additional terms above, constitute the entire agreement
              and understanding with respect to the access or use of any or all of the Software and/or
              Services. These Terms may be accepted electronically, and you understand and agree
              that such acceptance shall be deemed to be as binding as an original signature being
              signed to these Terms by you.`,
      },
      {
        space: true,
      },
      {
        heading: 'Definitions and Interpretation',
        description: `1.1 In these Terms, capitalised words and expressions have the following meanings,
              unless otherwise stated:`,
      },
      {
        heading: 'Applicable Laws',
        description: `means any applicable statutes, laws, ordinances, orders, judgments,
              decrees, rules or regulations issued by any government authority, and any judicial or
              administrative interpretation of any of these;`,
      },
      {
        heading: 'Available Crypto Balance',
        description: `means Digital Currency which is deposited into a wallet
              within a CoinsSpirit Account;`,
      },
      {
        heading: 'Business Day',
        description: `means a day (other than a Saturday or Sunday or a public holiday)
              when commercial banks are open for ordinary banking business in Poland, provided
              this coincides with business days in London, England;`,
      },
      {
        heading: 'Business Relationship',
        description: `means a business, professional or commercial relationship
              between you and us and which is expected to have an element of duration, or any
              transaction(s) exceeding the limits of Occasional Transactions as set out in Clause 4.3;`,
      },
      {
        heading: 'Card Purchase',
        description: `means a purchased cryptocurrency using fiat currency from a debit or
              credit card;`,
      },
      {
        heading: 'CoinsSpirit Account',
        description: `means a digital account on our systems that is specific to you
              where we record your Available Balance, data relating to your Transactions and other
              information from time to time, and which allows you to use the Services;`,
      },
      {
        heading: 'Deposit Transaction',
        description: `means a Card Purchase or a Deposit Transfer;`,
      },
      {
        heading: 'Deposit Transfer',
        description: `means a deposit of Digital Currency into your CoinsSpirit Account
              by transferring a Supported Digital Currency from an external Digital Currency wallet or
              address;`,
      },
      {
        heading: 'Digital Currency',
        description: `means a digital representation of value that is not issued or
              guaranteed by a central bank or a public authority, is not necessarily attached to a
              legally established currency and does not possess a legal status of currency or money
              under the Governing Law, but is accepted by natural or legal persons as a means of
              exchange and which can be transferred, stored and traded electronically;`,
      },
      {
        heading: 'Digital Currency Exchange',
        description: `means a transaction where you exchange one Digital
              Currency for another form of Digital Currency;`,
      },
      {
        heading: 'Fees',
        description: `has the meaning as set out in the Pricing and Fee Schedule which can be found
              at https://coinsspirit.com/fees`,
      },
      {
        heading: 'FIAT Currency',
        description: `means any currency that a government has issued and backed such as
              EUR, USD, GBP, etc.`,
      },
      {
        heading: 'Force Majeure Event',
        description: `means any event or circumstance which is beyond our
              reasonable control, including but not limited to: any act of God, flood, earthquake or other natural disaster, terrorist acts, riots, war, sanction or embargo, fire, explosion or
              accident, industrial action of any kind (other than induced by us), interruption or failure
              of any utility service or act taken by any Government Body;`,
      },
      {
        heading: 'Governing Law',
        description: `means the laws of the Republic of Poland;`,
      },
      {
        heading: 'Government Body',
        description: `means any foreign, federal, state, local or other governmental
              authority or regulatory body or competent tax authority;`,
      },
      {
        heading: 'Inbound Transaction',
        description: `means a payment of Digital Currency that is transferred into
              your CoinsSpirit Account such as via a Deposit Transfer, Purchase or Digital Currency
              Exchange;`,
      },
      {
        heading: 'Intellectual Property Rights',
        description: `means patents, utility models, trade-marks, service
              marks, trade and business names, rights in designs, copyright (including rights in
              software), database rights, domain names, semi-conductor topography rights, rights in
              inventions, rights in know-how and confidential information and other intellectual
              property rights which may subsist in any part of the world, in each case whether
              registered or not (and including applications for registration);`,
      },
      {
        heading: 'KYC',
        description: `means know-your-customer processes, which the Company is required to carry
              out in accordance with Applicable laws;`,
      },
      {
        heading: 'Losses',
        description: `means all losses, damages, claims, liabilities, costs and expenses (including
                  reasonable attorneys’ and other reasonable legal fees and expenses, any penalties
                  imposed by a Government Body or under Applicable Laws);`,
      },
      {
        heading: 'Notice',
        description: `means any information published or provided to you by CoinsSpirit via the
              Software, your email or any other method as CoinsSpirit may use from time to time;`,
      },
      {
        heading: 'Occasional Transactions',
        description: `means a transaction or series of transactions in accordance
              with the limits in Clause 4.3;`,
      },
      {
        heading: 'Outbound Transaction',
        description: `means a payment of Digital Currency that is transferred out
              of your CoinsSpirit Account to another CoinsSpirit Account or to a third-party wallet not
              held with the Company;`,
      },
      {
        heading: 'Partner',
        description: `means any company we have partnered with to carry out KYC, or any other
              company we may partner with in connection with the Software and/or Services from
              time to time;`,
      },
      {
        heading: 'Prohibited Jurisdiction',
        description: `means any jurisdiction in which we have suspended trading
              in, ceased or otherwise prohibited use of any of the Services, in response to Applicable
              Law as outlined in the terms of service`,
      },
      {
        heading: 'Purchase',
        description: `means a purchase of Digital Currency to be held in your CoinsSpirit Account
              (including using Card purchase”)`,
      },
      {
        heading: 'Security Credentials',
        description: `means security information (such as answers to questions
                  known only to you, usernames, passwords, passcodes, PIN, or codes generated through
                  a multi-factor authentication security device) that may be used to access your
                  CoinsSpirit Account or make Transactions;`,
      },
      {
        heading: 'Services',
        description: `has the meaning given to it in Clause 4;`,
      },
      {
        heading: 'Software',
        description: `means any software owned or operated, such as a mobile app, website app
              and any other software (for use on mobile devices or otherwise) offered by us in the
              future offering similar or additional functionality, when available;`,
      },
      {
        heading: 'Supported Digital Currency',
        description: `means any Digital Currency that is available with, and in
              the correct form to be stored in, your CoinsSpirit Account as outlined in the`,
      },
      {
        heading: 'Terms',
        description: `means the entire Terms and Conditions, including the Privacy Policy, Fee
              Schedule and Cookie Notice;`,
      },
      {
        heading: 'Transactions',
        description: `means Outbound Transactions and Inbound Transactions;`,
      },
      {
        space: true,
      },
      {
        heading: '1.2  In these Terms:',
        description: `1.2.1 References to “include” or “including” do not limit the generality of any preceding
              words and are to be construed without limitation;`,
      },
      {
        description: `1.2.2. References to a “person” include any individual, company, partnership, joint
              venture, firm, association, trust, government authority or other body or entity (whether
              or not having separate legal personality);`,
      },
      {
        description: `1.2.3 The headings are inserted for convenience only and do not affect the
              construction of these Terms; and`,
      },
      {
        description: `1.2.4. Unless the context otherwise requires, words in the singular include the plural
              and vice versa and a reference to any gender includes all other genders.`,
      },
      {
        space: true,
      },
      {
        heading: 'Important Notice',
        description: `We will NEVER ask you to send us any User ID, Online Password, Online PIN, or Mobile
              PIN via email. We or related companies will NEVER ask for your Full User ID, Online
              Password, Online PIN, or Mobile PIN when identifying you via the phone. The social
              media sites should be a source for general enquires only and is not a secure way to
              communicate with us.`,
      },
      {
        description: `We do not hold any FIAT Currency on your behalf, we only hold Digital Currency.`,
      },
      {
        description: `You are solely responsible for understanding and complying with any and all Applicable
              Laws of your specific jurisdiction or any other jurisdiction in which you may be located
              in from time to time that may apply to you in connection with your use of any and all of
              our Services.`,
      },
      {
        description: `Digital Currency are primarily used by speculators and can be highly risky, due to the
              fact that they are traded throughout the day without limits or protection on the rise or
              fall in their price, and market makers, global government policies and regulators, and
              public opinion may cause major irreversible fluctuations in their prices. You
              acknowledge, understand, and agree that investment in Digital Currency may result in
              substantial or complete loss of your investment and therefore you are advised to use
              your ability to bear any such loss to determine the size of your investment. Any and all
              profits or Losses arising therefrom will be your sole responsibilty and we shall not be held
              liable to any extent whatsoever. There is no guarantee against Losses on the Software
              or through use of the Services. We assume no responsibility for the maintenance,
              security, and proper function of the underlying protocols of Digital Currency, and we are
              not able to guarantee the functionality or security of network operations. You
              acknowledge and accept the risk that underlying software protocols relating to any
              Digital Currency you store in your CoinsSpirit Account may change. You must read our
              Risk Statement before deciding to make a Digital Currency Transaction.`,
      },
      {
        description: `If you do not agree to the terms of this Agreement and/or any change made thereto
              from time to time, please immediately stop using the Services and our Software, and
              terminate your CoinsSpirit Account in accordance with Clause 12. Upon your logging
              into this Software or using any of the Services offered by us, you shall be deemed as
              having understood and fully agree to all terms of this Agreement, including any and all
              changes and/or updates, that we may have made to this Agreement which apply at such
              time.`,
      },
      {
        space: true,
      },
      {
        heading: 'Disclaimer',
        description: `Any information contained on the Software is for general information and educational
              purposes only. No information contained on this Software should in any way be
              considered financial advice in any form or to any extent. Nothing published on this
              Software is a recommendation for any investments, and nor should anything published
              on this Software be relied on for any investment decisions. Please conduct your own
              independent research and seek independent financial and investment advice before
              entering into any financial transaction. We are not responsible for any loss arising
              directly or indirectly from reliance on such information, including but not limited to, any
              loss of profits.`,
      },
      {
        description: `Subject to Applicable Laws, CoinsSpirit disclaims any and all warranties, whether
              express or implied, including without limitation warranties of merchantability, fitness
              for a particular purpose, title or non-infringement or warranties arising from the course of performance, course of dealing or usage in trade in relation to the provision of our
              Services and/or the Software. The Company assumes no responsibility for errors or
              omissions in the any of contents of the Services and/or the Software.`,
      },
      {
        description: `In no event shall the Company be liable for any special, direct, indirect, consequential,
              or incidental damages or any damages whatsoever, whether in an action of contract,
              negligence, or other tort, arising out of or in connection with the use or contents of the
              Software, The Company reserves the right to make additions, deletions, or modification
              to the contents on the Software at any time without prior notice. The Company does not
              warrant that the Software are free of viruses or other harmful components. As we
              cannot control the reliability and availability of the internet, we will not be responsible
              for any distortion, delay, link failure or any other internet disruptions.`,
      },
      {
        space: true,
      },
      {
        heading: 'External links disclaimer:',
        description: `The Software may contain links to external websites and software that are not provided
              or maintained by or in any way affiliated with the Company. Please note that the
              Company does not guarantee the accuracy, relevance, timeliness, or completeness of
              any information on these external websites and software. You acknowledge that we
              cannot guarantee, nor are we in any way responsible for, the acts or omissions of third
              parties or the performance of their products, services, websites or and software.`,
      },
      {
        description: `For any such products and services which are made available (directly or indirectly) by
              third parties, we do not make any express or implied warranties or conditions, including
              of quality, merchantability, fitness for a particular purpose, title, and non-infringement.
              You expressly agree that that we shall not be held liable for (and you expressly assume
              the risk of) any matters arising out of or relating to the actions and omissions of such
              third parties or their products, services, software, and websites.`,
      },
      {
        space: true,
      },
      {
        heading: 'Terms and Conditions applicable to the services provided by the Company',
        description: ``,
      },
      {
        space: true,
      },
      {
        heading: '2. Changes to these Terms',
        description: `2.1 We may, in our sole discretion, update or amend these Terms from time to time.
              Every time you wish to use our Services, please check these Terms to ensure you
              understand the terms that apply at that time. If you disagree with these Terms, now or
              as amended, you may discontinue your use of the Software and the Services by
              terminating your CoinsSpirit Account in accordance with Clause 12 below. Any
              continued use of the Services after a change becomes effective will constitute your agreement to have the changes to these Terms govern your use and any Transactions
              connected with your use.`,
      },
      {
        description: `2.2 Changes to these terms are effective as of the effective date indicated in the
              relevant Notice provided and will apply prospectively to any use of the Services that
              take place following the effective date. If changes will reduce your rights or increase
              your responsibilities, we will provide notice to you 2 months prior to the relevant
              effective date. By continuing to use our Services after that effective date, you will be
              deemed to have agreed to be bound by the updated or amended terms.`,
      },
      {
        space: true,
      },
      {
        heading: '3. Registration and Eligibility',
        description: `3.1. You may register and open a CoinsSpirit Account via the Software or other means
              that we may in the future prescribe. If you choose to register and open a CoinsSpirit
              Account, you agree to provide us with true, accurate, current, and complete information
              about yourself, which includes certain biometric data (which may include a facial scan
              and a thumbprint/fingerprint scan) and update us promptly and in writing if there are
              any changes to that information or data. By registering a CoinsSpirit Account, you agree
              that you have read, understood, and accept all of these Terms, including our Privacy
              Policy, Cookie Notice and Fee Schedule.`,
      },
      {
        description: `3.2. You warrant that you are a natural person legally deemed as having full legal
              capacity in your own jurisdiction you reside in a country in which your access to and
              intended use of the Services is lawful and will not breach any Applicable Law. In order
              for you to prove your capacity, age and/or your location, we reserve the right to request
              from you any extra necessary information. You agree and understand that you (and not
              CoinsSpirit) are solely responsible for reporting of any transactions or other information
              necessary to comply the applicable tax authorities and meet any of your tax obligations.
              You agree to always refrain from engaging in or participating in any act or activity that
              damages the interests of this Software or the Company, whether or not in connection
              with the Services provided by us or this Software.`,
      },
      {
        description: `3.3. In accordance with Clause 3.5 of these Terms, Card Purchase on your CoinsSpirit
              Account will not be activated, and you shall only be allowed to carry out Occasional
              Transactions, unless we have been provided with the required information so that we
              may identify you to our satisfaction and comply with all Applicable Law, our internal policies and KYC requirements. We shall keep records of the information and
              documents you provide in accordance with our Privacy Policy and any Applicable Law.
              We reserve the right to request you to provide us with additional identification data and
              documents. We reserve the right to withhold access to your CoinsSpirit Account until we
              are provided with documents and/or information that are necessary to fulfil our
              responsibilities under the Applicable Laws.`,
      },
      {
        description: `3.4. We reserve the right to not accept your application for a CoinsSpirit Account and we
              are not obliged to provide the reason for declining your application. If we have
              reasonable grounds to suspect that any information you have provided to us is untrue,
              inaccurate or incomplete, we may suspend or terminate your CoinsSpirit Account and
              refuse any and all current and/or future use of the Services by you.`,
      },
      {
        description: `3.5. Confirmation of these Terms does not mean that we enter into a continuous
              Business Relationship with you in regard to the Services listed in Section 4.1. If you fail
              to provide us with all KYC information required and/or fail to undergo full identification
              procedures in accordance with our internal rules and policies, we will not enter into a
              continuous Business Relationship with you, and you shall only be allowed to carry out
              Occasional Transactions within the limits outlined in Clause 4.3.`,
      },
      {
        description: `3.6. By signing these Terms, you confirm that you act and use the Services in your own
              name, and not on behalf of any third party. You accept and warrant that you are fully
              responsible for, and that you are the ultimate beneficial owner for all activity, all profits
              and all losses that occur on your CoinsSpirit Account.`,
      },
      {
        description: `3.7. Upon entering into a Business Relationship with us you:`,
      },
      {
        description: `3.7.1. Confirm that you shall use Services for the following purposes:
              a. Depositing Digital Currency that is owned by you;
              b. Exchanging Digital Currency that is owned by you;
              c. Purchasing Digital Currency; and
              d. Withdrawing Digital Currency that is owned by you.`,
      },
      {
        description: `3.7.2. You may, subject to any transaction limits we may set, have the ability to use the
              Services and conduct transactions up to a limit of EUR 1,000, or equivalent value in FIAT
              Currency per day, to exceed this limit you must provide any additional necessary
              information required, such as complete KYC information.`,
      },
      {
        description: `3.8. Our Services and the Software can be accessed via the internet, an international
              network which you may have access to via IT devices, such as a desktop computer, a
              tablet, phone, or other similar device. You are solely responsible for the proper
              functioning, maintenance and security of your own IT devices and your internet access,
              for any purposes in connection with our Software and/or Services. You are solely liable
              for any Losses resulting from a failure by you to ensure the proper functioning,
              maintenance and security of your own IT devices and your internet access.`,
      },
      {
        space: true,
      },
      {
        heading: '4. The Services',
        description: `4.1. We will provide the following Services to you in accordance with these Terms: (i)
              providing access to and maintaining the Software; (ii) exchanging fiat currency using
              Card Purchase for Digital Currency paid to you; (iii) exchanging a Digital Currency for a
              different form of Digital Currency (iv) hosting a CoinsSpirit Account enabling you to
              store Digital Currencies; (v) managing Transactions in your CoinsSpirit Account,
              including tracking and messaging additional information; (vi) and any other related
              services.`,
      },
      {
        description: `4.2. Your eligibility to access certain Services may depend on the country in which you
              reside in or are based in from time to time. You shall not attempt to access or make use
              of the Services from or in any jurisdiction where such access or use is prohibited by the
              Applicable Laws.`,
      },
      {
        description: `4.3. Unless we enter into a continuous Business Relationship with you, you may only
              carry out Occasional Transactions, whether the transactions are carried out in a single
              transaction or several linked transactions, that adhere to the following limits (or any
              other transaction limits we may set):`,
      },
      {
        description: `4.3.1. The value of a Digital Currency Exchange or other Transactions carried out in
              Digital Currency do not exceed EUR 1,000 or an equivalent amount in FIAT Currency; or`,
      },
      {
        description: `4.3.2. The value of withdrawn Digital Currency does not exceed EUR 1,000 or an
              equivalent amount in FIAT Currency.`,
      },
      {
        description: `4.4. We reserve the right add or remove a Digital Currency to/from the list of Supported
              Digital Currencies, and to add or remove a jurisdiction to/from the list of Prohibited
              Jurisdictions. Changes to these lists are effective as of the effective date indicated in
              relevant Notice provided. We may, in our sole discretion and without liability to you,
              with or without prior notice and at any time, modify or discontinue, temporarily or
              permanently, all or any part of our Services, which may include but is not limited to
              suspending trading in or ceasing to offer Services in respect of any Digital Currency or
              prohibiting use of the Services in or from certain jurisdictions in response to any
              Applicable Law. Any choice by us to support a Digital Currency does not amount to an
              endorsement or recommendation by us in relation to that Digital Currency or it’s
              continued support, value or maintenance.`,
      },
      {
        space: true,
      },
      {
        heading: '5. Access to your CoinsSpirit Account and use of the Services',
        description: `5.1. You may access your CoinsSpirit Account by logging into the Software. From here,
              you will be able to see your CoinsSpirit Transaction activity and view your CoinsSpirit
              Account details. You should check your CoinsSpirit Account Transaction activity
              regularly, not less than on a monthly basis and contact us immediately in relation to any
              irregularities or enquiries. We shall not be liable for any Losses that result from any
              outdated, inaccurate or incomplete information you provide.`,
      },
      {
        description: `5.2. Subject to these Terms, the value of each Outbound Transaction will be deducted
              from your Available Crypto Balance and the value of each Inbound Transaction will be
              credited to your Available Crypto Balance. You must ensure that you have a sufficient
              Available Crypto Balance from time to time to pay for your Outbound Transactions and
              any Fees. If for any reason your Available Crypto Balance is insufficient to fulfil
              requested Transactions, we will not process that Transaction, and not processing that
              Transaction will not constitute a breach of these Terms.`,
      },
      {
        description: `5.3. We may refuse to authorise or may suspend any Transaction or use of the Services
              that could breach these Terms, or if we have reasonable grounds for suspecting that
              you or a third party have committed or are planning to commit fraud, or any other
              illegal or an unauthorised use of the Services. This will include freezing your Available
              Crypto Balance and/or otherwise making unavailable any of the Services to you.`,
      },
      {
        description: `5.4. Your ability to use or access the Services and CoinsSpirit Account may occasionally
              be interrupted, for example but not limited to, if we need to carry out maintenance on
              our systems or due a Force Majeure Event. Please contact us in accordance with Clause
              17 if you wish to report any issues.`,
      },
      {
        description: `5.5 You agree and warrant that you will:`,
      },
      {
        description: `5.5.1. only use the Services for lawful purposes and will adhere at all times to all
              Applicable Laws and these Terms;`,
      },
      {
        description: `5.5.2. not attempt to discover any source code included in the Software, upload to our
              systems any malware, viruses or other unauthorized object code or source code, or
              otherwise disrupt or interfere with the conduct of the Service;`,
      },
      {
        description: `5.5.3. not copy, adapt, reverse engineer, decompile, disassemble, modify, or make error
              corrections to the Software in whole or in part, except to the extent permitted by the
              Applicable Laws.`,
      },
      {
        space: true,
      },
      {
        heading: '6. Depositing on to your CoinsSpirit Account',
        description: `6.1. You may transfer a deposit onto your CoinsSpirit Account by depositing a
              Supported Digital Currency from an external Digital Currency address into your
              CoinsSpirit Account. Digital transfers are usually irreversible. Once you have confirmed
              your Deposit Transfer, the transfer is irreversible, and you do not have a right to cancel
              the transaction or these Terms except as provided for in Clause 12.`,
      },
      {
        description: `6.2. You may also use fiat currency through a Card Purchase to Purchase Digital
              Currency through your CoinsSpirit Account. Once you have confirmed your Purchase,
              your transaction is irreversible, and you do not have a right to cancel the transaction or
              these Terms except as provided for in Clause 12. Any fiat used to Purchase Digital
              Currency will be definitively spent, and you will no longer be able to redeem any such
              fiat currency.`,
      },
      {
        description: `6.3. We will display your Available Crypto Balance, showing the amount of each Digital
              Currency you hold in your CoinsSpirit Account and the estimated FIAT value of your
              Available Crypto Balance. The estimated value of your Available Crypto Balance may not accurately reflect the value you will receive if you were to sell your Digital Currency or
              the price you will pay to purchase more Digital Currency and does not include the
              impact of any applicable Fees on the value you will receive. Your Available Crypto
              Balance may fluctuate as Digital Currency can be very volatile and may fluctuate in price
              significantly and quickly.`,
      },
      {
        description: `6.4. Our processing of all Deposit Transactions will be subject to our usual fraud, anti-
              money laundering and other operational and customer service checks. Accordingly,
              there may be delays in the time required for Digital Currency to appear in your
              CoinsSpirit Account once you have performed a Deposit Transaction.`,
      },
      {
        description: `6.5. Under no circumstances should you attempt to use your CoinsSpirit Wallet to store,
              send, request, or receive Digital Currency in any form that is not a Supported Digital
              Currency. We assume no responsibility or liability in connection with any attempt to use
              your CoinsSpirit Wallet to store or transfer Digital Currency that we do not support. You
              acknowledge and agree that CoinsSpirit bears no responsibility and is not liable for any
              loss in connection with any Digital Currency which is not supported that is sent to your
              CoinsSpirit Account.`,
      },
      {
        description: `6.6. If you make a Deposit Transfer or a Purchase, information on the source of funds
              will be stored securely in accordance with our Privacy Policy and Applicable Laws.`,
      },
      {
        space: true,
      },
      {
        heading: '7. Fees',
        description: `7.1. CoinsSpirit makes money when you buy or sell Supported Digital Currency on our
              Software. A description of the CoinsSpirit “Fees” for using your CoinsSpirit Account
              and/or the Services can be found at https://coinsspirit.com/fees/ which displays
              CoinsSpirit’s Pricing and Fee Schedule.`,
      },
      {
        description: `7.2. By using the Services, you agree to pay all applicable fees. CoinsSpirit reserves the
              right to update its Pricing and Fee Schedule at any time. We will always notify you of the
              pricing which apply to your transaction when you authorise the transaction. We may
              charge a blockchain fee (miner fees) to process a Digital Currency Transaction on your
              behalf. We will calculate the blockchain fee in our discretion, although we will always
              notify you of the blockchain fee at or before the time you authorize the Digital Currency
              Transaction.`,
      },
      {
        description: `7.3. You agree that any Fees and other amounts due and payable to us under these
              Terms may be deducted from your Available Crypto Balance as they fall due. Any Fees
              will be taken from your Available Crypto Balance depending on the type of transaction
              being made and we will endeavour to combine any Fees into the displayed trading
              price. You are responsible for paying any additional fees charged by your financial
              service provider.`,
      },
      {
        description: `7.4. Any changes to the Pricing and Fee Schedule are effective as of the effective date
              indicated in the relevant Notice provided. Any updated fees will apply to any sales or
              other Transactions that occur following such effective date. By continuing to use our
              Services after that effective date, you will be deemed to have agreed to be bound by the
              updated or amended Fee Schedule. You authorise CoinsSpirit to deduct from your
              CoinsSpirit Account any applicable fees that you owe under these Terms. Such
              deductions may be made at any time, and without us notifying you, but these will be
              reflected in the information in the Pricing and Fee Schedule.`,
      },
      {
        description: `7.5. We will display the estimated trading price which is an estimate of value based on
              market data of Digital Currency values. However the estimated values we provide will
              only be valid for a limited time as Digital Currency can be very volatile and may fluctuate
              in price significantly and quickly. If the estimated trading price we displayed has expired
              you will not be able to complete the Transaction at those values. By confirming a
              Transaction, you agree to any applicable Fees.`,
      },
      {
        space: true,
      },
      {
        heading: '8. Managing your CoinsSpirit Account',
        description: `8.1. You are responsible for the use of your CoinsSpirit Account, for ensuring that use of
              the Services or access to your CoinsSpirit Account complies fully with these Terms and
              for the safekeeping of your username, password, and any other Security Credentials for
              your CoinsSpirit Account.`,
      },
      {
        description: `8.2. Do not share your Security Credentials with anyone. If you disclose your Security
              Credentials to anyone, you are responsible and liable for their access, use or misuse of
              your CoinsSpirit Account, their breach of these Terms, and any Losses resulting from
              such access, use, misuse and/or breach.`,
      },
      {
        description: `8.3. If your Security Credentials or other CoinsSpirit Account details are lost, stolen, or
              otherwise compromised, or you believe a third party has unauthorised access to your
              CoinsSpirit Account, you must contact us immediately in accordance with Clause 17. We will take reasonable steps to stop any unauthorised use of your CoinsSpirit Account,
              which may include suspending or cancelling your CoinsSpirit Account or otherwise
              suspending access to the system and Services.`,
      },
      {
        description: `8.4. If you claim there has been an unauthorised or incorrect Transaction on your
              CoinsSpirit Account, you must not transfer that Digital Currency before reporting the
              Transaction to CoinsSpirit and during the course of our investigation of your claim. If
              you transfer the proceeds of any claimed unauthorised or incorrect Transaction we will
              refuse your claim, you will not be able to recover any of the relevant Digital Currency
              and we may freeze any relevant proceeds or funds related to such Transaction pending
              and subject to the conclusion of our investigations. The amount of any proceeds of a
              suspected or confirmed unauthorised or incorrect transaction are deemed to be a debt
              due and payable to us on demand. You agree that we may charge the amount of any
              such debt against any funds subsequently loaded onto your CoinsSpirit Account. We
              reserve the right to recover the value or any proceeds of any unauthorised or incorrect
              transaction related to you by any other legal means.`,
      },
      {
        space: true,
      },
      {
        heading: '9. Changes to your CoinsSpirit Account details',
        description: `9.1. You must notify us immediately of any change in your CoinsSpirit Account details
              and we cannot guarantee proper performance of the Services in the event the
              CoinsSpirit Account details you have provided are not accurate or become inaccurate
              and disclaim all liability for any Losses that may result from such inaccuracies. You can
              notify us by contacting us in accordance with Clause 17, and we may require you to
              confirm such notification in writing or through other factors of authentication (which
              may include messages or calls to your mobile phone, or confirmation mail to your
              physical address). You will be liable for any Losses that directly result from any failure
              by you to notify us of any change promptly. To verify a new address, you must provide
              any proof that we require.`,
      },
      {
        description: `9.2. We reserve the right at any time to satisfy ourselves as to your identity and address
              (for example, by requesting relevant original documents), including for the purposes of
              preventing fraud and/or money laundering. In addition, at the time of your application
              and at any time in the future, we may perform, in connection with your CoinsSpirit
              Account, electronic identity verification checks either directly or using a KYC relevant
              Partner. You agree to provide us with the information we request for purposes of
              identity verification and the detection of money laundering, terrorist financing, fraud or
              any other financial crime, and we will keep a record of such information in accordance
              with Applicable Laws and our Privacy Policy. If you fail to provide the requested information, we reserve the right to suspend your CoinsSpirit Account and use of the
              Services pending receipt and verification of that information.`,
      },
      {
        space: true,
      },
      {
        heading: '10. Proprietary Rights',
        description: `10.1 All rights, title and interest in, and to, the Intellectual Property Rights subsisting in,
              or embodied by the Software, the Services, any proprietary software or other
              technology required to operate the Software, the Services, and any modifications or
              improvements thereto, including any derivative works, are retained by us and protected
              under applicable Intellectual Property Rights and Applicable Laws.`,
      },
      {
        description: `10.2 By activating your CoinsSpirit Account, we grant you a limited permission to use the
              Software solely for the purpose of using the Services in accordance with these Terms. In
              particular and without limitation, this permission does not grant you the right to create,
              author or invent any modifications or improvements to, or derivative works of the
              Software. We may suspend or terminate this permission in accordance with Clause 12.
              Notwithstanding the foregoing, nothing in these Terms grants you any permission or
              right to use our trademarks, service marks, trade dress, slogans, logos or other indicia
              of origin.`,
      },
      {
        description: `10.3 If you submit any ideas, suggestions or other feedback to us about the Software or
              the Services, you grant to us the right to use or disclose such feedback without any
              further obligation to you. All rights not expressly granted to you under these Terms are
              reserved by us.`,
      },
      {
        space: true,
      },
      {
        heading: '11. Right to Cancel',
        description: `11.1. You have the right to cancel your CoinsSpirit Account and these Terms for any
              reason within a ‘cooling-off’ period of fourteen (14) days from the date on which your
              CoinsSpirit Account is registered. You must contact us within this fourteen (14) day
              period and inform us that you wish to withdraw from these Terms, and you must not
              make any Transactions within this fourteen (14) day period. We will then cancel your
              CoinsSpirit Account and reimburse your Available Crypto Balance to an address
              provided by you within fourteen (14) Business Days of our receipt of your cancellation
              request.`,
      },
      {
        description: `11.2. If you fail to exercise your right to cancel under Clause 11.1, then these Terms
              shall remain in full force and effect, and you may only terminate your CoinsSpirit
              Account and these Terms in accordance with Clause 12.`,
      },
      {
        space: true,
      },
      {
        heading: '12. Termination and Suspension',
        description: `12.1. If you wish to terminate your CoinsSpirit Account at any time, you must inform us
              of your wish to terminate through the Software or by contacting us in accordance with
              Clause 17.`,
      },
      {
        description: `12.2. We may in our sole discretion and without liability to you terminate or suspend
              your CoinsSpirit Account, the provision of the Services and these Terms for any reason
              by giving you fourteen (14) days’ notice. We may in our sole discretion and without
              liability to you also limit, terminate or suspend your CoinsSpirit Account, your use of the
              Services and these Terms immediately in the event that: (i) We believe that you have
              used or are likely to use the Services, or allow them to be used, in breach of any of
              these Terms; (ii) We believe or suspect that you are in any way involved in any
              fraudulent activity, money laundering, terrorism financing, other criminal activity, other
              breach of Applicable Laws, or if we have any other security or legal or regulatory
              compliance concerns; (iii) We believe that you have provided false or misleading
              information; (iv) We believe that you are involved in any dispute or disagreement with
              any of our Partners whether that dispute or disagreement relates to the Services or
              otherwise; (v) We are unable to verify your identity or any other information pertaining
              to you, your CoinsSpirit Account or a Transaction; (vi) We are required to do so in
              accordance with our legal and regulatory obligations under Applicable Laws; or (vii) We
              cease to be authorised to provide the Services; (viii) We no longer support a Digital
              Currency held in your CoinsSpirit account; (ix) the jurisdiction in which you access the
              Services is added to the Prohibited Jurisdiction List; (x) We reasonably believe it is
              necessary for any other reason. We are not liable for any Losses you may incur (and you
              are not entitled to any gains you make) as a result of your CoinsSpirit Account being
              limited, suspended, or terminated.`,
      },
      {
        description: `12.3. If there have been no Transactions or other activity on your CoinsSpirit Account for
              a period of at least one (1) year, we reserve the right to terminate it and these Terms.
              We will give you 14 days prior notice of any such termination. However, if you log in or
              make a Transaction during this 14 day period we will not terminate your account once
              such period expires.`,
      },
      {
        description: `12.4. Upon termination of these Terms and your CoinsSpirit Account in accordance with
              this Clause 12, all pending Transactions will be processed and, if your CoinsSpirit
              Account has a positive balance, we will redeem your Available Crypto Balance, minus
              any applicable Fees, to an address that you nominate, within thirty (30) Business Days’
              of the date on which you inform us of your wish to terminate your CoinsSpirit Account or on which termination otherwise occurs in accordance with this Clause 12. Unless
              otherwise expressly stated in Clause 12, the provisions of Clause 15 shall apply in such
              circumstances.`,
      },
      {
        description: `12.5. Upon termination of your CoinsSpirit Account for any reason, we may continue to
              process your personal data in accordance with the terms of our Privacy Policy and
              Cookie Notice as applicable.`,
      },
      {
        description: `12.6. The Terms in effect as of the date of the termination of your CoinsSpirit Account
              will survive and continue to apply to any aspects of your prior use of the Software or the
              Services, including to your CoinsSpirit Account information, your Available Crypto
              Balance, any outstanding Transactions, and any relationship between you and a Partner
              formed with the assistance of the Software or the Services.`,
      },
      {
        description: `12.7. If there is a Digital Currency balance remaining in your account after termination,
              you agree to provide us with an external digital wallet address, so that we can attempt
              to return the remaining Digital Currency to you within a period of ninety (90) days. You
              are not permitted to use the Services or your CoinsSpirit Account for any purposes
              during this period and we may, at our discretion, limit the functionality of your
              CoinsSpirit Account and/or your access to the Software accordingly.`,
      },
      {
        description: `12.8. If we suspend or terminate your CoinsSpirit Account or your use of the Services for
              any reason, we reserve the right to require you to provide us with all KYC information
              and undergo full identification procedures in accordance with our internal rules and
              policies and any Applicable Law before returning any Digital Currency to you.`,
      },
      {
        space: true,
      },
      {
        heading: '13. Redemption',
        description: `13.1. We will redeem, either in part or in full, the Digital Currency of the Available Crypto
              Balance on your CoinsSpirit Account, at any time, at par value, following instructions
              given by you to do so and subject to your payment of any applicable Fees, compliance
              with Applicable Laws and the remainder of this Clause 13.`,
      },
      {
        description: `13.2. We reserve the right to carry out any necessary anti money laundering, terrorist
              financing, fraud and other illegal activity and operational checks before authorising any
              redemption or transfer of funds to you, including returning any funds after the termination of these Terms. Accordingly, there may be delays in the time between our
              receipt of your redemption request and your receipt of the relevant funds.`,
      },
      {
        description: `13.3. We reserve the right to charge a Fee when you require redemption either in full or
              in part of the Available Crypto Balance on your CoinsSpirit Account before the
              termination of these Terms (other than if you cancel during the ‘cooling-off’ period
              pursuant to Clause 11).`,
      },
      {
        description: `13.4. After we have approved the redemption of your funds, you must ensure that the
              address for the transfer is accurate and complete. Where you provide us with incorrect
              details, we will not be held liable for funds sent to the incorrect address where we have
              acted in accordance with your instructions. We reserve the right to charge you a Fee in
              the event that you request our assistance in retrieving Digital Currency from an
              incorrect address or when you have transferred the wrong Digital Currency onto your
              CoinsSpirit Account.`,
      },
      {
        space: true,
      },
      {
        heading: '14. Tax',
        description: `14.1. It is your (and not CoinsSpirit’s) sole responsibility to determine what tax
              obligations you may have and to comply with any such tax obligations owed such as
              reporting and paying any relevant tax to any applicable tax authority.`,
      },
      {
        space: true,
      },
      {
        heading: '15. Liability',
        description: `15.1. Nothing in these Terms shall limit or exclude our liability: (i) For fraud or
              fraudulent misrepresentation; (ii) For death or personal injury caused by our negligence;
              or (iii) Where such limitation or exclusion would be contrary to Applicable Law.`,
      },
      {
        description: `15.2. Except as provided in Clause 15.1, we shall not be liable under or in connection
              with these Terms, whether in contract, tort (including negligence), breach of statutory
              duty or otherwise:`,
      },
      {
        description: `15.2.1. For any:
              a. indirect, incidental, special or consequential loss or damage of any kind; or
              b. loss of profits, business, revenue or savings (actual or anticipated), loss of
              agreements or contracts, loss of opportunity, loss of data or loss of, or damage to,
              goodwill or reputation (including as a result of your inability to participate in any goods, 
                  services or offerings of a Partner, to the extent that it is caused by a failure in the
                  Software, the Services or delays in the time required for the Purchase of Digital
                  Currency to appear in your CoinsSpirit Account once you have performed a Deposit
                  Transaction);`,
      },
      {
        description: `15.2.2. For any Losses in connection with the rights, privileges, licenses, goods or
              services that are purchased with your CoinsSpirit Account from a Partner or the terms
              on which they are provided by a Partner such as; (i) If a Partner refuses to accept an
              Outbound Transaction or other payment..`,
      },
      {
        description: `15.3. Subject to Clause 15.1, The Services and Software, in whole and in part, are
              provided on an “as is” and “as available” basis, without express or implied warranties of
              any kind, including without limitation warranties of merchantability, fitness for a
              particular purpose, title or non-infringement or warranties arising from the course of
              performance, course of dealing or usage in trade.`,
      },
      {
        description: `15.4. Except as provided in Clauses 15.1 and 15.7 and subject to Clause 15.2 and any
              Applicable Laws, in no event shall the aggregate liability of CoinsSpirit (including our
              directors, members, employees and agents), whether in contract, warranty, tort
              (including negligence) product liability, breach of statutory duty, arising out of or
              relating to the use of or inability to use CoinsSpirit Services, the Software and/or these
              Terms exceed the Fees paid by you to CoinsSpirit during the 6 months preceding the
              date of any claim giving rise to such liability or an equivalent amount in FIAT Currency.`,
      },
      {
        description: `15.5. You are solely responsible for your interactions with Partners. We reserve the
              right, but have no obligation, to monitor or mediate any disputes between you and any
              Partners.`,
      },
      {
        description: `15.6. You agree to indemnify and hold harmless CoinsSpirit and our directors, officers,
              employees, agents, affiliates and subcontractors against any and all claims, actions,
              proceedings, investigations, demands, suits and Losses suffered or incurred by us that arise out of, or in connection with, any breach by you, of any of these Terms or
              Applicable Law, any enforcement by us of these Terms and/or your violation of any
              rights of any third party.`,
      },
      {
        description: `15.7. Except as provided in Clause 15.1 and subject to Clause 15.2, where we have
              incorrectly and due to our fault:`,
      },
      {
        description: `15.7.1. Deducted amounts from your Available Crypto Balance, our liability shall be
              limited to payment to you of an equivalent amount or;`,
      },
      {
        description: `15.7.2. Credited too low an amount to your Available Crypto Balance our liability shall
              be limited to payment to you of an incremental amount that ensures you have received
              the correct amount due, provided that, in each case, if we subsequently establish that
              the refunded or incremental credited amount had been correctly deducted or credited
              (as the case may be), we may deduct it from your Available Crypto Balance and, if you
              do not have a sufficient balance, you must repay us the amount immediately upon
              demand.`,
      },
      {
        description: `15.8. Except as provided in Clause 15.1, we shall not be liable for Losses relating to any
              unauthorised Transactions resulting from the use of lost or stolen Security Credentials
              and/or CoinsSpirit Account details or misappropriation of your CoinsSpirit Account,
              except to the extent that the relevant Losses are caused by our breach of these Terms
              or our failure to authenticate a Transaction in accordance with our then published
              authentication documentation. Otherwise than as set out in the preceding sentence,
              you shall be liable for Losses relating to any unauthorised Transactions resulting from
              the use of lost or stolen Security Credentials and CoinsSpirit Account details or
              misappropriation of your CoinsSpirit Account.`,
      },
      {
        description: `15.9. Notwithstanding Clause 15.8, and except as provided in Clause 15.1, we shall not
              be liable for any unauthorised or incorrectly executed Transactions, including but not
              limited to in the event that the Transaction was affected by a Force Majeure Event or
              where we acted in accordance with a legal or regulatory obligation or other Applicable
              Laws.`,
      },
      {
        description: `15.10. We do not guarantee the identity of any user, receiver, requestee or other third
              party (including other users of our Services) and we will have no liability or responsibility
              for ensuring that the information provided by any user is accurate and complete.`,
      },
      {
        description: `15.11. We have no control over, or liability for, the delivery, quality, safety, legality or any
              other aspect of any goods or services that you may purchase or receive from, or sell or
              transfer to, any third party (including other users of our Services). We are not
              responsible for ensuring that a third party you transact with will complete the
              Transaction or is authorised to do so.`,
      },
      {
        description: `15.12. If you believe a third party has behaved in a fraudulent, misleading, or
              inappropriate manner, or if you cannot adequately resolve a dispute with a third party,
              you may notify CoinsSpirit Support in accordance with clause 17.`,
      },
      {
        space: true,
      },
      {
        heading: '16. Force Majeure',
        description: `16.1. We shall not have any liability under, or be deemed to be in breach of, these
              Terms for any delays or failures in performance of any of our obligations under these
              Terms that result from an event out of our control or any other Force Majeure Event.`,
      },
      {
        space: true,
      },
      {
        heading: '17. Contact Details',
        description: `17.1. If you have any questions or concerns about the Services, or want to report issues
              with your CoinsSpirit Account, please contact us by email at support@coinsspirit.com`,
      },
      {
        space: true,
      },
      {
        heading: '18. Miscellaneous',
        description: `18.1. We may assign or transfer all or any of our rights or obligations under these Terms
              to any third party (including by way of merger, consolidation or the acquisition of all or
              substantially all of our business and assets relating to these Terms).`,
      },
      {
        description: `18.2. You may only assign or transfer your rights or your obligations under these Terms
              to a third party if we give our prior written consent to this.`,
      },
      {
        description: `18.3. A person who is not a party to these Terms has no right to enforce any part of
              these Terms. The rights to terminate, rescind or agree any variation, waiver or
              settlement under these Terms are not subject to the consent of any person that is not a
              party to these Terms.`,
      },
      {
        description: `18.4. Nothing in these Terms is intended to, or shall be construed so as to, establish or
              imply any partnership or joint venture or a relationship of principal and agent between
              you and us or constitute either of you or us as the agent of the other party, or authorise
              you or us to make or enter into any commitments for or on behalf of the other party`,
      },
      {
        description: `18.5. If any provision of these Terms is held to be invalid, illegal or unenforceable in any
              respect under the law of any jurisdiction, the validity, legality and enforceability of the
              remaining provisions shall not in any way be affected or impaired thereby, and such
              provision shall be deemed to be restated to reflect yours and our original intentions as
              nearly as possible in accordance with Applicable Laws.`,
      },
      {
        description: `18.6. No waiver of any of our rights under these Terms shall be effective unless in
              writing. Unless expressly stated otherwise, a waiver shall be effective only in the
              circumstances for which it is given, and no delay or omission by us in exercising any
              right or remedy provided by law or under these Terms shall constitute a waiver by us of
              such right or remedy.`,
      },
      {
        description: `18.7. These Terms and the documents referred to in it, constitute the whole agreement
              between you and us relating to the subject matter of these Terms and supersedes any
              prior written or oral arrangement, understanding or agreement between them relating
              to such subject matter.`,
      },
      {
        description: `18.8. These Terms are provided to you in the English language. Where we have provided
              a translation of these Terms to you, you agree that such translation is provided for your
              convenience only and that the English language version of these Terms will govern your
              relationship with us. If there is any contradiction between the English language version
              of these Terms and any translation, the English language version takes precedence.`,
      },
      {
        space: true,
      },
      {
        heading: '19. Dispute Resolution',
        description: `19.1. If you have any complaints or want to raise a dispute we encourage you to raise
              these directly with us so that we can attempt to resolve any issue at first instance to
              both parties’ satisfaction. You agree that if the parties attempt to resolve a dispute this
              way the parties agree to act in good faith to resolve the dispute and during the conduct
              of any negotiations. The parties acknowledge that after raising a dispute they may agree
              to refer the case to arbitration by concluding an arbitration agreement.`,
      },
      {
        description: `19.2. You and CoinsSpirit agree to give written notification to the other party within
              thirty (30) days of the dispute or claim arising, or of your intention to initiate such claim
              or dispute arising.`,
      },
      {
        description: `19.3. The parties agree that any claims or disputes shall be kept confidential. The
              existence any claim or dispute, any non-public and any other confidential information
              provided (collectively, the “Confidential Information”) shall not be disclosed to any non-
              party. A party may only disclose Confidential Information to a non-party to the extent
              that such disclosure is required to fulfil a legal duty, assert a legal right, or otherwise as
              required by law. This confidentiality provision shall survive termination of these Terms.`,
      },
      {
        description: `19.4. Any formal claim or dispute against CoinsSpirit must be initiated by submitting the
              claim or dispute to the courts of the Republic of Lithuania in the shortest statutory
              limitation period permitted by the Governing Law after the date the party bringing the
              claim knows or reasonably could have known of the facts giving rise to the claim; and
              there shall be no remedy for any claim asserted after that time period.`,
      },
      {
        space: true,
      },
      {
        heading: '20. Governing Law and Jurisdiction',
        description: `20.1. These Terms, and any noncontractual obligations arising out of or in connection
              with these Terms, are governed by, and shall be construed in accordance with, the
              Governing Law.`,
      },
      {
        description: `20.2. Each party irrevocably agrees that the courts of Poland shall have exclusive
              jurisdiction to settle any dispute or claim (including non-contractual disputes or claims)
              arising out of or in connection with the Services, these Terms or their subject matter or
              formation, unless they are settled by negotiations between the parties or are submitted
              to arbitration by the parties executing an arbitration agreement.`,
      },
      {
        space: true,
      },
      {
        heading: '21. Notice',
        description: `21.1. You may serve notices to us in writing at the contact details in Clause 17.`,
      },
      {
        description: `21.2. You accept and agree that we may provide you with Notices and other disclosures
              in connection with your CoinsSpirit Account and use of the Services.`,
      },
      {
        space: true,
      },
      {
        heading: "Refund policy",
        description: ``,
      },
      {
        space: true,
      },
      {
        description: `22.General policy`,
      },
      {
        space: true,
      },
      {
        description: `22.1 Our platform allows customers to purchase cryptocurrencies using various card payment methods. Due to the volatile nature of cryptocurrencies and the real-time processing of Transactions, all purchases of cryptocurrencies on our platform are considered final and non-refundable.`,
      },
      {
        space: true,
      },
      {
        description: `22.2 By using our services and completing a Transaction, you acknowledge and agree that the Purchase of any cryptocurrency is subject to risks, including but not limited to price volatility and the irreversible nature of blockchain Transactions.`,
      },
      {
        space: true,
      },
      {
        description: `Exceptions`
      },
      {
        space: true,
      },
      {
        description: `22.3 Notwithstanding the foregoing, we may, at our sole discretion, consider refund requests under the following exceptional circumstances:`
      },
      {
        space: true,
      },
      {
        description: `22.3.1 Unauthorised Transactions: If a Transaction was unauthorised or fraudulent, and you provide timely notification in accordance with our fraudulent Transaction policy.`
      },
      {
        space: true,
      },
      {
        description: `22.3.2 Transaction error: If there was a clear error in the transaction, such as a mistaken Transaction amount due to a technical glitch on our platform.`
      },
      {
        space: true,
      },
      {
        description: ` 22.4 All requests for refunds under these exceptions must be submitted in writing within 48 hours of the Transaction. Each request will be evaluated on a case-by-case basis, and we reserve the right to require documentation or evidence to support your claim.`
      },
      {
        space: true,
      },
      {
        description: `Process and conditions`
      },
      {
        space: true,
      },
      {
        description: ` 22.5 If a refund is granted by us under the exceptions outlined above, the refund amount will be credited to the same payment method used for the original Transaction. Refunds will be processed in the original currency of the Transaction.`
      },
      {
        space: true,
      },
      {
        description: ` 22.6 Any Transaction fees, including but not limited to processing fees, network fees, or bank charges, are non-refundable. If a refund is granted, such fees will be deducted from the refund amount.`
      },
      {
        space: true,
      },
      {
        description: ` 22.7 Due to the nature of Digital Currency Transactions, we cannot guarantee any specific timeframe for processing a refund. The timing may depend on various factors, including, but not limited to, the policies of the card-issuing bank.`
      },
      {
        space: true,
      },
      {
        description: `No guarantees`
      },
      {
        space: true,
      },
      {
        description: `22.8 While we will consider refund requests in exceptional circumstances, we make no guarantees that any request will be approved. The decision to grant a refund is at our complete discretion, based on the merits and documentation provided.`
      },
      {
        space: true,
      },
    ],
    "coinsswapcenter": [
      {
          heading: "Who we are and how to contact us",
          description: `These terms and conditions (“Terms” or “Agreement”) govern the use of the Services (as
              defined below), which are provided by Alfortar S.R.O (trading as ${paymentProviderName}) a company incorporated under the laws of the Czech Republic with
              registered number 19092261 and having its registered office at Cimburkova 916/8, Žižkov (Praha 3), 130 00 Praha, (${paymentProviderName}, “Company”, “we”, “us” or “our”) to any person
              whose application we approve (“you” or “your”).`,
      }, {
          space: true
      }, {
          heading: "By using our site, you accept these Terms and Conditions",
          description: `By activating your ${paymentProviderName} Account (as defined below) with us, you confirm that you
          have read, understood, and agree to these Terms. We recommend that you print a copy
          of these terms for future reference and check this page regularly to stay up to date with
          any changes to these terms. \n\n`,
      }, {
          description: `There are other Terms that apply to you.`,
      }, {
          description: `These Terms refer to the following additional terms, which also apply to your use of the
          Services and should be read in conjunction with these Terms, along with any other
          additional terms we may publish from time to time: `,
      }, {
          description: `Our Terms, which outlines some of the most common risks of trading cryptocurrencies which
          you must read before deciding to make a Digital Currency Transaction.`,
      }, {
          description: `Our Privacy Policy, which sets out the terms on which we process your personal data.
          You agree that any and all personal data provided by you is accurate and complete.`,
      }, {
          description: `Our Cookie Notice, relevant to web access to ${paymentProviderName} Software is found on the
          ${paymentProviderName} website which sets out information about the cookies used on our website.`,
      }, {
          description: `Our Fee Schedule, which sets out the terms on which we will charge Fees for the use
          of our Services and any other fees that may apply.`,
      }, {
          description: `By agreeing to these Terms, you agree that you have read, understood, and accept
          these Terms as well as our Privacy Policy, Cookie Notice and Fee Schedule, and you
          acknowledge and agree that you will be bound by such terms, policies and notices.
          These Terms, together with the additional terms above, constitute the entire agreement
          and understanding with respect to the access or use of any or all of the Software and/or
          Services. These Terms may be accepted electronically, and you understand and agree
          that such acceptance shall be deemed to be as binding as an original signature being
          signed to these Terms by you.`,
      }, {
          space: true
      }, {
          heading: "Definitions and Interpretation",
          description: `1.1 In these Terms, capitalised words and expressions have the following meanings,
          unless otherwise stated:`,
      }, {
          heading: "Applicable Laws",
          description: `means any applicable statutes, laws, ordinances, orders, judgments,
          decrees, rules or regulations issued by any government authority, and any judicial or
          administrative interpretation of any of these;`,
      }, {
          heading: "Available Crypto Balance",
          description: `means Digital Currency which is deposited into a wallet
          within a ${paymentProviderName} Account;`,
      }, {
          heading: "Business Day",
          description: `means a day (other than a Saturday or Sunday or a public holiday)
          when commercial banks are open for ordinary banking business in Poland, provided
          this coincides with business days in London, England;`,
      }, {
          heading: "Business Relationship",
          description: `means a business, professional or commercial relationship
          between you and us and which is expected to have an element of duration, or any
          transaction(s) exceeding the limits of Occasional Transactions as set out in Clause 4.3;`,
      }, {
          heading: "Card Purchase",
          description: `means a purchased cryptocurrency using fiat currency from a debit or
          credit card;`,
      }, {
          heading: `${paymentProviderName} Account`,
          description: `means a digital account on our systems that is specific to you
          where we record your Available Balance, data relating to your Transactions and other
          information from time to time, and which allows you to use the Services;`,
      }, {
          heading: "Deposit Transaction",
          description: `means a Card Purchase or a Deposit Transfer;`,
      }, {
          heading: "Deposit Transfer",
          description: `means a deposit of Digital Currency into your ${paymentProviderName} Account
          by transferring a Supported Digital Currency from an external Digital Currency wallet or
          address;`,
      }, {
          heading: "Digital Currency",
          description: `means a digital representation of value that is not issued or
          guaranteed by a central bank or a public authority, is not necessarily attached to a
          legally established currency and does not possess a legal status of currency or money
          under the Governing Law, but is accepted by natural or legal persons as a means of
          exchange and which can be transferred, stored and traded electronically;`,
      }, {
          heading: "Digital Currency Exchange",
          description: `means a transaction where you exchange one Digital
          Currency for another form of Digital Currency;`,
      }, {
          heading: "Fees",
          description: `has the meaning as set out in the Pricing and Fee Schedule which can be found
          at https://${paymentProviderName}.com/fees`,
      }, {
          heading: "FIAT Currency",
          description: `means any currency that a government has issued and backed such as
          EUR, USD, GBP, etc.`,
      }, {
          heading: "Force Majeure Event",
          description: `means any event or circumstance which is beyond our
          reasonable control, including but not limited to: any act of God, flood, earthquake or other natural disaster, terrorist acts, riots, war, sanction or embargo, fire, explosion or
          accident, industrial action of any kind (other than induced by us), interruption or failure
          of any utility service or act taken by any Government Body;`,
      }, {
          heading: "Governing Law",
          description: `means the laws of the Republic of Czech Republic.`,
      }, {
          heading: "Government Body",
          description: `means any foreign, federal, state, local or other governmental
          authority or regulatory body or competent tax authority;`,
      }, {
          heading: "Inbound Transaction",
          description: `means a payment of Digital Currency that is transferred into
          your ${paymentProviderName} Account such as via a Deposit Transfer, Purchase or Digital Currency
          Exchange;`,
      }, {
          heading: "Intellectual Property Rights",
          description: `means patents, utility models, trade-marks, service
          marks, trade and business names, rights in designs, copyright (including rights in
          software), database rights, domain names, semi-conductor topography rights, rights in
          inventions, rights in know-how and confidential information and other intellectual
          property rights which may subsist in any part of the world, in each case whether
          registered or not (and including applications for registration);`,
      }, {
          heading: "KYC",
          description: `means know-your-customer processes, which the Company is required to carry
          out in accordance with Applicable laws;`,
      }, {
          heading: "Losses",
          description: `means all losses, damages, claims, liabilities, costs and expenses (including
              reasonable attorneys’ and other reasonable legal fees and expenses, any penalties
              imposed by a Government Body or under Applicable Laws);`,
      }, {
          heading: "Notice",
          description: `means any information published or provided to you by ${paymentProviderName} via the
          Software, your email or any other method as ${paymentProviderName} may use from time to time;`,
      }, {
          heading: "Occasional Transactions",
          description: `means a transaction or series of transactions in accordance
          with the limits in Clause 4.3;`,
      }, {
          heading: "Outbound Transaction",
          description: `means a payment of Digital Currency that is transferred out
          of your ${paymentProviderName} Account to another ${paymentProviderName} Account or to a third-party wallet not
          held with the Company;`,
      }, {
          heading: "“Partner”",
          description: `means any company we have partnered with to carry out KYC, or any other
          company we may partner with in connection with the Software and/or Services from
          time to time;`,
      }, {
          heading: "Prohibited Jurisdiction",
          description: `means any jurisdiction in which we have suspended trading
          in, ceased or otherwise prohibited use of any of the Services, in response to Applicable
          Law as outlined in the terms of service`,
      }, {
          heading: "Purchase",
          description: `means a purchase of Digital Currency to be held in your ${paymentProviderName} Account
          (including using Card purchase”)`,
      }, {
          heading: "Security Credentials",
          description: `means security information (such as answers to questions
              known only to you, usernames, passwords, passcodes, PIN, or codes generated through
              a multi-factor authentication security device) that may be used to access your
              ${paymentProviderName} Account or make Transactions;`,
      }, {
          heading: "Services",
          description: `has the meaning given to it in Clause 4;`,
      }, {
          heading: "Software",
          description: `means any software owned or operated, such as a mobile app, website app
          and any other software (for use on mobile devices or otherwise) offered by us in the
          future offering similar or additional functionality, when available;`,
      }, {
          heading: "Supported Digital Currency",
          description: `means any Digital Currency that is available with, and in
          the correct form to be stored in, your ${paymentProviderName} Account as outlined in the`,
      }, {
          heading: "Terms",
          description: `means the entire Terms and Conditions, including the Privacy Policy, Fee
          Schedule and Cookie Notice;`,
      }, {
          heading: "Transactions",
          description: `means Outbound Transactions and Inbound Transactions;`,
      }, {
          space: true,
      }, {
          heading: "1.2  In these Terms:",
          description: `1.2.1 References to “include” or “including” do not limit the generality of any preceding
          words and are to be construed without limitation;`,
      }, {
          description: `1.2.2. References to a “person” include any individual, company, partnership, joint
          venture, firm, association, trust, government authority or other body or entity (whether
          or not having separate legal personality);`,
      }, {
          description: `1.2.3 The headings are inserted for convenience only and do not affect the
          construction of these Terms; and`,
      }, {
          description: `1.2.4. Unless the context otherwise requires, words in the singular include the plural
          and vice versa and a reference to any gender includes all other genders.`,
      }, {
          space: true,
      }, {
          heading: "Important Notice",
          description: `We will NEVER ask you to send us any User ID, Online Password, Online PIN, or Mobile
          PIN via email. We or related companies will NEVER ask for your Full User ID, Online
          Password, Online PIN, or Mobile PIN when identifying you via the phone. The social
          media sites should be a source for general enquires only and is not a secure way to
          communicate with us.`,
      }, {
          description: `We do not hold any FIAT Currency on your behalf, we only hold Digital Currency.`,
      }, {
          description: `You are solely responsible for understanding and complying with any and all Applicable
          Laws of your specific jurisdiction or any other jurisdiction in which you may be located
          in from time to time that may apply to you in connection with your use of any and all of
          our Services.`,
      }, {
          description: `Digital Currency are primarily used by speculators and can be highly risky, due to the
          fact that they are traded throughout the day without limits or protection on the rise or
          fall in their price, and market makers, global government policies and regulators, and
          public opinion may cause major irreversible fluctuations in their prices. You
          acknowledge, understand, and agree that investment in Digital Currency may result in
          substantial or complete loss of your investment and therefore you are advised to use
          your ability to bear any such loss to determine the size of your investment. Any and all
          profits or Losses arising therefrom will be your sole responsibility and we shall not be held
          liable to any extent whatsoever. There is no guarantee against Losses on the Software
          or through use of the Services. We assume no responsibility for the maintenance,
          security, and proper function of the underlying protocols of Digital Currency, and we are
          not able to guarantee the functionality or security of network operations. You
          acknowledge and accept the risk that underlying software protocols relating to any
          Digital Currency you store in your ${paymentProviderName} Account may change. You must read our
          Risk Statement before deciding to make a Digital Currency Transaction.`,
      }, {
          description: `If you do not agree to the terms of this Agreement and/or any change made thereto
          from time to time, please immediately stop using the Services and our Software, and
          terminate your ${paymentProviderName} Account in accordance with Clause 12. Upon your logging
          into this Software or using any of the Services offered by us, you shall be deemed as
          having understood and fully agree to all terms of this Agreement, including any and all
          changes and/or updates, that we may have made to this Agreement which apply at such
          time.`,
      }, {
          space: true,
      }, {
          heading: "Disclaimer",
          description: `Any information contained on the Software is for general information and educational
          purposes only. No information contained on this Software should in any way be
          considered financial advice in any form or to any extent. Nothing published on this
          Software is a recommendation for any investments, and nor should anything published
          on this Software be relied on for any investment decisions. Please conduct your own
          independent research and seek independent financial and investment advice before
          entering into any financial transaction. We are not responsible for any loss arising
          directly or indirectly from reliance on such information, including but not limited to, any
          loss of profits.`,
      }, {
          description: `Subject to Applicable Laws, ${paymentProviderName} disclaims any and all warranties, whether
          express or implied, including without limitation warranties of merchantability, fitness
          for a particular purpose, title or non-infringement or warranties arising from the course of performance, course of dealing or usage in trade in relation to the provision of our
          Services and/or the Software. The Company assumes no responsibility for errors or
          omissions in the any of contents of the Services and/or the Software.`,
      }, {
          description: `In no event shall the Company be liable for any special, direct, indirect, consequential,
          or incidental damages or any damages whatsoever, whether in an action of contract,
          negligence, or other tort, arising out of or in connection with the use or contents of the
          Software, The Company reserves the right to make additions, deletions, or modification
          to the contents on the Software at any time without prior notice. The Company does not
          warrant that the Software are free of viruses or other harmful components. As we
          cannot control the reliability and availability of the internet, we will not be responsible
          for any distortion, delay, link failure or any other internet disruptions.`,
      }, {
          space: true,
      }, {
          heading: "External links disclaimer:",
          description: `The Software may contain links to external websites and software that are not provided
          or maintained by or in any way affiliated with the Company. Please note that the
          Company does not guarantee the accuracy, relevance, timeliness, or completeness of
          any information on these external websites and software. You acknowledge that we
          cannot guarantee, nor are we in any way responsible for, the acts or omissions of third
          parties or the performance of their products, services, websites or and software.`,
      }, {
          description: `For any such products and services which are made available (directly or indirectly) by
          third parties, we do not make any express or implied warranties or conditions, including
          of quality, merchantability, fitness for a particular purpose, title, and non-infringement.
          You expressly agree that that we shall not be held liable for (and you expressly assume
          the risk of) any matters arising out of or relating to the actions and omissions of such
          third parties or their products, services, software, and websites.`,
      }, {
          space: true,
      }, {
          heading: "Terms and Conditions applicable to the services provided by the Company",
          description: ``,
      }, {
          space: true,
      }, {
          heading: "2. Changes to these Terms",
          description: `2.1 We may, in our sole discretion, update or amend these Terms from time to time.
          Every time you wish to use our Services, please check these Terms to ensure you
          understand the terms that apply at that time. If you disagree with these Terms, now or
          as amended, you may discontinue your use of the Software and the Services by
          terminating your ${paymentProviderName} Account in accordance with Clause 12 below. Any
          continued use of the Services after a change becomes effective will constitute your agreement to have the changes to these Terms govern your use and any Transactions
          connected with your use.`,
      }, {
          description: `2.2 Changes to these terms are effective as of the effective date indicated in the
          relevant Notice provided and will apply prospectively to any use of the Services that
          take place following the effective date. If changes will reduce your rights or increase
          your responsibilities, we will provide notice to you 2 months prior to the relevant
          effective date. By continuing to use our Services after that effective date, you will be
          deemed to have agreed to be bound by the updated or amended terms.`,
      },
      {
          space: true,
      }, {
          heading: "3. Registration and Eligibility",
          description: `3.1. You may register and open a ${paymentProviderName} Account via the Software or other means
          that we may in the future prescribe. If you choose to register and open a ${paymentProviderName}
          Account, you agree to provide us with true, accurate, current, and complete information
          about yourself, which includes certain biometric data (which may include a facial scan
          and a thumbprint/fingerprint scan) and update us promptly and in writing if there are
          any changes to that information or data. By registering a ${paymentProviderName} Account, you agree
          that you have read, understood, and accept all of these Terms, including our Privacy
          Policy, Cookie Notice and Fee Schedule.`,
      }, {
          description: `3.2. You warrant that you are a natural person legally deemed as having full legal
          capacity in your own jurisdiction you reside in a country in which your access to and
          intended use of the Services is lawful and will not breach any Applicable Law. In order
          for you to prove your capacity, age and/or your location, we reserve the right to request
          from you any extra necessary information. You agree and understand that you (and not
          ${paymentProviderName}) are solely responsible for reporting of any transactions or other information
          necessary to comply the applicable tax authorities and meet any of your tax obligations.
          You agree to always refrain from engaging in or participating in any act or activity that
          damages the interests of this Software or the Company, whether or not in connection
          with the Services provided by us or this Software.`,
      }, {
          description: `3.3. In accordance with Clause 3.5 of these Terms, Card Purchase on your ${paymentProviderName}
          Account will not be activated, and you shall only be allowed to carry out Occasional
          Transactions, unless we have been provided with the required information so that we
          may identify you to our satisfaction and comply with all Applicable Law, our internal policies and KYC requirements. We shall keep records of the information and
          documents you provide in accordance with our Privacy Policy and any Applicable Law.
          We reserve the right to request you to provide us with additional identification data and
          documents. We reserve the right to withhold access to your ${paymentProviderName} Account until we
          are provided with documents and/or information that are necessary to fulfil our
          responsibilities under the Applicable Laws.`,
      }, {
          description: `3.4. We reserve the right to not accept your application for a ${paymentProviderName} Account and we
          are not obliged to provide the reason for declining your application. If we have
          reasonable grounds to suspect that any information you have provided to us is untrue,
          inaccurate or incomplete, we may suspend or terminate your ${paymentProviderName} Account and
          refuse any and all current and/or future use of the Services by you.`,
      }, {
          description: `3.5. Confirmation of these Terms does not mean that we enter into a continuous
          Business Relationship with you in regard to the Services listed in Section 4.1. If you fail
          to provide us with all KYC information required and/or fail to undergo full identification
          procedures in accordance with our internal rules and policies, we will not enter into a
          continuous Business Relationship with you, and you shall only be allowed to carry out
          Occasional Transactions within the limits outlined in Clause 4.3.`,
      }, {
          description: `3.6. By signing these Terms, you confirm that you act and use the Services in your own
          name, and not on behalf of any third party. You accept and warrant that you are fully
          responsible for, and that you are the ultimate beneficial owner for all activity, all profits
          and all losses that occur on your ${paymentProviderName} Account.`,
      }, {
          description: `3.7. Upon entering into a Business Relationship with us you:`,
      }, {
          description: `3.7.1. Confirm that you shall use Services for the following purposes:
          a. Depositing Digital Currency that is owned by you;
          b. Exchanging Digital Currency that is owned by you;
          c. Purchasing Digital Currency; and
          d. Withdrawing Digital Currency that is owned by you.`,
      }, {
          description: `3.7.2. You may, subject to any transaction limits we may set, have the ability to use the
          Services and conduct transactions up to a limit of EUR 1,000, or equivalent value in FIAT
          Currency per day, to exceed this limit you must provide any additional necessary
          information required, such as complete KYC information.`,
      }, {
          description: `3.8. Our Services and the Software can be accessed via the internet, an international
          network which you may have access to via IT devices, such as a desktop computer, a
          tablet, phone, or other similar device. You are solely responsible for the proper
          functioning, maintenance and security of your own IT devices and your internet access,
          for any purposes in connection with our Software and/or Services. You are solely liable
          for any Losses resulting from a failure by you to ensure the proper functioning,
          maintenance and security of your own IT devices and your internet access.`,
      }, {
          space: true,
      }, {
          heading: "4. The Services",
          description: `4.1. We will provide the following Services to you in accordance with these Terms: (i)
          providing access to and maintaining the Software; (ii) exchanging fiat currency using
          Card Purchase for Digital Currency paid to you; (iii) exchanging a Digital Currency for a
          different form of Digital Currency (iv) hosting a ${paymentProviderName} Account enabling you to
          store Digital Currencies; (v) managing Transactions in your ${paymentProviderName} Account,
          including tracking and messaging additional information; (vi) and any other related
          services.`,
      }, {
          description: `4.2. Your eligibility to access certain Services may depend on the country in which you
          reside in or are based in from time to time. You shall not attempt to access or make use
          of the Services from or in any jurisdiction where such access or use is prohibited by the
          Applicable Laws.`,
      }, {
          description: `4.3. Unless we enter into a continuous Business Relationship with you, you may only
          carry out Occasional Transactions, whether the transactions are carried out in a single
          transaction or several linked transactions, that adhere to the following limits (or any
          other transaction limits we may set):`,
      }, {
          description: `4.3.1. The value of a Digital Currency Exchange or other Transactions carried out in
          Digital Currency do not exceed EUR 1,000 or an equivalent amount in FIAT Currency; or`,
      }, {
          description: `4.3.2. The value of withdrawn Digital Currency does not exceed EUR 1,000 or an
          equivalent amount in FIAT Currency.`,
      }, {
          description: `4.4. We reserve the right add or remove a Digital Currency to/from the list of Supported
          Digital Currencies, and to add or remove a jurisdiction to/from the list of Prohibited
          Jurisdictions. Changes to these lists are effective as of the effective date indicated in
          relevant Notice provided. We may, in our sole discretion and without liability to you,
          with or without prior notice and at any time, modify or discontinue, temporarily or
          permanently, all or any part of our Services, which may include but is not limited to
          suspending trading in or ceasing to offer Services in respect of any Digital Currency or
          prohibiting use of the Services in or from certain jurisdictions in response to any
          Applicable Law. Any choice by us to support a Digital Currency does not amount to an
          endorsement or recommendation by us in relation to that Digital Currency or it’s
          continued support, value or maintenance.`,
      }, {
          space: true,
      }, {
          heading: `5. Access to your ${paymentProviderName} Account and use of the Services`,
          description: `5.1. You may access your ${paymentProviderName} Account by logging into the Software. From here,
          you will be able to see your ${paymentProviderName} Transaction activity and view your ${paymentProviderName}
          Account details. You should check your ${paymentProviderName} Account Transaction activity
          regularly, not less than on a monthly basis and contact us immediately in relation to any
          irregularities or enquiries. We shall not be liable for any Losses that result from any
          outdated, inaccurate or incomplete information you provide.`,
      }, {
          description: `5.2. Subject to these Terms, the value of each Outbound Transaction will be deducted
          from your Available Crypto Balance and the value of each Inbound Transaction will be
          credited to your Available Crypto Balance. You must ensure that you have a sufficient
          Available Crypto Balance from time to time to pay for your Outbound Transactions and
          any Fees. If for any reason your Available Crypto Balance is insufficient to fulfil
          requested Transactions, we will not process that Transaction, and not processing that
          Transaction will not constitute a breach of these Terms.`,
      }, {
          description: `5.3. We may refuse to authorise or may suspend any Transaction or use of the Services
          that could breach these Terms, or if we have reasonable grounds for suspecting that
          you or a third party have committed or are planning to commit fraud, or any other
          illegal or an unauthorised use of the Services. This will include freezing your Available
          Crypto Balance and/or otherwise making unavailable any of the Services to you.`,
      }, {
          description: `5.4. Your ability to use or access the Services and ${paymentProviderName} Account may occasionally
          be interrupted, for example but not limited to, if we need to carry out maintenance on
          our systems or due a Force Majeure Event. Please contact us in accordance with Clause
          17 if you wish to report any issues.`,
      }, {
          description: `5.5 You agree and warrant that you will:`,
      }, {
          description: `5.5.1. only use the Services for lawful purposes and will adhere at all times to all
          Applicable Laws and these Terms;`,
      }, {
          description: `5.5.2. not attempt to discover any source code included in the Software, upload to our
          systems any malware, viruses or other unauthorized object code or source code, or
          otherwise disrupt or interfere with the conduct of the Service;`,
      }, {
          description: `5.5.3. not copy, adapt, reverse engineer, decompile, disassemble, modify, or make error
          corrections to the Software in whole or in part, except to the extent permitted by the
          Applicable Laws.`,
      }, {
          space: true,
      }, {
          heading: `6. Depositing on to your ${paymentProviderName} Account`,
          description: `6.1. You may transfer a deposit onto your ${paymentProviderName} Account by depositing a
          Supported Digital Currency from an external Digital Currency address into your
          ${paymentProviderName} Account. Digital transfers are usually irreversible. Once you have confirmed
          your Deposit Transfer, the transfer is irreversible, and you do not have a right to cancel
          the transaction or these Terms except as provided for in Clause 12.`,
      }, {
          description: `6.2. You may also use fiat currency through a Card Purchase to Purchase Digital
          Currency through your ${paymentProviderName} Account. Once you have confirmed your Purchase,
          your transaction is irreversible, and you do not have a right to cancel the transaction or
          these Terms except as provided for in Clause 12. Any fiat used to Purchase Digital
          Currency will be definitively spent, and you will no longer be able to redeem any such
          fiat currency.`,
      }, {
          description: `6.3. We will display your Available Crypto Balance, showing the amount of each Digital
          Currency you hold in your ${paymentProviderName} Account and the estimated FIAT value of your
          Available Crypto Balance. The estimated value of your Available Crypto Balance may not accurately reflect the value you will receive if you were to sell your Digital Currency or
          the price you will pay to purchase more Digital Currency and does not include the
          impact of any applicable Fees on the value you will receive. Your Available Crypto
          Balance may fluctuate as Digital Currency can be very volatile and may fluctuate in price
          significantly and quickly.`,
      }, {
          description: `6.4. Our processing of all Deposit Transactions will be subject to our usual fraud, anti-
          money laundering and other operational and customer service checks. Accordingly,
          there may be delays in the time required for Digital Currency to appear in your
          ${paymentProviderName} Account once you have performed a Deposit Transaction.`,
      }, {
          description: `6.5. Under no circumstances should you attempt to use your ${paymentProviderName} Wallet to store,
          send, request, or receive Digital Currency in any form that is not a Supported Digital
          Currency. We assume no responsibility or liability in connection with any attempt to use
          your ${paymentProviderName} Wallet to store or transfer Digital Currency that we do not support. You
          acknowledge and agree that ${paymentProviderName} bears no responsibility and is not liable for any
          loss in connection with any Digital Currency which is not supported that is sent to your
          ${paymentProviderName} Account.`,
      }, {
          description: `6.6. If you make a Deposit Transfer or a Purchase, information on the source of funds
          will be stored securely in accordance with our Privacy Policy and Applicable Laws.`,
      }, {
          space: true,
      }, {
          heading: "7. Fees",
          description: `7.1. ${paymentProviderName} makes money when you buy or sell Supported Digital Currency on our
          Software. A description of the ${paymentProviderName} “Fees” for using your ${paymentProviderName} Account
          and/or the Services can be found at https://${paymentProviderName}.com/fees/ which displays
          ${paymentProviderName}’s Pricing and Fee Schedule.`,
      }, {
          description: `7.2. By using the Services, you agree to pay all applicable fees. ${paymentProviderName} reserves the
          right to update its Pricing and Fee Schedule at any time. We will always notify you of the
          pricing which apply to your transaction when you authorise the transaction. We may
          charge a blockchain fee (miner fees) to process a Digital Currency Transaction on your
          behalf. We will calculate the blockchain fee in our discretion, although we will always
          notify you of the blockchain fee at or before the time you authorize the Digital Currency
          Transaction.`,
      }, {
          description: `7.3. You agree that any Fees and other amounts due and payable to us under these
          Terms may be deducted from your Available Crypto Balance as they fall due. Any Fees
          will be taken from your Available Crypto Balance depending on the type of transaction
          being made and we will endeavour to combine any Fees into the displayed trading
          price. You are responsible for paying any additional fees charged by your financial
          service provider.`,
      }, {
          description: `7.4. Any changes to the Pricing and Fee Schedule are effective as of the effective date
          indicated in the relevant Notice provided. Any updated fees will apply to any sales or
          other Transactions that occur following such effective date. By continuing to use our
          Services after that effective date, you will be deemed to have agreed to be bound by the
          updated or amended Fee Schedule. You authorise ${paymentProviderName} to deduct from your
          ${paymentProviderName} Account any applicable fees that you owe under these Terms. Such
          deductions may be made at any time, and without us notifying you, but these will be
          reflected in the information in the Pricing and Fee Schedule.`,
      }, {
          description: `7.5. We will display the estimated trading price which is an estimate of value based on
          market data of Digital Currency values. However the estimated values we provide will
          only be valid for a limited time as Digital Currency can be very volatile and may fluctuate
          in price significantly and quickly. If the estimated trading price we displayed has expired
          you will not be able to complete the Transaction at those values. By confirming a
          Transaction, you agree to any applicable Fees.`,
      }, {
          space: true,
      }, {
          heading: `8. Managing your ${paymentProviderName} Account`,
          description: `8.1. You are responsible for the use of your ${paymentProviderName} Account, for ensuring that use of
          the Services or access to your ${paymentProviderName} Account complies fully with these Terms and
          for the safekeeping of your username, password, and any other Security Credentials for
          your ${paymentProviderName} Account.`,
      }, {
          description: `8.2. Do not share your Security Credentials with anyone. If you disclose your Security
          Credentials to anyone, you are responsible and liable for their access, use or misuse of
          your ${paymentProviderName} Account, their breach of these Terms, and any Losses resulting from
          such access, use, misuse and/or breach.`,
      }, {
          description: `8.3. If your Security Credentials or other ${paymentProviderName} Account details are lost, stolen, or
          otherwise compromised, or you believe a third party has unauthorised access to your
          ${paymentProviderName} Account, you must contact us immediately in accordance with Clause 17. We will take reasonable steps to stop any unauthorised use of your ${paymentProviderName} Account,
          which may include suspending or cancelling your ${paymentProviderName} Account or otherwise
          suspending access to the system and Services.`,
      }, {
          description: `8.4. If you claim there has been an unauthorised or incorrect Transaction on your
          ${paymentProviderName} Account, you must not transfer that Digital Currency before reporting the
          Transaction to ${paymentProviderName} and during the course of our investigation of your claim. If
          you transfer the proceeds of any claimed unauthorised or incorrect Transaction we will
          refuse your claim, you will not be able to recover any of the relevant Digital Currency
          and we may freeze any relevant proceeds or funds related to such Transaction pending
          and subject to the conclusion of our investigations. The amount of any proceeds of a
          suspected or confirmed unauthorised or incorrect transaction are deemed to be a debt
          due and payable to us on demand. You agree that we may charge the amount of any
          such debt against any funds subsequently loaded onto your ${paymentProviderName} Account. We
          reserve the right to recover the value or any proceeds of any unauthorised or incorrect
          transaction related to you by any other legal means.`,
      }, {
          space: true,
      }, {
          heading: `9. Changes to your ${paymentProviderName} Account details`,
          description: `9.1. You must notify us immediately of any change in your ${paymentProviderName} Account details
          and we cannot guarantee proper performance of the Services in the event the
          ${paymentProviderName} Account details you have provided are not accurate or become inaccurate
          and disclaim all liability for any Losses that may result from such inaccuracies. You can
          notify us by contacting us in accordance with Clause 17, and we may require you to
          confirm such notification in writing or through other factors of authentication (which
          may include messages or calls to your mobile phone, or confirmation mail to your
          physical address). You will be liable for any Losses that directly result from any failure
          by you to notify us of any change promptly. To verify a new address, you must provide
          any proof that we require.`,
      }, {
          description: `9.2. We reserve the right at any time to satisfy ourselves as to your identity and address
          (for example, by requesting relevant original documents), including for the purposes of
          preventing fraud and/or money laundering. In addition, at the time of your application
          and at any time in the future, we may perform, in connection with your ${paymentProviderName}
          Account, electronic identity verification checks either directly or using a KYC relevant
          Partner. You agree to provide us with the information we request for purposes of
          identity verification and the detection of money laundering, terrorist financing, fraud or
          any other financial crime, and we will keep a record of such information in accordance
          with Applicable Laws and our Privacy Policy. If you fail to provide the requested information, we reserve the right to suspend your ${paymentProviderName} Account and use of the
          Services pending receipt and verification of that information.`,
      }, {
          space: true
      }, {
          heading: "10. Proprietary Rights",
          description: `10.1 All rights, title and interest in, and to, the Intellectual Property Rights subsisting in,
          or embodied by the Software, the Services, any proprietary software or other
          technology required to operate the Software, the Services, and any modifications or
          improvements thereto, including any derivative works, are retained by us and protected
          under applicable Intellectual Property Rights and Applicable Laws.`,
      }, {
          description: `10.2 By activating your ${paymentProviderName} Account, we grant you a limited permission to use the
          Software solely for the purpose of using the Services in accordance with these Terms. In
          particular and without limitation, this permission does not grant you the right to create,
          author or invent any modifications or improvements to, or derivative works of the
          Software. We may suspend or terminate this permission in accordance with Clause 12.
          Notwithstanding the foregoing, nothing in these Terms grants you any permission or
          right to use our trademarks, service marks, trade dress, slogans, logos or other indicia
          of origin.`,
      }, {
          description: `10.3 If you submit any ideas, suggestions or other feedback to us about the Software or
          the Services, you grant to us the right to use or disclose such feedback without any
          further obligation to you. All rights not expressly granted to you under these Terms are
          reserved by us.`,
      }, {
          space: true,
      }, {
          heading: "11. Right to Cancel",
          description: `11.1. You have the right to cancel your ${paymentProviderName} Account and these Terms for any
          reason within a ‘cooling-off’ period of fourteen (14) days from the date on which your
          ${paymentProviderName} Account is registered. You must contact us within this fourteen (14) day
          period and inform us that you wish to withdraw from these Terms, and you must not
          make any Transactions within this fourteen (14) day period. We will then cancel your
          ${paymentProviderName} Account and reimburse your Available Crypto Balance to an address
          provided by you within fourteen (14) Business Days of our receipt of your cancellation
          request.`,
      }, {
          description: `11.2. If you fail to exercise your right to cancel under Clause 11.1, then these Terms
          shall remain in full force and effect, and you may only terminate your ${paymentProviderName}
          Account and these Terms in accordance with Clause 12.`,
      }, {
          space: true,
      }, {
          heading: "12. Termination and Suspension",
          description: `12.1. If you wish to terminate your ${paymentProviderName} Account at any time, you must inform us
          of your wish to terminate through the Software or by contacting us in accordance with
          Clause 17.`,
      }, {
          description: `12.2. We may in our sole discretion and without liability to you terminate or suspend
          your ${paymentProviderName} Account, the provision of the Services and these Terms for any reason
          by giving you fourteen (14) days’ notice. We may in our sole discretion and without
          liability to you also limit, terminate or suspend your ${paymentProviderName} Account, your use of the
          Services and these Terms immediately in the event that: (i) We believe that you have
          used or are likely to use the Services, or allow them to be used, in breach of any of
          these Terms; (ii) We believe or suspect that you are in any way involved in any
          fraudulent activity, money laundering, terrorism financing, other criminal activity, other
          breach of Applicable Laws, or if we have any other security or legal or regulatory
          compliance concerns; (iii) We believe that you have provided false or misleading
          information; (iv) We believe that you are involved in any dispute or disagreement with
          any of our Partners whether that dispute or disagreement relates to the Services or
          otherwise; (v) We are unable to verify your identity or any other information pertaining
          to you, your ${paymentProviderName} Account or a Transaction; (vi) We are required to do so in
          accordance with our legal and regulatory obligations under Applicable Laws; or (vii) We
          cease to be authorised to provide the Services; (viii) We no longer support a Digital
          Currency held in your ${paymentProviderName} account; (ix) the jurisdiction in which you access the
          Services is added to the Prohibited Jurisdiction List; (x) We reasonably believe it is
          necessary for any other reason. We are not liable for any Losses you may incur (and you
          are not entitled to any gains you make) as a result of your ${paymentProviderName} Account being
          limited, suspended, or terminated.`,
      }, {
          description: `12.3. If there have been no Transactions or other activity on your ${paymentProviderName} Account for
          a period of at least one (1) year, we reserve the right to terminate it and these Terms.
          We will give you 14 days prior notice of any such termination. However, if you log in or
          make a Transaction during this 14 day period we will not terminate your account once
          such period expires.`,
      }, {
          description: `12.4. Upon termination of these Terms and your ${paymentProviderName} Account in accordance with
          this Clause 12, all pending Transactions will be processed and, if your ${paymentProviderName}
          Account has a positive balance, we will redeem your Available Crypto Balance, minus
          any applicable Fees, to an address that you nominate, within thirty (30) Business Days’
          of the date on which you inform us of your wish to terminate your ${paymentProviderName} Account or on which termination otherwise occurs in accordance with this Clause 12. Unless
          otherwise expressly stated in Clause 12, the provisions of Clause 15 shall apply in such
          circumstances.`,
      }, {
          description: `12.5. Upon termination of your ${paymentProviderName} Account for any reason, we may continue to
          process your personal data in accordance with the terms of our Privacy Policy and
          Cookie Notice as applicable.`,
      }, {
          description: `12.6. The Terms in effect as of the date of the termination of your ${paymentProviderName} Account
          will survive and continue to apply to any aspects of your prior use of the Software or the
          Services, including to your ${paymentProviderName} Account information, your Available Crypto
          Balance, any outstanding Transactions, and any relationship between you and a Partner
          formed with the assistance of the Software or the Services.`,
      }, {
          description: `12.7. If there is a Digital Currency balance remaining in your account after termination,
          you agree to provide us with an external digital wallet address, so that we can attempt
          to return the remaining Digital Currency to you within a period of ninety (90) days. You
          are not permitted to use the Services or your ${paymentProviderName} Account for any purposes
          during this period and we may, at our discretion, limit the functionality of your
          ${paymentProviderName} Account and/or your access to the Software accordingly.`,
      }, {
          description: `12.8. If we suspend or terminate your ${paymentProviderName} Account or your use of the Services for
          any reason, we reserve the right to require you to provide us with all KYC information
          and undergo full identification procedures in accordance with our internal rules and
          policies and any Applicable Law before returning any Digital Currency to you.`,
      }, {
          space: true,
      }, {
          heading: "13. Redemption",
          description: `13.1. We will redeem, either in part or in full, the Digital Currency of the Available Crypto
          Balance on your ${paymentProviderName} Account, at any time, at par value, following instructions
          given by you to do so and subject to your payment of any applicable Fees, compliance
          with Applicable Laws and the remainder of this Clause 13.`,
      }, {
          description: `13.2. We reserve the right to carry out any necessary anti money laundering, terrorist
          financing, fraud and other illegal activity and operational checks before authorising any
          redemption or transfer of funds to you, including returning any funds after the termination of these Terms. Accordingly, there may be delays in the time between our
          receipt of your redemption request and your receipt of the relevant funds.`,
      }, {
          description: `13.3. We reserve the right to charge a Fee when you require redemption either in full or
          in part of the Available Crypto Balance on your ${paymentProviderName} Account before the
          termination of these Terms (other than if you cancel during the ‘cooling-off’ period
          pursuant to Clause 11).`,
      }, {
          description: `13.4. After we have approved the redemption of your funds, you must ensure that the
          address for the transfer is accurate and complete. Where you provide us with incorrect
          details, we will not be held liable for funds sent to the incorrect address where we have
          acted in accordance with your instructions. We reserve the right to charge you a Fee in
          the event that you request our assistance in retrieving Digital Currency from an
          incorrect address or when you have transferred the wrong Digital Currency onto your
          ${paymentProviderName} Account.`,
      }, {
          space: true,
      }, {
          heading: "14. Tax",
          description: `14.1. It is your (and not ${paymentProviderName}’s) sole responsibility to determine what tax
          obligations you may have and to comply with any such tax obligations owed such as
          reporting and paying any relevant tax to any applicable tax authority.`,
      }, {
          space: true,
      }, {
          heading: "15. Liability",
          description: `15.1. Nothing in these Terms shall limit or exclude our liability: (i) For fraud or
          fraudulent misrepresentation; (ii) For death or personal injury caused by our negligence;
          or (iii) Where such limitation or exclusion would be contrary to Applicable Law.`,
      }, {
          description: `15.2. Except as provided in Clause 15.1, we shall not be liable under or in connection
          with these Terms, whether in contract, tort (including negligence), breach of statutory
          duty or otherwise:`,
      }, {
          description: `15.2.1. For any:
          a. indirect, incidental, special or consequential loss or damage of any kind; or
          b. loss of profits, business, revenue or savings (actual or anticipated), loss of
          agreements or contracts, loss of opportunity, loss of data or loss of, or damage to,
          goodwill or reputation (including as a result of your inability to participate in any goods, 
              services or offerings of a Partner, to the extent that it is caused by a failure in the
              Software, the Services or delays in the time required for the Purchase of Digital
              Currency to appear in your ${paymentProviderName} Account once you have performed a Deposit
              Transaction);`,
      }, {
          description: `15.2.2. For any Losses in connection with the rights, privileges, licenses, goods or
          services that are purchased with your ${paymentProviderName} Account from a Partner or the terms
          on which they are provided by a Partner such as; (i) If a Partner refuses to accept an
          Outbound Transaction or other payment..`,
      }, {
          description: `15.3. Subject to Clause 15.1, The Services and Software, in whole and in part, are
          provided on an “as is” and “as available” basis, without express or implied warranties of
          any kind, including without limitation warranties of merchantability, fitness for a
          particular purpose, title or non-infringement or warranties arising from the course of
          performance, course of dealing or usage in trade.`,
      }, {
          description: `15.4. Except as provided in Clauses 15.1 and 15.7 and subject to Clause 15.2 and any
          Applicable Laws, in no event shall the aggregate liability of ${paymentProviderName} (including our
          directors, members, employees and agents), whether in contract, warranty, tort
          (including negligence) product liability, breach of statutory duty, arising out of or
          relating to the use of or inability to use ${paymentProviderName} Services, the Software and/or these
          Terms exceed the Fees paid by you to ${paymentProviderName} during the 6 months preceding the
          date of any claim giving rise to such liability or an equivalent amount in FIAT Currency.`,
      }, {
          description: `15.5. You are solely responsible for your interactions with Partners. We reserve the
          right, but have no obligation, to monitor or mediate any disputes between you and any
          Partners.`,
      }, {
          description: `15.6. You agree to indemnify and hold harmless ${paymentProviderName} and our directors, officers,
          employees, agents, affiliates and subcontractors against any and all claims, actions,
          proceedings, investigations, demands, suits and Losses suffered or incurred by us that arise out of, or in connection with, any breach by you, of any of these Terms or
          Applicable Law, any enforcement by us of these Terms and/or your violation of any
          rights of any third party.`,
      }, {
          description: `15.7. Except as provided in Clause 15.1 and subject to Clause 15.2, where we have
          incorrectly and due to our fault:`,
      }, {
          description: `15.7.1. Deducted amounts from your Available Crypto Balance, our liability shall be
          limited to payment to you of an equivalent amount or;`,
      }, {
          description: `15.7.2. Credited too low an amount to your Available Crypto Balance our liability shall
          be limited to payment to you of an incremental amount that ensures you have received
          the correct amount due, provided that, in each case, if we subsequently establish that
          the refunded or incremental credited amount had been correctly deducted or credited
          (as the case may be), we may deduct it from your Available Crypto Balance and, if you
          do not have a sufficient balance, you must repay us the amount immediately upon
          demand.`,
      }, {
          description: `15.8. Except as provided in Clause 15.1, we shall not be liable for Losses relating to any
          unauthorised Transactions resulting from the use of lost or stolen Security Credentials
          and/or ${paymentProviderName} Account details or misappropriation of your ${paymentProviderName} Account,
          except to the extent that the relevant Losses are caused by our breach of these Terms
          or our failure to authenticate a Transaction in accordance with our then published
          authentication documentation. Otherwise than as set out in the preceding sentence,
          you shall be liable for Losses relating to any unauthorised Transactions resulting from
          the use of lost or stolen Security Credentials and ${paymentProviderName} Account details or
          misappropriation of your ${paymentProviderName} Account.`,
      }, {
          description: `15.9. Notwithstanding Clause 15.8, and except as provided in Clause 15.1, we shall not
          be liable for any unauthorised or incorrectly executed Transactions, including but not
          limited to in the event that the Transaction was affected by a Force Majeure Event or
          where we acted in accordance with a legal or regulatory obligation or other Applicable
          Laws.`,
      }, {
          description: `15.10. We do not guarantee the identity of any user, receiver, requestee or other third
          party (including other users of our Services) and we will have no liability or responsibility
          for ensuring that the information provided by any user is accurate and complete.`,
      }, {
          description: `15.11. We have no control over, or liability for, the delivery, quality, safety, legality or any
          other aspect of any goods or services that you may purchase or receive from, or sell or
          transfer to, any third party (including other users of our Services). We are not
          responsible for ensuring that a third party you transact with will complete the
          Transaction or is authorised to do so.`,
      }, {
          description: `15.12. If you believe a third party has behaved in a fraudulent, misleading, or
          inappropriate manner, or if you cannot adequately resolve a dispute with a third party,
          you may notify ${paymentProviderName} Support in accordance with clause 17.`,
      }, {
          space: true,
      }, {
          heading: "16. Force Majeure",
          description: `16.1. We shall not have any liability under, or be deemed to be in breach of, these
          Terms for any delays or failures in performance of any of our obligations under these
          Terms that result from an event out of our control or any other Force Majeure Event.`,
      }, {
          space: true,
      }, {
          heading: "17. Contact Details",
          description: `17.1. If you have any questions or concerns about the Services, or want to report issues
          with your ${paymentProviderName} Account, please contact us by email at support@${paymentProviderName}.com`,
      }, {
          space: true,
      }, {
          heading: "18. Miscellaneous",
          description: `18.1. We may assign or transfer all or any of our rights or obligations under these Terms
          to any third party (including by way of merger, consolidation or the acquisition of all or
          substantially all of our business and assets relating to these Terms).`,
      }, {
          description: `18.2. You may only assign or transfer your rights or your obligations under these Terms
          to a third party if we give our prior written consent to this.`,
      }, {
          description: `18.3. A person who is not a party to these Terms has no right to enforce any part of
          these Terms. The rights to terminate, rescind or agree any variation, waiver or
          settlement under these Terms are not subject to the consent of any person that is not a
          party to these Terms.`,
      }, {
          description: `18.4. Nothing in these Terms is intended to, or shall be construed so as to, establish or
          imply any partnership or joint venture or a relationship of principal and agent between
          you and us or constitute either of you or us as the agent of the other party, or authorise
          you or us to make or enter into any commitments for or on behalf of the other party`,
      }, {
          description: `18.5. If any provision of these Terms is held to be invalid, illegal or unenforceable in any
          respect under the law of any jurisdiction, the validity, legality and enforceability of the
          remaining provisions shall not in any way be affected or impaired thereby, and such
          provision shall be deemed to be restated to reflect yours and our original intentions as
          nearly as possible in accordance with Applicable Laws.`,
      }, {
          description: `18.6. No waiver of any of our rights under these Terms shall be effective unless in
          writing. Unless expressly stated otherwise, a waiver shall be effective only in the
          circumstances for which it is given, and no delay or omission by us in exercising any
          right or remedy provided by law or under these Terms shall constitute a waiver by us of
          such right or remedy.`,
      }, {
          description: `18.7. These Terms and the documents referred to in it, constitute the whole agreement
          between you and us relating to the subject matter of these Terms and supersedes any
          prior written or oral arrangement, understanding or agreement between them relating
          to such subject matter.`,
      }, {
          description: `18.8. These Terms are provided to you in the English language. Where we have provided
          a translation of these Terms to you, you agree that such translation is provided for your
          convenience only and that the English language version of these Terms will govern your
          relationship with us. If there is any contradiction between the English language version
          of these Terms and any translation, the English language version takes precedence.`,
      }, {
          space: true,
      }, {
          heading: "19. Dispute Resolution",
          description: `19.1. If you have any complaints or want to raise a dispute we encourage you to raise
          these directly with us so that we can attempt to resolve any issue at first instance to
          both parties’ satisfaction. You agree that if the parties attempt to resolve a dispute this
          way the parties agree to act in good faith to resolve the dispute and during the conduct
          of any negotiations. The parties acknowledge that after raising a dispute they may agree
          to refer the case to arbitration by concluding an arbitration agreement.`,
      }, {
          description: `19.2. You and ${paymentProviderName} agree to give written notification to the other party within
          thirty (30) days of the dispute or claim arising, or of your intention to initiate such claim
          or dispute arising.`,
      }, {
          description: `19.3. The parties agree that any claims or disputes shall be kept confidential. The
          existence any claim or dispute, any non-public and any other confidential information
          provided (collectively, the “Confidential Information”) shall not be disclosed to any non-
          party. A party may only disclose Confidential Information to a non-party to the extent
          that such disclosure is required to fulfil a legal duty, assert a legal right, or otherwise as
          required by law. This confidentiality provision shall survive termination of these Terms.`,
      }, {
          description: `19.4. Any formal claim or dispute against ${paymentProviderName} must be initiated by submitting the
          claim or dispute to the courts of the Republic of Lithuania in the shortest statutory
          limitation period permitted by the Governing Law after the date the party bringing the
          claim knows or reasonably could have known of the facts giving rise to the claim; and
          there shall be no remedy for any claim asserted after that time period.`,
      }, {
          space: true,
      }, {
          heading: "20. Governing Law and Jurisdiction",
          description: `20.1. These Terms, and any noncontractual obligations arising out of or in connection
          with these Terms, are governed by, and shall be construed in accordance with, the
          Governing Law.`,
      }, {
          description: `20.2. Each party irrevocably agrees that the courts of Poland shall have exclusive
          jurisdiction to settle any dispute or claim (including non-contractual disputes or claims)
          arising out of or in connection with the Services, these Terms or their subject matter or
          formation, unless they are settled by negotiations between the parties or are submitted
          to arbitration by the parties executing an arbitration agreement.`,
      }, {
          space: true,
      }, {
          heading: "21. Notice",
          description: `21.1. You may serve notices to us in writing at the contact details in Clause 17.`,
      }, {
          description: `21.2. You accept and agree that we may provide you with Notices and other disclosures
          in connection with your ${paymentProviderName} Account and use of the Services.`,
      }, {
          space: true,
      },
      {
        heading: "Refund policy",
        description: ``,
      },
      {
        space: true,
      },
      {
        description: `22.General policy`,
      },
      {
        space: true,
      },
      {
        description: `22.1 Our platform allows customers to purchase cryptocurrencies using various card payment methods. Due to the volatile nature of cryptocurrencies and the real-time processing of Transactions, all purchases of cryptocurrencies on our platform are considered final and non-refundable.`,
      },
      {
        space: true,
      },
      {
        description: `22.2 By using our services and completing a Transaction, you acknowledge and agree that the Purchase of any cryptocurrency is subject to risks, including but not limited to price volatility and the irreversible nature of blockchain Transactions.`,
      },
      {
        space: true,
      },
      {
        description: `Exceptions`
      },
      {
        space: true,
      },
      {
        description: `22.3 Notwithstanding the foregoing, we may, at our sole discretion, consider refund requests under the following exceptional circumstances:`
      },
      {
        space: true,
      },
      {
        description: `22.3.1 Unauthorised Transactions: If a Transaction was unauthorised or fraudulent, and you provide timely notification in accordance with our fraudulent Transaction policy.`
      },
      {
        space: true,
      },
      {
        description: `22.3.2 Transaction error: If there was a clear error in the transaction, such as a mistaken Transaction amount due to a technical glitch on our platform.`
      },
      {
        space: true,
      },
      {
        description: ` 22.4 All requests for refunds under these exceptions must be submitted in writing within 48 hours of the Transaction. Each request will be evaluated on a case-by-case basis, and we reserve the right to require documentation or evidence to support your claim.`
      },
      {
        space: true,
      },
      {
        description: `Process and conditions`
      },
      {
        space: true,
      },
      {
        description: ` 22.5 If a refund is granted by us under the exceptions outlined above, the refund amount will be credited to the same payment method used for the original Transaction. Refunds will be processed in the original currency of the Transaction.`
      },
      {
        space: true,
      },
      {
        description: ` 22.6 Any Transaction fees, including but not limited to processing fees, network fees, or bank charges, are non-refundable. If a refund is granted, such fees will be deducted from the refund amount.`
      },
      {
        space: true,
      },
      {
        description: ` 22.7 Due to the nature of Digital Currency Transactions, we cannot guarantee any specific timeframe for processing a refund. The timing may depend on various factors, including, but not limited to, the policies of the card-issuing bank.`
      },
      {
        space: true,
      },
      {
        description: `No guarantees`
      },
      {
        space: true,
      },
      {
        description: `22.8 While we will consider refund requests in exceptional circumstances, we make no guarantees that any request will be approved. The decision to grant a refund is at our complete discretion, based on the merits and documentation provided.`
      },
      {
        space: true,
      },
  ]
}
return allData[host];
  };
  