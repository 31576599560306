import React, { useEffect, useState } from 'react';
import B2CHeaderAndFooter from '../../../Layouts/B2CHeaderAndFooter/B2CHeaderAndFooter';
import { getTermsData } from './TermsOfUseData';

const TermsOfUse = () => {
	const [host, setHost] = useState("")
	const [paymentProviderName, setPaymentProviderName] = useState(null);
    const termsData = getTermsData(host, paymentProviderName);
	useEffect(() => {
		const hostName = window.location.hostname.includes("coinsspirit") ? "coinsspirit" : "coinsswapcenter";
		setHost(hostName)
		if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
			setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
		  }
	}, [])
	
	return (
		<B2CHeaderAndFooter>
			<div className="">
				<div className="header__layout"></div>
				<section className="section legal">
					<div className="content-container">
						<p className="section-title mb">Terms of Use</p>
						{termsData && termsData.length > 0
							? termsData.map((data, i) => {
								if (data.heading) {
									return (<div key={i}><h4 className="legal__title">{data.heading}</h4>
										<p className="legal__desc">{data.description}</p>
									</div>)
								} else if (data.description) {
									return (<p key={i} className="legal__desc">{data.description}</p>)
								} else if (data.space) {
									return <div key={i}><p><br /></p></div>
								} else return null
							})
							: <div style={{ marginTop: "100px", color: 'lightgray' }}><p style={{ textAlign: "center" }}>No terms found.</p></div>
						}
					</div>
				</section>
			</div>
		</B2CHeaderAndFooter>
	);
}

export default TermsOfUse;
