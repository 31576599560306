import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import * as actions from '../../../Redux/actions';
import { useDispatch } from 'react-redux';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';

const InvoiceDetails = (props) => {
  const { open, handleClose, invoiceDetailsData, handleBack, handleConfirm, allCryptos, selectedCryptos, image_base_url } = props;
  const [colorMode, setColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
    if (checkValueInSS("colorMode")) {
      setColorMode(sessionStorage.getItem("colorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, []);

  const dispatch = useDispatch();
  const setLoading = (data) => {
    dispatch(actions.setLoading(data));
  };

  const iconsDisplay = () => {
    return (
      <div className="currency-icons-display-details">
        {selectedCryptos &&
          selectedCryptos.map((obj, index) => {
            const foundObject = allCryptos.find((symbol) => symbol.symbol === obj);
            return <img key={index} src={image_base_url + foundObject.icon} alt={index} width={25} height={25} />;
          })}
      </div>
    );
  };

  let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog open={open} className="b2c-signup-model deposit_model_mobile">
        <div className="enable-authenticator-model" style={{ width: '461px' }}>
          <Card style={{ width: '461px' }} className="card-bg">
            <div className="p-0 mt-4 invoice-modal-header enable-authenticator-header">
              <div className="create-invoice-modal-header">{`New Invoice`}</div>
              <div className="invoice-close-icon signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={handleClose} className="" />
              </div>
            </div>
            <div className="invoice-details-header"> Please confirm invoice details</div>
            {invoiceDetailsData &&
              invoiceDetailsData.map((detail, index) => {
                if (index < 4) {
                  return (
                    <div key={index + 1} className="invoice-details-container">
                      <div className="invoice-details-key">{detail.key}:</div>
                      <div className="invoice-details-value">
                        {index === 0 && (
                          <div className="invoice-details-icons">
                            {iconsDisplay()}
                            <div>{detail.value}</div>
                          </div>
                        )}
                        {index !== 0 && detail.value}
                      </div>
                    </div>
                  );
                }
              })}
            <button className={'create-invoice-btn-handler mt-4'} style={{ ...headerButtonStyle }} onClick={handleConfirm}>
              <div data-e2e="confirm-btn">CONFIRM</div>
            </button>
            <div onClick={handleBack} data-e2e="back-btn" className="invoice-details-back">
              Back
            </div>
          </Card>
        </div>
      </Dialog>
    </div>
  );
};

export default InvoiceDetails;
