import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';


export default function PaysuFooter() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const navigate = useNavigate()
  const [paymentProviderId, setPaymentProviderId] = useState(null)
  const [domainId, setDomainId] = useState(null)
  const theme = useSelector((state) => state.common.theme);
  const logo = theme === 'light' ? '/homeb2c/cryptoLightLogo.png' : '/common/cryptonpay_logo.svg';

  useEffect(() => {
    if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== "" && sessionStorage.getItem("paymentProviderId") !== "null") {
      setPaymentProviderId(sessionStorage.getItem("paymentProviderId"));
    }
    
    if (sessionStorage.getItem("domainId") && sessionStorage.getItem("domainId") !== "" && sessionStorage.getItem("domainId") !== "null") {
      setDomainId(sessionStorage.getItem("domainId"));
    }
  }, []);

	return (
		<footer className="ps-footer">
			<img className="ps-footer-deco" src={`${image_base_url}/paysu/ps-footer-deco.webp`} />
			<div className="content-container">
				<img src={`${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/logo${theme === 'light' ? "" : "dark"}.png` : logo}`} className="ps-footer-logo" />
				<div className="ps-footer-divider"></div>
				<div className="ps-footer-bottom">
					<p className="ps-footer-text">© PaySu 2023. All rights reserved.</p>
					<div className="ps-footer-divider"></div>
					<div className="ps-footer-links">
						{/* <Link to="/terms-of-use" className="ps-footer-text">Terms & Conditions</Link> */}
						<button onClick={() => {
							navigate('/cookies-policy')
							window.scrollTo(0, 0);
						}} className="ps-footer-text">Cookie Policy</button>
					</div>
				</div>
				<div className="ps-footer-desc">
					<p className="ps-footer-text">PaySu is wholly owned by PBS Operations Europe UAB, PBS Operations Europe UAB is licensed and regulated in Europe to hold and to trade virtual currencies. Architektų g. 56-101,Vilnius, Lithuania #306205545. <a href="mailto:compliance@paysu.net." target="_blank">compliance@paysu.net.</a></p>
				</div>
			</div>
		</footer>
	)
}