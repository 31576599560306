import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import localDb from '../../../common/localDb';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import SideToolsMenuLayout from '../../Layouts/SideToolsMenu/SideToolsMenuLayout';
const bgImage = "/home/homemainbg.png";

const APIGuide = (props) => {
    const [paymentProviderId, setPaymentProviderId] = useState(null)
    const [domainId, setDomainId] = useState(null)
    const [paymentProviderName, setPaymentProviderName] = useState(null)
    useEffect(() => {
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
        }
        if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== "" && sessionStorage.getItem("paymentProviderId") !== "null") {
            setPaymentProviderId(sessionStorage.getItem("paymentProviderId"));
        }
        if (sessionStorage.getItem("domainId") && sessionStorage.getItem("domainId") !== "" && sessionStorage.getItem("domainId") !== "null") {
            setDomainId(sessionStorage.getItem("domainId"));
        }
    }, []);
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const theme = useSelector((state) => state.common.theme);
    const baseUrl = localDb.getVal("domainNameUrl") && localDb.getVal("domainNameUrl") !== "null" ? localDb.getVal("domainNameUrl") : "https://cryptonpay.com";
    const baseTestUrl = localDb.getVal("domainNameUrl") && localDb.getVal("domainNameUrl") !== "null" ? localDb.getVal("domainNameUrl") : "https://dev.cryptonyte.org";
    return (
        <HeaderAndFooterLayout>
            <SideToolsMenuLayout>
                <div className="merchantToolsContent_main">
                    <img
                        src={`${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/bg${theme === 'light' ? "" : "dark"}.png` : bgImage}`}
                        onError={() => {
                            setPaymentProviderId(null);
                            setDomainId(null);
                        }}
                        style={{ opacity: 0, position: "absolute", height: "1px", zIndex: -1 }}
                    />
                    <div className="tool_main APIGuide_main" style={{
                        ...(paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? { backgroundPositionX: "0px", backgroundPositionY: "0px" } : {}),
                        backgroundImage: `url(${image_base_url}${paymentProviderId && paymentProviderId !== "" && paymentProviderId !== "null" ? `/payment-provider/${paymentProviderId}${domainId ? ("/" + domainId) : ""}/bg${theme === 'light' ? "" : "dark"}.png` : bgImage})`
                    }}>
                        <div className="tool_heading" data-e2e="apiGuide">API GUIDE</div>
                        <div className="tools_content_wrap">
                            <div className="tools_left_part">
                                <div className="tools_content_text">
                                    {`The ${paymentProviderName ? paymentProviderName : "Crypton Pay"} API is built up from a group of REST endpoints.`}<br />
                                    Standard HTTP response codes and authentication are used to make the integration simple and familiar.
                                </div>
                                <div className="tools_content_text" style={{ marginBottom: "15px" }}>
                                    Base Production URL: <a className="tools_content_link">{baseUrl}</a>
                                </div>
                                <div className="tools_content_text">
                                    Base Test URL:  <a className="tools_content_link">{baseTestUrl}</a>
                                </div>
                            </div>
                            <div className="tools_right_part">
                            </div>
                        </div>
                    </div>
                </div>
            </SideToolsMenuLayout>
        </HeaderAndFooterLayout>
    )
}

export default APIGuide