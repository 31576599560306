import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { apiService } from '../../../common/apiCallService';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
// const WAValidator = require('wallet-address-validator');
// var testVariable;

const WithdrawModal = (props) => {
    const {testVariable, setTestVariable} = props //for e2e purpose don't remove
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const api_url = useSelector((state) => state.config.api_url);
    const payment_api_url = useSelector((state) => state.config.payment_api_url);
    const [openCryptoOption, setOpenCryptoOption] = useState(false);
    const [cryptos, setCryptos] = useState([]);
    const [amount, setAmount] = useState();
    const [currentCrypto, setCurrentCrypto] = useState("");
    const [fromAddress, setFromAddress] = useState("");
    const [toAddress, setToAddress] = useState("");
    const [network, setNetwork] = useState("");
    const [error, setError] = useState("");
    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);
    const [merchantWithdrawalAddresses, setMerchantWithdrawalAddresses] = useState([]);
    const [currentMerchantWithdrawalAddress, setCurrentMerchantWithdrawalAddress] = useState(null);
    const [openMerchantWithdrawalAddress, setOpenMerchantWithdrawalAddress] = useState(false);
    const merchantId = localStorage.getItem("merchant_Id");

    useEffect(() => {
        getGasFee();
        getCryptos();
    }, []);

    useEffect(() => {
        if (currentCrypto) {
            getMerchantWithdrawalAddresses();
        }
    }, [currentCrypto]);

    useEffect(() => {
        getMerchantBalance();
        setFromAddress(props.blockchainAddress);
        setNetwork(props.blockchainNetwork);
    }, [props.coin]);

    const [balance, setBalance] = useState(0);

    const getMerchantWithdrawalAddresses = () => {
        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-merchant-withdrawal-addresses',
            {
                merchantId,
                blockchain: currentCrypto.blockchain,
            },
            (data) => {
                props.setLoading(false);
                if (data) {
                    setMerchantWithdrawalAddresses(data);
                    if(data.length !== 0) {
                      setCurrentMerchantWithdrawalAddress(data[0]);
                    }
                }
            },
            (err) => {
                props.setLoading(false);
                console.log(err);
            }
        )
    }

    const getMerchantBalance = () => {
        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-merchant-balance-by-crypto',
            {
                user_email: localStorage.getItem("user_name"),
                merchantId: localStorage.getItem("merchantId"),
                cryptoSymbol: props.coin,
            },
            (data) => {
                if (data) {
                    props.setLoading(false);
                    setBalance(data);
                }
            },
            (err) => {
                props.setLoading(false);
                console.log(err);
            },
        );
    };

    const getCryptos = () => {
        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-cryptos',
            {
                user_email: localStorage.getItem("user_name"),
            },
            (data) => {
                if (data.cryptos) {
                    props.setLoading(false);
                    setCryptos(data.cryptos);
                    let cryp = {};
                    data.cryptos && data.cryptos.length > 0 && data.cryptos.find((ele) => {
                        if (ele.symbol === props.coin) {
                            cryp = ele;
                        }
                    });
                    setCurrentCrypto(cryp);
                }
            },
            (err) => {
                props.setLoading(false);
                console.log(err);
            },
        );
    };

    const handleClose = () => {
        props.onClose();
    };

    const handleCrypto = () => {
        setOpenCryptoOption(!openCryptoOption);
    };

    const handleMerchantWithdrawalAddress = () => {
        setOpenMerchantWithdrawalAddress(!openMerchantWithdrawalAddress);
    }

    const handleSelectCryptoOptions = (ele) => {
        let blockchain = ele.symbol === "BTC" ? "Bitcoin" : "Ethereum";
        props.handleCoinChange(ele.symbol, blockchain);
        setCurrentCrypto(ele);
        handleCrypto();
        setAmount(0);
        setBalance(0);
    };

    const handleSelectMerchantWithdrawalAddress = (address) => {
        setCurrentMerchantWithdrawalAddress(address);
        handleMerchantWithdrawalAddress();
    }

    const [gasFeeMapper, setGasFeeMapper] = useState({
        'BTC': 0,
        'ETH': 0
    });
    const [resTotal, setResTotal] = useState(0)
    const [gasValidation, setGasValidation] = useState({
        type: '',
        chargeGas: true,
        allowAhead: false
    });

    useEffect(() => {
        if (gasValidation.chargeGas) {
            return setResTotal(parseFloat(amount) + parseFloat(calcCrypFee()))
        }
        setResTotal(parseFloat(amount))
    }, [amount, props.coin, gasFeeMapper, gasValidation.chargeGas])

    useEffect(() => {
        if (checkValueInSS("colorMode")) {
            setColorMode(sessionStorage.getItem("colorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        }
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        }
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
        let interval = setInterval(getGasFee, 30000)
        return () => clearInterval(interval)
    }, []);

    const calcCrypFee = () => {
        if (props.coin === "BTC") {
            return gasFeeMapper['BTC']? gasFeeMapper['BTC'] : 0
        } else {
            return gasFeeMapper['ETH']? gasFeeMapper['ETH'] : 0 
        }
    };

    const getGasFee = () => {
        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/fetch-gas',
            {
            },
            (data) => {
                props.setLoading(false);
                if (data) {
                    setGasFeeMapper(data)
                }
            },
            (err) => {
                props.setLoading(false);
                console.log(err);
            },
        );
    };

    const notEnoughBalance = balance <= resTotal

    const disable = (
        balance <= resTotal
        || Number.isNaN(resTotal)
        || (!currentMerchantWithdrawalAddress || currentMerchantWithdrawalAddress.address.length < 8)
        || amount <= 0
    );

    const handleWithdraw = () => {
        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/create-merchant-withdraw-transaction',
            {
                user_email: localStorage.getItem("user_name"),
                merchantId: localStorage.getItem("merchantId"),
                merchant_Id: merchantId,
                blockchain: currentCrypto.blockchain,
                amount: amount,
                currency: "USD",
                recipientWallet: currentMerchantWithdrawalAddress.address,
                fromAddress: fromAddress,
                cryptoCurrency: props.coin,
                network: network,
            },
            (data) => {
                props.setLoading(false);
                if (data) {
                    setTestVariable(data.transactionID)
                    props.openConfirmationModal();
                    setTimeout(() => {
                        handleClose();
                    },5000);
                }
            },
            (err) => {
                props.setLoading(false);
                console.log(err);
            },
        );
    }
    let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

    return (
        <div >
            <Dialog open={props.open} className="withdraw-model-dialog b2c-signup-model deposit_model_mobile">
                <div className="enable-authenticator-model">
                    <Card className="card-bg">
                        <div className="enable-authenticator-header">
                            <div></div>
                            <div data-e2e="receive-crypto-window">{`Withdraw`}</div>
                            <div className="signup-close-icon" data-e2e="close-icon">
                                <ClearIcon onClick={() => handleClose()} className="" />
                            </div>
                        </div>
                        <div className="crypto_heading">{`Cryptocurrency`}</div>
                        {cryptos && cryptos.length > 0 ? (
                            <div className="enable-authenticator-content">
                                <div
                                    className={openCryptoOption ? 'kyc-seleted-options kyc-select-options cursor-pointer merchantBorderColor' : 'kyc-select-options kyc-select cursor-pointer merchantBorderColor'}
                                    onClick={() => handleCrypto()}
                                >
                                    <div className="verify-onboard-sub-heading">
                                        {currentCrypto && currentCrypto !== '' ? (
                                            <div>
                                                {' '}
                                                <img src={`${image_base_url}${currentCrypto.icon}`} data-e2e="copy-icon"  className='currencyImg' alt="bitcoin" height={30} width={30} />{' '}
                                                <span className="ml-10" data-e2e-drop="receive-drop-down" data-e2e={currentCrypto.name}>{currentCrypto.name}</span>
                                            </div>
                                        ) : (
                                            cryptos &&
                                            cryptos.length > 0 && (
                                                <div>
                                                    {' '}
                                                    <img
                                                        src={`${image_base_url}${currentCrypto.icon}`}
                                                        className='currencyImg' alt="bitcoin" height={30} width={30} />{' '}
                                                    <span className="ml-10">{currentCrypto.name}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>{openCryptoOption ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
                                </div>
                                {openCryptoOption && (
                                    <div className="kyc-options-container verify-onboard-sub-heading merchantBorderColor">
                                        {cryptos &&
                                            cryptos.length > 0 &&
                                            cryptos.filter(e => e.symbol !== "LTC" && e.symbol !== "XRP").map((ele, index) => {
                                                return (
                                                    <div className="kyc-options cursor-pointer" key={index}>
                                                        <div onClick={() => handleSelectCryptoOptions(ele)}>
                                                            <div>
                                                                {' '}
                                                                <img src={`${image_base_url}${ele.icon}`} className='currencyImg' alt={ele.name} height={30} width={30} />{' '}
                                                                <span className="ml-10">{ele.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="signup-input-label font-bold mt-20 receive-crypto-text-header">No wallets available </div>
                        )}

                        <div className="crypto_heading">{`Amount`}
                            <span>{`(Available amount: ${parseFloat(balance).toFixed(6)} ${props.coin} )`}</span>
                        </div>
                        <div className="input_container">
                            <input
                                data-e2e="enter-amount"
                                className="withdraw-modal-input pl-3"
                                type="number"
                                placeholder="Enter amount"
                                min="0"
                                step="1"
                                value={amount && Math.max(0, amount)}
                                onChange={e => setAmount(e.target.value ? Number(e.target.value) : e.target.value)}
                            />
                        </div>
                        {(!merchantWithdrawalAddresses || merchantWithdrawalAddresses.length === 0 ) ? (
                            <div className="font-bold mt-20 mb-20 receive-crypto-text-header">No wallets available </div>
                        ) : (
                            <div className="enable-authenticator-content">
                                <div
                                    className={openMerchantWithdrawalAddress ? 'kyc-seleted-options kyc-select-options cursor-pointer merchantBorderColor' : 'kyc-select-options kyc-select cursor-pointer merchantBorderColor'}
                                    onClick={() => handleMerchantWithdrawalAddress()}
                                >
                                    <div className="verify-onboard-sub-heading">
                                        {currentMerchantWithdrawalAddress ? (
                                            <div>
                                                <span className="ml-10" data-e2e-drop="receive-drop-down">
                                                    {currentMerchantWithdrawalAddress.name}
                                                </span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="ml-10">Select a wallet</span>
                                            </div>
                                        )}
                                    </div>
                                    <div>{openMerchantWithdrawalAddress ? <ExpandLessIcon className="kyc-progress" /> : <ExpandMoreIcon className="kyc-progress" />}</div>
                                </div>
                                {openMerchantWithdrawalAddress && (
                                    <div className="kyc-options-container verify-onboard-sub-heading merchantBorderColor">
                                        {merchantWithdrawalAddresses &&
                                            merchantWithdrawalAddresses.length > 0 &&
                                            merchantWithdrawalAddresses.map((ele, index) => {
                                                return (
                                                    <div className="kyc-options cursor-pointer" key={index}>
                                                        <div onClick={() => handleSelectMerchantWithdrawalAddress(ele)}>
                                                            <div>
                                                                {' '}
                                                                <span className="ml-10">{ele.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                          })}
                                    </div>
                                )}
                            </div>
                        )}
                        {notEnoughBalance
                            && <div className="font-bold mt-20 mb-20 receive-crypto-text-header">
                                Not enough balance for withdrawal
                            </div>
                        }

                        {
                            amount && amount > 0 ?
                                <div className='gas-fee-container text-dark withdraw_gasFee mb-3'>
                                    <div className='gas-fee-child-container '>
                                        <div>Estimated Gas Fee</div>
                                        <div>{calcCrypFee()} {props.coin}</div>
                                    </div>
                                </div>
                                : null
                        }

                        {error != "" && <div style={{ color: "red", marginLeft: "25px", marginBottom: "5px" }}>{error}</div>}
                          <button className={disable || error != ""? "withdraw_btn send__continue__opacity" : "withdraw_btn"} disabled={disable || error != ""}
                              onClick={() => handleWithdraw()}
                              style={disable || error != "" ? {} : { ...headerButtonStyle }}
                              >
                              <div data-e2e="withdraw-btn" data-e2e-trasact={testVariable && testVariable}>{`withdraw`}</div>
                          </button>
                    </Card>
                </div>
            </Dialog>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(actions.setLoading(value)),
    };
};

export default connect(null, mapDispatchToProps)(WithdrawModal)