import React from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from "react-redux";

export default function PaysuPlanning() {
  const image_base_url = useSelector((state) => state.config.image_base_url);

	return (
		<section className="ps-section">
			<div className="content-container">
				<div className="ps-section-grid order-reversed-mobile">
					<img className="ps-section-img" src={`${image_base_url}/paysu/ps-planning.webp`} />
					<div>
						<div className="ps-section-title-line"></div>
						<AnimationOnScroll animateIn="animate__fadeIn">
							<p className="ps-section-title mb">Consultancy and Developments Planning.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="200" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Aside from offering the technology you need to grow your business; we also provide our knowledge and expertise. Our consultancy and development planning services provide your business with the knowledge needed for effective operational strategy development and payment completion.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="400" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Your business requires the right leadership and teambuilding planning for success, and at PaySu, we offer consultancy on payments planning and onward implementation to maximise your opportunities for growth.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="600" animateIn="animate__fadeInUp">
							<p className="ps-section-text mb">Through our consultancy and development planning, we’ll help you expand within your target markets or to plan for entering new markets or any day-to-day challenge! This includes marketing strategy too. We support start-ups and clients who are launching new ventures alongside market entries for existing businesses.</p>
						</AnimationOnScroll>
						<AnimationOnScroll delay="800" animateIn="animate__fadeInUp">
							<p className="ps-section-text">What is more, these services are free to contracted clients!</p>
						</AnimationOnScroll>
					</div>
				</div>
			</div>
		</section>
	)
}