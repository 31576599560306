import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import SelectCryptoToDeposit from "./SelectCryptoToDeposit";
import SelectCryptopayWalletToDeposit from './SelectCryptopayWalletToDeposit';
import RefundDeposit from '../Refund/RefundDeposit';
import FourZeroFour from '../../../common/404';
import MerchantPayloading from './MerchantPayloading';
import MyAccount from '../MyAccount/MyAccount';

const LocationWrapComponent = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    switch (props.componentName) {
        case "SelectCryptoToDeposit":
            return <SelectCryptoToDeposit {...props} navigate={navigate} location={location} />
        case "SelectCryptopayWalletToDeposit": 
            return <SelectCryptopayWalletToDeposit {...props} navigate={navigate} location={location} />
        case "SelectAmountToDeposit": 
            return <SelectAmountToDeposit {...props} navigate={navigate} location={location} />
        case "RefundDeposit": 
            return <RefundDeposit {...props} navigate={navigate} location={location} />
        case "MerchantPayloading": 
            return <MerchantPayloading {...props} navigate={navigate} location={location} />
        case "MyAccount": 
            return <MyAccount {...props} navigate={navigate} location={location} />
        default:
            return <FourZeroFour />
    }
}

export default LocationWrapComponent